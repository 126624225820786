import CommonDiv from 'Components/CommonDiv'
import React, { useContext } from 'react'
import { baseUrl, momentFormats } from 'Util/common';
import styled, { css } from "styled-components";
import { THEME, notification } from 'Util/constants';
import { DeleteFilled } from '@ant-design/icons';
import { NotificationNetworkIcon, NotificationPeopleIcon, NotificationTradeIcon, NotificationTruckIcon } from 'Components/Icons';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { DELETE_ADMIN_NOTIFICATION, UPDATE_ADMIN_NOTIFICATIONS, UPDATE_ADMIN_NOTIFICATIONS_RECIPIENT, DELETE_ADMIN_NOTIFICATIONS_RECIPIENT } from 'Util/mutations';
import { NotificationContext } from './NotificationContext';

const Logo = styled.img`
    width: 40px;
`

const IconWrapper = styled.div`
    background-color: #ccc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    right: 0px;
    top: -20px;
`
const SectionWrapper = styled.div`
    cursor: pointer;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid grey;
    padding-bottom: 20px;
    margin-bottom: 25px;
    position: relative;
    .logo{
        margin-right: 20px;
    }

    .notification-header{
        label{
            color: ${THEME.colors.gray};
            font-weight: bold;
            &.notification-type{
                text-transform: uppercase;
            }
        }
    }

    .preview{
        font-size: 16px;
    }

    .delete-icon{
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
    }

`

function NotificationBox(props) {
    const { admin_notification, id: recipient_id } = props
    const { category, title, body, created_at, id } = admin_notification
    const { setShow, setData } = useContext(NotificationContext)

    const [remove] = useMutation(DELETE_ADMIN_NOTIFICATIONS_RECIPIENT, {
        onCompleted() {
            console.log("notification deleted")
            // notification['success']({
            //     message: 'Saved!',
            //     description:
            //         'Location has been updated!',
            // });
            // setDrawer(false);
        },
        onError(e) { console.log('erre', e) }
    });

    const [update] = useMutation(UPDATE_ADMIN_NOTIFICATIONS_RECIPIENT, {
        onCompleted() {
            console.log("notification updated")
            // notification['success']({
            //     message: 'Saved!',
            //     description:
            //         'Location has been updated!',
            // });
            // setDrawer(false);
        },
        onError(e) { console.log('erre1', e) }
    });

    const NotificationIcon = ({ category }) => {

        switch (category) {
            case notification.category.ALL:
                return <IconWrapper>
                    <span style={{ color: THEME.colors.primary, fontWeight: 'bold' }} >ALL</span>
                    <span className='notification' ></span>
                </IconWrapper>

            case notification.category.ADMIN:
                return <IconWrapper><NotificationNetworkIcon size={25} /></IconWrapper>

            case notification.category.PROCUREMENT:
                return <IconWrapper><NotificationTradeIcon size={25} /></IconWrapper>

            case notification.category.LOGISTICS:
                return <IconWrapper><NotificationTruckIcon size={25} /></IconWrapper>

            case notification.category.CRM:
                return <IconWrapper><NotificationPeopleIcon size={25} /></IconWrapper>

            default:
                return <IconWrapper  >
                    <span style={{ color: THEME.colors.primary, fontWeight: 'bold' }} >ALL</span>
                    <span className='notification' ></span>
                </IconWrapper>
        }

    }

    const onDelete = () => remove({ variables: { where: { id: { _eq: recipient_id } } } })
    const onRead = async () => {
        await setData(admin_notification)
        await update({ variables: { where: { id: { _eq: recipient_id } }, _set: { read: 1 } } })
        setShow(true)
    }

    return (
        <SectionWrapper onDoubleClick={onRead} >
            <NotificationIcon category={category} />
            <CommonDiv direction="row" style={{ marginBottom: 12 }} >
                <Logo src={`${baseUrl}/images/cv-logo.png`} alt="Catch Value" className="logo" />
                <CommonDiv className="notification-header">
                    <label className='notification-type' >{category}</label>
                    <label className='notification-date' >{moment(created_at).format('D MMM YYYY hh:mm')}</label>
                </CommonDiv>
            </CommonDiv>
            <h3>{title}</h3>
            <label className='preview'>{body}</label>
            <DeleteFilled className='delete-icon' style={{ color: THEME.colors.primary, fontSize: 20 }} onClick={onDelete} />
        </SectionWrapper>
    )
}

export default NotificationBox