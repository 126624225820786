import React from 'react'
import PropTypes from 'prop-types'
import ButtonCard from 'Components/ButtonCard'
import { useLocation, useParams } from 'react-router-dom'

const data = [
    { link: '/procurement/species-database', title: 'Master' },
    { link: '/procurement/species-database/active', title: 'Active Species' },
    // { link: '/procurement/species-database/pending', title: 'Pending Approval' },
    // { link: '/procurement/species-database/rejected', title: 'Rejected' },
]

function MenuButton(props) {
    const location = useLocation();
    return (
        <div style={{ display: 'flex' }} >
            {data.map(v => (<ButtonCard
                key={v.link}
                link={v.link}
                title={v.title}
                lightBlue
                selected={location.pathname === v.link}
            />))}
        </div>
    )
}

MenuButton.propTypes = {}

export default MenuButton
