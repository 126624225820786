import { useMutation, useSubscription } from '@apollo/client';
import { appConstant } from 'Util/constants';
import { UPDATE_USER_OBJECT } from 'Util/mutations';
import { sendOnBoardingSMS } from 'Util/smsHelper';
import { SUB_USERS } from 'Util/subscriptions';
import { Table, Typography, notification } from 'antd';
import leadingZeroes from 'leading-zeroes';
import React from 'react';
import './style.scss';

function CMSUsersPending() {

    const { data, loading, error } = useSubscription(SUB_USERS, { variables: { status: "pending" } });
    const [updateUser] = useMutation(UPDATE_USER_OBJECT, {
        onCompleted() {

            notification['success']({
                message: 'Saved!',
                description:
                    'Success!',
            });
        },
        onError(e) {
            console.log('erre', e)
        }
    });


    const verifiy = (record, status) => {

        const text = status === "verified" ? "approve" : "reject"

        if (window.confirm(`You wish to ${text} user: ${record.name}? Press ok to continue`)) {

            updateUser({ variables: { id: record.id, obj: { status } } })

            // TODO: send user notification via EMAIL or SMS
            sendOnBoardingSMS({
                to: record.mobile,
                name: record.name,
                action: text
            })

        }

    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const columns = [
        {
            title: 'FishermanID',
            dataIndex: 'id',
            render: (_, rec) => (<span>{leadingZeroes(rec.id, appConstant.leadingZero)}</span>)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true,
        },
        {
            title: 'Aadhaar ID',
            dataIndex: 'aadhaar_id',
            editable: true,
        },
        {
            title: 'Roles',
            dataIndex: 'type',
            editable: true,
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            editable: true,
        },
        {
            title: 'Vessel',
            dataIndex: 'vessel',
            editable: true,
        },
        {
            title: 'Language',
            dataIndex: 'language',
            editable: true,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            editable: true,
        },
        {
            title: 'Verification ID',
            dataIndex: 'ver_id',
            editable: true,
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="action-text-wrapper">
                    <Typography.Link onClick={() => verifiy(record, "verified")}>
                        Approve
                    </Typography.Link>
                    <Typography.Link className="reject-link" onClick={() => verifiy(record, "rejected")}>
                        Reject
                    </Typography.Link>
                </div>
            ),
        },
    ];

    const dataSource = () => {

        if (data) {

            return data.users.map((v, i) => (
                {
                    key: `key-${i}-user-list`,
                    id: v.id,
                    aadhaar_id: v.aadhaar_id,
                    email: v.email,
                    mobile: v.mobile,
                    name: v.name,
                    vessel: v.vessel,
                    language: v.user_language.label,
                    language_id: v.user_language.id,
                    location: v.user_location.label,
                    location_id: v.user_location.id,
                    type: v.user_type.label,
                    users_type_id: v.user_type.id,
                    ver_id: v.verification_id
                }
            ))
        }

        return []

    }

    return (
        <div className="users-page" >
            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    );
}

export default CMSUsersPending
