import { Select, Input, Switch, Popconfirm, notification } from 'antd';
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { Link, useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { DirectSalesItemsContext } from '..';
import { SearchOutlined, PlusOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons';
import { ExtraFilters } from '.';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import { auctionStatusText } from 'Util/common';
import { directSalesAtom, directSalesFilterAtom } from 'Store/directSalesAtom';
import { debounce } from 'lodash';

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const MenuButtonContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

function TableFilters(props) {

    const history = useHistory()
    const [filter, setFilters] = useRecoilState(directSalesFilterAtom);

    const updateQuery = (e) =>{
        setFilters({ ...filter, search: e.target.value })
    }
    const debouncedOnChange = debounce(updateQuery, 200);

    return (
        <Container>

            <DropdownContainer>
                <label>Offers: </label>
                <Select defaultValue="open"
                    style={{ width: 180 }}
                    bordered={false}
                    onChange={v => {
                        setFilters({ ...filter, status: v })
                    }}
                >
                    <Option value="open">Open</Option>
                    <Option value="accepted">Accepted</Option>
                    <Option value="withdrawn">Withdrawn</Option>
                </Select>
            </DropdownContainer>

            <SearchContainer>
                <Input prefix={<SearchOutlined />} style={{ width: 200 }} placeholder="Search" onChange={(e) => debouncedOnChange(e)}/>
            </SearchContainer>

            <ExtraFilters data={props.data} columns={props.columns}/>

        </Container>
    )
}

TableFilters.propTypes = {}

export default TableFilters
