import React from 'react';
import PropTypes from 'prop-types'
import { Layout, Button } from 'antd';
import { baseUrl } from 'Util/common';
import TemplateMenu from 'Menus/TemplateMenu';
import _ from 'lodash'
import './style.scss'
import moment from 'moment';
import CommonHeader from 'Components/CommonHeader';

const { Header, Footer, Content } = Layout;

function BlankLayout({ children, pageTitle, menu: PageMenu }) {

    return (
        <Layout className="common-layout">

            <CommonHeader />

            <Content style={{ padding: '0 50px' }}>
                <div className="content-wrapper">{children}</div>
            </Content>

            <Footer style={{ textAlign: 'right' }}>Copyright &copy; Catch Value {moment().format('YYYY')}</Footer>

        </Layout>
    )
}

BlankLayout.propTypes = {
    title: PropTypes.string
}

export default BlankLayout
