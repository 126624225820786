import { useMutation, useSubscription } from '@apollo/client';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification, Popconfirm, Table } from 'antd';
import React from 'react';
import gmap from 'gmap';
import { DIRECT_OFFER_STATUS } from 'Util/constants';
import { ACCEPT_DIRECT_OFFER, UPDATE_DIRECT_OFFER } from 'Util/mutations';
import { SUB_DIRECT_OFFERS } from 'Util/subscriptions';
import { commonColumns, dataSource, statusQuery } from './common';
import PopOverDetails from './components/PopOverDetails';
import './style.scss';
import { mapConstant } from 'Util/constants';

function StagingDirectSales(props) {

    const { data, loading, error } = useSubscription(SUB_DIRECT_OFFERS, statusQuery(['staged', 'staged-withdrawn']));

    const [updateDirectOffer] = useMutation(UPDATE_DIRECT_OFFER, {
        onCompleted() {
            notification['success']({
                message: "Success!"
            });
        },
        onError(e) { console.log('erre', e) }
    });

    const [acceptDirectOffer] = useMutation(ACCEPT_DIRECT_OFFER, {
        onCompleted() { console.log('Staged Basket Direct Offer Accepted') },
        onError(e) { console.log('erre', e) }
    });

    const handleConfirm = (data) => {

        const { quantity } = data

        let _set = {
            status: DIRECT_OFFER_STATUS.open,
            order_quantity: null,
            avail_quantity: quantity
        }

        if (data.status === DIRECT_OFFER_STATUS.stagedWithdrawn) {
            _set = {
                status: DIRECT_OFFER_STATUS.withdrawn
            }
        }

        updateDirectOffer({
            variables: {
                id: data.id,
                _set
            }
        })

    }

    const handleSubmit = ({ action, data }) => {
        const { id, order_quantity, avail_quantity, user_id, user_info } = data

        if (action === 'buy') {

            const link = gmap.directions(mapConstant.pickupCoordinates, `${user_info.user_location.lat},${user_info.user_location.lng}`)

            acceptDirectOffer({
                variables: {
                    objects: {
                        eta: 10, /** TODO: eta as editable */
                        user_id,
                        link,
                        purchase_order: {
                            data: {
                                user_id,
                                status: "pending",
                                direct_offer_order: {
                                    data: {
                                        status: "accepted",
                                        order_quantity,
                                        direct_offer_id: id
                                    }
                                }
                            }
                        }
                    }
                }
            })


            updateDirectOffer({
                variables: {
                    id,
                    _set: {
                        avail_quantity,
                        status: (avail_quantity <= 0) ? DIRECT_OFFER_STATUS.withdrawn : DIRECT_OFFER_STATUS.open,
                        order_quantity: 0
                    }
                }
            })

        }

    }

    const columns = [
        ...commonColumns,
        {
            title: 'Order',
            dataIndex: 'order_quantity',
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <div className='direct-offer-action-wrapper' >

                    {record.status !== DIRECT_OFFER_STATUS.stagedWithdrawn && (
                        <PopOverDetails data={record} onSubmit={handleSubmit} >
                            <FontAwesomeIcon
                                size="1x"
                                style={{ "cursor": "pointer" }}
                                icon={faCheck}
                                color='#20B0B5'
                            />
                        </PopOverDetails>
                    )}

                    <Popconfirm
                        title="Are you sure you want to remove this item?"
                        onConfirm={() => handleConfirm(record)}
                        okText="Yes"
                        cancelText="No"
                        placement='left'
                    >
                        <FontAwesomeIcon
                            size="1x"
                            style={{ "cursor": "pointer" }}
                            icon={faTimes}
                            color='#FF0100'
                        />
                    </Popconfirm>

                </div>
            ),
        }
    ]

    const cancel = () => {
        console.log('cancel editing')
    };

    return (
        <div className="direct-offer-page" >
            <Table
                loading={loading}
                bordered
                dataSource={dataSource('staged', data)}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    )
}

StagingDirectSales.propTypes = {

}

export default StagingDirectSales

