import React from 'react'
import PropTypes from 'prop-types'
import DashTitleDivider from 'Components/DashTitleDivider'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'

const ERPPage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            ERPPage</div>
    )
}

ERPPage.propTypes = {}

export default ERPPage