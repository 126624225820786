import styled from "styled-components";
import Delete from './Delete';
import Edit from './Edit';
import ExtraFilters from './ExtraFilters';
import JobsDropdown from './JobsDropdown';
import Search from './Search';
import NewJob from "./NewJob";

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

function TableFilter(props) {    
    return (
        <Container>
            <JobsDropdown />
            <Search />
            <NewJob />
            <Edit />
            <Delete />
            <ExtraFilters data={props.data} columns={props.column}/>
        </Container>
    )
}

TableFilter.propTypes = {
}

export default TableFilter