import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Radio } from 'antd';
import CommonButton, { ButtonLoader } from 'Components/CommonButton';
import CommonDiv from 'Components/CommonDiv';
import { FormDivision, FormSection } from 'Components/Form';
import leadingZeroes from 'leading-zeroes';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { appConstant, mapConstant, THEME } from 'Util/constants';
import { UPDATE_PURCHASE_ORDER, INSERT_VENDOR_NOTIFICATION } from 'Util/mutations';
import { GET_PURCHASE_ORDERS } from 'Util/queries';
import SaleTypeSelect from './components/Select/SaleTypeSelect';
import FulfilmentTypeSelect from './components/Select/FulfilmentTypeSelect';
import { ChooseLocation, ChooseProduct, Header } from './components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Barcode from 'react-barcode'
import moment from 'moment';
import { isNull } from 'lodash';
import { IconMap } from 'Components/Icons';
import gmap from 'gmap';

const Container = styled.div``
function PurchaseOrderPage(props) {

    const [form] = Form.useForm();
    const params = useParams()
    const history = useHistory()
    const [formLoading, setFormLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [openModal, setOpenModal] = useState(false);
    const [amended, setAmended] = useState(false)
    const [values, setValues] = useState()
    const [showProductModal, setShowProductModal] = useState(false)
    const [showLocationModal, setShowLocationModal] = useState(false)
    const [locationOption, setLocationOption] = useState('user');
    const [locationDetails, setLocationDetails] = useState();
    const [mapLink, setMapLink] = useState();

    const [getPurchaseOrder ] = useLazyQuery(GET_PURCHASE_ORDERS, {
        fetchPolicy: "network-only",
        onCompleted(data) {
            const po = data.purchase_orders[0]
            const { bid, user, pickup_orders, direct_offer_order } = po
            const { collection_location } = pickup_orders[0]

            const isDirectSale = isNull(po.bid_id)
            const totalValue = isDirectSale ? direct_offer_order.order_quantity * direct_offer_order.direct_offer.price : bid.volume * bid.price

            console.log('test', pickup_orders);

            const isUserLocation = isNull(pickup_orders[0].collection_location_id)
            const lat = collection_location?.lat ?? user.user_location.lat
            const lng = collection_location?.lng ?? user.user_location.lng
            const link = gmap.directions(mapConstant.pickupCoordinates, `${lat},${lng}`)

            setLocationOption(isUserLocation ? 'user' : 'collection')
            setLocationDetails({
                collection_location,
                user: {
                    pickup_location: user.user_location.label,
                    location_code: leadingZeroes(user.user_location.id, appConstant.leadingZero),
                    lat: user.user_location.lat,
                    lng: user.user_location.lng
                }
            })        
            setMapLink(link)

            form.setFieldsValue({
                ...po,
                product_id: isDirectSale ? null : bid.product.id,
                item_code: isDirectSale ? direct_offer_order.direct_offer.species_sku : bid.product.sku,
                item_description: isDirectSale ? direct_offer_order.direct_offer.species_name : bid.product.label,
                vendor_id: leadingZeroes(user.id, appConstant.leadingZero),
                quantity_sold: isDirectSale ? direct_offer_order.order_quantity : bid.volume,
                price_sold: isDirectSale ? direct_offer_order.direct_offer.price : bid.price,
                total_value: totalValue,
                adjustment_value: po.adjustment_value || 0,
                payment_value: (totalValue + (po.adjustment_value || 0)),
                date: moment(po.updated_at).tz('Asia/Kolkata').format('DD MMM YYYY'),
                time: moment(po.updated_at).tz('Asia/Kolkata').format('HH:mm:ss'),
                sale_type: po.sale_type,
                lot_number: isDirectSale ? direct_offer_order.id : bid.id,
                fulfilment_type: po.fulfilment_type,
                collection_location_id: pickup_orders[0].collection_location_id,
                pickup_location: isUserLocation ? user.user_location.label : collection_location.location_name,
                location_code: isUserLocation ? leadingZeroes(user.user_location.id, appConstant.leadingZero) : collection_location.location_code,
            })
        }
    })

    const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER, {
        onCompleted(data) {
            setFormLoading(false);
            notification['success']({
                message: 'Amended!',
                description: 'Purchase Order Amended!',
            });
            setAmended(true)
        }
    });

    const [insertVendorNotification] = useMutation(INSERT_VENDOR_NOTIFICATION, {
        onCompleted(data) {
            setFormLoading(false);
            notification['success']({
                message: 'Sent!',
                description: 'Amendment Sent!',
            });
            setOpenModal(false)
            setAmended(false)
        }
    });

    useEffect(() => {
        getPurchaseOrder({ variables: { where: { id: { _eq: params.id }  } } })
    }, [getPurchaseOrder, params.id])

    const submitHandler = async (values) => {
        console.log('values',values);
        setValues(values)
        setOpenModal(true)
    }
    
    const onAmend = async () => {
        //TO DO: update if direct sale
        updatePurchaseOrder({
            variables: {
                id: params.id,
                _set: {
                    adjustment_value: values.adjustment_value,
                    sale_type: values.sale_type,
                    fulfilment_type: values.fulfilment_type,
                    updated_at: moment()
                },
                bid_id: values.lot_number,
                bid_object: {
                    volume: values.quantity_sold,
                    price: values.price_sold,
                    product_id: values.product_id,
                    updated_at: moment()
                },
                pu_set: {
                    collection_location_id: values.collection_location_id,
                    updated_at: moment()
                }
            }
        })
    }

    const onResendAmend = async () => {

        const { vendor_id } = form.getFieldsValue(['vendor_id'])
        const uid = parseInt(vendor_id, 10);

        const variables = {
            "objects": [
                {
                    user_id: uid,
                    subject: 'Purchase Order Amended',
                    body: `Purchase order #${params.id} has been amended`,
                    type: 'alert'
                }
            ]
        }
        insertVendorNotification({ variables })
    }

    const onCancelModal = () => {
        setOpenModal(false)
        setAmended(false)
    }
    
    const failedSubmitHandler = (values) => {
        setFormLoading(false);
        notification['error']({
            message: 'Something went wrong!',
            description: 'Please input values on required fields.',
        });
    }

    const cancelHandler = () => {
        history.push(`/procurement/purchase-orders/data`)
    }

    const currency = appConstant.currencySymbol

    const calcAdjustments = () => {
        const {quantity_sold, price_sold, adjustment_value} = form.getFieldsValue(['quantity_sold', 'price_sold', 'adjustment_value'])
        const totalValue = quantity_sold * price_sold
        form.setFieldsValue({
            total_value: quantity_sold * price_sold,
            payment_value: parseInt(totalValue) + parseInt(adjustment_value || 0)
        })
    }

    const setFieldValue = (key, value) => {
        if (!Array.isArray(key)) form.setFieldsValue({ ...form.getFieldsValue(), [key]: value })
        else form.setFieldsValue({
          ...form.getFieldsValue(),
          [key[0]]: {
            ...form.getFieldsValue()?.[key[0]],
            [key[1]]: value
          }
        })
    }

    const onChangeLocationOption = (e) => {
        const value = e.target.value
        const { user, collection_location} = locationDetails
        let link;

        if (value === 'user'){
            form.setFieldsValue({
                collection_location_id: null,
                pickup_location: user.pickup_location,
                location_code: user.location_code
            })
            link = gmap.directions(mapConstant.pickupCoordinates, `${user.lat},${user.lng}`)
        }else{
            form.setFieldsValue({
                collection_location_id: collection_location?.id ?? null,
                pickup_location: collection_location?.location_name ?? null,
                location_code: collection_location?.location_code ?? null
            });      
            link = collection_location && gmap.directions(mapConstant.pickupCoordinates, `${collection_location.lat},${collection_location.lng}`)      
        }
        setMapLink(link)
        setLocationOption(value);
    };

    const Ellipsis = () => (
        <div
            style={{
                position: 'absolute',
                right: '10px',
                bottom: '2px',
                cursor: 'pointer',
                height: '100%',
                color: THEME.colors.primary,
                fontSize: '18px',
                fontWeight: 'bold',
            }}                             
        >
            ...
        </div>
    );

    const onClickIconMap = () => {
        mapLink && window.open(mapLink, '_blank')
    }
    
    return (
        <Container>
            <Header setDisabled={(set) => setDisabled(set)}/>
            <ChooseProduct showModal={showProductModal} setShowModal={setShowProductModal} setFieldValue={setFieldValue}/>
            <ChooseLocation showModal={showLocationModal} setShowModal={setShowLocationModal} setFieldValue={setFieldValue}/>
            <Form
                form={form}
                name='vendorForm'
                onFinish={submitHandler}
                onFinishFailed={failedSubmitHandler}
            >
                <FormSection title="Purchase Order Details">
                    <FormDivision>
                        <Form.Item style={{ display: 'none' }} label="Product Id" name="product_id" ><Input /></Form.Item>

                        <Form.Item label="Item Code" name="item_code" >
                            <Input onClick={() => setShowProductModal(true)} disabled={disabled} readOnly suffix={<Ellipsis/>}/>
                        </Form.Item>

                        <Form.Item label="Item Description" name="item_description" ><Input disabled={disabled} readOnly/></Form.Item>

                        <Form.Item label="Vendor Id" name="vendor_id"><Input disabled={disabled} readOnly/></Form.Item>

                        <Form.Item label="Quantity Sold (KG)" name="quantity_sold"><Input onChange={calcAdjustments} type='number' disabled={disabled} /></Form.Item>

                        <Form.Item label={`Price Sold (${currency}/KG)`} name="price_sold"><Input onChange={calcAdjustments} type='number' disabled={disabled} /></Form.Item>

                        <Form.Item label={`Total Value (${currency})`} name="total_value"><Input readOnly style={{ color: 'darkgrey'}} disabled={disabled} /></Form.Item>

                        <Form.Item label={`Adjustment Value (${currency})`} name="adjustment_value"><Input onChange={calcAdjustments} type='number' disabled={disabled} /></Form.Item>

                        <Form.Item label={`Payment Value (${currency})`} name="payment_value"><Input readOnly style={{ color: 'darkgrey'}} disabled={disabled} /></Form.Item>

                    </FormDivision>

                    <FormDivision>

                        <Form.Item label="Date" name="date" readOnly><Input disabled={disabled} readOnly/></Form.Item>

                        <Form.Item label="Time" name="time"><Input disabled={disabled} readOnly/></Form.Item>

                        <Form.Item label="Sale Type" name="sale_type"><SaleTypeSelect disabled={disabled} /></Form.Item>

                        <Form.Item label="Lot Number" name="lot_number"><Input disabled={disabled} readOnly/></Form.Item>

                        <Form.Item label="Fulfilment Type" name="fulfilment_type"><FulfilmentTypeSelect disabled={disabled} /></Form.Item>

                        <div style={{ display: "flex", flexDirection: "row", marginTop: 24, marginBottom: 24 }}>
                            <div style={{ marginRight: 71 }}>Location Option:</div>
                            <div style={{ display: "flex" }}>                            
                                <Radio.Group onChange={onChangeLocationOption} value={locationOption} disabled={disabled}>
                                    <Radio value='user'>User Location</Radio>
                                    <Radio value='collection'>Collection Location</Radio>
                                </Radio.Group>
                            </div>
                        </div>

                        <Form.Item style={{ display: 'none' }} label="Collection Location ID" name="collection_location_id" ><Input /></Form.Item>                        

                        <Form.Item label="Pickup Location" name="pickup_location">
                            <Input placeholder='...' style={{ color: 'darkgrey'}} 
                                disabled={disabled} 
                                readOnly 
                                suffix={
                                    <div onClick={onClickIconMap} style={{ display: "flex", cursor: 'pointer' }}>
                                        <IconMap/>
                                    </div>
                                }
                            />
                        </Form.Item>

                        <Form.Item label="Location Code" name="location_code" rules={[{ required: true, message: '' }]}>
                            <Input placeholder='Choose Location Code' onClick={() => setShowLocationModal(true)} disabled={disabled || locationOption !== 'collection'} readOnly suffix={<Ellipsis/>}/>
                        </Form.Item>


                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginRight: 100 }}>PO Barcode:</div>
                            <div style={{ display: "flex" }}>
                                <Barcode value={leadingZeroes(params.id, appConstant.leadingZero)} height={70} margin={0} fontSize={10}/> 
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", marginTop: 24 }}>
                            <div style={{ marginRight: 67 }}>Logistics Tracker:</div>
                            <div style={{ display: "flex" }}>
                                <Barcode value='1001' height={50} margin={0} fontSize={10} />
                            </div>
                        </div>                        
                        
                    </FormDivision>
                </FormSection>


                <CommonDiv direction="row" justify="flex-end"  >
                    <ButtonLoader loading={formLoading} htmlType='submit' disabled={disabled}>Amend</ButtonLoader>
                    <CommonButton className='reverse' onClick={cancelHandler} >Cancel</CommonButton>

                    <Modal
                        visible={openModal}
                        centered={true}
                        autoFocusButton='ok'
                        width='600px'
                        open={openModal}
                        closeIcon=" "
                        footer={
                        <div>
                            <Button style={{ backgroundColor: THEME.colors.primary, borderColor: THEME.colors.primary }} type="primary" onClick={amended ? onResendAmend: onAmend}>Yes</Button>
                            <Button onClick={onCancelModal}>{amended ? 'Cancel' : 'No'}</Button>
                        </div>
                        }
                    >
                        <p>
                            <span style={{ fontSize: '30px', marginRight: '20px', color: THEME.colors.primary }}>
                                <QuestionCircleOutlined />
                            </span>             
                            {amended ? `Resend Amended P.O. #${params.id}?` : `Amend P.O. #${params.id}?`}
                        </p>
                    </Modal>
                </CommonDiv>

            </Form>


        </Container>
    )
}

PurchaseOrderPage.propTypes = {}

export default PurchaseOrderPage
