import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SETTINGS } from 'Util/queries';
import { UPDATE_SETTINGS } from 'Util/mutations';
import { useRecoilState } from 'recoil';
import { FilterFilled, InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { listOrderAtom, infoIconAtom, selectedSpecies} from 'Store/procurementAtom';
import PropTypes from 'prop-types'

function InfoBoxSwitch({origin, resetSelection}){

    const [info, setInfo] = useRecoilState(infoIconAtom)
    const [settings, setSettings] = useState()

    const { data, loading } = useQuery(GET_SETTINGS, {
        fetchPolicy: "network-only",
        variables: {
            where: { key: { _eq: "info_box_switch"} }
        },
        onCompleted(data) {
            const { species, products, vendors } = data.settings[0].values
            setInfo({
                species: species === 1 ? true : false,
                products: products === 1 ? true : false,
                vendors: vendors === 1 ? true : false
            })
            setSettings(data.settings[0].values)
        }
    });

    const [updateSettings] = useMutation(UPDATE_SETTINGS, {
        onCompleted(data) {
        },
        onError(e) {
            console.log('erre', e)
        }
    })

    if (loading) return <></>

    const changeInfo = () => {
        let value = !info[origin]
        setInfo({
            ...info,
            [origin]: !info[origin] 
        })
        updateSettings({
            variables: {
                key: "info_box_switch",
                values: {
                    ...settings,
                    [origin]: value ? 1 : 0
                }
            }
        })
        resetSelection()
    }

    return <>
        {info[origin] ?
        <InfoCircleFilled onClick={changeInfo}/>:
        <InfoCircleOutlined onClick={changeInfo}/>
        }
    </>    
}

InfoBoxSwitch.propTypes = {
    origin: PropTypes.string,
    resetSelection: PropTypes.func
}

export default InfoBoxSwitch;