import React, {useState} from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { DataTable } from './components'
import { Button } from 'antd';
import { THEME } from 'Util/constants';
import { ADD_INSERT_USER_TAGS } from 'Util/mutations';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

const Container = styled.div``

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .custom-button {
        border-radius: 0px; 
        background-color: ${THEME.colors.lightBlue};
        color: white;
    }
`

const auctionMenu = [
    { title: "All Users", link: "/crm/notifications/all-users" },
    { title: "Segments", link: "/crm/notifications/segments" },
    { title: "Tags", link: "/crm/notifications/tags" },
    { title: "Polls", link: "/crm/notifications/polls" }
]

function CRMPolls() {
    const history = useHistory()
    
    return (
        <>
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
                <ButtonWrapper>
                    <Button shape="round" className="custom-button" onClick={() => { history.push(`/crm/notifications/polls/new`) }}>New Poll</Button>
                </ButtonWrapper>
                <DataTable />
            </Container>
        </>
    )
}

export default CRMPolls
