import axios from "axios";

// const apiKey = process.env.REACT_APP_TRACK_POD_KEY || "";
const endpoint = `${process.env.REACT_APP_API}/pod`//'https://api.track-pod.com';
const headers = {
    // 'X-API-KEY': apiKey,
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    // 'Accept-Encoding': 'gzip, deflate, br'
};

/**
 * Utility function to post data to the Track-Pod address endpoint.
 * @param {Object} data - The data to be posted.
 * @returns {Promise<Object>} - A promise that resolves to the response data.
 * const data = {
    "Id": "30495",
    "Name": "2 St Josephs Crescent, Liverpool, L3 3JF 111",
    "Street": "Pinegrove",
    "City": "Bacolod",
    "State": "",
    "PostalCode": "",
    "FullAddress": "2 St Josephs Crescent, Liverpool, L3 3JF 11",
    "Zone": "Lvp",
    "Lat": 25.29048,
    "Lon": 65.29405
}
 */
export const podAddress = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/address', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const addDriver = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/add-driver', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const updateDriver = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/update-driver', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const addVehicle = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/add-vehicle', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const updateVehicle = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/update-vehicle', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const addRoute = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/add-route', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const updateRoute = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/update-route', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const deleteRoute = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/delete-route', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const deleteDriver = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/delete-driver', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const deleteVehicle = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/delete-vehicle', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const getVehicle = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/get-vehicle', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const getDriver = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/get-driver', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};


export const addOrder = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/add-order', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error)
        );
    });
};
export const updateOrder = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/update-order', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error)
        );
    });
};

export const deleteOrder = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/delete-order', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    //404: proceeds to delete in console if job is not found in TrackPod
                    resolve({ Status: 404, Detail: "Not found. Order not found", Title: "Order Not Found" });
                } else {
                    reject(error);
                }
            }
        );
    });
};

export const getOrder = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + '/get-order', data, { headers })
            .then(response => resolve(response.data))
            .catch(error => reject(error)
        );
    });
};