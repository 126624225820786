import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState, useRef } from 'react';
import { config, momentFormats } from '../Util/common';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { SUB_AUCTION_SCHEDULES } from 'Util/subscriptions';
import { useSubscription } from '@apollo/client';

const Container = styled.div`
    position: absolute; 
    display: flex;
    gap: 5px;
    right: 250px;
    top: 408px;
`
const LabelText = styled.h3({
    fontWeight: 'bold',
})

const TimerText = styled.h3({
    fontWeight: 'bold',
    color: '#ff0000'
},
    variant({
        prop: 'status',
        variants: {
            prebid: { color: '#ff0000' },
            auction:{ color: '#00b050'},
            result: { color: '#ed7014'}
        }
    })
)

const useCountdownTimer = (duration, status) => {
    const [countdown, setCountdown] = useState(duration);

    useEffect(() => {
        let interval;
    
        if (duration > 0) {
          setCountdown(duration);
          interval = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown > 0) {
                return prevCountdown - 1;
              }
              return 0;
            });
          }, 1000);
        } else if (duration === 0 ) {
            setCountdown(0);
        } 

        return () => {
          clearInterval(interval);
        };
    }, [duration, status]);
    return countdown;   
};

function AuctionTimer({ position = "relative" }) {

    // const [timeStr, setTimeStr] = useState("")
    // const [labelText, setLabelText] = useState("⌛")
    const [sched, setSched] = useState(null);
    const [dataSchedule, setDataSchedule] = useState()

    const { loading, error } = useSubscription(SUB_AUCTION_SCHEDULES, {
        variables: {
            order_by: { datetime: 'asc' }
          },
        onSubscriptionData({ subscriptionData: { data } }) {
            if (data && data.auction_schedules) {
                setDataSchedule(data.auction_schedules)
            }
        }
    })

    const statusLabels = status => {
        if (status === 'prebid') { return "Prebidding: " }
        if (status === 'auction') { return "Auction in-progress: " }
        if (status === 'counter') { return "Offer phase: " }
        if (status === 'results') { return "Auction results: " }
        return ""
    }

    useEffect(() => {
        const socket = new WebSocket(`${process.env.REACT_APP_WS_WEBHOOK_ENDPOINT}/websocket`);
    
        socket.onopen = () => {
          console.log('WebSocket connection established.');

          const keepAliveInterval = setInterval(() => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.send('ping from web');
            } else {
              clearInterval(keepAliveInterval);
            }
          }, 30000);
        };
    
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if(data.status){
                if(data.status !== 'closed'){
                    setSched(data);
                }else{
                    setSched(null);
                }
            }
        };
    
        return () => {
          socket.close();
          console.log('WebSocket connection has closed.');
          setSched(null);
        };
    }, []);

    const countdown = useCountdownTimer(sched?.duration || 0, sched?.status);
    
    let schedules = dataSchedule
    let labelText = "⌛";
    let timeStr;

    if(sched){
        labelText = statusLabels(sched.status)
        var formattedTime = moment.utc(moment.duration(countdown, 'seconds').asMilliseconds()).format('HH:mm:ss');
        timeStr = formattedTime
    }else{
        const nextAuction = _.first(_.filter(schedules, ['status', 'scheduled']))
        const currentTimeIST = moment(`${moment().tz(config.timezone).format('YYYY-MM-DD HH:mm:ss')}`)
        labelText = nextAuction ? "Next Auction starts" : "No Scheduled Auctions"
        timeStr = nextAuction ? moment(nextAuction.datetime).from(currentTimeIST) : ""
    }

    return (
        <Container>
            <LabelText>{labelText} </LabelText>
            <TimerText status={sched?.status}>{timeStr}</TimerText>
        </Container>
    )
}

export default AuctionTimer
