import { THEME } from "Util/constants";
import {
  DatePicker,
  Form,
  Input,
  notification,
  Select,
  Switch
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useLazyQuery, useMutation } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import PhoneInput from 'Components/PhoneValidator';
import { UPSERT_DRIVERS } from "Util/mutations";
import { useHistory } from "react-router-dom";
import { Header } from "./components/DriverCard";
import { GET_DRIVER } from "Util/queries";
import { addDriver, updateDriver, getDriver } from 'Util/trackpod'
import { PlusOutlined } from '@ant-design/icons';
import DriverTypeDropDown from "./components/DriverCard/DriverTypeDropdown";
import { Country, City } from 'country-state-city';

const Container = styled.div``;

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function DriverCard(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  const editMode = routeLocation.pathname.includes("/logistics/drivers/edit");
  const [formLoading, setFormLoading] = useState(false);
  const [img, setImg] = useState();
  const [origImg, setOrigImg] = useState(null);
  const imageRef = useRef(null)
  const [cityList, setCityList] = useState([])
  const [loc, setLoc] = useState({
    country: ''
})


  const [update] = useMutation(UPSERT_DRIVERS, {
    onCompleted(data) {
      notification['success']({
        message: 'Saved!',
        description: `Driver has been saved!`,
      });
    }
  })

  const [getDriverDetails] = useLazyQuery(GET_DRIVER, {
    fetchPolicy: "network-only",
    onCompleted(data) {
        console.log('dataq', data);
        const userDetails = data.drivers[0]

        const {address1, address2, city, country} = userDetails
        const address = [address1, address2, city, country].filter(Boolean).join(", ");
        form.setFieldsValue({
            ...userDetails,
            address
        })
    }
  })

  useEffect(() => {
    if (editMode) {
      console.log('querying...')
      getDriverDetails({ variables: { where: { id: { _eq: params.id } } }, });
    } 
    setCityList(City.getCitiesOfCountry('IN'));
  }, [getDriverDetails, params.id, editMode]);

  const submitHandler = async (values) => {

    setFormLoading(true)

    const {dimensions, address, ...driverDetails } = values;
    let uuid = null

    const updateObject = {
      "Id": values.uuid,
      "Phone": values.phone,
      "HomeAddress": address,
      "Name": values.name,
      "Active": values.active,
      "Username": values.username,
      "Password": values.password
    }

    const addObject = {
      "Phone": values.phone,
      "HomeAddress": address,
      "Name": values.name,
      "Active": values.active,
      "Username": values.username,
      "Password": values.password
    }

    const getObject = {
      "Username": values.username
    }
  
    if (editMode){
      try {
        const res = await updateDriver(updateObject) 
        console.log('res', res);
        const { Detail, Status, Title } = res
        console.log({ Detail, Status, Title});

        if(Status !== 202){
          notification["error"]({
            message: Title,
            description: Detail,
          });
          setFormLoading(false)
          history.goBack()
          return
        }
      } catch (error) {
        console.log('errorerrorerror', error);
        if (error.response) {
          console.error('Error status code:', error.response.status);
          if (error.response.status === 405) {
              console.error('Error 405: Method Not Allowed');
          } else if (error.response.status === 500) {
              console.error('Error 500: Internal Server Error');
          }
      } else {
          console.error('Error occurred:', error);
      }
        notification["error"]({
          message: "Something went wrong!",
          description: "Please try again later.",
        });
        setFormLoading(false)
        history.goBack()
        return
      }

    }
    else{
      try {
        const res = await addDriver( addObject )
        console.log(res);
        const { Detail, Status, Title } = res
        console.log({ Detail, Status, Title});

        if(Status !== 201){
          notification["error"]({
            message: Title,
            description: Detail,
          });
          setFormLoading(false)
          history.goBack()
          return
        }

        const getRes = await getDriver(getObject)        
        uuid = getRes.Id

      } catch (error) {
        console.log('errorerrorerror', error);
        if (error.response) {
          console.error('Error status code:', error.response.status);
          if (error.response.status === 405) {
              console.error('Error 405: Method Not Allowed');
          } else if (error.response.status === 500) {
              console.error('Error 500: Internal Server Error');
          }
      } else {
          console.error('Error occurred:', error);
      }
        notification["error"]({
          message: "Something went wrong!",
          description: "Please try again later.",
        });
        setFormLoading(false)
        history.goBack()
        return
      }
    }

    const objects = {
      ...driverDetails,
      uuid
    }

    await update({ variables: { 
      update_columns: ["id", ...Object.keys(driverDetails)],
      objects 
      } 
    })

    setFormLoading(false)
    history.goBack()
  };

  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const handleAddressChange = () => {
    const { address1, address2, country, city } = form.getFieldsValue(['address1', 'address2', 'country', 'city']);
    const address = [address1, address2, city, country].filter(Boolean).join(", ");
    form.setFieldsValue({ address });
  };

  return (
    <Container>
      <Header editMode={editMode}/>
      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="General">
          <div className="division">

            <Form.Item name="id" hidden >
              <Input />
            </Form.Item>

            <Form.Item name="uuid" hidden >
              <Input />
            </Form.Item>

            <Form.Item label="Driver License No." name="license">
              <Input />
            </Form.Item>

            <Form.Item label="License Expiry" name="license_expiry">
              <DatePicker format="DD MMM YYYY" style={{ width: '100%' }}/>
            </Form.Item>


            <Form.Item label="Driver Type" name="type">
              <DriverTypeDropDown />
            </Form.Item>


            <Form.Item label="Name" name="name" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

            <Form.Item 
                name="active" 
                label="Blocked" 
                valuePropName="checked" 
                initialValue={false}
            >
                <Switch />
            </Form.Item>

            <Form.Item name="address" hidden>
              <Input />
            </Form.Item>

            <Form.Item label="Address 1" name="address1">
              <Input onChange={handleAddressChange}/>
            </Form.Item>

            <Form.Item label="Address 2" name="address2">
              <Input onChange={handleAddressChange}/>
            </Form.Item>

            <Form.Item label="Country" name="country" rules={[{ required: true, message: '' }]} initialValue={"India"}>
              <Select showSearch
                  onChange={(countryName, option) => {
                    const isoCode = option.key
                    console.log('isoCode', isoCode);
                    setCityList(City.getCitiesOfCountry(isoCode));
                    setLoc({ ...loc, country: isoCode });
                    handleAddressChange();
                  }}
              
              >
                  {Country.getAllCountries().map(v => <Select.Option value={v.name} key={`${v.isoCode}`}>{v.flag} {v.name} ({v.isoCode})</Select.Option>)}
              </Select>
            </Form.Item>

            <Form.Item label="City" name="city" rules={[{ required: true, message: '' }]}>
                <Select
                    showSearch
                    placeholder="Select a City"
                    onChange={handleAddressChange}
                >
                    {cityList.map(v => <Select.Option value={v.name} key={`city-${v.name}`}>{v.name}</Select.Option>)}
                </Select>

            </Form.Item>

            <Form.Item label="Postal Code" name="postal">
              <Input />
            </Form.Item>

            <Form.Item label="Track POD Username" name="username" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Track POD Password" name="password" rules={[{ required: true, message: '' }]}>
              <Input type="password"/>
            </Form.Item>


          </div>

          <div className="division">

          <Form.Item label="Class of Vehicle">
              <div  style={{ display: 'flex', flexDirection: 'column', gap: 8}}>
                <Form.Item name={['dimensions', "length"]} noStyle initialValue={"MCWG"}>
                  <Select/>
                </Form.Item>
                <Form.Item name={['dimensions', "width"]} noStyle initialValue={"LMV"}>
                  <Select/>
                </Form.Item>
                <Form.Item name={['dimensions', "height"]} noStyle initialValue={"TRANS"}>
                  <Select/>
                </Form.Item>         
              </div>
            </Form.Item>

            <Form.Item label="Mobile No." name="phone">
              <PhoneInput />
            </Form.Item>

            <Form.Item label="Email" name="email" >
              <Input />
            </Form.Item>

            <Form.Item label="Aadhaar ID" name="aadhaar_id" >
              <Input />
            </Form.Item>

            <div style={{ display: "flex", flexDirection: "row", marginBottom: 24 }}>
                <div style={{ marginRight: 30 }}>Verification Documents</div>
                <div style={{ display: "flex" }}>
                    {img && <img src={img} alt="" style={{ height: 70, width: 70, marginRight: 10 }} />}
                    <label htmlFor="upload-photo" style={{ display: "flex", backgroundColor: "#D9E3F3", alignItems: "center", justifyContent: "center" }}>
                        <PlusOutlined style={{ fontSize: 70, color: "#90ABDD" }} size={100} color="red" />
                    </label>
                    {/* <input type="file" ref={imageRef} onChange={handleInputChange} id="upload-photo" style={{ display: "none" }} disabled={disabled} /> */}
                </div>
            </div>
                          
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 24 }}>
                <div style={{ marginRight: 52 }}>Add Picture or Logo</div>
                <div style={{ display: "flex" }}>
                    {img && <img src={img} alt="" style={{ height: 70, width: 70, marginRight: 10 }} />}
                    <label htmlFor="upload-photo" style={{ display: "flex", backgroundColor: "#D9E3F3", alignItems: "center", justifyContent: "center" }}>
                        <PlusOutlined style={{ fontSize: 70, color: "#90ABDD" }} size={100} color="red" />
                    </label>
                    {/* <input type="file" ref={imageRef} onChange={handleInputChange} id="upload-photo" style={{ display: "none" }} disabled={disabled} /> */}
                </div>
            </div>

          </div>

        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit" >
            {editMode ? "Update" : "Save"}
          </ButtonLoader>

          <CommonButton className='reverse' onClick={() => history.goBack()}>
            Cancel
          </CommonButton>

        </CommonDiv>
      </Form>
    </Container>
  );
}

DriverCard.propTypes = {};

export default DriverCard;
