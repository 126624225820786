import { faKey, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { loginAtom } from 'Store/loginAtom';
import './login.scss';

// import Cryptr from 'cryptr'
// const cryptr = new Cryptr(process.env.REACT_APP_CRYPTR_SECRET);


function Login() {
    const [state, setState] = useRecoilState(loginAtom)

    const { err, qr, visible, input } = state

    const setErr = (err) => {
        setState({ ...state, err })
    }

    const setQr = (qr) => {
        setState({ ...state, qr })
    }

    const setVisible = (visible) => {
        setState({ ...state, visible })
    }

    const setInput = (input) => {
        setState({
            ...state, input: {
                ...input,
            }
        })
    }

    const submitHandler = () => {

        /** clear error */
        setErr("")

        if (_.isEmpty(input.username) && _.isEmpty(input.password)) {
            setErr("Username and Password are required.")
            return null
        }


        axios.post(
            `${process.env.REACT_APP_AUTH_ENDPOINT}/admin/login`,
            { ...input }
        ).then(res => {

            const { success } = res.data

            if (!success) {
                setErr(res.data.message)
            }

            if (success && !_.isEmpty(res.data.qrcode)) {
                setState({
                    ...state,
                    visible: true,
                    qr: res.data.qrcode
                })
            }

            if (success && !_.isUndefined(res.data.token)) {
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('user_type', res.data.user_type_id)
                localStorage.setItem('name', res.data.name)
                localStorage.setItem('uid', res.data.user_id)
                localStorage.setItem('email', res.data.email)
                localStorage.setItem('user', JSON.stringify(res.data.user))
                window.location.href = "/"
            }

        })

    }


    return (
        <div className="login-form-wrapper">

            <div className={'login-form'} >

                <div className={'form-item'}>
                    <img className={'logo'} src="/images/logo.png" />
                </div>

                <div className={'form-item'}>
                    <h3>Administrator Login</h3>
                </div>

                <div className={'form-item'}>
                    <div className="input-icon">
                        <FontAwesomeIcon size="2x" icon={faUser} />
                    </div>
                    <Input placeholder='Username' onChange={(e) => { setInput({ ...input, user: e.target.value }) }} />
                </div>

                <div className={'form-item'}>
                    <div className="input-icon">
                        <FontAwesomeIcon size="2x" icon={faLock} />
                    </div>

                    <Input placeholder='Password' type="password" onChange={(e) => { setInput({ ...input, password: e.target.value }) }} />
                </div>

                <div className={'form-item'}>
                    <div className="input-icon">
                        <FontAwesomeIcon size="2x" icon={faKey} />
                    </div>
                    <Input placeholder='Authenticator Code' onChange={(e) => { setInput({ ...input, otp: e.target.value }) }} />
                </div>

                <div className={'form-item'} style={{ height: "20px", marginTop: "4px" }}>
                    {err && <p className={'error-wrapper'} >ERROR: {err}</p>}
                </div>

                <div className={'form-item'}>
                    <Button className={'submit-btn'} onClick={submitHandler} >Log In</Button>
                </div>

            </div>

            <Modal
                onCancel={() => { setVisible(false) }}
                className={'qr-modal'}
                title="Set up Two Factor Authentication"
                visible={visible}
                closable={true}
                centered
                footer={null}
            >
                {!_.isEmpty(qr) && <img src={qr} />}
                <p>Scan the QR code to add authenticator to your account.</p>
                <p>Once done just close the dialog.</p>
            </Modal>

        </div>
    );

}

export default Login
