import { DeleteFilled, EditOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { DatePicker, Select, notification, Popconfirm, Modal } from 'antd';
import { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { ExtraFilters } from '.';
import { AuctionScheduleContext } from '..';
import { auctionScheduleAtom } from 'Store/auctionAtom';
import { useRecoilState } from 'recoil';
import { DELETE_AUCTION_SCHEDULES, DELETE_AUCTION_SCHEDULE_SETTINGS, DELETE_AUCTION } from 'Util/mutations'
import { useMutation } from '@apollo/client';
import moment from 'moment'
import RecurringModalOption from '../RecurringModalOption';

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const MenuButtonContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

function TableFilters(props) {

    const { filter, setFilter } = useContext(AuctionScheduleContext)
    const [loading, setLoading] = useState(false)
    const [selectedSchedule, setSelectedSchedule] = useRecoilState(auctionScheduleAtom)
    const [modalVisible, setModalVisible] = useState(false);
    const [action, setAction] = useState('edit')
    const history = useHistory()

    const [deleteSchedule] = useMutation(DELETE_AUCTION_SCHEDULES, {
        onCompleted() {
            notification['success']({
                message: 'Deleted!',
                description: 'Schedule deleted!',
            });
            setSelectedSchedule({})
        }
    });

    const [deleteScheduleSettings] = useMutation(DELETE_AUCTION_SCHEDULE_SETTINGS, {
        onCompleted() {
            notification['success']({
                message: 'Deleted!',
                description: 'Schedule deleted!',
            });
            setSelectedSchedule({})
        }
    });

    const [deleteAuction] = useMutation(DELETE_AUCTION);

    const deleteRecord = () => {

        !selectedSchedule.recurring && deleteAuction({
            variables: {
                id: selectedSchedule.auction_number 
            }
        })
        
        !selectedSchedule.recurring && deleteSchedule({
            variables: {
                where: { id: { _eq: selectedSchedule.id } }
            }
        })
    }

    const handleModalOpen = (action) => {
        if(selectedSchedule.id){
            if(selectedSchedule.recurring){
                setModalVisible(true);
                setAction(action)
            }else{
                history.push(`schedules/edit/${selectedSchedule.id}`)
            }        
        }
    };
  
    const handleModalClose = () => {
      setModalVisible(false);
    };
  
    const handleModalOk = (selectedOption) => {
      handleModalClose();
    };
    
    return (
        <Container>
            <DropdownContainer>
                <label>Auction: </label>
                <Select defaultValue={filter.status}
                    style={{ width: 120 }}
                    bordered={false}
                    onChange={v => { setFilter({ ...filter, status: v }) }}
                >
                    <Option key="dp-auc-all" value="all">All</Option>
                    <Option key="dp-auc-closed" value="closed">Closed</Option>
                    <Option key="dp-auc-prebid" value="prebid">Pre Bid</Option>
                    <Option key="dp-auc-results" value="results">Results</Option>
                    <Option key="dp-auc-counter" value="counter">Counter</Option>
                    <Option key="dp-auc-scheduled" value="scheduled">Scheduled</Option>
                </Select>
            </DropdownContainer>

            <DropdownContainer>
                <label>Sort: </label>
                <Select defaultValue={filter.sort}
                    value={filter.sort === "bydate" ? "By Date" : filter.sort}
                    style={{ width: 120 }}
                    bordered={false}
                    onChange={v => { 
                        setFilter({ ...filter, sort: v, date: null })             
                    }}
                >
                    <Option key="today" value="today">Today</Option>
                    <Option key="week" value="week">+7 Days</Option>
                    <Option key="month" value="month">+30 Days</Option>
                </Select>
            </DropdownContainer>

            <SearchContainer>
                <DatePicker defaultValue={filter.date} value={filter.date} onChange={v => setFilter({ ...filter, date: v, sort: 'bydate' })} />
            </SearchContainer>

            <MenuButtonContainer>
                <Link to={'schedules/new'} ><PlusOutlined /></Link>
                <label> New </label>
            </MenuButtonContainer>

            <MenuButtonContainer>
                <span onClick={() => handleModalOpen('edit')}>
                    <EditOutlined /> <label> Edit </label>
                </span>
            </MenuButtonContainer>

            <RecurringModalOption
                visible={modalVisible}
                onCancel={handleModalClose}
                onOk={handleModalOk}
                scheduleId = {selectedSchedule?.id}
                scheduleDate = {selectedSchedule?.date}
                action={action}      
            />
                
            <MenuButtonContainer>
                {
                    !selectedSchedule.recurring ? <Popconfirm
                        title="Are you sure to delete this schedule?"
                        onConfirm={deleteRecord}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteFilled /> <label> Delete </label>
                    </Popconfirm> : 
                        <span onClick={() => handleModalOpen('delete')}>
                            <DeleteFilled /> <label> Delete </label>
                        </span>
                }
            </MenuButtonContainer>

            <ExtraFilters data={props.data} columns={props.columns}/>

        </Container>
    )
}

TableFilters.propTypes = {}

export default TableFilters
