import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { DeleteFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Button, Space, Tooltip } from 'antd';
import _ from 'lodash'
import { selectedSpecies } from "Store/procurementAtom";
import { useRecoilState } from 'recoil';
import { useMutation } from '@apollo/client';
import { UPDATE_SPECIES } from 'Util/mutations'

import moment from 'moment'

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;


function DeleteVendor(props) {
    const [species, setSelectedSpecies] = useRecoilState(selectedSpecies);

    const [deleteProduct] = useMutation(UPDATE_SPECIES);
  
    const confirmDelete = () => {  
      deleteProduct({
        variables: {
          id: species.id,
          set: {
            deleted_at: moment()
          }
        }
      })
    }

    const confirm = () => {

        Object.values(species).length > 0 && Modal.confirm({
            centered: true,
            icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
            content: `Delete ${species.label}?`,
            autoFocusButton: 'ok',
            okButtonProps: {
                style: { backgroundColor: THEME.colors.primary }
            },
            onOk: confirmDelete
        });
    };

    return (
        <Container>
            <Tooltip title={species.label}>
                <DeleteFilled onClick={confirm} />
                <label>Delete</label>
            </Tooltip>
        </Container>
    )
}

DeleteVendor.propTypes = {}

export default DeleteVendor