import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import Search from './Search';
import ExtraFilter from './ExtraFilter';

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

function TableFilter({data, column, setSearch}) {

    return (
        <Container>
            <Search setSearch={setSearch}/>
            <ExtraFilter data={data} column={column}/>
        </Container>
    )
}

TableFilter.propTypes = {
    data: PropTypes.array,
    column: PropTypes.array,
    setSearch: PropTypes.func
}

export default TableFilter
