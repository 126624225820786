import { atom } from "recoil";

export const loginAtom = atom({
    key: "login",
    default: {
        err: '',
        qr: '',
        visible: '',
        input: {
            user: '',
            password: '',
            otp: ''
        }
    }
})
