import { appConstant, LogisticsJobStatus, THEME } from "Util/constants";
import {
  Form,
  Input,
  notification,
  DatePicker,
  TimePicker,
  Select
} from "antd";
import { createContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import { useLazyQuery, useMutation } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import PhoneInput from 'Components/PhoneValidator';
import { UPSERT_JOBS, UPDATE_PURCHASE_ORDER_JOB_ID } from "Util/mutations";
import { GET_JOBS } from "Util/queries";
import TextArea from "antd/lib/input/TextArea";
import { useHistory } from "react-router-dom";
import { CardHeader } from "./components/JobCard";
import PurchaseOrderItems from './components/JobCard/PurchaseOrderItems';
import moment from "moment";
import DepotsDropDown from "Components/DepotsDropdown";
import ShippersDropDown from "Components/ShippersDropdown";
import { addOrder, updateOrder, getOrder } from 'Util/trackpod'
import slugify from 'slugify'

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

export const JobCardContext = createContext()

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function JobCard(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  const editMode = routeLocation.pathname.includes("/logistics/jobs/edit");
  const [formLoading, setFormLoading] = useState(false);
  const [initialPo, setInitialPo] = useState([])
  const [purchaseOrderList, setPurchaseOrderList] = useState({})

  const [getJob, { data }] =
    useLazyQuery(GET_JOBS, {
      fetchPolicy: "network-only",
      onError(error) {
        console.log('error', error)
      },
      onCompleted(data) {
        console.log('dataq', data.jobs[0]);
        const jobDetails = data.jobs[0]

        form.setFieldsValue({
          ...jobDetails,
          date: moment(jobDetails.date).tz('Asia/Kolkata'),
          job_time: (jobDetails.time_from && jobDetails.time_from) ? [
            moment(jobDetails.time_from, 'hh:mm:ssZ').tz('Asia/Kolkata'),
            moment(jobDetails.time_to, 'hh:mm:ssZ').tz('Asia/Kolkata')
          ] : null,
          depot_name: jobDetails.depot?.name,
          shipper_name: jobDetails.shipper?.name,
        })
        setInitialPo(jobDetails.purchase_orders.map(item => item.id))
      },
    });

  const [update] = useMutation(UPSERT_JOBS)
  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER_JOB_ID)

  useEffect(() => {
    if (editMode) {
      console.log('querying...')
      getJob({ variables: { where: { id: { _eq: params.id } } }, });
    }
  }, [editMode, getJob, params.id]);

  const submitHandler = async (values) => {

    setFormLoading(true)

    const { purchase_orders, job_time, shipper_name, depot_name, ...jobDetails } = values;
    const timeFrom = job_time?.[0] ? moment(job_time[0]).tz('Asia/Kolkata').format('hh:mm:ssZ') : null;
    const timeTo = job_time?.[1] ? moment(job_time[1]).tz('Asia/Kolkata').format('hh:mm:ssZ') : null;

    const jobObjects = {
      ...jobDetails,
      time_from: timeFrom,
      time_to: timeTo,
      date: moment(values.date).format(appConstant.dateFormat)
    };

    values.date = moment(values.date).format(appConstant.dateFormat)

    const originalSet = new Set(initialPo);
    let updatedSet;
    let missingIds;
    let addedIds;
    let jobId;
    let track_id = null

    try {
      const { data: jobData } = await update({
        variables: {
          update_columns: ["id", ...Object.keys(jobObjects)],
          objects: [jobObjects]
        }
      });

      jobId = jobData.insert_jobs.returning[0].id

      if (purchase_orders) {
        updatedSet = new Set(purchase_orders);
        missingIds = initialPo.filter(id => !updatedSet.has(id));
        addedIds = purchase_orders.filter(id => !originalSet.has(id));

        if (missingIds && missingIds.length > 0) {
          updatePurchaseOrder({
            variables: {
              where: { id: { _in: missingIds } },
              _set: { job_id: null }
            }
          })
        }

        if (addedIds && addedIds.length > 0) {
          updatePurchaseOrder({
            variables: {
              where: { id: { _in: addedIds } },
              _set: { job_id: jobId }
            }
          })
        }

      }

      notification.success({
        message: "Saved!",
        description: "Job Saved!",
      });
    } catch (error) {
      console.log('error', error);
      notification.error({
        message: "Error",
        description: "Something went wrong while saving.",
      });
    } finally {
      setFormLoading(false);
    }

    const goods = purchaseOrderList.map(order => ({
      GoodsId: order.bid?.product.sku ?? order.direct_offer_order.direct_offer.species_sku,
      GoodsName: order.bid?.product.label ?? order.direct_offer_order.direct_offer.species_name,
      GoodsUnit: "KG",
      Quantity: order.bid?.volume ?? order.direct_offer_order.order_quantity,
      Cost: order.bid?.price ?? order.direct_offer_order.direct_offer.price
    }))

    const addObject = {
      "Number": jobId.toString(),
      "Id": jobId.toString(),
      "Date": values.date,
      "Type": values.type,
      "Shipper": values.shipper_name,
      "ShipperId": values.shipper_id.toString(),
      "Depot": values.depot_name,
      "Client": values.client,
      "Address": values.address,
      "TimeSlotFrom": values.time_from,
      "TimeSlotTo": values.time_to,
      "ServiceTime": values.service_time,
      "Note": values.note,
      "ContactName": values.contact_name,
      "Phone": values.phone,
      "Email": values.email,
      "Barcode": values.barcode,
      "DepotId": values.depot_id.toString(),
      "GoodsList": goods
    }

    if (editMode) {
      try {
        const res = await updateOrder(addObject)
        const { Detail, Status, Title } = res
        console.log({ Detail, Status, Title });

        if (Status !== 202) {
          notification["error"]({
            message: Title,
            description: Detail,
          });
          setFormLoading(false)
          history.goBack()
          return
        }
      } catch (error) {
        console.log('errorerrorerror', error);
        if (error.response) {
          console.error('Error status code:', error.response.status);
          if (error.response.status === 405) {
            console.error('Error 405: Method Not Allowed');
          } else if (error.response.status === 500) {
            console.error('Error 500: Internal Server Error');
          }
        } else {
          console.error('Error occurred:', error);
        }
        notification["error"]({
          message: "Something went wrong!",
          description: "Please try again later.",
        });
        setFormLoading(false)
        history.goBack()
        return
      }

    } else {
      try {
        const res = await addOrder(addObject)
        const { Detail, Status, Title } = res
        console.log({ Detail, Status, Title });

        if (Status !== 201) {
          notification["error"]({
            message: Title,
            description: Detail,
          });
          setFormLoading(false)
          history.goBack()
          return
        }

        const getObject = { "Number": jobId };
        const getRes = await getOrder(getObject)
        track_id = getRes.TrackId

        const jobObjects = {
          ...jobDetails,
          id: jobId,
          track_id
        };

        await update({
          variables: {
            update_columns: ["id", ...Object.keys(jobObjects)],
            objects: [jobObjects]
          }
        });

      } catch (error) {
        console.log('errorerrorerror', error);
        if (error.response) {
          console.error('Error status code:', error.response.status);
          if (error.response.status === 405) {
            console.error('Error 405: Method Not Allowed');
          } else if (error.response.status === 500) {
            console.error('Error 500: Internal Server Error');
          }
        } else {
          console.error('Error occurred:', error);
        }
        notification["error"]({
          message: "Something went wrong!",
          description: "Please try again later.",
        });
        setFormLoading(false)
        history.goBack()
        return
      }
    }

    setFormLoading(false)
    history.goBack()

  };


  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const setFieldValue = (key, value) => {
    if (!Array.isArray(key)) form.setFieldsValue({ ...form.getFieldsValue(), [key]: value })
    else form.setFieldsValue({
      ...form.getFieldsValue(),
      [key[0]]: {
        ...form.getFieldsValue()?.[key[0]],
        [key[1]]: value
      }
    })
  }

  return (
    <JobCardContext.Provider value={{ purchaseOrderList, setPurchaseOrderList }}>
      <CardHeader editMode={editMode} />
      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="Job Details">
          <div className="division">

            <Form.Item label="Job ID No." name="id">
              <Input disabled placeholder="Auto generated" />
            </Form.Item>

            <Form.Item label="Date" name="date">
              <DatePicker format="DD MMM YYYY" style={{ width: '100%' }} disabled={editMode} />
            </Form.Item>

            <Form.Item label="Client" name="client" rules={[{ required: true, message: '' }]}>
              <Input disabled={editMode} />
            </Form.Item>

            <Form.Item label="Address" name="address" rules={[{ required: true, message: '' }]}>
              <TextArea rows={2} disabled={editMode} />
            </Form.Item>

            <Form.Item label="Contact No." name="phone">
              <PhoneInput />
            </Form.Item>

            <Form.Item label="Contact Name" name="contact_name">
              <Input />
            </Form.Item>

            <Form.Item label="Job Time" name="job_time">
              <TimePicker.RangePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Service Time (Mins)" name="service_time">
              <Input />
            </Form.Item>

            <Form.Item label="Notify Email" name="email">
              <Input />
            </Form.Item>

            <Form.Item label="Instructions (Note)" name="note">
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item label="Primary Job Status" name="job_status" initialValue={'unscheduled'}>
              <Select options={LogisticsJobStatus} />
            </Form.Item>

            <Form.Item label="Shipper" name="shipper_id" rules={[{ required: true, message: '' }]}>
              <ShippersDropDown form={form} />
            </Form.Item>

            <Form.Item name="shipper_name" hidden>
              <Input />
            </Form.Item>

            <Form.Item label="Type" name="type" initialValue={routeLocation.pathname.includes("collection") ? 1 : 0} style={{ display: 'none' }}>
              <Input />
            </Form.Item>
          </div>

          <div className="division">
            <Form.Item label="Tracking No." name="track_id">
              <Input disabled placeholder="Auto generated" />
            </Form.Item>

            <Form.Item label="Barcode" name="barcode" >
              <Input />
            </Form.Item>

            <Form.Item label="Depot/Ship From" name="depot_id" rules={[{ required: true, message: '' }]}>
              <DepotsDropDown form={form} />
            </Form.Item>

            <Form.Item name="depot_name" hidden>
              <Input />
            </Form.Item>

            <Form.Item name="purchase_orders" >
              <PurchaseOrderItems setFieldValue={setFieldValue} jobId={data?.jobs[0]?.id} />
            </Form.Item>

          </div>

        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit" >
            {editMode ? "Update" : "Save"}
          </ButtonLoader>

          <CommonButton className='reverse' onClick={() => history.goBack()}>
            Cancel
          </CommonButton>

        </CommonDiv>
      </Form>
    </JobCardContext.Provider>
  );
}

JobCard.propTypes = {};

export default JobCard;
