import { createContext, useState } from 'react'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { useParams } from 'react-router-dom'
import { DataTable, Menu } from './components'
import { useSubscription } from '@apollo/client'
import { SUB_VEHICLES } from 'Util/subscriptions'
import _ from 'lodash'

/** Page context */
export const LogisticsJobsContext = createContext()

const VehiclesPage = props => {
    const params = useParams();
    const [filters, setFilter] = useState({ type: 'all' })
    const [selectedRecord, setSelectedRecord] = useState({})

    /** status condition */
    const status = !_.isUndefined(params.status) ? { _eq: params.status } : {}

    const { data, loading, error } = useSubscription(SUB_VEHICLES, {
        variables: {
            where: {
                status,
                deleted_at: { _is_null: true }
            }
        }
    });

    const formatData = (data) => {

        if (loading) { return [] }
        if (data?.vehicles) {
            return data.vehicles.map(d => ({
                ...d,
                driver: d.driver.name,
                depot: d.depot.name
            }))
        }
        return []
    }

    return (
        <LogisticsJobsContext.Provider value={{ filters, setFilter, selectedRecord, setSelectedRecord }} >
            <SubPageBreadCrumbs />
            <Menu />
            <DataTable
                data={loading ? [] : formatData(data)}
                loading={loading}
                pageSize={50}
                height={500}
            />
        </LogisticsJobsContext.Provider>
    )
}

VehiclesPage.propTypes = {}

export default VehiclesPage