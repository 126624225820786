import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Popover, Tabs, Typography } from "antd";
import styled, { css } from "styled-components";
import { THEME } from "Util/constants";
import TableFilter from "./TableFilter";
import _ from "lodash";
import { PaperClipOutlined, InfoCircleFilled } from "@ant-design/icons";
import { CRMVendorContext } from "..";
import { useHistory, Link } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { vendorInfoAtom, selectedVendorAtom, vendorSearchAtom } from "Store/crmAtom";
import { useLocation } from 'react-router-dom'
import { infoIconAtom } from "Store/procurementAtom";

const { TabPane } = Tabs;

const Container = styled.div`
	.ant-table-thead {
		.ant-table-cell {
			color: #999;
			font-weight: bold;
		}
	}

    .ant-table-tbody > tr:hover > td {
		background-color: "none";
	}
	.ant-table-tbody > tr.selected > td {
		background-color: rgba(22, 180, 177, 0.5);
	}

`;

const PopContainer = styled.div`
    .ant-tabs-tabpane {
        width: 350px;
        label.title{
            font-weight: bold;
        }
        .data-container{
            .data-item{
                display: flex;
                justify-content: space-between;
                label{
                    color: #555;
                }
                span{
                    color: ${THEME.colors.primary};
                }
            }
        }
    }    
`

const renderColumns =  (setSelectedVendor) => {
    const columns = [
    {
        title: 'Vendor ID',
        dataIndex: 'vendor_id',
        key: 'vendor_id',
        fixed: 'left',
        width: 140,
        render: (_, record) => ( 
            <Link onClick={() => setSelectedVendor(record)} style={{ color: THEME.colors.primary, fontSize: 16 }} to={{pathname: `/crm/vendors/edit/${record.id}`, state: { prevPath: '/crm/vendors' }}} >
                {record.vendor_id}
            </Link>
        ),
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 250
    },
    {
        title: 'Aadhaar ID',
        dataIndex: 'aadhaar_id',
        key: 'aadhaar_id',
        width: 150
    },
    {
        title: 'Mobile#',
        dataIndex: 'mobile',
          key: 'mobile',
        width: 150
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 250
    },
    {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact',
        width: 200
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: 300
    },
    {
        title: 'Location Code',
        dataIndex: 'location_code',
        key: 'location_code',
        width: 140
    },
    {
        title: 'VPA',
        dataIndex: 'vpa',
        key: 'vpa',
        width: 250
    },
    {
        title: 'Vessel Reg',
        dataIndex: 'vessel',
        key: 'vessel',
        width: 200
    },
    ];

    return columns
}

const PopContent = ({ data }) => {
    return (
        <PopContainer>
            <div className="tab">
                <Tabs defaultActiveKey="1" centered>
                    <TabPane
                        className="data-pane"
                        tab={
                            <span>
                                {" "}
                                <InfoCircleFilled /> Details
                            </span>
                        }
                        key="1"
                    >
                        {/* {JSON.stringify(data.data)} */}
                        <label className="title"> Key Vendor Data </label>
                        <div className="data-container">
                            <div className="data-item">
                                {" "}
                                <label>Vendor ID</label>
                                <span>{data.vendor_id}</span>{" "}
                            </div>
                            <div className="data-item">
                                {" "}
                                <label>Status</label>
                                <span style={{ textTransform: "capitalize" }}>
                                    {data.user.status}
                                </span>{" "}
                            </div>
                            <div className="data-item">
                                {" "}
                                <label>Name</label>
                                <span>{data.name}</span>{" "}
                            </div>
                            <div className="data-item">
                                {" "}
                                <label>Sales Value (LCY)</label>
                                <span></span>{" "}
                            </div>
                            <div className="data-item">
                                {" "}
                                <label>Purchase Value (LCY)</label>
                                <span></span>{" "}
                            </div>
                            <div className="data-item">
                                {" "}
                                <label>Refunds</label>
                                <span></span>{" "}
                            </div>
                            <div className="data-item">
                                {" "}
                                <label>Outstanding Invoices</label>
                                <span></span>{" "}
                            </div>
                            <div className="data-item">
                                {" "}
                                <label>Wallet Balance</label>
                                <span></span>{" "}
                            </div>
                        </div>
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <PaperClipOutlined /> Attachements
                            </span>
                        }
                        key="2"
                    >
                        N/A
                    </TabPane>
                </Tabs>
            </div>
        </PopContainer>
    );
};

function DataTable({
    pageSize = 50,
    height = 250,
    data = [],
    loading = false
}) {
    const [selectedUser, setSelectedUser] = useState({});
    const [vendor, setSelectedVendor] = useRecoilState(selectedVendorAtom);
    const [search, setSearch ] = useRecoilState(vendorSearchAtom)
    const [info, setInfo] = useRecoilState(infoIconAtom)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const location = useLocation();
    const [selectedRow, setSelectedRow] = useState({});
    const [visible, setVisible] = useState(false)

    const searchable = data.map((obj, i) => {
        let result = [];
        let concatenatedValues = "";
        renderColumns().forEach(column => {
          if (obj[column.key] && obj[column.key] !== undefined) concatenatedValues += obj[column.dataIndex] + " ";
        });
        result.push(concatenatedValues.trim());
    
        return {
          ...obj,
          searchText:  result.toString()
        }
      })
    
    const filterData = (data) => {
        if(!search){
            return data
        }
        return _.filter(searchable, function (d) { 
            let query = search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    };

    const handleRowDoubleClick = (row) => {
        let visible = selectedRow && selectedRow?.id === row?.id
        if (visible) {
          setVisible(true)
          setSelectedRow(row);
        } else {
          setVisible(false)
          setSelectedRow({});
        }
      };
    
      const handleRowClick = (row) => {
        setVisible(false)
        setSelectedRow(row)
        setSelectedVendor(row);
        console.log('row', row);
      }
    
    useEffect(() => {
        setSelectedVendor({})
        setSelectedRowKeys([])
    }, [location.pathname])
    
    return (
        <Container>
            <TableFilter data={filterData(data)} column={renderColumns()}/>
            <Table
                loading={loading}
                columns={renderColumns(setSelectedVendor)}
                dataSource={filterData(data)}
                onRow={(rec, idx) => ({
                    onClick: (e) => {
                      handleRowClick(rec)
                    },
                    onDoubleClick: (event) => {
                      handleRowDoubleClick(rec);
                    }          
                })}
                pagination={{ defaultPageSize: pageSize }}
                scroll={{ y: height, x: 1500 }}
                rowKey={rec => parseInt(rec.vendor_id, 10)}
                components={{
                    body: {
                        row: (props) => {
                            const row = _.find(filterData(data), { id: props['data-row-key'] })
                            let selected = false;
                            if (!_.isEmpty(vendor) && !_.isEmpty(row)) {
                            selected = row.id === vendor.id;
                            }
                            let rowvis = selectedRow && selectedRow?.id === row?.id
                            const tableRow = (
                            <tr 
                                {...props}
                                style={{ cursor: 'pointer' }} 
                                onClick={props.onClick}
                                className={`ant-table-row ant-table-row-level-0 yeah ${selected ? "selected" : ""}`}
                                onDoubleClick={info.species ? props.onDoubleClick : null}
                            >
                                {props.children}
                            </tr>)

                            return info.vendors && row ? (<Popover 
                                            content={<PopContent data={row} />} 
                                            trigger="click"
                                            visible={rowvis && visible}                                                   
                                            placement="bottom">{tableRow}
                                          </Popover>) : (tableRow)
                        }
                    }
                }}
            />
        </Container>
    )

}

DataTable.propTypes = {
    pageSize: PropTypes.oneOf([10, 20, 50, 100]),
    height: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
};

export default DataTable;
