import { createContext, useState } from 'react'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { useParams } from 'react-router-dom'
import { DataTable, Menu } from './components'
import { useSubscription } from '@apollo/client'
import { SUB_DEPOTS } from 'Util/subscriptions'
import _ from 'lodash'

/** Page context */
export const LogisticsJobsContext = createContext()

const DepotPage = props => {
    const params = useParams();
    const [filters, setFilter] = useState({ type: 'all' })
    const [selectedRecord, setSelectedRecord] = useState({})

    /** status condition */
    const type = !_.isUndefined(params.type) ? { _eq: params.type } : {}

    const { data, loading, error } = useSubscription(SUB_DEPOTS, {
        variables: {
            where: {
                type,
                deleted_at: { _is_null: true }
            }
        }
    });

    const formatData = (data) => {

        if (loading) { return [] }

        if (data?.depots) {            
            return data.depots.map(d => ({
                ...d,
                address: [d.street, d.city, d.state, d.country].filter(Boolean).join(", ")
            }))

        }

        return []

    }

    return (
        <LogisticsJobsContext.Provider value={{ filters, setFilter, selectedRecord, setSelectedRecord }}>
            <SubPageBreadCrumbs />
            <Menu />
            <DataTable
                data={loading ? [] : formatData(data)}
                loading={loading}
                pageSize={50}
                height={500}
            />
        </LogisticsJobsContext.Provider>
    )
}

DepotPage.propTypes = {}

export default DepotPage