import { useSubscription, useMutation } from '@apollo/client';
import { notification, Table, Popconfirm } from 'antd';
import leadingZeroes from 'leading-zeroes';
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import styled from 'styled-components';
import { getDate, getTimeFromDate } from 'Util/common';
import { appConstant } from 'Util/constants';
import { SUB_DIRECT_OFFERS } from 'Util/subscriptions';
import { ACCEPT_DIRECT_OFFER, UPDATE_DIRECT_OFFER } from 'Util/mutations';
import { PopOverDetails } from '../../components';
import { commonColumns, dataSource } from '../../common';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapConstant } from 'Util/constants';
import gmap from 'gmap';
import { DIRECT_OFFER_STATUS } from 'Util/constants';
import moment from 'moment'
import { directSalesFilterAtom } from 'Store/directSalesAtom';
import _ from 'lodash'
import { TableFilters } from './';

const Container = styled.div``

function DataTable() {

    /** Global State */
    const [auctionState, setAuctionState] = useRecoilState(auctionAtom)
    const [filter, setFilter] = useRecoilState(directSalesFilterAtom)
    // const { auctionStatus } = auctionState
    const auctionStatus = 'open'


    /** Local State */
    const [data, setData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    /** GraphQL Hooks */
    const { loading } = useSubscription(SUB_DIRECT_OFFERS, {
        variables: {
            where: {
                active: { _eq: true },
                status: { _in: ['staged', 'staged-withdrawn'] }
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            data && data.direct_offers && setData(data.direct_offers)
        }
    });

    const [updateDirectOffer] = useMutation(UPDATE_DIRECT_OFFER, {
        onCompleted() {
            notification['success']({
                message: "Updated Staging Basket"
            });
        },
        onError(e) { console.log('erre', e) }
    });

    const [acceptDirectOffer] = useMutation(ACCEPT_DIRECT_OFFER, {
        onCompleted() { console.log('Direct offer accepted') },
        onError(e) { console.log('erre', e) }
    });

    /** Functions */
    const onSelectChange = (newSelectedRowKeys, data) => {
        // setSelectedRecord(data)
        setSelectedRowKeys(newSelectedRowKeys);
    };

    useEffect(() => {
        setFilter({ ...filter, search: '' })
    }, [])

    const filterData = (data) => {
        let dSource = dataSource('open', data)
        const searchable = dSource.map(obj=>({
            ...obj,
            searchText: `${ obj.species_sku } ${ obj.species_name } ${ obj.time } ${obj.id} ${obj.preservation_method} ${obj.country_origin} ${obj.avail_quantity} ${obj.min_quantity} ${obj.harvest_method} ${obj.price} ${obj.offer_type} ${obj.vendor} ${obj.location}`
          }))    

        if(!filter.search){
            return dSource
        }
        return _.filter(searchable, function (d) { 
            let query = filter.search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    }

    const handleSubmit = ({ action, data }) => {
        const { id, order_quantity, avail_quantity, user_id, user_info } = data

        /** Buy Offer */
        if (action === 'buy') {

            const link = gmap.directions(mapConstant.pickupCoordinates, `${user_info.user_location.lat},${user_info.user_location.lng}`)

            acceptDirectOffer({
                variables: {
                    objects: {
                        eta: 10, /** TODO: eta as editable */
                        user_id,
                        link,
                        purchase_order: {
                            data: {
                                user_id,
                                status: "pending",
                                direct_offer_order: {
                                    data: {
                                        status: "accepted",
                                        order_quantity,
                                        direct_offer_id: id
                                    }
                                }
                            }
                        }
                    }
                }
            })


            updateDirectOffer({
                variables: {
                    id,
                    _set: {
                        avail_quantity,
                        status: (avail_quantity <= 0) ? DIRECT_OFFER_STATUS.withdrawn : DIRECT_OFFER_STATUS.open,
                        order_quantity: 0
                    }
                }
            })

        }

        /** Witdraw Offer */
        if (action === 'withdraw') {
            const { quantity } = data

            let _set = {
                status: DIRECT_OFFER_STATUS.open,
                order_quantity: null,
                avail_quantity: quantity
            }

            if (data.status === DIRECT_OFFER_STATUS.stagedWithdrawn) {
                _set = {
                    status: DIRECT_OFFER_STATUS.withdrawn
                }
            }

            updateDirectOffer({
                variables: {
                    id: data.id,
                    _set
                }
            })

        }

        /** Edit Offer */
        if (action === 'edit') {
            alert("edit action")
        }

    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        ...commonColumns,
        // {
        //     title: 'Order',
        //     dataIndex: 'order_quantity',
        // },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <div className='direct-offer-action-wrapper' >

                    {/* {record.status !== DIRECT_OFFER_STATUS.stagedWithdrawn && ( */}
                    <PopOverDetails data={record} onSubmit={handleSubmit} >
                        <FontAwesomeIcon
                            size="2x"
                            style={{ "cursor": "pointer" }}
                            icon={faInfoCircle}
                            color='#20B0B5'
                        />
                    </PopOverDetails>
                    {/* )} */}

                </div>
            ),
        }
    ]

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={columns}/>
            <Table
                loading={loading}
                size='small'
                columns={columns}
                dataSource={filterData(data)}
                // rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 50
                }}
            />
        </Container>
    )
}

export default DataTable    