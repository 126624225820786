export const SYSTEM_ADMIN = {
    links: [
        {
            title: "General",
            data: [
                {
                    text: "System", sub: [
                        { text: "System Information" },
                        { text: "Table Information" },
                        { text: "Profile (Roles)", link: 'profile-roles' }
                    ]
                },
                { text: "Application" },
                { text: "Job Queue" },
                { text: "Change Log" },
                { text: "Reporting" },
                { text: "Developer" },
                { text: "Feature Management" },
            ]
        },
        {
            title: "Data",
            data: [
                { text: "Reference Data" },
                { text: "Data Management" },
                { text: "Data Migration" },
                { text: "Data Exchange" },
                { text: "Data Creation" },
                { text: "Data Deletion" },
            ]
        },
        {
            title: "Users",
            data: [
                { text: "Users" },
                { text: "User Groups" },
                { text: "User Setup" },
                { text: "Permission Sets" },
                { text: "User Security Status" },
                { text: "User Tasks" },
                { text: "User Settings" },
                { text: "Time Registers" },
            ]
        },
        {
            title: "Workflow",
            data: [
                { text: "Workflows" },
                { text: "Workflows User Groups" },
                { text: "Approval User Setup" },
                { text: "Approval User Dashboard" },
                { text: "Incoming Documents Setup" },
                { text: "Send Overdue Approval Notifications" },
                { text: "Templates" },
                { text: "Workflow Events" },
                { text: "Notifications" },
                { text: "Entries/Archived" },
            ]
        }
    ]
}