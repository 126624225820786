import React from 'react'
import PropTypes from 'prop-types'
import DashTitleDivider from 'Components/DashTitleDivider'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'

const SettingsPage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            SettingsPage</div>
    )
}

SettingsPage.propTypes = {}

export default SettingsPage