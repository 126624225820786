import React from 'react'
import PropTypes from 'prop-types'
import ButtonCard from 'Components/ButtonCard'
import { useLocation, useParams } from 'react-router-dom'

const data = [
    { link: '/crm/vendors', title: 'Master' },
    { link: '/crm/vendors/verified', title: 'Verified Vendors' },
    { link: '/crm/vendors/pending', title: 'Pending Approval' },
    { link: '/crm/vendors/rejected', title: 'Rejected' },
    { link: '/crm/vendors/blocked', title: 'Blocked Vendors' }
]

function MenuButton(props) {
    const location = useLocation();
    return (
        <div style={{ display: 'flex' }} >
            {data.map(v => (<ButtonCard
                key={v.link}
                link={v.link}
                title={v.title}
                lightBlue
                selected={location.pathname === v.link}
            />))}
        </div>
    )
}

MenuButton.propTypes = {}

export default MenuButton
