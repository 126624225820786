import CommonDiv from 'Components/CommonDiv';
import { THEME } from 'Util/constants';
import moment from 'moment';
import { useContext, useState } from 'react';
import { FaUser } from 'react-icons/fa';

import { LabelInfo } from '../styled';

import { NotificationContext } from './../../NotificationContext';


function NotificationHeaders() {

    const [answered, setAnswered] = useState(false)
    const { data } = useContext(NotificationContext)
    const { title, created_at, category } = data

    return (
        <>
            <CommonDiv direction="row" style={{ borderBottom: `${THEME.colors.gray} solid 1px`, paddingBottom: "5px" }} >
                <LabelInfo>{moment(created_at).format('D MMM YYYY hh:mm a')}</LabelInfo>
                <LabelInfo style={{ margin: "0px 10px" }} > - </LabelInfo>
                <LabelInfo>{category}</LabelInfo>
            </CommonDiv>
            <CommonDiv direction="row" align="center" style={{ borderBottom: `#000 solid 1px`, padding: "5px 0px", marginTop: 10, marginBottom: 40 }} >
                <FaUser color={THEME.colors.gray} style={{ marginRight: 5 }} />
                <LabelInfo className='notification-date' >Admin</LabelInfo>
            </CommonDiv>
        </>
    )
}

export default NotificationHeaders