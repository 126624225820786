import React from 'react'
import PropTypes from 'prop-types'
import ButtonCard from 'Components/ButtonCard'
import { useLocation, useParams } from 'react-router-dom'

const data = [
    { link: '/logistics/vehicles', title: 'All Vehicles' },
    { link: '/logistics/vehicles/fleet', title: 'Vehicle Fleet' },
    { link: '/logistics/vehicles/group', title: 'Vehicle Group' }
]

function MenuButton(props) {
    const location = useLocation();
    return (
        <div style={{ display: 'flex' }} >
            {data.map(v => (<ButtonCard
                key={v.link}
                link={v.link}
                title={v.title}
                lightBlue
                selected={location.pathname === v.link}
            />))}
        </div>
    )
}

MenuButton.propTypes = {}

export default MenuButton
