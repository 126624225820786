import React, { useState } from "react";
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';

const Table = styled.div`
    .thead, .tbody{
        width: 800px;
        padding: 5px;
        span{
            display: inline-block;
            text-align: left;
        }
        .col-1{
            width: 5%;
        }
        .col-2{
            width: 30%;
        }
        .col-3{
            width: 65%;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        .col-1, .col-2{
            color: ${THEME.colors.primary};
        }
    }

    .info {
        margin-left: 40px;
        margin-top: 10px;
        font-weight: bold;
    }
`

function ActivityTimelineTable() {
  const [rows, setRows] = useState([
    { id: 1, group: "2/13/23 1:00 PM", activity: "In-App Message Sent", info: "Message Details" },
    { id: 2, group: "2/13/23 6:00 AM", activity: "In-App Message Received", info: "Message Details" }
  ]);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleExpandedRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <Table>
    <div className='thead' >
        <span className="col-1">  </span>
        <span className="col-2"> Group </span>
        <span className="col-3"> Activity </span>
    </div>
  
    {rows.map((row, index)=>(
          <div key={`row-item-${row.id}`} className='tbody' >
              <span className="col-1" onClick={() => toggleExpandedRow(index)}>{expandedRow === index ? <DownOutlined /> : <RightOutlined />}</span>
              <span className="col-2"> {row.group} </span>
              <span className="col-3"> {row.activity} </span>
              {expandedRow === index && (
              <span className="info">{row.info}</span>
            )}
          </div>
      ))} 
  </Table>
  );
}

export default ActivityTimelineTable;
