import React from 'react'
import PropTypes from 'prop-types'
import ButtonCard from 'Components/ButtonCard'
import { useLocation, useParams } from 'react-router-dom'

const data = [
    { link: '/admin/roles', title: 'Company Hub' },
    { link: '/admin/roles/system-admin', title: 'System Admin' },
    { link: '/admin/roles/finance', title: 'Finance' },
    { link: '/admin/roles/human-resource', title: 'Human Resource' },
    { link: '/admin/roles/processing', title: 'Processing' },
    { link: '/admin/roles/procurement', title: 'Procurement' },
    { link: '/admin/roles/resource', title: 'Resource' },
    { link: '/admin/roles/sales', title: 'Sales' },
    { link: '/admin/roles/logistics', title: 'Logistics' },
    { link: '/admin/roles/warehouse', title: 'Warehouse' }
]

function MenuButton(props) {
    const location = useLocation();
    return (
        <div style={{ display: 'flex' }} >
            {data.map(v => (<ButtonCard
                key={v.link}
                link={v.link}
                title={v.title}
                lightBlue
                selected={location.pathname === v.link}
            />))}
        </div>
    )
}

MenuButton.propTypes = {}

export default MenuButton
