import React from 'react'
import { Route, Switch, Redirect } from "react-router";

/** Layouts */
import {
  MainLayout,
  CommonLayout,
  DefaultLayout,
  BlankLayout
} from 'Layouts'

/**Pages */
import CMS from 'Pages/CMS';
import Auction from 'Pages/Auction/Index';
import Login from 'Pages/AuthPages/Login';
import AuctionMenu from 'Menus/AuctionMenu';
import LiveAuctioPage from 'Pages/Auction/Live';
import CMSUsers from 'Pages/CMS/users';
import CMSLocations from 'Pages/CMS/locations';
import CMSSpecies from 'Pages/CMS/species';
import CMSProducts from 'Pages/CMS/products';
import CMSLanguage from 'Pages/CMS/language';
import AuctionSettings from 'Pages/Auction/Settings';
import ArchivePage from 'Pages/Auction/Archive';
import CMSUsersPending from 'Pages/CMS/users-pending';
import UsersMenu from 'Menus/UsersMenu';
import UsersAdminMenu from 'Menus/UsersAdminMenu';
import CMSUsersRejected from 'Pages/CMS/users-rejected';
import Home from 'Pages/Home';
import SettingsLayout from 'Layouts/SettingsLayout';
import UserManagement from 'Pages/Admin/Settings/UserManagenment/Index';
import RoleManagement from 'Pages/Admin/Settings/UserManagenment/Role';
import ModuleManagement from 'Pages/Admin/Settings/UserManagenment/Module';
import Activate from 'Pages/AuthPages/Activate';
import { AcceptedDirectSales, OpenDirectSales, StagingDirectSales, DirectSaleSettings, WithdrawnDirectSales } from 'Pages/DirectSales';
import DirectSalesMenu from 'Menus/DirectSalesMenu';


import Dashboard from 'Pages/Dashboard/index'
import Procurement from 'Pages/Procurement';

/** logistics routes */
import Logistics from 'Pages/Logistics';
import LogisticsJobs from 'Pages/Logistics/Jobs';
import LogisticsJobsCard from 'Pages/Logistics/Jobs/JobCard';


import ERP from 'Pages/ERP';
import Exchange from 'Pages/Exchange';
import EComm from 'Pages/Ecomm';
import Accounts from 'Pages/Accounts';
import { AdminPage } from 'Pages/Admin';
import Settings from 'Pages/Settings';

import { CRMPage, CRMRetailPages, CRMVendorsPages, CRMWholesalePages, CRMVendorsPagesNewPage } from 'Pages/CRM';
import { CRMAllUsers, NotificationSystem, CRMSegments, CRMTags, CRMPolls } from 'Pages/CRM/Notifications';
import { PushNotificationPage } from 'Pages/CRM/components'
import ProcurementActiveSpecies from 'Pages/Procurement/ActiveSpecies';
import NewSpeciePage from 'Pages/Procurement/Species/NewSpeciePage';
import { AdminHomePage, AdminFinancePage, AdminCashMgtPage, AdminSalePage, AdminProcurementPage, AdminVoucherPage, AdminTaxationPage, AdminRolesPage } from 'Pages/Admin'
import { AdminRolesSystemAdminProfileRoles, AdminRolesSystemAdminProfileRolesCard, AdminRolesSystemAdminUsersCard, AdminRolesSystemAdminUserGroups, AdminRolesSystemAdminUsers, ApprovalUserDashboard } from 'Pages/Admin/Roles/SystemAdmin';
import { CollectionLocationCard, CollectionPoints, CollectionPointsQuotas } from 'Pages/Procurement/CollectionPoints';
import ActiveProductList from 'Pages/ProductList/ActiveProducts';
import NewProductPage from 'Pages/ProductList/Products/NewProductPage';

import { AuctionQuotas, AuctionSystem, AuctionLive, AuctionData, AuctionSchedule, NewAuctionSchedule, AuctionQuotaEdit } from 'Pages/Procurement/Auction'
import { DirectSales, DirectSalesStagingBasket, DirectSalesSettings, DirectSalesSpecies, NewDirectSelectionList, DirectSelectionList, DirectSalesPO } from 'Pages/Procurement/DirectSales'

import TestPage from 'Pages/Test';
import NewPollsPage from 'Pages/CRM/Notifications/Polls/NewPollsPage';
import PollDetailsPage from 'Pages/CRM/Notifications/Polls/PollsDetailsPage';
import { PurchaseOrdersData } from 'Pages/Procurement/PurchaseOrders';
import PurchaseOrderPage from 'Pages/Procurement/PurchaseOrders/Data/PurchaseOrderPage';
import VehiclesPage from 'Pages/Logistics/Vehicle';
import DriversPage from 'Pages/Logistics/Drivers';
import DriverCard from 'Pages/Logistics/Drivers/DriverCard';
import VehicleCard from 'Pages/Logistics/Vehicle/VehicleCard';
import LogisticsRoutes from 'Pages/Logistics/Routes';
import RouteCard from 'Pages/Logistics/Routes/RoutesCard';
import DepotPage from 'Pages/Logistics/Depot';
import DepotCard from 'Pages/Logistics/Depot/DepotCard';
import ShippersPage from 'Pages/Logistics/Shippers';
import ShippersCard from 'Pages/Logistics/Shippers/ShippersCard';
import CarriersPage from 'Pages/Logistics/Carriers';
import CarriersCard from 'Pages/Logistics/Carriers/CarriersCard';


/** Route Router with Authentication */
const AppRoute = ({ component: Component, layout: Layout, title, menu, pub = false, ...rest }) => {

  /** Authentication filter */
  // if (rest.path == '/login' && localStorage.getItem('token')) {
  //   return <Redirect to="/" />
  // }

  // if (rest.path != '/login' && !localStorage.getItem('token')) {
  //   return <Redirect to="/login" />
  // }

  if (!pub && !localStorage.getItem('token')) {
    return <Redirect to="/login" />
  }

  return <Route {...rest} render={props => (
    <Layout pageTitle={title} menu={menu} >
      <Component {...props} />
    </Layout>
  )} />

}

function Routes() {
  return (
    <Switch>
      {/* <AppRoute exact path="/" layout={MainLayout} title="Dashboard" component={Dashboard} /> */}
      {/* <AppRoute exact path="/" layout={MainLayout} title="Dashboard" component={AuctionMenu} /> */}

      {/* Dasboard */}
      <AppRoute exact path="/" layout={DefaultLayout} title="Dashboard" component={Dashboard} />

      {/* Procurement */}
      <AppRoute exact path="/procurement" layout={DefaultLayout} title="Procurement" component={Procurement} />
      <AppRoute exact path="/procurement/species-database/" layout={DefaultLayout} title="Procurement" component={ProcurementActiveSpecies} />
      <AppRoute exact path="/procurement/species-database/:type" layout={DefaultLayout} title="Procurement" component={ProcurementActiveSpecies} />
      <AppRoute exact path="/procurement/species-database/new/:type" layout={BlankLayout} title="Procurement" component={NewSpeciePage} />
      <AppRoute exact path="/procurement/species-database/new/edit/:id" layout={BlankLayout} title="Procurement" component={NewSpeciePage} />


      <AppRoute exact path="/procurement/collection-points/" layout={DefaultLayout} title="Procurement" component={CollectionPoints} />
      <AppRoute exact path="/procurement/collection-points/quotas" layout={DefaultLayout} title="Procurement" component={CollectionPointsQuotas} />
      <AppRoute exact path="/procurement/collection-points/:type" layout={DefaultLayout} title="Procurement" component={CollectionPoints} />

      <AppRoute exact path="/procurement/collection-points/:type/new" layout={BlankLayout} title="Procurement" component={CollectionLocationCard} />
      <AppRoute exact path="/procurement/collection-points/edit/:id" layout={BlankLayout} title="Procurement" component={CollectionLocationCard} />

      {/* Procurement Product list*/}
      <AppRoute exact path="/procurement/product-list/" layout={DefaultLayout} title="Product list" component={ActiveProductList} />
      <AppRoute exact path="/procurement/product-list/:type" layout={DefaultLayout} title="Product list" component={ActiveProductList} />
      <AppRoute exact path="/procurement/product-list/new/:type" layout={BlankLayout} title="Product list" component={NewProductPage} />
      <AppRoute exact path="/procurement/product-list/new/edit/:id" layout={BlankLayout} title="Product list" component={NewProductPage} />

      {/* Procurement Auctions*/}
      <AppRoute exact path="/procurement/auction" layout={DefaultLayout} title="Procurement Auctions" component={AuctionSystem} />

      <AppRoute exact path="/procurement/auction/schedules" layout={DefaultLayout} title="Procurement Auctions" component={AuctionSchedule} />
      <AppRoute exact path="/procurement/auction/schedules/new" layout={BlankLayout} title="Procurement Auctions New" component={NewAuctionSchedule} />
      <AppRoute exact path="/procurement/auction/schedules/edit/:id" layout={BlankLayout} title="Procurement Auctions Edit" component={NewAuctionSchedule} />

      <AppRoute exact path="/procurement/auction/live" layout={DefaultLayout} title="Procurement Auctions" component={AuctionLive} />
      <AppRoute exact path="/procurement/auction/data" layout={DefaultLayout} title="Procurement Auctions" component={AuctionData} />

      <AppRoute exact path="/procurement/auction/quotas" layout={DefaultLayout} title="Procurement Auctions Quotas" component={AuctionQuotas} />
      <AppRoute exact path="/procurement/auction/quotas/edit" layout={BlankLayout} title="Procurement Auctions Quotas Edit" component={AuctionQuotaEdit} />

      {/* Procurement Direct Sales*/}
      <AppRoute exact path="/procurement/direct-sales" layout={DefaultLayout} title="Direct Sales" component={DirectSales} />
      <AppRoute exact path="/procurement/direct-sales/selection-list" layout={DefaultLayout} title="Direct Sales" component={DirectSelectionList} />
      <AppRoute exact path="/procurement/direct-sales/offers" layout={DefaultLayout} title="Direct Sales" component={DirectSales} />
      <AppRoute exact path="/procurement/direct-sales/species" layout={DefaultLayout} title="Direct Sales" component={DirectSalesSpecies} />
      <AppRoute exact path="/procurement/direct-sales/staging-basket" layout={DefaultLayout} title="Direct Sales" component={DirectSalesStagingBasket} />
      <AppRoute exact path="/procurement/direct-sales/purchase-orders" layout={DefaultLayout} title="Direct Sales" component={DirectSalesPO} />
      <AppRoute exact path="/procurement/direct-sales/settings" layout={DefaultLayout} title="Direct Sales" component={DirectSalesSettings} />

      {/* Procurement Purchase Orders*/}
      <AppRoute exact path="/procurement/purchase-orders/data" layout={DefaultLayout} title="Direct Sales" component={PurchaseOrdersData} />
      <AppRoute exact path="/procurement/purchase-orders/edit/:id" layout={BlankLayout} title="CRM" component={PurchaseOrderPage} />

      <AppRoute exact path="/procurement/direct-sales/new/new-selection-list" layout={BlankLayout} title="Direct Sales" component={NewDirectSelectionList} />

      {/* Logistics */}
      <AppRoute exact path="/logistics" layout={DefaultLayout} title="Logistics" component={Logistics} />
      <AppRoute exact path="/logistics/jobs" layout={DefaultLayout} title="Logistics" component={LogisticsJobs} />
      <AppRoute exact path="/logistics/jobs/:type" layout={DefaultLayout} title="Logistics" component={LogisticsJobs} />
      <AppRoute exact path="/logistics/jobs/new/:type" layout={BlankLayout} title="Logistics" component={LogisticsJobsCard} />
      <AppRoute exact path="/logistics/jobs/edit/:id" layout={BlankLayout} title="Logistics" component={LogisticsJobsCard} />
      <AppRoute exact path="/logistics/vehicles" layout={DefaultLayout} title="Logistics" component={VehiclesPage} />
      <AppRoute exact path="/logistics/vehicles/:status" layout={DefaultLayout} title="Logistics" component={VehiclesPage} />
      <AppRoute exact path="/logistics/vehicles/new/item" layout={BlankLayout} title="Logistics" component={VehicleCard} />
      <AppRoute exact path="/logistics/vehicles/edit/:id" layout={BlankLayout} title="Logistics" component={VehicleCard} />
      <AppRoute exact path="/logistics/drivers" layout={DefaultLayout} title="Logistics" component={DriversPage} />
      <AppRoute exact path="/logistics/drivers/:status" layout={DefaultLayout} title="Logistics" component={DriversPage} />
      <AppRoute exact path="/logistics/drivers/new/item" layout={BlankLayout} title="Logistics" component={DriverCard} />
      <AppRoute exact path="/logistics/drivers/edit/:id" layout={BlankLayout} title="Logistics" component={DriverCard} />
      <AppRoute exact path="/logistics/routes" layout={DefaultLayout} title="Logistics" component={LogisticsRoutes} />
      <AppRoute exact path="/logistics/routes/:status" layout={DefaultLayout} title="Logistics" component={LogisticsRoutes} />
      <AppRoute exact path="/logistics/routes/edit/:id" layout={BlankLayout} title="Logistics" component={RouteCard} />
      <AppRoute exact path="/logistics/routes/new/route" layout={BlankLayout} title="Logistics" component={RouteCard} />
      <AppRoute exact path="/logistics/depots" layout={DefaultLayout} title="Logistics" component={DepotPage} />
      <AppRoute exact path="/logistics/depots/new/item" layout={BlankLayout} title="Logistics" component={DepotCard} />
      <AppRoute exact path="/logistics/depots/edit/:id" layout={BlankLayout} title="Logistics" component={DepotCard} />
      <AppRoute exact path="/logistics/depots/:type" layout={DefaultLayout} title="Logistics" component={DepotPage} />
      <AppRoute exact path="/logistics/shippers" layout={DefaultLayout} title="Logistics" component={ShippersPage} />
      <AppRoute exact path="/logistics/shippers/new/item" layout={BlankLayout} title="Logistics" component={ShippersCard} />
      <AppRoute exact path="/logistics/shippers/edit/:id" layout={BlankLayout} title="Logistics" component={ShippersCard} />
      <AppRoute exact path="/logistics/carriers" layout={DefaultLayout} title="Logistics" component={CarriersPage} />
      <AppRoute exact path="/logistics/carriers/new/item" layout={BlankLayout} title="Logistics" component={CarriersCard} />
      <AppRoute exact path="/logistics/carriers/edit/:id" layout={BlankLayout} title="Logistics" component={CarriersCard} />

      {/* ERP */}
      <AppRoute exact path="/erp" layout={DefaultLayout} title="ERP" component={ERP} />

      {/* CRM */}
      <AppRoute exact path="/crm" layout={DefaultLayout} title="CRM" component={CRMPage} />

      <AppRoute exact path="/crm/vendors/new/:type" layout={BlankLayout} title="CRM" component={CRMVendorsPagesNewPage} />
      <AppRoute exact path="/crm/vendors/edit/:id" layout={BlankLayout} title="CRM" component={CRMVendorsPagesNewPage} />

      <AppRoute path="/crm/vendors/:status" layout={DefaultLayout} title="CRM" component={CRMVendorsPages} />
      <AppRoute path="/crm/vendors" layout={DefaultLayout} title="CRM" component={CRMVendorsPages} />

      <AppRoute path="/crm/wholesale" layout={DefaultLayout} title="CRM" component={CRMWholesalePages} />
      <AppRoute path="/crm/retail" layout={DefaultLayout} title="CRM" component={CRMRetailPages} />

      <AppRoute exact path="/crm/notifications" layout={DefaultLayout} title="CRM" component={NotificationSystem} />
      <AppRoute exact path="/crm/notifications/all-users" layout={DefaultLayout} title="CRM" component={CRMAllUsers} />
      <AppRoute exact path="/crm/notifications/segments" layout={DefaultLayout} title="CRM" component={CRMSegments} />
      <AppRoute exact path="/crm/notifications/tags" layout={DefaultLayout} title="CRM" component={CRMTags} />
      <AppRoute exact path="/crm/notifications/polls" layout={DefaultLayout} title="CRM" component={CRMPolls} />
      <AppRoute exact path="/crm/notifications/new/:group_id" layout={BlankLayout} title="CRM" component={PushNotificationPage} />
      <AppRoute exact path="/crm/notifications/polls/new" layout={BlankLayout} title="CRM" component={NewPollsPage} />
      <AppRoute exact path="/crm/notifications/polls/details/:id" layout={BlankLayout} title="CRM" component={PollDetailsPage} />

      {/* Exchange */}
      <AppRoute exact path="/exchange" layout={DefaultLayout} title="Exchange" component={Exchange} />

      {/* E-comm */}
      <AppRoute exact path="/e-comm" layout={DefaultLayout} title="E-Commerce" component={EComm} />

      {/* Accounts */}
      <AppRoute exact path="/accounts" layout={DefaultLayout} title="Accounts" component={Accounts} />

      {/* Admin */}
      <AppRoute exact path="/admin" layout={DefaultLayout} title="Admin" component={AdminPage} />
      <AppRoute exact path="/admin/home" layout={DefaultLayout} title="Admin Home" component={AdminHomePage} />
      <AppRoute exact path="/admin/finance" layout={DefaultLayout} title="Admin Finance" component={AdminFinancePage} />
      <AppRoute exact path="/admin/cash-management" layout={DefaultLayout} title="Admin Cash Management" component={AdminCashMgtPage} />
      <AppRoute exact path="/admin/sales" layout={DefaultLayout} title="Admin Sales" component={AdminSalePage} />
      <AppRoute exact path="/admin/procurement" layout={DefaultLayout} title="Admin Procurement" component={AdminProcurementPage} />
      <AppRoute exact path="/admin/voucher-interface" layout={DefaultLayout} title="Admin Voucher Interface" component={AdminVoucherPage} />
      <AppRoute exact path="/admin/taxation" layout={DefaultLayout} title="Admin Taxation" component={AdminTaxationPage} />


      <AppRoute exact path="/admin/roles/system-admin/users" layout={BlankLayout} title="Admin Roles Users" component={AdminRolesSystemAdminUsers} />
      <AppRoute exact path="/admin/roles/system-admin/users/new" layout={BlankLayout} title="Admin Roles Users New" component={AdminRolesSystemAdminUsersCard} />
      <AppRoute exact path="/admin/roles/system-admin/users/edit/:id" layout={BlankLayout} title="Admin Roles Users Edit" component={AdminRolesSystemAdminUsersCard} />

      <AppRoute exact path="/admin/roles/system-admin/user-groups" layout={BlankLayout} title="Admin Roles User Groups" component={AdminRolesSystemAdminUserGroups} />

      <AppRoute exact path="/admin/roles/system-admin/profile-roles" layout={BlankLayout} title="Admin Profile Roles " component={AdminRolesSystemAdminProfileRoles} />
      <AppRoute exact path="/admin/roles/system-admin/profile-roles/new" layout={BlankLayout} title="Admin Profile Roles New " component={AdminRolesSystemAdminProfileRolesCard} />
      <AppRoute exact path="/admin/roles/system-admin/profile-roles/edit/:id" layout={BlankLayout} title="Admin Profile Edit " component={AdminRolesSystemAdminProfileRolesCard} />

      <AppRoute exact path="/admin/roles/system-admin/approval-user-dashboard" layout={BlankLayout} title="Admin Approval User Dashboard" component={ApprovalUserDashboard} />


      <AppRoute path="/admin/roles/:page" layout={DefaultLayout} title="Admin Roles" component={AdminRolesPage} />
      <AppRoute path="/admin/roles" layout={DefaultLayout} title="Admin Roles" component={AdminRolesPage} />

      {/* Settings */}
      <AppRoute exact path="/settings" layout={DefaultLayout} title="Settings" component={Settings} />


      <AppRoute exact pub path="/login" layout={CommonLayout} component={Login} />
      <AppRoute exact pub path="/activate/:reset_token" layout={CommonLayout} component={Activate} />

      {/* <AppRoute exact path="/auction" layout={MainLayout} title={ 'Auction Center' } menu={AuctionMenu} component={Auction} /> */}
      <AppRoute exact path="/auction" layout={MainLayout} title={'Live Auction'} menu={AuctionMenu} component={LiveAuctioPage} />
      <AppRoute exact path="/auction/settings" layout={MainLayout} title={'Auction Settings'} menu={AuctionMenu} component={AuctionSettings} />
      <AppRoute exact path="/auction/archive" layout={MainLayout} title={'Auction Archive'} menu={AuctionMenu} component={ArchivePage} />

      <AppRoute exact path="/direct-sales" layout={MainLayout} title={'Direct Sales | Open'} menu={DirectSalesMenu} component={OpenDirectSales} />
      <AppRoute exact path="/direct-sales/staging" layout={MainLayout} title={'Direct Sales | Staging basket'} menu={DirectSalesMenu} component={StagingDirectSales} />
      <AppRoute exact path="/direct-sales/accepted" layout={MainLayout} title={'Direct Sales | Accepted'} menu={DirectSalesMenu} component={AcceptedDirectSales} />
      <AppRoute exact path="/direct-sales/withdrawn" layout={MainLayout} title={'Direct Sales | Withdrawn'} menu={DirectSalesMenu} component={WithdrawnDirectSales} />
      <AppRoute exact path="/direct-sales/settings" layout={MainLayout} title={'Direct Sales | Settings'} menu={DirectSalesMenu} component={DirectSaleSettings} />

      {/* CMS */}
      <AppRoute exact path="/cms/users" layout={MainLayout} title="Content Manangement Users" menu={UsersMenu} component={CMSUsers} />
      <AppRoute exact path="/cms/users/pending" layout={MainLayout} title="Content Manangement Users - Pending" menu={UsersMenu} component={CMSUsersPending} />
      <AppRoute exact path="/cms/users/rejected" layout={MainLayout} title="Content Manangement Users - Rejected" menu={UsersMenu} component={CMSUsersRejected} />
      <AppRoute exact path="/cms/locations" layout={MainLayout} title="Content Manangement Locations" component={CMSLocations} />
      <AppRoute exact path="/cms/species" layout={MainLayout} title="Content Manangement Species" component={CMSSpecies} />
      <AppRoute exact path="/cms/products" layout={MainLayout} title="Content Manangement Products" component={CMSProducts} />
      <AppRoute exact path="/cms/language" layout={MainLayout} title="Content Manangement Language" component={CMSLanguage} />

      {/* Settings */}
      <AppRoute exact path="/admin/settings/user-management" layout={SettingsLayout} menu={UsersAdminMenu} title="Settings - User Management" component={UserManagement} />
      <AppRoute exact path="/admin/settings/role-management" layout={SettingsLayout} menu={UsersAdminMenu} title="Settings - User Roles Management" component={RoleManagement} />
      <AppRoute exact path="/admin/settings/module-management" layout={SettingsLayout} menu={UsersAdminMenu} title="Settings - Module Management" component={ModuleManagement} />


      <AppRoute exact path="/home" layout={DefaultLayout} title="Home Page" component={Home} />

      { /** testing components */}
      <AppRoute exact path="/test" layout={BlankLayout} title="Home Page" component={TestPage} />




    </Switch>
  )
}

export default Routes
