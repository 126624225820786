import React from 'react'
import PropTypes from 'prop-types'
import DashTitleDivider from 'Components/DashTitleDivider'
import ButtonCard from 'Components/ButtonCard'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'

const CRMPage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            <div style={{ display: 'flex' }} >
                <ButtonCard link='/crm/vendors' title={'Vendors'} blue />
                <ButtonCard link='/crm/wholesale' title={'Wholesale Customers'} blue />
                <ButtonCard link='/crm/retail' title={'Retail Customers'} blue />
                <ButtonCard link='/crm/notifications' title={'Notifications'} blue />
            </div>
        </div>
    )
}

CRMPage.propTypes = {}

export default CRMPage