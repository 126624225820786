import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { vendorCardConstants } from 'Util/constants'
import styled from 'styled-components'
import { THEME } from 'Util/constants';
import str from 'string-sanitizer'

const { Option } = Select

const ContentWrapper = styled.div`
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
        text-transform: capitalize;
    }
`

function PartnerTypeDropdown({ onChange, ...rest }) {
    // const [list, setList] = useState([])

    return (
        <ContentWrapper>
            <Select
                {...rest}
                placeholder="Select Partner Type"
                onChange={onChange}
            >
                {vendorCardConstants.PARTNER_TYPES.map(v => <Option style={{ textTransform: 'capitalize' }} value={v} key={`partner-type-dd-opt${str.sanitize.addDash(v)}`}>{v}</Option>)}
            </Select>
        </ContentWrapper>
    )
}

PartnerTypeDropdown.propTypes = {}

export default PartnerTypeDropdown
