import { useSubscription } from '@apollo/client';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { appConstant } from 'Util/constants';
import { SUB_LATEST_AUCTION } from 'Util/subscriptions';
const timeFormat = 'dddd, MMMM Do, YYYY, h:mm:ss A';

function Clock(props) {

    /** for compare */
    const dtFormat = `${appConstant.dateFormat} ${appConstant.timeFormat}`;

    const [dateTime, setDateTime] = useState({
        newDelhi: moment().tz('Asia/Kolkata').format(timeFormat),
        local: moment().format(timeFormat)
    })

    const { data, loading, error } = useSubscription(SUB_LATEST_AUCTION);

    useEffect(() => {
        var timerID = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(timerID);
        };
    });

    function tick() {

        setDateTime({
            newDelhi: moment().tz('Asia/Kolkata').format(timeFormat),
            local: moment().format(timeFormat)
        });

    }

    if( loading ){
        return <></>
    }

    return (
        <div className="clock-wrapper">
            <p>IST: {dateTime.newDelhi}</p>
            <p>Local: {dateTime.local}</p>
            <p style={{ textTransform: "capitalize" }} >Auction Status: {data.auctions[0].status}</p>
        </div>
    )
}

Clock.propTypes = {

}

export default Clock