import { THEME } from 'Util/constants';
import styled from 'styled-components'

export const SectionContainer = styled.div`
    .content{
        margin: 5px;
        display: flex;
        justify-content: space-between;
        &>div.division{
            width: calc( 50% - 30px );
            .ant-form-item-label > label{ width: 180px; }
            .ant-form-item-control { width: 10px; }
        }
    }
    .ant-switch-checked{ background-color: ${THEME.colors.primary}; }
`

export const Division = styled.div`
    width: calc( 50% - 30px );
    .ant-form-item-label > label{ width: 180px; }
    .ant-form-item-control { width: 10px; }
`