import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ArrowLeftOutlined, EditFilled, DeleteFilled, PlusOutlined, CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { IconFlow, IconShare } from 'Components/Icons';
import { THEME, approvalType } from 'Util/constants';
import { useHistory, useParams } from 'react-router-dom';
import str from 'string-sanitizer'

const menu = [
    { label: "Request Approval" },
    { label: "More Options" }
]

const Container = styled.div`
    .actions-wrapper{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icons-wrapper{
            justify-content: flex-end;
            width: 180px;
            gap: 20px;        
            svg{
                color: ${THEME.colors.primary};
                font-size: 25px;
                cursor: pointer;
            }
        }
        
        .backIconWrapper{
            background-color: ${THEME.colors.primary};
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
        h1{
            margin: 0px 10px;
        }
        
    }

    .submenu-wrapper{

        padding: 10px 0px;
        border-bottom: solid #555 thin;
        border-top: solid #555 thin;
        margin: 20px 0px;

        &>a{
            color: #555;
            margin: 0px 10px;
            font-size: 16px;
            font-weight: 500;
            &[disabled]{
                color: #ccc;
                .anticon{
                    color: #ccc !important;
                }
            }
        }
    }
`

function Header({ setDisabled }) {

    const history = useHistory()
    const params = useParams()

    const backHandler = () => { 
        history.push(history.location.state.prevPath)
    }

    const subMenuClickHandler = (val) => {

        switch (val) {
            case "Request Approval":
                console.log('Request Approval')
                break;

            default:
                console.log('Meh')
                break;
        }

    }

    return (
        <Container>

            <div className='actions-wrapper' >

                <div>
                    <button onClick={backHandler} className='backIconWrapper' >
                        <ArrowLeftOutlined />
                    </button>

                    <h1>Purchase Order #{params.id}</h1>
                </div>

                <div className='icons-wrapper' >
                    <EditFilled onClick={() => setDisabled(false)} />
                    <IconShare color={THEME.colors.primary} size={25} />
                    <DeleteFilled/>
                    <PlusOutlined />
                </div>

            </div>

            <div className='submenu-wrapper'>
                {menu.map(v => (
                    <a key={`vendor-header-sub-${str.addDash(v.label)}`} onClick={() => subMenuClickHandler(v.label)} >{v.label}</a>
                ))}
            </div>

        </Container>
    )
}

Header.propTypes = {
    onEvent: PropTypes.func,
    editMode: PropTypes.bool,
    setDisabled: PropTypes.func
}

export default Header
