import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants';
import _ from 'lodash'
import moment from 'moment'
import { cleanTitle, getDate, getTimeFromDate } from 'Util/common';

export const statusQuery = (statusArr) => ({
    variables: {
        where: {
            "active": { "_eq": true },
            "_or": statusArr.map(s => ({ "status": { "_eq": s } }))
        }
    }
})

export const dataSource = (key = 'open', data) => {

    if (_.isUndefined(data)) {
        return []
    }

    return data.map((v, i) => (
        {
            ...v,
            key: `key-${i}-direct-offers-${key}-list`,
            item_code: v.species_sku,
            label: v.species_name,
            location: v.user_info.user_location.label || "",
            vendor: leadingZeroes(v.user_id, appConstant.leadingZero),
            time: getTimeFromDate(v.created_at, true),
            date: getDate(v.created_at),
            po: !_.isNull(v.purchase_order) ? leadingZeroes(v.purchase_order.id, appConstant.leadingZero) : v.purchase_order
        }
    ))
}

export const commonColumns = [
    {
        title: 'Item Code',
        dataIndex: 'item_code',
        sorter: (a, b) => a.item_code?.localeCompare(b.item_code),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Time',
        dataIndex: 'time',
        sorter: (a, b) => (moment(a.created_at).unix() - moment(b.created_at).unix()),
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'descend',
    },
    {
        title: 'DO#',
        dataIndex: 'id',
        sorter: { compare: (a, b) => a.id - b.id },
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, rec) => (<span>{leadingZeroes(rec.id, appConstant.leadingZero)}</span>)
    },
    {
        title: 'Label',
        dataIndex: 'label',
        editable: true,
        sorter: (a, b) => a.label?.localeCompare(b.label),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Preservation',
        dataIndex: 'preservation_method',
        editable: true,
        sorter: (a, b) => a.preservation_method?.localeCompare(b.preservation_method),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Origin',
        dataIndex: 'country_origin',
        editable: true,
        sorter: (a, b) => a.country_origin?.localeCompare(b.country_origin),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Harvest Method',
        dataIndex: 'harvest_method',
        editable: true,
        sorter: (a, b) => a.harvest_method?.localeCompare(b.harvest_method),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Available(KG)',
        dataIndex: 'avail_quantity',
        sorter: (a, b) => a.avail_quantity - b.avail_quantity,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, rec) => (<span>{rec.avail_quantity} / {rec.quantity}</span>),
        editable: true,
    },
    {
        title: 'MOQ(KG)',
        dataIndex: 'min_quantity',
        sorter: (a, b) => a.min_quantity - b.min_quantity,
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Offer Price',
        dataIndex: 'price',
        sorter: (a, b) => a.price - b.price,
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Offer Type',
        dataIndex: 'offer_type',
        sorter: (a, b) => a.offer_type?.localeCompare(b.offer_type),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Vendor ID',
        dataIndex: 'vendor',
        sorter: (a, b) => a.vendor?.localeCompare(b.vendor),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Location',
        dataIndex: 'location',
        sorter: (a, b) => a.location?.localeCompare(b.location),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    }

]


export const acceptedColumns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'do-location',
        sorter: (a, b) => (new Date(a.date) - new Date(b.date)),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Time',
        dataIndex: 'time',
        sorter: (a, b) => a.time?.localeCompare(b.time),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'DO#',
        dataIndex: 'id',
        sorter: { compare: (a, b) => a.id - b.id },
        render: (_, rec) => (<span>{leadingZeroes(rec.id, appConstant.leadingZero)}</span>)
    },
    {
        title: 'Vendor ID',
        dataIndex: 'vendor',
        sorter: { compare: (a, b) => a.vendor - b.vendor },
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Location',
        dataIndex: 'location',
        sorter: (a, b) => a.location?.localeCompare(b.location),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Species',
        dataIndex: 'species_name',
        sorter: (a, b) => a.species_name?.localeCompare(b.species_name),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Category',
        dataIndex: 'category',
        sorter: (a, b) => a.category?.localeCompare(b.category),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Preservation',
        dataIndex: 'preservation_method',
        sorter: (a, b) => a.preservation_method?.localeCompare(b.preservation_method),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Origin',
        dataIndex: 'country_origin',
        sorter: (a, b) => a.country_origin?.localeCompare(b.country_origin),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Harvest',
        dataIndex: 'harvest_method',
        sorter: (a, b) => a.harvest_method?.localeCompare(b.harvest_method),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Price',
        dataIndex: 'price',
        sorter: (a, b) => a.price - b.price,
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Offer Type',
        dataIndex: 'offer_type',
        sorter: (a, b) => a.offer_type?.localeCompare(b.offer_type),
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },
    {
        title: 'Order',
        dataIndex: 'order_quantity',
        sorter: (a, b) => a.order_quantity - b.order_quantity,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'PO#',
        dataIndex: 'po',
        sorter: (a, b) => a.po - b.po,
        sortDirections: ['ascend', 'descend', 'ascend'],
        editable: true,
    },

]