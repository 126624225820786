import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useState } from 'react';
import { GET_DRIVERS } from 'Util/queries';
const { Option } = Select;

function DriverTypeDropDown({ onChange, ...rest }) {

    const [list, setList] = useState({
        "1": "Reefer Truck",
        // "2": "Aquaculture Farmer",
        // "3": "Wholesaler",
        // "4": "Processor",
        // "5": "Retailer"
        }
    )

    // const { data } = useQuery(GET_DRIVERS, {
    //     variables: {
    //         where: {
    //             active: { _eq: true }
    //         }
    //     },
    //     onCompleted() {
    //         setList(data.drivers)
    //     }
    // });

    return (
        <div>
            <Select
                {...rest}
                placeholder="Select a driver type"
                onChange={onChange}
            >
                {Object.entries(list).map(([key, value]) => (<option value={key} key={key}>{value}</option>))}
            </Select>
        </div>
    )
}

export default DriverTypeDropDown
