import React, { useState } from "react";
import { Modal, Form, Input, Button, Tree, Transfer} from 'antd';
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { GET_USER_TAGS, GET_USER_SEGMENTS } from 'Util/queries';
import { useQuery, useLazyQuery } from '@apollo/client';
import { RollbackOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { selectedVendorAtom } from "Store/crmAtom";
import { useRecoilState } from "recoil";
import axios from 'axios';

const StyledButton = styled(Button)`
    //color: #fff;
    //background: ${THEME.colors.primary};
    border-color: ${THEME.colors.primary};
`
const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  height: auto;
`;

const Box = styled.div`
  //margin: 5%;
  padding: 2%;
  width: 100%;
  height: 100%;
  border: 1px solid ${THEME.colors.primary};
  text-align: left;
  position: relative;
  background: #fff;
  li.active{
    background: rgba(22, 180, 177, 0.5);
  }
`;

const Label = styled.span`
  //font-weight: bold;
  //font-size: 16px;
  //margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
        margin: 5px 0px;
        padding: 2px 15px;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border: 1px solid ${THEME.colors.primary};
    }
`

function TransferList({visible, onCancel, onSubmit}) {

    const [checkedSegments, setCheckedSegments] = useState([]);
    const [checkedTags, setCheckedTags] = useState([]);
    const [segment, setSegment] = useState([]);
    const [selectedSegment, setSelectedSegment] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [vendor, setSelectedVendor] = useRecoilState(selectedVendorAtom);
    const [open, setOpen] = useState(false);
  
    const { data: tag } = useQuery(GET_USER_TAGS, {
        onCompleted() {
          setTags(tag.user_tags)
        }
      });

      const { data: segments } = useQuery(GET_USER_SEGMENTS, {
        onCompleted() {
          setSegment(segments.user_segments)
        }
      });
  
    const handleCancel = () => {
        setTags(tag.user_tags)
        setSegment(segments.user_segments)
        setSelectedSegment([])
        setSelectedTags([])
        onCancel();
      };

      const filter = (data, selected) => {
        const idsToRemove = selected.map(sel => sel.id);
        if (data?.length > 0) {
            const filteredData = data.filter(
                (sel) => !idsToRemove.includes(sel.id)
              );
              return filteredData
        }
      }    
     
      const handleToggle = (value) => () => {

        let currentIndex = checkedTags.indexOf(value);
        let newChecked = [...checkedTags];

        if (value.__typename === 'user_segments'){
            currentIndex = checkedSegments.indexOf(value);
            newChecked = [...checkedSegments];
        }

        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }

        if (value.__typename === 'user_segments'){
            setCheckedSegments(newChecked);
        }
        else {
            setCheckedTags(newChecked)
        }
      };
    
      const handleAllRight = () => {
        setSelectedSegment(selectedSegment?.length > 0 ? selectedSegment.concat(segment) : segment);
        setSelectedTags(selectedTags?.length > 0 ? selectedTags.concat(tags) : tags);
        setSegment([]);
        setTags([]);
      };
    
      const handleCheckedRight = () => {
        setSelectedSegment(selectedSegment?.length > 0 ? selectedSegment.concat(checkedSegments) : checkedSegments);
        setSegment(filter(segment, checkedSegments))
        setCheckedSegments([]);

        setSelectedTags(selectedTags?.length > 0 ? selectedTags.concat(checkedTags) : checkedTags);
        setTags(filter(tags, checkedTags))
        setCheckedTags([]);
      };
    
      const handleCheckedLeft = () => {
        setSelectedSegment(filter(selectedSegment, checkedSegments));
        setSegment(segment?.length > 0 ? segment.concat(checkedSegments) : checkedSegments)
        setCheckedSegments([]);

        setSelectedTags(filter(selectedTags, checkedTags));
        setTags(tags?.length > 0 ? tags.concat(checkedTags) : checkedTags)
        setCheckedTags([]);
      };
    
      const handleAllLeft = () => {
        setSegment(segment?.length > 0 ? segment.concat(selectedSegment) : selectedSegment);
        setTags(tags?.length > 0 ? tags.concat(selectedTags) : selectedTags);
        setSelectedSegment([]);
        setSelectedTags([]);
      };

      const handleApply = () => {
        if (selectedTags.length === 0 && selectedSegment.length === 0) return        
        setOpen(true)
      }

      const handleSubmit = () => {
        const transformedSegment = selectedSegment.reduce((result, item) => {
          const key = item.label.toLowerCase().replace(/ /g, '_')+ '_segment';
          result[key] = '1';
          return result;
        }, {});

        const transformedTags = selectedTags.reduce((result, item) => {
          const key = item.label.toLowerCase().replace(/ /g, '_');
          result[key] = '1';
          return result;
        }, {});

        const dataTags = {...transformedSegment, ...transformedTags}

        const userIds = vendor.map(obj => obj.id);

        userIds.forEach(id => {
          axios.put(`${process.env.REACT_APP_API}/onesignal/create-data-tags`, { id, tags: dataTags}).then(res => {
            const { success } = res.data
            if (success) {
                // console.log(res.data);
                //onSubmit(userIds, dataTags)          
            }
          })  
        });
        onSubmit(userIds, dataTags)
        setOpen(false) 

      }    
      const customList = (title, items) => {
        const sortedItems = items?.slice().sort((a, b) => a.label.localeCompare(b.label))
        return (
            <div>
              <b>{(title === "Segments" || title === "Tags") && <RollbackOutlined style={{ transform: "rotate(180deg)", strokeWidth: "30", stroke: "black" }}/>} {title}</b>
              <ul style={{listStyle: 'none', padding: 0}}>
                {sortedItems?.map((value) => {
                  const labelId = `transfer-list-item-${value?.id}-label`;
    
                  return (
                    <li key={value?.id} role="listitem" onClick={handleToggle(value)} className={checkedSegments.includes(value) || checkedTags.includes(value) ? "active" : ""}>
                      <label htmlFor={labelId} style={{marginLeft: '15px'}}>
                        {(title === "Segments" || title === "Tags") && <RollbackOutlined style={{ transform: "rotate(180deg)", strokeWidth: "30", stroke: "black" }}/>} {value?.label}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
      }
      
  return (
    <>
      <Modal width={1000} visible={visible} title="Assign Users" onCancel={handleCancel}
            footer={[<StyledButton onClick={handleApply} key="create" >Apply</StyledButton>,
                      <StyledButton onClick={handleCancel} key="cancel" >Cancel</StyledButton>]}>
          <div 
              style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
              }}
              >
              <div> Total Users Selected</div>
              <div style={{ fontWeight: 'bold' }}>{vendor.length ?? 0}</div>                         
          </div>

          <div style={{display: 'flex', justifyContent: 'space-around',   background: '#eee', padding: '20px'}}>

              <BoxContainer>
                  <Label>Available Fields</Label>
                  <Box>
                      <b>NOTIFICATIONS</b>
                      {customList("Segments", segment)}
                      {customList("Tags", tags)}
                  </Box>
              </BoxContainer>


              <ButtonContainer>
                  <button onClick={handleAllRight}>≫</button>
                  <button onClick={handleCheckedRight}>&gt;</button>
                  <button onClick={handleCheckedLeft}>&lt;</button>
                  <button onClick={handleAllLeft}>≪</button>
              </ButtonContainer>

              <BoxContainer>
                  <Label>Add To Selected Segments</Label>
                  <Box>
                      {customList("Segments:", selectedSegment)}
                      {customList("Tags:", selectedTags)}
                  </Box>
              </BoxContainer>
          </div>
      </Modal>
      <Modal
        visible={open}
        centered= {true}
        autoFocusButton = 'ok'
        width = '600px'
        okText = 'Yes, Add'
        closable={false}
        open = {open}  
        footer={
        <div>
            <Button style={{  backgroundColor: THEME.colors.primary, borderColor: THEME.colors.primary }} type="primary" onClick={handleSubmit}>Yes, Add</Button>
            <Button style={{  backgroundColor: 'red', color: 'white' }} onClick={()=>setOpen(false)}>Cancel</Button>
        </div>
        }
    >
    <p><span style={{ fontSize: '30px', marginRight: '20px', color: THEME.colors.primary }}><QuestionCircleOutlined /></span> Are you sure you want to add these {vendor.length ?? 0} users to the selected
  notification segments and tags?</p>
      </Modal>
    </>
  );
}

export default TransferList;

