// import Cryptr from 'cryptr'
// const cryptr = new Cryptr(process.env.REACT_APP_CRYPTR_SECRET);

export default function User() {

    const username = localStorage.getItem("name") || "";
    const user_type = localStorage.getItem("user_type") || "";
    const token = localStorage.getItem("token") || "";
    const uid = localStorage.getItem("uid") || "";

    const user = JSON.parse(localStorage.getItem("user") || "");
    const userRoles = user.roles
    const isMaster = user.roles.includes(1)
    const isAdmin = user.roles.includes(2)

    /** check permission based on roles */
    const hasRole = (role) => {

        /** all access role */
        if (role === 0) return true

        return user.roles.includes(role)
    }

    return {
        user_id: parseInt(uid),
        username,
        user_type,
        token,
        isMaster,
        isAdmin,
        hasRole,
        user,
        userRoles
    }

}