import React, { useContext } from 'react'
import ButtonCard from 'Components/ButtonCard'
import { useLocation } from 'react-router-dom'
import { LogisticsRouteContext } from '..'


const data = [
    { link: '/logistics/routes', title: 'All Routes', value: 'all' },
    { link: '/logistics/routes/open', title: 'Open Routes', value: 'open'},
    { link: '/logistics/routes/completed', title: 'Completed Routes', value: 'completed' },
]

function MenuButton(props) {
    const location = useLocation();
    const { filters, setFilter } = useContext( LogisticsRouteContext )
    const handleOnClick = (jobtype) => {
        setFilter({ ...filters, jobtype })
    }
    return (
        <div style={{ display: 'flex' }}>
            {data.map(v => (
                <div onClick={() => handleOnClick(v.value)} key={`menu-key-${v.title}`}>
                    <ButtonCard
                        key={v.link}
                        link={v.link}
                        title={v.title}
                        lightBlue
                        selected={location.pathname === v.link}
                    />
                </div>

            ))}
        </div>
    )
}

MenuButton.propTypes = {}

export default MenuButton
