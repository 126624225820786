import { useMutation, useSubscription } from '@apollo/client';
import { Button, Form, Input, notification, Table, Typography } from 'antd';
import CommonDrawer from 'Components/CommonDrawer';
import ImageInput from 'Components/ImageInput';
import _ from 'lodash';
import React, { useState } from 'react';
import { ADD_PRODUCT_SPECIES, UPDATE_PRODUCT_SPECIES } from 'Util/mutations';
import { SUB_SPECIES } from 'Util/subscriptions';
import S3FileUpload from 'react-s3';
import './style.scss';

const s3Config = {
    dirName: 'species',
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
}


function CMSSpecies() {
    const [form] = Form.useForm();
    const { data, loading, error } = useSubscription(SUB_SPECIES);
    const [editId, setEditId] = useState(null);
    const [drawer, setDrawer] = useState(false);
    const [newImage, setNewImage] = useState(null)

    const [add] = useMutation(ADD_PRODUCT_SPECIES, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Species has been added',
            });
            setDrawer(false);
            setNewImage(null)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const [update] = useMutation(UPDATE_PRODUCT_SPECIES, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Species has been updated!',
            });
            setDrawer(false);
            setNewImage(null)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const drawerClose = () => {
        setDrawer(false);
        setEditId(null);
    }

    const edit = (record) => {
        setEditId(record.id)
        form.setFieldsValue({
            ...record,
        });
        setDrawer(true);
    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const onFormSubmit = () => {
        let formObject = form.getFieldsValue();

        if (!_.isNull(newImage)) {

            S3FileUpload.uploadFile(newImage, s3Config)
                .then(data => {

                    saveObject({
                        ...formObject,
                        image: process.env.REACT_APP_S3_URI + '/' + data.key
                    })

                }).catch(err => {
                    /** TODO: add error  */
                    console.log(err)
                })

        } else {
            saveObject(formObject)
        }

    }

    const saveObject = (object) => {
        _.isNull(editId) ? add({ variables: { object } }) : update({ variables: { id: editId, object } })
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            editable: true,
            render: (img) => (
                <img src={img} height="45" />
            ),
        },
        {
            title: 'Label',
            dataIndex: 'label',
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <Typography.Link onClick={() => edit(record)}>
                    Edit
                </Typography.Link>
            ),
        },
    ];

    const dataSource = () => {

        if (data) {
            return data.product_species.map((v, i) => (
                {
                    key: `key-${i}-psp-list`,
                    id: v.id,
                    label: v.label,
                    image: v.image,
                    description: v.description
                }
            ))
        }

        return []

    }

    /** Validation Rules */
    const rule = [
        {
            required: true,
            message: "Field is required"
        },
    ]

    return (
        <div className="species-page" >
            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { setDrawer(true); }} >New Species</Button>
            </div>

            <CommonDrawer
                open={drawer}
                form={form}
                title={editId ? "Edit species" : "Add new species"}
                onClose={drawerClose}
            >

                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                    component={false}
                >
                    <Form.Item
                        rules={rule}
                        label="Image" name="image">
                        <ImageInput onDone={(d) => {
                            form.setFieldsValue({ image: d.base64 })
                            setNewImage(d.file)
                        }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Label" name="label">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Description" name="description">
                        <Input.TextArea placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                </Form>

            </CommonDrawer>

            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    );
}

export default CMSSpecies
