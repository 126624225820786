import { useSubscription } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import leadingZeroes from 'leading-zeroes';
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { directSalesFilterAtom } from 'Store/directSalesAtom';
import styled from 'styled-components';
import { appConstant } from 'Util/constants';
import { DIRECT_SELECTION_LIST } from 'Util/subscriptions';
import TableFilters from './TableFilters'
import _ from 'lodash';
import PopOverInfo from '../../DirectSales/components/PopOverInfo';

const Container = styled.div``

const commonColumns = [
    {
        title: 'Item Code',
        dataIndex: 'item_code',
        sorter: (a, b) => a.item_code?.localeCompare(b.item_code),
        sortDirections: ['ascend', 'descend', 'ascend']

    },
    {
        title: 'Item Description',
        dataIndex: 'description',
        sorter: (a, b) => a.description?.localeCompare(b.description),
        sortDirections: ['ascend', 'descend', 'ascend']

    },
    {
        title: 'Label',
        dataIndex: 'label',
        sorter: (a, b) => a.label?.localeCompare(b.label),
        sortDirections: ['ascend', 'descend', 'ascend']

    },
    {
        title: 'Preservation Method',
        dataIndex: 'preservation_method',
        sorter: (a, b) => a.preservation_method?.localeCompare(b.preservation_method),
        sortDirections: ['ascend', 'descend', 'ascend']

    },
    {
        title: 'Country of origin',
        dataIndex: 'country_origin',
        sorter: (a, b) => a.country_origin?.localeCompare(b.country_origin),
        sortDirections: ['ascend', 'descend', 'ascend']

    },
    {
        title: 'Harvest Method',
        dataIndex: 'harvest_method',
        sorter: (a, b) => a.harvest_method?.localeCompare(b.harvest_method),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Available Quantity',
        dataIndex: 'quantity',
        sorter: (a, b) => a.quantity - b.quantity,
        sortDirections: ['ascend', 'descend', 'ascend']

    },
    {
        title: 'Offer Price',
        dataIndex: 'price',
        sorter: (a, b) => a.price - b.price,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Vendor',
        dataIndex: 'vendor',
        sorter: (a, b) => a.vendor - b.vendor,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
]

function DataTable() {

    /** Global State */
    const [filter, setFilter] = useRecoilState(directSalesFilterAtom)
    // const { auctionStatus } = auctionState
    const auctionStatus = 'open'


    /** Local State */
    const [data, setData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    /** GraphQL Hooks */
    const { loading } = useSubscription(DIRECT_SELECTION_LIST, {
        variables: {
            where: {
                active: { _eq: true }
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {

            if (data && data.custom_products) {

                setData(data.custom_products.map(v => {
                    const { preservation_method, country_origin, harvest_method, quantity, price, user_id } = v
                    return { 
                        key: `dsl-${v.id}`,
                        item_code: v.species_sku,
                        description: v.species_name,
                        label: v.species_name,
                        preservation_method,
                        country_origin,
                        harvest_method,
                        quantity,
                        price,
                        vendor: leadingZeroes(user_id, appConstant.leadingZero),
                        data: v
                    }
                }))
            }
        }
    });

    /** Functions */
    const onSelectChange = (newSelectedRowKeys, data) => {
        // setSelectedRecord(data)
        setSelectedRowKeys(newSelectedRowKeys);
    };

    useEffect(() => {
        setFilter({ ...filter, search: '' })
    }, [])

    const filterData = (data) => {
        const searchable = data.map(obj => ({
            ...obj,
            searchText: `${obj.item_code} ${obj.label} ${obj.description} ${obj.preservation_method} ${obj.country_origin} ${obj.quantity} ${obj.harvest_method} ${obj.price} ${obj.vendor}`
        }))

        if (!filter.search) {
            return data
        }
        return _.filter(searchable, function (d) {
            let query = filter.search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        });
    }

    const handleSubmit = ({ action, data }) => {

    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        ...commonColumns,
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => {
                return <div className='direct-offer-action-wrapper' >
                    <PopOverInfo data={record.data}>
                        <FontAwesomeIcon
                            size="2x"
                            style={{ "cursor": "pointer" }}
                            icon={faInfoCircle}
                            color='#20B0B5'
                        />
                    </PopOverInfo>
                </div>
            }
        }
    ]

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={columns} />
            <Table
                loading={loading}
                size='small'
                columns={columns}
                dataSource={filterData(data)}
                // rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 50
                }}
            />
        </Container>
    )
}

export default DataTable    