import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import React from 'react';
import { GET_SETTINGS } from 'Util/queries';
const { Option } = Select;

function SellerTypeDropdown({ onChange, value, ...rest }) {

    const { loading, data } = useQuery(GET_SETTINGS, {
        variables: {
            where: { key: { _eq: "seller_type" } }
        }
    })

    if (loading) { return <></> }

    const list = data?.settings[0]?.values || {};
    const selectValue = Object.keys(list).includes(String(value)) ? String(value) : undefined;

    return (
        <div>
            <Select
                placeholder="Select a type"
                onChange={onChange}
                value={selectValue}
                {...rest}
            >
                {Object.entries(list).map(([value, label]) => (
                    <Option key={value} value={value}>
                        {label}
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default SellerTypeDropdown
