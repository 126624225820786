import { AppContext } from 'AppContext';
import { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { appConstant } from 'Util/constants';

function useSettings(key) {
    const { appState } = useContext(AppContext);
    const obj = _.find(appState.settings, { key });
    const settings = _.isUndefined(obj) ? obj : obj.values
    const dtFormat = `${appConstant.dateFormat} ${appConstant.timeFormat}`;
    let schedules = []

    if (!_.isUndefined(obj)) {
        const { schedule, auction_duration, pre_bid_duration, result_duration, automatic_bid_acceptance } = settings
        schedule.forEach(t => {

            const rawTime = moment(t.time);
            const hour = parseInt(rawTime.format('H'))
            const min = parseInt(rawTime.format('m'))
            const time = moment().startOf('day').add(hour, 'hours').add(min, 'minutes');

            /**Prebid*/
            schedules.push({
                status: 'prebid',
                time: moment(time).subtract(pre_bid_duration, 'minutes').format(dtFormat)
            })

            /**InProgress*/
            schedules.push({
                status: 'inprogress',
                time: moment(time).format(dtFormat)
            })

            /** result */
            schedules.push({
                status: 'result',
                time: moment(time).add(auction_duration, 'minutes').format(dtFormat)
            })

            /**Closed*/
            schedules.push({
                status: 'closed',
                time: moment(time).add(parseInt(result_duration) + parseInt(auction_duration), 'minutes').subtract(1, 'second').format(dtFormat)
            })

        });

    }


    return [settings, schedules]
}

export default useSettings;