import { gql } from "@apollo/client";

const GET_SETTINGS = gql`query SettingsQuery($where: settings_bool_exp = {}) {
  settings(where: $where) {
    id
    key
    values
  }
}`

const GET_PRODUCTS = gql`query MyQuery {
    products {
      id
	  sku
      species {
        label
        description
        image
      }
    }
  }`

const GET_LANGUAGES = gql`query MyQuery {
  user_language {
    id
    label
	code
  }
}
`
const GET_LANGUAGE = gql`query MyQuery($where: user_language_bool_exp = {}) {
	user_language(where: $where) {
	  code
	  id
	  label
	}
  }
`

const GET_USER_TYPE = gql`query MyQuery {
  user_type {
    id
    label
  }
}
`

const GET_LOCATIONS = gql`query MyQuery {
  user_location {
    id
    label
    lat
    lng
  }
}
`

const GET_LOCATION = gql`query MyLocationQuery($where: user_location_bool_exp = {}) {
  user_location(where: $where) {
    id
    lat
    lng
    address
    label
    notes
  }
}
`
const GET_SPECIES = gql`query MyQuery {
  product_species {
    id
    label
    description
  }
}`

const GET_ADMIN_USER_ROLES = gql`query AdminUserRoles {
  admin_user_role {
    id
    name
    modules
    description
  }
}`

const GET_ADMIN_MODULE = gql`query get_admin_module {
  admin_modules {
    id
    name
    type
    description
  }
}`

const GET_VENDOR = gql`query MyVendorQuery($where: vendor_details_bool_exp = {}) {
  vendor_details(where: $where, order_by: {user: {id: asc}}) {
    id
    address1
    address2
    cash_flow_terms
    city
    contact_person_name
    contact_person_num
    coordinates
    country
    creditor_number
    gst_include
    gst_registration
    lead_time_calculation
    location_address
    location_code
    partner_type
    payment_code
    payment_terms
    phone
    postcode
    prefered_bank
    shipment_method_code
    virtual_payment_address
    wallet_accountt_number
	state_name
	image
    user {
      aadhaar_id
      username
      email
      mobile
      name
      status
      vessel
      id
      verification_id
      onboard_code
	  onboard_status
      user_language {
        label
        id
      }
      user_type {
        id
        label
      }
      user_location {
        id
        label
        lat
        lng
        address
      }
	  tag_ids
	  channel_ids
	  channel_playerids
	  collection_location_id
	  collection_location{
		id
		address_1
	  }
	  seller_type_id
    }
  }
}`

export const GET_TODAY_AUCTION = gql`
query MyQuery($date: date) {
  auction_schedules(where: { date: {_eq:$date}}) {
    id
    auction_number
    status
    datetime
    active
  }
}
`

export const GET_PRODUCT_DETAILS = gql`query MyProductSubscription($id: Int) {
	products(where: {id: {_eq: $id}}, order_by: {label: asc}) {
	  id
	  label
	  species_id
	  unit_of_measurement
	  product_type
	  product_environment
	  product_sub_category
	  product_size
	  process_category
	  image
	  volume
	  price
	  bid_specs
	  status
	  quota
	  sku
	  category
	  preservation_method
	  country_origin
	  harvest_method
	  is_rejected
	  onboard_status
	  uom
	  specifications
	  species {
		id
		image
		label
		description
	  }
	  species_environment {
		id
		code
		description
	  }
	  species_sizing {
		id
		code
		description
	  }
	  species_sub_category {
		id
		code
		description
	  }
	  species_type {
		id
		code
		description
	  }
	  processCategoryByProcessCategory {
		id
		code
		description
	  }
	}
  }
`;

export const GET_SPECIES_PROCUREMENT = gql`
	query MyProductSubscription($where: species_bool_exp) {
		species(where: $where, order_by: { label: asc }) {
			id
			label
			volume
			price
			bid_specs
			status
			sku
			category
			preservation_method
			country_origin
			harvest_method
			is_rejected
			uom
			unit_of_measurement
			species_type
			species_environment
			species_sub_category
			species_size
			process_category
			common_item_no
			species
			remarks
      		blocked
      		image
			localizations
			description
			scientific_name
			species_identifier
			species_inventories {
				id
				location_no
				inventory
				quantity_on_asm_component
				stock_out_warning
				quantity_on_purchase_order
				quantity_on_sales_order
				quantity_on_job_order
				quantity_on_assembly_order
				net_weight
				gross_weight
				unit_volume
			}
			species_cost_postings {
				costing_method
				standard_cost
				unit_cost
				indirect_cost_percentage
				last_direct_cost
				net_invoiced_quantity
				gen_prod_posting_group
				inventory_posting_group
				default_deferral_template
				gst_group_code
				gst_credit
				hsn_sac_code
				is_cost_adjusted
				is_cost_posted_to_gl
				purchase_prices_discounts
				is_exempted
				is_subcontracting
				sub_contract_location
				tariff_no
				country_of_origin_code
				id
			}
			species_pricing_and_sales {
				id
				unit_price
				unit_price_excl_gst
				is_price_includes_gst
				price_profit_calculation
				profit_percentage
				sales_prices_and_discounts
				is_allow_invoice_discounts
				item_discount_group
				sales_unit_of_measure
				is_sales_blocked
			}
			species_replenishments {
				id
				replenishment_system
				lead_time_calculation
				vendor_no
				vendor_item_no
				purchase_unit_of_measure
				overhead_rate
				assembly_policy
				assembly_bom
				is_purchasing_blocked
			}
			species_plannings {
				reordering_policy
				reserve
				reorder_point
				order_tracking_policy
				dampener_period
				is_critical
				safety_lead_time
				safety_stock_quantity
				is_include_inventory
				lot_accumulation_period
				rescheduling_period
				reorder_quantity
				maximum_inventory
				overflow_level
				time_bucket
				minimum_order_quantity
				maximum_order_quantity
				order_multiple
				id
			}
			species_item_trackings {
				id
				item_tracking_code
				serial_no
				lot_no
				expiration_calculation
			}
			species_warehouses {
				id
				warehouse_class_code
				special_equipment_code
				putaway_template_code
				putaway_unit_of_measure
				phys_invt_counting_period
				last_phys_invt_date
				last_counting_period_update
				next_counting_start_date
				next_counting_end_date
				is_use_cross_docking
			}
			species_sizing_details {
				id
				code
				description
			}
			process_category_details {
				id
				code
				description
			}
			unit_of_measurement_details {
				id
				code
				description
			}
			species_environment_details {
				id
				code
				description
			}
			species_sub_category_details {
				id
				code
				description
			}
			species_type_details {
				id
				code
				description
			}
		}
	}
`;
const GET_USER_ROLE = gql`query GetUserRole($where: user_roles_bool_exp = {}) {
  user_roles(where: $where) {
    id
    enabled
    display_name
    description
    is_default_profile
    profile_id
    role_id
    source
  }
}
`

const GET_ADMIN_USER = gql`
	query AdminUsers($where: admin_user_bool_exp = {}) {
		admin_user(order_by: {id: asc}, where: $where) {
		id
		email
		full_name
		status
		username
		default_profile
		is_pending
		authentication_status
		authentication_email
		reset_token
		channel_ids
		admin_user_groups{
			id
			user_group{
				id
				code
				name
			}
		}
		admin_user_roles{
			id
			user_role{
				id
				display_name
				profile_id
				description
			}
		}
		}
	}
`;

const GET_LOCATION_POINTS = gql`query GetLocationPoints($where: collection_locations_bool_exp = {}) {
	collection_locations(where: $where) {
	  	id
		is_active
		lat
		lng
		location_code
		location_name
		location_type
		postal_code
		address_1
		address_2
		city
		state
		country
		google_map_url
		depot_id
	}
  }
  `

const GET_AUCTION_SCHEDULES_SETTINGS = gql`query MyAuctionScheduleSettings($where: auction_schedule_settings_bool_exp = {}) {
	auction_schedule_settings(where: $where) {
	  results_duration
	  repeat
	  recurring
	  pre_bid_duration
	  id
	  datetime
	  date
	  counter_offer_duration
	  auction_time
	  auction_duration
	  end_date
	  override
	  reference_id
	}
  }`

const GET_AUCTION_SCHEDULES = gql`query MyAuctionSchedule($where: auction_schedules_bool_exp = {}) {
	auction_schedules(where: $where) {
		auction_number
		date
		datetime
		id
		settings
		status
		active
		total_duration
	}
  }`

const GET_PRODUCT_AUCTION = gql`
  query ProductAuction($where: auctions_bool_exp = {}, $pwhere: products_bool_exp = {}) {
	auctions(where: $where, order_by: {created_at: asc}) {
	  id
	  status
	  created_at
	  bids {
		id
		price
		volume
		rem_quota
		product_id
	  }
	}
	products(where: $pwhere) {
	  id
	  sku
	  label
	  preservation_method
	  harvest_method
	  country_origin
	  quota
	  price
	  volume
	  auction_quota
	  species{
		id
		sku
		description
	  }
	  bids{
		id
		price
		volume
		auction_id
	  }
	}
  }`

const GET_USER = gql`query GetUser($where: users_bool_exp = {}) {
	users(where: $where) {
	  aadhaar_id
	  id
	  mobile
	  name
	  status
	  onboard_code
	  onboard_status
	}
  }
  `
const GET_USER_TAGS = gql`
	query GetUserTags {
		user_tags {
			id
			description
			label
		}
	}
`
const GET_USER_SEGMENTS = gql`
	query GetUserSegments {
		user_segments {
			id
			label
		}
	}
`

const GET_USER_CHANNELS = gql`
	query GetUserChannels {
		user_channels {
			id
			label
		  }
	}
`

const GET_POLL_RESPONSE = gql`query PollResponseComments($whereRes: poll_response_bool_exp = {}, $whereCom: poll_comments_bool_exp = {}) {
	poll_response(where: $whereRes) {
	  id
	  response
	  user {
		id
		username
	  }
	  admin_user {
		id
		username
	  }
	}
	poll_comments(where: $whereCom) {
	  id
	  comment
	}
  }
  `
const GET_POLL = gql`query GetPolls($where: polls_bool_exp = {}) {
	polls(where: $where, order_by: {created_at: asc}) {
	  active
	  comments
	  created_at
	  created_by
	  expiry_date
	  id
	  option_type
	  options
	  question
	  show_result
	  title
	  updated_at
	  user_segment_id
	}
  } 
`

const GET_NOTIFICATION_TEMPLATES = gql`query getNotificationTemplates($where: admin_notifications_templates_bool_exp = {}) {
	admin_notifications_templates(where: $where) {
	  id
	  category_id
	  message
	  title
	}
  }`

const GET_PRODUCT_FORMAT = gql`query getProductFormat {
	product_format {
	  id
	  value
	}
  }  
`
const GET_PRODUCT_CATCH_METHOD = gql`query getProductCatchMethod {
	product_catch_method {
	  id
	  value
	}
  }  
`

const GET_PURCHASE_ORDERS = gql`query getPurchaseOrder($where: purchase_orders_bool_exp!) {
	purchase_orders(where: $where, order_by: {created_at: asc}) {
		id
		status
		sale_type
		adjustment_value
		fulfilment_type
		updated_at
		bid_id
		bid {
			id
			auction_id
			accept_condition
			accept_spec
			price
			status
			user_id
			volume
			rem_quota
			created_at
			product {
				id
				volume
				price
				log_cost
				label
				sku
				image
			}
		}
		user {
			id
			user_location {
				label
				lat
				lng
				id
			}
		}
		direct_offer_order {
			created_at
			updated_at
			direct_offer_id
			id
			order_quantity
			status
			updated_at
			direct_offer {
			  price
			  avail_quantity
			  category
			  contact_name
			  contact_number
			  country_origin
			  created_at
			  description
			  end_datetime
			  expiry
			  harvest_method
			  id
			  image
			  min_quantity
			  offer_type
			  order_quantity
			  preservation_method
			  quantity
			  species_name
			  status
			  unit
			  updated_at
			  user_id
			  species_sku
			}
		}
	}
  }`


/** Logistics */
const GET_DRIVERS = gql`query GetDriversQuery($where: drivers_bool_exp = {}) {
	drivers(where: $where, order_by: {id: asc}) {
		id
		uuid
		name
		status
		username
	}
}`


const GET_CARRIERS = gql`query GetCarriersQuery($where: carriers_bool_exp = {}) {
	carriers(where: $where, order_by: {id: asc}) {
		id
		code
		name
		contact_name
		contact_phone
		email
	}
}`

const GET_SHIPPERS = gql`query GetShippersQuery($where: shippers_bool_exp = {})  {
	shippers(where: $where, order_by: {id: asc}) {
		id
		code
		name
	}
}`

const GET_DEPOTS = gql`query GetDepotsQuery($where: depots_bool_exp = {}) {
	depots(where: $where, order_by: {id: asc}) {
		id
		code
		name
		type
		active
		street
		country
		state
		city
		latitude
		longitude
		google_map_url
	}
}`


const GET_JOBS = gql`query QueryJobs($where: jobs_bool_exp = {}) {
  jobs(where: $where) {
	id
    address
    address_zone
    barcode
    client
    cod
    contact_name
    date
    email
    job_status
    note
    phone
    service_time
    shipper_id
	shipper {
		id
		name
    }
    time_from
    time_to
    type
	track_id
	depot_id
	depot {
		id
		name
	}
	purchase_orders {
      id
      job_id
      bid {
        price
        volume
        product {
          sku
          label
        }
      }
	  direct_offer_order {
        order_quantity
		direct_offer {
          species_sku
          species_name
          price
        }
      }
    }
	purchase_orders_aggregate {
      aggregate {
        count
      }
    }
  }
}`

const GET_DRIVER = gql`query GetDriver($where: drivers_bool_exp = {}) {
	drivers(where: $where) {
		id
		address1
		address2
		city
		country
		depot_id
		driver_id
		email
		image
		license
		license_expiry
		type
		username
		verification_documents
		uuid
		status
		postal
		phone
		password
		name
		active
		aadhaar_id
	}
  }
`

const GET_VEHICLE = gql`query GetVehicle($where: vehicles_bool_exp = {}) {
	vehicles(where: $where) {
	  carrier_id
	  carrier {
	    id
		code
		name
      }
	  depot_id
	  depot {
		name
		code
      }
	  driver_id
	  driver {
		uuid
		id
      }  
	  id
	  number
	  start_time
	  status
	  volume
	  weight
	  work_time
	  vehicle_id
	  dimensions
	}
  }  
`

const GET_ROUTES = gql`query GetRouts($where: routes_bool_exp = {}) {
	routes(where: $where) {
	  id		
	  code
	  date
	  start_time
	  status
	  driver_id
	  depot_id
	  vehicle_id
	  driver {
		id
		name
		username
	  }
	  vehicle {
		id
		number
	  }
	  depot {
		street
		city
		state
		country
		code
	  }
	  jobs{
		id
		address
		address_zone
		barcode
		client
		cod
		contact_name
		date
		email
		job_status
		note
		phone
		service_time
		shipper_id
		shipper {
			name
			id
		}
		time_from
		time_to
		type
		track_id
		depot_id
		depot {
			id
			name
		}
		purchase_orders {
			id
			job_id
			bid {
				price
				volume
				product {
					sku
					label
				}
			}
			direct_offer_order {
				order_quantity
				direct_offer {
					species_sku
					species_name
					price
				}
			}
		}
		purchase_orders_aggregate {
			aggregate {
				count
			}
		}
	  }
	}
  }  
`


export {
	GET_SETTINGS,
	GET_LANGUAGES,
	GET_LANGUAGE,
	GET_LOCATIONS,
	GET_LOCATION,
	GET_SPECIES,

	/** admin user */
	GET_ADMIN_USER,
	GET_ADMIN_USER_ROLES,
	GET_ADMIN_MODULE,

	/** Collection Location Points */
	GET_LOCATION_POINTS,

	/** Auction Schedules */
	GET_AUCTION_SCHEDULES_SETTINGS,
	GET_AUCTION_SCHEDULES,

	/** product */
	GET_PRODUCT_AUCTION,
	GET_PRODUCTS,
	GET_PRODUCT_FORMAT,
	GET_PRODUCT_CATCH_METHOD,

	/** vendors/users */
	GET_VENDOR,
	GET_USER_ROLE,
	GET_USER_TYPE,
	GET_USER,
	GET_USER_TAGS,
	GET_USER_CHANNELS,
	GET_USER_SEGMENTS,

	/** notifications */
	GET_POLL_RESPONSE,
	GET_POLL,
	GET_NOTIFICATION_TEMPLATES,

	/** Auction/Bids */
	GET_PURCHASE_ORDERS,

	/** Logistics */
	GET_DRIVERS,
	GET_JOBS,
	GET_DRIVER,
	GET_VEHICLE,
	GET_CARRIERS,
	GET_DEPOTS,
	GET_ROUTES,
	GET_SHIPPERS

}
