import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { useState } from 'react'
import styled from 'styled-components'
import { auctionMenu } from './constants'

const Container = styled.div``

/** Schedule */
function AuctionSystem() {

    return (
        <Container>
            <SubPageBreadCrumbs />
            <Level2Menu menu={auctionMenu} />
        </Container>
    )
}

export default AuctionSystem