import React from 'react'
import './auction.scss'
import ArchiveList from './components/ArchiveList'

function ArchivePage(props) {

    return (
        <div className="auction-archive-page">
            <div style={{ backgroundColor: "#CCC", padding: 10, marginBottom: 15 }} >
                Menu Filter Placeholder Here.
            </div>
            <ArchiveList />
        </div>
    )
}

ArchivePage.propTypes = {

}

export default ArchivePage