
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { SUB_UNIT_OF_MEASUREMENT } from 'Util/subscriptions';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 33.33%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function ChooseUom(props) {
    const history = useHistory()
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState('company')

    const { data, loading } = useSubscription(SUB_UNIT_OF_MEASUREMENT)

    const { state, setState, setFieldValue } = props

    const okHandler = () => {
        setState({ ...state, showKg: false });
        setFieldValue(state.fieldKey, selected.code)
    }


    return (
        <Container>
            <Modal
                visible={state.showKg}
                centered
                onCancel={() => setState({ ...state, showKg: false })}
                onOk={okHandler}
                title={
                    <div style={{ flexDirection: "row", display: "flex" }}>
                        <span style={{ marginRight: 50 }}>Select unit of measurement</span>
                        <div><PlusOutlined color='#C6C8CD' />New <EditOutlined style={{ marginLeft: 20 }} />Edit <DeleteOutlined style={{ marginLeft: 20 }} /></div>
                    </div>
                }
                okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
                width={600}
                closable={false}
                bodyStyle={{ background: '#F2F2F2' }}
            >
                <ModalContainer>

                    <div className='thead' >
                        <span> Code </span>
                        <span> Description </span>
                        <span> International Standard Code </span>
                    </div>

                    {data?.unit_of_measurements?.length > 0 &&
                        data.unit_of_measurements.map((item) => (
                            <div key={`ChooseUom-${item.id}`} onClick={() => setSelected(item)} className={`tbody ${selected.id === item.id ? 'selected' : ''}`} >
                                <span> {item.code} </span>
                                <span> {item.description} </span>
                                <span> {item.international_standard_code} </span>
                            </div>
                        ))
                    }

                </ModalContainer>
            </Modal>
        </Container>
    )
}

ChooseUom.propTypes = {}

export default ChooseUom