import React, { useState } from 'react';
import styled, { ThemeConsumer } from "styled-components";
import { THEME } from "Util/constants";
import { Card, Table, Spin, Switch, Button } from "antd";
import PropTypes from 'prop-types';
import { useSubscription } from '@apollo/client';
import { POLL_RESPONSE } from 'Util/subscriptions';
import moment from 'moment-timezone';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    //height: 100vh;
    padding: 20px 50px;
    gap: 50px;
    .box {
        flex: 1;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    } 
`;

const Section = styled.div`
    display: inline-block;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
    width: 50%;

    .row {
        flex: 1;
        border: 1px solid ${THEME.colors.lightGray};
        padding: 10px;

        .title{
            font-weight: bold;
        }
        .details{
            color: ${THEME.colors.primary};
        }
    }
`

const Circle = styled.div`
    label{
        cursor: pointer;
        color: ${THEME.colors.primary};
        font-weight: 500;
        font-size: 20px;
        border: 1px solid ${THEME.colors.primary};
        padding: 3px 8px;
        border-radius: 50%;
    }
`
    
const Details = ({poll}) => {

    const switchText = poll.active ? "Active" : "Closed"
      
    return (
        <Container>

            <Section>
                <div className="box" >
                    <Card title="Creator" >
                        <div style={{display: "flex", gap: "50px", alignItems: "center"}}>
                            <Circle>
                                <label>A</label>
                            </Circle>
                            <div>
                                <div>Administrator</div>
                                <div style={{ color: THEME.colors.gray}}>{moment(poll.created_at).format("DD-MMM-YY")}</div>
                            </div>                      
                        </div>
                    </Card>
                </div>

                <div className="box" style={{marginTop: "50px"}}>
                    <Card title={poll.question}>
                        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                            {poll.options.map((buttonText, index) => (
                                <Button key={index}>{poll.option_type === "no-image" ? buttonText : buttonText.text}</Button>
                            ))}
                        </div>

                    </Card>
                </div>
            </Section>

            <Section>
                <div className="box">
                    <div className="row" style={{display: "flex", justifyContent: "space-between"}}>
                        <div className='title'>
                            Status
                        </div>
                        <div className='details' style={{ marginRight: "calc(30%)"}}>
                            <Switch defaultChecked checkedChildren={switchText} unCheckedChildren="Closed" />
                        </div>  
                    </div>

                    <div style={{display: "flex"}}>
                        <div className="row">
                            <div className='title'>
                                Published Date
                            </div>
                            <div className='details'>
                                {moment(poll.created_at).format("DD-MMM-YY")}
                            </div>             
                        </div>
                        <div className="row">
                            <div className='title'>
                                Expiration Date
                            </div>
                            <div className='details'>
                                {poll.expiry_date ? moment(poll.expiry_date).format("DD-MMM-YY") : "None"}
                            </div>             
                        </div>
                    </div>

                    <div className="row">
                        <div className='title'>
                            Segments
                        </div>
                        <div className='details'>
                            ALL EMPLOYEES
                        </div>             
                    </div>

                    <div className="row">
                        <div className='title'>
                            TAGS
                        </div>
                        <div className='details'>
                            VERIFIED VENDORS
                        </div>             
                    </div>

                    <div className="row">
                        <div className='title'>
                            BroadCast
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                                <div style={{ color: THEME.colors.gray}}>SENT</div>
                                <div style={{ color: THEME.colors.primary}}>4</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <div style={{ color: THEME.colors.gray}}>SCHEDULED</div>
                                <div style={{ color: THEME.colors.primary}}>0</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <div style={{ color: THEME.colors.gray}}>RECIPIENT</div>
                                <div style={{ color: THEME.colors.primary}}>4</div>
                            </div>                            
                        </div>          
                    </div>

                    <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center"}}>
                        <Button style={{ background: THEME.colors.primary, color: "white", borderColor: THEME.colors.primary}}>New Broadcast</Button>
                    </div>
                </div>
            </Section>


        </Container>
  )
};

Details.prototype = {
    poll: PropTypes.arrayOf(PropTypes.object)
}

export default Details;