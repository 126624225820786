import React from 'react'
import PropTypes from 'prop-types'
import ButtonCard from 'Components/ButtonCard'
import { useLocation, useParams } from 'react-router-dom'

const data = [
  { link: '/procurement/product-list', title: 'Master' },
  { link: '/procurement/product-list/active', title: 'Active Product List' },
  { link: '/procurement/product-list/pending', title: 'Pending Approval' },
  { link: '/procurement/product-list/quotas', title: 'Quotas' },
]

function MenuButton(props) {
  const location = useLocation();
  return (
    <div style={{ display: 'flex' }} >
      {data.map(v => (<ButtonCard
        key={v.link}
        link={v.link}
        title={v.title}
        lightBlue
        selected={location.pathname === v.link}
      />))}
    </div>
  )
}

MenuButton.propTypes = {}

export default MenuButton
