
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Modal } from 'antd';
import { useSubscription } from '@apollo/client';
import { SUB_JOBS } from 'Util/subscriptions';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            // width: 25%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function SelectPurchaseOrder(props) {
    const { showModal, setShowModal, handleAdd, excludedIds, routeId } = props
    const [selected, setSelected] = useState()
    const [selectableItems, setSelectableItems] = useState([]);

    const { data, loading } = useSubscription(SUB_JOBS, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                job_status: { _eq: "unscheduled" },
                deleted_at: { _is_null: true },
                _or: [
                    { route_id: { _is_null: true } },
                    { route_id: { _eq: routeId ?? 0 } }
                ]
            }
        }
    })

    useEffect(() => {
        if (data?.jobs) {
            const items = data.jobs.filter(item => !excludedIds.includes(item.id));
            setSelectableItems(items);
        }
    }, [data, excludedIds]);

    const okHandler = () => {
        if(selected){
            handleAdd(selected)
        }
        setShowModal(false);
    }

    if (loading) return <></>
 
    return (
        <Container>
        <Modal
            visible={showModal}
            centered
            onCancel={() => setShowModal(false)}
            onOk={okHandler}
            title={
            <div style={{ flexDirection: "row", display: "flex" }}>
                <span style={{ marginRight: 50 }}>Unscheduled Jobs</span>
            </div>
            }
            okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
            width={900}
            closable={false}
            bodyStyle={{ background: '#F2F2F2' }}
        >
            <ModalContainer>
            <div style={{ maxHeight: 300, overflow: "auto", maxWidth: 1800}}>
                <div className='thead' >
                    <span style={{ width: "10%"}}>Job No.</span>
                    <span style={{ width: "30%"}}>Client</span>
                    <span style={{ width: "30%"}}>Address</span>
                    <span style={{ width: "10%"}}>No. Of PO</span>
                    <span style={{ width: "10%"}}>Weight (KG)</span>
                    <span style={{ width: "10%"}}>Type</span>
                </div>

                {selectableItems.length > 0 &&
                    selectableItems.map((item) => {
                        const totalVolume = item.purchase_orders.reduce((total, order) => {
                            const volume = order.bid?.volume || order.direct_offer_order?.order_quantity || 0;
                            return total + volume;                
                        }, 0);
                        
                        return (
                            <div key={`ChooseLocation-${item.id}`} onClick={() => setSelected(item)} className={`tbody ${selected?.id === item.id ? 'selected' : ''}`} >
                                <span style={{ width: "10%"}}> {item.id} </span>
                                <span style={{ width: "30%", textAlign: 'left'}}> {item.client} </span>
                                <span style={{ width: "30%"}}> {item.address} </span>
                                <span style={{ width: "10%", textTransform: 'capitalize'}}> {item.purchase_orders_aggregate.aggregate.count} </span>
                                <span style={{ width: "10%", textTransform: 'capitalize'}}> {totalVolume || 0}</span>
                                <span style={{ width: "10%", textTransform: 'capitalize'}}> {item.type === 1 ? "Collection" : "Delivery"} </span>
                            </div>
                        )
                    })
                }
            </div>
            </ModalContainer>
        </Modal>
        </Container>
    )
}

SelectPurchaseOrder.propTypes = {}

export default SelectPurchaseOrder
