

import React, { createContext, useState } from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu, } from 'Components/Menus'
import { auctionMenu } from '../constants'
import { DataTable, TableFilters } from './components'
import moment from 'moment'
import { useSubscription } from '@apollo/client'
import { SUB_AUCTION_SCHEDULES_SETTINGS } from 'Util/subscriptions'
import ClockV2 from 'Components/ClockV2'
import AuctionTimer from 'Components/AuctionTimer'

export const AuctionScheduleContext = createContext()

const Container = styled.div``

function AuctionSchedule() {

    const [filter, setFilter] = useState({
        date: moment().tz('Asia/Kolkata'),
        status: 'all',
        sort: 'today'
    })

    const [schedule, setSchedule] = useState([])

    useSubscription(SUB_AUCTION_SCHEDULES_SETTINGS, {
        variables: { where: { recurring: { _eq: true } } },
        onSubscriptionData({ subscriptionData: { data } }) {
            if (data && data.auction_schedule_settings) {
                setSchedule(data.auction_schedule_settings)
            }
        }
    })

    return (
        <AuctionScheduleContext.Provider value={{
            filter, setFilter,
            schedule, setSchedule
        }} >
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
                <AuctionTimer position='absolute'/>
                <ClockV2 position='absolute' />
                {/* <TableFilters /> */}
                <DataTable />
            </Container>
        </AuctionScheduleContext.Provider>
    )
}

export default AuctionSchedule