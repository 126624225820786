
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import { useRecoilState } from "recoil";
import { selectedVendorAtom, notificationAtom } from "Store/crmAtom";

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;


function Confirmation(props) {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [vendor, setSelectedVendor] = useRecoilState(selectedVendorAtom)
    const [notifvalues, setNotifValues] = useRecoilState(notificationAtom)
    const [modalText, setModalText] = useState(`Are you sure you want to send out this notification to these ${vendor.length} recipient(s)?`);
    const [confirm, setConfirm] = useState(false);
    const history = useHistory()

    const onConfirm = () => {  
        setConfirmLoading(true);
        setTimeout(() => {
            setModalText('Your notification has been successfully sent!');
            setConfirm(true)
          }, 2000);
    }

    const openModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);      
    };

    const onClose = () => {
        setOpen(false)
        history.goBack()
    }

    useEffect(() => {
        return () => {
            setNotifValues({})
        }  
    }, []);
    
    return (
        <Container>
          <ButtonLoader loading={props.loading} htmlType="submit" onClick={openModal}>Send</ButtonLoader>
          <Modal
            visible={open}
            centered= {true}
            autoFocusButton = 'ok'
            width = '600px'
            okText = 'Yes, Send'
            open = {open}
            footer={
               !confirm ? <div>
                <Button loading={confirmLoading} style={{  backgroundColor: THEME.colors.primary, borderColor: THEME.colors.primary }} type="primary" onClick={onConfirm}>Yes, Send</Button>
                <Button style={{  backgroundColor: 'red', color: 'white' }} onClick={handleCancel}>Cancel</Button>
              </div> :
              <div>
                <Button onClick={onClose}>Close</Button>
              </div>
            }
        
          >
            <p><span style={{ fontSize: '30px', marginRight: '20px', color: THEME.colors.primary }}><QuestionCircleOutlined /></span> {modalText}</p>
          </Modal>
        </Container>
    )
}

Confirmation.propTypes = {
    loading: PropTypes.bool,
}

export default Confirmation
