import ButtonCard from 'Components/ButtonCard';
import { BarChart, DoughnutChart, LineChart } from 'Components/Charts';
import DashInfoCard from 'Components/DashInfoCard';
import DashTitleDivider from 'Components/DashTitleDivider';
import { Button } from 'antd';
import 'chart.js/auto';

function Home() {

    return (
        <div>
            <DashTitleDivider title='Key Metrics' />
            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }} >
                <DashInfoCard title='Procurement Value This Month' link='#test' value='4,786,863' />
                <DashInfoCard title='Wholesale Revenue This Month' link='#test' value='5,993,112' />
                <DashInfoCard title='Retail Revenue This Month' link='#test' value='2,993,112' />
                <DashInfoCard title='Overdue Sales Invoices' link='#test' red value='3' />
                <DashInfoCard title='Overdue Purchase Invoices' link='#test' value='0' />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', }} >

                <div>
                    <h2>Ongoing Procurement</h2>
                    <div style={{ display: 'flex' }} >
                        <ButtonCard title={'Purchase Orders'}> <h1>123</h1> </ButtonCard>
                        <ButtonCard title={'Purchase Invoices'}> <h1>123</h1> </ButtonCard>
                    </div>
                </div>

                <div>
                    <h2>Ongoing Sales</h2>
                    <div style={{ display: 'flex' }} >
                        <ButtonCard title={'Wholesale Orders'}> <h1>123</h1> </ButtonCard>
                        <ButtonCard title={'Retail Orders'}> <h1>123</h1> </ButtonCard>
                        <ButtonCard title={'Wholesale Invoices'}> <h1>123</h1> </ButtonCard>
                        <ButtonCard title={'Retail Invoices'}> <h1>123</h1> </ButtonCard>
                    </div>
                </div>

                <div>
                    <h2>Payments</h2>
                    <div style={{ display: 'flex' }} >
                        <ButtonCard title={'Collection Days'} />
                        <ButtonCard title={'Payable Days'} />
                    </div>
                </div>

            </div>

            <DashTitleDivider title='Data Analytics' />
            <div style={{ display: 'flex', justifyContent: 'space-between', }} >
                <DoughnutChart />
                <LineChart />
                <DoughnutChart />
                <BarChart />
            </div>

        </div >
    )
}

export default Home