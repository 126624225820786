import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { FilterFilled, InfoCircleFilled, UnorderedListOutlined } from '@ant-design/icons';
import { IconShare } from 'Components/Icons';
import axios from 'axios';
import { Tooltip } from 'antd';
import CommonExportIcon from 'Components/CommonExportIcon'

const Container = styled.div`
    
    width: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        cursor: pointer;
    }
    
    svg{
        cursor: pointer;
    }

    `;

function ExtraFilters(props) {

    const auctionActivateHandle = async () => {
        await axios.post(`${process.env.REACT_APP_WEBHOOK_ENDPOINT}/webhook/activate-auction`)
    }

    return (
        <Container>
            <CommonExportIcon data={props.data} columns={props.columns}/>
            <Tooltip title="Manual auction activation" >
                <InfoCircleFilled onClick={auctionActivateHandle} />
            </Tooltip>
        </Container>
    )
}

ExtraFilters.propTypes = {}

export default ExtraFilters