import React, { createContext, useState } from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { auctionMenu } from '../constants'
import { DataTable, TableFilters } from './components'
import ClockV2 from 'Components/ClockV2'
import AuctionTimer from 'Components/AuctionTimer'

const Container = styled.div``

export const AuctionQuotaContext = createContext()

function AuctionQuotas() {

  const [filter, setFilter] = useState('all')

  return (
    <AuctionQuotaContext.Provider
      value={{
        filter, setFilter
      }}
    >
      <Container>
        <SubPageBreadCrumbs />
        <Level2Menu menu={auctionMenu} />
        <AuctionTimer position='absolute'/>
        <ClockV2 position='absolute' />
        {/* <TableFilters /> */}
        <DataTable />
      </Container>
    </AuctionQuotaContext.Provider>
  )
}

export default AuctionQuotas