import React from 'react'
import PropTypes from 'prop-types'
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';


const data = {
    labels: [
        'New Users',
        'Active Users',
        'Ressurected Users',
    ],
    datasets: [{
        label: 'Active Users',
        data: [300, 50, 100, 150],
        backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
        ],
        hoverOffset: 4
    }]
}

const LineChart = props => {
    return (
        <div style={{ width: 400 }}>
            <Line data={data} />
        </div>
    )
}

LineChart.propTypes = {}

export default LineChart