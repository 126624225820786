import { ControlOutlined, DashboardOutlined, LogoutOutlined } from '@ant-design/icons';
import { faHandPaper, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";

function MainMenu(props) {

    const onLogOut = () => {
        localStorage.removeItem('token');
        window.location.href = '/login'
    }

    return (
        <>
            <Menu theme="light" mode="horizontal" defaultSelectedKeys={['dashboard']}>

                {/* <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                    <span>Dashboard</span>
                    <Link to="/" />
                </Menu.Item> */}

                <Menu.SubMenu title="CMS" icon={<ControlOutlined />} >

                    <Menu.Item key="cms:users" >
                        <span>Users</span>
                        <Link to="/cms/users" />
                    </Menu.Item>

                    <Menu.Item key="cms:language" >
                        <span>Language</span>
                        <Link to="/cms/language" />
                    </Menu.Item>

                    <Menu.Item key="cms:locations" >
                        <span>Locations</span>
                        <Link to="/cms/locations" />
                    </Menu.Item>

                    <Menu.Item key="cms:species" >
                        <span>Species</span>
                        <Link to="/cms/species" />
                    </Menu.Item>

                    <Menu.Item key="cms:products" >
                        <span>Products</span>
                        <Link to="/cms/products" />
                    </Menu.Item>

                </Menu.SubMenu>

                <Menu.Item key="auction" icon={<FontAwesomeIcon className="sub-menu-title-icon" icon={faHandPaper} />} >
                    <span>Auction</span>
                    <Link to="/auction" />
                </Menu.Item>

                <Menu.Item key="direct-sales" icon={<FontAwesomeIcon className="sub-menu-title-icon" icon={faHandshake} />} >
                    <span>Direct Sales</span>
                    <Link to="/direct-sales" />
                </Menu.Item>

            </Menu>

            <div>
                <Button className="logout-btn" onClick={() => onLogOut()} >
                    <LogoutOutlined />
                </Button>
            </div>
        </>
    )
}

MainMenu.propTypes = {

}

export default MainMenu

