import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Button, Select, Table} from 'antd';
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Country } from 'country-state-city';
import { GET_USER_TAGS, GET_LANGUAGES, GET_USER, GET_LOCATIONS } from 'Util/queries';
import { useQuery, useLazyQuery } from '@apollo/client';

const { Option } = Select;

const Container = styled.div`
`
const StyledButton = styled(Button)`
    color: #fff;
    background: ${THEME.colors.primary};
    border-color: ${THEME.colors.primary};
`
const ListTable = styled.div`
    .container {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      //padding: 20px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        padding: 5px;
      }
    }

    .thead, .tbody{
        width: 950px;
        padding: 5px;
        font-weight: bold;
        cursor: pointer;
        span{
            display: inline-block;
            text-align: left;
        }
        .col-1{
            width: 20%;
            background: #eee;
            padding: 10px;
        }
        .col-2{
            width: 75%;
            background: rgb(0, 175, 240,0.2);
            padding: 10px;
        }
        .col-3{
            width: 5%;
            background: rgb(0, 175, 240,0.2);
            color: ${THEME.colors.primary};
            padding: 10px;
        }
    }
  `
const NewSegmentModal = ({visible, onCancel, onSubmit, data}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showList, setShowList] = useState(true)
  const [languages, setLanguages] = useState([])
  const [locations, setLocations] = useState([])
  const [tags, setTags] = useState([])


  useEffect(() => {
    if (data){
        form.setFieldsValue({
            id: data.id,
            label: data.label,
            description: data.description,
          });
          setEditMode(true)
      }
  }, [])
  
  const listData = [
    { id: 1, title: 'Group', description: 'User Notification General Group e.g. Vendors, Wholesale Clients, Retail Customers, Employees' },
    { id: 2, title: 'User Tag', description: "Tags added to user's device" },
    { id: 3, title: 'Language', description: "The language selected in the User's Profile" },
    { id: 4, title: 'Location', description: "The geo-location registered in the User's profile" },
    { id: 5, title: 'Country', description: "The domicile country selected in the User's profile" }
  ];

  const { data: tag } = useQuery(GET_USER_TAGS, {
    onCompleted() {
      // let userTagsList = tags.user_tags
      setTags(tag.user_tags)
    }
  });
  
  const { data: language } = useQuery(GET_LANGUAGES, {
    onCompleted() {
      //console.log(language.user_language);
      setLanguages(language.user_language)
    }
  });

  const { data: location } = useQuery(GET_LOCATIONS, {
    onCompleted() {
      //console.log(location.user_location);
      setLocations(location.user_location)
    }
  });

  const [totalUsers, setTotalUsers] = useState(0)
  const [prevFieldValue, setPrevFieldValue] = useState(null);
  const prevFieldRef = useRef(null);

  const [getUsers, {data: users}] = useLazyQuery(GET_USER, {
    //variables: { where: { user_language: { code:  { _eq: 'en' } } } },
    onCompleted() {
        // users && console.log(users.users.length);
        // setTotalUsers(totalUsers + users.users.length)
        if (users && users.users.length > 0) {
          setTotalUsers(prevTotalUsers => prevTotalUsers + users.users.length);
        }    
    }
  })

  const filteredData = listData.filter((item) => !tableData.find((t) => t.id === item.id));

  const countTotalUser = (field, value) => {
    if (prevFieldRef.current?.field === field) {
      return;
    }  
    getUsers({ variables: { where: { [field]: { code:  { _eq: value } } } } })  
    prevFieldRef.current = { field, value };
    console.log(prevFieldRef.current);
  }

  const handleCancel = () => {
    form.resetFields();
    setTableData([]);
    setShowList(true);
    onCancel();
  };

  const onItemClick = (item) => {
    setShowList(false)
    setTableData([...tableData, item]);
    //form.setFieldsValue({ table: [...tableData, item]});
  };

  const onDeleteFromTable = (record) => {
    const dataIndex = tableData.findIndex((item) => item.id === record.id);
    const filteredTableData = [...tableData];
    filteredTableData.splice(dataIndex, 1);

    const value = form.getFieldsValue()
    const field = record.title.split(" ")[0].toLowerCase();
    if (value.table?.[field]){
      delete value.table[field]
    }


    if (filteredTableData.length === 0){
      setShowList(true)
    }
    setTableData(filteredTableData);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 190,
      render(text, record) {
        return {
          props: {
            style: { background: '#eee', fontWeight: 'bold'}
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 650,
      render(text, record) {
        let dropdown;
        switch (record.title) {
          case 'Group':
            dropdown = 
            <Form.Item name="group" rules={[{ required: true, message: '' }]} style={{ marginBottom: 0 }}>
              <Select placeholder="Select a group" style={{ width: 500 }}
                onChange={(v) => form.setFieldsValue({ table: {group: v}})}
              >
                <Option key={1} value="Vendors">Vendors</Option>
                <Option key={2} value="Wholesale Clients">Wholesale Clients</Option>
                <Option key={3} value="Retail Customers">Retail Customers</Option>
                <Option key={4} value="Employees">Employees</Option>
              </Select>
            </Form.Item>
            break;
          case 'User Tag':
            dropdown =  
            <Form.Item name="tag" rules={[{ required: true, message: '' }]} style={{ marginBottom: 0 }}>
               <Select
                style={{ width: 500 }}
                  placeholder="Select a tag"
                  //onChange={(v) => form.setFieldsValue({ table: {user: v}})}
                >
                {tags.map(v => <Option value={v.id} key={`tag_${v.id}`}>{v.label}</Option>)}
              </Select>
            </Form.Item>
            //dropdown = 
            // <Form.Item name="users">
            //   <TagAdder form={form} name="users"/>
            // </Form.Item>
            break;
          case 'Language':
            dropdown = 
            <Form.Item name="language" rules={[{ required: true, message: '' }]} style={{ marginBottom: 0 }}>
              <Select
                style={{ width: 500 }}
                  placeholder="Select a language"
                  //={(v) => countTotalUser("user_language", v)}
                >
                {languages.map(v => <Option value={v.code} key={`language_${v.id}`}>{v.label}</Option>)}
              </Select>
            </Form.Item>
            break;
          case 'Location':
            dropdown = 
            <Form.Item name="location" rules={[{ required: true, message: '' }]} style={{ marginBottom: 0 }}>
              {/* <LocationDropdown style={{ width: 500 }}/> */}
              <Select
                style={{ width: 500 }}
                  placeholder="Select a location"
                  //onChange={(v) => countTotalUser("user_language", v)}
                >
                {locations.map(v => <Option value={v.id} key={`location_${v.id}`}>{v.label}</Option>)}
              </Select>
            </Form.Item>
            break;
          case 'Country':
            dropdown = 
            <Form.Item name="country" rules={[{ required: true, message: '' }]} style={{ marginBottom: 0 }}>
              <Select showSearch
                //onChange={(v) => form.setFieldsValue({ table: {country: v}})}
                placeholder="Select a country"
                style={{ width: 500 }}
              >
                {Country.getAllCountries().map(v => <Select.Option value={v.isoCode} key={`country-${v.isoCode}`}>{v.name} ({v.isoCode})</Select.Option>)}
              </Select>
            </Form.Item>
            break;
          default:
            dropdown = '';
        }
        return {
          props: {
            style: { background: 'rgb(0, 175, 240,0.2)'}
          },
          children: 
            <div>
              {dropdown}
            </div>
        };
      }      
    },
    {
      title: 'Action',
      key: 'action',
      width: 47.5,
      render(text, record) {
        return {
          props: {
            style: { background: 'rgb(0, 175, 240,0.2)'}
          },
          children: 
          <div>
            <Button type="link" onClick={() => onDeleteFromTable(record)}>
              <CloseOutlined />
            </Button>
          </div>
        };
      }
    },
  ];

  const onCreate = async () => {
    //setLoading(true);
    //form.validateFields();
    try {
      const values = form.getFieldsValue()
      await form.validateFields().then(values => {
        const segmentName = values.label;
        const segmentFilters = [];

        const filters = Object.entries(values).filter(([key, value]) => {
          if (key === "label" || key === "id" ) {
            return false;
          }
          if (key === "location") {
            const locationDetails = locations.find(loc => loc.id === values.location)
            const filter = {
              field: 'location',
              lat: locationDetails.lat,
              long: locationDetails.lng,
              radius: '100'
            };
            segmentFilters.push(filter);
            return false;
          }
          if (key === "group") {
            const filter = {
              field: 'tag',
              key: values.group + '_group',
              relation: '=',
              value: "1"
            };
            segmentFilters.push(filter);
            return false;
          }
          if (key === "tag") {
            const tagDetails = tags.find(tag => tag.id === values.tag)
            const filter = {
              field: 'tag',
              key: tagDetails.label,
              relation: '=',
              value: "1"
            };
            segmentFilters.push(filter);
            return false;
          }
          return true;
        }).map(([key, value]) => {
          return {
            "field": key,
            "relation": "=",
            "value": value.toString()
          };
        });
        
        const orSegment = [
          {"operator": "OR"},{"field": "tag", "relation": "=", "key": `${segmentName}_segment`, "value": "1"},
        ]
        
        const segmentData = {
          "name": segmentName,
          "filters": [...filters, ...segmentFilters, ...orSegment]
        };        

        let group = values.group ? '{1}' : '{}' //TODO add on table
        let tag = values.tag ? '{' + values.tag + '}' : '{}'
        let location = values.location ? '{' + locations.find(loc => loc.id === values.location ).id + '}' : '{}'
        let country = values.country ? '{' + values.country + '}' : '{}'
        let language = values.language ? '{' + languages.find(lang => lang.code === values.language ).id + '}' : '{}'
    
        const object = {
          id: values.id,
          label: values.label,
          status: true,
          group: group,
          tags:  tag,
          language: language,
          location:  location,
          country:  country
        }
    
        axios.post(`${process.env.REACT_APP_API}/onesignal/create-segment`, { name: segmentName, filters: segmentData.filters}).then(res => {
          const { success } = res.data
          if (success) {
              console.log(res.data);
              onSubmit(object);
              form.resetFields();
              setTableData([]);
              setShowList(true);
              setLoading(false);           
          }
      })

        
      }).catch(error => {console.log('error', error);})
  
    } catch (error) {
      console.log('Validation failed:', error);
      setLoading(false);
    }
  };

  return (
    <Modal width={1000} visible={visible} title="Segment Editor" onCancel={handleCancel} footer={[
        <StyledButton key="create" type="primary" loading={loading} onClick={onCreate} disabled={editMode}>
            Create Segment
        </StyledButton>
        ]}>
        <Form form={form} layout="vertical">
                <Form.Item style={{ display: 'none' }} label="id" name={"id"} >
                    <Input />
                </Form.Item>
                <div  style={{
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                    <Form.Item style={{ width: "50%" }} label="Segment Name" name="label" rules={[{ required: true, message: '' }]}>
                        <Input />
                    </Form.Item>
                    
                    <div 
                        style={{
                            width: "50%",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            paddingLeft: '200px'
                        }}
                    >
                        <div> Total Users</div>
                        <div style={{ fontWeight: 'bold' }}>{totalUsers}</div>                         
                    </div>
                </div>

                <div>
                {tableData.length > 0 && (
                      <>
                        <Table dataSource={tableData} columns={columns} pagination={false} rowKey="id" showHeader={false} width={900} />
                        {(!showList && filteredData.length > 0) && <Button type="link" style={{ display: 'flex' }} onClick={()=>setShowList(true)}>Add Filter</Button>}
                      </>
                    )}
                </div>
            </Form>

            <div>
            {showList && (
              <ListTable>   
                <div className='container'>
                  <div className='header' style={{ display: tableData.length > 0 ? '': 'none'}}>
                    <span>Filters</span> 
                    <span><CloseOutlined onClick={()=>setShowList(false)}/></span> 
                  </div> 
                  {filteredData.map((row, index)=>(
                        <div key={`row-item-${row.id}`} className='tbody' onClick={() => onItemClick(row)}>
                            <span className="col-1"> {row.title} </span>
                            <span className="col-2"> {row.description} </span>
                            <span className="col-3" >+</span>
                        </div>
                    ))} 
                </div>     
              </ListTable>
            )}
            </div>
    </Modal>
  );
};

export default NewSegmentModal;
