import { useSubscription } from '@apollo/client';
import { Table } from 'antd';
import React from 'react';
import { DIRECT_OFFER_STATUS } from 'Util/constants';
import { SUB_DIRECT_OFFERS } from 'Util/subscriptions';
import { commonColumns, dataSource, statusQuery } from './common';
import './style.scss';

function WithdrawnDirectSales(props) {

    const { data, loading, error } = useSubscription(SUB_DIRECT_OFFERS, statusQuery([DIRECT_OFFER_STATUS.withdrawn, DIRECT_OFFER_STATUS.stagedWithdrawn]));

    const cancel = () => { console.log('cancel editing') };

    return (
        <div className="direct-offer-page" >
            <Table
                loading={loading}
                bordered
                dataSource={dataSource('accepted', data)}
                columns={commonColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    )
}

WithdrawnDirectSales.propTypes = {

}

export default WithdrawnDirectSales