import React, { useEffect } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import Routes from 'Routes';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './common-style.scss'
import AppContextProvider from 'AppContext';
import { useSubscription } from '@apollo/client';
import { BIDS_LIST, SUB_AUCTION_SCHEDULES, SUB_BIDS } from 'Util/subscriptions';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import _ from 'lodash'
// import OneSignalComponent from 'Components/OneSignal'
import OneSignal from 'react-onesignal';


/** TODO: deprecate context and use recoil */

function App() {

  const [auctionData, setAuctionData] = useRecoilState(auctionAtom)

  // useEffect(async () => {
  //   await OneSignal.init({ appId: '89506342-d9a9-463f-8202-71f372860e2c', allowLocalhostAsSecureOrigin: true })
  // }, [])


  /** subscribe to auction schedules */
  useSubscription(SUB_AUCTION_SCHEDULES, {
    variables: {
      where: { active: { _eq: true }, status: { _nin: ['closed', 'scheduled'] } },
      order_by: { datetime: 'asc' }
    },
    onSubscriptionData({ subscriptionData: { data } }) {

      if (data && !_.isEmpty(data.auction_schedules)) {

        console.log('oi', data.auction_schedules[0])
        const { status, auction_number } = data.auction_schedules[0]
        setAuctionData({
          auctionStatus: status,
          currentAuction: auction_number
        })
        return null
      }

      /** reset auction status */
      setAuctionData({
        ...auctionData,
        auctionStatus: null,
        currentAuction: null
      })

    }
  })

  /** subscribe on auction bids */
  useSubscription(BIDS_LIST, {
    variables: { status: { _neq: 'closed' } },
    onSubscriptionData({ subscriptionData: { data } }) {

      setAuctionData({
        ...auctionData,
        currentBids: data.bids.map(bid => {
          const { id, rem_quota, auction_id, product, status, volume } = bid
          return { id, rem_quota, auction_id, product_id: product.id, status, volume }
        })
      })

    }
  })


  return (
    <AppContextProvider>
      <Router>
        <Routes />
      </Router>
    </AppContextProvider>

  )
}

export default App
