import { useSubscription } from '@apollo/client';
import { Space, Table, Tag } from 'antd';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { THEME } from 'Util/constants';
import { ADMIN_USERS } from 'Util/subscriptions';
import { UsersContext } from '../Users';
import { CheckSquareOutlined } from '@ant-design/icons';
import { useHistory, Link } from "react-router-dom";

const Container = styled.div`
    width: 100%;
`
function UsersDataTable(props) {

    const { setSelectedRecord } = useContext(UsersContext)
    const [data, setData] = useState([])
    // const [selected, setSelected] = useState([]);
    const { loading } = useSubscription(ADMIN_USERS, {
        onSubscriptionData({ subscriptionData: { data } }) {
            data.admin_user && setData(data.admin_user.map(user => ({
                key: `admin-user-data-key-${user.id}`,
                ...user,
                action: user.id,
                user_status: {
                    status: user.status,
                    is_pending: user.is_pending
                }
            })))
        }
    })

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (_, record) => ( 
                <Link style={{ color: THEME.colors.primary }} to={`users/edit/${record.id}`} >
                    {record.username}
                </Link>
            ),
        },
        {
            title: 'Fullname',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'Status',
            dataIndex: 'user_status',
            key: 'user_status',
            render: (rec) => {
                const { is_pending, status } = rec
                return <Space size='small'>
                    <Tag color={(is_pending && !status) ? 'orange' : status ? 'green' : 'red'} >{(is_pending && !status) ? 'Pending' : status ? 'Enabled' : 'Disabled'}</Tag>
                </Space>
            }
        },
        {
            title: 'Authentication Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <a style={{ color: THEME.colors.primary }} >{text}</a>,
        },
        {
            title: 'Default Profile',
            dataIndex: 'default_profile',
            key: 'default_profile',
            render: (status) => (status ? <CheckSquareOutlined /> : <></>),
        }
    ];



    const onSelect = (object) => {
        setSelectedRecord(object)
    };

    return (
        <Container>
            <Table
                columns={columns}
                loading={loading}
                dataSource={data}
                rowSelection={{ type: "radio", onSelect }}
            />
        </Container>
    )
}

UsersDataTable.propTypes = {}

export default UsersDataTable
