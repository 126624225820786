import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { SubPageBreadCrumbs } from "Components/BreadCrumbs";
import { useParams } from "react-router-dom";
import { DataTable, Menu } from "./components";
import { useSubscription } from "@apollo/client";
import { SUB_PRODUCTS, SUB_SPECIES_PROCUREMENT } from "Util/subscriptions";
import leadingZeroes from "leading-zeroes";
import { appConstant } from "Util/constants";
import _ from "lodash";

const ProcurementActiveSpecies = (props) => {
  const params = useParams();
  const [pageState, setPageState] = useState({
    selectedUser: {},
  });

  const getWhere = () => {
    if (!params.type) {
      return {
        status: { _in: [true, false] }
      }
    } else {
      if (params.type === "rejected") {
        return {
          is_rejected: { _eq: true }
        }
      } else if (params.type === "active") {
        return {
          status: { _eq: true }
        }
      } else {
        return {
          status: { _eq: false }
        }
      }

    }
  }

  const { data, loading, error } = useSubscription(SUB_SPECIES_PROCUREMENT, {
    variables: {
      where: {
        ...getWhere(),
        deleted_at: { _is_null: true }
      }
    },
  });

  const formatData = (data) => {
    if (loading) { return []; }

    if (data.species) {
      return data.species.map((specie) => ({
        ...specie, ...specie.localizations
      }));
    }

    return [];
  };

  return (
    <div>
      <SubPageBreadCrumbs />
      <Menu />
      <DataTable
        data={loading ? [] : formatData(data)}
        loading={loading}
        pageSize={50}
        height={500}
      />
    </div>
  );
};

ProcurementActiveSpecies.propTypes = {};

export default ProcurementActiveSpecies;
