import React from 'react'
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types'
import ProductDropdown from 'Components/ProductDropdown'
import { Form, notification, Button, InputNumber, Row, Col } from 'antd'
import BidSpecs from 'Components/BidSpecs'
import { ADD_AUCTION } from 'Util/mutations';
import { appConstant } from 'Util/constants';

function CreateAuction({ form, onSucess }) {

    const [addAuction, { data }] = useMutation(ADD_AUCTION, {
        onCompleted(data) {
            console.log("data", data)
            notification['success']({
                message: 'Saved!',
                description:
                    'Auction details has been posted',
            });
        },
        onError(e) {
            console.log('erre', e)
        }
    })

    /** Validation Rules */
    const rule = [
        {
            required: true,
            message: "Field is required"
        },
    ]

    const createAuction = (data) => {

        const variables = {
            "objects": [
                {
                    "duration": data.duration,
                    "volume": data.volume,
                    "price": data.price,
                    "product_id": data.product_id,
                    "status": "active",
                    "bid_specs": JSON.stringify(data.bid_specs)
                }
            ]
        }

        console.log(variables)
        form.resetFields();
        addAuction({ variables })
    }

    return (
        <div className="auction-create" >
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                onFinish={createAuction}
            >
                <Form.Item
                    rules={rule}
                    label="Product" name="product_id">
                    <ProductDropdown />
                </Form.Item>
                <Form.Item
                    rules={rule}
                    label="Bid Specifications" name="bid_specs">
                    <BidSpecs />
                </Form.Item>
                <Form.Item
                    rules={rule}
                    label="Quantity" name="volume">
                    <InputNumber placeholder="KG" style={{ width: 150 }} />
                </Form.Item>
                <Form.Item
                    rules={rule}
                    label="Price" name="price">
                    <InputNumber placeholder={ appConstant.currencySymbol } style={{ width: 150 }} />
                </Form.Item>
                <Form.Item
                    rules={rule}
                    label="Duration" name="duration">
                    <InputNumber placeholder="Minutes" style={{ width: 150 }} />
                </Form.Item>
            </Form>

        </div>
    )
}

CreateAuction.propTypes = {

}

export default CreateAuction

