import React, { useState, createContext } from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { auctionMenu } from '../constants'
import { DataTable, TableFilters } from './components'
import ClockV2 from 'Components/ClockV2'

export const DirectSalesItemsContext = createContext()

const Container = styled.div``

function StagingBaket() {

    const [filter, setFilter] = useState('open')

    return (
        <DirectSalesItemsContext.Provider
            value={{
                filter, setFilter
            }}
        >
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
                {/* <ClockV2 position='absolute' /> */}
                {/* <TableFilters /> */}
                <DataTable />
            </Container>
        </DirectSalesItemsContext.Provider>
    )
}

export default StagingBaket