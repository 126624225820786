import axios from "axios"

export async function generateUploadUrl(data) {
    try {
        const apiUrl = 'https://api.catch-value.com/assets/presigned-url';
        //const apiUrl = `${process.env.REACT_APP_API}/assets/presigned-url`

        const response = await axios.post(apiUrl, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        return response.data.uploadUrl
    
    } catch (error) {
    console.error('Error:', error);
    }
}

export async function uploadImage(data, imageFile) {
  try {
    const uploadUrl = await generateUploadUrl(data)

    await axios.put(uploadUrl, imageFile, {
      headers: {
        'Content-Type': 'image/png'
      }
    });
    
  } catch (error) {
    console.error('Error:', error)
    throw error;
  }
}

