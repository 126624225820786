import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { DownOutlined, DollarOutlined, MailOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import IconPayVendor from 'Components/Icons/IconPayVendor';

const menu = (
    <Menu>
        <Menu.Item>
            <a target="_blank" href="#" > <IconPayVendor size={15} color={THEME.colors.primary} />
                <span style={{ marginLeft: 6 }}>Pay Vendor</span>
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" href="#" > <MailOutlined style={{ color: THEME.colors.primary }} />Send Email </a>
        </Menu.Item>
    </Menu>
);

const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        margin-left: 5px;
    }
    label{
        font-weight: bold;
    }
    .ant-space-item{
        color: #000;
        &:first-child{
            font-weight: bold;
        }
    }
`;

function ProcessVendor(props) {

    return (
        <Container>
            <Dropdown overlay={menu} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        Process
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Container>
    )
}

ProcessVendor.propTypes = {}

export default ProcessVendor