import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { variant } from 'styled-system'
import str from 'string-sanitizer'

const Container = styled.div``

const StatText = styled.label({
    fontWeight: 'bold',
    textTransform: 'capitalize'
},
    variant({
        prop: 'status',
        variants: {
            accepted: { color: 'green' },
            collected: { color: 'green' },
            rejected: { color: 'red' },
            counter: { color: 'purple' },
            pending: { color: 'orange' },
            submitted: { color: 'orange' },
            prebid: { color: 'blue' },
            results: { color: 'blue' },
            inprogress: { color: 'blue' }
        }
    })
)
function StatusText({ statusText }) {
    return <Container>
        <StatText status={str.sanitize(statusText).toLocaleLowerCase()} >{statusText}</StatText>
    </Container>
}

StatusText.prototype = {
    statusText: PropTypes.string.isRequired
}


export default StatusText
