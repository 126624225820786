import { Button, Input, Modal } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import './style.scss';

const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*?[#?!@$%^&*\-\+)\(\[\];:,'"\\/_|~`<>{}=])[A-Za-z\d@$!%*#)\(\[\]?&\-\+;:,'"\\/_|~`<>^{}=]{8,}$/
const inputInit = {
    password: '',
    c_password: ''
}

function Activate(props) {
    const { reset_token } = useParams();
    const [input, setInput] = useState(inputInit)
    const [err, setErr] = useState('')
    const [visible, setVisible] = useState(false)
    const [qr, setQr] = useState('')

    const submitHandler = () => {

        setErr('')

        if (_.isEmpty(input.password) || _.isEmpty(input.c_password)) {
            setErr('All fields are required.')
            return null
        }

        if (input.password !== input.c_password) {
            setErr('Password did not match')
            return null
        }

        if (!pattern.test(input.password)) {
            setErr('Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.')
            return null
        }

        axios.post(`${process.env.REACT_APP_AUTH_ENDPOINT}/admin/reset-password`, { reset_token, password: input.password }).then(res => {
            const { success, qrcode } = res.data
            if (success) {

                if (!qrcode) {
                    window.location.href = "/login"
                    return null
                }

                setVisible(true)
                setQr(qrcode)
            }
        })

    }

    return (
        <div className="activate-form-wrapper">

            <div className={'activate-form'} >

                <div className={'form-item center'}>
                    <img className={'logo'} src="/images/logo.png" />
                </div>

                <div className={'form-item label center'} >
                    <span>Set your new password to activate your account.</span> <br /><br />
                </div>

                <div className={'form-item'}>
                    <label>Password</label>
                    <Input value={input.password} type="password" onChange={(e) => { setInput({ ...input, password: e.target.value }) }} />
                </div>

                <div className={'form-item'}>
                    <label>Confirm Password</label>
                    <Input value={input.c_password} type="password" onChange={(e) => { setInput({ ...input, c_password: e.target.value }) }} />
                </div>

                <div className={'form-item center'} style={{ minHeight: 20, marginTop: 4 }}>
                    {err && <p className={'error-wrapper'} >ERROR: {err}</p>}
                </div>

                <div className={'form-item center'} >
                    <Button className={'submit-btn'} onClick={submitHandler} >Set Password</Button>
                </div>

            </div>

            <Modal
                onCancel={() => {
                    setVisible(false)
                    window.location.href = "/login"
                }}
                className={'qr-modal'}
                title="Set up Two Factor Authentication"
                visible={visible}
                closable={true}
                centered
                footer={null}
            >
                {!_.isEmpty(qr) && <img src={qr} />}
                <p>Scan the QR code to add authenticator to your account.</p>
                <p>Once done just close the dialog.</p>
            </Modal>

        </div >
    )
}

Activate.propTypes = {

}

export default Activate