import { Button, Drawer, Form } from 'antd'
import React, { useState, useContext } from 'react'
import CreateAuction from './components/Create';
import AuctionList from './components/List';
import './auction.scss'
import { AppContext } from 'AppContext';

function Index() {
    const [createDrawer, setCreateDrawer] = useState(false);
    const [createForm] = Form.useForm();
    const { appState } = useContext(AppContext)

    const createDrawerClose = ()=> {
        createForm.resetFields()
        setCreateDrawer(false); 
    }

    const createDrawerSubmit = () => {
        createForm.submit() 
    }

    return (
        <div className="auction-page" >

            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { setCreateDrawer(true) }} >Post Auction</Button>
            </div>

            <AuctionList />

            <Drawer
                visible={createDrawer}
                onClose={() => { setCreateDrawer(false) }}
                title="Post auction"
                width={750}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={ ()=>createDrawerClose() } style={{ marginRight: 8 }}>
                            Cancel
                      </Button>
                        <Button onClick={ ()=>createDrawerSubmit() } type="primary">
                            Submit
                      </Button>
                    </div>
                }
            >
                <CreateAuction form={createForm} />
            </Drawer>

        </div>
    )
}

export default Index
