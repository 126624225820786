import React from 'react'
import PropTypes from 'prop-types'
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';


const DoughnutChart = ({ label = 'My First Dataset' }) => {

    const data = {
        labels: [
            'New Vendors',
            'Target Achievement'
        ],
        datasets: [{
            label,
            data: [Math.random() * 100, Math.random() * 100],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
            ],
            hoverOffset: 4
        }]
    }

    return (
        <div style={{ width: 250 }}>
            <Doughnut data={data} />
        </div>

    )
}

DoughnutChart.propTypes = {
    label: PropTypes.string
}

export default DoughnutChart