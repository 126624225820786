import { appConstant, THEME } from "Util/constants";
import {
  Form,
  Input,
  notification,
  TimePicker
} from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useLazyQuery, useMutation } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import { UPSERT_VEHICLES } from "Util/mutations";
import { useHistory } from "react-router-dom";
import { Header } from "./components/VehicleCard";
import { GET_VEHICLE } from "Util/queries";
import moment from "moment";
import DriversDropDown from "Components/DriversDropdown";
import { addVehicle, updateVehicle, getVehicle } from 'Util/trackpod'
import CarriersDropDown from "Components/CarriersDropdown";
import DepotsDropDown from "Components/DepotsDropdown";

const Container = styled.div``;

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function VehicleCard(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  const editMode = routeLocation.pathname.includes("/logistics/vehicles/edit");
  const [formLoading, setFormLoading] = useState(false);

  const [update] = useMutation(UPSERT_VEHICLES, {
    onCompleted(data) {
      notification['success']({
        message: 'Saved!',
        description: `Vehicle has been saved!`,
      });
    }
  })

  const [getVehicleDetails] = useLazyQuery(GET_VEHICLE, {
    fetchPolicy: "network-only",
    onCompleted(data) {
        console.log('dataq', data);
        const details = data.vehicles[0]

        form.setFieldsValue({
            ...details,
            start_time: moment(details.start_time).tz('Asia/Kolkata'),
            carrier_name: details.carrier.name,
            carrier_code: details.carrier.code,
            driver_uuid: details.driver.uuid,
            depot_name: details.depot.name,
            depot_code: details.depot.code
        })
    }
  })

  useEffect(() => {
    if (editMode) {
      console.log('querying...')
      getVehicleDetails({ variables: { where: { id: { _eq: params.id } } }, });
    } 
  }, [editMode, getVehicleDetails, params.id]);

  const submitHandler = async (values) => {

    setFormLoading(true)

    const { driver_uuid, carrier_code, carrier_name, depot_name, depot_code, ...vehicleDetails } = values;
    let vehicle_id = null

    const updateObject = {
      "Id": values.vehicle_id,
      "Number": values.number,
      "CarrierCode": values.carrier_code,
      "Carrier": carrier_name,
      "Weight": values.weight,
      "Volume": values.volume,
      "Pallets": values.pallets,
      "DriverId": driver_uuid,
      "DepotId": depot_code,
      "Depot": depot_name,
      "StartTime": moment(values.start_time).format('HH:mm:ss'),
      "MaxWorkTime": values.work_time
    }

    const addObject = {
      "Number": values.number,
      "CarrierCode": values.carrier_code,
      "Carrier": carrier_name,
      "Weight": values.weight,
      "Volume": values.volume,
      "Pallets": values.pallets,
      "DriverId": driver_uuid,
      "DepotId": depot_code,
      "Depot": depot_name,
      "StartTime": moment(values.start_time).format('HH:mm:ss'),
      "MaxWorkTime": values.work_time
    }

    const getObject = {
      "Number": values.number
    }
    
    if (editMode){
      try {
        const res = await updateVehicle(updateObject) 
        console.log('res', res);
        const { Detail, Status, Title } = res
        console.log({ Detail, Status, Title});

        if(Status !== 202){
          notification["error"]({
            message: Title,
            description: Detail,
          });
          setFormLoading(false)
          history.goBack()
          return
        }
        
      } catch (error) {
        console.error('Error:', error);
        const { Detail, Title } = error.response.data

        notification["error"]({
          message: Title,
          description: Detail
        });

        setFormLoading(false)
        history.goBack()
        return
      }

    }
    else{
      try {
        const res = await addVehicle( addObject )
        console.log(res);
        const { Detail, Status, Title } = res
        console.log({ Detail, Status, Title});

        if(Status !== 201){
          notification["error"]({
            message: Title,
            description: Detail,
          });
          setFormLoading(false)
          history.goBack()
          return
        }

        const getRes = await getVehicle(getObject)
        vehicle_id = getRes[0].Id

      } catch (error) {
        console.error('Error:', error);
        const { Detail, Title } = error.response.data

        notification["error"]({
          message: Title,
          description: Detail
        });

        setFormLoading(false)
        history.goBack()
        return
      }
    }

    const objects = {
      ...vehicleDetails,
      vehicle_id
    }

    await update({ variables: { 
        update_columns: ["id", ...Object.keys(vehicleDetails)],
        objects 
      } 
    })

    setFormLoading(false)
    history.goBack()
  };

  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const handleDimensionChange = () => {
    const {dimensions} = form.getFieldsValue([['dimensions', "length"], ['dimensions', "width"], ['dimensions', "height"]])
    const volume = dimensions.length * dimensions.width * dimensions.height;
    form.setFieldsValue({ volume: volume})
  };

  return (
    <Container>
      <Header editMode={editMode}/>
      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="Vehicle Card">
          <div className="division">

            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>

            <Form.Item label="vehicleid" name="vehicle_id" style={{ display: 'none' }} >
              <Input />
            </Form.Item>

            <Form.Item label="Vehicle No." name="number" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Driver" name="driver_id" rules={[{ required: true, message: '' }]}>
                <DriversDropDown form={form}/>
            </Form.Item>

            <Form.Item name="driver_uuid" hidden>
              <Input/>
            </Form.Item>

            <Form.Item label="Carrier" name="carrier_id">
              <CarriersDropDown form={form}/>
            </Form.Item>

            <Form.Item name="carrier_name" hidden>
              <Input/>
            </Form.Item>

            <Form.Item name="carrier_code" hidden>
              <Input/>
            </Form.Item>

            <Form.Item label="Depot" name="depot_id" rules={[{ required: true, message: '' }]} >
              <DepotsDropDown form={form}/>
            </Form.Item>

            <Form.Item name="depot_name" hidden>
              <Input/>
            </Form.Item>

            <Form.Item name="depot_code" hidden>
              <Input/>
            </Form.Item>

            <Form.Item label="Payload Dimensions">
              <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Form.Item name={['dimensions', "length"]} noStyle initialValue={0}>
                  <Input type="number" addonBefore="L:" addonAfter="mm" min={1} onChange={handleDimensionChange}/>
                </Form.Item>
                <Form.Item name={['dimensions', "width"]} noStyle initialValue={0}>
                  <Input type="number" addonBefore="W:" addonAfter="mm" min={1} onChange={handleDimensionChange}/>
                </Form.Item>
                <Form.Item name={['dimensions', "height"]} noStyle initialValue={0}>
                  <Input type="number" addonBefore="H:" addonAfter="mm" min={1} onChange={handleDimensionChange}/>
                </Form.Item>         
              </div>
            </Form.Item>

            <Form.Item label="Payload Volume (cubic m)" name="volume" >
              <Input placeholder="Auto Generated" readOnly/>
            </Form.Item>

            <Form.Item label="Weight" name="weight" >
              <Input type="number"/>
            </Form.Item>

            <Form.Item label="Start Time" name="start_time" >
                <TimePicker 
                    placeholder={appConstant.timeFormat2}
                    format={appConstant.timeFormat2}
                    showNow={false} 
                    style={{ width: '100%' }}
                />
            </Form.Item>

            <Form.Item label="Work Time (h)" name="work_time" initialValue={0} >
              <Input type="number" min={1} />
            </Form.Item>
          </div>

        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit" >
            {editMode ? "Update" : "Save"}
          </ButtonLoader>

          <CommonButton className='reverse' onClick={() => history.goBack()}>
            Cancel
          </CommonButton>

        </CommonDiv>
      </Form>
    </Container>
  );
}

VehicleCard.propTypes = {};

export default VehicleCard;
