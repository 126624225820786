import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import styled from "styled-components";
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
margin: 15px 0px;
    .ant-divider-horizontal{
        margin: 0 0 20px 0;
        border-top: 3px solid #ccc;
    }

    .ant-breadcrumb{
        span{
            font-size: 20px;
            font-weight: 500;
        }
    }
`;

const SubPageBreadCrumbs = props => {

    const location = useLocation();
    const data = location.pathname.split('/')
    data.splice(0, 1)
    let prevLink = ''

    return (
        <Container className='dash-divider'>

            <Breadcrumb separator=">">
                {
                    data.map(v => {
                        prevLink = `${prevLink}/${v}`;
                        return <Breadcrumb.Item key={v}>
                            <Link to={prevLink}>{v.toLocaleUpperCase()}</Link>
                        </Breadcrumb.Item>
                    })
                }
            </Breadcrumb>
            <Divider />
        </Container>
    )
}

SubPageBreadCrumbs.propTypes = {}

export default SubPageBreadCrumbs