import { useState, useEffect } from 'react';
import { Switch, Popconfirm } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SETTINGS } from 'Util/queries';
import { UPDATE_SETTINGS } from 'Util/mutations';
import { THEME } from 'Util/constants';
import _ from 'lodash';
import { auctionAutoBidAtom } from 'Store/auctionAtom';
import { useRecoilState } from 'recoil';

function AutoBidSwitch(){

    //const [check, setCheck] = useState()
    const [check, setCheck] = useRecoilState(auctionAutoBidAtom)
    let autoBidAccept = '';

    const { data, loading } = useQuery(GET_SETTINGS, {
        fetchPolicy: "network-only",
        variables: {
            where: { key: { _eq: "auto_bid_accept"} }
        }
    });

    const [updateSettings] = useMutation(UPDATE_SETTINGS, {
        onCompleted(data) {
        },
        onError(e) {
            console.log('erre', e)
        }
    })

    let autoBidAcceptSettings = data?.settings[0]?.values;

    if (!_.isUndefined(autoBidAcceptSettings)) {
        autoBidAccept = autoBidAcceptSettings === 0 ? false : true;
    }

    useEffect(() => {       
        if (_.isEmpty(check)){
            setCheck(autoBidAccept)
        }
    }, [autoBidAcceptSettings])


    if (loading) return <></>

    const handleConfirm = () => {
        let value = !check
        setCheck(value)
        updateSettings({
            variables: {
                key: "auto_bid_accept",
                values: value ? 1 : 0
            }
        })
    }

    const backGroundColor = () => {
        if (!_.isNull(check)){
            return check ? THEME.colors.primary : THEME.colors.grey
        }
        return autoBidAccept ? THEME.colors.primary : THEME.colors.grey
    }

    return <>
        <label> Auto Accept:  </label>
        <Popconfirm placement="left" title='Are you sure you want to do this?' onConfirm={handleConfirm} okText="Yes" cancelText="No">
            <Switch checked={_.isNull(check) ? autoBidAccept : check} onClick={(v) => setCheck(!v)} defaultChecked style={{background: backGroundColor()}}/>
        </Popconfirm>
    </>
}

export default AutoBidSwitch;