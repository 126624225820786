import React, { useContext } from 'react'
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, notification, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_JOBS, UPDATE_PURCHASE_ORDER_JOB_ID } from 'Util/mutations'
import moment from 'moment'
import { LogisticsJobContext } from '../..';
import { deleteOrder } from 'Util/trackpod';

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }  
`;

function Delete(props) {
    const { selectedRecord, setSelectedRecord } = useContext(LogisticsJobContext)

    const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER_JOB_ID)
    const [updateJobs] = useMutation(UPDATE_JOBS, {
        onCompleted(){
            notification["success"]({
                message: 'Deleted',
                description: "Job has been deleted"
            });
        }
    })
  
    const confirmDelete = async () => {  
        /** delete API to trackpod */
        await deleteOrder({ "Number": selectedRecord.id });

        try {
            const res =  await deleteOrder({ "Number": selectedRecord.id });
            const { Detail, Status, Title } = res
            console.log({ Detail, Status, Title });
    
            if (Status !== 200 && Status !== 404) { //404: proceeds to delete in console if job is not found in TrackPod
              notification["error"]({
                message: Title,
                description: Detail,
              });
              return
            }
          } catch (error) {
            console.log('errorerrorerror', error);
            if (error.response) {
              console.error('Error status code:', error.response.status);
              if (error.response.status === 500) {
                console.error('Error 500: Internal Server Error');
              }
            } else {
              console.error('Error occurred:', error);
            }
            notification["error"]({
              message: "Something went wrong!",
              description: "Please try again later.",
            });
            return Promise.resolve();
        }

        const ids = selectedRecord.purchase_orders.map(item => item.id);

        await updateJobs({
            variables: {
              where: { id: { _eq: selectedRecord.id } },
              _set: { deleted_at: moment() }
            }
        })

        updatePurchaseOrder({
            variables: {
              where: { id: { _in: ids } },
              _set: { job_id: null }
            }
        })

        setSelectedRecord({})
    }

    const confirm = () => {
        selectedRecord.id && Modal.confirm({
            centered: true,
            icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
            content: `Delete Job #${selectedRecord.id}?`,
            autoFocusButton: 'ok',
            okButtonProps: {
                style: { backgroundColor: THEME.colors.primary }
            },
            onOk: confirmDelete
        });
    };

    if (!selectedRecord) return <DeleteFilled />

    return (
        <Container>
            <Tooltip title={selectedRecord.id}>
                <DeleteFilled onClick={confirm} />
                <label>Delete</label>
            </Tooltip>
        </Container>
    )
}

Delete.propTypes = {}

export default Delete