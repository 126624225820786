import React, { useRef, useState } from 'react';
import axios from 'axios';

function ApiRequest() {
    const methodRef = useRef(null);
    const urlRef = useRef(null);
    const payloadRef = useRef(null);
    const [error, setError] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const method = methodRef.current.value;
        const url = urlRef.current.value;
        let payload;
        try {
            payload = JSON.parse(payloadRef.current.value);
        } catch (e) {
            setError('Payload must be a valid JSON object');
            return;
        }
        setError(null);

        axios({
            method: method,
            url: url,
            data: payload
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.error(error);
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="method">Method:</label>
                <select ref={methodRef} id="method">
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                </select>
            </div>
            <div>
                <label htmlFor="url">URL:</label>
                <input ref={urlRef} id="url" type="text" />
            </div>
            <div>
                <label htmlFor="payload">Payload:</label>
                <textarea ref={payloadRef} id="payload" rows="5"></textarea>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
            <button type="submit">Submit</button>
        </form>
    );
}

export default ApiRequest;
