
import React, { useState } from 'react'
import { Level2Menu } from 'Components/Menus'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { CollectionPointsContext } from '.';
import { DataTable, TableFilters } from './components';

const Container = styled.div``

const menu = [
    { title: "Master", link: "/procurement/collection-points" },
    { title: "Pending Approval", link: "/procurement/collection-points/pending-approval" },
    { title: "Quotas", link: "/procurement/collection-points/quotas" },
]

function CollectionPoints() {
    const { type } = useParams()

    const [selectedRecord, setSelectedRecord] = useState({})
    const [filter, setFitler] = useState({ is_active: true })

    switch (type) {
        case "active-collection-points":

            break;

        default:
            break;
    }

    return (
        <CollectionPointsContext.Provider value={{
            selectedRecord, setSelectedRecord,
            filter, setFitler
        }}>
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={menu} />
                {/* <TableFilters /> */}
                <DataTable />
            </Container>
        </CollectionPointsContext.Provider>

    )
}

export default CollectionPoints