import React from 'react'
import PropTypes from 'prop-types'
import DashTitleDivider from 'Components/DashTitleDivider'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import ButtonCard from 'Components/ButtonCard'

const AdminPage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            <div style={{ display: 'flex' }} >
                <ButtonCard link='/admin/home' title={'Home'} blue />
                <ButtonCard link='/admin/finance' title={'Finance'} blue />
                <ButtonCard link='/admin/cash-management' title={'Cash Management'} blue />
                <ButtonCard link='/admin/sales' title={'Sales'} blue />
                <ButtonCard link='/admin/procurement' title={'Procurement'} blue />
                <ButtonCard link='/admin/voucher-interface' title={'Voucher Interface'} blue />
                <ButtonCard link='/admin/taxation' title={'Taxation'} blue />
                <ButtonCard link='/admin/roles' title={'Roles'} blue />
            </div>

        </div>
    )
}

AdminPage.propTypes = {}

export default AdminPage