import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { FilterFilled, InfoCircleFilled, UnorderedListOutlined, AppstoreOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { IconShare } from 'Components/Icons';
import { useRecoilState } from 'recoil';
import { listOrderAtom, infoIconAtom, selectedProduct} from 'Store/procurementAtom';
import CommonExportIcon from 'Components/CommonExportIcon'
import InfoBoxSwitch from 'Components/InfoBoxSwitch'

const Container = styled.div`
    
    width: ${props => props.state === 'list' ? '55px' : '35px'};
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        cursor: pointer;
    }
    
    svg{
        cursor: pointer;
    }

    `;

function ExtraFilters(props) {
    const [state, setState] = useRecoilState(listOrderAtom)
    const [product, setSelectedProduct] = useRecoilState(selectedProduct);
    const changeOrder = () => {
        setSelectedProduct({})
        if(state === 'list') setState('grid')
        else setState('list')
    }
    const resetSelection = () => {
        setSelectedProduct({})
    }
    return (
        <Container state={state}>
            <CommonExportIcon data={props.data} columns={props.columns}/>
            {state === 'list' ?
            <UnorderedListOutlined onClick={changeOrder}/>:
            <AppstoreOutlined onClick={changeOrder}/>
            }
            {state === 'list' && <InfoBoxSwitch origin='products' resetSelection={resetSelection}/>}
        </Container>
    )
}

ExtraFilters.propTypes = {}

export default ExtraFilters