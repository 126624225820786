
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { PlusOutlined, EditOutlined, DeleteOutlined, SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { SUB_SPECIES_PROCUREMENT } from 'Util/subscriptions';
import { debounce, filter, includes } from 'lodash';
import { UPDATE_SPECIES } from 'Util/mutations'
import { useMutation } from '@apollo/client';
import moment from 'moment'

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 14.2%;
            text-align: left;
            flex: 1 1 auto;
            font-size: 12px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            white-space: nowrap;
        }
        display: flex;
        flex-direction:row;
    }

    .thead{
      position: sticky; 
      top: 0; 
      background-color: #f2f2f2;
        span{
            font-weight: bold;
            color: #555;
            position: sticky;
            top: 0;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
               // white-space: normal;
            }
        }
    }
`
function ChooseSpecies(props) {
  const history = useHistory()
  const [selected, setSelected] = useState('')
  const [search, setSearch] = useState('')

  const { data, loading, error } = useSubscription(SUB_SPECIES_PROCUREMENT, {
    variables: {
      where: {
        status: { _eq: true },
        deleted_at: { _is_null: true }
      }
    },
  });

  const updateQuery = (e) => {
    setSearch(e.target.value)
  }
  
  const debouncedOnChange = debounce(updateQuery, 200);

  const filteredData =(data) => {

    const searchable = data?.species.map(item =>({
      ...item,
      searchText: `${item.label} ${item.localizations.malayalam} ${item.localizations.tamil} ${item.localizations.bengali} ${item.localizations.marathi} ${item.localizations.gujarati} ${item.localizations.telugu} ${item.description}`
    }))

    if(!search){
      return data?.species
    }

    return filter(searchable, function (d) { 
        let query = search.toLowerCase();
        return includes(d.searchText.toLowerCase(), query)
    }); 
  }

  const { state, setState, setFieldValue, setImage } = props

  const okHandler = () => {
    const itemCodes = [selected.species_type_details, selected.species_environment_details, selected.species_sub_category_details, selected.label, selected.species_sizing_details, selected.process_category_details ]
    setState({ ...state, showSpecies: false });
    setFieldValue("species_id", selected.id)
    setFieldValue("species_name", selected.species)
    setFieldValue("label", selected.label, selected.label)
    setFieldValue("unit_of_measurement", selected.uom)
    setFieldValue("product_environment", selected.species_environment_details?.description)
    setFieldValue("product_sub_category", selected.species_sub_category_details?.description)
    setFieldValue("process_category", selected.process_category_details?.description)
    setFieldValue("product_type", selected.species_type_details?.description)
    setFieldValue("product_size", selected.species_sizing_details?.description)
    setFieldValue("category", selected.category)
    setFieldValue("remarks", selected.remarks)
    setFieldValue("sku", '', itemCodes)
    setImage(selected.image)
  }

  const [deleteProduct] = useMutation(UPDATE_SPECIES);
  
  const confirmDelete = () => {  
    deleteProduct({
      variables: {
        id: selected.id,
        set: {
          deleted_at: moment()
        }
      }
    })
  }

  const confirm = () => {
      Object.keys(selected).length > 0 > 0 && Modal.confirm({
          centered: true,
          icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
          content: `Delete ${selected.label}?`,
          autoFocusButton: 'ok',
          okButtonProps: {
              style: { backgroundColor: THEME.colors.primary }
          },
          onOk: confirmDelete
      });
  };

  return (
    <Container>
      <Modal
        visible={state.showSpecies}
        centered
        onCancel={() => setState({ ...state, showSpecies: false })}
        onOk={okHandler}
        title={
          <div style={{ flexDirection: "row", display: "flex" }}>
            <span style={{ marginRight: 50 }}>Select - Species Database</span>
            <Container>
              <Input defaultValue="" prefix={<SearchOutlined />} style={{ width: 200 }} placeholder="Search" onChange={(e) => debouncedOnChange(e)} />
              <PlusOutlined onClick={() => history.push(`/procurement/species-database/new/species`)} style={{ marginLeft: 20 }}/>New 
              <EditOutlined onClick={() => Object.keys(selected).length > 0 && history.push(`/procurement/species-database/new/edit/${selected.id}`)} style={{ marginLeft: 20 }}/>Edit List 
              <DeleteOutlined onClick={confirm} style={{ marginLeft: 20 }}/>
            </Container>
          </div>
        }
        okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
        width={1400}
        height={600}
        closable={false}
        bodyStyle={{ background: '#F2F2F2' }}
      >
        <ModalContainer>
        <div style={{ maxHeight: 300, overflow: "auto", maxWidth: 1400}}>

          <div className='thead' >
            <span> Species Name </span>
            <span> Malayalam </span>
            <span> Tamil </span>
            <span> Bengali </span>
            <span> Marathi </span>
            <span> Gujarati </span>
            <span> Telugu </span>
            {/* <span> Species Description </span> */}
          </div>

            {data?.species?.length > 0 &&
              filteredData(data).map((item) => (
                <div onClick={() => setSelected(item)} className={`tbody ${selected.id === item.id ? 'selected' : ''}`} key={`row-species-item-${item.id}`}>
                  <span> {item.label} </span>
                  <span> {item.localizations.malayalam} </span>
                  <span> {item.localizations.tamil} </span>
                  <span> {item.localizations.bengali} </span>
                  <span> {item.localizations.marathi} </span>
                  <span> {item.localizations.gujarati} </span>
                  <span> {item.localizations.telugu} </span>
                  {/* <span className='desc'> {item.description} </span> */}
                </div>
              ))
            }
          </div>

        </ModalContainer>
      </Modal>
    </Container>
  )
}

ChooseSpecies.propTypes = {}

export default ChooseSpecies
