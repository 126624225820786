import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useState } from 'react';
import { GET_DEPOTS } from 'Util/queries';
const { Option } = Select;

function DepotsDropDown({ onChange, form, ...rest }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_DEPOTS, {
        variables: {
            where: {
                deleted_at: { _is_null: true },
                active: { _eq: true }
            }
        },
        onCompleted() {
            setList(data.depots)
        }
    });

    const handleChange = (value) => {
        const selected = list.find(item => item.id === value);
        if (form) {
            form.setFieldsValue({
                depot_id: selected.id,
                depot_name: selected.name,
                depot_code: selected.code
            });
        }
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div>
            <Select
                {...rest}
                placeholder="Select a depot"
                onChange={handleChange}
            >
                {list.map(v => <Option value={v.id} key={`depots-dd-opt${v.id}`}>{v.name}</Option>)}
            </Select>
        </div>
    )
}

export default DepotsDropDown
