import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { EditFilled } from '@ant-design/icons';
import { selectedVendorAtom } from "Store/crmAtom";
import { useRecoilState } from 'recoil';
import { useHistory } from "react-router-dom";
import { LogisticsJobsContext } from '../..';

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;


function Edit(props) {
    const { selectedRecord } = useContext(LogisticsJobsContext)
    const history = useHistory()

    const onClick = () => {
        if (!selectedRecord.id) return
        history.push(`/logistics/depots/edit/${selectedRecord.id}`, { prevPath: '/logistics/depots'} )
    };

    return (
        <Container>
            <EditFilled onClick={onClick} />
            <label>Edit</label>
        </Container>
    )
}

Edit.propTypes = {}

export default Edit