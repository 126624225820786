import { useMutation } from '@apollo/client'
import { Form, Input, notification, InputNumber } from 'antd'
import CommonButton, { ButtonLoader } from 'Components/CommonButton'
import CommonDiv from 'Components/CommonDiv'
import CardHeader from 'Components/CommonHeader/CardHeader'
import { FormDivision, FormSection } from 'Components/Form'
import CardSubMenu from 'Menus/CardSubMenu'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash'
import styled from 'styled-components'
import { UPDATE_PRODUCT } from 'Util/mutations';
import { auctionQuotaSelectedRecordAtom } from 'Store/auctionAtom';
import { useRecoilState } from 'recoil';
import { THEME } from 'Util/constants'

const Container = styled.div`
    .ant-picker{
        width: 200px;
    }
    .ant-form-item-control-input{
        width: 200px;
    }
`

const LabelWrapper = styled.div`
    display: flex;
    width: 600px;
    gap: 20px;
    label{
        width: 200px;
        display: block;
        margin-right: 10px;
        font-weight: bold;
        font-size: 16px;
    }
    
`
const InputWrapper = styled.div`
    display: flex;
    width: 620px;
    &>div{
        width: 200px;
        margin-top: 10px;
        margin-right: 10px;
    }
`

function EditCard() {

    const history = useHistory()
    const [selectedData, setSelectedData] = useRecoilState(auctionQuotaSelectedRecordAtom)
    const [cardName, setCardName] = useState("New")
    const [disable, setDisable] = useState(false)

    const [form] = Form.useForm()

    const { day_quota, cum_pur_vol, reserved_price  } = selectedData?.data
    const { auction_quota, id } = selectedData?.product_data

    const auctionData = selectedData.auction_data.map(auction => {
        const quota = _.find(auction_quota, ['aid', auction.id])
        const price = !_.isUndefined(quota) ? quota?.price : 0;
        return {
            id: auction.id,
            auction: selectedData.data[`auction_${auction.id}`],
            res: price
        }
    })

     const [update] = useMutation(UPDATE_PRODUCT, {
        // onCompleted() {
        //     notification['success']({
        //         message: 'Saved!',
        //         description:
        //             'Product has been updated!',
        //     });
        // },
        onError(e) {
            console.log('erre', e)
        }
    });

    const submitHandler = async (values) => {
        const auctionQuotaObj = selectedData.auction_data.map((obj, id) => {
            const quota = values[`auction_${obj.id}`] === 'CLOSED' ? 0 : values[`auction_${obj.id}`];
            const price = values[`res_${obj.id}`] === 'CLOSED' ? reserved_price : values[`res_${obj.id}`];
            return {aid: obj.id, price, quota }
        })
        
        const auctionQuotaChanges = JSON.stringify(auction_quota) !== JSON.stringify(auctionQuotaObj)
        const dayQuota = values.day_quota !== day_quota

        if(auctionQuotaChanges || dayQuota){
            const object = {
                quota: values.day_quota,
                auction_quota: auctionQuotaObj
            }
            update({ variables: { id, object} })
        }
        setTimeout(() => { history.goBack() }, 1000)
    }
    
    const cancelHandler = () => {
        history.goBack()
    }

    const errorHandler = (err) => {

    }

    useEffect(() => {
        form.setFieldsValue({ day_quota, cum_day_purchase: cum_pur_vol })
    }, [])

  const handleChange = async (value, fieldname) => {
    try {
        await form.validateFields().then(values => {
            const prefix = 'auction';
            const totalAuctionQuota = Object.keys(values).reduce((result, key) => {
                if (key.startsWith(prefix) && typeof values[key] === 'number' && Number.isInteger(values[key])) {
                  return result + values[key];
                }
                return result;
              }, 0);
            const quota = parseInt(values.day_quota) - values.cum_day_purchase          
            const diff = quota - totalAuctionQuota

            if(totalAuctionQuota !== quota){
                form.setFields([
                    {
                      name: fieldname,
                      errors: (fieldname === 'day_quota' ? diff > 0 : diff < 0) ? 
                                [<span style={{ fontSize: '12px' }}>Please remove {Math.abs(diff)}.</span>]
                              : [<span style={{ fontSize: '12px' }}>Please add {Math.abs(diff)}.</span>]
                    },
                  ]);
            }
        });
        const err = form.getFieldsError()
        const hasError = err.filter(({ errors }) => errors.length).length > 0
        setDisable(hasError);           
    } catch (errInfo) {
        console.log("Failed:", errInfo);
    }
  }
    return (
        <Container>

            <CardHeader
                title='Product card' // TODO: Add icon
                itemName={cardName}
            />

            <CommonDiv direction="row">
                <h3>{selectedData?.data?.item_code}</h3>
                <span style={{ padding: '0px 10px' }} >|</span>
                <h3>{selectedData?.data?.item_description}</h3>
            </CommonDiv>

            <CardSubMenu items={[
                <label>Process</label>,
                <label>Request Approval</label>,
                <label>More Options</label>,
            ]} />


            <Form
                form={form}
                name="new-schedule-form"
                onFinish={submitHandler}
                onFinishFailed={errorHandler}
                initialValues={auctionData.reduce((acc, item) => {
                    acc[`auction_${item.id}`] = item.auction
                    acc[`res_${item.id}`] = item.auction === 'CLOSED' ? "CLOSED" : item.res
                    return acc;
                  }, {})}
            >
                <FormSection title='Quotas & Reserve Price'>

                    <div style={{ display: 'flex', justifyContent: "space-around", maxWidth: 4000 }}>
                        <FormDivision>

                            <Form.Item label="Day Quota" name="day_quota" rules={[{ required: true, message: `Day Quota is required.`}]}>
                                <InputNumber onChange={(value) => handleChange(value, 'day_quota')} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item label="Cum Day Purchase" name="cum_day_purchase" >
                                <Input readOnly />
                            </Form.Item>

                        </FormDivision>

                        <FormDivision>

                            <LabelWrapper>
                                <label>Auction Quota</label>
                                <label>Quota</label>
                                <label>Reserve Price</label>
                            </LabelWrapper>

                            {
                                auctionData.sort((a, b) => a.id - b.id ).map(auction => {
                                    const q = selectedData.data[`auction_${auction.id}`]
                                    return <InputWrapper>
                                        <div>{`Auction #${auction.id}`}</div>

                                        <div>
                                            <Form.Item name={`auction_${auction.id}`} rules={[{ required: true, message: `Quota is required.`}]}>
                                            <InputNumber onChange={(value) => handleChange(value, `auction_${auction.id}`)} 
                                                        style={{ width: "100%", color: q === "CLOSED" ? THEME.colors.gray : THEME.colors.primary }} 
                                                        readOnly={q === "CLOSED"}/>
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item name={`res_${auction.id}`} rules={[{ required: true, message: `Reserved Price is required.`}]}>
                                            <InputNumber style={{ width: "100%", color: q === "CLOSED" ? THEME.colors.gray : THEME.colors.primary }} readOnly={q === "CLOSED"} />
                                            </Form.Item>
                                        </div>
                                    </InputWrapper>
                                })
                            }

                        </FormDivision>
                    </div>

                </FormSection>
                <CommonDiv direction="row" justify="flex-end">
                    <ButtonLoader loading={false} htmlType="submit" disabled={disable}>
                        Save
                    </ButtonLoader>
                    <CommonButton className='reverse' onClick={cancelHandler} >
                        Cancel
                    </CommonButton>
                </CommonDiv>
            </Form>
        </Container>
    )
}

export default EditCard