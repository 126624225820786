import React, { useState, useEffect } from "react";
import { Form, Input, Table } from "antd";
import { THEME } from 'Util/constants'
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { auctionQuotaSelectedRecordAtom } from 'Store/auctionAtom';

const StyledDiv = styled.div`
    padding: 5px 2px;
    cursor: pointer;
    position: relative;
  
`;

const StyledTable = styled(Table)`
    .editable-row {
        padding: 4px 1px;
        border: 1px solid #d9d9d9;
        border-radius: 2px; 
    }

    .editable-row:hover .editable-cell-value-wrap {
        padding: 4px 1px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
`;

const EditableCell = ({
    record,
    dataIndex,
    title,
    editing,
    editable,
    cancel,
    onChange,
    children,
  ...restProps
}) => {
    
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0
              }}
              rules={[
                {
                  required: true,
                  message: <span style={{ fontSize: '12px', whiteSpace: 'nowrap', zIndex: '9999', position: 'relative' }}>{title} is required.</span> 
                }
              ]}
            >
              <Input type='number' onChange={() => onChange(dataIndex)} style={{color: THEME.colors.primary}}/>
            </Form.Item>
          ) : (
            <StyledDiv
            className="editable-cell-value-wrap"
          >
            {children}
          </StyledDiv>
        )     
    }
  return (
    <td {...restProps}>{childNode}</td>
  );
};

const EditableTable = (props) => {
    const {loading, size, dataSource, pagination, columns, isEditable} = props

    const [selectedData, setSelectedData] = useRecoilState(auctionQuotaSelectedRecordAtom)
    const [editingKey, setEditingKey] = useState("");

    const [form] = Form.useForm();
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        if(isEditable && (record.key !== editingKey)){
            form.setFieldsValue({
                ...record
            });
            setSelectedData(record)  
            setEditingKey(record.key);
        }
    };

    const cancel = () => {
        setEditingKey("");
        setSelectedData("")
    };
    
    useEffect(() => {
        if(!selectedData){
            setEditingKey('')
        }
    }, [selectedData])

    useEffect(() => {
        setSelectedData('')
        function handleKeyDown(event) {
          if (event.key === 'Escape') {
            cancel()
          }
        }  
        document.addEventListener('keydown', handleKeyDown);   
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };    
    }, [])
    
    const onChange = async (field) => {
        try {
            const row = await form.validateFields().then(values => {
                const prefix = 'auction';

                const auctionQuotas = Object.entries(values).filter(([key]) => key.startsWith(prefix))
                const totalAuctionQuota = auctionQuotas.reduce((acc, [, val]) => acc + parseInt(val), 0);
                const quota = parseInt(values.day_quota) - selectedData.cum_pur_vol
                const diff = quota - totalAuctionQuota

                if((auctionQuotas.length > 0) && totalAuctionQuota !== quota && field !== 'reserved_price'){
                    form.setFields([
                        {
                          name: field,
                          errors: (field === 'day_quota' ? diff > 0 : diff < 0) ? 
                                    [<span style={{ fontSize: '12px', whiteSpace: 'nowrap', zIndex: '9999', position: 'relative' }}>Please remove {Math.abs(diff)}.</span>]
                                  : [<span style={{ fontSize: '12px', whiteSpace: 'nowrap', zIndex: '9999', position: 'relative' }}>Please add {Math.abs(diff)}.</span>]
                        },
                      ]);
                }else{
                    return values
                }
            });

            const newData = [...dataSource];
            const index = newData.findIndex((item) => selectedData.key === item.key);

            if (typeof row === 'undefined') {
                const previousData = newData[index];
                setSelectedData(previousData);
            }else{
                const newData = [...dataSource];

                const res = {}
                for (const key in row) {
                    res[key] = {};
                    const parsed = parseInt(row[key]);
                    res[key] = isNaN(parsed) ? row[key] : parsed;
                }           
                
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                      ...item,
                      ...res
                    });
                  } else {
                    newData.push(res);
                  }
                setSelectedData(newData[index]);
            }
              
        } catch (errInfo) {
          console.log("Failed:", errInfo);
        }
    };
 
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            editable: col.editable,
            cancel,
            onChange
            })
        };
    });
  
    return (
        <Form form={form} component={false}>
        <StyledTable
            components={{
            body: {
                cell: EditableCell
            }}}
            onRow={(rowData, rowIndex) => ({
                onDoubleClick: () => edit(rowData),
            })}
            size = {size}
            dataSource={dataSource}
            columns={mergedColumns}
            rowClassName={isEditable && "editable-row"}
            pagination={{
            onChange: cancel,
            ...pagination
            }}
            loading={loading}
            scroll={{ x : 0 }}
        />
        </Form>
    );
};
export default EditableTable;

