import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Table, Typography, notification } from 'antd';
import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants';
import { useMutation, useSubscription } from '@apollo/client';
import { SUB_ADMIN_USER_ROLES } from 'Util/subscriptions';

function AdminRoleList({ onEdit }) {

    const { data, loading } = useSubscription(SUB_ADMIN_USER_ROLES);

    const edit = (record) => {
        onEdit(record)
    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const columns = [
        {
            title: 'Role',
            dataIndex: 'name',
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
        },
        {
            title: 'Modules',
            dataIndex: 'modules_names',
            editable: true,
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <Typography.Link onClick={() => edit(record)}>
                    Edit
                </Typography.Link>
            ),
        },
    ];


    const dataSource = () => {

        if (data) {

            return data.admin_user_role.map((v, i) => (
                {
                    key: `key-${i}-admin-user-role-list`,
                    id: v.id,
                    name: v.name,
                    description: v.description,
                    modules: v.modules,
                    modules_names: v.modules.map(module => module.name).join(" | ")

                }
            ))
        }

        return []

    }

    return (
        <div className='admin-user-list'>
            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    )
}

AdminRoleList.propTypes = {

}

export default AdminRoleList