import React from 'react'
import PropTypes from 'prop-types'
import DashTitleDivider from 'Components/DashTitleDivider'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import ButtonCard from 'Components/ButtonCard'

const LogisticstPage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            <div style={{ display: 'flex' }} >
                <ButtonCard link='/logistics/routes' title={'Routes'} blue />
                <ButtonCard link='/logistics/jobs' title={'Jobs'} blue />
                <ButtonCard link='/logistics/vehicles' title={'Vehicles'} blue />
                <ButtonCard link='/logistics/drivers' title={'Drivers'} blue />
                <ButtonCard link='/logistics/depots' title={'Depots'} blue />
                <ButtonCard link='/logistics/shippers' title={'Shippers'} blue />
                <ButtonCard link='/logistics/carriers' title={'Carriers'} blue />
                <ButtonCard link='/logistics/reports' title={'Reports'} blue />
                <ButtonCard link='/logistics/user-groups' title={'User Groups'} blue />
            </div>
        </div>
    )
}

LogisticstPage.propTypes = {}

export default LogisticstPage