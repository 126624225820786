import { QuestionCircleOutlined } from '@ant-design/icons';
import { THEME } from 'Util/constants';
import { Modal } from 'antd';
import React from 'react';

const CommonConfirmModal = ({ onOk, onCancel, content = "", title = "", okText = 'Ok', cancelText = 'Cancel' }) => {
    return Modal.confirm({
        centered: true,
        closable: true,
        title,
        icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
        content,
        autoFocusButton: 'ok',
        okText,
        okButtonProps: {
            style: { backgroundColor: THEME.colors.primary }
        },
        cancelText,
        cancelButtonProps: {
            style: { backgroundColor: 'red', color: '#fff' }
        },
        onOk,
        onCancel
    });
}


CommonConfirmModal.propTypes = {}

export default CommonConfirmModal
