import CommonButton from 'Components/CommonButton'
import CommonDiv from 'Components/CommonDiv'
import React, { useContext, useState } from 'react'
import { Table } from 'antd';
import { useQuery } from '@apollo/client';
import { THEME } from 'Util/constants';
import SelectPurchaseOrder from './SelectPurchaseOrder';
import { DeleteFilled } from '@ant-design/icons';
import { GET_JOBS } from 'Util/queries';
import CommonConfirmModal from 'Components/Modal/ModalConfirm';
import { RoutesCardContext } from '../../RoutesCard';

function JobItems(props) {
    const { setFieldValue, routeId } = props
    const [select, showSelect] = useState(false)
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const {jobList, setJobList} = useContext(RoutesCardContext)

    const { loading } = useQuery(GET_JOBS, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                route_id: { _eq: routeId ?? 0 },
            }
        },
        onCompleted(data) {    
            console.log('data.jobs', data.jobs);
            const newData = data.jobs.map(item => {
                const totalVolume = item.purchase_orders.reduce((total, order) => {
                    const volume = order.bid?.volume || order.direct_offer_order?.order_quantity || 0;
                    return total + volume;                
                }, 0);
                return {
                    key: `key-${item.id}-po-list`,
                    id: item.id,
                    client_name: item.client,
                    address: item.address,
                    total: item.purchase_orders_aggregate.aggregate.count,
                    weight: totalVolume,
                    status: item.job_status,
                };
            });
            setData(newData);
            setJobList(data.jobs)
            setFieldValue('jobs', newData.map(item => item.id));

        }
    });
    
    const handleDelete = (id) => {
        CommonConfirmModal({
            content: `Are you sure you would like to delete Job# ${id}?`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                const newData = data.filter(item => item.id !== id);
                setJobList(prevJobs => prevJobs.filter(job => job.id !== id));
                setData(newData);
                setFieldValue('jobs', newData.map(item => item.id));
            },
        })
    }

    const handleAdd = (item) => {        
        if (!data.some(d => d.id === item.id)) {
            const totalVolume = item.purchase_orders.reduce((total, order) => {
                const volume = order.bid?.volume || order.direct_offer_order?.order_quantity || 0;
                return total + volume;            
            }, 0);

            const newData = [...data, {
                key: `key-${item.id}-po-list`,
                id: item.id,
                client_name: item.client,
                address: item.address,
                total: item.purchase_orders_aggregate.aggregate.count,
                weight: totalVolume,
                status: item.job_status,
            }]
            setData(newData)
            setJobList([...jobList, item])
            setFieldValue('jobs', newData.map(item => item.id));
        }
    }

    const columns = [
        {
            title: 'Job #',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <a style={{ color: THEME.colors.primary }}>{text}</a>
        },
        {
            title: 'Client',
            dataIndex: 'client_name',
            key: 'client_name',
            render: (_, rec) => ( 
                <>
                    <div style={{fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{rec.client_name}</div>
                    <div>{rec.address}</div>
                </>
            ),
            width: "30%"
        },
        {
            title: 'No. of PO',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Total Weight (KG)',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <span><DeleteFilled onClick={()=> handleDelete(id) }style={{color: THEME.colors.primary}}/></span>,
            width: '5%'
        }
    ];

    if (loading) return <></>

    return (
        <div>
            <CommonDiv direction='row' justify='space-between' style={{ marginBottom: 10 }}>
                <label>Job Details:</label>
                <CommonButton className='' onClick={() => {showSelect(true)}}>
                    + Add Item
                </CommonButton>
            </CommonDiv>
            <div style={{ maxHeight: 450, overflow: "auto", maxWidth: 1400}}>
                <Table pagination={false} columns={columns} dataSource={data} sticky /> 
            </div>
            <SelectPurchaseOrder showModal={showModal} setShowModal={setShowModal} handleAdd={handleAdd} excludedIds={data.map(item => item.id)} routeId={routeId}/>

            {(select || data.length === 0) && <div style={{ color: THEME.colors.primary, padding: 20, cursor: "pointer" }} onClick={() => setShowModal(true)}>Select Job#</div>}
        </div>
    )
}

export default JobItems