import React from 'react'

function Status({ value }) {
    return (
        <span className={`status-text ${value.toLowerCase()}`} >
            {value}
        </span>
    )
}

export default Status