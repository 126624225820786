
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 33.33%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function NewLocationModal(props) {
    const history = useHistory()
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState('location')

    const okHandler = () => {
        setVisible(false);
        history.push(`/procurement/collection-points/${selected}/new`)
    }

    return (
        <Container>
            <PlusOutlined onClick={() => setVisible(true)} />
            <label>New</label>
            <Modal
                visible={visible}
                centered
                onCancel={() => setVisible(false)}
                onOk={okHandler}
                title="Select a template for a new collection location"
                okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
                width={600}
                closable={false}
                bodyStyle={{ background: '#F2F2F2' }}
            >
                <ModalContainer>
                    <div className='thead' >
                        <span> Code </span>
                        <span> Description </span>
                    </div>
                    <div onClick={() => setSelected('location')} className={`tbody ${selected === 'location' ? 'selected' : ''}`} >
                        <span> Location </span>
                        <span> Collection Location </span>
                    </div>

                </ModalContainer>
            </Modal>
        </Container>
    )
}

NewLocationModal.propTypes = {}

export default NewLocationModal