import { useSubscription } from '@apollo/client';
import { Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { SUB_AUCTION_SCHEDULES } from 'Util/subscriptions';
import { AuctionScheduleContext } from '..';
import { auctionScheduleAtom } from 'Store/auctionAtom';
import { useRecoilState } from 'recoil';
import { TableFilters } from './';

const Container = styled.div``
const StatusContainer = styled.span`
    text-transform: capitalize;
`

const columns = [
    {
        title: 'Auction Number',
        dataIndex: 'auction_number',
        key: 'auction_number',
        render: (v) => <span>{_.isNull(v) ? '-------' : v}</span>
    },
    {
        title: 'Auction Date',
        dataIndex: 'date',
        key: 'date'
    },
    {
        title: 'Auction Time',
        dataIndex: 'auction_time',
        key: 'auction_time'
    },
    {
        title: 'Auction Status',
        dataIndex: 'status',
        key: 'status',
        render: (v) => <StatusContainer>{v}</StatusContainer>
    }
];

const timeFormat = 'dddd, MMMM Do, YYYY, h:mm:ss A';
const newDelhi = moment().tz('Asia/Kolkata')

function DataTable() {

    const { filter, schedule } = useContext(AuctionScheduleContext)
    const [data, setData] = useState([])
    const [selectedSchedule, setSelectedSchedule] = useRecoilState(auctionScheduleAtom)
    const [query, setQuery] = useState()
    const [range, setRange] = useState()

    const { loading, error } = useSubscription(SUB_AUCTION_SCHEDULES, {
        variables: {
            where: { date: query },
            order_by: { 'datetime': 'asc' }
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            if (data && data.auction_schedules) {
                const scheds = data.auction_schedules.map((d) => ({
                    ...d,
                    auction_time: moment(d.datetime).format('HH:mm'),
                    key: `auc-sched ${d.id}`,
                    recurring: false
                }))
                setData(scheds)
            }
        }
    })

    const onSelect = (object) => {
        setSelectedSchedule(object)
    };

    const rowSelection = {
        type: "radio", 
        onSelect,
        getCheckboxProps: (v) => {
            return{
                disabled: v.status.toLowerCase() !== 'scheduled'
            }
        }
    }

    useEffect(() => {
        switch (filter.sort) {
            case "today":
                const today =  newDelhi.format('YYYY-MM-DD') 
                setQuery({ _eq: today})
                setRange({start: today, end: today})
                break;
            case "week":
                const { startOfWeek, endOfWeek } = getStartAndEndOfWeek();
                setQuery({ _gte: startOfWeek, _lte: endOfWeek })
                setRange({start: startOfWeek, end: endOfWeek})
                break;
            case "month":
                const { startOfMonth, endOfMonth } = getStartAndEndOfCurrentMonth();
                setQuery({ _gte: startOfMonth, _lte: endOfMonth })    
                setRange({start: startOfMonth, end: endOfMonth})
                break;
            case "bydate":
                if(filter.date){
                    const date = filter.date.format('YYYY-MM-DD')
                    setQuery({ _eq: date })
                    setRange({start: date, end: date})
                }
                break;
            default:
                break;
        }
    }, [filter.sort, filter.date])

    const getStartAndEndOfWeek = () => {
        const now = moment();
        const startOfWeek = now.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endOfWeek = now.add(7, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        return { startOfWeek, endOfWeek };
    };

    const getStartAndEndOfCurrentMonth = () => {
        const now = moment();
        const startOfMonth = now.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endOfMonth = now.add(30, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        return { startOfMonth, endOfMonth };
    };

    const generateDatesForRepeat = (repeat, date, startDate, endDate, override, ignoreSchedules, id, scheduleEndDate) => {
        const dates = [];
        const isTodayOrBefore = moment(startDate).isSameOrBefore(moment(), 'day');
        let currentDate = filter.sort === 'bydate' && !isTodayOrBefore ? moment(startDate) : moment(startDate).add(1, 'days');
        let end = scheduleEndDate ? moment(scheduleEndDate).subtract(1, 'days') : moment(endDate)

        const ignoreDate = ignoreSchedules.filter(item => item.reference_id === id);
    
        while (currentDate <= end && override !== "ignore") {
            if (
                repeat === 'daily' ||
                (repeat === 'weekly' && currentDate.format('ddd') === moment(date).format('ddd')) ||
                (repeat === 'monthly' && currentDate.format('DD') === moment(date).format('DD'))
            ) {
                let targetDate = currentDate.format('YYYY-MM-DD')
                const found = ignoreDate.some(entry => moment(entry.end_date).format('YYYY-MM-DD') === targetDate);
                if (!found ) {
                    dates.push(targetDate);
                }                
            }
            currentDate = currentDate.add(1, 'days');
        }   
        return dates;
    };
    
                
    const filterData = () => {
        
        let start, end;
        [start, end] = [range?.start, range?.end]
        let result = []

        const ignoreSchedules = schedule.filter(obj => obj.override === 'ignore');

        schedule.forEach(el => {
            const eventDates = generateDatesForRepeat(el.repeat, el.date, start, end, el.override, ignoreSchedules, el.id, el.end_date);
            eventDates.forEach(eventDate => {
                if (eventDate >= moment(start).format('YYYY-MM-DD') 
                    && eventDate <= moment(end).format('YYYY-MM-DD') 
                    && eventDate >= el.date) {
                    result.push({
                        id: el.id,
                        recurring: true,
                        auction_number: null,
                        date: eventDate,
                        auction_time: moment(el.datetime).format('HH:mm'),
                        status: "scheduled",
                        key: `auc-sche-sett-${eventDate}-${el.datetime}`
                    });
                }
            });
        });
        
        let finalResult = result.concat(data);

        finalResult = filter.status === 'all' ? finalResult : _.filter(finalResult, ['status', filter.status])
        return _.sortBy(finalResult, ['date'])
    }

    return (
        <Container>
            <TableFilters data={filterData()} columns={columns}/>
            <Table
                loading={loading}
                size='small'
                columns={columns}
                dataSource={filterData()}
                pagination={{
                    defaultPageSize: 50
                }}
                rowSelection={rowSelection}
            />
        </Container>
    )
}

export default DataTable