import { useMutation } from '@apollo/client';
import { Button, Form, Input, notification, Popconfirm, Select } from 'antd';
import CommonDrawer from 'Components/CommonDrawer';
import _ from 'lodash';
import React from 'react';
import { sendCreateAdminEmail, sendResetadminEmail } from 'Util/mailer';
import { ADD_ADMIN_USER, UPDATE_ADMIN_USER } from 'Util/mutations';
import { v4 as uuidv4 } from 'uuid';
import RolesSelect from './RolesSelect';


const rule = [
    {
        required: true,
        message: "Field is required"
    },
]

function AdminUserForm({ form, drawer, drawerToggle, editId }) {

    const [addUser] = useMutation(ADD_ADMIN_USER, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'User has been added with default password',
            });
            drawerToggle(false)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const [updateUser] = useMutation(UPDATE_ADMIN_USER, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'User has been updated!',
            });
            drawerToggle(false)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const onFormSubmit = () => {
        const formData = form.getFieldsValue();

        if (_.isNull(editId)) {
            const reset_token = uuidv4();

            addUser({ variables: { objects: [{ reset_token, ...formData }] } })

            sendCreateAdminEmail({
                email: formData.email,
                full_name: formData.full_name,
                reset_token
            })

            return null
        }

        updateUser({ variables: { _eq: editId, _set: formData } })

    }

    const confirmReset = async () => {

        const reset_token = uuidv4();
        const formData = form.getFieldsValue();

        await updateUser({
            variables: {
                _eq: editId,
                _set: {
                    password: null,
                    token: null,
                    reset_token
                }
            }
        })

        sendResetadminEmail({
            email: formData.email,
            full_name: formData.full_name,
            reset_token
        })

    }


    return (
        <div className='admin-user-form'>

            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { drawerToggle(true) }} >New User</Button>
            </div>

            <CommonDrawer
                open={drawer}
                form={form}
                title={_.isNull(editId) ? "Add new user" : "Edit user"}
                onClose={() => drawerToggle(false)}
            >

                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                    component={false}
                >


                    <Form.Item
                        rules={rule}
                        label="Username" name="username">
                        <Input disabled={!_.isNull(editId)} placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Email" name="email">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Full Name" name="full_name">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="User Role" name="user_role">
                        <RolesSelect />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Status" name="status">
                        <Select style={{ width: 300 }}>
                            <Select.Option value={true}>Active</Select.Option>
                            <Select.Option value={false}>Inactive</Select.Option>
                        </Select>
                    </Form.Item>

                    {
                        !_.isNull(editId) && <div className='ant-row ant-form-item'>
                            <div className='ant-col ant-col-6' />
                            <div className='ant-col ant-col-16 ant-form-item-control'>
                                <Popconfirm
                                    title="Would you like to proceed?"
                                    onConfirm={confirmReset}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button style={{ width: 300 }} type='danger'>Reset Password</Button>
                                </Popconfirm>

                            </div>
                        </div>
                    }


                </Form>

            </CommonDrawer>

        </div>
    )
}

AdminUserForm.propTypes = {

}

export default AdminUserForm

