import React, { createContext, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client';
import { GET_SETTINGS } from 'Util/queries';

export const AppContext = createContext();
export const initialState = {
    bid: [],
    settings: []
}

function AppContextProvider({ children }) {

    const [appState, setAppState] = useState(initialState)

    /**
     * TODO: transfer to a boostrap function 
     * bootstarp settings 
     */
    const { data, refetch: refetchSettings } = useQuery(GET_SETTINGS, {
        fetchPolicy: "network-only",
        // onCompleted() { TODO: investigate why its no triggred; useEffect temp solution
        //     console.log('Get settings')
        //     setAppState({
        //         ...appState,
        //         settings: data.settings
        //     })
        // }
    });

    useEffect(() => {
        console.log('update settings')
        if (data) {
            setAppState({
                ...appState,
                settings: data.settings
            })
        }
    }, [data])

    const contextValues = {
        appState,
        setAppState,
        refetchSettings
    }

    return (
        <AppContext.Provider value={contextValues}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContextProvider
