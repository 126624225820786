import { ArrowLeftOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { THEME } from 'Util/constants';

const Container = styled.div`
    .actions-wrapper{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icons-wrapper{
            justify-content: space-between;
            width: 180px;
            svg{
                color: ${THEME.colors.primary};
                font-size: 25px;
                cursor: pointer;
            }
        }
        
        .backIconWrapper{
            background-color: ${THEME.colors.primary};
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
        h1{
            margin: 0px 10px;
        }
        
    }

`

function Header({ title = "" }) {

    const history = useHistory()

    const backHandler = () => {
        history.goBack()
    }

    return (
        <Container>

            <div className='actions-wrapper' >

                <div>
                    <button onClick={backHandler} className='backIconWrapper' >
                        <ArrowLeftOutlined style={{ color: '#000' }} />
                    </button>

                    <h1>{title}</h1>
                </div>

            </div>

            {/* <Divider style={{ borderTop: '1px solid #555' }} /> */}

        </Container>
    )
}

Header.propTypes = {
    title: PropTypes.string.isRequired
}

export default Header
