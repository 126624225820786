import React, { useState } from 'react'
import { THEME } from 'Util/constants';
import { Modal } from 'antd';
import { RejectIcon } from 'Components/Icons/actions';
import { useRecoilState } from 'recoil';
import { useMutation } from '@apollo/client';
import { UPDATE_BULK_BID_REJECT_STATUS } from 'Util/mutations';
import _ from 'lodash';
import { auctionSelectedRecordAtom } from 'Store/auctionAtom';

function RejectBidModal({check}) {
    const [selectedRecord, setSelectedRecord] = useRecoilState(auctionSelectedRecordAtom)
    const {selectedData} = selectedRecord
    const [visible, setVisible] = useState(false)

    const [rejectBid] = useMutation(UPDATE_BULK_BID_REJECT_STATUS);

    const rejectBidHandler = () => {

        setVisible(false);

        let id = []
        selectedData.forEach(d => { id.push(d.lot_number)})
   
        rejectBid({
            variables: {
                where: {
                    id: { _in: id}
                },
                status : 'rejected'
            }
        })
        setSelectedRecord({});
    }

    return (
        <>
            <RejectIcon color={check ? "#cccccc" : null} onClick={() => {!_.isEmpty(selectedData) && setVisible(true)}} />
            <Modal
                visible={visible}
                centered
                onCancel={() => setVisible(false)}
                onOk={rejectBidHandler}
                title="Reject All Selected Lots?"
                okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
                width={300}
                closable={false}
                okText = 'YES'
            >
            </Modal>
        </>
    )
}

RejectBidModal.propTypes = {}

export default RejectBidModal