import { useMutation } from '@apollo/client';
import { ADD_REQUEST_APPROVAL } from 'Util/mutations';

/** Common Hook for requesting approval */
export function useRequestAproval() {

    const [update, { data, loading, error }] = useMutation(ADD_REQUEST_APPROVAL);

    const requestApproval = ({
        role = 1,
        requester_id = 1,
        type = "default-approval",
        workflow = "Default workflow",
        ref_id = null
    }) => {

        return update({
            variables: {
                objects: [{ approver_role: role, requester_id, approval_request_type: type, workflow, ref_id }]
            }
        })

    }

    return {
        requestApproval,
        data,
        loading,
        error
    }
}

export function checkApproval() {
    return null
}
