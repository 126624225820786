import React, { useEffect, useState } from 'react'
import CardHeader from 'Components/CommonHeader/CardHeader'
import CardSubMenu from 'Menus/CardSubMenu'
import styled from 'styled-components'
import { Modal, Form, Input, notification, Select, Space, Button, Alert, Switch } from 'antd'
import { FormDivision, FormSection } from 'Components/Form'
import CommonDiv from 'Components/CommonDiv'
import { ButtonLoader } from 'Components/CommonButton'
import { LOCATION_POINT_TYPES } from './constants'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { Country, State, City } from 'country-state-city';
import sanitize from 'string-sanitizer'
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADD_COLLECTION_LOCATION_POINT, UPDATE_COLLECTION_LOCATION_POINT } from 'Util/mutations'
import { GET_LOCATION_POINTS } from 'Util/queries'
import { podAddress } from 'Util/trackpod'
import DepotsDropDown from 'Components/DepotsDropdown'

const Container = styled.div``

function CollectionLocationCard() {

    const history = useHistory()
    const params = useParams()
    const [form] = Form.useForm()

    const [cityList, setCityList] = useState(City.getCitiesOfCountry('IN'))
    const [stateList, setStateList] = useState(State.getStatesOfCountry('IN'))
    const [formLoading, setFormLoading] = useState(false)
    const [locationName, setLocationName] = useState("New Location")

    const editMode = !_.isUndefined(params.id)

    const [getLocation] = useLazyQuery(GET_LOCATION_POINTS, {
        fetchPolicy: "network-only",
        onCompleted(data) {
            const values = data.collection_locations[0]       
            data.collection_locations[0] && form.setFieldsValue(values)
            setLocationName(values.location_name)
        }
    })

    const [add] = useMutation(ADD_COLLECTION_LOCATION_POINT, {
        onCompleted(data) {

            const { returning } = data.insert_collection_locations
            notification['success']({
                message: 'Saved!',
                description: `Collection Location ${returning[0].location_code} has been saved!`,
            });
            setFormLoading(false);
            setTimeout(() => { history.goBack() }, 2000)

        }
    });


    const [update] = useMutation(UPDATE_COLLECTION_LOCATION_POINT, {
        onCompleted() {
            setFormLoading(false);
            notification['success']({
                message: 'Updated!',
                description: 'Collection Location updated!',
            });
            const fv = form.getFieldsValue()
            setLocationName(fv.location_name)
            setFormLoading(false);
            setTimeout(() => { history.goBack() }, 2000)
        }
    });

    useEffect(() => {
        editMode && getLocation({ variables: { where: { id: { _eq: params.id } } } })
    }, [])

    const handleSubmit = async (objects) => {

        // setFormLoading(true)

        /** create object for pod */
        const { location_code, location_name, city, state, postal_code, address_1, address_2, lat, lng} = objects
        
        const podObject = {
            "Id": location_code,
            "Name": location_name,
            "Street": "",
            "City": city,
            "State": state,
            "PostalCode": postal_code,
            "FullAddress": `${ address_1 || "" } ${ address_2 || "" }`,
            "Zone": "",
            "Lat": lat,
            "Lon": lng
        }

        /** send location data to pod-track */
        await podAddress( podObject )

        
        if (editMode) {
            await update({ variables: { where: { id: { _eq: params.id } }, _set: { ...objects } } })
            setLocationName(objects.location_name)
        } else {
            add({ variables: { objects } })
        }

    }

    return (
        <Container>
            <CardHeader
                title='Collection Location Card'
                itemName={locationName}
            />
            <CardSubMenu items={[
                <label>Process</label>,
                <label>Request Approval</label>,
                <label>More Options</label>,
            ]} />

            <Form
                form={form}
                name="collectionLocationCard"
                onFinish={handleSubmit}
                onFinishFailed={() => { console.error('fail') }}
            >

                <FormSection title='General'>
                    <FormDivision>

                        <Form.Item
                            label="Location Code"
                            name="location_code"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Location Name"
                            name="location_name"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Location Type" name="location_type" initialValue={LOCATION_POINT_TYPES[0].name}>
                            <Select
                                rules={[{ required: true, message: '' }]}
                            >
                                {LOCATION_POINT_TYPES.map(item => <Select.Option key={`collection-location-key-${item.id}`} value={item.name} >{item.label}</Select.Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            initialValue={true}
                            label="Active"
                            name="is_active"
                            valuePropName="checked">
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            label="Street"
                            name="address_1"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Full Address"
                            name="address_2"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Country"
                            name="country"
                            initialValue="IN"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Select
                                showSearch
                                onChange={v => {
                                    setStateList(State.getStatesOfCountry(v))
                                    setCityList(City.getCitiesOfCountry(v))
                                }}
                            >
                                {Country.getAllCountries().map(v => <Select.Option key={sanitize.addDash(`${v.isoCode}`.toLowerCase())} value={v.isoCode} >{v.flag} {v.name} ({v.isoCode})</Select.Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="State"
                            name="state"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Select
                                showSearch
                                onChange={v => { 
                                    let stateDetails = _.filter(stateList,['name', v])[0]
                                    setCityList(City.getCitiesOfState(stateDetails.countryCode,stateDetails.isoCode))
                                }}
                            >
                                {stateList.map(v => <Select.Option key={sanitize.addDash(`${v.name + v.isoCode}`.toLowerCase())} value={v.name} >{v.name} ({v.isoCode})</Select.Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="City"
                            name="city"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Select
                                showSearch
                            >
                                {cityList.map(v => <Select.Option key={sanitize.addDash(`${v.name + v.stateCode}`.toLowerCase())} value={v.name} >{`${v.name} (${v.stateCode})`}</Select.Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            rules={[{ required: true, message: '' }]}
                            label="Postal Code"
                            name="postal_code"
                        >
                            <Input />
                        </Form.Item>

                    </FormDivision>

                    <FormDivision>
                        <Form.Item
                            label="Latittude"
                            name="lat"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Longitude"
                            name="lng"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Google Map URL"
                            name="google_map_url"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Depot/Ship From" name="depot_id" rules={[{ required: true, message: '' }]}>
                            <DepotsDropDown />
                        </Form.Item>

                        <a>Show in map</a>

                    </FormDivision>
                </FormSection>

                <CommonDiv direction="row" justify="flex-end" >
                    <ButtonLoader loading={formLoading} htmlType='submit' >
                        {editMode ? 'Update' : 'Save'}
                    </ButtonLoader>
                </CommonDiv>

            </Form>

        </Container>
    )
}

export default CollectionLocationCard