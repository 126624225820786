import { useSubscription } from '@apollo/client';
import React, { useState, useEffect} from 'react'
import styled from 'styled-components'
import { ActionsCol } from '../../components';
import moment from 'moment'
import { useRecoilState } from 'recoil';
import { auctionQuotaSelectedRecordAtom, auctionQuotasTableFiltersAtom } from 'Store/auctionAtom';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { SUB_AUCTIONS, SUB_PRODUCTS } from 'Util/subscriptions';
import EditableTable from 'Components/EditableTable'
import { TableFilters } from './';

const Container = styled.div``

function DataTable() {

    const history = useHistory()

    const [, setSelectedData] = useRecoilState(auctionQuotaSelectedRecordAtom)
    const [filter, setFilter] = useRecoilState(auctionQuotasTableFiltersAtom)
    const [product_data, setPData] = useState([])
    const [auction_data, setAData] = useState([])

    const startOfday = moment().tz('Asia/Kolkata').startOf('day').format('YYYY-MM-DD');

    useSubscription(SUB_AUCTIONS, {
        variables: { "where": { "time": { "_like": `${startOfday}%` } } }, 
        onSubscriptionData({ subscriptionData: { data } }) {
            data.auctions && setAData(data.auctions)
        }
    })

    const { loading } = useSubscription(SUB_PRODUCTS, {
        variables: { "where": {
            status: { _eq: true },
            onboard_status: { _eq: 'approved' }
        } },
        onSubscriptionData({ subscriptionData: { data } }) {
            data.products && setPData(data.products)
        }
    })

    const onEdit = (rec) => {
        /** set to recoild */
        setSelectedData(rec)

        /** redirect to route */
        history.push('/procurement/auction/quotas/edit')
    }

    const getCols = () => {

        const actionCols = {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (rec) => <ActionsCol type='cesr' data={rec} onAction={(action) => {
                if (action === 'edit' && isEditable()) {
                    onEdit(rec)
                    return
                }
                //alert(action)
            }} />,
            width: 100,
            fixed: 'right' 
            
        }

        const auctionCols = auction_data.slice().sort((a, b) => a.id - b.id ).map(a => ({
            title: `Auction #${a.id}`,
            dataIndex: `auction_${a.id}`,
            key: `auction_${a.id}`,
            sorter: (a, b) => (typeof `auction_${a.id}` === 'number' ? `auction_${a.id}` - `auction_${b.id}` : `auction_${a.id}`?.localeCompare(`auction_${b.id}`)),
            sortDirections: ['ascend', 'descend', 'ascend'],
            editable: a.status === "closed" ? false : true,
            width: 100            
        }))

        const cols = [
            {
                title: 'Item Code',
                dataIndex: 'item_code',
                key: 'item_code',
                sorter: (a, b) => a.item_code?.localeCompare(b.item_code),
                sortDirections: ['ascend', 'descend', 'ascend'],
                fixed: 'left',
                width: 200    
            },
            {
                title: 'Item Description',
                dataIndex: 'item_description',
                key: 'item_description',
                sorter: (a, b) => a.item_description?.localeCompare(b.item_description),
                sortDirections: ['ascend', 'descend', 'ascend'],
                fixed: 'left',
                width: 200        
            },
            {
                title: 'Preservation Method',
                dataIndex: 'preservation_method',
                key: 'preservation_method',
                sorter: (a, b) => a.preservation_method?.localeCompare(b.preservation_method),
                sortDirections: ['ascend', 'descend', 'ascend'],
                fixed: 'left',
                width: 100        
            },
            {
                title: 'Country of Origin',
                dataIndex: 'country_of_origin',
                key: 'country_of_origin',
                sorter: (a, b) => a.country_of_origin?.localeCompare(b.country_of_origin),
                sortDirections: ['ascend', 'descend', 'ascend'],
                fixed: 'left',
                width: 100        
            },
            {
                title: 'Harvest Method',
                dataIndex: 'harvest_method',
                key: 'harvest_method',
                sorter: (a, b) => a.harvest_method?.localeCompare(b.harvest_method),
                sortDirections: ['ascend', 'descend', 'ascend'],
                fixed: 'left',
                width: 100            
            },
            {
                title: 'Day Quota (KG)',
                dataIndex: 'day_quota',
                key: 'day_quota',
                sorter: (a, b) => a.day_quota - b.day_quota,
                sortDirections: ['ascend', 'descend', 'ascend'],
                editable: true,
                fixed: 'left',
                width: 100        
            },
            {
                title: 'Cum Purc Vol',
                dataIndex: 'cum_pur_vol',
                key: 'cum_pur_vol',
                sorter: (a, b) => a.cum_pur_vol - b.cum_pur_vol,
                sortDirections: ['ascend', 'descend', 'ascend'],
                fixed: 'left',
                width: 100        
            },
            {
                title: 'Reserved Price',
                dataIndex: 'reserved_price',
                key: 'reserved_price',
                sorter: (a, b) => a.reserved_price - b.reserved_price,
                sortDirections: ['ascend', 'descend', 'ascend'],
                editable: true,
                fixed: 'left',
                width: 100        
            }
        ];

        return cols.concat(auctionCols, actionCols)

    }

    /** clean up */
    useEffect(() => {
        return () => { setFilter({ ...filter, searchText: '' }) }
    }, [])

    const filterData = () => {

        const data = product_data.map((p,i) => {

            const data = {
                key: i,
                id: p.id,
                item_code: p.sku,
                item_description: p.label,
                preservation_method: p.preservation_method,
                country_of_origin: p.country_origin,
                harvest_method: p.harvest_method,
                day_quota: p.quota,
                cum_pur_vol: p.cum_day_purchased,
                reserved_price: p.price,
            }

            /** initial data */
            auction_data.forEach((a, i) => {
                const ares = _.find(p.auction_quota, ['aid', a.id])
                const quota = !_.isUndefined(ares) ? ares?.quota : 0;
                Object.assign(data, { [`auction_${a.id}`]: a.status === "closed" ? "CLOSED" : quota })
            })

            return { ...data, action: { data, auction_data, product_data: p } }

        })

        const searchable = data.map((obj, i) => {
            let str = '';
            auction_data.forEach((a, i) => {
                str += obj[`auction_${a.id}`] + " ";
            })

            return {
                key: i,
                ...obj,
                searchText: `${obj.item_code} ${obj.item_description} ${obj.preservation_method} ${obj.country_of_origin} ${obj.harvest_method} ${obj.day_quota} ${obj.cum_pur_vol} ${obj.reserved_price} ${str}`
            }
        })

        if (!filter.searchText) {
            return data.map((d, i) => ({ key: i, ...d }))
        }
        
        return _.filter(searchable, function (d) {
            let query = filter.searchText.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        });
    }

    const isEditable = () => {
        return !auction_data.some(a => a.status !== 'closed' && a.status !== 'pending');
    }

    return (
        <Container>
            <TableFilters data={filterData()} columns={getCols()}/>
            <EditableTable
                loading={loading}
                size='small'
                columns={getCols()}
                dataSource={filterData()}
                pagination={{ pageSize: 100 }}
                isEditable={isEditable()}
            />           
        </Container>
    )
}

export default DataTable


/**
 * TODO: 
 * Ang FORM sa qoutas
 * Registration of admin revisit
 */