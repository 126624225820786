import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, notification, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { UPSERT_CARRIERS, UPSERT_SHIPPERS } from 'Util/mutations';
import { useRecoilState } from "recoil";
import { selectedVendorAtom } from "Store/crmAtom";
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import { LogisticsContext } from '../..';

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: ${props => props.editMode ? '0px' : '5px'};
        margin-top: ${props => props.editMode ? '5px' : '0'};
    }
    label{
        font-weight: bold;
    } 
`;

function Delete({editMode = false}) {
    const { selectedRecord, setSelectedRecord } = useContext(LogisticsContext) || {}
    const history = useHistory()

    const [deleteCarrier] = useMutation(UPSERT_CARRIERS, {
        onCompleted(){
            notification["success"]({
                message: 'Deleted',
                description: "Carrier has been deleted"
            });
        }
    })
  
    const confirmDelete = async () => {    

        const objects = {
            id: selectedRecord.id,
            code: selectedRecord.code,
            name: selectedRecord.name,
            deleted_at: moment()
        };
      
        await deleteCarrier({ variables: { 
                update_columns: ["id", ...Object.keys(objects)],
                objects: [objects] 
            } 
        })        
        setSelectedRecord({})
        if (editMode) history.push(`/logistics/carriers`)
    }

    const confirm = () => {
        selectedRecord.id && Modal.confirm({
            centered: true,
            icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
            content: `Delete ${selectedRecord.code} - ${selectedRecord.name}?`,
            autoFocusButton: 'ok',
            okButtonProps: {
                style: { backgroundColor: THEME.colors.primary }
            },
            onOk: confirmDelete
        });
    };

    if (!selectedRecord) return <DeleteFilled />

    return (
        <Container editMode={editMode}>
            <Tooltip title={selectedRecord.code}>
                <DeleteFilled onClick={confirm} />
                {!editMode && <label>Delete</label>}
            </Tooltip>
        </Container>
    )
}

Delete.propTypes = {
    editMode: PropTypes.bool
}

export default Delete