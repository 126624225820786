import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import { THEME } from 'Util/constants';

const Container = styled.div`
    .top-section{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .right{
        width: 50%;
        display: flex;
        flexDirection: column;
        alignItems: flex-start;
        paddingLeft: 200px;
    }
`
const StyledButton = styled(Button)`
    color: #fff;
    background: ${THEME.colors.primary};
    border-color: ${THEME.colors.primary};
`

const NewTagModal = ({ visible, onCancel, onSubmit, data }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleOk = async () => {
    setLoading(true);
    try {
      await form.validateFields();
      onSubmit(form.getFieldsValue());
      setLoading(false);
    } catch (error) {
      console.log('Validation failed:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data){
        form.setFieldsValue({
            id: data.id,
            label: data.label,
            description: data.description,
          });
          setEditMode(true)
      }
  }, [])

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const onCreateTag = async () => {
    setLoading(true);
    try {
      await form.validateFields();
      onSubmit(form.getFieldsValue());
      form.resetFields();
      setLoading(false);
    } catch (error) {
      console.log('Validation failed:', error);
      setLoading(false);
    }
  };

  return (
    <Container>
        <Modal width={800} visible={visible} title="Tag Editor" onCancel={handleCancel} footer={[
            <StyledButton key="create" type="primary" onClick={onCreateTag} disabled={editMode}>
                Create Tag
            </StyledButton>,            
            <Button key="save" loading={loading} onClick={handleOk} disabled={!editMode}>
                Save Edit
            </Button>,
            ]}>
            <Form form={form} layout="vertical">
                <Form.Item style={{ display: 'none' }} label="id" name={"id"} >
                    <Input />
                </Form.Item>
                <div  style={{
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                    <Form.Item style={{ width: "50%" }} label="Tag Name" name="label" rules={[{ required: true, message: '' }]}>
                        <Input />
                    </Form.Item>
                    
                    <div 
                        style={{
                            width: "50%",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            paddingLeft: '200px'
                        }}
                    >
                        <div> Total Users</div>
                        <div style={{ fontWeight: 'bold' }}>{data ? data.total_users : 0}</div>                         
                    </div>
                </div>

                <Form.Item label="Description" name="description" rules={[{ required: true, message: '' }]}>
                    <TextArea rows={5} />
                </Form.Item>
            </Form>
        </Modal>
    </Container>

  );
};

export default NewTagModal;
