import React from 'react'
import PropTypes from 'prop-types'
import { ControlOutlined, DashboardOutlined, ContainerOutlined, CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { Menu } from 'antd';

function DirectSalesMenu(props) {
    return (
        <>
            <Menu theme="light" mode="horizontal" defaultSelectedKeys={['auction']}>
                <Menu.Item key="auction-live" icon={<DashboardOutlined />}>
                    <span>Open</span>
                    <Link to="/direct-sales" />
                </Menu.Item>

                <Menu.Item key="auction-archive" icon={<ContainerOutlined />}>
                    <span>Staging Basket</span>
                    <Link to="/direct-sales/staging" />
                </Menu.Item>

                <Menu.Item key="auction-accepted" icon={<CheckOutlined />}>
                    <span>Accepted</span>
                    <Link to="/direct-sales/accepted" />
                </Menu.Item>


                <Menu.Item key="auction-withdrawn" icon={<CloseCircleOutlined />}>
                    <span>Withdrawn</span>
                    <Link to="/direct-sales/withdrawn" />
                </Menu.Item>

                <Menu.Item key="auction-settings" icon={<ControlOutlined />}>
                    <span>Settings</span>
                    <Link to="/direct-sales/settings" />
                </Menu.Item>

            </Menu>
        </>
    )
}

DirectSalesMenu.propTypes = {

}

export default DirectSalesMenu

