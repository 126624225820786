/** Constants */
export const appConstant = {
    leadingZero: 10,
    timeFormat: 'hh:mm:ss a',
    dateFormat: 'YYYY/MM/DD',
    currencySymbol: '₹',
    currencyInitials: 'Rs',
    volumeUnit: 'KG',
    defaultPass: '$2a$10$3qYBBEG3uJAmuwTOyDYYk.XWU9dF9q06ur8EV3G3khVz0yiKdqdoi', //pa55word,
    timeFormat2: 'hh:mmA',
}

export const mapConstant = {
    pickupCoordinates: '1.4073447365744254,103.75628788189645'
}

export const moduleType = [
    "page",
    "permission",
    "setting"
]

export const preservationMethods = ["Live", "Chilled", "Frozen", "Processed"]  /** TODO: transfer to db */
export const harvestMethods = ["Farmed", "Wild Caught", "Others"]

export const DIRECT_OFFER_STATUS = {
    open: 'open',
    accepted: 'accepted',
    withdrawn: 'withdrawn',
    staged: 'staged',
    stagedWithdrawn: 'staged-withdrawn'
}


/** import as separate file */
export const THEME = {
    colors: {
        primary: '#16B4B1',
        blue: '#2F74B6',
        lightBlue: '#00AFF0',
        darkGray: '#555555',
        gray: '#999999',
        lightGray: '#fbfbfb',
        orange: 'orange',
        green: 'green',
        red: 'red'
    }
}

/** Vendor form */
export const vendorCardConstants = {
    PARTNER_TYPES: ['personal', 'company'],
    CASHFLOW_TERMS: ['CDO'],
    PAYMENT_CODES: ['ACCOUNT'],
    SHIPMENT_CODE: ['INHOUSE-LOG']
}

export const approvalStatus = [
    { label: "Pending", value: "pending" },
    { label: "Approved", value: "approved" },
    { label: "Reject", value: "rejected" },
    { label: "Additional Documentation", value: "requirements" },
    { label: "Consult", value: "support" }
]

export const vendorApprovalStatus = [
    { label: "Pending", value: "pending" },
    { label: "Approve", value: "verified" },
    { label: "Reject", value: "rejected" },
    { label: "Block", value: "blocked" },
]

export const vendorApprovalStatusTypes = {
    REVIEW: 'review',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    VERIFIED: 'verified'
}

/** Approvals */
export const approvalType = {
    NEW_ADMIN_APPROVAL: 'new-admin-approval',
    NEW_VENDOR_VETTING: 'new-vendor-vetting',
    NEW_VENDOR_APPROVAL: 'new-vendor-approval',
    NEW_PRODUCT_APPROVAL: 'new-product-approval'
}

export const approvalTypes = [
    { id: "new-vendor-vetting", label: "New Vendor Vetting", category: "vendor" },
    { id: "new-vendor-approval", label: "Vendor OnBoarding", category: "vendor" },
    { id: "new-admin-approval", label: "Admin User OnBoarding", category: "admin_user" },
    { id: "new-product-approval", label: "Product OnBoarding", category: "product" }
]

/** transfer to separate roles permissions */
export const vendorApproverRoles = [1, 2]

export const localizationList = ['english', 'malayalam', 'tamil', 'bengali', 'marathi', 'gujarati', 'telugu']

/** Notifications */
export const notification = {
    category: {
        ALL: "all",
        ADMIN: "admin",
        PROCUREMENT: "procurement",
        LOGISTICS: "logistics",
        CRM: "crm"
    }
}

// This can be created as a table later on
export const notification_groups = [
    { id: 1, group: 'Vendors', description: 'Group vendors and suppliers' },
    { id: 2, group: 'Wholesale Customers', description: 'B2B Clients' },
    { id: 3, group: 'Retail Customers', description: 'B2C customers' },
    { id: 4, group: 'Internal Employees', description: 'Company Employees' }
]

export const communication = {
    channel: {
        MOBILE_PUSH: 1,
        WEB_PUSH: 2,
        EMAIL: 3,
        SMS: 4
    }
}

export const onesignal = {
    device_type: {
        iOS: 0,
        Android: 1,
        Amazon: 2,
        WindowsPhone: 3,
        ChromeApps: 4,
        ChromeWebPush: 5,
        Windows: 6,
        Safari: 7,
        Firefox: 8,
        MacOS: 9,
        Alexa: 10,
        Email: 11,
        Huawei: 13,
        Sms: 14
    },
    notification_types: {
        subscribed: 1,
        unsubscribed: -2
    },
    // Match id with notification_groups
    ext_user_id_prefix: [
        {id: 1, prefix: "vendor_"},
        {id: 2, prefix: "wholesale_"},
        {id: 3, prefix: "retail_"},
        {id: 4, prefix: "employee_"}
    ]
}

//** Products */

export const productOnboardStatus = {
    PENDING: 'pending',
    REVIEW: 'review',
    VERIFIED: 'verified',
    APPROVED: 'approved',
    REJECTED: 'rejected'
}

export const productApprovalStatus = [
    { label: "Pending", value: "pending" },
    { label: "Approve", value: "approved" },
    { label: "Reject", value: "rejected" }
]

//** Purchase Orders */

export const saleType = ["Auction", "Direct Sale", "Direct Market Request"]
export const fulfilmentType = ["Collection", "Deliver to Collection Point"]

/** Logistics */

export const LogisticsJobStatus = [
    { label: "In Progress", value: "in-progress" },
    { label: "Scheduled", value: "scheduled" },
    { label: "Unscheduled", value: "unscheduled" },
    { label: "Collected", value: "collected" },
    { label: "Delivered", value: "delivered" },
]

export const LogisticsDepotsType = [
    { label: "Collection Point", value: "collection-point" },
    { label: "Collection Hub", value: "collection-hub" },
    { label: "Processing Plant", value: "processing-plant" },
    { label: "Cold Room Warehouse", value: "cold-room-warehouse" }
]