import { gql, useQuery } from "@apollo/client"
import { Select } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import leadingZeroes from 'leading-zeroes';
import { appConstant } from "Util/constants";

/** GraphQL */
const GET_VENDORS = gql`query getUsers($where: users_bool_exp = {}) {
    users(where: $where, order_by: {id: asc}) {
      id
      username
      name
      image
      email
    }
  }`

const { Option } = Select

const ContentWrapper = styled.div`
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
        text-transform: capitalize;
    }
`
export default function ({ onChange, ...rest }) {
    const [list, setList] = useState([])

    useQuery(GET_VENDORS, {
        variables: {
            where: {
                user_type: {
                    label: { _eq: "Mobile" }
                },
                status: { _eq: "verified" }
            }
        },
        onCompleted(data) {
            data && data.users && setList(data.users)
        }
    })

    return (
        <ContentWrapper>
            <Select
                allowClear
                showSearch
                {...rest}
                onChange={onChange}
            >
                {list.map(v => <Option value={v.id} key={`vendor-dd-opt${v.id}`}>{`${leadingZeroes(v.id, appConstant.leadingZero)} - ${v.name}`}</Option>)}
            </Select>
        </ContentWrapper>
    )
}

