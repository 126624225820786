import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { vendorCardConstants } from 'Util/constants'
import styled from 'styled-components'
import { THEME } from 'Util/constants';

const { Option } = Select

const ContentWrapper = styled.div`
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
        text-transform: capitalize;
    }
`
export default function ({ onChange, ...rest }) {
    // const [list, setList] = useState([])

    return (
        <ContentWrapper>
            <Select
                {...rest}
                onChange={onChange}
            >
                {vendorCardConstants.CASHFLOW_TERMS.map(v => <Option style={{ textTransform: 'uppercase' }} value={v} key={`cashflowterms-type-dd-opt${v.id}`}>{v}</Option>)}
            </Select>
        </ContentWrapper>
    )
}

