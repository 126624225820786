import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import CommonButton from 'Components/CommonButton';
import CommonDiv from 'Components/CommonDiv';
import { CheckCircleOutlined } from '@ant-design/icons';

const ModalContainer = styled.div`
    .modal-content{ 
        min-height: 120px;        
        span{
            font-size: 22px;
        }
     }

`

function PreApproveModal(props) {

    return <Modal
        visible={props.show}
        centered
        okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
        width={600}
        closable={false}
        bodyStyle={{ background: '#FFF' }}
        footer={false}
    >
        <ModalContainer>

            <CommonDiv direction="row" className="modal-content" >
                <CommonDiv direction="row" align="center">
                    <CheckCircleOutlined style={{ fontSize: 50, marginRight: 10, color: THEME.colors.primary }} />
                    <span>Pre-Approve for Final Approval Request?</span>
                </CommonDiv>
            </CommonDiv>

            <CommonDiv direction="row" justify="flex-end" className="modal-button-container">
                <CommonButton onClick={props.onApprove} > Pre-Approve </CommonButton  >
                <CommonButton onClick={props.onReject} type="error"> Reject </CommonButton>
                <CommonButton onClick={props.onCancel} type="none"> Cancel </CommonButton>
            </CommonDiv>

        </ModalContainer>
    </Modal>
}

PreApproveModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onApprove: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default PreApproveModal
