import {React, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { SchemaMetaFieldDef } from 'graphql';
import { debounce } from 'lodash';
import { useRecoilState } from 'recoil';
import { speciesSearchAtom } from 'Store/procurementAtom';

const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

function Search(props) {

    const [searchRecord, setSearchRecord ] = useRecoilState(speciesSearchAtom)
    
    const updateQuery = (e) =>{
        setSearchRecord(e.target.value)
    }
    const debouncedOnChange = debounce(updateQuery, 200);

    useEffect(() => {
        return () =>{
            setSearchRecord('')
        }
    }, [])

    return (
        <Container>
            <Input prefix={<SearchOutlined />} placeholder="Search" onChange={(e) => debouncedOnChange(e)}/>
        </Container>
    )
}

Search.propTypes = {}

export default Search