import { useMutation, useSubscription } from '@apollo/client';
import { Button, Form, Input, Table, Typography, notification } from 'antd';
import CommonDrawer from 'Components/CommonDrawer';
import LanguageDropdown from 'Components/LanguageDropdown';
import LocationDropdown from 'Components/LocationDropdown';
import UserTypeDropdown from 'Components/UserTypeDropdown';
import React, { useState } from 'react';
import { ADD_USER, UPDATE_USER, UPDATE_USER_OBJECT } from 'Util/mutations';
import { SUB_USERS } from 'Util/subscriptions';
import _ from 'lodash'
import './style.scss';
import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants';

function CMSUsersRejected() {

    const { data, loading, error } = useSubscription(SUB_USERS, { variables: { status: "rejected" } });
    const [updateUser] = useMutation(UPDATE_USER_OBJECT, {
        onCompleted() {
            // TODO: send user notification via EMAIL or SMS
            notification['success']({
                message: 'Saved!',
                description:
                    'User has been posted for verification!',
            });
        },
        onError(e) {
            console.log('erre', e)
        }
    });


    const verifiy = (record) => {

        if (window.confirm(`You wish to approve user: ${record.name}? Press ok to continue`)) {

            updateUser({
                variables: {
                    id: record.id,
                    obj: {
                        status: "approved"
                    }
                }
            })

        }

    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const columns = [
        {
            title: 'FishermanID',
            dataIndex: 'id',
            render: (_, rec) => (<span>{leadingZeroes(rec.id, appConstant.leadingZero)}</span>)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true,
        },
        {
            title: 'Aadhaar ID',
            dataIndex: 'aadhaar_id',
            editable: true,
        },
        {
            title: 'Roles',
            dataIndex: 'type',
            editable: true,
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            editable: true,
        },
        {
            title: 'Vessel',
            dataIndex: 'vessel',
            editable: true,
        },
        {
            title: 'Language',
            dataIndex: 'language',
            editable: true,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            editable: true,
        },
        {
            title: 'Verification ID',
            dataIndex: 'ver_id',
            editable: true,
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <div className="action-text-wrapper">
                    <Typography.Link onClick={() => verifiy(record)}>
                        Approve
                    </Typography.Link>
                </div>
            ),
        },
    ];

    const dataSource = () => {

        if (data) {

            return data.users.map((v, i) => (
                {
                    key: `key-${i}-user-list`,
                    id: v.id,
                    aadhaar_id: v.aadhaar_id,
                    email: v.email,
                    mobile: v.mobile,
                    name: v.name,
                    vessel: v.vessel,
                    language: v.user_language.label,
                    language_id: v.user_language.id,
                    location: v.user_location.label,
                    location_id: v.user_location.id,
                    type: v.user_type.label,
                    users_type_id: v.user_type.id,
                    ver_id: v.verification_id
                }
            ))
        }

        return []

    }

    return (
        <div className="users-page" >
            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    );
}

export default CMSUsersRejected
