import React from 'react'
import NumberFormat from 'react-number-format';
import { appConstant } from 'Util/constants';

function CurrencyText({ value, ...rest }) {
    return <NumberFormat
        value={value}
        defaultValue={0}
        thousandSeparator={true}
        prefix={appConstant.currencySymbol}
        displayType="text"
        {...rest}
    />
}

export default CurrencyText
