import { Select } from 'antd';
import { useContext } from 'react';
import styled from "styled-components";
import { LogisticsRouteContext } from '../..';
import { useHistory } from "react-router-dom";

const { Option } = Select;

const Container = styled.div`
  label{
      font-weight: bold;
  }  
`;


function RoutesDropdown(props) {
    
    const { filters, setFilter } = useContext( LogisticsRouteContext )
    const history = useHistory()

    const handleChange = (jobtype) => {
        setFilter({ ...filters, jobtype })
        history.push(`/logistics/routes/${jobtype}`)
        // if (jobtype === "collection") {
        //     history.push('/logistics/jobs')
        // }else{
        //     history.push(`/logistics/routes/${jobtype}`)
        // }
    }

    return (
        <Container>
            <label>Routes: </label>
            <Select value={filters.jobtype} defaultValue="all" style={{ width: 120 }} bordered={false} onChange={handleChange}>
                <Option value="all">All</Option>
                <Option value="open">Open</Option>
                <Option value="completed">Completed</Option>
            </Select>
        </Container>
    )
}

RoutesDropdown.propTypes = {}

export default RoutesDropdown