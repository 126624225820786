import axios from "axios"

const { REACT_APP_SENDGRID_API_KEY } = process.env
const endpoint = "https://api.sendgrid.com/v3/mail/send"
const headers = {
    "Accept": "*/*",
    "Authorization": `Bearer ${REACT_APP_SENDGRID_API_KEY}`,
    "Content-Type": "application/json"
}

export const sendMail = ({ to, name = "", from = "info@catch-value.com", subject = "", message }) => {

    const htmlContent = `<html><head></head><body>${message}</body></html>`; // TODO: create a template email
    axios.post(endpoint, {
        personalizations: [{ to: [{ email: to }] }],
        from: { email: from },
        subject,
        content: [{ type: 'text/plain', value: htmlContent }]
    }, { headers }).then(
        res => console.log('Mail sent')
    )

}


export const sendMailJS = async ({ to, name = "", from = "info@catch-value.com", subject = "", message }) => {

    const headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "Origin": "*"
    }

    const bodyContent = JSON.stringify({
        service_id: process.env.REACT_APP_EJS_SERVICE_ID,
        template_id: process.env.REACT_APP_EJS_TEMPLATE_ID,
        user_id: process.env.REACT_APP_EJS_USER_ID,
        template_params: { to, name, subject, message }
    });

    const reqOptions = {
        url: "https://api.emailjs.com/api/v1.0/email/send",
        method: "POST",
        headers: headersList,
        data: bodyContent,
    }

    await axios.request(reqOptions);

    console.log("Email sent");

}

export const sendCreateAdminEmail = ({ email, reset_token, full_name }) => {

    const message = `Your admin account has been created. Please activate using the link. ${window.location.origin}/activate/${reset_token}'>${window.location.origin}/activate/${reset_token}`

    sendMailJS({
        to: email,
        name: full_name,
        subject: "[Catch Value] Admin Onboarding Activation",
        message
    })
}

export const sendResetadminEmail = ({ email, reset_token, full_name }) => {

    const message = `Your admin account password has been reset. Please activate using the link. ${window.location.origin}/activate/${reset_token}'>${window.location.origin}/activate/${reset_token} `

    sendMailJS({
        to: email,
        name: full_name,
        subject: "[Catch Value] Admin Password Reset Activation",
        message
    })
}