import { Button, Popover } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';

import { faKey, faShoppingBasket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';




function PopOverDetails({
    data = {},
    add = false,
    onSubmit,
    children
}) {
    const history = useHistory()
    const inputRef = useRef();

    const [oqInput, setOqInput] = useState(null)
    const [visible, setVisible] = useState(false)

    const isDisabled = _.isNull(oqInput) || _.isEmpty(oqInput.toString()) || parseInt(oqInput) < 1 || parseInt(oqInput) < data.min_quantity || parseInt(oqInput) > data.avail_quantity || data.order_quantity >= data.avail_quantity

    const handleCart = () => {
        history.push('direct-sales/staging')
    }

    const handleSubmit = (action) => {


        let order_quantity = data.order_quantity
        let avail_quantity = data.avail_quantity
        let min_quantity = data.min_quantity

        if (action === 'add') {
            order_quantity = data.order_quantity + parseInt(inputRef.current.value);
            inputRef.current.value = "";
        }

        if (action === 'buy') {

            if (!_.isUndefined(inputRef.current)) {
                order_quantity = parseInt(inputRef.current.value)
            }

            avail_quantity = data.avail_quantity - order_quantity
            if (min_quantity > avail_quantity) {
                min_quantity = avail_quantity
            }
        }

        onSubmit({ action, data: { ...data, order_quantity, avail_quantity, min_quantity } })
        setVisible(false);

    }

    const content = () => (
        <div className='direct-offer-popover' >

            <div className='desc-wrapper'>
                <img src={data.image} />
                <div className='desc'>
                    <span className='title'>{data.species_name}</span>
                    <span>{`${data.harvest_method} from ${data.location}. ${data.preservation_method}`}</span>

                </div>
            </div>
            <div>
                <div className='row-wrapper'>
                    <label>Category:</label>
                    <span>{data.category}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Preservation type:</label>
                    <span>{data.preservation_method}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Country of origin:</label>
                    <span>{data.country_origin}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Harvest method:</label>
                    <span>{data.harvest_method}</span>
                </div>
                <div className={`row-wrapper ${parseInt(oqInput) > data.avail_quantity ? 'error' : ''}`}>
                    <label>Available QTY:</label>
                    <span>{data.avail_quantity} / {data.quantity}</span>
                </div>
                <div className={`row-wrapper ${parseInt(oqInput) < data.min_quantity ? 'error' : ''}`}>
                    <label>Minimum order QTY:</label>
                    <span>{data.min_quantity}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Unit price:</label>
                    <span>{data.price}/{data.unit}</span>
                </div>
                <div className='row-wrapper'>
                    <label>OffeType price:</label>
                    <span>{data.offer_type}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Order quantity:</label>
                    {add && <input ref={inputRef} onChange={v => setOqInput(v.target.value)} type={"number"} />}
                    {!add && <span>{data.order_quantity}</span>}

                </div>
            </div>

            {
                add && <div className='pop-over-buttons-wrapper' >
                    <Button disabled={isDisabled} type="primary" className='add' onClick={() => handleSubmit('add')} > Add </Button>
                    {data.status === "open" && <Button disabled={isDisabled} type="primary" className='buy' onClick={() => handleSubmit('buy')} > Buy </Button>}
                    {data.status === "staged" && <Button type="primary" className='buy' onClick={() => handleCart('buy')} ><FontAwesomeIcon size="1x" icon={faShoppingBasket} /> ({data.order_quantity}) </Button>}

                </div>
            }

            {
                !add && <div className='pop-over-buttons-wrapper' >
                    <Button type="primary" className='cancel' onClick={() => handleSubmit('cancel')} > Cancel </Button>
                    <Button type="primary" className='buy' onClick={() => handleSubmit('buy')} > Buy </Button>
                </div>
            }


        </div>
    )

    if (_.isEmpty(data)) { return <></> }

    return <Popover
        placement='leftTop'
        trigger={'click'}
        onVisibleChange={visible => setVisible(visible)}
        visible={visible}
        content={content}
    >{children}</Popover>

}

PopOverDetails.propTypes = {
    data: PropTypes.object.isRequired,
    add: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.node
}



export default PopOverDetails