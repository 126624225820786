import React from 'react'
import PropTypes from 'prop-types'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import ButtonCard from 'Components/ButtonCard'

const CRMRetailPage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            CRM Retail Page
        </div>
    )
}

CRMRetailPage.propTypes = {}

export default CRMRetailPage