import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Popover, Tabs, Input, Button, Form } from "antd";
import styled, { css } from "styled-components";
import { THEME } from "Util/constants";
import TableFilter from "./TableFilter";
import _ from "lodash";
import { PaperClipOutlined, InfoCircleFilled } from "@ant-design/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { listOrderAtom, selectedProduct, infoIconAtom, speciesSearchAtom } from "Store/procurementAtom";
import { useHistory } from "react-router-dom";
import { UPDATE_PRODUCT } from 'Util/mutations';
import { useMutation } from '@apollo/client';

const { TabPane } = Tabs;

const Container = styled.div`
	.ant-table-thead {
		.ant-table-cell {
			color: #999;
			font-weight: bold;
		}
	}

	.ant-table-tbody > tr:hover > td {
		background-color: "none";
	}
	.ant-table-tbody > tr.selected > td {
		background-color: ${THEME.colors.primary};
	}
  .shorten{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const GridContainer = styled.div`
	.fish-img {
		height: 100px;
		width: 100px;
	}
	* {
		padding: 2px;
	}
	.flex-row {
		display: flex;
		flex-direction: row;
	}
	.mr {
		margin-right: 10px;
	}
	.basis {
		flex-basis: 33%;
		background-color: #eee;
	}
  .basisclicked {
		flex-basis: 33%;
		background-color: rgba(22, 180, 177, 0.5);
	}
  .basis:hover {
		flex-basis: 33%;
		background-color: #e5e5e5;
	}
	.title {
		font-weight: bold;
		font-size: 12px;
	}
	.space-between {
		justify-content: space-between;
		width: 100%;
	}
	.link {
		text-decoration: underline;
		color: #16b4b1;
		font-size: 16px;
		cursor: pointer;
	}
	.flex-end {
		align-self: flex-end;
	}
	.bold {
		font-weight: bold;
	}
`;

const PopContainer = styled.div`
	.ant-tabs-tabpane {
		width: 430px;
		label.title {
			font-weight: bold;
		}
		.data-container {
			.data-item {
				display: flex;
				justify-content: space-between;
				label {
					color: #555;
				}
				span {
          text-align: right;
				}
			}
      .ant-form-item-control {
        flex-direction: row-reverse;
      }
      .ant-input{
        width: 70px;
        padding: 0px 5px;
        border: 1px solid ${THEME.colors.primary};
        color: ${THEME.colors.primary};
      }
      .ant-form-item {
        margin-bottom: 0px;
      }
      .ant-form-item-label {
        label {
          color: #555;
        }
      }
      .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
      }
		}
    .ant-form-item-explain.ant-form-item-explain-error {
      display: flex;
      div {
        margin: auto
      }
    }
    .button-container {
      justify-content: flex-end;
      display: flex;
      gap: 10px;
      padding-top: 10px;
      .ant-btn-primary{
        background: ${THEME.colors.primary};
        border-color: ${THEME.colors.primary};
      }
      .ant-btn {
        width: 80px;
        height: 30px;
        padding: 0px;
      }
    }
	}
`;

const columns = [
  {
    title: "Item Code",
    dataIndex: "sku",
    key: "sku",
    width: 300
  },
  {
    title: "Item Description",
    render: (item) => <span>{item.label}{item.bid_specs.length > 0 ? ', ' : ''}{item.bid_specs?.join(", ")}</span>,
    className: "shorten",
    key: "label",
    width: 350
  },
  {
    title: "Item Type",
    dataIndex: "category",
    key: "category"
  },
  {
    title: "Inventory",
    dataIndex: "volume",
    key: "volume"
  },
  {
    title: "Process BOM",
    render: (item) => <span>{item.status ? 'Yes' : 'No'}</span>,
    key: "status"
  },
  {
    title: "Base UoM",
    dataIndex: "uom",
    key: "uom"
  },
  {
    title: "Produce Type",
    dataIndex: "harvest_method",
    key: "harvest_method"
  },
  {
    title: "Produce Origin",
    render: (item) => <span>{item.species_environment?.description}</span>, 
    key: "produce_origin"
  }
];

const PopContent = ({ data, popVisible }) => {
  const [form] = Form.useForm();

  const [update] = useMutation(UPDATE_PRODUCT, {
    onError(e) {
        console.log('erre', e)
    }
  });

  const initialValues = {
    id: data.id,
    volume: data.volume,
    price: data.price,
    quota: data.quota
  };

  const rules = { value : [{ required: true, message: 'Required field'}]}

  const submitHandler = (values) => {
    const object = {
      quota: values.quota,
      volume: values.volume,
      price: values.price
    }
    update({ variables: { id: values.id, object} })
  }

  return (
    <PopContainer>
      <div className="tab">
        <Tabs defaultActiveKey="1" centered>
        <TabPane
            className="data-pane"
            tab={
              <span>
                {" "}
                <InfoCircleFilled /> Details
              </span>
            }
            key="1"
          >
            <label className="title">
              {" "}
              Product Details - General{" "}
            </label>
            <Form
              form={form}
              name="popForm"
              onFinish={submitHandler}
              initialValues={initialValues}
            >
            <div className="data-container">
              <Form.Item style={{ display: 'none' }} label="id" name="id">
                <Input />
              </Form.Item>
              <div className="data-item">
                {" "}
                <label>Item Code</label>
                <span>{data.sku}</span>{" "}
              </div>
              <div className="data-item">
                {" "}
                <label>Status</label>
                <span style={{ textTransform: "capitalize" }}>
                  {data.status ? "Active" : "Inactive"}
                </span>{" "}
              </div>
              <div className="data-item">
                {" "}
                <label>Item Description</label>         
                <span>{data.label}{data.bid_specs.length > 0 ? ', ' : ''}{data.bid_specs.join(", ")}</span>{" "}
              </div>
              <div>
                <Form.Item label="Day Quota (KG)" name="quota" rules={rules.value}>
                  <Input type="number"/>
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Current Auction Quota (KG)" name="volume" rules={rules.value}>
                  <Input type="number"/>
                </Form.Item>
              </div>
              <div className="data-item">
                {" "}
                <label>Cum Purchased Volume (KG)</label>
                <span>{data.cum_day_purchased}</span>{" "}
              </div>
              <div>
                <Form.Item label="Reserved Price (INR)" name="price" rules={rules.value}>
                  <Input type="number"/>
                </Form.Item>
              </div>
            </div>
            <div className="button-container">
              <Button type="primary" htmlType="submit">Save</Button>
              <Button onClick={() => popVisible(false)}>Cancel</Button>
            </div>
            </Form>          
          </TabPane>
          <TabPane
            tab={
              <span>
                <PaperClipOutlined /> Attachements
              </span>
            }
            key="2"
          >
            N/A
          </TabPane>
        </Tabs>
      </div>
    </PopContainer>
  );
};

function GridView({ data }) {
  const history = useHistory()
  const goToLink = (item) => {
    history.push(`/procurement/product-list/new/edit/${item.id}`, { prevPath: '/procurement/product-list'})
  }
  const [product, setSelectedProduct] = useRecoilState(selectedProduct);
  const [searchRecord, setSearchRecord ] = useRecoilState(speciesSearchAtom)

  const searchable = data.map((obj, i) => ({
    key: i,
    ...obj,
    searchText: `${obj.label} ${obj.sku} ${obj.price ? obj.price : 0} ${obj.volume ? obj.volume : 0}`
  }))

  data = _.filter(searchable, function (d) {
    let query = searchRecord.toLowerCase();
    return _.includes(d.searchText.toLowerCase(), query)
  });

  return (
    <GridContainer>
      <div style={{ gap: 5, display: "flex", flexWrap: "wrap" }}>
        {data.map((item) => (
          <div className={product.id === item.id ? "flex-row basisclicked" : "flex-row basis"} key={`grid-product-item-${item.id}`} onClick={() => setSelectedProduct(item)}>
            <div className="mr">
              <img
                className="fish-img"
                src={item?.image ?? `https://via.placeholder.com/150?text=${item?.label}`}
                alt={item?.label}
              />
            </div>
            <div className="flex-row space-between">
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="title">{item.sku}</div>
                <div className="link bold" onClick={() => goToLink(item)}>{item.label}</div>
                <div>{item?.price?.toFixed(2) ?? 0}</div>
              </div>
              <div className="flex-end">
                <div className="bold">{item.volume / 1000}</div>
                <div>{item.uom}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </GridContainer>
  );
}

function DataTable({
  pageSize = 30,
  height = 230,
  data = [],
  loading = false,
}) {
  const listOrGrid = useRecoilValue(listOrderAtom);
  const [info, setInfo] = useRecoilState(infoIconAtom)
  const [product, setSelectedProduct] = useRecoilState(selectedProduct);
  const [visible, setVisible] = useState(false)
  const [searchRecord, setSearchRecord ] = useRecoilState(speciesSearchAtom)
  const [selectedRow, setSelectedRow] = useState({});

  const searchable = data.map((obj, i) => {
    let result = [];
    let concatenatedValues = "";
    columns.forEach(column => {
      if (obj[column.key] && obj[column.key] !== undefined) concatenatedValues += obj[column.key] + " ";
    });
    result.push(concatenatedValues.trim());

    return {
      key: i,
      ...obj,
      searchText: `${result.toString()} ${obj.bid_specs.toString()} ${obj.species_environment?.description}`
    }
  })

  const filterData = (data) => {  
    if(!searchRecord){
        return data
    }
    return _.filter(searchable, function (d) {
      let query = searchRecord.toLowerCase();
      return _.includes(d.searchText.toLowerCase(), query)
    });
  }

  useEffect(() => {
    return () => { setSelectedProduct({}) 
    setInfo(false)}
  }, [])

  const handleRowDoubleClick = (row) => {
    let visible = selectedRow && selectedRow?.id === row?.id
    if (visible) {
      setVisible(true)
      setSelectedRow(row);
    } else {
      setVisible(false)
      setSelectedRow({});
    }
  };

  const handleRowClick = (row) => {
    setVisible(false)
    setSelectedRow(row)
    setSelectedProduct(row);
  }

  return (
    <Container>
      <TableFilter data={filterData(data)} columns={columns}/>
      {listOrGrid === "list" ? (<Table
        loading={loading}
        columns={columns}
        dataSource={filterData(data)}
        pagination={{
          defaultPageSize: pageSize,
        }}
        scroll={{
          y: height,
          x: 1300,
        }}
        onRow={(rec, idx) => ({
          onClick: (e) => {
            handleRowClick(rec)
          },
          onDoubleClick: (event) => {
            handleRowDoubleClick(rec);
          }          
        })}
        rowKey={(rec) => parseInt(rec.id, 10)}
        components={{
          body: {
            row: (props) => {
              const row = _.find(filterData(data), {
                id: props["data-row-key"],
              });
              let selected = false;
              if (!_.isEmpty(product) && !_.isEmpty(row)) {
                selected = row.id === product.id;
              }
              let rowvis = selectedRow && selectedRow?.id === row?.id
              const tableRow = (
              <tr
                style={{ cursor: "pointer" }}
                onClick={props.onClick}
                className={`ant-table-row ant-table-row-level-0 yeah ${selected ? "selected" : ""}`}
                onDoubleClick={info.products ? props.onDoubleClick : null}
              >
                {props.children}
              </tr>)

              return (
                info.products ? (<Popover
                  content={<PopContent data={row} popVisible={(set) => setVisible(set)} />}
                  placement="bottom"
                  open={visible}          
                  visible={rowvis && visible}
                >
                  {tableRow}
                </Popover>) : (tableRow)
              );
            },
          },
        }}
      />
      ) : (
        <GridView data={data} module={module} />
      )}
    </Container>
  );
}

DataTable.propTypes = {
  pageSize: PropTypes.oneOf([10, 20, 50, 100]),
  height: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

export default DataTable;
