import React from 'react'
import PropTypes from 'prop-types'
import './auction.scss'
import LiveAuctionList from './components/LiveList'
import DailyQuota from './components/DailyQuota'

function LiveAuctioPage(props) {
    return (
        <div className="auction-live-page">
            <LiveAuctionList />
            <DailyQuota />
        </div>
    )
}

LiveAuctioPage.propTypes = {}

export default LiveAuctioPage