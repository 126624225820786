import { useSubscription, useMutation } from '@apollo/client';
import { notification, Table } from 'antd';
import leadingZeroes from 'leading-zeroes';
import { useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import styled from 'styled-components';
import { getDate, getTimeFromDate } from 'Util/common';
import { appConstant, DIRECT_OFFER_STATUS } from 'Util/constants';
import { SUB_DIRECT_OFFERS } from 'Util/subscriptions';
import { ACCEPT_DIRECT_OFFER, UPDATE_DIRECT_OFFER } from 'Util/mutations';
import { commonColumns, dataSource } from '../../common';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopOverDetails } from '../../components'
import { mapConstant } from 'Util/constants';
import gmap from 'gmap';
import { directSalesFilterAtom } from 'Store/directSalesAtom';
import moment from 'moment'
import { TableFilters } from './';

const Container = styled.div``



function DataTable() {

    /** Global State */
    const [filter] = useRecoilState(directSalesFilterAtom)
    // const { auctionStatus } = auctionState
    const auctionStatus = 'open'

    /** Local State */
    const [data, setData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //const submitMessage = useRef()

    /** GraphQL Hooks */
    const { loading } = useSubscription(SUB_DIRECT_OFFERS, {
        variables: {
            where: {
                active: { _eq: true },
                status: { _in: [DIRECT_OFFER_STATUS.open, DIRECT_OFFER_STATUS.staged] }
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            data && data.direct_offers && setData(data.direct_offers)
        }
    });


    /** Functions */
    const onSelectChange = (newSelectedRowKeys, data) => {
        // setSelectedRecord(data)
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const filterData = (data) => {
        return dataSource('open', data)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        ...commonColumns,
        {
            title: 'PO#',
            width: '120px',
            dataIndex: 'action'
        }
    ]

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={columns}/>
            <Table
                loading={loading}
                size='small'
                columns={columns}
                dataSource={filterData(data)}
                // rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 50
                }}
            />
        </Container>
    )
}

export default DataTable    