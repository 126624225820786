import { Select, Input, Switch, Popconfirm, notification } from 'antd';
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { Link, useHistory } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AuctionLiveContext } from '../';
import { SearchOutlined, PlusOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons';
import { ExtraFilters } from './';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import { auctionStatusText } from 'Util/common';
import { debounce } from 'lodash';
import { auctionSearchAtom } from 'Store/auctionAtom';
import { AutoBidSwitch } from '../../components';

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const MenuButtonContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

function TableFilters(props) {

    const history = useHistory()
    const { filter, setFilter } = useContext(AuctionLiveContext) /** removed laters */
    const [{ auctionStatus, currentAuction }] = useRecoilState(auctionAtom);
    const [searchRecord, setSearchRecord] = useRecoilState(auctionSearchAtom) 

    const updateQuery = (e) =>{
        setSearchRecord(e.target.value)
    }
    const debouncedOnChange = debounce(updateQuery, 200);

    return (
        <Container>

            <DropdownContainer>
                <label>Auction: </label>
                <Select defaultValue="live"
                    style={{ width: 100 }}
                    bordered={false}
                    onChange={v => { setFilter(v) }}
                >
                    <Option value="live">Live</Option>
                    <Option value="day">1 Day</Option>
                    <Option value="week">1 Week</Option>
                    <Option value="month">1 Month</Option>

                </Select>
            </DropdownContainer>

            <SearchContainer>
                <Input prefix={<SearchOutlined />} style={{ width: 200 }} placeholder="Search" onChange={(e) => debouncedOnChange(e)}/>
            </SearchContainer>

            <MenuButtonContainer>
                <label> Auction <span>{currentAuction || 'N/A'}</span> : <span style={{ color: THEME.colors.primary, textTransform: "capitalize" }} >{auctionStatusText(auctionStatus)}</span>
                </label>
            </MenuButtonContainer>

            <MenuButtonContainer>
                <AutoBidSwitch/>  
            </MenuButtonContainer>

            <ExtraFilters data={props.data} columns={props.columns}/>

        </Container>
    )
}

TableFilters.propTypes = {}

export default TableFilters
