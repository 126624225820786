import React from 'react';
import PropTypes from 'prop-types'
import { Layout } from 'antd';
import { baseUrl } from 'Util/common';
import MainMenu from 'Menus/MainMenu';
import _ from 'lodash'
import './main.scss'
import Clock from 'Components/Clock';

const { Header, Footer, Content } = Layout;

function SettingsLayout({ children, pageTitle, menu: PageMenu }) {

    return (
        <Layout className="main-layout">
            <Header>
                <div className="logo">
                    <img src={`${baseUrl}/images/logo.png`} alt="Catch Value" className="logo" />
                </div>
                <MainMenu />
            </Header>
            <Content style={{ padding: '0 50px' }}>

                <div className="sub-header">
                    <h1 style={{ marginTop: 10 }} >{pageTitle}</h1>
                    <Clock />
                </div>


                {_.isUndefined(PageMenu) ? <></> : <PageMenu />}
                <div className="content-wrapper">{children}</div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Catch Value ©2021</Footer>
        </Layout>
    )
}

SettingsLayout.propTypes = {
    title: PropTypes.string
}

export default SettingsLayout
