import { useMutation, useSubscription } from '@apollo/client';
import { Button, Form, Input, notification, Table, Typography, Select, InputNumber } from 'antd';
import BidSpecs from 'Components/BidSpecs';
import CommonDrawer from 'Components/CommonDrawer';
import HarvestMethodDropdown from 'Components/HarvestMethodDropdown';
import PreservationMethodDropdown from 'Components/PreservationMethodDropdown';
import SpeciesDropdown from 'Components/SpeciesDropdown';
import _ from 'lodash';
import React, { useState } from 'react';
import { appConstant } from 'Util/constants';
import { ADD_PRODUCT, UPDATE_PRODUCT } from 'Util/mutations';
import { SUB_PRODUCTS } from 'Util/subscriptions';
import './style.scss';


function CMSProducts() {
    const [form] = Form.useForm();
    const { data, loading, error } = useSubscription(SUB_PRODUCTS);
    const [editId, setEditId] = useState(null);
    const [drawer, setDrawer] = useState(false);

    const [add] = useMutation(ADD_PRODUCT, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Product has been added',
            });
            setDrawer(false);
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const [update] = useMutation(UPDATE_PRODUCT, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Product has been updated!',
            });
            setDrawer(false);
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const drawerClose = () => {
        setDrawer(false);
        setEditId(null);
    }

    const edit = (record) => {
        setEditId(record.id)
        form.setFieldsValue({
            ...record,
        });
        setDrawer(true);
    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const onFormSubmit = () => {
        let object = form.getFieldsValue();
        _.isNull(editId) ? add({ variables: { object } }) : update({ variables: { id: editId, object } })
    }

    const columns = [
        {
            title: 'SKU',
            dataIndex: 'sku',
            editable: true,
        },
        {
            title: 'Image',
            dataIndex: 'image',
            editable: true,
            render: (img) => (
                <img src={img} height="45" />
            ),
        },
        {
            title: 'Label',
            dataIndex: 'label',
            editable: true,
        },
        {
            title: 'Species Description',
            dataIndex: 'species',
            editable: true,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            editable: true,
        },
        {
            title: 'Preservation Method',
            dataIndex: 'preservation_method',
            editable: true,
        },
        {
            title: 'Country of Origin',
            dataIndex: 'country_origin',
            editable: true,
        },
        {
            title: 'Harvest Method',
            dataIndex: 'harvest_method',
            editable: true,
        },
        {
            title: "Bid Specifications",
            dataIndex: 'bid_specs',
            render: (list) => {
                if (!_.isNull(list)) {
                    return <ul>{list.map(v => <li key={`bid-spec-list${Math.random()}`} >{v}</li>)}</ul>
                }
                return '';
            }
        },
        {
            title: 'Daily Quota',
            dataIndex: 'quota',
            editable: true,
            render: (quota) => (<span>{quota} KG</span>)
        },
        {
            title: 'Volume',
            dataIndex: 'volume',
            editable: true,
        },
        {
            title: 'Reserve Price',
            dataIndex: 'price',
            editable: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            editable: true,
            render: (_, rec) => (
                rec.status ? <span>active</span> : <span>inactive</span>
            )
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <Typography.Link onClick={() => edit(record)}>
                    Edit
                </Typography.Link>
            ),
        },
    ];

    const dataSource = () => {

        if (data) {
            return data.products.map((v, i) => (
                {
                    key: `key-${i}-psp-list`,
                    id: v.id,
                    label: v.label,
                    species_id: v.species.id,
                    image: v.species.image,
                    species: v.species.label,
                    status: v.status,
                    bid_specs: v.bid_specs,
                    volume: v.volume,
                    price: v.price,
                    quota: v.quota,
                    sku: v.sku,
                    category: v.category,
                    preservation_method: v.preservation_method,
                    country_origin: v.country_origin,
                    harvest_method: v.harvest_method
                }
            ))
        }

        return []

    }

    /** Validation Rules */
    const rule = [
        {
            required: true,
            message: "Field is required"
        },
    ]

    return (
        <div className="products-page" >

            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { setDrawer(true); }} >New product</Button>
            </div>

            <CommonDrawer
                open={drawer}
                form={form}
                title={editId ? "Edit product" : "Add new product"}
                onClose={drawerClose}
            >

                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                    component={false}
                >

                    <Form.Item
                        rules={rule}
                        label="SKU" name="sku">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Label" name="label">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Status" name="status">
                        <Select
                            style={{ width: 300 }}
                            placeholder="Select status"
                        >
                            <Select.Option value={true} key={`product-dd-opt-active`} >
                                Active
                            </Select.Option>
                            <Select.Option value={false} key={`product-dd-opt-inactive`} >
                                Inactive
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Species" name="species_id">
                        <SpeciesDropdown />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Category" name="category">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Preservation Method" name="preservation_method">
                        <PreservationMethodDropdown />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Country of Origin" name="country_origin">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Harvest Method" name="harvest_method">
                        <HarvestMethodDropdown />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Bid Specifications" name="bid_specs">
                        <BidSpecs />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Daily Quota" name="quota">
                        <InputNumber placeholder="KG" style={{ width: 150 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Quantity" name="volume">
                        <InputNumber placeholder="KG" style={{ width: 150 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Reserve Price" name="price">
                        <InputNumber placeholder={appConstant.currencySymbol} style={{ width: 150 }} />
                    </Form.Item>


                </Form>

            </CommonDrawer>

            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    );
}

export default CMSProducts
