import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useState } from 'react';
import { GET_VEHICLE } from 'Util/queries';
const { Option } = Select;

function VehiclesDropDown({ onChange, form, ...rest }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_VEHICLE, {
        variables: {
            where: {
                deleted_at: { _is_null: true }
            }
        },
        onCompleted() {
            setList(data.vehicles)
        }
    });

    const handleChange = (value) => {
        const selected = list.find(item => item.id === value);
        if (form) {
            form.setFieldsValue({
                vehicle_id: selected.id,
                vehicle_number: selected.number
            });
        }
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div>
            <Select
                {...rest}
                placeholder="Select a vehicle"
                onChange={handleChange}
            >
                {list.map(v => <Option value={v.id} key={`vehicles-dd-opt${v.id}`}>{v.number}</Option>)}
            </Select>
        </div>
    )
}

export default VehiclesDropDown
