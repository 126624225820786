import React from 'react'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'

function index() {
    return (
        <div>
            <SubPageBreadCrumbs />
            Home</div>
    )
}

export default index