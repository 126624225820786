import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ArrowLeftOutlined, EditFilled, DeleteFilled, PlusOutlined, CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { IconFlow, IconShare } from 'Components/Icons';
import { THEME } from 'Util/constants';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd'
import str from 'string-sanitizer'
import CommonDiv from 'Components/CommonDiv'


const menu = [
    { label: "Process" },
    // { label: "Item" },
    // { label: "Prices & Discount" },
    // { label: "Request Approval" },
    { label: "More Options" }
]

const menu2 = [
    { icon: null, label: "Pre-Approval" },
    { icon: <CheckOutlined style={{ color: THEME.colors.primary }} />, label: "Send Approval Request" },
    { icon: <CloseOutlined style={{ color: 'red' }} />, label: "Cancel Approval Request" },
    { icon: <IconFlow color={THEME.colors.primary} size={18} />, label: "Create Flow" },
    { icon: <IconFlow color={THEME.colors.primary} size={18} />, label: "See my Flows" }
]

const Container = styled.div`
    .actions-wrapper{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icons-wrapper{
            justify-content: space-between;
            width: 180px;
            svg{
                color: ${THEME.colors.primary};
                font-size: 25px;
                cursor: pointer;
            }
        }
        
        .backIconWrapper{
            background-color: ${THEME.colors.primary};
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
        h1{
            margin: 0px 10px;
        }
        
    }

    .submenu-wrapper{

        padding: 10px 0px;
        border-bottom: solid #555 thin;
        border-top: solid #555 thin;
        margin: 20px 0px;

        &>a{
            color: #555;
            margin: 0px 10px;
            font-size: 16px;
            font-weight: 500;
        }
    }
`

function Header({ editMode = false, label }) {

    const history = useHistory()

    const backHandler = () => {
        history.goBack()
    }

    const subMenuClickHandler = (val) => {

        switch (val) {
            case "Pre-Approval":

                Modal.confirm({
                    centered: true,
                    closable: true,
                    title: '',
                    icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
                    content: `Pre-Approve for Final Approval Request?`,
                    autoFocusButton: 'ok',
                    okText: "Pre-Approve",
                    okButtonProps: {
                        style: { backgroundColor: THEME.colors.primary }
                    },
                    cancelText: "Reject",
                    cancelButtonProps: {
                        style: { backgroundColor: 'red', color: '#fff' }
                    },
                });

                break;

            case "Send Approval Request":

                Modal.confirm({
                    centered: true,
                    closable: true,
                    title: '',
                    icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
                    content: `Request Final Approval for <Name here>`,
                    autoFocusButton: 'ok',
                    okText: "yes",
                    okButtonProps: {
                        style: { backgroundColor: THEME.colors.primary }
                    },
                    cancelText: "No",
                });
                break

            default:
                alert(val)
                break;
        }

    }

    return (
        <Container>

            <div className='actions-wrapper' >

                <div>
                    <button onClick={backHandler} className='backIconWrapper' >
                        <ArrowLeftOutlined />
                    </button>

                    <h1>{editMode ? 'Edit' : ''} Logistics Job Card</h1>
                </div>

                <div className='icons-wrapper' >
                    <EditFilled />
                    <IconShare color={THEME.colors.primary} size={25} />
                    <DeleteFilled />
                    <PlusOutlined />
                </div>

            </div>

            <CommonDiv direction="row">
                {editMode && <h3 style={{ padding: '20px 10px 0px' }} >{label}</h3>}
            </CommonDiv>

            {/* {editMode && <div className='submenu-wrapper'>
                {menu.map(v => (
                    <a key={`vendor-header-sub-${str.addDash(v.label)}`} onClick={() => subMenuClickHandler(v.label)} >{v.label}</a>
                ))}
            </div>} */}

            {/* <div className='submenu-wrapper'>
                {menu2.map(v => {
                    return <a key={`vendor-header-${str.addDash(v.label)}`} onClick={() => subMenuClickHandler(v.label)} >
                        {v.icon && <span style={{ marginRight: 5 }}>{v.icon}</span>}
                        {v.label}
                    </a>
                })}
            </div> */}

        </Container>
    )
}

Header.propTypes = {}

export default Header
