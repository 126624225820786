import { ArrowLeftOutlined} from '@ant-design/icons';
import { THEME } from "Util/constants";
import { Form, Select, Layout, Menu, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import { useSubscription } from '@apollo/client';
import { GET_POLL } from 'Util/queries';
import _ from 'lodash'
import Results from './PollDetailsCards/Results';
import Details from './PollDetailsCards/Details';

const { Sider, Content } = Layout;

const Container = styled.div`
    .actions-wrapper{   
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icons-wrapper{
            justify-content: flex-end;
            width: 180px;
            gap: 20px;        
            svg{
                color: ${THEME.colors.primary};
                font-size: 25px;
                cursor: pointer;
            }
        }
        
        .backIconWrapper{
            background-color: ${THEME.colors.primary};
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
        h1{
            margin: 0px 10px;
        }
        
    }

    .submenu-wrapper{

        padding: 10px 0px;
        border-bottom: solid #555 thin;
        border-top: solid #555 thin;
        margin: 20px 0px;

        &>span{
            color: #555;
            margin: 0px 10px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: ${THEME.colors.primary};
        color: ${THEME.colors.lightGray};
        border-style: solid;
        border-width: 1px;
        border-color: #eee;
        margin: 0px;
    }

    .ant-menu-inline .ant-menu-item::after {
        border-right: 0px;        
    }

    .ant-menu-inline > .ant-menu-item {
        color: ${THEME.colors.primary};
        border-style: solid;
        border-width: 1px;
        border-color: #eee;
        margin: 0px;  
    }
`

const SectionContainer = styled.div`
    .content {
        margin: 5px;
        display: flex;
        justify-content: space-between;
        & > div.division {
            width: 50%;
            .ant-form-item-label > label {
                width: 180px;
            }
            .ant-form-item-control {
                width: 10px;
            }
        }
    }
    .division-title {
        font-weight: bold;
    }

    .ant-layout {
        background-color: ${THEME.colors.lightGray};
    }

    .ant-switch-checked {
        background-color: ${THEME.colors.primary};
    }
      
    .ant-radio-checked .ant-radio-inner:after {
        background: ${THEME.colors.primary};
        border-color: ${THEME.colors.primary};
    }
      
    .ant-radio-inner {
        border-color: ${THEME.colors.primary};
    }      
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function PollDetailsPage(props) {
  const params = useParams();
  const history = useHistory();
  const [formLoading, setFormLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");
  const [poll, setPoll] = useState()

  const handleMenuClick = (menuItem) => {
    setSelectedItem(menuItem.key);
  };

  const [getPoll, { data}] =
    useLazyQuery(GET_POLL, {
        fetchPolicy: "network-only",
        onError(error) {
            console.log('error', error)
        },
        onCompleted() {
            const polls = data.polls?.[0]
            setPoll(polls)
        },
  });

  useEffect(() => {
    getPoll({
        variables: { where: { id: { _eq: params.id } } },
      });
  }, []);

  
  return poll ? (
    <Container>   
        <div className='actions-wrapper' >
            <div>
                <button onClick={() => history.goBack()} className='backIconWrapper' >
                    <ArrowLeftOutlined />
                </button>
                <h1>Quick Poll</h1>
            </div>
        </div>
        <div className='submenu-wrapper'>
            <span>{poll.title}</span>
        </div>

        <Section title="Poll Details">
            <Layout style={{ minHeight: '100vh' }}>
                <Sider width={200}>
                    <Menu
                        mode="inline"
                        selectedKeys={[selectedItem]}
                        onClick={handleMenuClick}
                        style={{ height: '100%', borderRight: 0 }}
                        >
                        <Menu.Item key="1">Details</Menu.Item>
                        <Menu.Item key="2">Recent Activity</Menu.Item>
                        <Menu.Item key="3">Analytics</Menu.Item>
                        <Menu.Item key="4">View Results</Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Content style={{ padding: '24px' }}>
                        {selectedItem === "1" && <Details poll={poll}/>}
                        {selectedItem === "2" && <div>Recent Activity</div>}
                        {selectedItem === "3" && <div>Analytics</div>}
                        {selectedItem === "4" && <Results poll={poll}/>}
                    </Content>
                </Layout>
            </Layout>
        </Section>
    </Container>
  ) : <Spin /> ;
}

PollDetailsPage.propTypes = {};

export default PollDetailsPage;
