import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { AcceptIcon, CardIcon, CounterIcon, EditIcon, RejectIcon, SaveIcon } from 'Components/Icons/actions';
import { Button, Input, notification, Popover } from 'antd';
import styled from 'styled-components';
import CommonDiv from 'Components/CommonDiv';
import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants';
import moment from 'moment-timezone';
import { useMutation } from '@apollo/client';
import { UPDATE_COUNTER_OFFER } from 'Util/mutations';

const PopContentWrapper = styled.div`
    width: 400px;
`

const PopTextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

function CounterOfferPopUp({ data }) {


    const [show, setShow] = useState(false)
    const [updateCounterOffer] = useMutation(UPDATE_COUNTER_OFFER)

    const priceInput = useRef();
    const volumeInput = useRef();

    const counterOfferHandler = () => {

        const { id } = data

        const counter = {
            price: priceInput.current.value,
            volume: volumeInput.current.value,
            prev_price: data.price,
            prev_volume: data.volume,
            time: moment().tz('Asia/Kolkata').format()
        }

        updateCounterOffer({ variables: { id, counter } }).then((res) => {
            notification['success']({
                message: 'Success!!',
                description: 'New counter offer submitter',
            });
            setShow(false)
        })

    }

    const content = () => {

        return <PopContentWrapper >
            <h2>Lot: #{leadingZeroes(data.id, appConstant.leadingZero)}</h2>

            <PopTextWrapper >
                <label>Auction#</label>
                <span>{data.auction_id}</span>
            </PopTextWrapper>
            <PopTextWrapper >
                <label>Product</label>
                <span>{data.product.label}</span>
            </PopTextWrapper>
            <PopTextWrapper >
                <label>Current Bid Price</label>
                <span>{data.price}</span>
            </PopTextWrapper>
            <PopTextWrapper >
                <label>Counter Offer Price</label>
                <input name='price' style={{ textAlign: 'right', width: 100 }} ref={priceInput} />
            </PopTextWrapper>
            <PopTextWrapper >
                <label>Current Bid Volume</label>
                <span>{data.volume}</span>
            </PopTextWrapper>
            <PopTextWrapper >
                <label>Counter Offer Volume</label>
                <input name='volume' style={{ textAlign: 'right', width: 100 }} ref={volumeInput} />
            </PopTextWrapper>

            <CommonDiv direction="row" margin="20px 0px 0px 0px" justify="flex-end">
                <Button style={{ marginRight: 10 }} type='primary' onClick={counterOfferHandler}>Submit</Button>
                <Button onClick={() => { setShow(false) }} >Cancel</Button>
            </CommonDiv>

        </PopContentWrapper>

    }

    return (
        <Popover
            title={<span> <CounterIcon style={{ width: 16, height: 16 }} /> Counter Offer</span>}
            trigger={(['pending', 'rejected'].includes(data.status)) ? 'click' : ''}
            visible={show}
            onVisibleChange={visble => setShow(visble)}
            content={content}
        >
            <CounterIcon color={(['pending', 'rejected'].includes(data.status)) ? null : '#cccccc'} />
        </Popover>
    )
}

CounterOfferPopUp.propTypes = {}

export default CounterOfferPopUp
