
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 33.33%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function NewSpecies(props) {
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState('item')

  const okHandler = () => {
    setVisible(false);
    history.push(`/procurement/product-list/new/${selected}`, { prevPath: '/procurement/product-list'})
  }

  return (
    <Container>
      <PlusOutlined onClick={() => setVisible(true)} style={{ marginLeft: 20 }} />
      <label>New</label>
      <Modal
        visible={visible}
        centered
        title="Select a template for a new product"
        okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
        width={600}
        closable={false}
        bodyStyle={{ background: '#F2F2F2' }}
        footer={[
          <Button key="ok" type="primary" onClick={okHandler} style={{ backgroundColor: THEME.colors.primary, borderColor: THEME.colors.primary}}>OK</Button>,
          <Button key="cancel" onClick={() => setVisible(false)}>Cancel</Button>
      ]}
      >
        <ModalContainer>

          <div className='thead' >
            <span> Code </span>
            <span> Description </span>
          </div>

          <div onClick={() => setSelected('item')} className={`tbody ${selected === 'item' ? 'selected' : ''}`} >
            <span> ITEM </span>
            <span> Item </span>
          </div>

          <div onClick={() => setSelected('service')} className={`tbody ${selected === 'service' ? 'selected' : ''}`} >
            <span> SERVICE </span>
            <span> Service </span>
          </div>

        </ModalContainer>
      </Modal>
    </Container>
  )
}

NewSpecies.propTypes = {}

export default NewSpecies
