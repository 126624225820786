import styled from "styled-components";
import ButtonCard from 'Components/ButtonCard';
import {
    IconAccounts, IconAdmin, IconCRM, IconDashboard, IconEcomm, IconERP, IconExchange, IconIntel, IconLogistics, IconProcure, IconSettings
} from 'Components/Icons';
import React from 'react';
import { useLocation } from "react-router-dom";

const menus = [
    { link: '', label: 'Dashboard', icon: IconDashboard, iconSize: 40 },
    { link: 'procurement', label: 'Procurement', icon: IconProcure, iconSize: 45 },
    { link: 'logistics', label: 'Logistics', icon: IconLogistics, iconSize: 50 },
    { link: 'erp', label: 'ERP', icon: IconERP, iconSize: 40 },
    { link: 'crm', label: 'CRM', icon: IconCRM, iconSize: 40 },
    { link: 'exchange', label: 'Exchange', icon: IconExchange, iconSize: 40 },
    { link: 'e-comm', label: 'E-comm', icon: IconEcomm, iconSize: 30 },
    { link: 'accounts', label: 'Accounts', icon: IconAccounts, iconSize: 35 },
    { link: 'admin', label: 'Admin', icon: IconAdmin, iconSize: 40 },
    { link: 'settings', label: 'Settings', icon: IconSettings, iconSize: 40 }
]

/** Styled */
const Container = styled.div`
    padding: 0px 50px;
    display: flex;
`
function TemplateMenu(props) {
    const location = useLocation();

    return (
        <Container>
            {menus.map(v => {
                const Icon = v.icon
                return <ButtonCard key={`template-menu-key-${v.link}`} title={v.label} link={'/' + v.link} center selected={location.pathname.split('/')[1] === v.link} >
                    <Icon color='#fff' size={v.iconSize} />
                </ButtonCard>
            })}
        </Container>
    )
}

TemplateMenu.propTypes = {

}

export default TemplateMenu

