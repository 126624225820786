import { appConstant, THEME } from "Util/constants";
import {
  Form,
  Input,
  notification,
  DatePicker,
  TimePicker
} from "antd";
import { createContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useLazyQuery, useMutation } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
// import { UPSERT_ROUTES, UPDATE_JOBS } from "Util/mutations";
import { GET_ROUTES } from "Util/queries";
import { useHistory } from "react-router-dom";
import { CardHeader, JobItems } from "./components/RoutesCard";
import DriversDropDown from "Components/DriversDropdown";
import moment from 'moment-timezone';
import DepotsDropDown from "Components/DepotsDropdown";
import VehiclesDropDown from "Components/VehiclesDropdown";
import { addRoute, updateRoute, deleteOrder, addOrder } from 'Util/trackpod'

moment.tz.setDefault("Asia/Kolkata");

export const RoutesCardContext = createContext()

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function RouteCard(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  const editMode = routeLocation.pathname.includes("/logistics/routes/edit");
  const [formLoading, setFormLoading] = useState(false);
  const [initialJobs, setInitialJobs] = useState([])
  const [initialJobsDetails, setInitialJobsDetails] = useState([])
  const [jobList, setJobList] = useState({})
  const [prevCode, setPrevCode] = useState()

  const [getJob, { loading, error, data, refetch, networkStatus }] =
    useLazyQuery(GET_ROUTES, {
      fetchPolicy: "network-only",
      onError(error) {
        console.log('error', error)
      },
      onCompleted(data) {
        console.log('dataq', data.routes[0]);
        const routeDetails = data.routes[0]

        form.setFieldsValue({
          ...routeDetails,
          date: moment(routeDetails.date).tz('Asia/Kolkata'),
          start_time: moment(routeDetails.start_time).tz('Asia/Kolkata'),
          driver_name: routeDetails.driver.name,
          vehicle_number: routeDetails.vehicle.number,
          depot_name: routeDetails.depot.name,
          depot_code: routeDetails.depot.code,
          driver_username: routeDetails.driver.username,
        })

        setInitialJobs(routeDetails.jobs.map(item => item.id))
        setInitialJobsDetails(routeDetails.jobs)
        setPrevCode(routeDetails.code)

      },
    });

  /**
   * this has been deprecated
   * const [update] = useMutation(UPSERT_ROUTES)
   * const [updateJobs] = useMutation(UPDATE_JOBS)
  */
  
  useEffect(() => {
    if (editMode) {
      console.log('querying...')
      getJob({ variables: { where: { id: { _eq: params.id } } }, });
    }
  }, [editMode, getJob, params.id]);

  const submitHandler = async (values) => {

    setFormLoading(true)

    const { jobs, depot_code, depot_name, vehicle_number, driver_name, driver_username, ...routeDetails } = values;
    const routeObjects = {
      ...routeDetails,
      start_time: moment(values.start_time).tz('Asia/Kolkata')
    };

    const originalSet = new Set(initialJobs);
    let updatedSet;
    let missingIds;
    let addedIds;

    const orders = jobList.map(item => ({
      Number: item.id.toString(),
      Id: item.id.toString(),
      Date: item.date,
      Type: item.type,
      Shipper: item.shipper?.name || "Catch Value",
      ShipperId: item.shipper_id.toString(),
      Depot: item.depot.name,
      Client: item.client,
      Address: item.address,
      TimeSlotFrom: item.time_from,
      TimeSlotTo: item.time_to,
      ServiceTime: item.service_time,
      Note: item.note,
      ContactName: item.contact_name,
      Phone: item.phone,
      Email: item.email,
      Barcode: item.barcode,
      DepotId: item.depot_id.toString(),
      GoodsList: item.purchase_orders.map(order => ({
        GoodsId: order.bid?.product.sku ?? order.direct_offer_order.direct_offer.species_sku,
        GoodsName: order.bid?.product.label ?? order.direct_offer_order.direct_offer.species_name,
        GoodsUnit: "KG",
        Quantity: order.bid?.volume ?? order.direct_offer_order.order_quantity,
        Cost: order.bid?.price ?? order.direct_offer_order.direct_offer.price
      }))
    }));

    const addOrdersList = initialJobsDetails.map(item => ({
      Number: item.id.toString(),
      Id: item.id.toString(),
      Date: item.date,
      Type: item.type,
      Shipper: item.shipper.name,
      ShipperId: item.shipper_id.toString(),
      Depot: item.depot.name,
      Client: item.client,
      Address: item.address,
      TimeSlotFrom: item.time_from,
      TimeSlotTo: item.time_to,
      ServiceTime: item.service_time,
      Note: item.note,
      ContactName: item.contact_name,
      Phone: item.phone,
      Email: item.email,
      Barcode: item.barcode,
      DepotId: item.depot_id.toString(),
      GoodsList: item.purchase_orders.map(order => ({
        GoodsId: order.bid?.product.sku ?? order.direct_offer_order.direct_offer.species_sku,
        GoodsName: order.bid?.product.label ?? order.direct_offer_order.direct_offer.species_name,
        GoodsUnit: "KG",
        Quantity: order.bid?.volume ?? order.direct_offer_order.order_quantity,
        Cost: order.bid?.price ?? order.direct_offer_order.direct_offer.price
      }))
    }));

    const addObject = {
      "Code": values.code,
      //"Id": values.code, //Routes created from TrackPod does not have ID
      "Date": moment(values.date).format('YYYY-MM-DD'),
      "StartTimePlan": moment(values.start_time).tz('Asia/Kolkata').format('HH:mm'),
      "DepotId": depot_code,
      "Depot": depot_name,
      "StartFromDepot": true,
      "ReturnToDepot": true,
      "DriverName": driver_name,
      "DriverLogin": driver_username,
      "Vehicle": {
        "Number": vehicle_number,
      },
      "PrevCode": prevCode,
      Orders: orders
    }
    
    if (jobs) {
      updatedSet = new Set(jobs);
      missingIds = initialJobs.filter(id => !updatedSet.has(id));
      addedIds = jobs.filter(id => !originalSet.has(id));

      const missingPromises = missingIds.map(async id => {
        console.log(`Processing missing job with ID: ${id}`);
        const orderObject = addOrdersList.find(order => order.Id === id.toString());
        if (!orderObject) {
          console.error(`Order with ID: ${id} not found`);
          return null;
        }
        try {
          const res = await addOrder(orderObject)
          const { Detail, Status, Title } = res
          console.log({ Detail, Status, Title });
        } catch (error) {
          console.error(`Failed to create job with ID: ${id}`, error);
          return null
        }
        return null
      });

      const addedPromises = addedIds.map(async id => {
        console.log(`Processing added job with ID: ${id}`);
        try {
          const deleteObject = { "Number": id };
          const getRes = await deleteOrder(deleteObject);
          console.log('Delete response:', getRes);
        } catch (error) {
          console.error(`Failed to delete job with ID: ${id}`, error);
          return null
        }
      });

      try {
        await Promise.all([...missingPromises, ...addedPromises]);
        console.log('All promises completed successfully.');
      } catch (error) {
        console.error('Error occurred during batch processing:', error);
      }
    }

    if (editMode) {
      try {
        const res = await updateRoute(addObject)
        console.log('res', res);
        const { Detail, Status, Title } = res
        console.log({ Detail, Status, Title });

        if (Status !== 202) {
          notification["error"]({
            message: Title,
            description: Detail,
          });
          setFormLoading(false)
          history.goBack()
          return
        }
      } catch (error) {
        console.log('errorerrorerror', error);
        if (error.response) {
          console.error('Error status code:', error.response.status);
          if (error.response.status === 405) {
            console.error('Error 405: Method Not Allowed');
          } else if (error.response.status === 500) {
            console.error('Error 500: Internal Server Error');
          }
        } else {
          console.error('Error occurred:', error);
        }
        notification["error"]({
          message: "Something went wrong!",
          description: "Please try again later.",
        });
        setFormLoading(false)
        history.goBack()
        return
      }

    }
    else {
      try {
        const res = await addRoute(addObject)
        console.log(res);
        const { Detail, Status, Title } = res
        console.log({ Detail, Status, Title });

        if (Status !== 201) {
          notification["error"]({
            message: Title,
            description: Detail,
          });
          setFormLoading(false)
          history.goBack()
          return
        }
      } catch (error) {
        console.log('errorerrorerror', error);
        if (error.response) {
          console.error('Error status code:', error.response.status);
          if (error.response.status === 405) {
            console.error('Error 405: Method Not Allowed');
          } else if (error.response.status === 500) {
            console.error('Error 500: Internal Server Error');
          }
        } else {
          console.error('Error occurred:', error);
        }
        notification["error"]({
          message: "Something went wrong!",
          description: "Please try again later.",
        });
        setFormLoading(false)
        history.goBack()
        return
      }
    }

    try {

      /** 
       * This has been deprecated
      const { data: routeData } = await update({
        variables: {
          update_columns: ["id", ...Object.keys(routeDetails)],
          objects: [routeObjects]
        }
      });

      const routeId = routeData.insert_routes.returning[0].id

      if(missingIds && missingIds.length > 0){
        updateJobs({
          variables: {
            where: { id: { _in: missingIds } },
            _set: { route_id: null }
          }
        })
      }

      if(addedIds && addedIds.length > 0){
        updateJobs({
          variables: {
            where: { id: { _in: addedIds } },
            _set: { route_id: routeId }
          }
        })
      }

       */

      notification.success({
        message: "Saved!",
        description: "Routes Saved!",
      });
    } catch (error) {
      console.log('error', error);
      notification.error({
        message: "Error",
        description: "Something went wrong while saving.",
      });
    } finally {
      setFormLoading(false);
    }

    setFormLoading(false)
    history.goBack()

  };
  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const setFieldValue = (key, value) => {
    if (!Array.isArray(key)) form.setFieldsValue({ ...form.getFieldsValue(), [key]: value })
    else form.setFieldsValue({
      ...form.getFieldsValue(),
      [key[0]]: {
        ...form.getFieldsValue()?.[key[0]],
        [key[1]]: value
      }
    })
  }

  return (
    <RoutesCardContext.Provider value={{ jobList, setJobList }} >
      <CardHeader editMode={editMode} />
      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="Route Details">
          <div className="division">

            <Form.Item label="id" name="id" hidden >
              <Input />
            </Form.Item>

            <Form.Item label="Route ID No. (Route Code)" name="code">
              <Input disabled placeholder="Auto generated"/>
            </Form.Item>

            <Form.Item label="Date" name="date">
              <DatePicker format="DD MMM YYYY" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Start Time" name="start_time" >
              <TimePicker
                placeholder={appConstant.timeFormat2}
                format={appConstant.timeFormat2}
                showNow={false}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item label="Depot/Ship From" name="depot_id" rules={[{ required: true, message: '' }]}>
              <DepotsDropDown form={form} />
            </Form.Item>

            <Form.Item name="depot_name" hidden>
              <Input />
            </Form.Item>

            <Form.Item name="depot_code" hidden>
              <Input />
            </Form.Item>

            <Form.Item label="Vehicle Assinged" name="vehicle_id" rules={[{ required: true, message: '' }]}>
              <VehiclesDropDown form={form} />
            </Form.Item>

            <Form.Item name="vehicle_number" hidden>
              <Input />
            </Form.Item>

            <Form.Item label="Driver Assigned" name="driver_id" rules={[{ required: true, message: '' }]}>
              <DriversDropDown form={form} />
            </Form.Item>

            <Form.Item name="driver_name" hidden>
              <Input />
            </Form.Item>

            <Form.Item name="driver_username" hidden>
              <Input />
            </Form.Item>

          </div>

          <div className="division">
            <Form.Item name="jobs">
              <JobItems setFieldValue={setFieldValue} routeId={params?.id} />
            </Form.Item>

          </div>

        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit" >
            {editMode ? "Update" : "Save"}
          </ButtonLoader>

          <CommonButton className='reverse' onClick={() => history.goBack()}>
            Cancel
          </CommonButton>

        </CommonDiv>
      </Form>
    </RoutesCardContext.Provider>
  );
}

RouteCard.propTypes = {};

export default RouteCard;
