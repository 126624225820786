import { Select, Input, Switch, Popconfirm, notification } from 'antd';
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { Link, useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { DirectSalesItemsContext } from '..';
import { SearchOutlined, PlusOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons';
import { ExtraFilters } from '.';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import { auctionStatusText } from 'Util/common';
import { directSalesFilterAtom } from 'Store/directSalesAtom';
import { debounce } from 'lodash';

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const MenuButtonContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

function TableFilters(props) {

    const history = useHistory()
    const { fitler, setFitler } = useContext(DirectSalesItemsContext) /** removed laters */
    const [{ auctionStatus, currentAuction }] = useRecoilState(auctionAtom);
    const [filter, setFilter] = useRecoilState(directSalesFilterAtom);

    const updateQuery = (e) =>{
        setFilter({ ...filter, search: e.target.value })
    }
    const debouncedOnChange = debounce(updateQuery, 200);

    return (
        <Container>

            {
                /* <DropdownContainer>
                    <label>Auction: </label>
                    <Select defaultValue="live"
                        style={{ width: 100 }}
                        bordered={false}
                        onChange={v => { setFitler(v) }}
                    >
                        <Option value="open">Open</Option>
                        <Option value="accepted">Accepted</Option>
                        <Option value="withdrawn">Withdrawn</Option>
                    </Select>
                </DropdownContainer> */
            }

            <SearchContainer>
                <Input prefix={<SearchOutlined />} style={{ width: 200 }} placeholder="Search" onChange={(e) => debouncedOnChange(e)}/>
            </SearchContainer>

            {
                /* <MenuButtonContainer>
                    <label> Auction <span>{currentAuction || 'N/A'}</span> : <span style={{ color: THEME.colors.primary, textTransform: "capitalize" }} >{auctionStatusText(auctionStatus)}</span>
                    </label>
                </MenuButtonContainer>
    
                <MenuButtonContainer>
                    <label> Auto Accept:  </label>
                    <Switch style={{ background: THEME.colors.primary }} />
                </MenuButtonContainer> */
            }

           <ExtraFilters data={props.data} columns={props.columns}/>

        </Container>
    )
}

TableFilters.propTypes = {}

export default TableFilters
