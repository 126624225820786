import { Table } from 'antd';
import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { AuctionDataContext } from '..';
import { useSubscription } from '@apollo/client';
import { SUB_BIDS_LIST } from 'Util/subscriptions';
import { getDate, getTimeFromDate } from 'Util/common';
import { appConstant } from 'Util/constants';
import leadingZeroes from 'leading-zeroes';
import _ from 'lodash'
import { useRecoilState } from 'recoil';
import { auctionDataTableFiltersAtom } from 'Store/auctionAtom';
import moment from 'moment';
import TableFilters from "./TableFilters";

const Container = styled.div``

const columns = [
    {
        title: 'Date',
        dataIndex: 'auction_date',
        key: 'auction_date',
        sorter: (a, b) => (new Date(a.auction_date) - new Date(b.auction_date)),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Auction',
        dataIndex: 'auction_id',
        key: 'auction_id',
        sorter: (a, b) => a.auction_id - b.auction_id,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Time',
        dataIndex: 'auction_time',
        key: 'auction_time',
        sorter: (a, b) => a.auction_time.localeCompare(b.auction_time),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Lot #',
        dataIndex: 'lot_number',
        key: 'lot_number',
        sorter: (a, b) => a.lot_number - b.lot_number,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Vendor ID',
        dataIndex: 'fisherman_id',
        key: 'fisherman_id',
        sorter: (a, b) => a.fisherman_id - b.fisherman_id,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: ' Location',
        dataIndex: 'location',
        key: 'location',
        sorter: (a, b) => a.location.localeCompare(b.location),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Species',
        dataIndex: 'species',
        key: 'species',
        sorter: (a, b) => a.species.localeCompare(b.species),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Weight (KG)',
        dataIndex: 'weight',
        key: 'weight',
        sorter: (a, b) => a.weight - b.weight,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Bid price (Unit)',
        dataIndex: 'bid_price',
        key: 'bid_price',
        sorter: (a, b) => a.bid_price - b.bid_price,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Log Cost',
        dataIndex: 'log_cost',
        key: 'log_cost',
        sorter: (a, b) => a.log_cost - b.log_cost,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Total Value',
        dataIndex: 'total_value',
        key: 'total_value',
        sorter: (a, b) => a.total_value - b.total_value,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Rem Quota',
        dataIndex: 'rem_quota',
        key: 'rem_quota',
        sorter: (a, b) => a.rem_quota - b.rem_quota,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Reserve Price',
        dataIndex: 'reserved_price',
        key: 'reserved_price',
        sorter: (a, b) => a.reserved_price - b.reserved_price,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Exp Revenue',
        dataIndex: 'exp_unit_rev',
        key: 'exp_unit_rev',
        sorter: (a, b) => a.exp_unit_rev - b.exp_unit_rev,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Gross',
        dataIndex: 'gross',
        key: 'gross',
        sorter: (a, b) => a.gross - b.gross,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (val) => {
            return <span style={{ color: val === 'rejected' ? 'red' : 'green', textTransform: 'uppercase' }} >
                {val === 'pending' ? 'pending collection' : val}
                </span>
        }
    },
    {
        title: "PO Lot#",
        dataIndex: "po_id",
        key: "po_id",
        sorter: (a, b) => a.po_id - b.po_id,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: "Pickup#",
        dataIndex: "pickup",
        key: "pickup",
        sorter: (a, b) => a.pickup - b.pickup,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (val) => {

            return val ? val : 'N/A'

        }
    }
]

function DataTable() {

    const { setSelectedRecord, setCsvData } = useContext(AuctionDataContext)

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([])
    const [filter, setFilter] = useRecoilState(auctionDataTableFiltersAtom)

    const fromDate = moment().subtract(1, filter.range).format('YYYY-MM-DD');
 
    /** GraphQL Hooks */
    const { loading } = useSubscription(SUB_BIDS_LIST, {
        variables: {
            where: {
                created_at: { _gte: fromDate },
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {     
            data.bids && setData(data.bids.map(bid => {
                const {purchase_orders, pickup_orders, product, user} = bid
                let logCost = product.log_cost
                let expUnitRev = pickup_orders[0]?.exp_unit_rev ?? 0
                let gross = expUnitRev - bid.price - logCost
                let grossPercent = ((gross / expUnitRev) * 100).toFixed(2)
                return {
                    key: `key-${bid.id}-bid-list`,
                    auction_date: getDate(bid.created_at),
                    auction_id: bid.auction_id,
                    auction_time: getTimeFromDate(bid.created_at, true),
                    lot_number: bid.id,
                    fisherman_id: leadingZeroes(user.id, appConstant.leadingZero),
                    location: bid.user.user_location.label,
                    species: product.label,
                    weight: bid.volume,
                    bid_price: bid.price,
                    log_cost: logCost,
                    total_value: bid.volume * bid.price,
                    rem_quota: bid.rem_quota,
                    reserved_price: product.price,
                    exp_unit_rev: expUnitRev,
                    gross: gross,
                    gross_percent: grossPercent,
                    status: bid.status === 'accepted' ? purchase_orders[0]?.status : bid.status,
                    pickup: pickup_orders[0]?.id,
                    po_id: bid.status === 'accepted' ? purchase_orders[0]?.id : 'None',
                    po: purchase_orders
                }
            }))

        }
    });

    const onSelectChange = (newSelectedRowKeys, data) => {
        setSelectedRecord(data)
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const filterData = (data) => {
        const searchable = data.map(obj => ({
            ...obj,
            searchText: `${obj.auction_date} ${obj.auction_id} ${obj.auction_time} ${obj.lot_number} ${obj.fisherman_id} ${obj.location} ${obj.species} ${obj.weight} ${obj.bid_price} ${obj.log_cost} ${obj.total_value} ${obj.rem_quota} ${obj.reserved_price} ${obj.exp_unit_rev} ${obj.gross} ${obj.status} ${obj.po_id} ${obj.pickup ? obj.pickup : 'N/A'}`
        }))

        if (!filter.searchText && filter.status === 'all') {
            return data
        }

        return _.filter(searchable, function (d) {
            let query = filter.searchText.toLowerCase();
            if(filter.status === 'all'){
                return _.includes(d.searchText.toLowerCase(), query)
            } else{
                return _.includes(d.searchText.toLowerCase(), query) && d.status === filter.status.split(" ")[0];
            }
        });     
    }
    

    /** clean up */
    useEffect(() => {
        return () => { setFilter({ ...filter, range: "1d", searchText: '' }) }
    }, [])

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={columns}/>
            <Table
                loading={loading}
                size='small'
                columns={columns}
                dataSource={filterData(data)}
                rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 50
                }}
            />
        </Container>
    )
}

export default DataTable