import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, notification, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_DEPOTS, UPSERT_SHIPPERS } from 'Util/mutations';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import { LogisticsJobsContext } from '../..';

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: ${props => props.editMode ? '0px' : '5px'};
        margin-top: ${props => props.editMode ? '5px' : '0'};
    }
    label{
        font-weight: bold;
    } 
`;

function Delete({editMode = false}) {
    const { selectedRecord, setSelectedRecord } = useContext(LogisticsJobsContext) || {}
    const history = useHistory()

    const [deleteDepot] = useMutation(UPDATE_DEPOTS, {
        onCompleted(){
            notification["success"]({
                message: 'Deleted',
                description: "Depot has been deleted"
            });
        }
    })
  
    const confirmDelete = async () => {    

        await deleteDepot({
            variables: {
              where: { id: { _eq: selectedRecord.id } },
              _set: { deleted_at: moment() }
            }
        })

        setSelectedRecord({})

        if (editMode) history.push(`/logistics/depots`)
    }

    const confirm = () => {
        selectedRecord.id && Modal.confirm({
            centered: true,
            icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
            content: `Delete ${selectedRecord.code} - ${selectedRecord.name}?`,
            autoFocusButton: 'ok',
            okButtonProps: {
                style: { backgroundColor: THEME.colors.primary }
            },
            onOk: confirmDelete
        });
    };

    if (!selectedRecord) return <DeleteFilled />

    return (
        <Container editMode={editMode}>
            <Tooltip title={selectedRecord.code}>
                <DeleteFilled onClick={confirm} />
                {!editMode && <label>Delete</label>}
            </Tooltip>
        </Container>
    )
}

Delete.propTypes = {
    editMode: PropTypes.bool
}

export default Delete