import { useMutation, useLazyQuery, useSubscription } from '@apollo/client'
import { Form, Input, Select, TimePicker, DatePicker, Switch, notification, Button } from 'antd'
import CommonButton, { ButtonLoader } from 'Components/CommonButton'
import CommonDiv from 'Components/CommonDiv'
import CardHeader from 'Components/CommonHeader/CardHeader'
import { FormDivision, FormSection } from 'Components/Form'
import CardSubMenu from 'Menus/CardSubMenu'
import moment from 'moment'
import { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash'
import styled from 'styled-components'
import { ADD_AUCTION_SCHEDULES, ADD_AUCTION_SCHEDULE_SETTINGS, UPDATE_AUCTION_SCHEDULE_SETTINGS, UPDATE_AUCTION_SCHEDULES } from 'Util/mutations'
import { GET_AUCTION_SCHEDULES_SETTINGS, GET_AUCTION_SCHEDULES } from 'Util/queries'
import { auctionScheduleAtom } from 'Store/auctionAtom';
import { useRecoilState } from 'recoil';
import { SUB_AUCTION_SCHEDULES_SETTINGS, SUB_AUCTION_SCHEDULES } from 'Util/subscriptions'

const { Option } = Select;

const Container = styled.div`
    .ant-picker{
        width: 200px;
    }
    .ant-form-item-control-input{
        width: 200px;
    }
`

function NewScheduleCard() {

    const history = useHistory()
    const params = useParams()

    const [selectedSchedule, setSelectedSchedule] = useRecoilState(auctionScheduleAtom)
    const [cardName, setCardName] = useState("New")
    const [recurring, setRecurring] = useState(false)
    const [resultsDuration, setResultsDuration] = useState(null)
    const [counterOfferDuration, setCounterOfferDuration] = useState(null)
    const [disabledSeconds, setDisabledSeconds] = useState(_.range(0, 60))
    const [scheduleRecords, setScheduleRecords] = useState([])
    const [times, setTimes] = useState([null, null, null, null]);
    const [disableSave, setDisableSave] = useState(true)
    const [datePickerValue, setDatePickerValue] = useState(null)
    const [remainingTime, setRemainingTime] = useState(null);
    const [totalDuration, setTotalDuration] = useState(0)
    const [scheduleSettings, setScheduleSettings] = useState([])
    const [notify, setNotify] = useState(true);

    const totalD = useRef()
    const [form] = Form.useForm()
    const toSecs = (val) => moment.duration(moment(val).format('HH:mm:ss')).asSeconds()
    const toMoment = (val) => moment().startOf('day').add(val,'seconds')
    const editOption = history.location.state?.editOption
    const scheduleDate = history.location.state?.scheduleDate

    const editMode = !_.isUndefined(params.id)

    useSubscription(SUB_AUCTION_SCHEDULES_SETTINGS, {
        variables: { where: { recurring: { _eq: true } } },
        onSubscriptionData({ subscriptionData: { data } }) {
            if (data && data.auction_schedule_settings) {
                setScheduleSettings(data.auction_schedule_settings)
            }
        }
    })

    const [getScheduleRecords, { variables}] = useLazyQuery(GET_AUCTION_SCHEDULES, {
        fetchPolicy: "network-only",
        onCompleted(data) {

            let startDate = variables.where.datetime._gte
            let endDate = moment(variables.where.datetime._lte).subtract(1, 'day');
            let selectedDate = moment(variables.where.date._gte, "YYYY-MM-DD HH:mm:00");

            let scheds = []

            if (data && data.auction_schedules) {
                scheds = data.auction_schedules.map((d) => ({
                    datetime: d.datetime,
                    duration: d.total_duration
                }))
            }

            let result = []

            const ignoreSchedules = scheduleSettings.filter(obj => obj.override === 'ignore');

            scheduleSettings.forEach(el => {
                const eventDates = generateDatesForRepeat(el.repeat, el.date, startDate, endDate, el.override, ignoreSchedules, el.id, el.end_date);
                eventDates.forEach(eventDate => {
                    if (eventDate >= moment(startDate).format('YYYY-MM-DD') && eventDate <= moment(endDate).format('YYYY-MM-DD')) {
                        result.push({
                            datetime: `${eventDate}T${moment(el.datetime).format('HH:mm:ss')}`,
                            duration: el.total_duration
                        });
                    }
                });
            });
            
            let finalResult = result.concat(scheds);
            setScheduleRecords(finalResult)

            let nearestDatetime = null;
            let minTimeDifference = Infinity;

            finalResult.forEach(entry => {
                const entryMoment = moment(entry.datetime);
                const timeDifference = entryMoment.diff(selectedDate, 'seconds');
                
                if (timeDifference > 0 && timeDifference < minTimeDifference) {
                    minTimeDifference = timeDifference;
                    nearestDatetime = entryMoment;
                }
            });

            if (nearestDatetime) {
                const timeDifferenceInSeconds = nearestDatetime.diff(selectedDate, 'seconds');
                totalD.current = timeDifferenceInSeconds
                let remainingDuration = timeDifferenceInSeconds - totalDuration
                if (remainingDuration < 0) {
                    setDisableSave(true)
                }else{
                    clearErrors()
                    setDisableSave(false)
                }
            } else {
                totalD.current = 86400
            }

        }
    })

    const [getScheduleSettings] = useLazyQuery(GET_AUCTION_SCHEDULES_SETTINGS, {
        onCompleted(data) {
            const values = data.auction_schedule_settings[0]
            data.auction_schedule_settings[0] && form.setFieldsValue({
                datetime: moment(scheduleDate + ' ' + values.auction_time),
                pre_bid_duration: toMoment(values.pre_bid_duration),
                auction_duration: toMoment(values.auction_duration),
                results_duration: toMoment(values.results_duration),
                counter_offer_duration: toMoment(values.counter_offer_duration),
                recurring: true,
                repeat: values.repeat
            })
            setRecurring(true)
            setCardName(values.id.toString())
        }
    })

    const [getSchedule] = useLazyQuery(GET_AUCTION_SCHEDULES, {
        onCompleted(data) {
            const values = data.auction_schedules[0]
            data.auction_schedules[0] && form.setFieldsValue({
                datetime : moment(values.datetime),
                pre_bid_duration: toMoment(values.settings.duration.prebid),
                auction_duration: toMoment(values.settings.duration.auction),
                results_duration: toMoment(values.settings.duration.results),
                counter_offer_duration: toMoment(values.settings.duration.counter),
                recurring: false,
                repeat: values.repeat
            })
            setRecurring(false)
            setCardName(values.id.toString())
        }
    })

    const [addScheduleSettings] = useMutation(ADD_AUCTION_SCHEDULE_SETTINGS, {
        onCompleted(data) {
            if (notify) {
                notification['success']({
                    message: 'Added!',
                    description: 'Schedule added!',
                });
                setNotify(false);
            }
        }
    })

    const [addSchedule] = useMutation(ADD_AUCTION_SCHEDULES, {
        onCompleted(data) {
            if (notify) {
                notification['success']({
                    message: 'Added!',
                    description: 'Schedule added!',
                });
                setNotify(false);
            }
        }
    })

    const [updateScheduleSettings] = useMutation(UPDATE_AUCTION_SCHEDULE_SETTINGS, {
        onCompleted() {
            if (notify) {
                notification['success']({
                    message: 'Updated!',
                    description: 'Schedule updated!',
                });
                setNotify(false);
            }
            setSelectedSchedule({})
        }
    });

    const [updateSchedules] = useMutation(UPDATE_AUCTION_SCHEDULES, {
        onCompleted() {
            if (notify) {
                notification['success']({
                    message: 'Updated!',
                    description: 'Schedule updated!',
                });
                setNotify(false);
            }
            setSelectedSchedule({})
        }
    });

    const disabledMinutes = () => {
        if (moment(resultsDuration).seconds() === 0){
            return _.range(moment(resultsDuration).minutes(), 60)
        }
        return _.range(moment(resultsDuration).minutes() + 1, 60)
    }

    const onSelect = (time)  => {
        let resultMin = moment(resultsDuration).minutes()
        let resultSec = moment(resultsDuration).seconds()
        let counterMin = moment(time).minutes()
        if (counterMin === resultMin){
            setDisabledSeconds(_.range(resultSec, 60))    
        }
        else{
            setDisabledSeconds([])       
        }
        setCounterOfferDuration(time)
    }

    const onChange = (index, time) => {
        setResultsDuration(time)
        if (time && counterOfferDuration > time){
            form.resetFields(["counter_offer_duration"])
        }
        handleTimeChange(index, time)
    }

    const addAuctionSchedule = (obj) => {
        addSchedule({
            variables: {
                objects: {
                    datetime: obj.datetime.format('YYYY-MM-DD HH:mm:00'),
                    date: obj.datetime,
                    status: "scheduled",
                    active: obj.datetime.format('YYYY-MM-DD') === moment().tz('Asia/Kolkata').format('YYYY-MM-DD'),
                    settings: {
                        duration: {
                            prebid: obj.pre_bid_duration,
                            auction: obj.auction_duration,
                            results: obj.results_duration,
                            counter: obj.counter_offer_duration
                        }
                    },
                    total_duration: obj.pre_bid_duration + obj.auction_duration + obj.results_duration + obj.counter_offer_duration
                }
            }
        })
    }

    const addAuctionScheduleSettings = (obj) => {
        addScheduleSettings({
            variables: {
                objects: {
                    repeat: obj.repeat,
                    recurring: obj.recurring,
                    pre_bid_duration: obj.pre_bid_duration,
                    auction_duration: obj.auction_duration,
                    results_duration: obj.results_duration,
                    counter_offer_duration: obj.counter_offer_duration,
                    date: obj.datetime,
                    datetime: obj.datetime.format('YYYY-MM-DD HH:mm:00'),
                    auction_time: obj.datetime.format('HH:mm'),
                    total_duration: obj.pre_bid_duration + obj.auction_duration + obj.results_duration + obj.counter_offer_duration,
                    override: obj.override ? "ignore" : null,
                    reference_id: obj.override ? params.id : null,
                    end_date: obj.override ? scheduleDate : null
                }
            }
        })
    }
                     
    const submitHandler = async (values) => {

        const obj = {
            ...values,
            datetime: moment(values.datetime).utcOffset(0, true),
            pre_bid_duration: toSecs(values.pre_bid_duration),
            auction_duration: toSecs(values.auction_duration),
            results_duration: toSecs(values.results_duration),
            counter_offer_duration: toSecs(values.counter_offer_duration)
        }

        if(editMode){
            if(recurring){
                if(editOption === "single"){
                    obj.override = true
                    addAuctionSchedule(obj)
                    addAuctionScheduleSettings(obj)
                }
                if(editOption === "series"){
                    addAuctionScheduleSettings(obj)
                    updateScheduleSettings({
                        variables: {
                            where: { id: {_eq:  params.id}},
                            _set: {
                                end_date: scheduleDate
                            }   
                        }
                    })
                }
            }
            else{
                updateSchedules({
                    variables: {
                        where: { id: {_eq:  params.id}},
                        _set: {
                            datetime: obj.datetime.format('YYYY-MM-DD HH:mm:00'),
                            date: obj.datetime,
                            status: "scheduled",
                            active: obj.datetime.format('YYYY-MM-DD') === moment().tz('Asia/Kolkata').format('YYYY-MM-DD'),
                            settings: {
                                duration: {
                                    prebid: obj.pre_bid_duration,
                                    auction: obj.auction_duration,
                                    results: obj.results_duration,
                                    counter: obj.counter_offer_duration
                                }
                            },
                            total_duration: obj.pre_bid_duration + obj.auction_duration + obj.results_duration + obj.counter_offer_duration
                        }   
                    }
                })
            }
        }
        else{
            /** add to schedule */
            await addAuctionSchedule(obj)

            if (obj.recurring) {
                /** add to schedule settings */
                addAuctionScheduleSettings(obj)
            }
        }
        setTimeout(() => { history.goBack() }, 2000)
    }
    const cancelHandler = () => {
        setSelectedSchedule({})
        history.goBack()
    }

    const errorHandler = (err) => {

    }

    const minutesFormat = "mm:ss"
    const hourFormat = "HH:mm:ss"
           
    useEffect(() => {
        if(editMode && selectedSchedule.recurring) {
            getScheduleSettings({ variables: { where: { id: { _eq: params.id } } } })
        }
        if(editMode && !selectedSchedule.recurring) {
            getSchedule({ variables: { where: { id: { _eq: params.id } } } })
            const timeFormat = 'YYYY-MM-DD'
            const startDate = moment(selectedSchedule.datetime).format(timeFormat)
            const endDate = moment(selectedSchedule.datetime).add(2, 'days').format(timeFormat)

            getScheduleRecords({ variables: {
                where: { 
                    datetime: { _gte: startDate, _lte: endDate },
                    date: { _gte: selectedSchedule.datetime },
                    id: { _neq: selectedSchedule.id}
                },
                order_by: { 'datetime': 'asc' }
            } })
        }
    }, [])
 
    const disabledDate = (current) => {
        return current && current < moment().startOf('day');
    };
      
    const onChangeDateTime = (value, dateString) => {
        const date = value.format('YYYY-MM-DD HH:mm:00')
        setDatePickerValue(date)
    }

    const handleDateSelect = (value) => {
        if (datePickerValue && moment(value).hour() !== moment(datePickerValue).hour()) {
            value.minute(0); // TODO: Reset to available minutes
        }

        const date = value.format('YYYY-MM-DD HH:mm:00')

        const timeFormat = 'YYYY-MM-DD'
        const startDate = moment(date).format(timeFormat)
        const endDate = moment(date).add(2, 'days').format(timeFormat)

        getScheduleRecords({ variables: {
            where: { 
                datetime: { _gte: startDate, _lte: endDate },
                date: { _gte: date },
                ...(editMode ? { id: { _neq: selectedSchedule.id } } : {})
            },
            order_by: { 'datetime': 'asc' }
        } })

        setDatePickerValue(date)
    };

    const clearErrors = () => {
        const fields = ['pre_bid_duration', 'auction_duration', 'results_duration', 'counter_offer_duration']
        const fieldErrors = fields.map(fieldName => ({
            name: fieldName,
            errors: [],
          }));
          
        form.setFields(fieldErrors);                  
    }

    const handleTimeChange = (index, mTime) => {
        const time = toSecs(mTime)
        const newTimes = [...times];
        newTimes[index] = time;
        setTimes(newTimes);

        const validTimes = newTimes.filter(tm => tm !== null);
        let sum = 0
        if (validTimes.length >= 2) {
            const totalDurationInSeconds = _.sum(validTimes)
            sum = totalDurationInSeconds
        }
        else{
            sum = validTimes[0]
        }
        const remainingDuration = totalD.current - sum
        setRemainingTime(remainingDuration)
        setTotalDuration(sum)

        if (remainingDuration < 0) {
            setDisableSave(true)
        }else{
            clearErrors()
            setDisableSave(false)
        }
    };
    
    const generateDatesForRepeat = (repeat, date, startDate, endDate, override, ignoreSchedules, id, scheduleEndDate) => {
        const dates = [];
        const isTodayOrBefore = moment(startDate).isSameOrBefore(moment(), 'day');
        let currentDate = !isTodayOrBefore ? moment(startDate) : moment(startDate).add(1, 'days');
        let end = scheduleEndDate ? moment(scheduleEndDate).subtract(1, 'days') : moment(endDate)

        const ignoreDate = ignoreSchedules.filter(item => item.reference_id === id);
    
        while (currentDate <= end && override !== "ignore") {
            if (
                repeat === 'daily' ||
                (repeat === 'weekly' && currentDate.format('ddd') === moment(date).format('ddd')) ||
                (repeat === 'monthly' && currentDate.format('DD') === moment(date).format('DD'))
            ) {
                let targetDate = currentDate.format('YYYY-MM-DD')
                const found = ignoreDate.some(entry => moment(entry.end_date).format('YYYY-MM-DD') === targetDate);
                if (!found ) {
                    dates.push(targetDate);
                }                
            }
            currentDate = currentDate.add(1, 'days');
        }   
        return dates;
    };

    const disabledDateTime = (current) => {      
        if (!current) { return []; }
      
        const selectedDate = current.format('YYYY-MM-DD')
        const selectedHour = current.hour();
        const selectedMinute = current.minute();
        const disabledTimesArray = scheduleRecords;
      
        const disabledTimes = disabledTimesArray.map(item => {
          const dateTime = moment(item.datetime);
          const startTime = dateTime.clone();
          const endTime = dateTime.clone().add(item.duration, 'seconds');
          return {
            start: [startTime.hour(), startTime.minute()],
            end: [endTime.hour(), endTime.minute()],
            date: dateTime.format('YYYY-MM-DD')
          };
        });

        const disabledHours = disabledTimes
            .flatMap((timeRange) => {
                const startHour = timeRange.start[0];
                const startMinute = timeRange.start[1];
                const endHour = timeRange.end[0];
                const endMinute = timeRange.end[1];
        
                if ((startHour > endHour || (startHour === endHour && startMinute >= endMinute)) && timeRange.date !== selectedDate) {
                    return [];
                }
        
                const hoursInRange = [];
                for (let hour = startHour; hour < endHour; hour++) {
                    hoursInRange.push(hour);
                }
        
                return hoursInRange;
        });
      

        const disabledMinutes = disabledTimes
            .filter(timeRange => timeRange.start[0] === selectedHour && timeRange.date === selectedDate)
            .flatMap(timeRange =>
                Array.from({ length: timeRange.end[1] - timeRange.start[1] + 1 }, (_, i) => timeRange.start[1] + i)
            );
            
        return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
        };
    };
      
    function customTimeValidator() {
        return (_, value) => {
            if (remainingTime >= 0) {
                return Promise.resolve();
            } else {
                return Promise.reject(new Error('Selected duration overlaps the next schedule.'));
            }
        };
    }      
       
    return (
        <Container>
            <CardHeader
                title='Auction Schedule'
                itemName={cardName}
            />

            <CardSubMenu items={[
                <label>Menu 1</label>,
                <label>Menu 2</label>,
                <label>Menu 3</label>,
            ]} />


            <Form
                form={form}
                name="new-schedule-form"
                onFinish={submitHandler}
                onFinishFailed={errorHandler}
                onFieldsChange={() => {
                    if (remainingTime >= 0){
                        setDisableSave(false);
                    }
                }}
            >
                <FormSection title='General'>

                    <FormDivision>

                        <Form.Item
                            label="Auction Number"
                            name="auction_num"
                        >
                            <Input readOnly />
                        </Form.Item>

                        <Form.Item
                            label="Auction Date/Time"
                            name="datetime"
                            rules={[{ required: true, message: '' }]}
                        >
                            <DatePicker 
                                onSelect={handleDateSelect}
                                disabledTime={disabledDateTime}
                                disabledDate={disabledDate}                       
                                value={datePickerValue}
                                onChange={onChangeDateTime} 
                                showNow={false} showTime 
                                format={'YYYY-MM-DD HH:mm'} 
                                placeholder='YYYY-MM-DD HH:mm' 
                                renderExtraFooter={() => (
                                    <Button onClick={() => form.setFieldsValue({ datetime: moment().tz('Asia/Kolkata') })}>
                                    Now
                                    </Button>
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Pre-Bid Duration"
                            name="pre_bid_duration"
                            rules={[{ required: true, message: '' },
                            {
                                validator: customTimeValidator()
                            }
                            ]}
                        >
                            <TimePicker 
                                placeholder="HH:mm:ss" 
                                format={hourFormat} 
                                showNow={false} 
                                onChange={time => { handleTimeChange(0, time)}}
                                disabled={!datePickerValue}                         
                            />
                        </Form.Item>

                        <Form.Item
                            label="Auction Duration"
                            name="auction_duration"
                            rules={[{ required: true, message: '' },
                            {
                                validator: customTimeValidator()
                            }
                            ]}
                        >
                            <TimePicker 
                                placeholder="mm:ss" 
                                format={minutesFormat} 
                                showNow={false} 
                                onChange={time => handleTimeChange(1, time)}
                                disabled={!datePickerValue}     

                            />
                        </Form.Item>

                        <Form.Item
                            label="Results Duration"
                            name="results_duration"
                            rules={[{ required: true, message: '' },
                            {
                                validator: customTimeValidator()
                            }
                            ]}
                        >
                            <TimePicker 
                                placeholder="mm:ss" 
                                format={minutesFormat} 
                                value={resultsDuration} 
                                onChange={time => {onChange(2, time)}} 
                                showNow={false}
                                disabled={!datePickerValue}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Counter Offer Duration"
                            name="counter_offer_duration"
                            rules={[{ required: true, message: '' },
                            {
                                validator: customTimeValidator()
                            }
                            ]}
                        >
                            <TimePicker 
                                placeholder="mm:ss" 
                                format={minutesFormat} 
                                disabledMinutes={disabledMinutes} 
                                disabledSeconds={() => disabledSeconds} 
                                onSelect={time => onSelect(time)} 
                                onChange={time => {handleTimeChange(3, time)}}
                                showNow={false}
                                disabled={!datePickerValue}
                            />
                        </Form.Item>

                    </FormDivision>

                    <FormDivision >

                        <Form.Item
                            name="recurring"
                            label="Recurring"
                            valuePropName="checked"
                            hidden = {editMode}
                        >
                            <Switch onClick={(o) => setRecurring(!o)}/>
                        </Form.Item>

                        <Form.Item name="repeat" label="Recurring Type" hidden = {recurring || editMode} >
                            <Select>
                                <Select.Option key={'nasdaily'} value="daily" >Daily</Select.Option>
                                <Select.Option key={'nasweekly'} value="weekly" >Weekly</Select.Option>
                                <Select.Option key={'nasmonthly'} value="monthly" >Monthly</Select.Option>
                            </Select>
                        </Form.Item>

                        {/* <Form.Item
                            label="End Date"
                            name="end date"
                            hidden = {recurring}
                        >
                            <DatePicker       
                                format={'YYYY-MM-DD'} 
                            />
                        </Form.Item> */}

                    </FormDivision>

                </FormSection>
                <CommonDiv direction="row" justify="flex-end">
                    <ButtonLoader loading={false} htmlType="submit" disabled={disableSave}>
                        {editMode ? 'Update' : 'Add'}
                    </ButtonLoader>
                    <CommonButton className='reverse' onClick={cancelHandler} >
                        Cancel
                    </CommonButton>
                </CommonDiv>
            </Form>
        </Container>
    )
}

export default NewScheduleCard