import React, { useState} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import CommonExportIcon from 'Components/CommonExportIcon'

const Container = styled.div`
    
    width: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        cursor: pointer;
    }
    
    svg{
        cursor: pointer;
    }

    `;

function ExtraFilter(props) {
    return (
        <Container>
            <CommonExportIcon data={props.data} columns={props.column}/>
        </Container>
    )
}

ExtraFilter.propTypes = {}

export default ExtraFilter
