import React, { useState, useEffect } from 'react';
import { Select, Button, Tag, Form } from 'antd';
import { GET_USER_TAGS } from 'Util/queries';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { THEME } from 'Util/constants';

const { Option } = Select;

const Container = styled.div`
    label {
        margin-right: 50px;
    }
`

const Section1 = styled.div`
    .ant-btn{
        background:  ${props => props.selectedTag ? THEME.colors.primary : '#eee'};
        border-color: ${props => props.selectedTag ? THEME.colors.primary : '#eee'};
        color: #fff;
        height: 32px;
        margin-left: 30px;
    }
`

const Section2 = styled.div`
    .ant-tag{
        background: ${THEME.colors.primary};
        border-color: ${THEME.colors.primary};
        height: 32px;
        color: #fff;
        margin-top: 10px;
        padding-top: 5px;
        .anticon svg {
            color: #fff;
            margin-bottom: 5px;
        }
    }
`

const TagAdder = ({ form, name, userTags, disabled, setTagList }) => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [tags, setTags] = useState([]);
  const [options, setOptions] = useState([]);
  const [list, setList] = useState([]);

  const { data, error } = useQuery(GET_USER_TAGS, {
      onCompleted() {
        let userTagsList = data.user_tags
          setList(userTagsList)
          setOptions(userTagsList)
      }
  });

  const handleTagSelection = (value) => {
    setSelectedTag(value);
  };

  useEffect(() => {
    if ( userTags?.length > 0) {
        const tagValues = list.filter(tag => userTags.includes(tag.id)).map(tag => tag.label);
        const filteredTags = list.filter(l => !tagValues.includes(l.label));
        setOptions(filteredTags)
        setTags(tagValues)
        form.setFieldsValue({ [name]: userTags});
    } 
  },[userTags])

  const handleAddTag = () => {
    if (selectedTag && !tags.includes(selectedTag)) {
      const newTags = [...tags, selectedTag]
      setTags(newTags);
      setTagList(newTags)
      setOptions(options.filter(tag => tag.label !== selectedTag))
      const tagIds = options.filter(tag => newTags.includes(tag.label)).map(tag => tag.id);
      form.setFieldsValue({ [name]: tagIds});
    }
    setSelectedTag(null);
  };

  const handleRemoveTag = (tag) => {
    const newTags = tags.filter((t) => t !== tag);
    const removedTag = list.filter(l => l.label === tag);
    setTags(newTags);
    setTagList(newTags)
    setOptions(options.concat(removedTag));
    const tagIds = options.filter(tag => newTags.includes(tag.label)).map(tag => tag.id);
    form.setFieldsValue({ [name]: tagIds });
  };

  return (
    <Container>
        <Form.Item label="Tags" style={{marginRight: '50px'}} >
            <Section1 selectedTag={selectedTag}>
                <Select
                    style={{ width: 200 }}
                    placeholder="Select a tag"
                    onChange={handleTagSelection}
                    value={selectedTag}
                    disabled={disabled || options.length === 0}
                >
                    {options && options.map(v => <Option value={v.label} key={`opt${v.id}`}>{v.label}</Option>)}
                </Select>
                <Button onClick={handleAddTag}>Add Tag</Button>
            </Section1>
            <Section2>
                {tags.map((tag) => (
                    <Tag 
                        key={tag} 
                        closable={!disabled}
                        onClose={() => handleRemoveTag(tag)}
                    >
                        {tag}
                    </Tag>
                ))}
            </Section2>
        <Form.ErrorList errors={form.getFieldError(name)} />
        </Form.Item>
    </Container>
  );
};

export default TagAdder;
