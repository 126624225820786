import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import { IconShare } from 'Components/Icons';
import { THEME } from 'Util/constants';
import styled from 'styled-components'
import { useLocation } from 'react-router-dom';
import { config } from '../Util/common';
import moment from 'moment-timezone';

const Container = styled.div`
    padding-top: 4px;
`;

const ExportTable = (props) => {
    const {data, columns} = props
    
    const location = useLocation();
    const tableLocation = location.pathname.split('/').slice(1).join('-')

    const handleExport = () => {
        if (!data.length > 0) return

        const csvData = data.map(item => {
            const row = {};
            columns.forEach(column => {
            row[column.title] = item[column.dataIndex];
            });
            return row;
        });

        const currentTimeIST = moment().tz(config.timezone).format('MMDDYYYY').toString()
        const filename = `${tableLocation}-${currentTimeIST}`

        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: false,
            filename,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(csvData);
    };
    
  return (
      <Container onClick={handleExport}><IconShare color={THEME.colors.primary} size={15} /></Container>
  );
};

export default ExportTable;