import { SearchOutlined } from '@ant-design/icons';
import CommonDiv from 'Components/CommonDiv';
import { auctionDataTableFiltersAtom } from 'Store/auctionAtom';
import { THEME } from 'Util/constants';
import { Input, Select } from 'antd';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from "styled-components";
import { ExtraFilters } from '.';

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{ font-weight: bold; }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{ font-weight: bold; }
`;

const MenuButtonContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{ font-weight: bold; }
`;

function TableFilters(props) {

    const history = useHistory()
    const [filter, setFilter] = useRecoilState(auctionDataTableFiltersAtom)

    const updateQuery = (e) => {
        setFilter({ ...filter, searchText: e.target.value })
    }
    const debouncedOnChange = debounce(updateQuery, 200);

    return (
        <Container>

            <CommonDiv direction="row" w={'400px'} justify="space-evenly" >

                <DropdownContainer>
                    <label>Data: </label>
                    <Select
                        defaultValue={filter.range}
                        style={{ width: 100 }}
                        bordered={false}
                        options={[
                            { value: "day", label: "1 Day" },
                            { value: "week", label: "1 Week" },
                            { value: "month", label: "1 Month" }
                        ]}
                        onChange={range => { setFilter({ ...filter, range }) }}
                    />

                </DropdownContainer>

                <DropdownContainer>
                    <Select
                        defaultValue={filter.status}
                        style={{ width: 200 }}
                        bordered={false}
                        options={[ "all", "collected", "pending collection", "rejected"].map(v => ({ value: v, label: v.toLocaleUpperCase() }))}
                        onChange={status => { setFilter({ ...filter, status }) }}
                    />
                </DropdownContainer>
            </CommonDiv>

            <SearchContainer>
                <Input defaultValue="" prefix={<SearchOutlined />} style={{ width: 200 }} placeholder="Search" onChange={(e) => debouncedOnChange(e)} />
            </SearchContainer>

            <ExtraFilters data={props.data} columns={props.columns}/>

        </Container>
    )
}

TableFilters.propTypes = {}

export default TableFilters
