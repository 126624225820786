import { Table } from 'antd';
import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { DataContext } from '..';
import { useSubscription } from '@apollo/client';
import { PURCHASE_ORDERS_LIST } from 'Util/subscriptions';
import { getDate, getTimeFromDate } from 'Util/common';
import { appConstant, THEME } from 'Util/constants';
import leadingZeroes from 'leading-zeroes';
import _, { isNull } from 'lodash'
import { useRecoilState } from 'recoil';
import { auctionDataTableFiltersAtom } from 'Store/auctionAtom';
import moment from 'moment';
import TableFilters from "./TableFilters";
import { Link } from 'react-router-dom';

const Container = styled.div``

const columns = [
    {
        title: "PO Lot #",
        dataIndex: "po_id",
        key: "po_id",
        sorter: (a, b) => a.po_id - b.po_id,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => ( 
            <Link style={{ color: THEME.colors.primary, fontSize: 16 }} to={{pathname: `/procurement/purchase-orders/edit/${record.po_id}`, state: { prevPath: '/procurement/purchase-orders/data' }}} >
                {record.po_id}
            </Link>
        )
    },
    {
        title: 'Date',
        dataIndex: 'auction_date',
        key: 'auction_date',
        sorter: (a, b) => a.auction_id - b.auction_id,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Auction',
        dataIndex: 'auction_id',
        key: 'auction_id',
        sorter: (a, b) => a.auction_id - b.auction_id,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Time',
        dataIndex: 'auction_time',
        key: 'auction_time',
        sorter: (a, b) => a.auction_time.localeCompare(b.auction_time),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Lot #',
        dataIndex: 'lot_number',
        key: 'lot_number',
        sorter: (a, b) => a.lot_number - b.lot_number,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Vendor ID',
        dataIndex: 'vendor_id',
        key: 'vendor_id',
        sorter: (a, b) => a.vendor_id - b.vendor_id,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        sorter: (a, b) => a.location.localeCompare(b.location),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Species',
        dataIndex: 'species',
        key: 'species',
        sorter: (a, b) => a.species.localeCompare(b.species),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Weight (KG)',
        dataIndex: 'weight',
        key: 'weight',
        sorter: (a, b) => a.weight - b.weight,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Unit Price',
        dataIndex: 'bid_price',
        key: 'bid_price',
        sorter: (a, b) => a.bid_price - b.bid_price,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Total Value',
        dataIndex: 'total_value',
        key: 'total_value',
        sorter: (a, b) => a.total_value - b.total_value,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Adjustment Value',
        dataIndex: 'adjustment_value',
        key: 'adjustment_value',
        sorter: (a, b) => a.adjustment_value - b.adjustment_value,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Payment Value',
        dataIndex: 'payment_value',
        key: 'payment_value',
        sorter: (a, b) => a.payment_value - b.payment_value,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (val) => {
            return <span style={{ color: 'green', textTransform: 'uppercase' }} >
                {val}
                </span>
        }
    }
]

function DataTable() {

    const { setSelectedRecord, setCsvData } = useContext(DataContext)

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([])
    const [filter, setFilter] = useRecoilState(auctionDataTableFiltersAtom)

    const fromDate = moment().subtract(1, filter.range).format('YYYY-MM-DD');
 
    /** GraphQL Hooks */
    const { loading } = useSubscription(PURCHASE_ORDERS_LIST, {
        variables: {
            where: {
                created_at: { _gte: fromDate },
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {    
            data.purchase_orders && setData(data.purchase_orders.map(po => {
                const {bid, user, direct_offer_order, pickup_orders} = po
                const { collection_location } = pickup_orders[0]
                const isDirectSale = isNull(po.bid_id)
                const isUserLocation = isNull(pickup_orders[0].collection_location_id)
                const totalValue = isDirectSale ? direct_offer_order.order_quantity * direct_offer_order.direct_offer.price : bid.volume * bid.price
                return {
                    key: `key-${po.id}-po-list`,
                    po_id: po.id,
                    auction_date: getDate(po.created_at),
                    auction_id: isDirectSale ? "Direct" : bid.auction_id,
                    auction_time: getTimeFromDate(po.created_at, true),
                    lot_number: isDirectSale ? direct_offer_order.id : bid.id,
                    vendor_id: leadingZeroes(user.id, appConstant.leadingZero),
                    location: isUserLocation ? user.user_location.label : collection_location.location_name,
                    species: isDirectSale ? direct_offer_order.direct_offer.species_name : bid.product.label,
                    weight: isDirectSale ? direct_offer_order.order_quantity : bid.volume,
                    bid_price: isDirectSale ? direct_offer_order.direct_offer.price : bid.price,
                    total_value: totalValue,
                    adjustment_value: po.adjustment_value || 0,
                    payment_value: (totalValue + (po.adjustment_value || 0)),
                    status: po.status, 
                }
            }))

        }
    });

    const onSelectChange = (newSelectedRowKeys, data) => {
        setSelectedRecord(data)
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const filterData = (data) => {
        const searchable = data.map(obj => ({
            ...obj,
            searchText: `${obj.auction_date} ${obj.auction_id} ${obj.auction_time} ${obj.lot_number} ${obj.vendor_id} ${obj.location} ${obj.species} ${obj.weight} ${obj.bid_price} ${obj.total_value} ${obj.status}`
        }))

        if (!filter.searchText && filter.status === 'all') {
            return data
        }

        return _.filter(searchable, function (d) {
            let query = filter.searchText.toLowerCase();
            if(filter.status === 'all'){
                return _.includes(d.searchText.toLowerCase(), query)
            } else{
                return _.includes(d.searchText.toLowerCase(), query) && d.status === filter.status.split(" ")[0];
            }
        });     
    }
    

    /** clean up */
    useEffect(() => {
        return () => { setFilter({ ...filter, range: "day", searchText: '' }) }
    }, [])

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={columns}/>
            <Table
                loading={loading}
                size='small'
                columns={columns}
                dataSource={filterData(data)}
                rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 50
                }}
            />
        </Container>
    )
}

export default DataTable