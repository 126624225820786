import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import styled from "styled-components";
import TableFilter from "./TableFilter";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { vendorSearchAtom } from "Store/crmAtom";
import { useLocation } from 'react-router-dom'
import { LogisticsJobsContext } from "..";
import { THEME } from "Util/constants";



const Container = styled.div`
	.ant-table-thead {
		.ant-table-cell {
			color: #999;
			font-weight: bold;
		}
	}

    .ant-table-tbody > tr:hover > td {
		background-color: "none";
	}
	.ant-table-tbody > tr.selected > td {
		background-color: ${THEME.colors.primary};
	}

`;


const columns = [
{
    title: 'Vehicle Name',
    dataIndex: 'number',
    key: 'number',
    sorter: (a, b) => a.number?.localeCompare(b.number),
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 150
},
{
    title: 'Depot',
    dataIndex: 'depot',
    key: 'depot',
    sorter: (a, b) => a.depot?.localeCompare(b.depot),
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 150
},
{
    title: 'Driver',
    dataIndex: 'driver',
    key: 'driver',
    sorter: (a, b) => a.driver?.localeCompare(b.driver),
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 150
}
];


function DataTable({
    pageSize = 50,
    height = 250,
    data = [],
    loading = false
}) {
    const { selectedRecord, setSelectedRecord } = useContext(LogisticsJobsContext)
    const [search, setSearch ] = useRecoilState(vendorSearchAtom)
    const location = useLocation();

    const searchable = data.map((obj, i) => {
        let result = [];
        let concatenatedValues = "";
        columns.forEach(column => {
          if (obj[column.key] && obj[column.key] !== undefined) concatenatedValues += obj[column.dataIndex] + " ";
        });
        result.push(concatenatedValues.trim());
    
        return {
          ...obj,
          searchText:  result.toString()
        }
      })

    const filterData = (data) => {
        if(!search){
            return data
        }
        return _.filter(searchable, function (d) { 
            let query = search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    };
    
    const handleRowClick = (row) => {
        setSelectedRecord(row);
        console.log('row', row);
    }
    
    useEffect(() => {
        setSelectedRecord({})
    }, [location.pathname, setSelectedRecord])
    
    return (
        <Container>
            <TableFilter data={filterData(data)} column={columns}/>
            <Table
                loading={loading}
                columns={columns}
                dataSource={filterData(data)}
                onRow={(rec, idx) => ({
                    onClick: (e) => {
                      handleRowClick(rec)
                    }       
                })}
                pagination={{ defaultPageSize: pageSize }}
                scroll={{ y: height, x: 1500 }}
                rowKey={rec => parseInt(rec.id, 10)}
                components={{
                    body: {
                        row: (props) => {
                            const row = _.find(filterData(data), { id: props['data-row-key'] })
                            let selected = false;
                            if (!_.isEmpty(selectedRecord) && !_.isEmpty(row)) {
                                selected = row.id === selectedRecord.id;
                            }
                            const tableRow = (
                                <tr 
                                    {...props}
                                    style={{ cursor: 'pointer' }} 
                                    onClick={props.onClick}
                                    className={`ant-table-row ant-table-row-level-0 yeah ${selected ? "selected" : ""}`}
                                >
                                    {props.children}
                                </tr>
                            )

                            return tableRow
                        }
                    }
                }}
            />
        </Container>
    )

}

DataTable.propTypes = {
    pageSize: PropTypes.oneOf([10, 20, 50, 100]),
    height: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
};

export default DataTable;
