import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { THEME } from "Util/constants";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import IconPurchaseInvoice from "Components/Icons/IconPurchaseInvoice";
import IconPurchaseCreditMemo from "Components/Icons/IconPurchaseCreditMemo";
import IconPurchaseOrder from "Components/Icons/IconPurchaseOrder";
import IconPurchaseQuote from "Components/Icons/IconPurchaseQuote";
import IconPurchaseReturnOrder from "Components/Icons/IconPurchaseReturnOrder";
import itemReferenceIcon from "../../../components/images/item-reference.png";
import adjustInventoryIcon from "../../../components/images/adjust-inventory.png";
import approvalsIcon from "../../../components/images/approvals.png";

const menu = (
	<Menu>
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={itemReferenceIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>Item Reference</span>
			</a>
		</Menu.Item>
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={adjustInventoryIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>Adjust Inventory</span>
			</a>
		</Menu.Item>
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={approvalsIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>Approvals</span>
			</a>
		</Menu.Item>
	</Menu>
);

const Container = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;

	.anticon {
		color: ${THEME.colors.primary};
		margin-left: 5px;
	}
	label {
		font-weight: bold;
	}
	.ant-space-item {
		color: #000;
		&:first-child {
			font-weight: bold;
		}
	}
`;

function Item(props) {
	return (
		<Container>
			<Dropdown overlay={menu} trigger={["click"]}>
				<a onClick={(e) => e.preventDefault()}>
					<Space>
						Item
						<DownOutlined />
					</Space>
				</a>
			</Dropdown>
		</Container>
	);
}

Item.propTypes = {};

export default Item;
