import { ContainerOutlined, DashboardOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";

function UsersMenu(props) {
    return (
        <>
            <Menu theme="light" mode="horizontal" defaultSelectedKeys={['auction']}>
                <Menu.Item key="cms-users" icon={<DashboardOutlined />}>
                    <span>Masterlist</span>
                    <Link to="/cms/users" />
                </Menu.Item>

                <Menu.Item key="cms-users-pending" icon={<ContainerOutlined />}>
                    <span>Pending</span>
                    <Link to="/cms/users/pending" />
                </Menu.Item>

                <Menu.Item key="cms-users-rejected" icon={<ContainerOutlined />}>
                    <span>Rejected</span>
                    <Link to="/cms/users/rejected" />
                </Menu.Item>

            </Menu>
        </>
    )
}

UsersMenu.propTypes = {

}

export default UsersMenu

