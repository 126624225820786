import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AdminRolesHeader, ProfileRolesDataTable, ProfileRolesTableFilter } from './components'

const Container = styled.div``

export const ProfileRolesContext = createContext()

function ProfileRoles(props) {

    const [selectedRecord, setSelectedRecord] = useState()

    return (
        <ProfileRolesContext.Provider value={{
            selectedRecord, setSelectedRecord
        }}>
            <Container>
                <AdminRolesHeader title='Profile Roles' />
                <ProfileRolesTableFilter />
                <ProfileRolesDataTable />
            </Container>
        </ProfileRolesContext.Provider>
    )
}

ProfileRoles.propTypes = {}

export default ProfileRoles
