import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import VendorDropDown from './VendorDropDown';
import Search from './Search';
import NewVendor from './NewVendor';
import DeleteVendor from './DeleteVendor';
import ProcessVendor from './ProcessVendor';
import NewDocument from './NewDocument';
import VendorAction from './VendorAction';
import ExtraFilters from './ExtraFilters';
import Edit from './Edit';

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

function TableFilter(props) {

    return (
        <Container>
            <VendorDropDown />
            <Search />
            <NewVendor />
            <DeleteVendor />
            <ProcessVendor />
            <NewDocument />
            <VendorAction />
            <ExtraFilters data={props.data} column={props.column}/>
        </Container>
    )
}

TableFilter.propTypes = {
}

export default TableFilter