import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Button } from 'antd';
import { baseUrl } from 'Util/common';
import AccountButton from './AccountButton';
const { Header } = Layout;

function CommonHeader(props) {

    return (
        <Header style={{ justifyContent: 'space-between', alignItems: 'center' }} >
            <div className="logo">
                <img src={`${baseUrl}/images/logo.png`} alt="Catch Value" className="logo" />
            </div>
            <AccountButton />
        </Header>
    )
}

CommonHeader.propTypes = {}

export default CommonHeader