import React from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
const Container = styled.div``

const auctionMenu = [
    { title: "All Users", link: "/crm/notifications/all-users" },
    { title: "Segments", link: "/crm/notifications/segments" },
    { title: "Tags", link: "/crm/notifications/tags" },
    { title: "Polls", link: "/crm/notifications/polls" }
]

function NotificationSystem() {
    return (
        <>
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
            </Container>
        </>
    )
}

export default NotificationSystem
