
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { variant } from 'styled-system'
const timeFormat = 'HH:mm:ss';

const Container = styled.div`
    position: relative;
`
const ClockText = styled.h3({
    fontWeight: 'bold'
},
    variant({
        prop: 'position',
        variants: {
            absolute: { position: "absolute", right: "0px", top: "-20px", },
            relative: { position: "relative" }
        }
    })
)

function ClockV2({ position = "relative" }) {

    /** for compare */
    const [dateTime, setDateTime] = useState({
        newDelhi: moment().tz('Asia/Kolkata').format(timeFormat),
        local: moment().format(timeFormat),
        date: moment().tz('Asia/Kolkata').format('YYYY/MM/DD'),
    })

    useEffect(() => {
        var timerID = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(timerID);
        };
    });

    function tick() {
        setDateTime({
            newDelhi: moment().tz('Asia/Kolkata').format(timeFormat),
            local: moment().format(timeFormat),
            date: moment().tz('Asia/Kolkata').format('YYYY/MM/DD'),
        });
    }

    return (
        <Container>
            <ClockText position={position} >IST: {dateTime.newDelhi}</ClockText>
        </Container>
    )
}

ClockV2.propTypes = {

}

export default ClockV2