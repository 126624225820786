import React, { useState } from 'react';
import styled from "styled-components";
import { THEME } from "Util/constants";
import { Card, Table, Typography, Button, Form, Input, Spin, Modal } from "antd";
import PropTypes from 'prop-types';
import { useSubscription, useMutation } from '@apollo/client';
import { POLL_RESPONSE, POLL_COMMENTS } from 'Util/subscriptions';
import { UPSERT_POLL_COMMENT_REPLIES, DELETE_POLL_COMMENT_REPLIES } from 'Util/mutations'
import useUser from 'Hooks/user'
import moment from 'moment';
import { QuestionCircleOutlined } from '@ant-design/icons';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    //height: 100vh;
    padding: 20px 200px;

    .box {
        flex: 1;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    } 
`;

const TableContainer = styled.div`
    .ant-card-grid {
        width: 25%;
        text-align: center;
        .details{
            color: ${THEME.colors.primary};
            font-weight: bold;
        }
    }

    .title{
        padding: 10px;
        font-weight: bold;
        color: ${THEME.colors.darkGray};         
    }
    .title-gray{
        padding: 10px;
        font-weight: bold;
        background: #D3D3D3;      
    }
`
  
const columns = [
    {
      title: 'Answer Choices',
      dataIndex: 'option',
      key: 'option',
    },
    {
      title: 'Responses',
      dataIndex: 'result',
      key: 'result',
    },
  ];

  
const { Text } = Typography;

const TextComponent = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
  
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  
    if (text.length <= 100) {
        return <div style={{ color: THEME.colors.gray}}>{text}</div>;
      }
    
      return (
        <div>
          {isExpanded ? (
            <div style={{ color: THEME.colors.gray}}>{text}</div>
          ) : (
            <div style={{ color: THEME.colors.gray}}>{text.slice(0, 100)}... {' '}<Button style={{ color: THEME.colors.primary, padding: 0, height: 0}} type="link" onClick={toggleExpand}>
            Read more
        </Button></div>
          )}
        </div>
      );
  };
  
  
const CommentSection = ({data}) => {
  const [showCommentForm, setShowCommentForm] = useState([]);
  const [reply, setReply] = useState('');
  const [editedReply, setEditedReply] = useState({ id: null, text: '' });
  const { user_id } = useUser();

  const [insert] = useMutation(UPSERT_POLL_COMMENT_REPLIES, {
    onCompleted() { },
    onError(e) { console.log('erre', e)}
  });

  const [deleteReply] = useMutation(DELETE_POLL_COMMENT_REPLIES);

  const handleReply = (commentId) => {
    setShowCommentForm([...showCommentForm, commentId]);
  };

  const handleReplyChange = (event) => {
    setReply(event.target.value);
  };

  const handleReplySubmit = (commentId, values) => {
    const submittedReply = values.comment;
    insert({
        variables: {
          objects: {
            comment_id: commentId,
            reply: submittedReply,
            admin_user_id: user_id
          }
        },
    });
    setReply('');
    setShowCommentForm(showCommentForm.filter((id) => id !== commentId));
  };

  const handleCancel = (commentId) => {
    setShowCommentForm(showCommentForm.filter((id) => id !== commentId));
    setReply('');
  };

  const isCommentFormVisible = (commentId) => showCommentForm.includes(commentId);

  const handleEditReply = (replyId, replyText) => {
    setEditedReply({ id: replyId, text: replyText });
  };

  const handleUpdateReply = (reply, updatedReply) => {
    const newDelhi = moment().tz('Asia/Kolkata')

    insert({
      variables: {
        update_columns: ["reply", "updated_at"],
        objects: {
          id: reply.id,
          comment_id: reply.comment_id,
          reply: updatedReply,
          admin_user_id: reply.admin_user_id,
          updated_at: newDelhi
        }
      },
    });

    setEditedReply({ id: null, text: '' })
  };

  const confirmDelete = (replyId) => {    
    deleteReply({
      variables: {
          id: replyId
      }
    })
  }
  
  const openDeleteModal = (replyId) => {

    Modal.confirm({
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
      content: `Are you sure to permanently delete this reply?`,
      autoFocusButton: 'ok',
      okButtonProps: {
        style: { backgroundColor: THEME.colors.primary }
      },
      onOk: () => confirmDelete(replyId)
    });
  };

  const buttonNoBorder = {
    color: THEME.colors.primary,
    padding: 0,
    height: 30,
    fontSize: '12px',
  };

  const buttonPrimary = { 
    background: THEME.colors.primary, 
    color: THEME.colors.lightGray, 
    border: THEME.colors.primary
  }

  const buttonCancel = { 
    marginLeft: "10px", 
    color: THEME.colors.primary
  }
 
  return (
    <div style={{ padding: '20px'}}>
      {data.map((comment) => (
        <div key={comment.id} style={{paddingBottom: '10px', borderBottom: '1px solid #f2f2f2'}}>
          <Text strong>Anonymous</Text>
          <TextComponent text={comment.comment}/>
          <Button style={buttonNoBorder} type="link" onClick={() => handleReply(comment.id)}>
            Reply
          </Button>
          {comment.poll_comment_replies.map((replies) =>
            <div key={replies.id} style={{ paddingLeft: '20px' }}>
                <Text strong>{replies.admin_user.full_name}</Text>
                {/* <div style={{ color: THEME.colors.gray}}>{replies.reply}</div> */}
                {editedReply.id === replies.id ? (
                  <Form onFinish={() => handleUpdateReply(replies, editedReply.text)}>
                    <Form.Item name="comment">
                      <Input.TextArea
                        rows={4}
                        placeholder="Enter your updated reply"
                        // value={editedReply.text}
                        defaultValue={replies.reply}
                        onChange={(e) => setEditedReply({ ...editedReply, text: e.target.value })}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={buttonPrimary}>
                        Submit
                      </Button>
                      <Button
                        style={buttonCancel}
                        onClick={() => setEditedReply({ id: null, text: '' })}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                ) : (
                  <div style={{ color: THEME.colors.gray }}>{replies.reply}</div>
                )}
                {replies.admin_user_id === user_id && (
                  <div>
                    <Button
                      style={buttonNoBorder}
                      type="link"
                      onClick={() => handleEditReply(replies.id, replies.reply)}
                    >
                      Edit
                    </Button>
                    {' '}
                    <Button 
                      style={buttonNoBorder} 
                      type="link"
                      onClick={() => openDeleteModal(replies.id)}>
                      Delete
                    </Button>
                  </div>
                )}
            </div>
          )}     
          {isCommentFormVisible(comment.id) && (
            <Form onFinish={(values) => handleReplySubmit(comment.id, values)}>
              <Form.Item name="comment">
                <Input.TextArea
                  rows={4}
                  placeholder="Enter your reply"
                  value={comment}
                  onChange={handleReplyChange}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={buttonPrimary}>Submit</Button>
                <Button style={buttonCancel} onClick={() => handleCancel(comment.id)}>Cancel</Button>
              </Form.Item>
            </Form>
          )}
        </div>
      ))}
    </div>
  );
};
  

const Results = ({poll}) => {
    const [pollResponse, setPollResponse] = useState()
    const [data, setData] = useState()
    const [pollComents, setPollComments] = useState()

    const { loading } = useSubscription(POLL_RESPONSE, {
        variables: { where: { poll_id: { _eq: poll.id } }},
        onSubscriptionData({ subscriptionData: { data } }) {

            const pollResponse = data.poll_response
            setPollResponse(pollResponse)

            const countMap = pollResponse.reduce((count, data) => {
                const responseIndex = data.response;
                count[responseIndex] = (count[responseIndex] || 0) + 1;
                return count;
            }, {});
                
            const finalResult = poll.options.map((option, index) => ({
                key: index.toString(),
                option: poll.option_type === "no-image" ? option : option.text,
                result: countMap[index] || 0
            }));
            
            setData(finalResult)
        }
    })

    const { loading: loadingReplies } = useSubscription(POLL_COMMENTS, {
        variables: { where: { poll_id: { _eq: poll.id }} },
        onSubscriptionData({ subscriptionData: { data } }) {
            const pollResponse = data.poll_comments
            setPollComments(pollResponse)
        }
    })
 
    return (
        <Container>
            <div className="box">
                <TableContainer>

                    <div className="title" >
                        {poll.title}
                    </div>

                    <Card loading={loading}>
                        <Card.Grid>
                            <div className='details'>4</div>
                            <div>Recipients</div>
                        </Card.Grid>
                        <Card.Grid>
                            <div className='details'>{pollResponse?.length}</div>
                            <div>Completed</div>
                        </Card.Grid>
                        <Card.Grid>
                            <div className='details'>0</div>
                            <div>In Progress</div>
                        </Card.Grid>
                        <Card.Grid>
                            <div className='details' style={{ color: poll.active ? "green" : "red" }}>{poll.active ? "Active" : "Closed"}</div>
                            <div>Poll Status</div>
                        </Card.Grid>
                    </Card>

                    <div className="title-gray">Poll Results</div>
                    <div className="title">{poll.question}</div>

                    <Table dataSource={data} columns={columns} pagination={false} loading={loading}/>

                    <div className="title-gray">Comments</div>
                    {pollComents ? <CommentSection data={pollComents}/> : <Spin/>}
                    
                </TableContainer>            
            </div>
        </Container>
  )
};

Results.prototype = {
    poll: PropTypes.arrayOf(PropTypes.object)
}

export default Results;