import React, { useState, createContext } from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { auctionMenu } from '../constants'
import { DataTable, TableFilters } from './components'
import ClockV2 from 'Components/ClockV2'
import AuctionTimer from 'Components/AuctionTimer'

export const AuctionLiveContext = createContext()

const Container = styled.div``

function AuctionLive() {

    const [filter, setFilter] = useState('live')

    return (
        <AuctionLiveContext.Provider
            value={{
                filter, setFilter
            }}
        >
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
                <AuctionTimer position='absolute'/>
                <ClockV2 position='absolute' />
                {/* <TableFilters /> */}
                <DataTable />
            </Container>
        </AuctionLiveContext.Provider>
    )
}

export default AuctionLive