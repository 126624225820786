import { Select } from 'antd';
import React from 'react';
import { harvestMethods } from 'Util/constants';
const { Option } = Select;

function HarvestMethodDropdown({ onChange, ...rest }) {

    return (
        <div>
            <Select
                style={{ width: 300 }}
                placeholder="Select a method"
                onChange={onChange}
                {...rest}
            >
                {harvestMethods.map((val, idx) => <Option value={val} key={`hm-dd-opt${idx}`}>{val}</Option>)}
            </Select>
        </div>
    )
}

export default HarvestMethodDropdown
