import React, { useState } from 'react'
import { THEME } from 'Util/constants';
import { Modal } from 'antd';
import { AcceptIcon } from 'Components/Icons/actions';
import { auctionAutoBidAtom } from 'Store/auctionAtom';
import { useRecoilState } from 'recoil';
import { useMutation } from '@apollo/client';
import { ADD_PICKUP_ORDER, UPDATE_BID_STATUS } from 'Util/mutations';
import { auctionAtom } from 'Store/auctionAtom';
import _ from 'lodash';
import gmap from 'gmap';
import { mapConstant } from 'Util/constants';
import { auctionSelectedRecordAtom } from 'Store/auctionAtom';

function AcceptBidModal({check}) {
    const [{ auctionStatus, currentAuction, currentBids }] = useRecoilState(auctionAtom);
    const [selectedRecord, setSelectedRecord] = useRecoilState(auctionSelectedRecordAtom)
    const {selectedData} = selectedRecord
    const [visible, setVisible] = useState(false)

    /** accept bid  */
    const [acceptBid] = useMutation(UPDATE_BID_STATUS)
    
    /** pick up order  */
    const [addPickUpOrder] = useMutation(ADD_PICKUP_ORDER);
    
    const acceptBidHandler = async () => {
            
        setVisible(false);

        for (const bid of selectedData){
            const { action } = bid

            const bids = _.filter(currentBids, { auction_id: action.auction_id, product_id: action.product.id, status: 'accepted' });
            const rem_quota = action.product.volume - (_.sumBy(bids, 'volume') + action.volume)
            
            const variables = {
                id: action.id,
                obj: { status: "accepted", rem_quota },
                po_object: { bid_id: action.id, user_id: action.user.id }
            }
    
            const res = await acceptBid({ variables })
    
            const objects = {
                purchase_order_id: res.data.insert_purchase_orders.returning[0].id,
                bid_id: action.id,
                user_id: action.user.id,
                link: gmap.directions(mapConstant.pickupCoordinates, `${action.user.user_location.lat},${action.user.user_location.lng}`),
                eta: 10,
            }
    
            addPickUpOrder({ variables: { objects } })
        }

        setSelectedRecord({});
    }

    return (
        <>
            <AcceptIcon color={check ? "#cccccc" : null} onClick={() => {!_.isEmpty(selectedData) && setVisible(true)}} />
            <Modal
                visible={visible}
                centered
                onCancel={() => setVisible(false)}
                onOk={acceptBidHandler}
                title="Accept All Selected Lots?"
                okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
                width={300}
                closable={false}
                okText = 'YES'
            >
            </Modal>
        </>
    )
}

AcceptBidModal.propTypes = {}

export default AcceptBidModal