import { useMutation, useSubscription } from '@apollo/client';
import { Button, Form, Input, Table, Typography, notification } from 'antd';
import CommonDrawer from 'Components/CommonDrawer';
import LanguageDropdown from 'Components/LanguageDropdown';
import LocationDropdown from 'Components/LocationDropdown';
import UserTypeDropdown from 'Components/UserTypeDropdown';
import React, { useState } from 'react';
import { ADD_USER, UPDATE_USER } from 'Util/mutations';
import { SUB_USERS } from 'Util/subscriptions';
import _ from 'lodash'
import './style.scss';
import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants';

function CMSUsers() {
    const [form] = Form.useForm();
    const { data, loading, error } = useSubscription(SUB_USERS, { variables: { status: "verified" } });
    const [editId, setEditId] = useState(null);
    const [drawer, setDrawer] = useState(false);
    const [addUser] = useMutation(ADD_USER, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'User has been added with default password',
            });
            setDrawer(false);
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const [updateUser] = useMutation(UPDATE_USER, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'User has been updated!',
            });
            setDrawer(false);
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const drawerClose = () => {
        setDrawer(false);
        setEditId(null);
    }

    const edit = (record) => {
        setEditId(record.id)
        form.setFieldsValue({
            ...record,
        });
        setDrawer(true);
    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const onFormSubmit = () => {
        const formData = form.getFieldsValue();
        _.isNull(editId) ?
            addUser({ variables: formData }) :
            updateUser({ variables: { id: editId, ...formData } })
    }

    const columns = [
        {
            title: 'FishermanID',
            dataIndex: 'id',
            render: (_, rec) => (<span>{leadingZeroes(rec.id, appConstant.leadingZero)}</span>)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true,
        },
        {
            title: 'Aadhaar ID',
            dataIndex: 'aadhaar_id',
            editable: true,
        },
        {
            title: 'Roles',
            dataIndex: 'type',
            editable: true,
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            editable: true,
        },
        {
            title: 'Vessel',
            dataIndex: 'vessel',
            editable: true,
        },
        {
            title: 'Language',
            dataIndex: 'language',
            editable: true,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            editable: true,
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <Typography.Link onClick={() => edit(record)}>
                    Edit
                </Typography.Link>
            ),
        },
    ];

    const dataSource = () => {

        if (data) {

            console.log(data)

            return data.users.map((v, i) => (
                {
                    key: `key-${i}-user-list`,
                    id: v.id,
                    aadhaar_id: v.aadhaar_id,
                    email: v.email,
                    mobile: v.mobile,
                    name: v.name,
                    vessel: v.vessel,
                    language: v.user_language.label,
                    language_id: v.user_language.id,
                    location: v.user_location.label,
                    location_id: v.user_location.id,
                    type: v.user_type.label,
                    users_type_id: v.user_type.id
                }
            ))
        }

        return []

    }

    /** Validation Rules */
    const rule = [
        {
            required: true,
            message: "Field is required"
        },
    ]

    return (
        <div className="users-page" >
            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { setDrawer(true); }} >New User</Button>
            </div>

            <CommonDrawer
                open={drawer}
                form={form}
                title={editId ? "Edit user" : "Add new user"}
                onClose={drawerClose}
            >

                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                    component={false}
                >

                    <Form.Item
                        rules={rule}
                        label="Name" name="name">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Aadhaar ID" name="aadhaar_id">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Email" name="email">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Mobile" name="mobile">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Vessel Reg" name="vessel">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Langugage" name="language_id">
                        <LanguageDropdown />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Location" name="location_id">
                        <LocationDropdown />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Type" name="users_type_id">
                        <UserTypeDropdown />
                    </Form.Item>

                </Form>

            </CommonDrawer>

            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    );
}

export default CMSUsers
