import React, { useState } from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { AuctionSystemContext } from '.'
import { Level2Menu } from 'Components/Menus'
import { auctionMenu } from './constants'

const Container = styled.div``

/** Schedule */
function AuctionSystem() {

  const [selectedRecord, setSelectedRecord] = useState({})
  const [filter, setFitler] = useState({ is_active: true })


  return (
    <AuctionSystemContext.Provider value={{
      selectedRecord, setSelectedRecord,
      filter, setFitler
    }}>
      <Container>
        <SubPageBreadCrumbs />
        <Level2Menu menu={auctionMenu} />
      </Container>
    </AuctionSystemContext.Provider>
  )
}

export default AuctionSystem