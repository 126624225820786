import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Select, Input, Checkbox } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
// import ManageDropDown from './ManageDropDown';
import ExtraFilters from './ExtraFilters';
import { Link, useHistory } from 'react-router-dom';
import { ApprovalDashboardContext } from '../Dashboard';
import CommonDiv from 'Components/CommonDiv';

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const NewContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const CheckBoxContainer = styled.div`
    label {
        font-weight: bold;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${THEME.colors.primary};
        border-color: ${THEME.colors.primary};
    }
`;

function ApprovalDashboardTableFilter(props) {

    const history = useHistory()
    const { selectedRecord, setFitler } = useContext(ApprovalDashboardContext)

    const deleteRecord = () => {
        alert('delete')// delete logic here, update deleted-at
    }
    const editRecord = (id) => { history.push(`profile-roles/edit/${id}`) }

    return (
        <Container>
            <CommonDiv direction="row" >
                <DropdownContainer>
                    <label>Notifications: </label>
                    <Select defaultValue="requestor"
                        style={{ width: 150 }}
                        bordered={false}
                        onChange={v => { setFitler(v) }}
                    >
                        <Option value="requestor">Requestor</Option>
                        <Option value="approver">Approver</Option>
                        <Option value="7day">Last 7D</Option>
                    </Select>
                </DropdownContainer>

                <SearchContainer>
                    <Input prefix={<SearchOutlined />} style={{ width: 500 }} placeholder="Search" />
                </SearchContainer>
            </CommonDiv>
            {/* 
            <NewContainer>
                <Link to={'profile-roles/new'}>
                    <PlusOutlined />
                </Link>
                <label>New</label>
            </NewContainer> */}

            {/* <ManageDropDown
                selectedRecord={selectedRecord || {}}
                onDelete={deleteRecord}
                onEdit={editRecord}
            /> */}

            {/* <CheckBoxContainer>
                <Checkbox onChange={() => { console.log('check') }}>Use as default profile</Checkbox>
            </CheckBoxContainer> */}

            <ExtraFilters />

        </Container>
    )
}

ApprovalDashboardTableFilter.propTypes = {}

export default ApprovalDashboardTableFilter
