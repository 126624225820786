import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ArrowLeftOutlined, EditFilled, DeleteFilled, PlusOutlined, CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { IconFlow, IconShare } from 'Components/Icons';
import { THEME, approvalType } from 'Util/constants';
import { useHistory } from 'react-router-dom';
import str from 'string-sanitizer'
import CommonConfirmModal from 'Components/Modal/ModalConfirm';
import { useRecoilState } from 'recoil';
import { vendorDetailAtom } from '../../../../../Store/crmAtom';
import { useRequestAproval } from '../../../../../Hooks/approval';
import { getApprovalWorkFlow } from '../../../../../Util/common';
import User from 'Hooks/user';
import { Modal, notification } from 'antd';
import DeleteVendor from '../TableFilter/Delete';
import { vendorApprovalStatusTypes } from '../../../../../Util/constants';
import { isUndefined } from 'lodash'
import PreApproveModal from '../../../../../Components/Modal/PreApproveModal';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_OBJECT } from 'Util/mutations';

const menu = [
    { label: "Process" },
    { label: "Request Approval" }
]

const Container = styled.div`
    .actions-wrapper{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icons-wrapper{
            justify-content: flex-end;
            width: 180px;
            gap: 20px;        
            svg{
                color: ${THEME.colors.primary};
                font-size: 25px;
                cursor: pointer;
            }
        }
        
        .backIconWrapper{
            background-color: ${THEME.colors.primary};
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
        h1{
            margin: 0px 10px;
        }
        
    }

    .submenu-wrapper{

        padding: 10px 0px;
        border-bottom: solid #555 thin;
        border-top: solid #555 thin;
        margin: 20px 0px;

        &>a{
            color: #555;
            margin: 0px 10px;
            font-size: 16px;
            font-weight: 500;
            &[disabled]{
                color: #ccc;
                .anticon{
                    color: #ccc !important;
                }
            }
        }
    }
`


function Header({ editMode = false, setDisabled, onEvent }) {

    const history = useHistory()
    const [vendor] = useRecoilState(vendorDetailAtom)
    const [showPreApprove, setShowPreApprove] = useState(false)

    const { requestApproval } = useRequestAproval()
    const { user_id, hasRole } = User()

    const [updateUser] = useMutation(UPDATE_USER_OBJECT)

    const backHandler = () => { 
        history.push(history.location.state.prevPath)
    }

    const preApproveHandler = (action) => {

        updateUser({
            variables: {
                id: vendor.id,
                obj: { onboard_status: action === 'reject' ? vendorApprovalStatusTypes.REJECTED : vendorApprovalStatusTypes.APPROVED }
            }
        }).then(res => {
            notification['success']({
                message: action === 'reject' ? 'Rejected!' : 'Approved!',
                description: `Vendor has been approved ${action === 'reject' ? 'Rejected!' : 'Approved!'} `,
            });
        })
        onEvent(`preapprove-${action}`)
        setShowPreApprove(false)

    }

    const subMenuClickHandler = (val) => {

        switch (val) {
            case "Pre-Approval":
                setShowPreApprove(true)
                break;

            case "Send Approval Request":

                CommonConfirmModal({
                    content: `Request Final Approval for ${vendor.username}s On-Boarding.`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                        requestApproval({
                            role: 1,
                            requester_id: user_id,
                            type: approvalType.NEW_VENDOR_APPROVAL,
                            workflow: getApprovalWorkFlow(approvalType.NEW_VENDOR_APPROVAL),
                            ref_id: vendor.id
                        }).then((res) => {
                            notification.success({ message: "Request sent!" })
                        })
                    },
                })

                break

            case "Cancel Approval Request":

                CommonConfirmModal({
                    content: `Cancel Approval for ${vendor.username}s On-Boarding.`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                        preApproveHandler('reject')
                    },
                    onCancel: () => { }
                })

                break;

            default:
                console.log('Meh')
                break;
        }

    }

    return (
        <Container>

            <div className='actions-wrapper' >

                <div>
                    <button onClick={backHandler} className='backIconWrapper' >
                        <ArrowLeftOutlined />
                    </button>

                    <h1>{editMode ? 'Edit' : ''} Driver Card</h1>
                </div>

                <div className='icons-wrapper' >
                    {editMode && <EditFilled onClick={() => setDisabled(false)} />}
                    <IconShare color={THEME.colors.primary} size={25} />
                    <DeleteFilled/>
                    {/* {editMode && <DeleteVendor editMode={true} />} */}
                    <PlusOutlined />
                </div>

            </div>

            {editMode && <div className='submenu-wrapper'>
                {menu.map(v => (
                    <a key={`vendor-header-sub-${str.addDash(v.label)}`} onClick={() => subMenuClickHandler(v.label)} >{v.label}</a>
                ))}
            </div>}

            <PreApproveModal
                show={showPreApprove}
                onCancel={() => setShowPreApprove(false)}
                onReject={() => { preApproveHandler('reject') }}
                onApprove={() => { preApproveHandler('approve') }}
            />

        </Container>
    )
}

Header.propTypes = {
    onEvent: PropTypes.func,
    editMode: PropTypes.bool,
    setDisabled: PropTypes.func
}

export default Header
