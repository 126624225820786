import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { createContext, useState } from 'react'
import { DataTable, Menu } from './components'
import ClockV2 from 'Components/ClockV2'
import { useSubscription } from '@apollo/client';
import { SUB_ROUTES } from 'Util/subscriptions';

export const LogisticsRouteContext = createContext()

function LogisticsRoutes() {

    /** Table filters */
    const [filters, setFilter] = useState({ jobtype: 'all' })
    const [selectedRecord, setSelectedRecord] = useState({})

    /** status condition */
    const status = filters.jobtype === "all" ? {} :  { _eq: filters.jobtype }

    const { data, loading, error } = useSubscription(SUB_ROUTES, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                status,
                deleted_at: { _is_null: true }
            }
        }
    })

    const formatData = (data) => {
        if (loading) { return [] }
        if (data?.routes) {
            return data.routes.map(d => ({
                ...d,
                driver: d.driver.name,
                vehicle: d.vehicle.number,
                depot_address: `${d.depot?.code}, ${d.depot?.street}, ${d.depot?.city}, ${d.depot?.state}, ${d.depot?.country}`
            }))
        }
        return []
    }

    return (

        <LogisticsRouteContext.Provider value={{ filters, setFilter, selectedRecord, setSelectedRecord }} >
            <div>
                <SubPageBreadCrumbs />
                <Menu/>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '20px'  }}>
                    {/* calendar compoenent here */}
                    
                    <div style={{ border: '1px solid #ccc', padding: '2px' }}>
                        {'<'} Tuesday March 6 2024 {'>'}
                    </div>

                    <ClockV2 />
                </div>
                <DataTable
                    data={loading ? [] : formatData(data)}
                    loading={loading}
                    pageSize={50}
                    height={500}
                />
            </div>
        </LogisticsRouteContext.Provider>
    )
}

export default LogisticsRoutes