
import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AdminRolesHeader, PermissionGroup, UserGroupsDataTable, UserGroupTableFilter } from './components'

const Container = styled.div``
const TablePermissionContainer = styled.div`
    display: flex;
    position: relative;
`

export const UserGroupsContext = createContext()
function UserGroups(props) {

    const [selectedRecord, setSelectedRecord] = useState({})
    const [permissionView, setPermissionView] = useState(false)



    return (
        <UserGroupsContext.Provider value={{
            selectedRecord, setSelectedRecord,
            permissionView, setPermissionView
        }}>
            <Container>
                <AdminRolesHeader title='User Groups' />
                <UserGroupTableFilter />
                <TablePermissionContainer>
                    <UserGroupsDataTable />
                    {permissionView && <PermissionGroup
                        sets={["test"]}
                        members={[
                            { username: "username", department: "department" }
                        ]}
                    />}
                </TablePermissionContainer>
            </Container>
        </UserGroupsContext.Provider>
    )
}

UserGroups.propTypes = {}

export default UserGroups
