import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { THEME } from 'Util/constants'
import slugify from 'slugify'

const Container = styled.div`
    width: 500px;
    padding: 0px 20px;
    position: absolute;
    right: 0;
    background: ${THEME.colors.lightGray};
`
const SetContainer = styled.div`
    margin-bottom: 20px;
    label{
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
    }
    .group{
        background-color: #f2f2f2;
        min-height: 200px;
        padding: 10px;
    }
`
const PermissionItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${THEME.colors.gray};
    padding: 2px 0px;
    .menu{
        display: block;
        cursor: pointer;
    }
`

const ItemHeaderWrapper = styled.div`
    display: flex;    
    border-bottom: 1px solid ${THEME.colors.gray};
    padding: 2px 0px;
    .cols{ width: 100%;}
`

const UserItemWrapper = styled.div`
    display: flex;   
    border-bottom: 1px solid ${THEME.colors.gray}; 
    padding: 2px 0px;
    span{ 
        width: 100%; 
        display: block
    }
`

const ItemHeader = ({ cols = [] }) => {
    return <ItemHeaderWrapper>
        {cols.map(c => (<span key={c} className='cols' >{c}</span>))}
    </ItemHeaderWrapper>
}

const PermissionItem = ({ label }) => {
    return <PermissionItemWrapper>
        <span className='label' >{label}</span>
        <span className='menu' >...</span>
    </PermissionItemWrapper>
}


const UserItem = ({ data }) => {
    return <>
        {
            data && data.map(c => <UserItemWrapper key={JSON.stringify(c)}>
                <span style={{ color: `${THEME.colors.primary}` }} >{c.username}</span>
                <span >{c.department}</span>
            </UserItemWrapper>)
        }
    </>
}



function PermissionGroup({ sets, members }) {

    return (
        <Container>
            <SetContainer>
                <label>Permission Sets</label>
                <div className='group'>
                    {sets && sets.map(v => <PermissionItem key={slugify(v, { lower: true })} label={v} />)}
                </div>
            </SetContainer>

            <SetContainer>
                <label>Members</label>
                <div className='group'>
                    {members && members.map(v => <PermissionItem key={slugify(v, { lower: true })} label={v} />)}
                </div>
                {/* <div className='group'>
                    <ItemHeader cols={['Username', 'Department']} />
                    <UserItem data={members} />
                </div> */}
            </SetContainer>

        </Container>
    )
}

PermissionGroup.propTypes = {
    sets: PropTypes.arrayOf(PropTypes.string),
    members: PropTypes.arrayOf(PropTypes.string),
    // members: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         username: PropTypes.string,
    //         department: PropTypes.string
    //     })
    // )
}

export default PermissionGroup
