import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Table } from 'antd';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { THEME } from "Util/constants";
import TableFilter from './TableFilter';
import PropTypes from "prop-types";
import { useLocation, Link } from 'react-router-dom'
import { vendorSearchAtom } from "Store/crmAtom";
import { LogisticsJobContext } from '..';
import moment from 'moment';

const Container = styled.div`
	.ant-table-thead {
		.ant-table-cell {
			color: #999;
			font-weight: bold;
		}
	}

	.ant-table-tbody > tr:hover > td {
		background-color: "none";
	}
	.ant-table-tbody > tr.selected > td {
		background-color: ${THEME.colors.primary};
	}  
`;

const columns = [
    {
        title: 'Job ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: "7%"
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => a.date?.localeCompare(b.date),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        sorter: (a, b) => a.client?.localeCompare(b.client),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => a.address?.localeCompare(b.address),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => a.type?.localeCompare(b.type),
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (type) => <span>{type === 0 ? "Delivery" : "Collection"}</span>
    },
    {
        title: 'Depot',
        dataIndex: 'depot',
        key: 'depot',
        sorter: (a, b) => a.depot?.localeCompare(b.depot),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Shipper',
        dataIndex: 'shipper',
        key: 'shipper',
        sorter: (a, b) => a.shipper?.localeCompare(b.shipper),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Job_time',
        dataIndex: 'job_time',
        key: 'job_time',
        sorter: (a, b) => a.job_time - b.job_time,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, rec) => {
            const from = moment(rec.time_from, 'HH:mm:ssZ').tz('Asia/Kolkata');
            const to = moment(rec.time_to, 'HH:mm:ssZ').tz('Asia/Kolkata');
            if (rec.time_from && rec.time_to){
                return `${from.tz('Asia/Kolkata').format('HH:mm')}-${to.tz('Asia/Kolkata').format('HH:mm')}`
            }        
        },
    },
    {
        title: 'Service Time (Mins)',
        dataIndex: 'service_time',
        key: 'service_time',
        sorter: (a, b) => a.service_time - b.service_time,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Total Weight (KG)',
        dataIndex: 'total_weight',
        key: 'total_weight',
        sorter: (a, b) => a.total_weight - b.total_weight,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Boxes',
        dataIndex: 'boxes',
        key: 'boxes',
        sorter: (a, b) => a.boxes - b.boxes,
        sortDirections: ['ascend', 'descend', 'ascend']
    }
];


function DataTable({
    pageSize = 50,
    height = 250,
    data = [],
    loading = false
}) {
    const { selectedRecord, setSelectedRecord } = useContext(LogisticsJobContext)
    const [search, setSearch ] = useRecoilState(vendorSearchAtom)
    const location = useLocation();

    const searchable = data.map((obj, i) => {
        let result = [];
        let concatenatedValues = "";
        columns.forEach(column => {
          if (obj[column.key] && obj[column.key] !== undefined) concatenatedValues += obj[column.dataIndex] + " ";
        });
        result.push(concatenatedValues.trim());
    
        return {
          ...obj,
          searchText:  result.toString()
        }
      })
    
    const filterData = (data) => {
        if(!search){
            return data
        }
        return _.filter(searchable, function (d) { 
            let query = search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    };
    
    const handleRowClick = (row) => {
        setSelectedRecord(row);
        console.log('row', row);
    }
    
    useEffect(() => {
        setSelectedRecord({})
    }, [location.pathname, setSelectedRecord])

    useEffect(() => {
        return () => { setSearch('') }
    }, [setSearch])
    
    return (
        <Container>
            <TableFilter data={filterData(data)} column={columns}/>
            <Table
                loading={loading}
                columns={columns}
                dataSource={filterData(data)}
                onRow={(rec, idx) => ({
                    onClick: (e) => {
                      handleRowClick(rec)
                    }       
                })}
                pagination={{ defaultPageSize: pageSize }}
                scroll={{ y: height, x: 1500 }}
                rowKey={rec => parseInt(rec.id, 10)}
                components={{
                    body: {
                        row: (props) => {
                            const row = _.find(filterData(data), { id: props['data-row-key'] })
                            let selected = false;
                            if (!_.isEmpty(selectedRecord) && !_.isEmpty(row)) {
                                selected = row.id === selectedRecord.id;
                            }
                            const tableRow = (
                                <tr 
                                    {...props}
                                    style={{ cursor: 'pointer' }} 
                                    onClick={props.onClick}
                                    className={`ant-table-row ant-table-row-level-0 yeah ${selected ? "selected" : ""}`}
                                >
                                    {props.children}
                                </tr>
                            )

                            return tableRow
                        }
                    }
                }}
            />
        </Container>
    )

}

DataTable.propTypes = {
    pageSize: PropTypes.oneOf([10, 20, 50, 100]),
    height: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
};

export default DataTable;