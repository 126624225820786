import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Dropdown, Menu, Space } from 'antd';
import { CheckOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';

const menu = (
    <Menu>
        <Menu.Item>
            <a target="_blank" href="#" > <CheckOutlined style={{ color: THEME.colors.primary }} />Send Approval Request </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" href="#" > <CloseOutlined style={{ color: 'red' }} />Cancel Approval Request </a>
        </Menu.Item>
    </Menu>
);

const Container = styled.div`
  cursor: pointer;
    display: flex;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        margin-left: 5px;
    }
    label{
        font-weight: bold;
    }
    .ant-space-item{
        color: #000;
        &:first-child{
            font-weight: bold;
        }
    }  
`;

function VendorAction(props) {
    return (
        <Container>
            <Dropdown overlay={menu} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        Action
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Container>
    )
}

VendorAction.propTypes = {}

export default VendorAction