import React from 'react'
import PropTypes from 'prop-types'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import ButtonCard from 'Components/ButtonCard'

const CRMWholeSalePage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            CRM WholeSale Page
        </div>
    )
}

CRMWholeSalePage.propTypes = {}

export default CRMWholeSalePage