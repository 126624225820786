import { createContext, useState } from 'react'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { useParams } from 'react-router-dom'
import { DataTable, Menu } from './components'
import { useSubscription } from '@apollo/client'
import { SUB_CARRIERS } from 'Util/subscriptions'

/** Page context */
export const LogisticsContext = createContext()

const CarriersPage = props => {
    const params = useParams();
    const [filters, setFilter] = useState({ type: 'all' })
    const [selectedRecord, setSelectedRecord] = useState({})

    /** status condition */
    // const type = !_.isUndefined(params.type) ? { _eq: params.type } : {}

    const { data, loading, error } = useSubscription(SUB_CARRIERS, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                deleted_at: { _is_null: true }
            }
        }
    });

    const formatData = (data) => {
        if (loading) { return [] }
        if (data?.carriers) {            
            return data.carriers.map(d => ({
                ...d
            }))
        }
        return []
    }

    return (
        <LogisticsContext.Provider value={{ filters, setFilter, selectedRecord, setSelectedRecord }}>
            <SubPageBreadCrumbs />
            <Menu />
            <DataTable
                data={loading ? [] : formatData(data)}
                loading={loading}
                pageSize={50}
                height={500}
            />
        </LogisticsContext.Provider>
    )
}

CarriersPage.propTypes = {}

export default CarriersPage