import React, { useContext, useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, notification, Switch } from 'antd';
import { useMutation } from '@apollo/client';
import { AppContext } from 'AppContext';
import moment from 'moment';
import useSettings from '../../Hooks/settings'
import { UPDATE_SETTINGS } from 'Util/mutations';
import _ from 'lodash'
import './auction.scss';
import TimePicker from 'Components/TimePicker';

function AuctionSettings() {

    const { refetchSettings } = useContext(AppContext)
    const [settings] = useSettings('auction');

    const [prebidDuration, setPrebidDuration] = useState()
    const [auctionDuration, setAuctionDuration] = useState()
    const [resultDuration, setResultDuration] = useState()

    const [updateSettings, { data }] = useMutation(UPDATE_SETTINGS, {
        onCompleted(data) {
            refetchSettings();
            notification['success']({
                message: 'Saved!',
                description:
                    'Auction settings has been saved',
            });
        },
        onError(e) {
            console.log('erre', e)
        }
    })

    if (_.isUndefined(settings)) {
        return <></>
    }

    const handleSubmit = (values) => {

        updateSettings({
            variables: {
                key: "auction",
                values
            }
        })
    }

    const preBid = (value) =>{
        setPrebidDuration(value)
    }

    const auction = (value) =>{
        setAuctionDuration(value)
    }

    const result = (value) =>{
        setResultDuration(value)
    }

    const durations = {
        prebid: prebidDuration == undefined ? settings.pre_bid_duration : prebidDuration,
        auction: auctionDuration == undefined ? settings.auction_duration : auctionDuration,
        result: resultDuration == undefined ? settings.result_duration : resultDuration
    }


    return (
        <div className="auction-settings" >

            <div className="form-wrapper" >
                <Form
                    onFinish={handleSubmit}
                    className="form-settings"
                >
                    <div >
                        <h2>Duration</h2>

                        <Form.Item initialValue={settings.pre_bid_duration} name="pre_bid_duration" label="Pre Bid Duration" labelAlign="left" >
                            <Input type="number" onChange={(e) => preBid(e.target.value)} />
                        </Form.Item>

                        <Form.Item initialValue={settings.auction_duration} name="auction_duration" label="Auction Duration" labelAlign="left" >
                            <Input type="number" onChange={(e) => auction(e.target.value)} />
                        </Form.Item>

                        <Form.Item initialValue={settings.result_duration} name="result_duration" label="Result Duration" labelAlign="left" >
                            <Input type="number" onChange={(e) => result(e.target.value)} />
                        </Form.Item>

                    </div>

                    <div style={{ maxWidth: '350px', marginTop: 40 }} >
                        <h2>Auction schedule</h2>
                        <Form.List
                            name="schedule"
                            initialValue={settings.schedule}
                        >
                            {(fields, { add, remove }) => (
                                <>

                                    {fields.map((field, key) => (
                                        <Space key={field.key} align="baseline">
                                            <Form.Item
                                                {...field}
                                                name={[key, 'time']}
                                                fieldKey={[field.fieldKey, 'schedule']}
                                                label={`${key + 1} Auction`} labelAlign="left" >
                                                <TimePicker data={durations} index={key + 1} />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </Space>

                                    ))}

                                    <Form.Item>
                                        <Button style={{ maxWidth: 295 }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Schedule
                                        </Button>
                                    </Form.Item>

                                    

                                </>

                            )}

                        </Form.List>

                    </div>

                    <Form.Item initialValue={settings.automatic_bid_acceptance} name="automatic_bid_acceptance" label="Auto Accept" hidden={true} >
                        <Input type="text" />
                    </Form.Item>

                    <Form.Item style={{ marginTop: 40 }}>
                        <Button type="primary" htmlType="submit">
                            Save changes
                        </Button>
                    </Form.Item>
                </Form>
            </div>

        </div>
    )
}

export default AuctionSettings
