import CommonDiv from 'Components/CommonDiv'
import { Drawer } from 'antd'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { THEME } from 'Util/constants'
import { FaUser } from 'react-icons/fa'
import { AttachmentComponent, Headers, NotificationContent, PollComponent } from './components/index'
import { LabelInfo } from './styled'
import { NotificationContext } from '../NotificationContext'

import _ from 'lodash'

function NotificationView() {

    const { show, setShow, data } = useContext(NotificationContext)
    const { title, id, admin_notifications_attachments, poll } = data;


    return (
        <Drawer
            visible={show}
            closable={true}
            placement="right"
            title={<h2>{title}</h2>}
            width="100%"
            height="calc(100% - 60px)"
            mask={false}
            onClose={() => { setShow(false) }}
        >

            <Headers />
            <NotificationContent />
            {!_.isEmpty(admin_notifications_attachments) && <AttachmentComponent />}
            {!_.isNull(poll) && <PollComponent />}

        </Drawer >
    )
}

export default NotificationView