import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Popconfirm, Table } from 'antd';
import { useSubscription } from '@apollo/client';
import { COLLECTION_LOCATIONS } from 'Util/subscriptions';
import { CollectionPointsContext } from '..';
import { collectionPointSearchAtom } from 'Store/collectionPointsAtom';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { TableFilters } from './';
import { THEME } from "Util/constants";

const Container = styled.div`
	.ant-table-thead {
		.ant-table-cell {
			color: #999;
			font-weight: bold;
		}
	}

	.ant-table-tbody > tr:hover > td {
		background-color: "none";
	}
	.ant-table-tbody > tr.selected > td {
		background-color: ${THEME.colors.primary};
	}  
`;

const columns = [
    {
        title: 'Location Code',
        dataIndex: 'location_code',
        key: 'location_code',
        sorter: (a, b) => a.location_code - b.location_code,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Location Name',
        dataIndex: 'location_name',
        key: 'location_name',
        sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => a.address?.localeCompare(b.address),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        sorter: (a, b) => a.city?.localeCompare(b.city),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => a.state?.localeCompare(b.state),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: (a, b) => a.country?.localeCompare(b.country),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Postcode',
        dataIndex: 'postal_code',
        key: 'postal_code',
        sorter: (a, b) => a.postal_code - b.postal_code,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Latitude',
        dataIndex: 'lat',
        key: 'lat',
        sorter: (a, b) => a.lat - b.lat,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Longitude',
        dataIndex: 'lng',
        key: 'lng',
        sorter: (a, b) => a.lng - b.lng,
        sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
        title: 'Google Map',
        dataIndex: 'google_map_url',
        key: 'google_map_url',
        sorter: (a, b) => a.location_name?.localeCompare(b.location_name),
        sortDirections: ['ascend', 'descend', 'ascend']
    },
];


function CollectionPointsDataTable() {

    const { selectedRecord, setSelectedRecord, filter } = useContext(CollectionPointsContext)
    const [searchRecord, setSearchRecord] = useRecoilState(collectionPointSearchAtom) 
    const [data, setData] = useState([]);

    const { loading } = useSubscription(COLLECTION_LOCATIONS, {
        variables: {
            where: {
                is_active: { _eq: filter.is_active },
                deleted_at: { _is_null: true }
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            data.collection_locations && setData(data.collection_locations.map(v => ({
                key: v.id,
                address: `${v.address_1}, ${v.address_2}`,
                ...v
            })))
        }
    })

    const filterData = (data) => {

        if (_.isEmpty(data)) {
            return []
        }

        const searchable = data.map(obj=>({
            ...obj,
            searchText: `${ obj.location_code } ${ obj.location_name } ${ obj.address } ${obj.city} ${obj.state} ${obj.country} ${obj.postal_code} ${obj.lat} ${obj.lng} ${obj.google_map_url}`
          }))    
          
        if(!searchRecord.search){
            return _.filter(data, filter)
        }
        return _.filter(searchable, function (d) { 
            let query = searchRecord.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    }

    useEffect(() => {
        return () => { setSearchRecord('') }
    }, [])

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={columns}/>
            <Table
                size='small'
                columns={columns}
                loading={loading}
                dataSource={filterData(data)}
                rowKey={(rec) => parseInt(rec.id, 10)}
                onRow={(rec, idx) => ({
                    onClick: (e) => {
                      setSelectedRecord(rec)
                    }     
                  })}
                components={{
                body: {
                    row: (props) => {
                    const row = _.find(filterData(data), {
                        id: props["data-row-key"],
                    });
                    let selected = false;
                    if (!_.isEmpty(selectedRecord) && !_.isEmpty(row)) {
                        selected = row.id === selectedRecord.id;
                    }
    
                    return (
                        <tr
                        style={{ cursor: "pointer" }}
                        onClick={props.onClick}
                        className={`ant-table-row ant-table-row-level-0 yeah ${selected ? "selected" : ""}`}
                        >
                        {props.children}
                        </tr>
                        );
                    },
                },
                }}
            />

        </Container>
    )
}

export default CollectionPointsDataTable