import { Select } from 'antd';
import React from 'react';
import { fulfilmentType } from 'Util/constants';
const { Option } = Select;

function FulfilmentTypeSelect({ onChange, ...rest }) {

    return (
        <div>
            <Select
                {...rest}
                placeholder="Select a type"
                onChange={onChange}
            >
                {fulfilmentType.map((val, idx) => <Option value={val} key={`fulfilment-opt${idx}`}>{val}</Option>)}
            </Select>
        </div>
    )
}

export default FulfilmentTypeSelect
