import { useMutation, useSubscription } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification, Table } from 'antd';
import gmap from 'gmap';
import React from 'react';
import { mapConstant } from 'Util/constants';
import { ACCEPT_DIRECT_OFFER, UPDATE_DIRECT_OFFER } from 'Util/mutations';
import { SUB_DIRECT_OFFERS } from 'Util/subscriptions';
import { commonColumns, dataSource, statusQuery } from './common';
import PopOverDetails from './components/PopOverDetails';
import './style.scss';

function OpenDirectSales(props) {
    const { data, loading, error } = useSubscription(SUB_DIRECT_OFFERS, statusQuery(['open', 'staged']));

    const [updateDirectOffer] = useMutation(UPDATE_DIRECT_OFFER, {
        onCompleted() {
            notification['success']({
                message: "Success!"
            });
        },
        onError(e) { console.log('erre', e) }
    });

    const [acceptDirectOffer] = useMutation(ACCEPT_DIRECT_OFFER, {
        onCompleted() { console.log('Direct offer accepted') },
        onError(e) { console.log('erre', e) }
    });

    const handleSubmit = ({ action, data }) => {

        const { id, order_quantity, avail_quantity, user_id, status, user_info } = data

        if (action === 'add') {

            updateDirectOffer({
                variables: {
                    id,
                    _set: {
                        status: 'staged',
                        order_quantity
                    }
                }
            })

        }

        if (action === 'buy') {

            const link = gmap.directions(mapConstant.pickupCoordinates, `${user_info.user_location.lat},${user_info.user_location.lng}`)

            acceptDirectOffer({
                variables: {
                    objects: {
                        eta: 10, /** TODO: eta as editable */
                        user_id,
                        link,
                        purchase_order: {
                            data: {
                                user_id,
                                status: "pending",
                                direct_offer_order: {
                                    data: {
                                        status: "accepted",
                                        order_quantity,
                                        direct_offer_id: id
                                    }
                                }
                            }
                        }
                    }
                }
            })

            updateDirectOffer({
                variables: {
                    id,
                    _set: {
                        avail_quantity,
                        order_quantity: 0,
                        status: (avail_quantity <= 0) ? 'withdrawn' : status
                    }
                }
            })

        }

    }

    const columns = [
        ...commonColumns,
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => {
                return <div className='direct-offer-action-wrapper' >
                    <PopOverDetails data={record} add onSubmit={handleSubmit} >
                        <FontAwesomeIcon
                            size="2x"
                            style={{ "cursor": "pointer" }}
                            icon={faInfoCircle}
                            color='#20B0B5'
                        />
                    </PopOverDetails>
                </div>
            }
        }
    ]

    const cancel = () => {
        console.log('cancel editing')
    };

    return (
        <div className="direct-offer-page" >
            <Table
                loading={loading}
                bordered
                className="bid-table"
                pagination={{ pageSize: 50 }}
                columns={columns}
                dataSource={dataSource('open', data)}
            />
        </div>
    )
}

OpenDirectSales.propTypes = {

}

export default OpenDirectSales

