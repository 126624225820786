import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { useParams } from 'react-router-dom'
import { DataTable, Menu } from './components'
import { useSubscription } from '@apollo/client'
import { SUB_VENDORS } from 'Util/subscriptions'
import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants'
import _ from 'lodash'

/** Page context */
export const CRMVendorContext = createContext()

const CRMVendorsPage = props => {
    const params = useParams();
    const [pageState, setPageState] = useState({
        selectedUser: {}
    })

    /** status condition */
    const status = !_.isUndefined(params.status) ? { _eq: params.status } : {}

    const { data, loading, error } = useSubscription(SUB_VENDORS, {
        variables: {
            where: {
                user: {
                    status,
                    user_type: {
                        label: { _eq: "Mobile" }
                    }
                },
                deleted_at: { _is_null: true }
            }
        }
    });

    const formatData = (data) => {

        if (loading) { return [] }

        if (data?.vendor_details) {

            return data.vendor_details.map(vd => ({
                id: vd.user.id,
                user: vd.user,
                vendor_id: leadingZeroes(vd.user.id, appConstant.leadingZero),
                name: vd.user.name ?? 'N/A',
                aadhaar_id: vd.user.aadhaar_id ?? 'N/A',
                mobile: vd.user.mobile ?? 'N/A',
                email: _.isEmpty(vd.user.email) ? 'N/A' : vd.user.email,
                contact: vd.contact_person_name ?? 'N/A',
                location: vd.user.user_location.label ?? 'N/A',
                location_code: leadingZeroes(vd.user.user_location.id, appConstant.leadingZero) ?? 'N/A',
                vpa: _.isEmpty(vd.virtual_payment_address) ? 'N/A' : vd.virtual_payment_address,
                vessel: leadingZeroes(vd.user.vessel, appConstant.leadingZero),
                onboard_code: vd.user.onboard_code,
                channels: vd.user.channel_ids
            }))

        }

        return []

    }

    return (
        <CRMVendorContext.Provider value={{ pageState, setPageState }}>
            <SubPageBreadCrumbs />
            <Menu />
            <DataTable
                data={loading ? [] : formatData(data)}
                loading={loading}
                pageSize={50}
                height={500}
                onSelectRow={(rec) => { setPageState({ ...pageState, selectedUser: rec }) }}
            />
        </CRMVendorContext.Provider>
    )
}

CRMVendorsPage.propTypes = {}

export default CRMVendorsPage