import React, { useState, createContext } from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { auctionMenu } from '../constants'
import { DataTable, TableFilters } from './components'
import ClockV2 from 'Components/ClockV2'
import AuctionTimer from 'Components/AuctionTimer'

export const AuctionDataContext = createContext()

const Container = styled.div``

function AuctionData() {

    const [filter, setFilter] = useState('1d')
    const [selectedRecord, setSelectedRecord] = useState(null)

    return (
        <AuctionDataContext.Provider value={{
            filter, setFilter,
            selectedRecord, setSelectedRecord
        }}>
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
                <AuctionTimer position='absolute'/>
                <ClockV2 position='absolute' />
                {/* <TableFilters/> */}
                <DataTable />
            </Container>
        </AuctionDataContext.Provider>
    )
}

export default AuctionData