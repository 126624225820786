
import React from 'react'
import { Level2Menu } from 'Components/Menus'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { useHistory, useLocation, useParams } from 'react-router-dom';

const menu = [
    { title: "Master", link: "/procurement/collection-points" },
    { title: "Pending Approval", link: "/procurement/collection-points/pending-approval" },
    { title: "Quotas", link: "/procurement/collection-points/quotas" },
]

function CollectionPointsQuotas() {

    return (
        <div>
            <SubPageBreadCrumbs />
            <Level2Menu menu={menu} />
            <label>Qoutas</label>
        </div>
    )
}

export default CollectionPointsQuotas