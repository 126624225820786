import React from 'react'

function CMS() {
    return (
        <div>
            <p>Management for:</p>
            <ul>
                <li>User</li>
                <li>User Roles</li>
                <li>Species Description</li>
                <li>Product</li>
                <li>Product Images</li>
            </ul>
        </div>
    )
}

export default CMS
