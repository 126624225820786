import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import { THEME } from 'Util/constants'
import { Link, useParams, useLocation } from 'react-router-dom';
import sanitize from 'string-sanitizer'

const Container = styled.div`
    ul>li>a{
      color: ${THEME.colors.primary};
      cursor: pointer;
    }
`

function LinkList({ title = "", data }) {

    const basePath = `/admin/roles/system-admin/`

    return (
        <Container>
            <h3>{title} ({data.length})</h3>
            <ul>
                {data.map(v => {
                    if (v.sub) {
                        return <li key={`linkList-${sanitize.addDash(v.text)}`} >
                            <Link to={v.link ? basePath + v.link : basePath + sanitize.addDash(`${v.text}`.toLowerCase())}>{v.text}</Link>
                            <ul>
                                {v.sub.map(sv => <li key={`linkList-sub-${sanitize.addDash(sv.text)}`}><Link to={sv.link ? basePath + sv.link : basePath + sanitize.addDash(`${sv.text}`.toLocaleLowerCase())}>{sv.text}</Link></li>)}
                            </ul>
                        </li>
                    }
                    return <li key={`linkList-${sanitize.addDash(v.text)}`} ><Link to={v.link ? basePath + v.link : basePath + sanitize.addDash(`${v.text}`.toLocaleLowerCase())}>{v.text}</Link></li>
                })}
            </ul>
        </Container>
    )
}

LinkList.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object)
}

export default LinkList
