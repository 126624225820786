import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';

/**
 * Props
 * 
 * (flex-direction) direction : row | row-reverse | column | column-reverse
 * 
 * (justify-content) justify : flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;
 * 
 * (align-items) align : stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
 * 
 * (align-content) content : flex-start | flex-end | center | space-between | space-around | space-evenly | stretch | start | end | baseline | first baseline | last baseline + ... safe | unsafe;
 * 
 * (width) w
 * 
 * (max-width) maxw
 * 
 * (padding) padding 0px 0px 0px 0px
 * 
 * */


const CommonDiv = styled.div`
  display: flex ;
  flex-direction: ${props => props.direction ?? 'column'} ;
  justify-content:  ${props => props.justify ?? 'flex-start'};
  align-items: ${props => props.align ?? 'flex-start'};
  width:  ${props => props.w ?? `100%`};
  max-width: ${props => props.maxw ?? `100%`};
  padding: ${props => props.padding ?? `0px`};
  margin: ${props => props.margin ?? `0px`};
  align-content: ${props => props.content ?? `center`} ;
`

// CommonDiv.propTypes = {
//   direction: PropTypes.oneOf(["row", "column"]).isRequired
//   direction: PropTypes.string.isRequired
// }

// CommonDiv.defaultProps = {
//   margin: 'flex-start'
// }

export default CommonDiv
/* @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
    flex-direction: ${props => props.direction ?? 'column'} ;
    justify-content:  ${props => props.justify ?? 'flex-start'};
    max-width: ${props => props.maxWidth ?? '100%'};
  } */

