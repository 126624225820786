import { useMutation, useSubscription } from '@apollo/client';
import { Button, Form, Input, notification, Table, Typography } from 'antd';
import CommonDrawer from 'Components/CommonDrawer';
import React, { useState } from 'react';
import { ADD_LANGUAGE, UPDATE_LANGUAGE } from 'Util/mutations';
import { SUB_LANGUAGE } from 'Util/subscriptions';
import _ from 'lodash';
import './style.scss';

function CMSLanguage() {
    const [form] = Form.useForm();
    const { data, loading, error } = useSubscription(SUB_LANGUAGE);
    const [editId, setEditId] = useState(null);
    const [drawer, setDrawer] = useState(false);
    const [add] = useMutation(ADD_LANGUAGE, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Language has been added',
            });
            setDrawer(false);
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const [update] = useMutation(UPDATE_LANGUAGE, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Language has been updated!',
            });
            setDrawer(false);
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const drawerClose = () => {
        setDrawer(false);
        setEditId(null);
    }

    const edit = (record) => {
        setEditId(record.id)
        form.setFieldsValue({
            ...record,
        });
        setDrawer(true);
    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const onFormSubmit = () => {
        let object = form.getFieldsValue();

        _.isNull(editId) ? add({ variables: { object } }) : update({ variables: { id: editId, object } })

    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'label',
            editable: true,
        },
        {
            title: 'Language Code',
            dataIndex: 'code',
            editable: true,
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <Typography.Link onClick={() => edit(record)}>
                    Edit
                </Typography.Link>
            ),
        },
    ];

    const dataSource = () => {

        if (data) {
            return data.user_language.map((v, i) => (
                {
                    key: `key-${i}-lang-list`,
                    id: v.id,
                    label: v.label,
                    code: v.code
                }
            ))
        }

        return []

    }

    /** Validation Rules */
    const rule = [
        {
            required: true,
            message: "Field is required"
        },
    ]

    return (
        <div className="langugage-page" >
            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { setDrawer(true); }} >New Language</Button>
            </div>

            <CommonDrawer
                open={drawer}
                form={form}
                title={editId ? "Edit language" : "Add new language"}
                onClose={drawerClose}
            >

                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                    component={false}
                >
                    <Form.Item
                        rules={rule}
                        label="Label" name="label">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Languge Code" name="code">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                </Form>

            </CommonDrawer>

            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    );
}

export default CMSLanguage
