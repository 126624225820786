import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import { variant } from 'styled-system'
import { THEME } from 'Util/constants'

const Container = styled.div`
    width: 190px;
    padding: 0px 5px;
    margin-bottom: 20px;
    
    h3{
        min-height: 60px ;
    }

    .link-wrapper{
        a{
            color: ${THEME.colors.primary};
            font-size: 16px;
            :before{
                content: '> ';
            }
        }
    }
`;

const ContentValue = styled('h1')({
    fontSize: '25px',
    borderBottom: 'solid 2px #ccc'
},
    variant({
        variants: {
            negative: { borderBottom: 'solid 2px red' }
        }
    })
)

const DashInfoCard = ({ title, value, link, red }) => {
    return (
        <Container>
            <h3>{title}</h3>
            <ContentValue variant={red ? 'negative' : ''} >{value}</ContentValue>
            <div className='link-wrapper'>
                <a href={link}>See More</a>
            </div>
        </Container>
    )
}

DashInfoCard.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    red: PropTypes.bool
}

export default DashInfoCard