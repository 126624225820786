import React from 'react'
import styled from "styled-components";
import StatusDropDown from './StatusDropDown';
import Search from './Search';
import New from './New';
import Delete from './Delete';
import ExtraFilters from './ExtraFilters';
import Edit from './Edit';

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

function TableFilter(props) {

    return (
        <Container>
            {/* <StatusDropDown /> */}
            <Search />
            <New />
            <Edit/>
            <Delete />
            <ExtraFilters data={props.data} column={props.column}/>
        </Container>
    )
}

TableFilter.propTypes = {
}

export default TableFilter