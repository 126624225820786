import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { Checkbox, Space, Table, Tag } from 'antd';
import { THEME } from 'Util/constants';
import { useSubscription } from '@apollo/client';
import { ADMIN_USER_GROUPS, USER_PROFILE_ROLES } from 'Util/subscriptions';
import { UserGroupsContext } from '../UserGroups';

const Container = styled.div`
    width: 100%;
`

const columns = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        render: (text) => <a style={{ color: THEME.colors.primary }} >{text}</a>,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Default Profile',
        dataIndex: 'profile',
        key: 'profile',
    }
];

function UserGroupsDataTable(props) {

    const { setSelectedRecord } = useContext(UserGroupsContext)

    const [data, setData] = useState([])
    const { loading } = useSubscription(ADMIN_USER_GROUPS, {
        onSubscriptionData({ subscriptionData: { data } }) {
            data.user_groups && setData(data.user_groups.map(ug => ({
                id: ug.id,
                key: `user-groups-data-${ug.id}`,
                code: ug.code ?? '',
                name: ug.name ?? '',
                profile: ug.user_role.profile_id ?? ''
            })))
        }
    });

    const onSelect = (object) => {

        setSelectedRecord(object)
    };

    return (
        <Container>
            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                rowSelection={{ type: "radio", onSelect }}
            />
        </Container>
    )
}

UserGroupsDataTable.propTypes = {}

export default UserGroupsDataTable
