import React, { useState } from 'react'
import _ from 'lodash'
import useSettings from '../Hooks/settings'
import moment from 'moment'

function TimePicker({ value, onChange, data, index }) {

    const zeroLeadStr = (num) => num.toString().padStart(2, '0')
    const [settings] = useSettings('auction');
    const auctionSchedule =  settings.schedule

    const prebid = data.prebid
    const auction = data.auction
    const result = data.result
    
    const totalTime = Number(prebid) + Number(auction) + Number(result) + 1;

    var hour = '00'
    var am_pm = 'pm'
    var min = '00';

    /*if(!_.isUndefined(auctionSchedule[0])){

        const arr = auctionSchedule[0].time.split(':')
        var hour = arr[0];
        var min = arr[1];
        const array = arr[2].split(' ')
        var am_pm = array[1];

    }

    var x = 1;
    var totalMinutes = 0;
    var h = hour;
    var loopCounter = 13;


    for (let i = parseInt(min); i < 59; i += totalTime) {

        if(x == index){
            totalMinutes = i
        }
        x++
    }

    var today = moment().format('YYYY-MM-DD');

    for(let s = 1; s < loopCounter; s++)
    {
        
        if(totalMinutes == 0){

            for (let t = parseInt(min); t < 59; t += totalTime) {

                if(x == index){

                    h = parseInt(hour) + s  
                    var newTime = parseInt(hour) + s 

                    if(newTime > 12){
                        h = moment(`${today} ${newTime}:${zeroLeadStr(t)}:00`).format("hh")

                    }else{
                        h = parseInt(hour) + s  
                    }

                    totalMinutes = parseInt(t)

                }
                x++
             
            }

        }
    }*/



    const [time, setTime] = useState({
        hour: !_.isUndefined(value) ? value.substr(0, 2) : hour,
        minutes: !_.isUndefined(value) ? value.substr(3, 2) : min,
        ampm: !_.isUndefined(value) ? value.substr(9, 2) : am_pm
    })



    const updateTime = (field, event) => {
        const { value } = event.target
        const newValue = { ...time, [field]:  value }
        setTime(newValue)
        onChange(`${newValue.hour}:${newValue.minutes}:00 ${newValue.ampm}`)
    }

    
    const commonOptions = (name, optionArr, pad = true) => optionArr.map(val => {
        const value = pad ? zeroLeadStr(val) : val

        /*if(name == 'minutes' && value < totalMinutes && index != 1){
            return <option key={`${name}${val}`} value={value} disabled >{value}</option>
        }else{*/
            return <option key={`${name}${val}`} value={value} >{value}</option>
        //}
        
        
    })

    return (
        <div className="time-picker-custom">

            <select defaultValue={time.hour} onChange={v => updateTime('hour', v)} >
                {commonOptions('hour', _.range(1, 13))}
            </select>

            <select defaultValue={time.minutes} onChange={v => updateTime('minutes', v)} >
                {commonOptions('minutes', _.range(60))}
            </select>

            <select defaultValue={time.ampm} onChange={v => updateTime('ampm', v)} >
                {commonOptions('ampm', ["am", "pm"], false)}
            </select>

        </div>
    )
}

export default TimePicker