import CommonButton from 'Components/CommonButton';
import CommonDiv from 'Components/CommonDiv';
import { Form, Input, Radio, Space } from 'antd';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { NotificationContext } from 'Components/Notification/NotificationContext';
import { AditionalInforContainer, OLWrapper } from '../styled';
import useUser from 'Hooks/user'
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_POLL_RESPONSE } from 'Util/queries';
import { UPSERT_POLL_RESPONSE, UPSERT_POLL_COMMENTS } from 'Util/mutations';
import moment from 'moment';

function PollComponent() {

    const { data } = useContext(NotificationContext)
    const { user_id } = useUser()
    const [answer, setAnswer] = useState(null);

    const { poll } = data

    console.log('poll', poll.option_type)
    console.log('poll', poll)

    const where = {
        poll_id: { _eq: poll.id },
        admin_user_id: { _eq: user_id }
    }

    const [getResponse] = useLazyQuery(GET_POLL_RESPONSE, {
        fetchPolicy: "network-only",
        onCompleted(data) {

            console.log("get reponse", data)

            !_.isEmpty(data.poll_response) && setAnswer({
                response: data.poll_response[0].response,
                comment: data.poll_comments[0]?.comment,
                date: data.poll_response[0].created_at,
                username: data.poll_response[0].user?.username || data.poll_response[0].admin_user?.username
            })
        }
    })

    const [addComment] = useMutation(UPSERT_POLL_COMMENTS);
    const [addResponse] = useMutation(UPSERT_POLL_RESPONSE);

    const onFinish = async (values) => {

        const { response, comment } = values
        const admin_user_id = user_id;
        const poll_id = poll.id;

        await addResponse({
            variables: {
                update_columns: ["response"],
                objects: [{
                    poll_id,
                    response,
                    admin_user_id
                }]
            }
        });

        poll.comments && await addComment({
            variables: {
                update_columns: ["comment"],
                objects: [{
                    poll_id,
                    comment,
                    admin_user_id
                }]
            }
        });


        getResponse({
            variables: {
                whereRes: { ...where },
                whereCom: { ...where }
            }
        })

    };

    useEffect(() => {

        getResponse({
            variables: {
                whereRes: { ...where },
                whereCom: { ...where }
            }
        })

        return () => { setAnswer(null) }



    }, [data])


    return (
        <div>

            {_.isNull(answer) && <CommonDiv>

                <Form
                    name='rsvp-form'
                    onFinish={onFinish}
                    style={{ width: "50%" }}
                >

                    <OLWrapper>

                        {
                            !_.isNull(poll) && <li>
                                <Space direction="vertical">
                                    <Form.Item
                                        name="response"
                                        style={{ display: "flex", flexDirection: "column" }}
                                        label={poll.question}
                                        labelAlign='left'
                                        rules={[{ required: true, message: 'Please select one answer.' },]}
                                    >
                                        <Radio.Group style={{ color: "#fff" }} >
                                            <Space direction="vertical">
                                                {poll.options.map((v, i) => <Radio style={{ alignItems: "center" }} key={`poll-option-${i}`} value={i}>
                                                    {poll.option_type === "image" ? <CommonDiv direction='column' content='center' >
                                                        <img style={{ maxWidth: 100 }} src={v.image} />
                                                        <span>{v.text}</span>
                                                    </CommonDiv> : v}
                                                </Radio>)}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                </Space>
                            </li>
                        }

                        {
                            poll.comments && <li>
                                <label>Any other comments?:</label>
                                <Form.Item name="comment" ><Input.TextArea rows={6}></Input.TextArea></Form.Item>
                            </li>
                        }


                    </OLWrapper>

                    <CommonButton type='primary' htmlType='submit' style={{ marginLeft: 40 }} >
                        Update
                    </CommonButton>

                </Form>

            </CommonDiv>}

            {(!_.isNull(answer)) && <CommonDiv>

                <label style={{ fontStyle: "italic", fontWeight: 600, color: "#808080", marginBottom: 15 }} >{poll.question}</label>

                <OLWrapper className='ansOl'>
                    <li>
                        <Space direction="vertical">
                            <label>Your response:</label>
                            <label className='ans'>
                                {poll.option_type === "image" ? <CommonDiv>
                                    <img style={{ maxWidth: 100 }} src={poll.options[answer.response].image} />
                                    <span>{poll.options[answer.response].text}</span>
                                </CommonDiv> : poll.options[answer.response]}
                            </label>
                        </Space>
                    </li>
                    <li>
                        <Space direction="vertical">
                            <label>Comments:</label>
                            <label className='ans'>{answer.comment}</label>
                        </Space>
                    </li>
                </OLWrapper>

                <AditionalInforContainer direction="row" >
                    {!_.isEmpty(answer) && <CommonDiv >
                        <label style={{ fontSize: 10 }} >
                            <span>Submitted by : </span>
                            <span>{answer.username}</span>
                            <span> on </span>
                            <span>{moment(answer.date).format('D MMM YYYY hh:mm')}</span>
                        </label>
                    </CommonDiv>}
                </AditionalInforContainer>

            </CommonDiv>}




        </div>
    )
}

export default PollComponent