import React, { useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import { Country } from 'country-state-city';

const { Option } = Select;

const PhoneInput = ({ onChange, value, disabled = false }) => {
  const [code, setCode] = useState("91");
  const [inputValue, setInputValue] = useState('');

  const handleValueChange = (newValue) => {
    let con;
    if (newValue.length <= code.length) {
        con = `+${code}`;
    } else {
        con = newValue.startsWith(`+${code}`) ? newValue : `+${code}${newValue}`;
    }
  
    setInputValue(con);
    onChange(con);
  };

  const handleCountryChange = (newCountryCode) => {
    setInputValue(`+${newCountryCode}`);
    setCode(newCountryCode)
  };

  const detectCountryCode = (phoneNumber) => {
    if (phoneNumber && phoneNumber !== undefined){
        const countries = Country.getAllCountries();
        for (const country of countries) {
          if (phoneNumber.startsWith("+" + country.phonecode)) {
            setCode(country.phonecode)
            break;
          }
        }
    }
  }

  useEffect(() => {
    detectCountryCode(value);
  }, [value]);

  const defaultValue = inputValue && inputValue.length > 0 ? inputValue : value;

  const selectCountry = () =>  
    <Select 
        value={code}
        onChange={handleCountryChange}
        >
        {Country.getAllCountries().map((country) => (
            <Option key={country.isoCode} value={country.phonecode}>
            {country.flag} {country.isoCode}
            </Option>
        ))}
    </Select>

  return (
      <Input
        value={defaultValue ? defaultValue : `+${code}`}
        onChange={(e) => handleValueChange(e.target.value)}
        style={{ width: "100%" }}
        addonBefore={selectCountry()}
        maxLength={15}
        disabled={disabled}
      />
  );
};

export default PhoneInput;
