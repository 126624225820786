import React from 'react'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { useParams } from 'react-router-dom'
import { Menu } from './components'
import { SystemAdminView, HubCompanyView, FinanceView, HumanRersourceView, ProcessingView, ProcurementView, ResourceView, SalesView, LogisticsView, WarehouseView } from './Contents';

function AdmiRolePage() {

  const { page } = useParams();
  const isCurrentView = (vieName) => page === vieName

  return (
    <div>
      <SubPageBreadCrumbs />
      <Menu />
      {isCurrentView(undefined) && <HubCompanyView />}
      {isCurrentView('system-admin') && <SystemAdminView />}
      {isCurrentView('finance') && <FinanceView />}
      {isCurrentView('human-resource') && <HumanRersourceView />}
      {isCurrentView('processing') && <ProcessingView />}
      {isCurrentView('procurement') && <ProcurementView />}
      {isCurrentView('resource') && <ResourceView />}
      {isCurrentView('sales') && <SalesView />}
      {isCurrentView('logistics') && <LogisticsView />}
      {isCurrentView('warehouse') && <WarehouseView />}
    </div>
  )
}

export default AdmiRolePage