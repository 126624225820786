import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { appConstant, THEME, productOnboardStatus } from "Util/constants";
import { useParams, useLocation } from "react-router-dom";
import { VendorCardHeader } from "./components/SpeciesCard";
import {
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Switch
} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import CommonDiv from "Components/CommonDiv";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import leadingZeroes from "leading-zeroes";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_LOCATION, GET_PRODUCT_DETAILS, GET_TODAY_AUCTION, GET_PRODUCTS } from "Util/queries";
import _ from "lodash";
import { ADD_PRODUCTS} from "Util/mutations";
import { useHistory } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import ChooseUom from "./components/ChooseUom";
import ChooseProduceType from "./components/ChooseProduceType";
import ChooseProductEnvironment from "./components/ChooseProductEnvironment";
import ChooseProduceSubCat from "./components/ChooseProduceSubCat";
import ChooseProduceSizing from "./components/ChooseProduceSizing";
import ChooseProcessCat from "./components/ChooseProcessCat";
import ChooseGenProdPostGrp from "./components/ChooseGenProdPostGrp";
import ChooseInventoryPostingGroup from "./components/ChooseInventoryPostingGroup";
import ChooseGstProdPostGrp from "./components/ChooseGstProdPostGrp";
import ChooseSpecies from "./components/ChooseSpecies";
import moment from "moment-timezone";
import CatchMethodDropdown from "./components/CatchMethodDropdown";
import FormatDropdown from "./components/FormatDropdown";
import { uploadImage } from "Util/imageUploaderS3";
import { v4 as uuidv4 } from 'uuid';


const Container = styled.div``;

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 190px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};
function NewProductPage(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  //const editMode = routeLocation.pathname.includes("/product-list/new/edit");
  const [editMode, setEditMode] = useState(routeLocation.pathname.includes("/product-list/new/edit"))
  const [formLoading, setFormLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [origImg, setOrigImg] = useState(null);
  const [auction, setAuction] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const imageRef = useRef(null)

  const [getLocation] = useLazyQuery(GET_LOCATION, {
    onCompleted(data) {
      const { id, lat, lng, address } = data.user_location[0];
      form.setFieldsValue({
        location_code: leadingZeroes(id, appConstant.leadingZero),
        coordinates: `${lat},${lng}`,
        location_address: address,
      });
    },
  });

  const [getAuctionSched, { data: auction_sched }] = useLazyQuery(GET_TODAY_AUCTION, {
    onCompleted() {
      console.log('auction', auction_sched)
      setAuction(auction_sched?.auction_schedules)
    }
  })

  const [getProduct, { loading, error, data, refetch, networkStatus }] =
    useLazyQuery(GET_PRODUCT_DETAILS,
      {
        onError(error) {
          console.log('error', error)
        },
        onCompleted() {
          const product_info = data.products?.[0]
          setImg(product_info.image)
          setOrigImg(product_info.image)

          setItemCodes({
            product_type: {
              code: product_info.species_type?.code,
              description: product_info.species_type?.description
            },
            product_environment: {
              code: product_info.species_environment?.code,
              description: product_info.species_environment?.description
            },
            product_sub_category: {
              code: product_info.species_sub_category?.code,
              description: product_info.species_sub_category?.description
            },
            label: {
              code: product_info.label,
              description: product_info.label
            },
            product_size: {
              code: product_info.species_sizing?.code,
              description: product_info.species_sizing?.description
            },
            process_category: {
              code: product_info.processCategoryByProcessCategory?.code,
              description: product_info.processCategoryByProcessCategory?.description
            }
          })

          form.setFieldsValue({
            ...product_info,
            process_category: product_info.processCategoryByProcessCategory?.description,
            product_environment: product_info.species_environment?.description,
            product_size: product_info.species_sizing?.description,
            product_sub_category: product_info.species_sub_category?.description,
            product_type: product_info.species_type?.description,
            species_name: product_info.label
          });
        },
      });
  
  const {data: all_products} = useQuery(GET_PRODUCTS, {
        onCompleted() {
            setAllProducts(all_products.products)
        }
  });

  const [add] = useMutation(ADD_PRODUCTS, {
    onCompleted(data) {
      setFormLoading(false);

      const { returning } = data.insert_products

      editMode && notification["success"]({
        message: "Saved!",
        description: "Product Updated!",
      });

      if (!editMode){
        notification["success"]({
          message: "Saved!",
          description: "Product Saved!",
        });
        setEditMode(true)
        getProduct({
          variables: { id: returning[0].id },
        });
        history.push(`/procurement/product-list/new/edit/${returning[0].id}`, { prevPath: '/procurement/product-list'})
      }
    }
  });

  useEffect(() => {
    if (editMode) {
      console.log('querying', params)
      getProduct({
        variables: { id: params.id },
      });
      getAuctionSched({
        variables: {
          date: moment().format("YYYY-MM-DD")
        }
      })
    } else {
      form.setFieldsValue({
        partner_type: params.type,
      });
    }
  }, []);

  const submitHandler = async (values) => {
    setFormLoading(true);
    // const species_cost_posting = { ...values.species_cost_posting }
    // const species_inventories = { ...values.species_inventories }
    // const species_item_trackings = { ...values.species_item_trackings }
    // const species_plannings = { ...values.species_plannings }
    // const species_pricing_and_sales = { ...values.species_pricing_and_sales }
    // const species_replenishments = { ...values.species_replenishments }
    // const species_warehouses = { ...values.species_warehouses }

    // delete values['species_cost_posting']
    // delete values['species_inventories']
    // delete values['species_item_trackings']
    // delete values['species_plannings']
    // delete values['species_pricing_and_sales']
    // delete values['species_replenishments']
    delete values['species_name']

    values.process_category = itemCodes.process_category.code
    values.product_environment = itemCodes.product_environment.code
    values.product_size = itemCodes.product_size.code
    values.product_sub_category = itemCodes.product_sub_category.code
    values.product_type = itemCodes.product_type.code
    if (warningMessage){
      values.id = warningMessage.id ?? undefined
    }

    let image = origImg || img;

    if (!_.isUndefined(imageRef.current.files[0])) {
      const file = imageRef.current.files[0];
      let existingImageId = null;

      if(editMode && origImg){
        const parts = origImg.split('/')
        const lastPart = parts[parts.length - 1]
        existingImageId = lastPart.replace(/\.(png|jpg)$/, "")
      }
      const imageId = existingImageId ?? uuidv4()

      const postData = {
        filename: `${imageId}.png`,
        directory: 'products'
      };
      
      await uploadImage(postData, file)
      image = `https://assets.catch-value.com/products/${imageId}.png`
    }

    add({
      variables: {
        update_columns: ["id", ...Object.keys(values), "image"],
        objects: [
          {
            ...values,
            image,
            onboard_status: productOnboardStatus.PENDING
          },
        ],
      },
    });

  };

  const headerEvents = (eventName) => {
    refetch()
  }

  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const cancelHandler = () => {
    history.push(`/procurement/product-list`)
  }

  const [state, setState] = useState({
    showKg: false,
    showProduceType: false,
    showProduceEnvironment: false,
    showProductSubCat: false,
    showProductSizing: false,
    showProcessCat: false,
    showInventoryPostGrp: false,
    showSpecies: false,
    chosenSpecies: null
  })

  const [itemCodes, setItemCodes] = useState({
		product_type: {
      code: '',
      description: ''
    },
		product_environment: {
      code: '',
      description: ''
    },
		product_sub_category: {
      code: '',
      description: ''
    },
		label: {
      code: '',
      description: ''
    },
		product_size: {
      code: '',
      description: ''
    },
		process_category: {
      code: '',
      description: ''
    }
	})

  const [warningMessage, setWarningMessage] = useState()

  const showModal = (field) => {
    switch (field) {
      case "unit_of_measurement":
        setState({ ...state, showKg: true, fieldKey: 'unit_of_measurement' })
        break;
      case "purchase_unit_of_measure":
        setState({ ...state, showKg: true, fieldKey: ['species_replenishments', 'purchase_unit_of_measure'] })
        break;
      case "product_type":
        setState({ ...state, showProduceType: true })
        break;
      case "product_environment":
        setState({ ...state, showProduceEnvironment: true })
        break;
      case "product_sub_category":
        setState({ ...state, showProductSubCat: true })
        break;
      case "product_sizing":
        setState({ ...state, showProductSizing: true })
        break;
      case "process_category":
        setState({ ...state, showProcessCat: true })
        break;
      case "gen_prod_posting_group":
        setState({ ...state, showGenProdPostGrp: true })
        break;
      case "inventory_posting_group":
        setState({ ...state, showInventoryPostGrp: true })
        break;
      case "gst_group_code":
        setState({ ...state, showGstProdPostGrp: true })
        break;
      case "species_id":
        setState({ ...state, showSpecies: true })
        break;
      default:
        break;
    }
  }

  const setFieldValue = (key, value, code) => {
    if (!Array.isArray(key)) form.setFieldsValue({ ...form.getFieldsValue(), [key]: value })
    else form.setFieldsValue({
      ...form.getFieldsValue(),
      [key[0]]: {
        ...form.getFieldsValue()?.[key[0]],
        [key[1]]: value
      }
    })

    if (code !== undefined){
      let str = ''
      if (!Array.isArray(code)){
        setItemCodes({ ...itemCodes, [key]: {
          code,
          description: value
        }})
        const newItemCode = { ...itemCodes, [key]: {
          code,
          description: value
        }}
        
        const codeValues = Object.values(newItemCode).map(({code}) => code?.slice(0, 3).toUpperCase());
        str = codeValues.filter(x => x !== '' && x !== undefined).join('-');
        form.setFieldsValue({ sku: str })
      } else {
        const keys = Object.keys(itemCodes);
        const newObject = {};
        for (let i = 0; i < keys.length; i++) {
          let prop = keys[i]
          newObject[prop] = {}
          newObject[prop].code = code[i]?.code ? code[i].code : code[i]
          newObject[prop].description = code[i]?.description ? code[i].description : code[i]
        }
        setItemCodes(newObject)
        
        const codeValues = Object.values(newObject).map(({code}) => code?.slice(0, 3).toUpperCase());
        str = codeValues.filter(x => x && x !== '').join('-');
        form.setFieldsValue({ sku: str })
        setWarningMessage(str)
      }
      handleItemCodeValidation(str)
    }
  }

  const handleInputChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImg(URL.createObjectURL(file))
    }

  }

  const onClickHere = (itemCodeId) => {
    history.push(`/procurement/product-list/new/edit/${itemCodeId}`, { prevPath: '/procurement/product-list' } )
    window.location.reload()
  }

  const handleItemCodeValidation = (currentItemCode) => {

    const previousItemCode = data?.products?.[0].sku ?? null
    const itemCodeId = allProducts.find(obj => obj.sku === currentItemCode)?.id ?? null;

    let compare = previousItemCode === currentItemCode


    if(!editMode){
      if(itemCodeId && !compare){
        setWarningMessage({
          id: itemCodeId,
          help: <span>This product already exists. Click <a onClick={() => onClickHere(itemCodeId)}>here</a> to update this product instead.</span>,
          validateStatus: 'warning'
        })
      }
      else if (!itemCodeId){
        setWarningMessage({})
      }
    }

    if (editMode){
      if(!itemCodeId && !compare){
        setWarningMessage({
          id: null,
          help: <span>Item code is updated. This will be saved as a new product.</span>,
          validateStatus: 'warning'
        })
      }
      else if(itemCodeId && !compare){
        setWarningMessage({
          id: itemCodeId,
          help: <span>This product already exists. Click <a href={editMode ? itemCodeId : `edit/${itemCodeId}`}>here</a> to update this product instead.</span>,
          validateStatus: 'warning'
        })
      }
    }
  }
  
  return (
    <Container>
      <ChooseUom state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProduceType state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProductEnvironment state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProduceSubCat state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProduceSizing state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProcessCat state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseGenProdPostGrp state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseInventoryPostingGroup state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseGstProdPostGrp state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseSpecies state={state} setState={setState} setFieldValue={setFieldValue} setImage={setImg}/>
      <VendorCardHeader editMode={editMode} label={`${data?.products[0].sku} | ${data?.products[0].label}`} onboardStatus={data?.products[0].onboard_status} pid={data?.products[0].id} onEvent={headerEvents}/>

      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="General">
          <div className="division">
            <Form.Item style={{ display: 'none' }} label="id" name={"id"} >
              <Input />
            </Form.Item>
            <Form.Item label="Item Code" name="sku"
              help = {warningMessage?.help}
              validateStatus = {warningMessage?.validateStatus}
            >
              <Input readOnly placeholder="Auto generated" />
            </Form.Item>
            <Form.Item label="Item Description" name="label" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="status"
              label="Blocked"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch />
            </Form.Item>
            <Form.Item label="Item Type" name="category">
              <Select>
                <Select.Option value="inventory">
                  Inventory
                </Select.Option>
                <Select.Option value="service">
                  Service
                </Select.Option>
                <Select.Option value="non-inventory">
                  Non-inventory
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item style={{ display: 'none' }} label="Species" name="species_id">
              <Input />
            </Form.Item>
            <Form.Item label="Species" name="species_name" rules={[{ required: true, message: '' }]}>
              <Input onClick={() => showModal("species_id")} />
            </Form.Item>
            <Form.Item label="Remarks" name="remarks">
              <TextArea />
            </Form.Item>
          </div>

          <div className="division">
            <Form.Item
              label="Unit of measurement"
              name="unit_of_measurement"
              rules={[{ required: true, message: '' }]}
            >
              <Input onClick={() => showModal("unit_of_measurement")} />
            </Form.Item>
            <Form.Item value="ok" label="Produce Type" name="product_type" rules={[{ required: true, message: '' }]} >
              <Input onClick={() => {showModal("product_type")}} />
            </Form.Item>
            <Form.Item
              label="Produce Environment"
              name="product_environment" rules={[{ required: true, message: '' }]}
            >
              <Input onClick={() => showModal("product_environment")} />
            </Form.Item>
            <Form.Item
              label="Product Sub Category"
              name="product_sub_category" rules={[{ required: true, message: '' }]}
            >
              <Input onClick={() => showModal("product_sub_category")} />
            </Form.Item>
            <Form.Item label="Produce Sizing" name="product_size" rules={[{ required: true, message: '' }]}>
              <Input onClick={() => showModal("product_sizing")} />
            </Form.Item>
            <Form.Item
              label="Common Item No."
              name="common_item_no"
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="Process Category"
              name="process_category"
              rules={[{ required: true, message: '' }]}
            >
              <Input onClick={() => showModal("process_category")} />
            </Form.Item>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 100 }}>Add Pictures</div>
              <div style={{ display: "flex" }}>
                {img && <img src={img} alt="" style={{ height: 70, width: 70, marginRight: 10 }} />}
                <label for="upload-photo" style={{ display: "flex", backgroundColor: "#D9E3F3", alignItems: "center", justifyContent: "center" }}>
                  <PlusOutlined style={{ fontSize: 70, color: "#90ABDD" }} size={100} color="red" />
                </label>
                <input type="file" ref={imageRef} onChange={handleInputChange} id="upload-photo" style={{ display: "none" }} />
              </div>
            </div>

          </div>
        </Section>

        <Section title="Quotas and Reserve Price">
          <div className="division">
            <Form.Item label="Day Quota" name="quota">
              <Input placeholder="0.00" />
            </Form.Item>
            <Form.Item label="Cum Day Purchased" name="cum_day_purchased">
              <Input placeholder="0.00" />
            </Form.Item>
          </div>
          {editMode && (
            <div className="division">
              <div className="header">
                <span>Auction Quota:</span>
                <span>Quota</span>
                <span>Reserve Price</span>
              </div>
              {auction && auction.map(item => (
                <div className="header" style={{ justifyContent: "space-between" }} key={`auction-id-${item.id}`}>
                  <span>Auction {item.auction_number}</span>
                  <Input disabled={item?.status === 'closed'}
                    placeholder={item.status === 'closed' ? "CLOSED" : "0.00"} style={{ width: '40%' }} />
                  <Input disabled={item?.status === 'closed'}
                    placeholder={item.status === 'closed' ? "CLOSED" : "0.00"} style={{ width: '40%' }} />

                </div>
              ))}
            </div>

          )}
        </Section>
        
        <Section title="Specification">
          <div className="division">
            <Form.Item label="Format" name={['specifications', 'format']} rules={[{ required: true, message: '' }]}>
              <FormatDropdown/>
              {/* <Input placeholder="0.00" /> */}
            </Form.Item>
            <Form.Item label="Scientific Name" name={['specifications', 'scientific_name']} rules={[{ required: true, message: '' }]}>
              <Input placeholder="0.00" />
            </Form.Item>

            <Form.Item label="Temperature (°C)">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Form.Item
                  name={['specifications', "min_temp"]}
                  noStyle
                  rules={[{ required: true, message: '' }]}
                >
                  <Input placeholder="0.0" style={{ width: '40%' }} />
                </Form.Item>
                <span style={{ margin: '0 10px' }}>to</span>
                <Form.Item
                  name={['specifications', "max_temp"]}
                  noStyle
                  rules={[{ required: true, message: '' }]}
                >
                  <Input placeholder="0.0" style={{ width: '40%' }} />
                </Form.Item>
              </div>
            </Form.Item>
            
          </div>

          <div className="division">
            <Form.Item label="Weight Range per piece (g)">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Form.Item
                  name={['specifications', "min_weight"]}
                  noStyle
                  rules={[{ required: true, message: '' }]}
                >
                  <Input placeholder="0.0" style={{ width: '40%' }} />
                </Form.Item>
                <span style={{ margin: '0 10px' }}>to</span>
                <Form.Item
                  name={['specifications', "max_weight"]}
                  noStyle
                  rules={[{ required: true, message: '' }]}
                >
                  <Input placeholder="0.0" style={{ width: '40%' }} />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item label="Max time from Harvest (hr)" name={['specifications', "time_harvest"]} rules={[{ required: true, message: '' }]}>
              <Input placeholder="0.00" />
            </Form.Item>
            <Form.Item label="Catch Method" name="harvest_method" rules={[{ required: true, message: '' }]}>
              <CatchMethodDropdown/>
              {/* <Input placeholder="0.00" /> */}
            </Form.Item>
          </div>

        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit" disabled={warningMessage?.id}>
            {editMode ? "Update" : "Save"}
          </ButtonLoader>
          <CommonButton className='reverse' onClick={cancelHandler} >Cancel</CommonButton>
        </CommonDiv>
      </Form>
    </Container>
  );
}

NewProductPage.propTypes = {};

export default NewProductPage
