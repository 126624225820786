import React, { useState } from 'react'
import { Dropdown, Menu, notification, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { approvalStatus, approvalType, productApprovalStatus } from 'Util/constants';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_ADMIN_APPROVAL, UPDATE_PRODUCT } from 'Util/mutations';
import _ from 'lodash'
import User from 'Hooks/user';



function ProductApproveActions({ ids }) {

    const [lbl, setLbl] = useState("Pending")

    const [updateProduct] = useMutation(UPDATE_PRODUCT)

    const { user_id } = User()

    const [update] = useMutation(UPDATE_ADMIN_APPROVAL, {
        onCompleted(data) {
            notification.success({
                message: "Status updated!"
            })
        }
    })

    const onAction = async (status) => {

        await update({
            variables: {
                where: { id: { _eq: ids.request_id } },
                _set: { status, approver_id: user_id }
            }
        })

        if (status === 'approved') { 
            updateProduct({
                variables: { 
                    id: ids.user_id, 
                    object: { 
                        onboard_status: status 
                    }
                } 
            })
        }

        const res = _.find(productApprovalStatus, ['value', status])
        setLbl(res.label)
    }

    const menu = (
        <Menu >
            {productApprovalStatus.map(v => {
                return <Menu.Item key={v.value} onClick={() => onAction(v.value)} >{v.label}</Menu.Item>
            })}
        </Menu >
    );

    return (
        <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
                <Space> {lbl} <DownOutlined /> </Space>
            </a>
        </Dropdown>
    )
}

export default ProductApproveActions