import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { THEME } from "Util/constants";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import IconPurchaseInvoice from "Components/Icons/IconPurchaseInvoice";
import IconPurchaseCreditMemo from "Components/Icons/IconPurchaseCreditMemo";
import IconPurchaseOrder from "Components/Icons/IconPurchaseOrder";
import IconPurchaseQuote from "Components/Icons/IconPurchaseQuote";
import IconPurchaseReturnOrder from "Components/Icons/IconPurchaseReturnOrder";
import salesPriceIcon from "../../../components/images/sales-price.png";
import salesDiscountsIcon from "../../../components/images/sales-discounts.png";
import salesPriceDiscountsOverviewIcon from "../../../components/images/sales-price-discounts-overview.png";

const menu = (
	<Menu>
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={salesPriceIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>Sales Prices</span>
			</a>
		</Menu.Item>
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={salesDiscountsIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>Sales Discounts</span>
			</a>
		</Menu.Item>{" "}
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={salesPriceDiscountsOverviewIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>
					Sales Prices & Discounts Overview
				</span>
			</a>
		</Menu.Item>{" "}
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={salesPriceDiscountsOverviewIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>Sales Price Worksheet</span>
			</a>
		</Menu.Item>
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={salesPriceIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>Purchase Prices</span>
			</a>
		</Menu.Item>
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={salesDiscountsIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>Purchase Discounts</span>
			</a>
		</Menu.Item>{" "}
		<Menu.Item>
			<a target="_blank" href="#">
				{" "}
				<img
					src={salesPriceDiscountsOverviewIcon}
					height={20}
					width={20}
					style={{ objectFit: "contain" }}
				/>
				<span style={{ marginLeft: 6 }}>
					Purchase Prices & Discounts Overview
				</span>
			</a>
		</Menu.Item>
	</Menu>
);

const Container = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;

	.anticon {
		color: ${THEME.colors.primary};
		margin-left: 5px;
	}
	label {
		font-weight: bold;
	}
	.ant-space-item {
		color: #000;
		&:first-child {
			font-weight: bold;
		}
	}
`;

function PricesAndDiscounts(props) {
	return (
		<Container>
			<Dropdown overlay={menu} trigger={["click"]}>
				<a onClick={(e) => e.preventDefault()}>
					<Space>
						Prices & Discounts
						<DownOutlined />
					</Space>
				</a>
			</Dropdown>
		</Container>
	);
}

PricesAndDiscounts.propTypes = {};

export default PricesAndDiscounts;
