import { gql } from "@apollo/client";

const SUB_AUCTIONS = gql`subscription AuctionList($where: auctions_bool_exp = {}) {
	auctions(order_by: {updated_at: asc}, where: $where) {
	  id
	  status
	  created_at
	  bids {
		id
		price
		volume
		rem_quota
		product_id
	  }
	}
  }`

// const SUB_AUCTIONS = gql`
// 	subscription AuctionList {
// 		auctions(order_by: { updated_at: desc }) {
// 			bid_specs
// 			duration
// 			id
// 			price
// 			status
// 			volume
// 			created_at
// 			product {
// 				species {
// 					description
// 					label
// 					images {
// 						label
// 						url
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

/** TODO: remove later */
const SUB_BIDS = gql`
	subscription BidList {
		bids(
			where: {
				user: { user_type: { label: { _eq: "Mobile" } } }
				auction: { status: { _neq: "closed" } }
			}
		) {
			id
			auction_id
			accept_condition
			accept_spec
			price
			status
			user_id
			volume
			rem_quota
			created_at
			user {
				id
				user_location {
					label
					lat
					lng
					id
				}
			}
			product {
				id
				volume
				price
				species {
					label
				}
			}
		}
	}
`;

/** TODO: remove later */
const SUB_BIDS_ARCHIVED = gql`
	subscription BidListArchive {
		bids(
			where: {
				user: { user_type: { label: { _eq: "Mobile" } } }
				auction: { status: { _eq: "closed" } }
			}
		) {
			id
			auction_id
			accept_condition
			accept_spec
			price
			status
			user_id
			volume
			rem_quota
			counter
			created_at
			user {
				id
				user_location {
					label
					lat
					lng
					id
				}
			}
			product {
				id
				volume
				price
				species {
					label
				}
			}
		}
	}
`;

const SUB_BIDS_LIST = gql`subscription MyQuery($where: bids_bool_exp = {user: {user_type: {label: {_eq: "Mobile"}}}}) {
	bids(where: $where, order_by: {created_at: asc}) {
	  created_at
	  auction_id
	  id
	  volume
	  price
	  rem_quota
	  status
	  purchase_orders {
		id
		status
	  }
	  pickup_orders {
		id
		exp_unit_rev
	  }
	  user {
		id
		user_location {
		  label
		}
	  }
	  product {
		label
		log_cost
		price
	  }
	  tac
	}
  }
`

const BIDS_LIST = gql`subscription BidList($status: String_comparison_exp = {_neq: "closed"}) {
	bids(where: {user: {user_type: {label: {_eq: "Mobile"}}}, auction: {status: $status}}) {
	  id
	  auction_id
	  accept_condition
	  accept_spec
	  price
	  status
	  user_id
	  volume
	  rem_quota
	  created_at
	  tac
	  user {
		id
		user_location {
		  label
		  lat
		  lng
		  id
		}
	  }
	  product {
		id
		volume
		price
		label
		species {
		  label
		}
		log_cost
	  }
	  pickup_orders {
		id
		exp_unit_rev
	}
	}
  }
  `

const SUB_USERS = gql`
	subscription SubUsers($status: String!) {
		users(order_by: { id: asc }, where: { status: { _eq: $status } }) {
			aadhaar_id
			email
			mobile
			name
			vessel
			id
			verification_id
			user_language {
				label
				id
			}
			user_type {
				id
				label
			}
			user_location {
				label
				id
			}
			tag_ids
			channel_ids
		}
	}
`;

const SUB_USER_SEGMENTS = gql`
	subscription SubUserSegments {
		user_segments {
		country
		group
		id
		label
		language
		location
		status
		tags
		}
	}
`;

const SUB_USER_CHANNELS = gql`
	subscription SubUserChannels {
	user_channels {
	  id
	  label
	}
  }
`;



const SUB_LOCATIONS = gql`
	subscription UserLocations {
		user_location {
			id
			label
			lat
			lng
			address
			notes
		}
	}
`;

const SUB_SPECIES = gql`
	subscription ProductSpecies {
		product_species {
			id
			image
			label
			description
		}
	}
`;

export const SPECIES = gql`
	subscription ProductSpecies {
		species {
			id
			image
			label
			bid_specs
      sku
      species
		}
	}
`;

const SUB_PRODUCTS = gql`
subscription ProductList($where: products_bool_exp = {}) {
	products(order_by: {label: asc}, where: $where) {
	  id
	  label
	  species_id
	  volume
	  price
	  bid_specs
	  status
	  quota
	  sku
	  category
	  preservation_method
	  country_origin
	  harvest_method
	  is_rejected
	  uom
	  auction_quota
	  cum_day_purchased
	  species {
		id
		sku
		image
		label
		description
	  }
	  bids {
		id
		price
		volume
		auction_id
	  }
	}
  }
  
`;

const SUB_LANGUAGE = gql`
	subscription MySubscription {
		user_language {
			id
			label
			code
		}
	}
`;

const SUB_LATEST_AUCTION = gql`
	subscription SubLatestAuction {
		auctions(limit: 1, order_by: { id: desc }) {
			id
			status
			time
		}
	}
`;

const SUB_PRODUCT_DAILY_QUOTA = gql`
	subscription MyDailyQuota($from: timestamptz!) {
		products {
			quota
			label
			price
			bids_aggregate(
				where: {
					status: { _eq: "accepted" }
					created_at: { _gte: $from }
				}
			) {
				aggregate {
					sum {
						volume
					}
				}
			}
		}
	}
`;

const SUB_ADMIN_USERS = gql`
	subscription AdminUsers {
		admin_user(order_by: { id: asc }) {
			id
			email
			full_name
			status
			username
			admin_user_role {
				name
				id
			}
		}
	}
`;

const SUB_ADMIN_USER_ROLES = gql`
	subscription sub_admin_user_roles {
		admin_user_role(order_by: { id: asc }) {
			id
			name
			description
			modules
		}
	}
`;

const SUB_ADMIN_MODULE = gql`
	subscription sub_admin_module {
		admin_modules(order_by: { id: asc }) {
			id
			name
			type
			description
		}
	}
`;

const SUB_DIRECT_OFFERS = gql`
	subscription MyDirectOffers($where: direct_offers_bool_exp = {}) {
		direct_offers(where: $where) {
			id
			active
			category
			contact_name
			contact_number
			country_origin
			created_at
			description
			harvest_method
			min_quantity
			avail_quantity
			offer_type
			preservation_method
			price
			quantity
			species_name
			status
			unit
			updated_at
			user_id
			image
			order_quantity
			species_sku
			purchase_order {
				id
			}
			user_info {
				user_location {
					label
					address
					lat
					lng
				}
			}
		}
	}
`;

const SUB_DIRECT_OFFER_ORDERS = gql`
	subscription MySubscription($where: direct_offer_orders_bool_exp = {}) {
		direct_offer_orders(where: $where) {
			id
			order_quantity
			status
			created_at
			purchase_order {
				id
			}
			direct_offer {
				id
				active
				category
				contact_name
				contact_number
				country_origin
				created_at
				description
				harvest_method
				min_quantity
				avail_quantity
				offer_type
				preservation_method
				price
				quantity
				species_name
				status
				unit
				updated_at
				user_id
				image
				user_info {
					user_location {
						label
						address
					}
				}
			}
		}
	}
`;

// const SUB_VENDORS = gql`subscription SubVendors($where: users_bool_exp = {}) {
//   users(order_by: {id: asc}, where: $where) {
//     aadhaar_id
//     email
//     mobile
//     name
//     status
//     vessel
//     id
//     verification_id
//     user_language {
//       label
//       id
//     }
//     user_type {
//       id
//       label
//     }
//     user_location {
//       label
//       id
//     }
//   }
// }`

const SUB_VENDORS = gql`
	subscription MyVendorSubscription($where: vendor_details_bool_exp = {}) {
		vendor_details(where: $where, order_by: { user: { id: asc } }) {
			id
			address1
			address2
			cash_flow_terms
			city
			contact_person_name
			contact_person_num
			coordinates
			country
			creditor_number
			gst_include
			gst_registration
			lead_time_calculation
			location_address
			location_code
			partner_type
			payment_code
			payment_terms
			phone
			postcode
			prefered_bank
			shipment_method_code
			virtual_payment_address
			wallet_accountt_number
			deleted_at
			user {
				aadhaar_id
				username
				email
				mobile
				name
				status
				vessel
				id
				verification_id
				onboard_code
				user_language {
					label
					id
				}
				user_type {
					id
					label
				}
				user_location {
					label
					id
				}
				channel_ids
			}
		}
	}
`;

export const SUB_PRODUCTS_PROCUREMENT = gql`
	subscription MyProductSubscription($where: products_bool_exp) {
		products(where: $where, order_by: { label: asc }) {
			id
			label
			volume
			price
			bid_specs
			status
			sku
			category
			preservation_method
			country_origin
			harvest_method
			is_rejected
			uom
			unit_of_measurement
			product_type
			product_environment
			product_sub_category
			product_size
			process_category
			common_item_no
			blocked
			quota
			image
			cum_day_purchased
			species_environment {
				id
				code
				description
			}
		}
	}
`

export const SUB_SPECIES_PROCUREMENT = gql`
	subscription MyProductSubscription($where: species_bool_exp) {
		species(where: $where, order_by: { label: asc }) {
			id
			label
			volume
			price
			bid_specs
			status
			sku
			category
			preservation_method
			country_origin
			harvest_method
			is_rejected
			uom
			unit_of_measurement
			species_type
			species_environment
			species_sub_category
			species_size
			process_category
			common_item_no
			blocked
      		image
			description
			localizations
			species
			remarks
			species_inventories {
				id
				location_no
				inventory
				quantity_on_asm_component
				stock_out_warning
				quantity_on_purchase_order
				quantity_on_sales_order
				quantity_on_job_order
				quantity_on_assembly_order
				net_weight
				gross_weight
				unit_volume
			}
			species_cost_postings {
				costing_method
				standard_cost
				unit_cost
				indirect_cost_percentage
				last_direct_cost
				net_invoiced_quantity
				gen_prod_posting_group
				inventory_posting_group
				default_deferral_template
				gst_group_code
				gst_credit
				hsn_sac_code
				is_cost_adjusted
				is_cost_posted_to_gl
				purchase_prices_discounts
				is_exempted
				is_subcontracting
				sub_contract_location
				tariff_no
				country_of_origin_code
				id
			}
			species_pricing_and_sales {
				id
				unit_price
				unit_price_excl_gst
				is_price_includes_gst
				price_profit_calculation
				profit_percentage
				sales_prices_and_discounts
				is_allow_invoice_discounts
				item_discount_group
				sales_unit_of_measure
				is_sales_blocked
			}
			species_replenishments {
				id
				replenishment_system
				lead_time_calculation
				vendor_no
				vendor_item_no
				purchase_unit_of_measure
				overhead_rate
				assembly_policy
				assembly_bom
				is_purchasing_blocked
			}
			species_plannings {
				reordering_policy
				reserve
				reorder_point
				order_tracking_policy
				dampener_period
				is_critical
				safety_lead_time
				safety_stock_quantity
				is_include_inventory
				lot_accumulation_period
				rescheduling_period
				reorder_quantity
				maximum_inventory
				overflow_level
				time_bucket
				minimum_order_quantity
				maximum_order_quantity
				order_multiple
				id
			}
			species_item_trackings {
				id
				item_tracking_code
				serial_no
				lot_no
				expiration_calculation
			}
			species_warehouses {
				id
				warehouse_class_code
				special_equipment_code
				putaway_template_code
				putaway_unit_of_measure
				phys_invt_counting_period
				last_phys_invt_date
				last_counting_period_update
				next_counting_start_date
				next_counting_end_date
				is_use_cross_docking
			}
			species_sizing_details {
				id
				code
				description
			}
			process_category_details {
				id
				code
				description
			}
			unit_of_measurement_details {
				id
				code
				description
			}
			species_environment_details {
				id
				code
				description
			}
			species_sub_category_details {
				id
				code
				description
			}
			species_type_details {
				id
				code
				description
			}
			process_category_details {
				id
				code
				description				
			}
		}
	}
`;

export const SUB_UNIT_OF_MEASUREMENT = gql`
subscription MyUnitOfMeasurements {
  unit_of_measurements {
    id
    code
    description
	  international_standard_code
  }
}
`
const USER_PROFILE_ROLES = gql`subscription MyUserProfileRolesSub($where: user_roles_bool_exp = {}) {
  user_roles(where: $where) {
    id
    profile_id
    role_id
    source
    display_name
    description
    enabled
    is_default_profile
  }
}`



export const SUB_PRODUCT_TYPE = gql`
subscription MyProductTypes {
  species_type{
    id
    code
    description
  }
}
`
export const SUB_PRODUCT_ENVIRONMENT = gql`
subscription MyProductEnvironment {
  species_environment {
    id
    code
    description
  }
}
`
export const SUB_PRODUCT_SUB_CATEGORY = gql`
subscription MyProductSubCategory {
  species_sub_category{
    id
    code
    description
  }
}
`
export const SUB_PRODUCT_SIZING = gql`
subscription MyProductSizing {
  species_sizing{
    id
    code
    description
  }
}
`
export const SUB_PROCESS_CATEGORY = gql`
subscription MyProcessCategory {
  process_category{
    id
    code
    description
  }
}
`
export const SUB_GEN_PROD_POST_GRP = gql`
subscription MyGenProdPostGrp {
  gen_prod_post_group{
    id
    code
    description
  }
}
`
export const SUB_INVENTORY_POSTING_GROUP = gql`
subscription MyInventoryPostingGroup {
  inventory_posting_group{
    id
    code
    description
  }
}
`
export const SUB_GST_POSTING_GROUP = gql`
subscription MyGstPostingGroup {
  gst_prod_post_group{
    id
    code
    description
  }
}
`
const ADMIN_USERS = gql`subscription AdminUsers($where: admin_user_bool_exp = {}) {
	admin_user(order_by: {id: asc}, where: $where) {
	  id
	  email
	  full_name
	  status
	  username
	  default_profile
	  is_pending
	  admin_user_groups{
		user_group{
		  id
		  code
		  name
		}
	  }
	  admin_user_roles{
		user_role{
		  id
		  display_name
		  description
		}
	  }
	}
  }`

const ADMIN_USER_GROUPS = gql`subscription userGroupsSub($where: user_groups_bool_exp = {}) {
  user_groups(where: $where) {
    id
    name
    code
    user_role {
      id
      profile_id
      display_name
      role_id
    }
  }
}`

const ADMIN_APPROVALS = gql`subscription MySubscription($where: approvals_bool_exp = {}) {
	approvals(where: $where, order_by: {created_at: desc}) {
	  id
	  created_at
	  approval_request_type
	  status
	  workflow
	  approver_role
	  ref_id
	  role{
		display_name
	  }
	  requester {
		id
		full_name
	  }
	}
  }
  `

const COLLECTION_LOCATIONS = gql`subscription LocationPointsSub($where: collection_locations_bool_exp = {}) {
	collection_locations(where: $where, order_by: {location_code: asc}) {
	  id
	  is_active
	  lat
	  lng
	  location_code
	  location_name
	  location_type
	  postal_code
	  address_1
	  address_2
	  state
	  city
	  country
	  google_map_url
	}
  }
  `

const SUB_AUCTION_SCHEDULES = gql`subscription MyScheduleSubscription($where: auction_schedules_bool_exp = {}, $order_by: [auction_schedules_order_by!] = {}) {
	auction_schedules(where: $where, order_by: $order_by) {
	  auction_number
	  date
	  datetime
	  id
	  settings
	  status
	  active
	  total_duration
	}
  }
`

const SUB_AUCTION_SCHEDULES_SETTINGS = gql`subscription MyAuctionScheduleSettings($where: auction_schedule_settings_bool_exp = {}, $order_by: [auction_schedule_settings_order_by!] = {}) {
	auction_schedule_settings(where: $where, order_by: $order_by) {
	  results_duration
	  repeat
	  recurring
	  pre_bid_duration
	  id
	  datetime
	  date
	  counter_offer_duration
	  auction_time
	  auction_duration
	  total_duration
	  end_date
	  override
	  reference_id
	}
  }`

const PURCHASE_ORDERS_LIST = gql`subscription PurchageOrdersList($where: purchase_orders_bool_exp!) {
	purchase_orders(where: $where, order_by: {created_at: asc}) {
		id
		status
		adjustment_value
		created_at
		bid_id
		bid {
			id
			auction_id
			accept_condition
			accept_spec
			price
			status
			user_id
			volume
			rem_quota
			created_at
			product {
				id
				volume
				price
				log_cost
				sku
				species {
					label
				}
				image
				label
			}
		}
		user {
			id
			user_location {
				label
				lat
				lng
				id
			}
		}
		direct_offer_order {
			created_at
			updated_at
			direct_offer_id
			id
			order_quantity
			status
			updated_at
			direct_offer {
			  price
			  avail_quantity
			  category
			  contact_name
			  contact_number
			  country_origin
			  created_at
			  description
			  end_datetime
			  expiry
			  harvest_method
			  id
			  image
			  min_quantity
			  offer_type
			  order_quantity
			  preservation_method
			  quantity
			  species_name
			  species_sku
			  status
			  unit
			  updated_at
			  user_id
			}
		}
	}
  }`

const DIRECT_SELECTION_LIST = gql`subscription DirectSelectionList($where: custom_products_bool_exp = {}) {
	custom_products(where: $where, order_by: {created_at: desc}) {
		active
		category
		contact_name
		contact_number
		country_origin
		description
		harvest_method
		created_at
		id
		image
		min_quantity
		offer_type
		preservation_method
		price
		quantity
		species_name
		unit
		end_datetime
		expiry
		species_sku
		item_type
		remarks
		species_type
		species_environment
		species_sub_category
		species_size
		common_item_number
		process_category,
		user_id
	}
  }
  `

export const SUB_USER_TAGS = gql`
  subscription UserTagsList {
	user_tags(order_by: {label: desc}) {
	  id
	  label
	  description
	}
  }  
  `

const ADMIN_NOTIFICATIONS = gql`subscription AdminNotifications($where: admin_notifications_bool_exp = {}) {
	admin_notifications(where: $where, order_by: {created_at: asc}) {
	  id
	  title
	  body
	  category
	  cta_link
	  cta_type
	  group
	  read
	  user
	  created_at
	  updated_at
	  admin_notifications_attachments {
		id
		name
		url
		type
	  }
	  poll {
		id
		active
		comments
		option_type
		options
		question
		show_result
		title
		user_segment_id
	  }
	}
  }
  `

const POLLS = gql`subscription Polls($where: polls_bool_exp = {}) {
	polls(where: $where, order_by: {created_at: asc}) {
	  active
	  comments
	  created_at
	  created_by
	  expiry_date
	  id
	  option_type
	  options
	  question
	  show_result
	  title
	  updated_at
	  user_segment_id
	}
  } 
`
const POLL_RESPONSE = gql`subscription PollResponse($where: poll_response_bool_exp = {}) {
	poll_response(where: $where) {
	  id
	  poll_id
	  response
	  user_id
	  admin_user_id
	}
  } 
`

const POLL_COMMENTS = gql`subscription PollComments($where: poll_comments_bool_exp = {}) {
	poll_comments(where: $where, order_by: {created_at: asc}) {
		comment
		id
		poll_id
		poll_comment_replies(order_by: {created_at: asc}) {
		  reply
		  id
		  comment_id
		  admin_user {
			full_name
		  }
		  admin_user_id
		}
	}
  }
`
const POLL_COMMENT_REPLIES = gql`subscription PollCommentReplies($where: poll_comment_replies_bool_exp = {}) {
	poll_comment_replies(where: $where) {
	  reply
	  id
	  poll_comment {
		comment
		id
		poll_id
	  }
	}
  }
`

const ADMIN_NOTIFICATIONS_RECIPIENT = gql`subscription AdminNotificationRecipient($where: admin_notifications_recipient_bool_exp = {}) {
	admin_notifications_recipient(where: $where, order_by: {id: asc}) {
	  id
	  read	
	  admin_notification {
		category
		title
		body
		created_at
		id
		admin_notifications_attachments {
			id
			name
			type
			url
		}
		poll {
		id
		active
		comments
		option_type
		options
		question
		show_result
		title
		user_segment_id
		}
	  }
	}
  }  
`

const SUB_JOBS = gql`subscription JobsSubscription($where: jobs_bool_exp = {}) {
  jobs(where: $where, order_by: {id: asc}) {
	id
    address
    address_zone
    barcode
    client
    cod
    contact_name
    date
    email
    job_status
    note
    phone
    service_time
    shipper_id
    time_from
    time_to
    type
	depot_id
	depot {
		id
		name
	}
	shipper {
		id
		name
	}
	purchase_orders {
      id
      job_id
      bid {
        price
        volume
        product {
          sku
          label
        }
      }
	  direct_offer_order {
        order_quantity
		direct_offer {
          species_sku
          species_name
          price
        }
      }
    }
	purchase_orders_aggregate {
      aggregate {
        count
      }
    }
  }
}`

const SUB_DRIVERS = gql`subscription DriversSubscription($where: drivers_bool_exp = {}) {
	drivers(where: $where, order_by: {id: asc}) {
		id
		address1
		address2
		city
		country
		depot_id
		driver_id
		email
		image
		license
		license_expiry
		type
		username
		verification_documents
		uuid
		status
		postal
		phone
		password
		name
		active
		aadhaar_id
	}
  } 
`

const SUB_VEHICLES = gql`subscription VehiclesSubscription($where: vehicles_bool_exp = {}) {
	vehicles(where: $where, order_by: {id: asc}) {
	  id
	  driver_id
	  depot_id
	  carrier_id
	  number
	  start_time
	  status
	  volume
	  weight
	  work_time
	  vehicle_id
	  driver {
		id
		name
	  }
	  depot {
		id
		name
      }
	}
  }  
`

const SUB_ROUTES = gql`subscription RoutesSubscription($where: routes_bool_exp = {}) {
	routes(where: $where, order_by: {id: asc}) {
	  id		
	  code
	  date
	  start_time
	  status
	  driver {
		id
		name
	  }
	  vehicle {
		id
		number
	  }
	  depot {
		street
		city
		state
		country
		code
	  }
	  jobs {
		id
		address
		address_zone
		barcode
		client
		cod
		contact_name
		date
		email
		job_status
		note
		phone
		service_time
		shipper_id
		time_from
		time_to
		type
		depot_id
		depot {
			id
			name
		}
		shipper {
			id
			name
		}
		purchase_orders {
			id
			job_id
			bid {
				price
				volume
				product {
					sku
					label
				}
			}
			direct_offer_order {
				order_quantity
				direct_offer {
					species_sku
					species_name
					price
				}
			}
		}
		purchase_orders_aggregate {
			aggregate {
				count
			}
		}
	  }
	}
  }  
`

const SUB_DEPOTS = gql`subscription DepotsSubscription($where: depots_bool_exp = {}) {
	depots(where: $where, order_by: {id: asc}) {
	  id
	  active
	  city
	  code
	  country
	  google_map_url
	  latitude
	  longitude
	  name
	  state
	  street
	  type
	}
  }
`

const SUB_SHIPPERS = gql`subscription ShippersSubscription($where: shippers_bool_exp = {}) {
	shippers(where: $where, order_by: {code: asc}) {
	  id
	  code
	  name
	}
}`

const SUB_CARRIERS = gql`subscription CarriersSubscription($where: carriers_bool_exp = {}) {
	carriers(where: $where, order_by: {id: asc}) {
		id
		code
		name
		contact_name
		contact_phone
		email
	}
}`


/** TODO: transfer per category the graphQLS */
export {
	SUB_AUCTIONS,
	SUB_BIDS,
	SUB_USERS, /** TODO: replace with vendors later */
	SUB_VENDORS,
	SUB_LOCATIONS,
	SUB_SPECIES,
	SUB_PRODUCTS,
	SUB_LANGUAGE,
	SUB_LATEST_AUCTION,
	SUB_PRODUCT_DAILY_QUOTA,
	SUB_BIDS_ARCHIVED,
	SUB_ADMIN_USERS,
	SUB_ADMIN_USER_ROLES,
	SUB_ADMIN_MODULE,
	SUB_DIRECT_OFFERS,
	SUB_DIRECT_OFFER_ORDERS,
	BIDS_LIST,
	SUB_BIDS_LIST,
	SUB_USER_SEGMENTS,
	SUB_USER_CHANNELS,

	/**User roles */
	USER_PROFILE_ROLES,

	/** Admin Users */
	ADMIN_USERS,

	/** Admin user groups */
	ADMIN_USER_GROUPS,

	/** Admin Approval */
	ADMIN_APPROVALS,

	/** Collection Location */
	COLLECTION_LOCATIONS,

	/** Auction Schedules */
	SUB_AUCTION_SCHEDULES,
	SUB_AUCTION_SCHEDULES_SETTINGS,

	/** purchage orders  */
	PURCHASE_ORDERS_LIST,

	/** custom products */
	DIRECT_SELECTION_LIST,

	/** admin notifications */
	ADMIN_NOTIFICATIONS,
	POLLS,
	POLL_RESPONSE,
	POLL_COMMENTS,
	POLL_COMMENT_REPLIES,
	ADMIN_NOTIFICATIONS_RECIPIENT,

	/** logisitics */
	SUB_JOBS,
	SUB_DRIVERS,
	SUB_VEHICLES,
	SUB_ROUTES,
	SUB_DEPOTS,
	SUB_SHIPPERS,
	SUB_CARRIERS
}
