import { useQuery } from '@apollo/client';
import { Select } from 'antd'
import React, { useState } from 'react'
import { GET_PRODUCTS } from 'Util/queries';
const { Option } = Select;

function ProductDropdown({ onChange }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_PRODUCTS, {
        onCompleted() {
            setList(data.products)
        }
    });

    return (
        <div>
            <Select
                style={{ width: 300 }}
                placeholder="Select a product"
                onChange={onChange}
            >
                { list.map(v=><Option value={v.id} key={`product-dd-opt${v.id}`}>{v.species.label}</Option>) }
            </Select>
        </div>
    )
}

export default ProductDropdown
