import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { SubPageBreadCrumbs } from "Components/BreadCrumbs";
import { useParams } from "react-router-dom";
import { DataTable, Menu } from "./components";
import { useSubscription } from "@apollo/client";
import { SUB_PRODUCTS_PROCUREMENT, SUB_SPECIES_PROCUREMENT } from "Util/subscriptions";
import leadingZeroes from "leading-zeroes";
import { productOnboardStatus } from "Util/constants";
import _ from "lodash";
import { DataTable as QuotaTable} from 'Pages/Procurement/Auction/Quotas/components'

const ActiveProductList = (props) => {
  const params = useParams();
  const [pageState, setPageState] = useState({
    selectedUser: {},
  });

  const getWhere = () => {
    if (!params.type) {
      return {
        status: { _in: [true, false] },
        onboard_status: { _eq: productOnboardStatus.APPROVED }
      }
    } else {
      if (params.type === "rejected") {
        return {
          is_rejected: { _eq: true }
        }
      } else if (params.type === "active") {
        return {
          status: { _eq: true },
          onboard_status: { _eq: productOnboardStatus.APPROVED }
        }
      } else if (params.type === "pending") {
        return {
          onboard_status: { _in: [productOnboardStatus.REVIEW, productOnboardStatus.VERIFIED, productOnboardStatus.PENDING] }
        }
      }else {
        return {
          status: { _eq: false }
        }
      }

    }
  }

  const { data, loading, error } = useSubscription(SUB_PRODUCTS_PROCUREMENT, {
    variables: {
      where: {
        ...getWhere(),
        deleted_at: { _is_null: true }
      }
    },
  });

  console.log('error', error)

  const formatData = (data) => {
    if (loading) {
      return [];
    }

    if (data?.products) {
      return data?.products.map((specie) => ({
        ...specie,
      }));
    }

    return [];
  };

  return (
    <div>
      <SubPageBreadCrumbs />
      <Menu />
      {params.type !== "quotas" ? 
      (<DataTable
        data={loading ? [] : formatData(data)}
        loading={loading}
        pageSize={50}
        height={500}
      />) : <QuotaTable/>}
    </div>
  );
};

ActiveProductList.propTypes = {};

export default ActiveProductList;
