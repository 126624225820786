import { LogisticsDepotsType, THEME } from "Util/constants";
import {
  Form,
  Input,
  notification,
  Select,
  Switch
} from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useLazyQuery, useMutation } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import { UPSERT_DEPOTS } from "Util/mutations";
import { useHistory } from "react-router-dom";
import { Header } from "./components/DepotCard";
import { GET_DEPOTS } from "Util/queries";
import TextArea from "antd/lib/input/TextArea";
import { Country, State, City } from 'country-state-city';

const Container = styled.div``;

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function DepotCard(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  const editMode = routeLocation.pathname.includes("/logistics/depots/edit");
  const [formLoading, setFormLoading] = useState(false);
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [loc, setLoc] = useState({
    state: '',
    country: ''
})

  const [update] = useMutation(UPSERT_DEPOTS)

  const [getDriver, { loading, error, data, refetch, networkStatus }] = useLazyQuery(GET_DEPOTS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
        console.log('datadepot', data);
        const userDetails = data.depots[0]
        const {street, city, state, country} = userDetails
        const address = [street, city, state, country].filter(Boolean).join(", ");
        form.setFieldsValue({
            ...userDetails,
            address
        })
    }
  })

  useEffect(() => {
    if (editMode) {
      console.log('querying...')
      getDriver({ variables: { where: { id: { _eq: params.id } } }, });
    } else {
      setStateList(State.getStatesOfCountry("IN"))
      setCityList(City.getCitiesOfState("IN", "AN"))
      setLoc({ state: "IN", country: "IN" })
    }
  }, [editMode, form, getDriver, params.id]);

  const handleAddressChange = () => {
    const { street, country, state, city } = form.getFieldsValue([
      'street',
      'country',
      'state',
      'city',
    ]);
    const address = [street, city, state, country].filter(Boolean).join(", ");
    form.setFieldsValue({ address });
  };

  const submitHandler = async (values) => {

    setFormLoading(true)

    const { address, ...depotDetails } = values;

    const depotObjects = {
      ...depotDetails
    };

    await update({ variables: { 
      update_columns: ["id", ...Object.keys(depotDetails)],
      objects: [depotObjects] 
      } 
    })

    notification.success({
      message: "Saved!",
      description: "Depots Saved!",
    });

    setFormLoading(false)
    history.goBack()
  };

  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  return (
    <Container>
      <Header editMode={editMode}/>
      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="Depot Details">
          <div className="division">

            <Form.Item label="id" name="id" style={{ display: 'none' }} >
              <Input />
            </Form.Item>

            <Form.Item label="Depot Code" name="code">
              <Input />
            </Form.Item>

            <Form.Item label="Depot Name" name="name">
              <Input />
            </Form.Item>

            <Form.Item label="Depot Type" name="type" initialValue={'collection-hub'}>
              <Select options={LogisticsDepotsType} />
            </Form.Item>

            <Form.Item 
                name="active" 
                label="Active" 
                valuePropName="checked" 
                initialValue={false}
            >
                <Switch />
            </Form.Item>

            <Form.Item label="Street" name="street">
              <Input onChange={handleAddressChange}/>
            </Form.Item>

            <Form.Item label="Full Address" name="address">
              <TextArea rows={2} placeholder="Auto Generated" readOnly/>
            </Form.Item>

            <Form.Item label="Country" name="country" rules={[{ required: true, message: '' }]} initialValue={"India"}>
              <Select showSearch
                  onChange={(countryName, option) => {
                    const isoCode = option.key
                    console.log('isoCode', isoCode);
                    setStateList(State.getStatesOfCountry(isoCode));
                    setLoc({ ...loc, country: isoCode });
                    handleAddressChange();
                  }}
              
              >
                  {Country.getAllCountries().map(v => <Select.Option value={v.name} key={`${v.isoCode}`}>{v.flag} {v.name} ({v.isoCode})</Select.Option>)}
              </Select>
          </Form.Item>
          <Form.Item label="State/Region Name" name="state" rules={[{ required: true, message: '' }]}>
              <Select
                  showSearch
                  onChange={(stateName, option) => {
                      const isoCode = option.key
                      setCityList(City.getCitiesOfState(loc.country, isoCode))
                      handleAddressChange()
                  }}
                  placeholder="Select a State"
              >
                  {stateList.map(v => <Select.Option value={v.name} key={`${v.isoCode}`}>{v.name}</Select.Option>)}
              </Select>
          </Form.Item>
          <Form.Item label="City" name="city" rules={[{ required: true, message: '' }]}>
              <Select
                  showSearch
                  placeholder="Select a City"
                  onChange={handleAddressChange}
              >
                  {cityList.map(v => <Select.Option value={v.name} key={`city-${v.name}`}>{v.name}</Select.Option>)}
              </Select>

          </Form.Item>

            <Form.Item label="Latitude" name="latitude" rules={[{ required: true, message: '' }]}>
              <Input type="number"/>
            </Form.Item>

            <Form.Item label="Longitude" name="longitude" rules={[{ required: true, message: '' }]}>
              <Input type="number"/>
            </Form.Item>


          </div>

          <div className="division">

            <Form.Item label="Google Map URL" name="google_map_url">
              <Input />
            </Form.Item>

          </div>

        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit" >
            {editMode ? "Update" : "Save"}
          </ButtonLoader>

          <CommonButton className='reverse' onClick={() => history.goBack()}>
            Cancel
          </CommonButton>

        </CommonDiv>
      </Form>
    </Container>
  );
}

DepotCard.propTypes = {};

export default DepotCard;
