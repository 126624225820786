import React from 'react'
import { Layout } from 'antd';
import './main.scss'

function CommonLayout({ children }) {
    return (
        <Layout className="pub-layout">
            <Layout.Content style={{ padding: '0 50px' }}>
                <div className="content-wrapper">{children}</div>
            </Layout.Content>
        </Layout>
    )
}

export default CommonLayout