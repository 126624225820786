import { useSubscription, useMutation } from '@apollo/client';
import { notification, Table } from 'antd';
import leadingZeroes from 'leading-zeroes';
import { useRef, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import styled from 'styled-components';
import { getDate, getTimeFromDate } from 'Util/common';
import { appConstant, DIRECT_OFFER_STATUS } from 'Util/constants';
import { SUB_DIRECT_OFFERS } from 'Util/subscriptions';
import { ACCEPT_DIRECT_OFFER, UPDATE_DIRECT_OFFER } from 'Util/mutations';
import { commonColumns, dataSource } from '../../common';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopOverDetails } from '../../components'
import { mapConstant } from 'Util/constants';
import gmap from 'gmap';
import { directSalesFilterAtom } from 'Store/directSalesAtom';
import moment from 'moment'
import _ from 'lodash'
import { TableFilters } from './';

const Container = styled.div``



function DataTable() {

    /** Global State */
    const [filter, setFilter] = useRecoilState(directSalesFilterAtom)
    // const { auctionStatus } = auctionState
    const auctionStatus = 'open'

    /** Local State */
    const [data, setData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const submitMessage = useRef()

    /** GraphQL Hooks */
    const { loading } = useSubscription(SUB_DIRECT_OFFERS, {
        variables: {
            where: {
                active: { _eq: true },
                status: { _in: [DIRECT_OFFER_STATUS.open, DIRECT_OFFER_STATUS.staged] }
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            data && data.direct_offers && setData(data.direct_offers)
        }
    });

    const [updateDirectOffer] = useMutation(UPDATE_DIRECT_OFFER, {
        onCompleted() {
            notification['success']({
                message: submitMessage.current
            });
        },
        onError(e) { console.log('erre', e) }
    });

    const [acceptDirectOffer] = useMutation(ACCEPT_DIRECT_OFFER, {
        onCompleted() { console.log('Direct offer accepted') },
        onError(e) { console.log('erre', e) }
    });

    /** Functions */
    const onSelectChange = (newSelectedRowKeys, data) => {
        // setSelectedRecord(data)
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const filterData = (data) => {
        let dSource = dataSource('open', data)
        const searchable = dSource.map(obj=>({
            ...obj,
            searchText: `${ obj.species_sku } ${ obj.species_name } ${ obj.time } ${obj.id} ${obj.preservation_method} ${obj.country_origin} ${obj.avail_quantity} ${obj.min_quantity} ${obj.harvest_method} ${obj.price} ${obj.offer_type} ${obj.vendor} ${obj.location}`
          }))     

        if(!filter.search){
            return dSource
        }
        return _.filter(searchable, function (d) { 
            let query = filter.search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    }

    const handleSubmit = ({ action, data }) => {

        const { id, order_quantity, avail_quantity, user_id, status, user_info, } = data

        if (action === 'add') {
            submitMessage.current = "Success! Added to Staging basket."
            updateDirectOffer({
                variables: {
                    id,
                    _set: {
                        status: 'staged',
                        order_quantity
                    }
                }
            })

        }

        if (action === 'buy') {

            const link = gmap.directions(mapConstant.pickupCoordinates, `${user_info.user_location.lat},${user_info.user_location.lng}`)
            submitMessage.current = "Order successfully bought!"
            acceptDirectOffer({
                variables: {
                    objects: {
                        eta: 10, /** TODO: eta as editable */
                        user_id,
                        link,
                        purchase_order: {
                            data: {
                                user_id,
                                status: "pending",
                                direct_offer_order: {
                                    data: {
                                        status: "accepted",
                                        order_quantity,
                                        direct_offer_id: id
                                    }
                                }
                            }
                        }
                    }
                }
            })

            updateDirectOffer({
                variables: {
                    id,
                    _set: {
                        avail_quantity,
                        order_quantity: 0,
                        status: (avail_quantity <= 0) ? 'withdrawn' : status
                    }
                }
            })
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        ...commonColumns,
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => {
                return <div className='direct-offer-action-wrapper' >
                    <PopOverDetails data={record} add onSubmit={handleSubmit} >
                        <FontAwesomeIcon
                            size="2x"
                            style={{ "cursor": "pointer" }}
                            icon={faInfoCircle}
                            color='#20B0B5'
                        />
                    </PopOverDetails>
                </div>
            }
        }
    ]

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={columns}/>
            <Table
                loading={loading}
                size='small'
                columns={columns}
                dataSource={filterData(data)}
                // rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 50
                }}
            />
        </Container>
    )
}

export default DataTable    