import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ApprovalDashboardDataTable, ApprovalDashboardTableFilter, Header } from './components'

const Container = styled.div``

export const ApprovalDashboardContext = createContext()

function ApprovalUserDashboard(props) {

    const [selectedRecord, setSelectedRecord] = useState()
    const [filter, setFitler] = useState('')

    return (
        <ApprovalDashboardContext.Provider value={{
            selectedRecord, setSelectedRecord,
            filter, setFitler
        }}>
            <Container>
                <Header title='Approval Dashboard' />
                <ApprovalDashboardTableFilter />
                <ApprovalDashboardDataTable />
            </Container>
        </ApprovalDashboardContext.Provider>
    )
}

ApprovalUserDashboard.propTypes = {}

export default ApprovalUserDashboard
