import { gql } from "@apollo/client";

const ADD_AUCTION = gql`mutation AddAuction($objects: [auctions_insert_input!]!) {
    insert_auctions(objects: $objects) {
      affected_rows
    }
  }`

const UPDATE_BID_STATUS = gql`mutation AcceptBidStatus($id: Int!, $obj: bids_set_input!, $po_object: [purchase_orders_insert_input!]!) {
  
  update_bids(where: {id: {_eq: $id}}, _set: $obj) {
    affected_rows
  }
  
  insert_purchase_orders(objects: $po_object  ){
    returning{
      id
    }
  }
  
}`

const UPDATE_BID_REJECT_STATUS = gql`mutation UpdateBidRejectStatus($id: Int!, $status: String!) {
  update_bids(_set: {status: $status}, where: {id: {_eq: $id}}) {
    returning{
      id
    }
  }
}`

const UPDATE_BULK_BID_REJECT_STATUS = gql`mutation MyMutation($status: String = "", $where: bids_bool_exp!) {
  update_bids(where: $where, _set: {status: $status}) {
    affected_rows
  }
}`

const ADD_PICKUP_ORDER = gql`mutation AddPickupOrder( $objects: [pickup_orders_insert_input!]! ) {
  insert_pickup_orders(objects: $objects){
    affected_rows
  }
}`

const UPDATE_AUCTION_STATUS_BY_ID = gql`mutation UpdateAuctionStatus($id: Int!, $status: String!) {
  update_auctions(where: {id: {_eq: $id}}, _set: {status: $status}) {
    affected_rows
  }
}
`
const ADD_USER = gql`mutation AddUser($email: String = "", $aadhaar_id: String = "", $language_id: Int = 10, $location_id: Int = 10, $mobile: String = "", $name: String = "", $users_type_id: Int = 10, $vessel: String = "") {
  insert_users(objects: {aadhaar_id: $aadhaar_id, email: $email, language_id: $language_id, location_id: $location_id, mobile: $mobile, name: $name, vessel: $vessel, users_type_id: $users_type_id}) {
    affected_rows
  }
}
`

/** TODO: use update user object */
const UPDATE_USER = gql`mutation UpdateUser($id: Int!, $aadhaar_id: String = "", $email: String = "", $language_id: Int!, $location_id: Int!, $mobile: String = "", $name: String = "", $users_type_id: Int!, $vessel: String = "") {
  update_users(where: {id: {_eq: $id}}, _set: {aadhaar_id: $aadhaar_id, email: $email, language_id: $language_id, location_id: $location_id, mobile: $mobile, name: $name, users_type_id: $users_type_id, vessel: $vessel}) {
    affected_rows
  }
}`

const UPDATE_USER_OBJECT = gql`mutation updateUserObject($id: Int!, $obj: users_set_input = {}) {
  update_users(where: {id: {_eq: $id}}, _set: $obj) {
    affected_rows
  }
}`

const ADD_LOCATION = gql`mutation AddLocation( $object: [user_location_insert_input!]! ) {
  insert_user_location(objects: $object) {
    affected_rows
  }
}
`

const UPDATE_LOCATION = gql`mutation UpdateLocation($id: Int!, $object: user_location_set_input!) {
  update_user_location(where: {id: {_eq: $id}}, _set: $object) {
    affected_rows
  }
}
`

const ADD_PRODUCT_SPECIES = gql`mutation AddProductSpecies($object: [product_species_insert_input!]!) {
  insert_product_species(objects: $object) {
    affected_rows
  }
}`

const UPDATE_PRODUCT_SPECIES = gql`mutation UpdateProductSpecies($id: Int!, $object: product_species_set_input!) {
  update_product_species(where: {id: {_eq: $id}}, _set: $object) {
    affected_rows
  }
}
`
const UPDATE_SPECIES = gql`mutation UpdateSpecies($id: Int!, $set: species_set_input!) {
  update_species(where: {id: {_eq: $id}}, _set: $set) {
    affected_rows
  }
}`

const ADD_PRODUCT = gql`mutation MyAddProductMutation($object: [products_insert_input!]!) {
  insert_products(objects: $object) {
    affected_rows
  }
}`

const UPDATE_PRODUCT = gql`mutation MyUpdateProductMutation($id: Int!, $object: products_set_input!) {
  update_products(where: {id: {_eq: $id}}, _set: $object) {
    affected_rows
  }
}`

const ADD_LANGUAGE = gql`mutation MyAddLangMutation($object: [user_language_insert_input!]!) {
  insert_user_language(objects: $object) {
    affected_rows
  }
}
`
const UPDATE_LANGUAGE = gql`mutation MyUpdateLangMutation($id: Int!, $object: user_language_set_input!) {
  update_user_language(where: {id: {_eq: $id}}, _set: $object) {
    affected_rows
  }
}
`

const UPDATE_SETTINGS = gql`mutation SettingsMutation($key: String!, $values:jsonb! ) {
  update_settings(where: {key: {_eq: $key}}, _set: { values: $values }) {
    affected_rows
  }
}`

const NEW_AUCTION = gql`mutation NewAuctionMutation($time: String!) {
  insert_auctions(objects: {status: "prebid", time: $time}) {
    affected_rows
  }
}
`

const UPDATE_AUCTION_STATUS = gql`mutation UpdateAuctionStatus($status: String!, $current: String!) {
  update_auctions(where: {status: {_eq: $current}}, _set: {status: $status}) {
    affected_rows
  }
}`

const CLOSE_ALL_AUCTION = gql`mutation CloseAllMutation {
  update_auctions(where: {}, _set: {status: "closed"}) {
    affected_rows
  }
}
`

const UPDATE_COUNTER_OFFER = gql`mutation MyMutation($counter: jsonb!, $id: Int!) {
  update_bids(where: {id: {_eq: $id}}, _set: {counter: $counter, status: "pending"}) {
    affected_rows
  }
}`

const AMMEND_QUOTA = gql`mutation AmmendQuotaMutation($auction_id: Int!, $quota: Int!, $product_id: Int!, $volume: float8!) {
  update_bids(where: {auction_id: {_eq: $auction_id}, rem_quota: {_is_null: false}}, _inc: {rem_quota: $quota}) {
    affected_rows
  }
  update_products(where: {id: {_eq: $product_id}}, _inc: {volume: $volume}) {
    affected_rows
  }
}`

const ADD_ADMIN_USER = gql`mutation AddAdminUser($objects: [admin_user_insert_input!]!) {
  insert_admin_user(objects: $objects) {
    returning{
      id
      mobile
      email
      channel_ids
    }
  }
}`

const UPDATE_ADMIN_USER = gql`mutation updateAdminUsers($_eq: Int!, $_set: admin_user_set_input!) {
  update_admin_user(where: {id: {_eq: $_eq}}, _set: $_set) {
    affected_rows
  }
}`

const UPDATE_ADMIN_USERS = gql`mutation MyMutation($where: admin_user_bool_exp!, $_set: admin_user_set_input!) {
  update_admin_user(where: $where, _set: $_set) {
    affected_rows
  }
}`

const ADD_ADMIN_ROLE = gql`mutation MyMutation($objects: [admin_user_role_insert_input!]!) {
  insert_admin_user_role(objects: $objects) {
    affected_rows
  }
}`


const UPDATE_ADMIN_ROLE = gql`mutation updateAdminUserRoles($_eq: Int!, $_set: admin_user_role_set_input!) {
  update_admin_user_role(where: {id: {_eq: $_eq}}, _set: $_set) {
    affected_rows
  }
}`

const ADD_MODULE = gql`mutation MyMutation($objects: [admin_modules_insert_input!]!) {
  insert_admin_modules(objects: $objects) {
    affected_rows
  }
}`

const UPDATE_MODULE = gql`mutation MyMutation($_eq: Int!, $_set: admin_modules_set_input!) {
  update_admin_modules(where: {id: {_eq: $_eq}}, _set: $_set) {
    affected_rows
  }
}`

const UPDATE_DIRECT_OFFER = gql`mutation DirectOfferMutation($_set: direct_offers_set_input = {}, $id: Int!) {
  update_direct_offers(where: {id: {_eq: $id}}, _set: $_set) {
    affected_rows
  }
}`

// const ACCEPT_DIRECT_OFFER = gql`mutation AcceptDirectOffer($objects: [purchase_orders_insert_input!]!) {
//   insert_purchase_orders(objects: $objects) {
//     affected_rows
//   }
// }`

const ACCEPT_DIRECT_OFFER = gql`mutation MyMutation($objects: [pickup_orders_insert_input!]!) {
  insert_pickup_orders(objects: $objects) {
    affected_rows
  }
}`

const UPDATE_VENDOR = gql`mutation userVendorUpdateMutation($vendor_details_object: vendor_details_set_input!, $user_object: users_set_input!, $user_id: Int!) {
  update_vendor_details(where: {user: {id: {_eq: $user_id}}}, _set: $vendor_details_object) {
    affected_rows
  }
  update_users(_set: $user_object, where: { id: { _eq: $user_id } }) {
    affected_rows
    returning {
      channel_ids
      channel_playerids
      tag_ids
      id
      username
      onboard_status
    }
  }
}`

const UPDATE_BULK_VENDOR = gql`mutation userVendorBulkUpdateMutation($vendor_details_object: vendor_details_set_input!, $user_object: users_set_input!, $where: vendor_details_bool_exp!, $where_user: users_bool_exp!) {
  update_vendor_details(where: $where, _set: $vendor_details_object) {
    affected_rows
  }
  update_users(_set: $user_object, where: $where_user) {
    affected_rows
  }
}`

const ADD_VENDOR = gql`mutation MyMutation($objects: [vendor_details_insert_input!]!) {
  insert_vendor_details(objects: $objects) {
    returning {
      user_id
      user {
        name
      }
    }
  }
}`

export const ADD_PRODUCTS = gql`
mutation MyMutation($objects: [products_insert_input!]!, $update_columns: [products_update_column!]!) {
  insert_products(objects: $objects, on_conflict: { constraint: products_pkey, update_columns: $update_columns}) {
    affected_rows
    returning {
      id
    }
  }
}
`
export const ADD_SPECIES = gql`
mutation MyMutation($objects: [species_insert_input!]!, $update_columns: [species_update_column!]!) {
  insert_species(objects: $objects, on_conflict: { constraint: species_pkey, update_columns: $update_columns}) {
    returning{
      id
    }
  }
}
`
const ADD_USER_ROLE = gql`mutation InserUserRoles($objects: [user_roles_insert_input!]!) {
    insert_user_roles(objects: $objects) {
      affected_rows
    }
  }`

const UPDATE_USER_ROLE = gql`mutation UpdateUserRole($where: user_roles_bool_exp!, $_set: user_roles_set_input!) {
  update_user_roles(where: $where, _set: $_set) {
    affected_rows
  }
}`

const UPDATE_ADMIN_USER_GROUP = gql`mutation updateAdminUserGroup($where: admin_user_groups_bool_exp!, $objects: [admin_user_groups_insert_input!]!) {
  
  delete_admin_user_groups(where: $where) {
    affected_rows
  }
  
  insert_admin_user_groups(objects: $objects) {
    affected_rows
  }
  
}`

const UPDATE_ADMIN_USER_ROLES = gql`mutation updateAdminUserRoles($where: admin_user_roles_bool_exp!, $objects: [admin_user_roles_insert_input!]!) {
  
  delete_admin_user_roles(where: $where) {
    affected_rows
  }
  
  insert_admin_user_roles(objects: $objects) {
    affected_rows
  }
  
}
`

const UPDATE_ADMIN_APPROVAL = gql`mutation updateApprovals($_set: approvals_set_input = {}, $where: approvals_bool_exp!) {
  update_approvals(_set: $_set, where: $where) {
    affected_rows
  }
}`

const ADD_REQUEST_APPROVAL = gql`mutation addApproval($objects: [approvals_insert_input!]!) {
  insert_approvals(objects: $objects) {
    returning {
      id
    }
  }
}`

const ADD_COLLECTION_LOCATION_POINT = gql`mutation AddCollectionPoint($objects: [collection_locations_insert_input!]!) {
  insert_collection_locations(objects: $objects) {
    returning {
      id
      location_code
    }
  }
}`

const UPDATE_COLLECTION_LOCATION_POINT = gql`mutation UpdateCollectionLocation(
  $where: collection_locations_bool_exp!, 
  $_set: collection_locations_set_input = {}
) {
  update_collection_locations(where: $where, _set: $_set) {
    returning {
      id
    }
  }
}`

const ADD_AUCTION_SCHEDULES = gql`mutation addAuctionSchedule($objects: [auction_schedules_insert_input!]!  ) {
  insert_auction_schedules(objects: $objects) {
    affected_rows
  }
}`

const ADD_AUCTION_SCHEDULE_SETTINGS = gql`mutation addAuctionScheduleSettings($objects: [auction_schedule_settings_insert_input!]!) {
  insert_auction_schedule_settings(objects: $objects) {
    affected_rows
  }
}`

const UPDATE_AUCTION_SCHEDULE_SETTINGS = gql`mutation MyMutation($where: auction_schedule_settings_bool_exp!, $_set: auction_schedule_settings_set_input = {}) {
  update_auction_schedule_settings(where: $where, _set: $_set) {
    affected_rows
  }
}`

const UPDATE_AUCTION_SCHEDULES = gql`mutation MyMutation($where: auction_schedules_bool_exp!, $_set: auction_schedules_set_input = {}) {
  update_auction_schedules(where: $where, _set: $_set) {
    affected_rows
  }
}`

const DELETE_AUCTION_SCHEDULES = gql`mutation MyMutation($where: auction_schedules_bool_exp!) {
  delete_auction_schedules(where: $where) {
    affected_rows
  }
}`

const DELETE_AUCTION_SCHEDULE_SETTINGS = gql`mutation MyMutation($where: auction_schedule_settings_bool_exp = {}) {
  delete_auction_schedule_settings(where: $where) {
    affected_rows
  }
}`

const ADD_CUSTOM_PRODUCT = gql`mutation InsertNewCustomProduct($objects: [custom_products_insert_input!]!) {
  insert_custom_products(objects: $objects) {
    affected_rows
  }
}`

const UPDATE_CUSTOM_PRODUCT = gql`mutation UpdateCustomProduct($id: Int!, $data: custom_products_set_input = {}) {
  update_custom_products(where: {id: {_eq: $id}}, _set: $data) {
    affected_rows
  }
}`

const DELETE_AUCTION = gql`mutation MyMutation($id: Int!) {
  delete_auctions(where: {id: {_eq: $id}}) {
    affected_rows
  }
}`

const ADD_INSERT_USER_TAGS = gql`mutation MyMutation($objects: [user_tags_insert_input!]!, $update_columns: [user_tags_update_column!]!) {
  insert_user_tags(objects: $objects, on_conflict: {constraint: user_tags_pkey, update_columns: $update_columns}) {
    returning {
      id
    }
  }
}
`
const ADD_USER_SEGMENTS = gql`mutation MyMutation($objects: [user_segments_insert_input!]!) {
    insert_user_segments(objects: $objects) {
      returning {
        id
      }
    }
  }
`

const DELETE_ADMIN_NOTIFICATION = gql`mutation DeleteNotifications($where: admin_notifications_bool_exp!) {
  delete_admin_notifications(where: $where) {
    affected_rows
  }
}`

const UPDATE_ADMIN_NOTIFICATIONS = gql`mutation UpdateAdminNotifications($where: admin_notifications_bool_exp!, $_set: admin_notifications_set_input = {}) {
  update_admin_notifications(where: $where, _set: $_set) {
    affected_rows
  }
}`

const INSERT_ADMIN_NOTIFICATIONS = gql`mutation InsertAdminNotifications($objects: [admin_notifications_insert_input!]!) {
  insert_admin_notifications(objects: $objects) {
    returning {
      id
    }
  }
}`


const INSERT_POLLS = gql`mutation InsertPolls($objects: [polls_insert_input!]!) {
  insert_polls(objects: $objects) {
    returning {
      id
    }
  }
}`

const UPSERT_POLL_RESPONSE = gql`mutation UpserPollResponse($objects: [poll_response_insert_input!]!, $update_columns: [poll_response_update_column!]! = admin_user_id) {
  insert_poll_response(objects: $objects, on_conflict: {constraint: poll_response_pkey, update_columns: $update_columns}) {
    returning {
      id
      response
    }
  }
}`

const UPSERT_POLL_COMMENTS = gql`mutation UpsertPollComment($objects: [poll_comments_insert_input!]!, $update_columns: [poll_comments_update_column!]! = admin_user_id) {
  insert_poll_comments(objects: $objects, on_conflict: {constraint: poll_comments_pkey, update_columns: $update_columns}) {
    returning {
      id
      comment
    }
  }
}`

const UPSERT_POLL_COMMENT_REPLIES = gql`mutation UpsertPollCommentReplies($objects: [poll_comment_replies_insert_input!]!, $update_columns: [poll_comment_replies_update_column!]! = admin_user_id) {
  insert_poll_comment_replies(objects: $objects, on_conflict: {constraint: poll_comment_replies_pkey, update_columns: $update_columns}) {
    returning {
      id
    }
  }
}`

const DELETE_POLL_COMMENT_REPLIES  = gql`mutation DeletePollCommentReplies($id: Int!) {
  delete_poll_comment_replies(where: {id: {_eq: $id}}) {
    affected_rows
  }
}`

const INSERT_ADMIN_NOTIFICATIONS_RECIPIENT = gql`mutation updateAdminNotificationsRecipient($objects: [admin_notifications_recipient_insert_input!]!) {
  
  insert_admin_notifications_recipient(objects: $objects) {
    affected_rows
  }
}
`

const DELETE_ADMIN_NOTIFICATIONS_RECIPIENT = gql`mutation deleteAdminNotificationsRecipient($where: admin_notifications_recipient_bool_exp!) {
  
  delete_admin_notifications_recipient(where: $where) {
    affected_rows
  } 
}
`
const UPDATE_ADMIN_NOTIFICATIONS_RECIPIENT = gql`mutation updateAdminNotificationsRecipient($where: admin_notifications_recipient_bool_exp!, $_set: admin_notifications_recipient_set_input!) {
  update_admin_notifications_recipient(where: $where, _set: $_set) {
    affected_rows
  }
}
`
const UPDATE_PURCHASE_ORDER = gql`mutation updatePurchaseOrder($_set: purchase_orders_set_input!, $id: Int!, $bid_id: Int!, $bid_object: bids_set_input!, $pu_set: pickup_orders_set_input = {}) {
  update_purchase_orders(where: {id: {_eq: $id}}, _set: $_set) {
    affected_rows
  }
  update_bids(where: {id: {_eq: $bid_id}}, _set: $bid_object) {
    affected_rows
  }
  update_pickup_orders(where: {purchase_order_id: {_eq: $id}}, _set: $pu_set) {
    affected_rows
  }
}
`

const UPDATE_PURCHASE_ORDER_JOB_ID = gql`mutation updatePurchaseOrderPickUp($where: purchase_orders_bool_exp!, $_set: purchase_orders_set_input!) {
  update_purchase_orders(where: $where, _set: $_set) {
    affected_rows
  }
}`

const INSERT_VENDOR_NOTIFICATION = gql`mutation insertVendorNotification($objects: [notifications_insert_input!]!) {
  insert_notifications(objects: $objects) {
    affected_rows
  }
}`

const UPSERT_JOBS = gql`mutation UpsertJobs($objects: [jobs_insert_input!]! = {}, $update_columns: [jobs_update_column!]!) {
  insert_jobs(objects: $objects, on_conflict: {constraint: jobs_pkey, update_columns: $update_columns}) {
    affected_rows
    returning {
      id
    }
  }
}`

const UPDATE_JOBS = gql`mutation UpdateJobs($where: jobs_bool_exp!, $_set: jobs_set_input!) {
  update_jobs(where: $where, _set: $_set) {
    affected_rows
  }
}`

const UPSERT_ROUTES = gql`mutation UpsertRoutes($objects: [routes_insert_input!]! = {}, $update_columns: [routes_update_column!]!) {
  insert_routes(objects: $objects, on_conflict: {constraint: routes_pkey, update_columns: $update_columns}) {
    affected_rows
    returning {
      id
    }
  }
}`

const UPSERT_DRIVERS = gql`mutation UpsertDrivers($objects: [drivers_insert_input!]! = {}, $update_columns: [drivers_update_column!]!) {
  insert_drivers(objects: $objects,  on_conflict: {constraint: drivers_pkey, update_columns: $update_columns}) {
    affected_rows
  }
}`

const UPSERT_VEHICLES = gql`mutation UpsertVehicles($objects: [vehicles_insert_input!]! = {}, $update_columns: [vehicles_update_column!]!) {
  insert_vehicles(objects: $objects, on_conflict: {constraint: vehicles_pkey, update_columns: $update_columns}){
    affected_rows
  }
}`

const UPSERT_DEPOTS = gql`mutation UpsertDepots($objects: [depots_insert_input!]! = {}, $update_columns: [depots_update_column!]!) {
  insert_depots(objects: $objects,  on_conflict: {constraint: depots_pkey, update_columns: $update_columns}) {
    affected_rows
  }
}`

const UPDATE_DEPOTS = gql`mutation UpdateDepots($where: depots_bool_exp!, $_set: depots_set_input!) {
  update_depots(where: $where, _set: $_set) {
    affected_rows
  }
}`

const UPSERT_SHIPPERS = gql`mutation UpsertShippers($objects: [shippers_insert_input!]! = {}, $update_columns: [shippers_update_column!]!) {
  insert_shippers(objects: $objects,  on_conflict: {constraint: shippers_pkey, update_columns: $update_columns}) {
    affected_rows
  }
}`

const UPSERT_CARRIERS = gql`mutation UpsertCarriers($objects: [carriers_insert_input!]! = {}, $update_columns: [carriers_update_column!]!) {
  insert_carriers(objects: $objects,  on_conflict: {constraint: carriers_pkey, update_columns: $update_columns}) {
    affected_rows
  }
}`

export {
  ADD_AUCTION,
  ADD_PICKUP_ORDER,
  UPDATE_BID_REJECT_STATUS,
  UPDATE_BULK_BID_REJECT_STATUS,
  UPDATE_AUCTION_STATUS_BY_ID,
  ADD_USER,
  UPDATE_USER,
  UPDATE_USER_OBJECT,
  ADD_LOCATION,
  UPDATE_LOCATION,
  ADD_PRODUCT_SPECIES,
  UPDATE_PRODUCT_SPECIES,
  UPDATE_SPECIES,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  ADD_LANGUAGE,
  UPDATE_LANGUAGE,
  UPDATE_SETTINGS,
  NEW_AUCTION,
  UPDATE_AUCTION_STATUS,
  CLOSE_ALL_AUCTION,
  UPDATE_COUNTER_OFFER,
  AMMEND_QUOTA,
  ADD_ADMIN_USER,
  UPDATE_ADMIN_USER,
  UPDATE_ADMIN_USERS,
  ADD_ADMIN_ROLE,
  UPDATE_ADMIN_ROLE,
  ADD_MODULE,
  UPDATE_MODULE,
  UPDATE_DIRECT_OFFER,
  ACCEPT_DIRECT_OFFER,
  DELETE_AUCTION,
  /** Vendor */
  UPDATE_VENDOR,
  UPDATE_BULK_VENDOR,
  ADD_VENDOR,
  /** User Roles */
  ADD_USER_ROLE,
  UPDATE_USER_ROLE,
  /** admin user group */
  UPDATE_ADMIN_USER_GROUP,
  /** admin user roles */
  UPDATE_ADMIN_USER_ROLES,

  /** Admin Approval */
  UPDATE_ADMIN_APPROVAL,
  ADD_REQUEST_APPROVAL,

  /** Collection Location Point */
  ADD_COLLECTION_LOCATION_POINT,
  UPDATE_COLLECTION_LOCATION_POINT,

  /** Schedule */
  ADD_AUCTION_SCHEDULES,
  ADD_AUCTION_SCHEDULE_SETTINGS,
  UPDATE_AUCTION_SCHEDULE_SETTINGS,
  UPDATE_AUCTION_SCHEDULES,
  DELETE_AUCTION_SCHEDULES,
  DELETE_AUCTION_SCHEDULE_SETTINGS,

  /** Custom products direct selection list */
  UPDATE_CUSTOM_PRODUCT,
  ADD_CUSTOM_PRODUCT,

  ADD_INSERT_USER_TAGS,
  ADD_USER_SEGMENTS,

  /** notifications */
  DELETE_ADMIN_NOTIFICATION,
  UPDATE_ADMIN_NOTIFICATIONS,
  INSERT_ADMIN_NOTIFICATIONS,
  INSERT_ADMIN_NOTIFICATIONS_RECIPIENT,
  DELETE_ADMIN_NOTIFICATIONS_RECIPIENT,
  UPDATE_ADMIN_NOTIFICATIONS_RECIPIENT,
  INSERT_VENDOR_NOTIFICATION,

  /** polls */
  INSERT_POLLS,
  UPSERT_POLL_RESPONSE,
  UPSERT_POLL_COMMENTS,
  UPSERT_POLL_COMMENT_REPLIES,
  DELETE_POLL_COMMENT_REPLIES,

  //** Auction/Bid */
  UPDATE_PURCHASE_ORDER,
  UPDATE_BID_STATUS,

  /** Logistics */
  UPSERT_JOBS,
  UPSERT_DRIVERS,
  UPSERT_VEHICLES,
  UPSERT_ROUTES,
  UPDATE_JOBS,
  UPDATE_PURCHASE_ORDER_JOB_ID,
  UPSERT_DEPOTS,
  UPSERT_SHIPPERS,
  UPSERT_CARRIERS,
  UPDATE_DEPOTS
}

