import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import React, { useState } from 'react';
import { GET_LOCATIONS } from 'Util/queries';
const { Option } = Select;

function LocationDropdown({ onChange, ...rest }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_LOCATIONS, {
        onCompleted() {
            setList(data.user_location)
        }
    });

    return (
        <div>
            <Select
                {...rest}
                placeholder="Select a location"
                onChange={onChange}
            >
                {list.map(v => <Option value={v.id} key={`location-dd-opt${v.id}`}>{v.label}</Option>)}
            </Select>
        </div>
    )
}

export default LocationDropdown
