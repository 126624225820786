import { Select } from 'antd';
import { useContext } from 'react';
import styled from "styled-components";
import { LogisticsJobContext } from '../..';
import { useHistory } from "react-router-dom";

const { Option } = Select;

const Container = styled.div`
  label{
      font-weight: bold;
  }  
`;


function JobsDropdown(props) {
    
    const { filters, setFilter } = useContext( LogisticsJobContext )
    const history = useHistory()

    const handleChange = (jobtype) => {
        setFilter({ ...filters, jobtype })
        if (jobtype === "collection") {
            history.push('/logistics/jobs')
        }else{
            history.push(`/logistics/jobs/${jobtype}`)
        }
    }

    return (
        <Container>
            <label>Jobs: </label>
            <Select value={filters.jobtype} defaultValue="all" style={{ width: 110 }} bordered={false} onChange={handleChange}>
                <Option value="all">All</Option>
                <Option value="collection">Collection</Option>
                <Option value="delivery">Delivery</Option>
            </Select>
        </Container>
    )
}

JobsDropdown.propTypes = {}

export default JobsDropdown