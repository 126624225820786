import React, { useState, createContext } from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { DataTable, TableFilters } from './components'
import ClockV2 from 'Components/ClockV2'
import AuctionTimer from 'Components/AuctionTimer'

export const DataContext = createContext()

const Container = styled.div``

function PurchaseOrdersData() {

    const [filter, setFilter] = useState()
    const [selectedRecord, setSelectedRecord] = useState(null)

    const auctionMenu = [
        { title: "Data", link: "/procurement/purchase-orders/data" }
    ]

    return (
        <DataContext.Provider value={{
            filter, setFilter,
            selectedRecord, setSelectedRecord
        }}>
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
                <AuctionTimer position='absolute'/>
                <ClockV2 position='absolute' />
                {/* <TableFilters/> */}
                <DataTable />
            </Container>
        </DataContext.Provider>
    )
}

export default PurchaseOrdersData