import React, {useState} from 'react';
import { EllipsisOutlined, EditFilled, CloseOutlined, EyeOutlined, PauseOutlined, CopyOutlined } from "@ant-design/icons";
import { THEME } from "Util/constants";
import { Popover} from 'antd';
import styled from "styled-components";
import { ADD_INSERT_USER_TAGS } from 'Util/mutations';
import { useMutation } from '@apollo/client';

const Container = styled.div``

const StyledPopover = styled(Popover)`
    .ant-popover-inner-content {
        padding: 0px 0px;
    }
`

const Content = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    .row {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }
    .text {
        margin-left: 5px;
    }
`
  
function KebabMenu({ data }) {
    const [visible, setVisible] = useState(false)
    const [open, setOpen] = useState(false)

    const edit = () => {
        setOpen(!open)
        setVisible(true)
    }
    const del = () => { 
        alert('delete') 
        setOpen(false)
    }

    const onCancel = () => {
        setVisible(false)  
    }

    const [add] = useMutation(ADD_INSERT_USER_TAGS, {
        onCompleted() {
            setVisible(false)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const onSubmit = (values) => {
        add({
            variables: {
              update_columns: ["id", ...Object.keys(values)],
              objects: [
                {
                  ...values
                },
              ],
            },
        });
    }

    const onClick = () => {
        setOpen(!open)
    }

    const content = (
        <Content>
            <div onClick={edit} className='row'>
                <EyeOutlined style={{ color: THEME.colors.primary}}/>
                <div className='text'>View Users</div>
            </div>
            <div onClick={edit} className='row'>
                <EditFilled style={{ color: THEME.colors.primary}}/>
                <div className='text'>Edit</div>
            </div>
            <div onClick={edit} className='row'>
                <PauseOutlined style={{ color: THEME.colors.primary}}/>
                <div className='text'>Pause</div>
            </div>
            <div onClick={edit} className='row'>
                <div style={{ color: THEME.colors.primary, width: '1em', height: '1em', textAlign: 'center', transform: 'scale(2)', paddingBottom: '17px'}}>*</div>
                <div className='text'>Use as Default</div>
            </div>
            <div onClick={edit} className='row'>
                <CopyOutlined style={{ color: THEME.colors.primary}}/>
                <div className='text'>Duplicate</div>
            </div>
            <div onClick={del} className='row'>
                <CloseOutlined style={{ color: THEME.colors.red}}/>
                <div className='text'>Delete</div>
            </div>
        </Content>
    );
      
    return (
      <Container>
        <StyledPopover placement="rightTop" content={content} trigger="click" visible={open} onClick={onClick} onVisibleChange={onClick}>
            <EllipsisOutlined type="ellipsis" style={{ transform: "rotate(90deg)", color: THEME.colors.primary}} />
        </StyledPopover>
        {/* <NewTagModal visible={visible} onCancel={onCancel} onSubmit={onSubmit} data={data}/> */}
      </Container>
    );
  };
  
  export default KebabMenu;
