import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { debounce } from 'lodash';

const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }   
`;

function Search({setSearch}) {
    const updateQuery = (e) =>{
        setSearch(e.target.value)
    }
    const debouncedOnChange = debounce(updateQuery, 200);
    return (
        <Container>
            <Input prefix={<SearchOutlined />} placeholder="Search"  onChange={(e) => debouncedOnChange(e)}/>
        </Container>
    )
}

Search.propTypes = {
    setSearch: PropTypes.func
}

export default Search
