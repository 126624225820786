import { useMutation } from '@apollo/client';
import { Button, Form, Input, notification, Select } from 'antd';
import CommonDrawer from 'Components/CommonDrawer';
import _ from 'lodash';
import React from 'react';
import { moduleType } from 'Util/constants';
import { ADD_MODULE, UPDATE_MODULE } from 'Util/mutations';


const rule = [
    {
        required: true,
        message: "Field is required"
    },
]

function AdminModuleForm({ form, drawer, drawerToggle, editId }) {

    const [addUser] = useMutation(ADD_MODULE, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Module has been added',
            });
            drawerToggle(false)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const [updateUser] = useMutation(UPDATE_MODULE, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Module has been updated!',
            });
            drawerToggle(false)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const onFormSubmit = () => {
        const formData = form.getFieldsValue();

        if (_.isNull(editId)) {
            addUser({ variables: { objects: formData } })
            return null
        }

        updateUser({ variables: { _eq: editId, _set: formData } })

    }

    return (
        <div className='admin-user-form'>

            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { drawerToggle(true) }} >New Module</Button>
            </div>

            <CommonDrawer
                open={drawer}
                form={form}
                title={_.isNull(editId) ? "Add new module" : "Edit module"}
                onClose={() => drawerToggle(false)}
            >

                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                    component={false}
                >

                    <Form.Item
                        rules={rule}
                        label="Name" name="name">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Description" name="description">
                        <Input.TextArea placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Type" name="type">
                        <Select style={{ width: 300 }}>
                            {moduleType.map(v => (<Select.Option key={`module-${v}-type`} value={v}>{v}</Select.Option>))}
                        </Select>
                    </Form.Item>

                </Form>

            </CommonDrawer>

        </div>
    )
}

AdminModuleForm.propTypes = {

}

export default AdminModuleForm

