import { atom } from "recoil";

export const auctionAtom = atom({
    key: "auctionStates",
    default: {
        auctionStatus: 'prebid',
        currentAuction: null,
        currentBids: []
    }
})

export const auctionSearchAtom = atom({
    key: "auctionSearch",
    default: ""
});

export const auctionAutoBidAtom = atom({
    key: "auctionAutoBid",
    default: null
});

export const auctionSelectedRecordAtom = atom({
    key: "auctionSelectedRecord",
    default: {
        selectedRowKeys: [],
        selectedData: []
    }
});

export const auctionScheduleAtom = atom({
    key: "auctionSchedule",
    default: ""
});

export const auctionDataTableFiltersAtom = atom({
    key: "auctionDataTableFilters",
    default: {
        searchText: "",
        range: "day",
        status: "all"
    }
});


/** auctionQuotas */
export const auctionQuotasTableFiltersAtom = atom({
    key: "auctionQuotasTableFilters",
    default: {
        searchText: "",
        qouta: "all"
    }
});

export const auctionQuotaSelectedRecordAtom = atom({
    key: "auctionQuotaSelectedRecordAtom",
    default: null
}) 