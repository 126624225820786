import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import SpeciesDropDown from './SpeciesDropDown';
import Search from './Search';
import NewSpecies from './NewSpecies';
import DeleteVendor from './DeleteVendor';
import ProcessVendor from './ProcessVendor';
import Report from './Report';
import SpeciesAction from './SpeciesAction';
import ExtraFilters from './ExtraFilters';
import Item from './Item';
import PricesAndDiscounts from './PricesAndDiscounts';
import Edit from './Edit';

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

function TableFilter(props) {

  return (
    <Container>
      <SpeciesDropDown />
      <Search />
      <NewSpecies />
      <Edit/>
      <DeleteVendor />
      <ProcessVendor />
      <Report/>
      <Item/>
      <PricesAndDiscounts/>
      <SpeciesAction />
      <ExtraFilters data={props.data} columns={props.columns}/>
    </Container>
  )
}

TableFilter.propTypes = {
}

export default TableFilter
