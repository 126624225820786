import CommonDiv from 'Components/CommonDiv';
import { useContext } from 'react';

import { NotificationContext } from 'Components/Notification/NotificationContext';


function NotificationContent() {

    const { data } = useContext(NotificationContext)
    const { body } = data

    return (
        <CommonDiv style={{ padding: "0px 10px", marginBottom: 50 }} >
            <pre>{body}</pre>
        </CommonDiv>
    )
}

export default NotificationContent