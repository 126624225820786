import React, {useState} from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { DataTable, TransferList } from './components'
import { Button } from 'antd';
import { THEME } from 'Util/constants';
import { selectedVendorAtom } from "Store/crmAtom";
import { useRecoilState } from "recoil";

const Container = styled.div`

`
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .custom-button {
        border-radius: 0px; 
        background-color: ${THEME.colors.primary};
        color: white;
    }
    .ant-btn[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
    }
    
    
`

const auctionMenu = [
    { title: "All Users", link: "/crm/notifications/all-users" },
    { title: "Segments", link: "/crm/notifications/segments" },
    { title: "Tags", link: "/crm/notifications/tags" },
    { title: "Polls", link: "/crm/notifications/polls" }
]

function CRMAllUsers() {
    const [visible, setVisible] = useState(false)
    const [vendor, setSelectedVendor] = useRecoilState(selectedVendorAtom);
    const onCancel = () => {setVisible(false)}

    // const [add] = useMutation(ADD_USER_SEGMENTS, {
    //     onCompleted() {
    //         setVisible(false)
    //     },
    //     onError(e) {
    //         console.log('erre', e)
    //     }
    // });

    const onSubmit = (values) => {
        //console.log('submitted', values);
        setVisible(false)
        
    }

    const onClick = () => {
        if (vendor.length > 0){
            setVisible(!visible)
        }
    }
    return (
        <>
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
                <ButtonWrapper>
                    <Button disabled={!vendor.length > 0} shape="round" className="custom-button" onClick={onClick}>Assign Users</Button>
                </ButtonWrapper>
                <DataTable />
                <TransferList visible={visible} onCancel={onCancel} onSubmit={onSubmit}/>
            </Container>
        </>
    )
}

export default CRMAllUsers
