import { useQuery } from '@apollo/client';
import { Select } from 'antd'
import React, { useState } from 'react'
import { GET_PRODUCT_CATCH_METHOD } from 'Util/queries';
const { Option } = Select;

function CatchMethodDropdown({ onChange, ...rest }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_PRODUCT_CATCH_METHOD, {
        onCompleted() {
            setList(data.product_catch_method)
        }
    });

    return (
        <div>
            <Select
                {...rest}
                style={{ width: '100%' }}
                placeholder="Select a catch method"
                onChange={onChange}
            >
                { list.map(v=><Option value={v.value} key={`product-catchmethod-opt${v.id}`}>{v.value}</Option>) }
            </Select>
        </div>
    )
}

export default CatchMethodDropdown
