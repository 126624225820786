import React from 'react'
import PropTypes from 'prop-types'
import { ControlOutlined, DashboardOutlined, ContainerOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { Menu } from 'antd';

function AuctionMenu(props) {
    return (
        <>
            <Menu theme="light" mode="horizontal" defaultSelectedKeys={['auction']}>
                <Menu.Item key="auction-live" icon={<DashboardOutlined />}>
                    <span>Live</span>
                    <Link to="/auction" />
                </Menu.Item>

                <Menu.Item key="auction-archive" icon={<ContainerOutlined />}>
                    <span>Archive</span>
                    <Link to="/auction/archive" />
                </Menu.Item>

                <Menu.Item key="auction-settings" icon={<ControlOutlined />}>
                    <span>Settings</span>
                    <Link to="/auction/settings" />
                </Menu.Item>
            </Menu>
        </>
    )
}

AuctionMenu.propTypes = {

}

export default AuctionMenu

