import { KeyOutlined, LikeOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { ButtonLoader } from 'Components/CommonButton'
import CommonDiv from 'Components/CommonDiv'
import { FormDivision, FormSection } from 'Components/Form'
import { CommonSelectModal } from 'Components/Modal'
import User from 'Hooks/user'
import { THEME, appConstant, onesignal, communication } from 'Util/constants'
import { sendCreateAdminEmail, sendResetadminEmail } from 'Util/mailer'
import { ADD_ADMIN_USER, ADD_REQUEST_APPROVAL, UPDATE_ADMIN_USER, UPDATE_ADMIN_USER_GROUP, UPDATE_ADMIN_USER_ROLES } from 'Util/mutations'
import { GET_ADMIN_USER, GET_USER_CHANNELS } from 'Util/queries'
import { Alert, Button, Checkbox, Form, Input, Modal, Select, Space, notification } from 'antd'
import leadingZeroes from 'leading-zeroes'
import _ from 'lodash'
import { createContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { AdminRolesHeader, UserGroupInputTable, UserPermissionInputTable } from './components'
import PhoneValidator from 'Components/PhoneValidator'
import axios from 'axios';

export const UsersCardContext = createContext()

const Container = styled.div`
    
    h1.profile-name{
        border-top: solid 1px #ccc;
        border-bottom: solid 1px #ccc;
        margin-top: 15px;
        /* padding-left: 40px; */
    }

    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }

    div.b-permissions{
        border-bottom: solid 1px #ccc;
        padding-bottom: 10px;
    }
    
`

const ModalContainer = styled.div``

function UsersCard(props) {

    const history = useHistory()
    const params = useParams()
    const [form] = Form.useForm()
    const { user_id } = User()

    const editMode = !_.isUndefined(params.id)
    const [formLoading, setFormLoading] = useState(false)
    const [userData, setUserData] = useState({
        full_name: "New user",
        admin_user_groups: [],
        admin_user_roles: []
    })

    const [showApprove, setShowApprove] = useState(false)
    const [showReset, setShowReset] = useState(false)
    const [adminUsers, setAdminUsers] = useState([])

    const resetPass = useRef();
    const reset2FA = useRef()

    useQuery(GET_ADMIN_USER, {
        onCompleted(data) {

            data && setAdminUsers(data.admin_user)

            if (data.admin_user && editMode) {
                const usr = _.find(data.admin_user, { id: parseInt(params.id) })
                setUserData(usr)
                console.log('usr', usr);
                form.setFieldsValue({
                    ...usr,
                    id: leadingZeroes(usr.id, appConstant.leadingZero),
                    authentication_email: usr.authentication_email ?? usr.email,
                    channels: usr.channel_ids
                })
            }

        }
    })

    const [updateAdminUser] = useMutation(UPDATE_ADMIN_USER, {
        onCompleted(data) {
            notification.success({ message: "User information updated!" })
            setFormLoading(false)
        }
    })

    const [updateGroups] = useMutation(UPDATE_ADMIN_USER_GROUP, {
        onCompleted(data) { }
    })

    const [updatePerms] = useMutation(UPDATE_ADMIN_USER_ROLES, {
        onCompleted(data) { }
    })

    /** TODO: Transfer to hooks */
    const [addRequestApproval] = useMutation(ADD_REQUEST_APPROVAL, {
        onCompleted(data) { }
    })

    const {data: options} = useQuery(GET_USER_CHANNELS);

    const [addUser] =  useMutation(ADD_ADMIN_USER, {
        onCompleted(response) {

            setFormLoading(false)

            const admin_user_id = response.insert_admin_user.returning[0].id
            const admin_user_groups_data = userData.admin_user_groups.map(aug => ({ admin_user_id, 'user_groups_id': aug.user_group.id }))
            const admin_user_roles_data = userData.admin_user_roles.map(aur => ({ admin_user_id, 'users_roles_id': aur.user_role.id }))
            const { mobile, email , channel_ids } = response.insert_admin_user.returning[0]

            if (channel_ids.length > 0){
                
                const values = {
                    mobile,
                    email,
                    channel_ids
                }
                const addPlayerIds = addPlayer(values, admin_user_id);

                updateAdminUser({
                    variables: {
                        _eq: admin_user_id,
                        _set: { channel_playerids: addPlayerIds }
                    }
                })

            } 

            updateGroups({
                variables: {
                    where: { admin_user_id: { _eq: admin_user_id } },
                    objects: admin_user_groups_data
                }
            })

            updatePerms({
                variables: {
                    where: { admin_user_id: { _eq: admin_user_id } },
                    objects: admin_user_roles_data
                }
            })

            addRequestApproval({
                variables: {
                    objects: [{
                        approver_role: 1, /** MASTER ADMINS */
                        requester_id: user_id,
                        approval_request_type: "new-admin-approval",
                        workflow: "Admin User OnBoarding",
                        ref_id: admin_user_id
                    }]
                }
            })

            form.setFieldsValue({ id: leadingZeroes(admin_user_id, appConstant.leadingZero) })

            notification['success']({
                message: 'Success!',
                description: 'User has been added with default password'
            });

        },
        onError(e) { console.log('erre', e) }
    });

    useEffect(() => {
        editMode && userData && setShowApprove(userData.is_pending && !userData.status)
    }, [userData])

    const handleSubmit = (data) => {

        const admin_user_groups_data = userData.admin_user_groups.map(aug => ({ 'admin_user_id': params.id, 'user_groups_id': aug.user_group.id }))
        const admin_user_roles_data = userData.admin_user_roles.map(aur => ({ 'admin_user_id': params.id, 'users_roles_id': aur.user_role.id }))
        let channels = data.channels ? '{' + data.channels.join(',') + '}' : '{}'

        if (_.isEmpty(admin_user_groups_data) || _.isEmpty(admin_user_roles_data)) {
            notification.error({
                message: `Please add 
                ${_.isEmpty(admin_user_groups_data) ? 'User group' : ''} 
                ${(_.isEmpty(admin_user_groups_data) && _.isEmpty(admin_user_roles_data)) ? ' and ' : ''}
                ${_.isEmpty(admin_user_roles_data) ? 'User permissions' : ''}.`
            })
            return null
        }

        setFormLoading(true)

        const user_data = {
            authentication_email: data.authentication_email,
            authentication_status: data.authentication_status,
            email: data.email,
            full_name: data.full_name,
            id: data.id,
            status: data.status,
            username: data.username,
            channel_ids: channels,
            mobile: data.mobile
        }

        if (editMode) {

            updateAdminUser({
                variables: {
                    _eq: params.id,
                    _set: _.omit(user_data, ['id'])
                }
            })

            updateGroups({
                variables: {
                    where: { admin_user_id: { _eq: params.id } },
                    objects: admin_user_groups_data
                }
            })

            updatePerms({
                variables: {
                    where: { admin_user_id: { _eq: params.id } },
                    objects: admin_user_roles_data
                }
            })

        } else {
            const reset_token = uuidv4();
            addUser({ variables: { objects: [{ reset_token, ...user_data, user_role: 1 }] } })
        }


    }

    const approveUserHandler = async () => {
        const { full_name, reset_token } = userData
        await updateAdminUser({ variables: { _eq: params.id, _set: { is_pending: false, status: true } } })
        sendCreateAdminEmail({ email: userData.authentication_email ?? userData.email, full_name, reset_token })
        setUserData({ ...userData, is_pending: false, status: true })
        // setShowApprove(false);
    }

    const sendResetHandler = async () => {
        if (resetPass.current.state.checked) {
            const reset_token = uuidv4();
            const { full_name, email } = userData
            /** update user query */
            await updateAdminUser({ variables: { _eq: params.id, _set: { reset_token } } })
            sendResetadminEmail({ email, full_name, reset_token })
        }

        if (reset2FA.current.state.checked) {
            /** update user query */
            await updateAdminUser({ variables: { _eq: params.id, _set: { token: null } } })
        }
        setShowReset(false)
    }

    const checkboxOptions = options?.user_channels.map(channel => ({
        label: channel.label,
        value: channel.id,
    }));

    const handleMobileChange = (value) => {
        if (value === "") {
            return true
        }
        const regex = /^\+?[1-9]\d{1,14}$/;        
        const format = regex.test(value)
        return format    
    };

    const addPlayer = async (values, vid) => {
        let channel_playerids = {}

        const { Sms, Email } = onesignal.device_type
        const { subscribed } = onesignal.notification_types
        const { MOBILE_PUSH, WEB_PUSH, SMS, EMAIL} = communication.channel

        const getPrefix = () => onesignal.ext_user_id_prefix.find(group => group.id == 4).prefix

        for (let i = 0; i < values.channel_ids.length; i++) {
            const cid = values.channel_ids[i];

            const info = {
                external_user_id: getPrefix() + vid.toString(),
                notification_types: subscribed,
            }

            if (cid === EMAIL){ 
                info.device_type = Email
                info.identifier = values.email
            }
            if (cid === SMS){
                info.device_type = Sms
                info.identifier = values.mobile
            }

            if (cid === EMAIL || cid === SMS ){
                await axios.post(`${process.env.REACT_APP_API}/onesignal/add-device`, { info }).then(res => {
                    const { success, id } = res.data
                    console.log(res.data);
                    if (success) {
                        channel_playerids[cid] = id
                    }
                })  
            }
        };

        return channel_playerids
    }

    return (
        <UsersCardContext.Provider value={{ userData, setUserData }} >

            {
                (userData.is_pending && !userData.status) && !showApprove && <Alert
                    message="This user has peding approval from admin."
                    type="warning"
                    showIcon
                    style={{ marginBottom: 10 }}
                    action={
                        <Button size="small" type="text"
                            onClick={() => setShowApprove(true)}
                        >
                            View
                        </Button>
                    }
                />
            }


            <Container>
                <AdminRolesHeader title='User Card' />
                <h1 className='profile-name' >{userData.full_name}</h1>
                <div className='sub-permissions' >
                    <div>
                        <KeyOutlined onClick={() => alert('new')} />
                        <label>Effective Permissions</label>
                    </div>
                </div>

                <Form
                    form={form}
                    name="adminUserForm"
                    onFinish={handleSubmit}
                    onFinishFailed={() => { console.error('fail') }}
                >

                    <FormSection title='General'>
                        <FormDivision>
                            <Form.Item
                                rules={[
                                    { required: true, message: '' },
                                    {
                                        validator: async (_, value) => {
                                            if (adminUsers.find(o => (o.username === value && value !== userData.username))) {
                                                return Promise.reject(new Error('Username already exists.'))
                                            }
                                        },
                                        validateTrigger: 'onChange'
                                    }
                                ]}
                                label="Username" name="username">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                rules={[{ required: true, message: '' }]}
                                label="Full name" name="full_name">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                rules={[
                                    { required: true, message: '' }
                                ]}
                                label="Status" name="status">
                                <Select>
                                    <Select.Option value={true} >Active</Select.Option>
                                    <Select.Option value={false} >Inactive</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    { required: true, message: '' },
                                    { type: 'email', message: 'Invalid email format.' },
                                    {
                                        validator: async (_, value) => {
                                            if (adminUsers.find(o => (o.email === value && value !== userData.email))) {
                                                return Promise.reject(new Error('Contact email already exists.'))
                                            }
                                        },
                                        validateTrigger: 'onChange'
                                    }
                                ]}
                                label="Contact Email" name="email">
                                <Input />
                            </Form.Item>
                        </FormDivision>

                        <FormDivision>
                            <Form.Item
                                label="Employee ID" name="id">
                                <Input readOnly />
                            </Form.Item>

                            <Form.Item label="Mobile #" name="mobile"
                                rules={[{ required: true, message: '' },
                                {
                                    validator(_, value) {
                                        const valid = handleMobileChange(value)
                                        if (valid || value === undefined) {
                                            return Promise.resolve();
                                        }else{
                                            return Promise.reject(new Error('Invalid Format'));
                                        }
                                    },
                                }
                                ]}>
                                <PhoneValidator/>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    { required: true, message: '' },
                                    { type: 'email', message: 'Invalid email format.' },
                                    {
                                        validator: async (_, value) => {
                                            if (adminUsers.find(o => (o.authentication_email === value && value !== userData.authentication_email))) {
                                                return Promise.reject(new Error('Authentication Email already exists.'))
                                            }
                                        },
                                        validateTrigger: 'onChange'
                                    }
                                ]}
                                label="Authentication Email" name="authentication_email">
                                <Input />
                            </Form.Item>

                            <Form.Item
                                rules={[{ required: true, message: '' }]}
                                label="Authentication Status" name="authentication_status">
                                <Select>
                                    <Select.Option value={true} >Active</Select.Option>
                                    <Select.Option value={false} >Inactive</Select.Option>
                                </Select>
                            </Form.Item>

                        </FormDivision>

                    </FormSection>

                    <FormSection title="Communication Channels">
                        <Form.Item name="channels">
                            <Checkbox.Group options={checkboxOptions} style={{borderColor: 'black', backgroundColor: 'white'}}/>
                        </Form.Item>
                    </FormSection>

                    <UserGroupInputTable data={userData.admin_user_groups} />
                    <UserPermissionInputTable data={userData.admin_user_roles} />

                    <CommonDiv direction="row" justify="flex-end" margin="20px 0px 0px 0px" >
                        <Space >

                            {
                                editMode && <ButtonLoader type="warn" onClick={() => setShowReset(true)} >
                                    {editMode ? 'Send Reset Password / 2FA' : 'Save'}
                                </ButtonLoader>
                            }

                            <ButtonLoader loading={formLoading} htmlType='submit' >
                                {editMode ? 'Update' : 'Save'}
                            </ButtonLoader>

                        </Space>

                    </CommonDiv>

                </Form>

            </Container>

            {
                editMode && <CommonSelectModal
                    visible={showReset}
                    title='Send request to reset password or 2FA token.'
                    okHandler={sendResetHandler}
                    cancelHandler={() => setShowReset(false)}
                >
                    <CommonDiv direction="row" justify="space-around" >
                        <Checkbox ref={resetPass} >Reset password.</Checkbox>
                        <Checkbox ref={reset2FA}>Reset 2FA Token</Checkbox>
                    </CommonDiv>

                </CommonSelectModal>
            }


            <Modal
                visible={showApprove}
                onOk={approveUserHandler}
                onCancel={() => setShowApprove(false)}
                okText="Approve"
                cancelText="Later"
                closable={false}
                centered
            >
                <ModalContainer>
                    <LikeOutlined style={{ color: THEME.colors.primary, fontSize: 150 }} />
                    <h2>Approve onboarding for user: {userData.full_name}</h2>
                    <span>This action will activate the user and an auto generated link for password creation will be sent to ({userData.email})</span>
                </ModalContainer>

            </Modal>

        </UsersCardContext.Provider>
    )
}

UsersCard.propTypes = {}

export default UsersCard
