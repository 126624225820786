import { Button, Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import './style.scss'

function BidSpecs({ onChange, value, ...rest }) {

    const inputRef = useRef()
    const [list, setList] = useState([])

    useEffect(() => {
        !_.isUndefined(value) && setList(value)
    }, [value])

    const addItem = () => {
        const itemValue = inputRef.current.state.value

        if (!_.isEmpty(itemValue)) {
            const newList = [...list, itemValue]
            setList(newList)
            inputRef.current.state.value = ""
            onChange(newList)
        }
    }

    const removeItem = (index) => {
        const _list = _.cloneDeep(list)
        _.pullAt(_list, index)
        setList(_list);
        onChange(_list)
    }

    return (
        <div className="bid-specs-input-wrapper">
            <div className="list-wrapper">
                {list.map((v, i) => <div key={`bid-specs-item-${i}`} ><span onClick={() => removeItem(i)} className="list-item" >{v}</span></div>)}
            </div>
            {!_.isEmpty(list) && <p className="remove-info" >Click items to remove</p>}
            <div className="list-input-wrapper" >
                <Input ref={inputRef} allowClear onPressEnter={() => addItem()} placeholder="Type bid specification item." />
                <Button onClick={() => addItem()} type="primary" >Add</Button>
            </div>
        </div>
    )
}

BidSpecs.propTypes = {
    onChange: PropTypes.func
}

export default BidSpecs
