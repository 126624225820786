import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { sanitize } from 'string-sanitizer'


const Container = styled.div`
    border-bottom: solid 1px #ccc;
    padding-bottom: 10px;
    display: flex ;
    flex-direction: row ;
`
const ItemWrapper = styled.div`
    margin-right: 15px;
`

function CardSubMenu({ items = [] }) {
    return (
        <Container>
            {items.map((item, i) => <ItemWrapper key={`card-sub-menu-${i}`} >{item}</ItemWrapper>)}
        </Container>
    )
}

CardSubMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any)
}

export default CardSubMenu