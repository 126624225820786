import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";

import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { ApolloProvider } from "@apollo/client/react";
import { RecoilRoot } from "recoil";

const token = localStorage.getItem("token") || "";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL,
  headers: {
    authorization: `Bearer ${token}`,
    // 'x-hasura-admin-secret': "secret
  },
  credentials: "include",
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_HASURA_GRAPHQL,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        authorization: `Bearer ${token}`,
        // 'x-hasura-admin-secret': "secret
      },
    },
  },
});

/** Combine http and ws links */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

/** apollo client */
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <RecoilRoot>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
