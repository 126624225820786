import { Select, Input, Switch, Popconfirm, notification } from 'antd';
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { Link, useHistory } from 'react-router-dom';
import { useContext, useState } from 'react';
import { SearchOutlined, PlusOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons';
import { ExtraFilters } from '.';
import { AuctionQuotaContext } from '../../Quotas/index';
import { AutoBidSwitch } from '../../components';
import { useRecoilState } from 'recoil';
import { auctionQuotasTableFiltersAtom } from 'Store/auctionAtom';
import { debounce } from 'lodash';

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const MenuButtonContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

function TableFilters(props) {

    const history = useHistory()
    // const { fitler, setFitler } = useContext(AuctionQuotaContext)
    const [ filter, setFilter] = useRecoilState(auctionQuotasTableFiltersAtom)
    const [check, setCheck] = useState(false)
    
    const updateQuery = (e) => {
        setFilter({ ...filter, searchText: e.target.value })
    }
    const debouncedOnChange = debounce(updateQuery, 200);

    const handleConfirm = () => {
        setCheck(!check)
    }
    return (
        <Container>

            <DropdownContainer>
                <label>Quotas: </label>
                <Select defaultValue="all"
                    style={{ width: 100 }}
                    bordered={false}
                    // onChange={v => { setFitler(v) }}
                >
                    <Option value="all">All</Option>

                </Select>
            </DropdownContainer>

            <SearchContainer>
                <Input prefix={<SearchOutlined />} style={{ width: 200 }} placeholder="Search" onChange={(e) => debouncedOnChange(e)}/>
            </SearchContainer>

            {/* <MenuButtonContainer>
                <label> Auction 22/619 :  <span style={{ color: THEME.colors.primary }} >Pending Results</span> </label>
            </MenuButtonContainer> */}

            {/* <MenuButtonContainer>
                <Popconfirm placement="left" title='Are you sure you want to do this?' onConfirm={handleConfirm} okText="Yes" cancelText="No">
                    <AutoBidSwitch />
                </Popconfirm>
            </MenuButtonContainer> */}

            <ExtraFilters data={props.data} columns={props.columns}/>

        </Container>
    )
}

TableFilters.propTypes = {}

export default TableFilters
