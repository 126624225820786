import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import React, { useState } from 'react';
import { GET_LANGUAGES } from 'Util/queries';
const { Option } = Select;

function LanguageDropdown({ onChange, ...rest }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_LANGUAGES, {
        onCompleted() {
            setList(data.user_language)
        }
    });

    return (
        <div>
            <Select
                {...rest}
                placeholder="Select a language"
                onChange={onChange}
            >
                {list.map(v => <Option value={v.id} key={`language-dd-opt${v.id}`}>{v.label}</Option>)}
            </Select>
        </div>
    )
}

export default LanguageDropdown
