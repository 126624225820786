import React from 'react'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import ButtonCard from 'Components/ButtonCard'

const ProcurementPage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            <div style={{ display: 'flex' }} >
                <ButtonCard link='/procurement/species-database' title={'Species Database'} blue />
                <ButtonCard link='/procurement/product-list' title={'Product List'} blue />
                <ButtonCard link='/procurement/collection-points' title={'Collection Points'} blue />
                <ButtonCard link='/procurement/auction' title={'Auction Systems'} blue />
                <ButtonCard link='/procurement/direct-sales/offers' title={'Direct Sales'} blue />
                <ButtonCard link='/procurement/purchase-orders/data' title={'Purchase Orders'} blue />
            </div>
        </div>
    )
}

ProcurementPage.propTypes = {}

export default ProcurementPage
