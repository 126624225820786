import { Form } from 'antd'
import React, { useState } from 'react'
import AdminUserForm from './components/Form'
import AdminUserList from './components/List'

function UserManagement(props) {

    const [form] = Form.useForm();
    const [drawer, setDrawer] = useState(false)
    const [editId, setEditId] = useState(null)

    const onEdit = (record) => {
        form.setFieldsValue({ ...record });
        setDrawer(true)
        setEditId(record.id)
    }

    const drawerToggle = (status) => {

        if (status) {
            form.resetFields()
            setEditId(null)
            setDrawer(true);
            return null
        }

        setDrawer(false)
    }



    return (
        <div className='admin-user-management'>
            <AdminUserForm
                form={form}
                drawer={drawer}
                drawerToggle={drawerToggle}
                editId={editId}
            />
            <AdminUserList onEdit={onEdit} />
        </div>
    )
}

UserManagement.propTypes = {

}

export default UserManagement

