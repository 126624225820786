import React, { useState } from 'react'
import { Dropdown, Menu, notification, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { approvalStatus } from 'Util/constants';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_ADMIN_APPROVAL, UPDATE_ADMIN_USER } from 'Util/mutations';
import { sendCreateAdminEmail } from 'Util/mailer';
import _ from 'lodash'
import { GET_ADMIN_USER } from 'Util/queries';
import User from 'Hooks/user';


function ApproveActions({ ids }) {

    const [lbl, setLbl] = useState("Pending")
    const [admin_user, setAdminUser] = useState(null)

    const { user_id } = User()

    useQuery(GET_ADMIN_USER, {
        variables: {
            where: { id: { _eq: ids.user_id } }
        },
        onCompleted(data) {
            data.admin_user && setAdminUser(data.admin_user[0])
        }
    })

    const [update] = useMutation(UPDATE_ADMIN_APPROVAL, {
        onCompleted(data) {
            notification.success({
                message: "Status updated!"
            })
        }
    })

    const [updateAdminUser] = useMutation(UPDATE_ADMIN_USER, {
        onCompleted(data) { }
    })

    const onAction = async (status) => {

        await update({
            variables: {
                where: { id: { _eq: ids.request_id } },
                _set: { status, approver_id: user_id }
            }
        })

        if (status === 'approved') {
            updateAdminUser({ variables: { _eq: ids.user_id, _set: { is_pending: false, status: true } } })
            sendCreateAdminEmail({ email: admin_user.authentication_email ?? admin_user.email, full_name: admin_user.full_name, reset_token: admin_user.reset_token })
        }

        const res = _.find(approvalStatus, ['value', status])
        setLbl(res.label)
    }


    const menu = (
        <Menu >
            {approvalStatus.map(v => {
                const res = _.find(approvalStatus, ['value', v.value])
                return <Menu.Item key={v.value} onClick={() => onAction(v.value)} >{res.label}</Menu.Item>
            })}
        </Menu >
    );

    return (
        <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
                <Space> {lbl} <DownOutlined /> </Space>
            </a>
        </Dropdown>
    )
}

export default ApproveActions