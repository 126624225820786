import axios from "axios"
export const uploadFile = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("api_key", "926696532366861");
  formData.append("upload_preset", "species");
  formData.append("timestamp", (Date.now() / 1000) | 0);
  return axios
    .post(
      "https://api.cloudinary.com/v1_1/catch-value/image/upload",
      formData,
      {
        headers: { "X-Requested-With": "XMLHttpRequest" },
        // headers: {
        //     "Content-Type": "multipart/form-data",
        // },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("err", err);
    });

};

/** TODO: implement signed upload to allow overwriting of images */


// import axios from "axios"
// import { createSignature } from "./common";

// export const uploadFile = (file, name) => {

//   const data = new FormData();
//   data.append("file", file);
//   data.append("api_key", '926696532366861');
//   data.append("upload_preset", "species");
//   data.append('public_id', name);
//   data.append('timestamp', (Date.now() / 1000) | 0);
//   const signature = createSignature(data)
//   data.append('signature', signature);

//   console.log(signature)

//   const url = `https://api.cloudinary.com/v1_1/catch-value/image/upload`;

//   return axios.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }, })


// }