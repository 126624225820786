import { atom } from "recoil";

export const directSalesAtom = atom({
    key: "directSalesStates",
    default: {}
})

export const directSalesFilterAtom = atom({
    key: "directSalesFilterStates",
    default: {
        status: 'open', /** [open, accepted, widthdrawn ] */
        search: ''
    }
})