import React from 'react'
import PropTypes from 'prop-types'
import ButtonCard from 'Components/ButtonCard'
import { useLocation, useParams } from 'react-router-dom'

const data = [
    { link: '/logistics/carriers', title: 'All' },
    // { link: '/logistics/depots/collection-point', title: 'Collection Point' },
    // { link: '/logistics/depots/collection-hub', title: 'Collection Hub' },
    // { link: '/logistics/depots/processing-plant', title: 'Processing Plant' },
    // { link: '/logistics/depots/cold-room-warehouse', title: 'Cold Room Warehouse' }
]

function MenuButton(props) {
    const location = useLocation();
    return (
        <div style={{ display: 'flex' }} >
            {data.map(v => (<ButtonCard
                key={v.link}
                link={v.link}
                title={v.title}
                lightBlue
                selected={location.pathname === v.link}
            />))}
        </div>
    )
}

MenuButton.propTypes = {}

export default MenuButton
