import { useSubscription } from '@apollo/client';
import { Table } from 'antd';
import leadingZeroes from 'leading-zeroes';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getDate, getTimeFromDate } from 'Util/common';
import { appConstant, DIRECT_OFFER_STATUS } from 'Util/constants';
import { SUB_DIRECT_OFFER_ORDERS } from 'Util/subscriptions';
import { acceptedColumns } from '../../common';
import { directSalesFilterAtom } from 'Store/directSalesAtom';
import { useRecoilState } from 'recoil';
import { TableFilters } from './';

const Container = styled.div``

function AcceptedDataTable() {

    /** Global State */
    // const [filter] = useRecoilState(directSalesFilterAtom)
    const [filter, setFilter] = useRecoilState(directSalesFilterAtom)

    /** Local State */
    const [data, setData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    /** GraphQL Hooks */
    const { loading } = useSubscription(SUB_DIRECT_OFFER_ORDERS, {
        variables: {
            where: {
                status: { _in: [DIRECT_OFFER_STATUS.accepted] }
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            data && data.direct_offer_orders && setData(data.direct_offer_orders)
        }
    });

    const filterData = (data) => {

        let dSource = data.map((v, i) => (
            {
                ...v.direct_offer,
                key: `key-${i}-direct-offers-accepted-list`,
                location: v.direct_offer.user_info.user_location.label || "",
                vendor: leadingZeroes(v.direct_offer.user_id, appConstant.leadingZero),
                time: getTimeFromDate(v.created_at, true),
                date: getDate(v.created_at),
                po: !_.isNull(v.purchase_order) ? leadingZeroes(v.purchase_order.id, appConstant.leadingZero) : v.purchase_order,
                price: v.order_quantity * v.direct_offer.price,
                order_quantity: v.order_quantity
            }
        ))
        const searchable = dSource.map(obj=>({
            ...obj,
            searchText: `${ obj.date } ${ obj.time } ${obj.id} ${obj.vendor} ${obj.location} ${ obj.species_name } ${ obj.category } ${obj.preservation_method} ${obj.country_origin} ${obj.price} ${obj.offer_type} ${obj.order_quantity} ${obj.po}`
          }))  

        if(!filter.search){
            return dSource
        }
        return _.filter(searchable, function (d) { 
            let query = filter.search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    }

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    // };

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={acceptedColumns}/>
            <Table
                loading={loading}
                size='small'
                columns={acceptedColumns}
                dataSource={filterData(data)}
                // rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 50
                }}
            />
        </Container>
    )
}

export default AcceptedDataTable    