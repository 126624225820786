import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { DownOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm, Space } from 'antd';
import _ from 'lodash';

const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        margin-left: 5px;
    }
    label{
        font-weight: bold;
    }
    .ant-space-item{
        color: #000;
        &:first-child{
            font-weight: bold;
        }
    }
`;

function ManageDropDown({ selectedRecord = {}, onEdit, onDelete }) {

    const _onEdit = () => {
        if (!_.isUndefined(selectedRecord.id)) {
            onEdit(selectedRecord.id)
        }
    }


    const confirm = (status) => {
        if (!_.isUndefined(selectedRecord.id)) {
            if (status) {
                console.log('Click Yes')
            }
        }
    };

    const cancel = (e) => {
        console.log('Click on No');
    };


    const menu = (
        <Menu>

            <Menu.Item disabled={_.isUndefined(selectedRecord.id)} onClick={_onEdit}>
                <span><EditOutlined style={{ color: THEME.colors.primary }} />Edit</span>
            </Menu.Item>

            {/* <Menu.Item> <a target="_blank" href="#" > <EyeOutlined style={{ color: THEME.colors.primary }} />View </a> </Menu.Item> */}

            <Menu.Item disabled={_.isUndefined(selectedRecord.id)} >
                <Popconfirm
                    title="Are you sure to delete this item?"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                    trigger={_.isUndefined(selectedRecord.id) ? 'none' : 'click'}
                >
                    <span><DeleteOutlined style={{ color: THEME.colors.primary }} />Delete</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
        <Container>
            <Dropdown overlay={menu} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        Manage
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Container>
    )
}

ManageDropDown.propTypes = {
    selectedRecord: PropTypes.any.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired
}

export default ManageDropDown