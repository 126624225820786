import React, {useState} from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { DataTable, NewTagModal } from './components'
import { Button } from 'antd';
import { THEME } from 'Util/constants';
import { ADD_INSERT_USER_TAGS } from 'Util/mutations';
import { useMutation } from '@apollo/client';

const Container = styled.div``

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .custom-button {
        border-radius: 0px; 
        background-color: ${THEME.colors.lightBlue};
        color: white;
    }
`

const auctionMenu = [
    { title: "All Users", link: "/crm/notifications/all-users" },
    { title: "Segments", link: "/crm/notifications/segments" },
    { title: "Tags", link: "/crm/notifications/tags" },
    { title: "Polls", link: "/crm/notifications/polls" }
]

function CRMTags() {
    const [visible, setVisible] = useState(false)
    const onCancel = () => {setVisible(false)}

    const [add] = useMutation(ADD_INSERT_USER_TAGS, {
        onCompleted() {
            setVisible(false)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const onSubmit = (values) => {
        console.log('submitted');
        add({
            variables: {
              update_columns: ["id", ...Object.keys(values)],
              objects: [
                {
                  ...values
                },
              ],
            },
        });
    }

    return (
        <>
            <Container>
                <SubPageBreadCrumbs />
                <Level2Menu menu={auctionMenu} />
                <ButtonWrapper>
                    <Button shape="round" className="custom-button" onClick={() => setVisible(!visible)}>New Tag</Button>
                </ButtonWrapper>
                <NewTagModal visible={visible} onCancel={onCancel} onSubmit={onSubmit}/>
                <DataTable />
            </Container>
        </>
    )
}

export default CRMTags
