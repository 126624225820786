import React, { useContext } from 'react'
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, notification, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_JOBS, UPSERT_ROUTES } from 'Util/mutations'
import moment from 'moment'
import { LogisticsRouteContext } from '../..';
import { deleteRoute, addOrder } from 'Util/trackpod'

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;


function Delete(props) {
    const { selectedRecord, setSelectedRecord } = useContext(LogisticsRouteContext)

    const [upsert] = useMutation(UPSERT_ROUTES, {
        onCompleted(){
            notification["success"]({
                message: 'Deleted',
                description: "Route has been deleted"
            });
        }
    })
    const [updateJobs] = useMutation(UPDATE_JOBS)
  
    const confirmDelete = async () => {    
        const body = {
            "Code": selectedRecord.code
        }

        console.log('selectedRecord', selectedRecord);

        try {
            const res = await deleteRoute(body) 
            console.log('res', res);
            const { Detail, Status, Title } = res
            console.log({ Detail, Status, Title});
    
            if(Status !== 200){
              notification["error"]({
                message: Title,
                description: Detail,
              });
              return
            }


            if (selectedRecord.jobs){
                const addOrdersList = selectedRecord.jobs.map(item => ({
                    Number: item.id.toString(),
                    Id: item.id.toString(),
                    Date: item.date,
                    Type: item.type,
                    Shipper: item.shipper.name,
                    ShipperId: item.shipper_id.toString(),
                    Depot: item.depot.name,
                    Client: item.client,
                    Address: item.address,
                    TimeSlotFrom: item.time_from,
                    TimeSlotTo: item.time_to,
                    ServiceTime: item.service_time,
                    Note: item.note,
                    ContactName: item.contact_name,
                    Phone: item.phone,
                    Email: item.email,
                    Barcode: item.barcode,
                    DepotId: item.depot_id.toString(),
                    GoodsList: item.purchase_orders.map(order => ({
                    GoodsId: order.bid?.product.sku ?? order.direct_offer_order.direct_offer.species_sku,
                    GoodsName: order.bid?.product.label ?? order.direct_offer_order.direct_offer.species_name,
                    GoodsUnit: "KG",
                    Quantity: order.bid?.volume ?? order.direct_offer_order.order_quantity,
                    Cost: order.bid?.price ?? order.direct_offer_order.direct_offer.price
                    }))
                }));

                addOrdersList.map(async item => {
                    console.log(`Processing job with ID: ${item.Id}`);
                    try {
                        const res = await addOrder(item)
                        const { Detail, Status, Title } = res
                        console.log({ Detail, Status, Title });
                    } catch (error) {
                        console.error(`Failed to create job with ID: ${item.Id}`, error);
                        return null
                    }
                    return null
                });
            }
          } catch (error) {
            console.log('errorerrorerror', error);
            if (error.response) {
              console.error('Error status code:', error.response.status);
              if (error.response.status === 405) {
                  console.error('Error 405: Method Not Allowed');
              } else if (error.response.status === 500) {
                  console.error('Error 500: Internal Server Error');
              }
          } else {
              console.error('Error occurred:', error);
          }
            notification["error"]({
              message: "Something went wrong!",
              description: "Please try again later.",
            });
            return
          }
    
        const objects = {
            id: selectedRecord.id,
            code: selectedRecord.code,
            deleted_at: moment.tz("Asia/Kolkata").format('HH:mm:ssZ') //TODO: Update column type in hasura to timestamp
        };
      
        await upsert({ variables: { 
                update_columns: ["id", ...Object.keys(objects)],
                objects: [objects] 
            } 
        })

        const ids = selectedRecord.jobs.map(item => item.id);

        updateJobs({
            variables: {
              where: { id: { _in: ids } },
              _set: { route_id: null, deleted_at: null }
            }
        })

        setSelectedRecord({})
    }

    const confirm = () => {
        selectedRecord.id && Modal.confirm({
            centered: true,
            icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
            content: `Delete Route ${selectedRecord.code}?`,
            autoFocusButton: 'ok',
            okButtonProps: {
                style: { backgroundColor: THEME.colors.primary }
            },
            onOk: confirmDelete
        });
    };

    if (!selectedRecord) return <DeleteFilled />

    return (
        <Container>
            <Tooltip title={selectedRecord.code}>
                <DeleteFilled onClick={confirm} />
                <label>Delete</label>
            </Tooltip>
        </Container>
    )
}

Delete.propTypes = {}

export default Delete
