import { THEME } from "Util/constants";
import {
  Form,
  Input,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useLazyQuery, useMutation } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import { UPSERT_CARRIERS } from "Util/mutations";
import { useHistory } from "react-router-dom";
import { Header } from "./components/CarriersCard";
import { GET_CARRIERS } from "Util/queries";
import PhoneInput from "Components/PhoneValidator";

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function CarriersCard(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  const editMode = routeLocation.pathname.includes("/logistics/carriers/edit");
  const [formLoading, setFormLoading] = useState(false);

  const [update] = useMutation(UPSERT_CARRIERS, {
    onCompleted(){
      notification.success({
        message: "Saved!",
        description: "Carriers Saved!",
      });
  
      setFormLoading(false)
      history.goBack()
    },
    onError(error){
      notification.error({
        message: "Error!",
        description: {error}
      });
    }
  })

  const [getCarrier] = useLazyQuery(GET_CARRIERS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
        console.log('datadepot', data);
        const details = data.carriers[0]
        form.setFieldsValue({
            ...details
        })
    }
  })

  useEffect(() => {
    if (editMode) {
      console.log('querying...')
      getCarrier({ variables: { where: { id: { _eq: params.id } } }, });
    } 
  }, [editMode, getCarrier, params.id]);


  const submitHandler = async (values) => {

    setFormLoading(true)

    const { ...details } = values;

    const objects = {
      ...details
    };

    await update({ variables: { 
      update_columns: ["id", ...Object.keys(details)],
      objects: [objects] 
      } 
    })
  };

  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  return (
    <div>
      <Header editMode={editMode}/>
      <Form
        form={form}
        name="carrierForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="Carrier Details">
          <div className="division">

            <Form.Item label="id" name="id" style={{ display: 'none' }} >
              <Input />
            </Form.Item>

            <Form.Item label="Carrier Code" name="code" rules={[{ required: true, message: '' }]}>
              <Input disabled={editMode}/>
            </Form.Item>

            <Form.Item label="Carrier Name" name="name" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Contact Name" name="contact_name">
              <Input />
            </Form.Item>

            <Form.Item label="Contact Phone" name="contact_phone">
              <PhoneInput />
            </Form.Item>

            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>

            {/* <Form.Item 
                name="active" 
                label="Active" 
                valuePropName="checked" 
                initialValue={false}
            >
                <Switch />
            </Form.Item> */}

          </div>
        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit" >
            {editMode ? "Update" : "Save"}
          </ButtonLoader>

          <CommonButton className='reverse' onClick={() => history.goBack()}>
            Cancel
          </CommonButton>

        </CommonDiv>
      </Form>
    </div>
  );
}

CarriersCard.propTypes = {};

export default CarriersCard;
