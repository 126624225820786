import axios from "axios"
const { REACT_APP_AUTH_ENDPOINT } = process.env

export const sendOnBoardingSMS = async ({ to, name, action = "approve" }) => {

    /** Reject Message initial */
    let message = `Hi ${name}, We are sorry to inform that your Catch Value application has been denied. You can create a new application for us to review.`

    if (action === "approve") {
        message = `Hi ${name}, Your Catch Value account application has been approved! You can now log in on our mobile App.`
    }

    return axios.post(`${REACT_APP_AUTH_ENDPOINT}/sms/send`, { message, to })

}