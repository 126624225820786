import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Popover, Tabs } from "antd";
import styled, { css } from "styled-components";
import { THEME } from "Util/constants";
import TableFilter from "./TableFilter";
import _ from "lodash";
import { PaperClipOutlined, InfoCircleFilled } from "@ant-design/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { listOrderAtom, selectedSpecies, infoIconAtom } from "Store/procurementAtom";
import { useHistory } from "react-router-dom";
import CommonButton from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import { speciesSearchAtom } from 'Store/procurementAtom';

const { TabPane } = Tabs;

const Container = styled.div`
	.ant-table-thead {
		.ant-table-cell {
			color: #999;
			font-weight: bold;
		}
	}

	.ant-table-tbody > tr:hover > td {
		background-color: "none";
	}
	.ant-table-tbody > tr.selected > td {
		background-color: ${THEME.colors.primary};
	}

  .shorten{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
`;

const GridContainer = styled.div`
	.fish-img {
		height: 100px;
		width: 100px;
	}
	* {
		padding: 2px;
	}
	.flex-row {
		display: flex;
		flex-direction: row;
	}
	.mr {
		margin-right: 10px;
	}
	.basis {
		flex-basis: 33%;
		background-color: #eee;
	}
  .basisclicked {
		flex-basis: 33%;
		background-color: rgba(22, 180, 177, 0.5);
	}
  .basis:hover {
		flex-basis: 33%;
		background-color: #e5e5e5;
	}
	.title {
		font-weight: bold;
		font-size: 12px;
	}
	.space-between {
		justify-content: space-between;
		width: 100%;
	}
	.link {
		text-decoration: underline;
		color: #16b4b1;
		font-size: 16px;
		cursor: pointer;
	}
	.flex-end {
		align-self: flex-end;
	}
	.bold {
		font-weight: bold;
	}
  .item-center {
    align-self: center;
  }
`;

const PopContainer = styled.div`
	
  .ant-tabs-tabpane {
		width: 450px;
		label.title { font-weight: bold; }
		.data-container {
			.data-item {
				display: flex;
				justify-content: space-between;
				label { color: #555; }
				span { color: ${THEME.colors.primary}; }
			}
		}
	}

  .bold{ font-weight: bold; }
`;

const columns = [
  {
    title: "Species Name",
    dataIndex: "label",
    key: "label",
  },
  {
    title: "Malayalam",
    dataIndex: "malayalam",
    key: "malayalam",
  },
  {
    title: "Tamil",
    dataIndex: "tamil",
    key: "tamil",
  },
  {
    title: "Bengali",
    dataIndex: "bengali",
    key: "bengali",
  },
  {
    title: "Marathi",
    dataIndex: "marathi",
    key: "marathi",
  },
  {
    title: "Gujarati",
    dataIndex: "gujarati",
    key: "gujarati",
  },
  {
    title: "Telugu",
    dataIndex: "telugu",
    key: "telugu",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    className: "shorten",
    width: 400
  }

]

const PopContent = ({ data, module }) => {
  const history = useHistory()
  const [species, setSelectedSpecies] = useRecoilState(selectedSpecies);

  return (
    <PopContainer>
      <div className="tab">
        <Tabs defaultActiveKey="1" centered>
          <TabPane
            className="data-pane"
            tab={
              <span>
                {" "}
                <InfoCircleFilled /> Details
              </span>
            }
            key="1"
          >

            <div style={{ display: "flex", justifyContent: "flex-end" }} >
              {data?.image && <img src={data?.image} style={{ width: '80px', border: 'solid 1px #ccc' }} />}
            </div>

            <label className="title">
              Species Details - General
            </label>

            <div className="data-container">
              <div className="data-item">
                <label className="bold" >Species Name:</label>
                <span>{data.label}</span>
              </div>
              <div className="data-item">
                <label className="bold" >Status:</label>
                <span style={{ textTransform: "capitalize" }}>
                  {data.status ? "Active" : "Inactive"}
                </span>
              </div>
              <div className="data-item">
                <label className="bold" >Species Description:</label>
              </div>
              <span>{data.description}</span>

            </div>

            {
              module === "direct-sales" && <CommonDiv align="center" margin="10px 0px" >
                <CommonButton
                  onClick={() => {
                    console.log(data)
                    setSelectedSpecies(data)
                    history.push(`/procurement/direct-sales/new/new-selection-list`)
                  }}
                >Add to Direct selection list</CommonButton>
              </CommonDiv>
            }

          </TabPane>
          <TabPane
            tab={
              <span>
                <PaperClipOutlined /> Attachements
              </span>
            }
            key="2"
          >
            N/A
          </TabPane>
        </Tabs>
      </div>
    </PopContainer>
  );
};

function GridView({ data, module }) {
  const history = useHistory()
  const [searchRecord, setSearchRecord] = useRecoilState(speciesSearchAtom)
  const [species, setSelectedSpecies] = useRecoilState(selectedSpecies);

  const goToLink = (item) => {
    if (module === "species") {
      history.push(`/procurement/species-database/new/edit/${item.id}`)
    }
  }

  const searchable = data.map((obj, i) => ({
    key: i,
    ...obj,
    searchText: `${obj.label}`
  }))

  data = _.filter(searchable, function (d) {
    let query = searchRecord.toLowerCase();
    return _.includes(d.searchText.toLowerCase(), query)
  });

  return (
    <GridContainer>
      <div style={{ gap: 5, display: "flex", flexWrap: "wrap" }}>
        {data.map((item) => (
          <div className={species.id === item.id ? "flex-row basisclicked" : "flex-row basis"} key={`grid-species-item-${item.id}`} onClick={() => setSelectedSpecies(item)}>
            <div className="mr">
              <img
                className="fish-img"
                src={item?.image ?? `https://via.placeholder.com/150?text=${item?.label}`}
                alt={item?.label}
              />
            </div>
            <div className="flex-row">
              <a className="link bold item-center" onClick={() => goToLink(item)}>{item.label}</a>
            </div>
          </div>
        ))}
      </div>
    </GridContainer>
  );
}

function DataTable({
  pageSize = 50,
  height = 250,
  data = [],
  loading = false,
  module = "species"
}) {
  const listOrGrid = useRecoilValue(listOrderAtom);
  const [species, setSelectedSpecies] = useRecoilState(selectedSpecies);
  const [searchRecord, setSearchRecord] = useRecoilState(speciesSearchAtom)
  const [selectedRow, setSelectedRow] = useState({});
  const [info, setInfo] = useRecoilState(infoIconAtom)
  const [visible, setVisible] = useState(false)

  const searchable = data.map((obj, i) => {
    let result = [];
    let concatenatedValues = "";
    columns.forEach(column => {
      if (obj[column.key] && obj[column.key] !== undefined) concatenatedValues += obj[column.dataIndex] + " ";
    });
    result.push(concatenatedValues.trim());

    return {
      key: i,
      ...obj,
      searchText: result.toString()
    }
  })

  const filterData = (data) => {
    if (!searchRecord) {
      return data
    }
    return _.filter(searchable, function (d) {
      let query = searchRecord.toLowerCase();
      return _.includes(d.searchText.toLowerCase(), query)
    });
  }

  useEffect(() => {
    return () => {
      setSelectedSpecies({})
      setInfo(false)
    }
  }, [])

  const handleRowDoubleClick = (row) => {
    let visible = selectedRow && selectedRow?.id === row?.id
    if (visible) {
      setVisible(true)
      setSelectedRow(row);
    } else {
      setVisible(false)
      setSelectedRow({});
    }
  };

  const handleRowClick = (row) => {
    setVisible(false)
    setSelectedRow(row)
    setSelectedSpecies(row);
  }

  return (
    <Container>
      <TableFilter data={filterData(data)} columns={columns} />
      {listOrGrid === "list" ? (
        <Table
          loading={loading}
          columns={columns}
          dataSource={filterData(data)}
          pagination={{
            defaultPageSize: pageSize,
          }}
          scroll={{ y: height, x: 1500, }}
          onRow={(rec, idx) => ({
            onClick: (e) => {
              handleRowClick(rec)
            },
            onDoubleClick: (event) => {
              handleRowDoubleClick(rec);
            }          
          })}
          rowKey={(rec) => parseInt(rec.id, 10)}
          components={{
            body: {
              row: (props) => {
                const row = _.find(filterData(data), {
                  id: props["data-row-key"],
                });
                let selected = false;
                if (!_.isEmpty(species) && !_.isEmpty(row)) {
                  selected = row.id === species.id;
                }
                let rowvis = selectedRow && selectedRow?.id === row?.id

                const tableRow = (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={props.onClick}
                    className={`ant-table-row ant-table-row-level-0 yeah ${selected ? "selected" : ""}`}
                    onDoubleClick={info.species ? props.onDoubleClick : null}
                  >
                    {props.children}
                  </tr>)
                return (
                  info.species ? (<Popover
                    content={<PopContent data={row} module={module} />}
                    placement="bottom"
                    visible={rowvis && visible}
                  >
                    {tableRow}
                  </Popover>) : (tableRow)
                );
              },
            },
          }}
        />
      ) : (
        <GridView data={data} module={module} />
      )}
    </Container>
  );
}

DataTable.propTypes = {
  pageSize: PropTypes.oneOf([10, 20, 50, 100]),
  height: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  module: PropTypes.string
};

export default DataTable;
