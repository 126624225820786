import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import CommonDiv from 'Components/CommonDiv';
import { NotificationNetworkIcon, NotificationPeopleIcon, NotificationTradeIcon, NotificationTruckIcon } from 'Components/Icons';
import { Badge, Button, Drawer, Tabs, Typography } from 'antd';
import { createContext, useState } from 'react';
import styled from 'styled-components'
import NotificationBox from './NotificationBox'
import NotificationView from './NotificationView'
import { THEME, notification } from 'Util/constants';
import { useSubscription } from '@apollo/client';
import { ADMIN_NOTIFICATIONS, ADMIN_NOTIFICATIONS_RECIPIENT } from 'Util/subscriptions';
import _ from 'lodash'
import { NotificationContext } from './NotificationContext'
import User from 'Hooks/user';

const IconWrapper = styled.div`
    position: relative;
    border: 1px solid #54b2b0;
    padding: 5px 8px;
    border-radius: 100%;
    background-color: #fff;
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
        
    background-color: ${props => props.selected ? "#c2d7ec" : "#fff"} ;
    
    .notification{
        display: block;
        width: 10px;
        height: 10px;
        background-color: #b02518;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        right: 0px;
    }
`

const TabWrapper = styled.div`
    .ant-tabs-nav-list{
        width: 100%;
        &.ant-tabs-ink-bar{
            background: ${THEME.colors.primary}
        }
        .ant-tabs-tab{
            width: 100%;
            justify-content: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            background-color: #d9d9d9;
            margin: 0px;
            color: ${THEME.colors.darkGray};
            &.ant-tabs-tab-active{
                background-color: #fff;
                .ant-tabs-tab-btn{
                    color: ${THEME.colors.primary};    
                }
            }
        }
    }
`

const { Title } = Typography;
const { TabPane } = Tabs;

const Notification = () => {

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('unread');
    const [category, setCategory] = useState(notification.category.ALL)
    const [readNotifications, setRead] = useState([]);
    const [unreadNotifications, setUnread] = useState([]);
    const [show, setShow] = useState(false);
    const [data, setData] = useState({})
    const { user_id } = User()

    const handleBellClick = () => {
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    useSubscription(
        ADMIN_NOTIFICATIONS_RECIPIENT, {
        variables: {
            where: { admin_user_id: { _eq: user_id }}
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            if (data && !_.isEmpty(data.admin_notifications_recipient)) {
                setRead(_.filter(data.admin_notifications_recipient, o => o.read))
                setUnread(_.filter(data.admin_notifications_recipient, o => !o.read))
            }
        }
    })

    const filtered = (data) => {
        if (category !== notification.category.ALL) {
            return _.filter(data, (obj) => obj.admin_notification.category === category);       
        }
        return data
    }

    return (
        <NotificationContext.Provider value={{
            show, setShow,
            data, setData
        }}>
            <CommonDiv direction="row" justify="flex-end" w={50}>
                <Badge count={unreadNotifications.length} onClick={handleBellClick}>
                    <BellOutlined style={{ fontSize: '28px', cursor: "pointer", color: THEME.colors.primary }} />
                </Badge>
                <Drawer
                    title={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Title level={4}>Notifications</Title>
                            <Button onClick={handleDrawerClose} type="text">
                                <CloseOutlined style={{ fontSize: '20px' }} />
                            </Button>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={handleDrawerClose}
                    visible={drawerVisible}
                    width={400}
                    bodyStyle={{ backgroundColor: '#f2f2f2', padding: 0 }}
                    headerStyle={{ backgroundColor: '#f2f2f2' }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10 }}>

                        <CommonDiv direction="row" justify="space-between">
                            <IconWrapper selected={category === notification.category.ALL} onClick={() => (setCategory(notification.category.ALL))} >
                                <span style={{ color: THEME.colors.primary, fontWeight: 'bold' }} >ALL</span>
                                <span className='notification' ></span>
                            </IconWrapper>
                            <IconWrapper selected={category === notification.category.ADMIN} onClick={() => (setCategory(notification.category.ADMIN))} >
                                <NotificationNetworkIcon size={20} />
                                <span className='notification' ></span>
                            </IconWrapper>
                            <IconWrapper selected={category === notification.category.PROCUREMENT} onClick={() => (setCategory(notification.category.PROCUREMENT))} >
                                <NotificationTradeIcon size={20} />
                                <span className='notification' ></span>
                            </IconWrapper>
                            <IconWrapper selected={category === notification.category.LOGISTICS} onClick={() => (setCategory(notification.category.LOGISTICS))} >
                                <NotificationTruckIcon size={20} />
                                <span className='notification' ></span>
                            </IconWrapper>
                            <IconWrapper selected={category === notification.category.CRM} onClick={() => (setCategory(notification.category.CRM))} >
                                <NotificationPeopleIcon size={20} />
                                <span className='notification' ></span>
                            </IconWrapper>
                        </CommonDiv>

                    </div>

                    <TabWrapper>
                        <Tabs activeKey={activeTab} onChange={handleTabChange} style={{ padding: 10 }} >


                            <TabPane tab={`Unread (${unreadNotifications.length})`} key="unread">
                                {unreadNotifications.length === 0 && <p>No unread notifications</p>}
                                {unreadNotifications.map((notification) => (
                                    <p key={notification.id}>{notification.message}</p>
                                ))}

                                {filtered(unreadNotifications).map(notification => <NotificationBox {...notification} key={notification.id}/>)}

                            </TabPane>
                            <TabPane tab={`Read (${readNotifications.length})`} key="read">
                                {readNotifications.length === 0 && <p>No read notifications</p>}
                                {readNotifications.map((notification) => (
                                    <p key={notification.id}>{notification.message}</p>
                                ))}
                                {filtered(readNotifications).map(notification => <NotificationBox {...notification} key={notification.id}/>)}
                            </TabPane>
                        </Tabs>
                    </TabWrapper>

                </Drawer>

                <NotificationView />

            </CommonDiv>
        </NotificationContext.Provider>
    );
};

export default Notification;
