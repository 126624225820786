import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import { Table } from 'antd';
import { appConstant, approvalStatus, approvalType, approvalTypes, THEME, vendorApproverRoles } from 'Util/constants';
import { useSubscription } from '@apollo/client';
import { ADMIN_APPROVALS } from 'Util/subscriptions';
import { ApprovalDashboardContext } from '../../Dashboard';
import { requestor_columns, approver_columns, results_columns } from './columns';
import ApproveActions from './approveActions';
import VendorApproveActions from './vendorApproveActions';
import ProductApproveActions from './productApproveActions';
import { getDate } from 'Util/common';
import moment from 'moment';
import leadingZeroes from 'leading-zeroes';
import _ from 'lodash'
import useUser from 'Hooks/user'

const Container = styled.div``

function ApprovalDashboardDataTable(props) {
    const { userRoles } = useUser()

    const { filter } = useContext(ApprovalDashboardContext)
    let columns = requestor_columns;

    if (filter === '7day') { columns = results_columns; }

    if (filter === 'approver') {
        columns = [
            ...approver_columns,
            userRoles.includes(1) ?
                {
                    title: "Action",
                    dataIndex: "actions",
                    key: "actions",
                    render: (v, rec) => {
                        if (approvalType.NEW_VENDOR_APPROVAL === rec.approve_type_id) {
                            return <VendorApproveActions ids={v} />
                        }
                        if (approvalType.NEW_PRODUCT_APPROVAL === rec.approve_type_id) {
                            return <ProductApproveActions ids={v} />
                        }
                        return <ApproveActions ids={v} />
                    }
                } : {}
        ];
    }

    const [data, setData] = useState([])

    /** TODO: create util for this */
    const getStatusLabel = (status) => {
        const res = _.find(approvalStatus, ['value', status])
        return res.label
    }

    /** TODO: use util for this */
    const getApproveTypeLabel = (type) => {
        const res = _.find(approvalTypes, ['id', type])
        return res.label
    }

    const { loading } = useSubscription(ADMIN_APPROVALS, {
        variables: {
            where: { approver_role: { _in: vendorApproverRoles } }
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            if (data.approvals) {
                setData(data.approvals.map(v => ({
                    key: `approval-dash-key-${v.id}`,
                    id: leadingZeroes(v.id, appConstant.leadingZero),
                    display_name_r: v.requester.full_name,
                    display_name_a: v.role.display_name,
                    created_at: getDate(v.created_at),
                    days: moment().diff(v.created_at, 'days'),
                    type: getApproveTypeLabel(v.approval_request_type),
                    account_id: leadingZeroes(v.ref_id, appConstant.leadingZero),
                    workflow: v.workflow,
                    status: getStatusLabel(v.status),
                    actions: { request_id: v.id, user_id: v.ref_id },
                    approve_type_id: v.approval_request_type
                })))
            }
        }
    });

    const filterData = () => {

        if (filter === 'approver') {
            return _.filter(data, function (o) { return o.status !== "Approved" });
        }

        return data
    }

    return (
        <Container>
            <Table
                loading={loading}
                columns={columns}
                dataSource={filterData()}
            />
        </Container>
    )
}

ApprovalDashboardDataTable.propTypes = {}

export default ApprovalDashboardDataTable
