import React, { useContext } from 'react'
import styled from "styled-components";
import { Select } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import { LogisticsJobsContext } from '../..';

const { Option } = Select;

const Container = styled.div`
  label{
      font-weight: bold;
  }  
`;

function StatusDropDown(props) {
    const { filters, setFilter } = useContext( LogisticsJobsContext )
    const history = useHistory()
    const params = useParams()

    const handleChange = (type) => {
        setFilter({ ...filters, type })
        history.push(`/logistics/vehicles/${type}`)
    }

    return (
        <Container>
            <label>Drivers: </label>
            <Select value={params.status ?? ""} defaultValue="" style={{ width: 100 }} bordered={false} onChange={handleChange}>
                <Option value="">All</Option>
                <Option value="fleet">Fleet</Option>
                <Option value="group">Group</Option>
            </Select>
        </Container>
    )
}

StatusDropDown.propTypes = {}

export default StatusDropDown