import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Select, Input, Checkbox, Popconfirm } from 'antd';
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ManageDropDown from './ManageDropDown';
import ExtraFilters from './ExtraFilters';
import { FaUsers } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import { UserGroupsContext } from '../UserGroups';
import _ from 'lodash'

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const NewContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const CheckBoxContainer = styled.div`
    label {
        font-weight: bold;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${THEME.colors.primary};
        border-color: ${THEME.colors.primary};
    }
`;

function UserGroupTableFilter(props) {

    const history = useHistory()
    const { selectedRecord, permissionView, setPermissionView } = useContext(UserGroupsContext)

    const confirmDelete = () => {

    }

    return (
        <Container>

            <SearchContainer>
                <Input prefix={<SearchOutlined />} placeholder="Search" />
            </SearchContainer>

            <NewContainer>
                <PlusOutlined onClick={() => alert('new')} />
                <label>New</label>
            </NewContainer>

            <NewContainer>
                <EditOutlined onClick={() => alert('new')} />
                <label>Edit List</label>
            </NewContainer>

            <NewContainer>
                <Popconfirm
                    title="Are you sure to delete this item?"
                    onConfirm={confirmDelete}
                    onCancel={() => { console.log('Cancel') }}
                    okText="Yes"
                    cancelText="No"
                    trigger={_.isUndefined(selectedRecord.id) ? 'none' : 'click'}
                >
                    <DeleteOutlined />
                    <label>Delete</label>
                </Popconfirm>
            </NewContainer>

            <NewContainer>
                <FaUsers className='anticon' onClick={() => alert('new')} />
                <label>Members</label>
            </NewContainer>

            <NewContainer>
                <PlusOutlined onClick={() => alert('new')} />
                <label>Permissions</label>
            </NewContainer>

            <ExtraFilters
                onInfo={() => setPermissionView(!permissionView)}
            />

        </Container>
    )
}

UserGroupTableFilter.propTypes = {}

export default UserGroupTableFilter
