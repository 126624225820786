import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import { CRMVendorContext } from '../..';
import { useMutation } from '@apollo/client';
import { UPDATE_VENDOR, UPDATE_BULK_VENDOR } from 'Util/mutations';
import { useRecoilState } from "recoil";
import { selectedVendorAtom } from "Store/crmAtom";
import { useHistory } from 'react-router-dom';
import moment from 'moment'

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: ${props => props.editMode ? '0px' : '5px'};
        margin-top: ${props => props.editMode ? '5px' : '0'};
    }
    label{
        font-weight: bold;
    } 
`;

function DeleteVendor({editMode = false}) {
    const [vendor, setSelectedVendor] = useRecoilState(selectedVendorAtom);
    const history = useHistory()

    const [deleteVendor] = useMutation(UPDATE_BULK_VENDOR);
  
    const confirmDelete = () => {    
        deleteVendor({
            variables: {
                where: {
                    user: {id: {_in: vendor.id}}
                },
                vendor_details_object: {deleted_at: moment()},
                user_object: {},
                where_user: {}
            }
        })
        setSelectedVendor({})
        if (editMode) history.push(`/crm/vendors`)
    }

    const confirm = () => {

        vendor && Modal.confirm({
            centered: true,
            icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
            content: `Delete ${vendor.vendor_id} - ${vendor.name}?`,
            autoFocusButton: 'ok',
            okButtonProps: {
                style: { backgroundColor: THEME.colors.primary }
            },
            onOk: confirmDelete
        });
    };

    return (
        <Container editMode={editMode}>
            <Tooltip title={vendor.vendor_id}>
                <DeleteFilled onClick={confirm} />
                {!editMode && <label>Delete</label>}
            </Tooltip>
        </Container>
    )
}

DeleteVendor.propTypes = {
    editMode: PropTypes.bool
}

export default DeleteVendor