import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CommonDiv from 'Components/CommonDiv'
import { IconShare } from 'Components/Icons';
import { THEME } from 'Util/constants';
import { ArrowsAltOutlined, DeleteFilled } from '@ant-design/icons';
import { Popconfirm, Table } from 'antd';
import PermissionSelect from './PermissionSelect';
import { UsersCardContext } from '../../UsersCard';
import _ from 'lodash'
const Container = styled.div`
.header-menu{

    border-bottom: solid 1px #ccc;
    border-top: solid 1px #ccc;
    padding: 10px 0px;
    margin-bottom: 10px;

    .title-menu{
        width: 300px;
        label{
            font-weight: bold;
        }
    }
    .filters{
        width: 60px;
    }
}`

const columns = [
    {
        title: 'Permission Set',
        dataIndex: 'display_name',

    },
    {
        title: 'Description',
        dataIndex: 'description',

    },
    {
        title: 'Extension name',
        dataIndex: 'profile_id',

    },
    {
        title: 'Permission Scope',
        dataIndex: 'permission_scope',

    },
];

function UserPermissionInputTable({ data }) {

    const { userData, setUserData } = useContext(UsersCardContext)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const tableData = data.map((gdata) => ({
        key: gdata.id,
        display_name: gdata.user_role.display_name,
        description: gdata.user_role.description,
        profile_id: gdata.user_role.profile_id,
        permission_scope: "N/A"
    }))

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onDelete = () => {
        const { admin_user_roles } = userData
        setUserData({
            ...userData,
            admin_user_roles: _.filter(admin_user_roles, obj => (!selectedRowKeys.includes(obj.id)))
        })
    }

    return (
        <Container>
            <CommonDiv className='header-menu' direction="row" align="center" justify="space-between" >

                <CommonDiv justify="space-between" direction="row" className='title-menu' >
                    <label>User Permission</label> | <label>Manage</label>
                </CommonDiv>

                <CommonDiv justify="space-between" direction="row" className='filters' >
                    <IconShare color={THEME.colors.primary} size={15} />
                    <ArrowsAltOutlined />
                    <Popconfirm
                        title="Remove all selected items?"
                        placement='left'
                        onConfirm={onDelete}
                    >
                        <DeleteFilled />
                    </Popconfirm>
                </CommonDiv>

            </CommonDiv>

            <Table
                size='small'
                columns={columns}
                dataSource={tableData}
                footer={() => <PermissionSelect />}
                pagination={false}
                rowSelection={rowSelection}
            />


        </Container>
    )
}

UserPermissionInputTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
}

export default UserPermissionInputTable