
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME, notification_groups } from 'Util/constants';
import { Modal } from 'antd';
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery } from '@apollo/client'
import { selectedGroupAtom } from "Store/crmAtom";
import { GET_VENDOR, GET_ADMIN_USER } from 'Util/queries';
import { useRecoilState } from 'recoil';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 33.33%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function SelectNotificationGroup({ showModal, setShowModal }) {
  const [selected, setSelected] = useState(null)
  const history = useHistory()
  const routeLocation = useLocation();
  const [selectedGroup, setSelectedGroup] = useRecoilState(selectedGroupAtom)

  const handleOk = () => {
    if (!selected) return
    setShowModal(false);
    if (routeLocation.pathname.includes('/crm/vendors')) {
      routeLocation.pathname.localeCompare('/crm/vendors') === 0 ? history.push(`./notifications/new/${selected.id}`) : history.push(`../notifications/new/${selected.id}`)
    } else {
      history.push(`new/${selected.id}`)
    }
  };

  const [getVendors] = useLazyQuery(GET_VENDOR, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      data && setSelectedGroup(
        data.vendor_details.map(vd => ({
            id: vd.user.id,
            channels: vd.user.channel_ids
        }))
      )
    }
  })

  const [getEmployees] = useLazyQuery(GET_ADMIN_USER, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      data && setSelectedGroup(
        data.admin_user.map(vd => ({
            id: vd.id,
            channels: vd.channel_ids
        }))
      )
    }
  })

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleChangeGroup = (item) => {
    setSelected(item)

    switch (item.group) {
      case "Vendors":
          getVendors()
          break;

      case "Internal Employees":
          getEmployees()
          break;

      default:
          setSelectedGroup({})
          console.log('default')
          break;
    }
  }

  return (
    <Container>
      <Modal
        visible={showModal}
        centered
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        title={
          <span style={{ marginRight: 50 }}>Select Notification Group</span>
        }
        okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
        width={800}
        closable={false}
        bodyStyle={{ background: '#F2F2F2' }}
      >
        <ModalContainer>

          <div className='thead' >
            <span> Group </span>
            <span> Description </span>
          </div>

          {notification_groups.length > 0 &&
            notification_groups.map((item) => (
              <div key={`SelectNotifictionGroup-${item.id}`} onClick={() => handleChangeGroup(item)} className={`tbody ${selected?.id === item.id ? 'selected' : ''}`} >
                <span> {item.group} </span>
                <span> {item.description} </span>
              </div>
            ))
          }

        </ModalContainer>
      </Modal>
    </Container>
  )
}

SelectNotificationGroup.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func
}

export default SelectNotificationGroup
