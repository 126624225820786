import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { createContext } from 'react'
import styled from 'styled-components'
import { auctionMenu } from '../constants'
import { DataTable } from './components'

export const DirectSalesItemsContext = createContext()

const Container = styled.div``

function DirectSelectionList() {
    // const [filter, setFilter] = useRecoilState(directSalesFilterAtom)
    return (

        <Container>
            <SubPageBreadCrumbs />
            <Level2Menu menu={auctionMenu} />
            <DataTable />
        </Container>

    )
}

export default DirectSelectionList