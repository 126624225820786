import { useQuery } from '@apollo/client';
import { Select } from 'antd'
import React, { useState } from 'react'
import { GET_PRODUCT_FORMAT } from 'Util/queries';
const { Option } = Select;

function FormatDropdown({ onChange, ...rest  }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_PRODUCT_FORMAT, {
        onCompleted() {
            setList(data.product_format)
        }
    });

    return (
        <div>
            <Select
                {...rest}
                style={{ width: '100%' }}
                placeholder="Select a format"
                onChange={onChange}
            >
                { list.map(v=><Option value={v.id} key={`product-format-opt${v.id}`}>{v.value}</Option>) }
            </Select>
        </div>
    )
}

export default FormatDropdown
