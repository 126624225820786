import { ContainerOutlined, DashboardOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";

function UsersAdminMenu(props) {
    return (
        <>
            <Menu theme="light" mode="horizontal" defaultSelectedKeys={['auction']}>
                <Menu.Item key="cms-users" icon={<DashboardOutlined />}>
                    <span>Masterlist</span>
                    <Link to="/admin/settings/user-management" />
                </Menu.Item>

                <Menu.Item key="cms-users-pending" icon={<ContainerOutlined />}>
                    <span>Roles</span>
                    <Link to="/admin/settings/role-management" />
                </Menu.Item>

                <Menu.Item key="cms-users-rejected" icon={<ContainerOutlined />}>
                    <span>Modules</span>
                    <Link to="/admin/settings/module-management" />
                </Menu.Item>

            </Menu>
        </>
    )
}

UsersAdminMenu.propTypes = {

}

export default UsersAdminMenu

