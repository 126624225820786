import React from 'react'
import PropTypes from 'prop-types'
import { SectionContainer } from './form.styled'

function Section({ title, children }) {
    return (
        <SectionContainer>
            <h3 style={{ borderBottom: 'solid thin #000', padding: '5px' }} >{title}</h3>
            <div className='content'>{children}</div>
        </SectionContainer>
    )
}

Section.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

export default Section
