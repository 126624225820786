import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { NotificationContext } from 'Components/Notification/NotificationContext';
import { useContext } from 'react';
import { AditionalInforContainer } from '../styled';


function Attachment() {

    const { data } = useContext(NotificationContext)
    const { admin_notifications_attachments } = data

    return (
        <div>
            <AditionalInforContainer direction="row" >
                <label>Attachments:</label>
                {
                    admin_notifications_attachments.map(attc => {
                        return <Button
                            key={`attachement-notif-${attc.id}`}
                            className='dl-btn'
                            type="primary"
                            onClick={() => { console.log('download from:' + attc.url) }}
                        >
                            <span>{attc.name}</span>
                            <DownloadOutlined />
                        </Button>
                    })
                }

            </AditionalInforContainer>
        </div>
    )
}

export default Attachment