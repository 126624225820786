import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Popover } from 'antd';
import CommonDiv from 'Components/CommonDiv';
import User from 'Hooks/user';
import Notification from 'Components/Notification';

const Container = styled.div`
    width: 300px;
    .fChar{
        color: #555;
        font-weight: 500;
        font-size: 28px;
        border: 1px solid #555;
        padding: 10px 24px;
        border-radius: 50%;
    }
`
const PopOverContainer = styled(Popover)`
    label{
        cursor: pointer;
        color: ${THEME.colors.primary};
        font-weight: 500;
        font-size: 20px;
        border: 1px solid ${THEME.colors.primary};
        padding: 3px 8px;
        border-radius: 50%;
    }
    `

function AccountButton(props) {
    const { username, user_id } = User()
    const firstChar = username.slice(0, 1);

    const onLogOut = () => {
        localStorage.removeItem('token');
        window.location.href = '/login'
    }

    const popContent = () => {
        return <Container>

            <CommonDiv direction="row" justify="space-between">
                <span>catch-value.com</span>
                <a onClick={onLogOut} >Sign out</a>
            </CommonDiv>

            <CommonDiv direction="row" justify="space-between" align="center" style={{ padding: `20px 0px` }}>
                <label className='fChar'>{firstChar}</label>
                <CommonDiv>
                    <label style={{ fontSize: 20 }} >{username}</label>
                    <label>{localStorage.getItem("email")}</label>
                    <a onClick={() => alert('View account action')}>View Account</a>
                </CommonDiv>
            </CommonDiv>

        </Container>
    }

    return (
        <CommonDiv direction="row" align="center" justify="space-between" w="70px" >
            <Notification />
            <PopOverContainer
                content={popContent}
                placement="bottomRight"
                trigger="click">
                <div>
                    <label>{firstChar}</label>
                </div>
            </PopOverContainer>
        </CommonDiv>
    )
}

AccountButton.propTypes = {}

export default AccountButton
