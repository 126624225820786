import { Link } from 'react-router-dom';
import { THEME } from 'Util/constants';
import { approvalType } from '../../../../../../../Util/constants';


const base_cols = [
    {
        title: 'Creation Date',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: 'Days in Queue',
        dataIndex: 'days',
        key: 'days',
    },
    {
        title: 'Request Type', // Type of Approval Request
        dataIndex: 'type',
        key: 'type'
    },
    {
        title: 'Account ID',
        dataIndex: 'account_id',
        key: 'account_id',
        render: (v, rec) => {

            /** default for admin card path */
            let basePath = '/admin/roles/system-admin/users/edit/';

            if (approvalType.NEW_VENDOR_APPROVAL === rec.approve_type_id) {
                basePath = '/crm/vendors/edit/'
            }

            if (approvalType.NEW_PRODUCT_APPROVAL === rec.approve_type_id) {
                basePath = '/procurement/product-list/new/edit/'
            }
            return <Link to={{pathname: `${basePath}${parseInt(v)}`, search: '?view=approval',state: { prevPath: '/admin/roles/system-admin/approval-user-dashboard' }}}>{v}</Link> 
                
        }
    },
]

const requestor_columns = [
    {
        title: 'Request ID',
        dataIndex: 'id',
        key: 'id',
        render: (text) => <a style={{ color: THEME.colors.primary }} >{text}</a>,
    },
    {
        title: 'Requestor',
        dataIndex: 'display_name_r',
        key: 'display_name_r',
    },
    ...base_cols,
    {
        title: 'Workflow Steps',
        dataIndex: 'workflow',
        key: 'workflow',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    // {
    //     title: 'Use as default profile',
    //     dataIndex: 'is_default_profile',
    //     key: 'is_default_profile',
    //     render: (status) => <Checkbox checked={status} />
    // },
];


const approver_columns = [
    {
        title: 'Request ID',
        dataIndex: 'id',
        key: 'id',
        render: (text) => <a style={{ color: THEME.colors.primary }} >{text}</a>,
    },
    {
        title: 'Approver',
        dataIndex: 'display_name_a',
        key: 'display_name_a',
    },
    ...base_cols
];

const results_columns = [
    {
        title: 'Request ID',
        dataIndex: 'id',
        key: 'id',
        render: (text) => <a style={{ color: THEME.colors.primary }} >{text}</a>,
    },
    {
        title: 'Approver',
        dataIndex: 'display_name_a',
        key: 'display_name_a',
    },
    ...base_cols,
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    }
];

export {
    requestor_columns,
    approver_columns,
    results_columns
}