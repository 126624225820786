import { ArrowLeftOutlined, CameraFilled, DeleteFilled } from '@ant-design/icons';
import { THEME } from "Util/constants";
import {
  Form,
  Input,
  notification,
  Select,
  Button,
  Radio,
  Space,
  DatePicker
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import { INSERT_POLLS } from 'Util/mutations';
import { uploadFile } from "../../../../Util/imageUploader";
import _ from 'lodash'

const { Option } = Select;

const Container = styled.div`
    .actions-wrapper{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icons-wrapper{
            justify-content: flex-end;
            width: 180px;
            gap: 20px;        
            svg{
                color: ${THEME.colors.primary};
                font-size: 25px;
                cursor: pointer;
            }
        }
        
        .backIconWrapper{
            background-color: ${THEME.colors.primary};
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
        h1{
            margin: 0px 10px;
        }
        
    }

    .submenu-wrapper{

        padding: 10px 0px;
        border-bottom: solid #555 thin;
        border-top: solid #555 thin;
        margin: 20px 0px;

        &>span{
            color: #555;
            margin: 0px 10px;
            font-size: 16px;
            font-weight: 500;
        }
    }
`

const SectionContainer = styled.div`
    .content {
        margin: 5px;
        display: flex;
        justify-content: space-between;
        & > div.division {
            width: 50%;
            .ant-form-item-label > label {
                width: 180px;
            }
            .ant-form-item-control {
                width: 10px;
            }
        }
    }
    .division-title {
        font-weight: bold;
    }

    .ant-switch-checked {
        background-color: ${THEME.colors.primary};
    }
      
    .ant-radio-checked .ant-radio-inner:after {
        background: ${THEME.colors.primary};
        border-color: ${THEME.colors.primary};
    }
      
    .ant-radio-inner {
        border-color: ${THEME.colors.primary};
    }      
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function NewPollsPage(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const history = useHistory();
  const [formLoading, setFormLoading] = useState(false);
  
  const [choices, setChoices] = useState([{ id: '0' },{ id: '1' }]);
  const [showImageLink, setShowImageLink] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().add(1, 'day'));
  const [img, setImg] = useState({});
  const imageRefs = useRef([]);


  const [add] = useMutation(INSERT_POLLS, {
    onCompleted() {
        notification['success']({
            message: 'Published!',
            description:
                'Poll has been published!',
        });
        history.goBack() 
    },
    onError(e) {
        console.log('erre', e)
    }
  });

  const handleAddChoice = () => {
    setChoices((prevChoices) => {
    const nextChoiceId = (parseInt(prevChoices[prevChoices.length - 1].id, 10) + 1).toString();
    const newChoice = { id: nextChoiceId };
    return [...prevChoices, newChoice];
    });
  };

  const handleRemoveChoice = (choiceId) => {
    const updatedChoices = choices.filter((choice) => choice.id !== choiceId);
    setChoices(updatedChoices);
  };

  const handleImageOptionChange = (value) => {
    setShowImageLink(value === 'image');
  };
 
  const backHandler = () => { history.goBack() }

  const submitHandler = async (values) => {

    const options = [];
    const optionsKeys = Object.keys(values).filter(key => key.startsWith("choices"));
    optionsKeys.forEach(key => {
        options.push(values[key]);
        delete values[key];
    });

    delete values['points_earned'] //TODO add to database table
    const created_by = localStorage.getItem("user_type");

    const optionImage = [];
    for (let i = 0; i < imageRefs.current.length; i++) {
        const ref = imageRefs.current[i];
        if (ref) {
            const file = ref.files[0];
            const upload = await uploadFile(file);
            const url = upload.data.url
            optionImage.push({
                text: options[i],
                image: url
            });
        }
    }
          
    add({
        variables: {
          objects: {
            ...values,
            options: values.options_type === "no-image" ? options : optionImage,
            expiry_date: values.expiry_date === "" ? null : selectedDate,
            created_by
          }
        },
    });
  };

  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setShowDatePicker(value === 'date');
  };

  const disabledDate = current => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current < today;
  };

  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const handleImageChange = async (event, cid) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      let imgUrl = URL.createObjectURL(file)
      setImg({
        ...img,
        [cid]: imgUrl
      })
      imageRefs.current[cid] = file;
    }
  }

  return (
    <Container>   
        <div className='actions-wrapper' >
            <div>
                <button onClick={backHandler} className='backIconWrapper' >
                    <ArrowLeftOutlined />
                </button>
                <h1>Quick Poll</h1>
            </div>
        </div>
        <div className='submenu-wrapper'>
            <span>Title</span>
        </div>
      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="Poll Details">
          <div className="division">

            <Form.Item label="Poll Title" name="title" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

            <Form.Item label=" " name="option_type" colon={false} initialValue="no-image">
                <Select onChange={handleImageOptionChange} placeholder="Select a Question Type">
                    <Option value="no-image">Multiple Choice</Option>
                    <Option value="image">Multiple Choice (Image)</Option>
                </Select>
            </Form.Item>

            <Form.Item label="Question" name="question" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Options">
            {choices.map((choice) => (
                <div key={choice.id}>
                {showImageLink && (
                    <div>
                        <label htmlFor={`upload-photo-${choice.id}`} style={{ fontSize: "12px", color: THEME.colors.primary  }}>
                            <CameraFilled /> Upload Image
                        </label>
                        <input required type="file" ref={(ref) => (imageRefs.current[choice.id] = ref)} onChange={(e) => handleImageChange(e, choice.id)} id={`upload-photo-${choice.id}`} name={`upload-photo-${choice.id}`} style={{ opacity: "0", position: "absolute", pointerEvents: "none" }} />
                    </div>
                )}
                    <div>
                        {(img && img[choice.id]) && <img src={img[choice.id]} alt="" style={{ height: 100, width: 100, marginRight: 20 }} />}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>

                        <Form.Item name={`choices_${choice.id}`} rules={[{ required: true, message: "" }]} style={{ flex: 1 }}>
                            <Input placeholder={`Option ${parseInt(choice.id) + 1}`} />
                        </Form.Item>

                        {choice.id !== "0" && choice.id !== "1" && (
                            <div style={{ marginLeft: "8px", marginBottom: "25px", alignItems: "center" }}>
                                <DeleteFilled onClick={() => handleRemoveChoice(choice.id)} style={{ fontSize: "12px", color: THEME.colors.primary }}/>
                            </div>
                        )}
                    </div>
                </div>
                ))}

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "-10px" }}>
                    <Button
                    type="link"
                    onClick={handleAddChoice}
                    style={{ fontSize: "12px", color: THEME.colors.primary, padding: 0, marginTop: "-10px" }}
                    >
                        Add another option
                    </Button>
                </div>
            </Form.Item>

            <Form.Item label="End Date" name="expiry_date" initialValue="">
                    <Radio.Group value={selectedDate} onChange={handleRadioChange}>
                        <Space direction="vertical">
                            <Radio value="">Never</Radio>
                            <Radio value="date">
                                <DatePicker disabled={!showDatePicker} 
                                            onChange={handleDatePickerChange}
                                            defaultValue={selectedDate}
                                            disabledDate={disabledDate}/>
                            </Radio>
                        </Space>
                    </Radio.Group>
            </Form.Item>

            <Form.Item label="Points Earned" name="points_earned" >
              <Input />
            </Form.Item>

            <Form.Item label="Poll Results" name="show_result" initialValue={true}>
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={true}>Show</Radio>
                            <Radio value={false}>Hide</Radio>
                        </Space>
                    </Radio.Group>
            </Form.Item>

            <Form.Item label="Comments" name="comments" initialValue={true}>
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Enabled</Radio>
                        <Radio value={false}>Disabled</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

          </div>
        </Section>

        <CommonDiv direction="row" justify="flex-end">
            <ButtonLoader loading={props.loading} htmlType="submit" >Publish</ButtonLoader>

            <CommonButton className='reverse' onClick={() => history.goBack()}>
                Save
            </CommonButton>

        </CommonDiv>
      </Form>
    </Container>
  );
}

NewPollsPage.propTypes = {};

export default NewPollsPage;

