import React from 'react'
import PropTypes from 'prop-types'
import ButtonCard from 'Components/ButtonCard'
import { useLocation, useParams } from 'react-router-dom'

function Level2Menu({ menu }) {
    const location = useLocation();

    return (
        <div style={{ display: 'flex' }} >
            {menu.map(v => (<ButtonCard
                key={v.link}
                link={v.link}
                title={v.title}
                lightBlue
                selected={location.pathname === v.link}
            />))}
        </div>
    )
}

Level2Menu.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape(
            {
                title: PropTypes.string,
                link: PropTypes.string
            }
        )
    ).isRequired
}

export default Level2Menu
