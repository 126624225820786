import React, { useState, createContext, useEffect } from 'react'
import styled from 'styled-components'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { auctionMenu } from '../constants'
import { DataTable, TableFilters } from './components'
import ClockV2 from 'Components/ClockV2'
import { directSalesFilterAtom } from 'Store/directSalesAtom'
import { useRecoilState } from 'recoil';
import AcceptedDataTable from './components/AcceptedDatatable'
import { WithdrawnDirectSales } from 'Pages/DirectSales'

export const DirectSalesItemsContext = createContext()

const Container = styled.div``

function DirectSalesItems() {


    const [filter, setFilter] = useRecoilState(directSalesFilterAtom)

    useEffect(() => {
        setFilter({ ...filter, status: 'accepted',  search: ''})
        // return () =>{
        //     setFilter({ ...filter, search: '' })
        // }
    }, [])


    return (

        <Container>
            <SubPageBreadCrumbs />
            <Level2Menu menu={auctionMenu} />
            {/* <ClockV2 position='absolute' /> */}
            {/* <TableFilters /> */}

            {/* {['open'].includes(filter.status) && <DataTable />} */}
            {['accepted'].includes(filter.status) && <AcceptedDataTable />}
            {/* {['withdrawn', 'staged-widthdrawn'].includes(filter.status) && <WithdrawnDirectSales />} */}

        </Container>

    )
}

export default DirectSalesItems