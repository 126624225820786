import { useSubscription } from '@apollo/client';
import { Table, Modal } from 'antd';
import leadingZeroes from 'leading-zeroes';
import { useState, useEffect, useContext } from 'react';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import styled from 'styled-components';
import { getDate, getTimeFromDate } from 'Util/common';
import { appConstant } from 'Util/constants';
import { SUB_BIDS_LIST } from 'Util/subscriptions';
import { ActionsCol, StatusText } from '../../components';
import _ from 'lodash';
import { auctionSearchAtom } from 'Store/auctionAtom';
import moment from 'moment';
import AcceptBidModal from './AcceptBidModal';
import RejectBidModal from './RejectBidModal';
import { auctionAutoBidAtom, auctionSelectedRecordAtom } from 'Store/auctionAtom';
import { TableFilters } from './';
import { AuctionLiveContext } from '..';

const Container = styled.div``

const ActionContainer = styled.div`
    width: 100px;
    svg{
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 5px;
        color: grey;
    }
`


const filterColumns = (status, check) => {
    const columns = [
        {
            title: 'Date',
            dataIndex: 'auction_date',
            key: 'auction_date',
            sorter: (a, b) => (new Date(a.auction_date) - new Date(b.auction_date)),
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Auction',
            dataIndex: 'auction_id',
            key: 'auction_id',
            sorter: (a, b) => a.auction_id - b.auction_id,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Time',
            dataIndex: 'auction_time',
            key: 'auction_time',
            sorter: (a, b) => a.auction_time.localeCompare(b.auction_time),
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Lot #',
            dataIndex: 'lot_number',
            key: 'lot_number',
            sorter: (a, b) => a.lot_number - b.lot_number,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Fisheman ID',
            dataIndex: 'fisherman_id',
            key: 'fisherman_id',
            sorter: (a, b) => a.fisherman_id - b.fisherman_id,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: ' Location',
            dataIndex: 'location',
            key: 'location',
            sorter: (a, b) => a.location.localeCompare(b.location),
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Species',
            dataIndex: 'species',
            key: 'species',
            sorter: (a, b) => a.species.localeCompare(b.species),
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Weight (KG)',
            dataIndex: 'weight',
            key: 'weight',
            sorter: (a, b) => a.weight - b.weight,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Bid price (Unit)',
            dataIndex: 'bid_price',
            key: 'bid_price',
            sorter: (a, b) => a.bid_price - b.bid_price,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Log Cost',
            dataIndex: 'log_cost',
            key: 'log_cost',
            sorter: (a, b) => a.log_cost - b.log_cost,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Total Value',
            dataIndex: 'total_value',
            key: 'total_value',
            sorter: (a, b) => a.total_value - b.total_value,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Rem Quota',
            dataIndex: 'rem_quota',
            key: 'rem_quota',
            sorter: (a, b) => a.rem_quota - b.rem_quota,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Reserve Price',
            dataIndex: 'reserved_price',
            key: 'reserved_price',
            sorter: (a, b) => a.reserved_price - b.reserved_price,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Exp Unit Rev',
            dataIndex: 'exp_unit_rev',
            key: 'exp_unit_rev',
            sorter: (a, b) => a.exp_unit_rev - b.exp_unit_rev,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: 'Gross',
            dataIndex: 'gross',
            key: 'gross',
            sorter: (a, b) => a.gross - b.gross,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: "Gross %",
            dataIndex: "gross_percent",
            key: "gross_percent",
            sorter: (a, b) => a.gross_percent - b.gross_percent,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: "TAC",
            dataIndex: "tac",
            key: "tac",
            sorter: (a, b) => a.tac - b.tac,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (val, obj) => {
                return <span style={{ color: obj.status === 'accepted' ? 'green' : 'red' }} >{val}</span>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (v) => {
                if (status === 'prebid') return <StatusText statusText="Pre-Bid" />
                if (['inprogress', 'auction'].includes(status)) return <StatusText statusText="Submitted" />
                if (['results'].includes(status) && ['pending'].includes(v)) return <StatusText statusText="Pending" />

                return <StatusText statusText={v} />
            },
            sorter: (a, b) =>  a.status.localeCompare(b.status),
            sortDirections: ['ascend', 'descend', 'ascend']
        }
    ];

    if (['results'].includes(status)) {
        columns.push({
            title: <ActionContainer><AcceptBidModal check={check}/><RejectBidModal check={check}/></ActionContainer>,
            dataIndex: 'action',
            key: 'action',
            render: (bid) => <ActionsCol type='arc' data={{ bid }} />
        },)

    }

    return columns

}

function DataTable() {

    /** Global State */
    const [auctionState, setAuctionState] = useRecoilState(auctionAtom)
    const { auctionStatus, currentAuction } = auctionState
    const [searchRecord, setSearchRecord] = useRecoilState(auctionSearchAtom)
    const [check] = useRecoilState(auctionAutoBidAtom)
    const [selectedRecord, setSelectedRecord] = useRecoilState(auctionSelectedRecordAtom)
    const {selectedRowKeys} = selectedRecord
    const { filter } = useContext(AuctionLiveContext)

    const fromDate = moment().subtract(1, filter).format('YYYY-MM-DD');
    const query = filter === 'live' ? {auction_id: { _eq: currentAuction ?? 0 }} : {created_at: { _gte: fromDate}}

    /** Local State */
    const [data, setData] = useState([])

    /** GraphQL Hooks */
    const { loading } = useSubscription(SUB_BIDS_LIST, {
        variables: {
            where: query     
        },
        onSubscriptionData({ subscriptionData: { data } }) {
            data.bids && setData(data.bids.map(bid => {
                let logCost = bid.product.log_cost;
                let expUnitRev = bid.pickup_orders[0]?.exp_unit_rev ?? 0;
                let gross = expUnitRev - bid.price - logCost
                let grossPercent = ((gross / expUnitRev) * 100).toFixed(2)
                return {
                    key: `key-${bid.id}-bid-list`,
                    auction_date: getDate(bid.created_at),
                    auction_id: bid.auction_id,
                    auction_time: getTimeFromDate(bid.created_at, true),
                    lot_number: bid.id,
                    fisherman_id: leadingZeroes(bid.user.id, appConstant.leadingZero),
                    location: bid.user.user_location.label,
                    species: bid.product.label,
                    weight: bid.volume,
                    bid_price: bid.price,
                    log_cost: logCost,
                    total_value: bid.volume * bid.price,
                    rem_quota: bid.rem_quota,
                    reserved_price: bid.product.price,
                    exp_unit_rev: expUnitRev,
                    gross: gross,
                    gross_percent: grossPercent,
                    tac: bid.tac,
                    status: bid.status,
                    action: bid
                }
            }))

        }
    });

    /** Functions */
    const onSelectChange = (newSelectedRowKeys, data) => {
        setSelectedRecord({
            selectedRowKeys : newSelectedRowKeys,
            selectedData : data
        })
    };

    const filterData = (data) => {
        const searchable = data.map(obj=>({
            ...obj,
            searchText: `${ obj.auction_date } ${ obj.auction_id } ${ obj.auction_time } ${obj.lot_number} ${obj.fisherman_id} ${obj.location} ${obj.species} ${obj.weight} ${obj.bid_price} ${obj.log_cost} ${obj.total_value} ${obj.rem_quota} ${obj.reserved_price} ${obj.exp_unit_rev} ${obj.gross} ${obj.gross_percent} ${obj.status} `
          }))     

        if(!searchRecord.search){
            return data
        }
        return _.filter(searchable, function (d) { 
            let query = searchRecord.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    }
    
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (v) => {
            if(!check && auctionStatus === 'results'){
                return{
                    disabled: v.status.toLowerCase() !== 'pending',
                    name: v.status
                }
            }
            else{
                return { disabled: true}
            }
        },
    };

    useEffect(() => {
        setSearchRecord('')
    }, [])

    useEffect(() => {       
        setSelectedRecord({
            selectedRowKeys : [],
            selectedData : []
        });
    }, [check])

    return (
        <Container>
            <TableFilters data={filterData(data)} columns={filterColumns(auctionStatus, check)}/>
            <Table
                loading={loading}
                size='small'
                columns={filterColumns(auctionStatus, check)}
                dataSource={filterData(data)}
                rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 50
                }}
            />
        </Container>
    )
}

export default DataTable    