import {
  Form,
  Input,
  InputNumber,
  notification,
  Select
} from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { harvestMethods, preservationMethods, THEME } from "Util/constants";
import { VendorCardHeader } from "./components/SpeciesCard";

import { useMutation } from "@apollo/client";
import TextArea from "antd/lib/input/TextArea";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import ChooseProcessCat from "Pages/Procurement/Species/components/ChooseProcessCat";
import ChooseProduceSizing from "Pages/Procurement/Species/components/ChooseProduceSizing";
import ChooseProduceSubCat from "Pages/Procurement/Species/components/ChooseProduceSubCat";
import ChooseProduceType from "Pages/Procurement/Species/components/ChooseProduceType";
import ChooseProductEnvironment from "Pages/Procurement/Species/components/ChooseProductEnvironment";
import ChooseUom from "Pages/Procurement/Species/components/ChooseUom";
import { useHistory } from "react-router-dom";
import { ADD_CUSTOM_PRODUCT } from "Util/mutations";

import { VendorsDropDown } from "Components/Dropdowns";
import { useRecoilState } from "recoil";
import { selectedSpecies } from "Store/procurementAtom";

const Container = styled.div``;

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function NewDirectSelectionList(props) {

  const [species, setSelectedSpecies] = useRecoilState(selectedSpecies);

  console.log('species selected', species)

  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  const editMode = routeLocation.pathname.includes("/species-database/new/edit");
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {

    form.setFieldsValue({
      ...species,
      description: species.bid_specs,
      species: species.label
    })

    /** Clean Up */
    return () => {
      setSelectedSpecies({})
    }

  }, []);

  const [add] = useMutation(ADD_CUSTOM_PRODUCT, {
    onCompleted(data) {
      setFormLoading(false)
      notification['success']({
        message: 'Saved!',
        description: `Product has been added to vendor`,
      });
    }
  })

  const submitHandler = (values) => {

    setFormLoading(true)

    const {
      country_origin,
      item_type,
      preservation_method,
      contact_name,
      contact_number,
      remarks,
      harvest_method,
      min_quantity,
      offer_type,
      price,
      process_category,
      species_environment,
      quantity,
      species_size,
      species_sub_category,
      description,
      species_type
    } = values

    /** TODO: fok expiry form */

    const objects = {
      species_name: values.species,
      category: values.species_sub_category,
      preservation_method,
      country_origin,
      harvest_method,
      quantity,
      min_quantity,
      price,
      unit: values.uom,
      offer_type,
      contact_name,
      contact_number,
      description,
      image: species.image,
      active: species.status,
      user_id: values.vendor_id,
      end_datetime: null, /** if FOK then choose time */
      expiry: null, /** if fok then choose month, year, day */
      species_sku: values.sku,
      item_type,
      remarks,
      species_type,
      species_environment,
      species_sub_category,
      species_size,
      common_item_number: values.common_item_no,
      process_category
    }

    add({ variables: { objects } })

  };

  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const [state, setState] = useState({
    showKg: false,
    showProduceType: false,
    showProduceEnvironment: false,
    showProductSubCat: false,
    showProductSizing: false,
    showProcessCat: false,
    showInventoryPostGrp: false,
    showSpecies: false,
    chosenSpecies: null
  })

  const showModal = (field) => {
    switch (field) {
      case "unit_of_measurement":
        setState({ ...state, showKg: true, fieldKey: 'unit_of_measurement' })
        break;
      case "purchase_unit_of_measure":
        setState({ ...state, showKg: true, fieldKey: ['species_replenishments', 'purchase_unit_of_measure'] })
        break;
      case "species_type":
        setState({ ...state, showProduceType: true })
        break;
      case "species_environment":
        setState({ ...state, showProduceEnvironment: true })
        break;
      case "species_sub_category":
        setState({ ...state, showProductSubCat: true })
        break;
      case "species_sizing":
        setState({ ...state, showProductSizing: true })
        break;
      case "process_category":
        setState({ ...state, showProcessCat: true })
        break;
      default:
        break;
    }
  }

  const setFieldValue = (key, value) => {
    console.log(key)
    if (!Array.isArray(key)) form.setFieldsValue({ ...form.getFieldsValue(), [key]: value })
    else form.setFieldsValue({
      ...form.getFieldsValue(),
      [key[0]]: {
        ...form.getFieldsValue()?.[key[0]],
        [key[1]]: value
      }
    })
  }

  return (
    <Container>

      <ChooseUom state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProduceType state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProductEnvironment state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProduceSubCat state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProduceSizing state={state} setState={setState} setFieldValue={setFieldValue} />
      <ChooseProcessCat state={state} setState={setState} setFieldValue={setFieldValue} />
      <VendorCardHeader editMode={editMode} />

      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="General">
          <div className="division">
            <Form.Item label="Item Code" name="sku"
              rules={[{ required: true, message: '' }]}
            >
              <Input placeholder="Auto generated" />
            </Form.Item>
            <Form.Item label="Item Description" name="description"

            >
              <Input />
            </Form.Item>

            <Form.Item label="Item Type" name="item_type">
              <Select>
                <Select.Option value="inventory">
                  Inventory
                </Select.Option>
                <Select.Option value="service">
                  Service
                </Select.Option>
                <Select.Option value="non-inventory">
                  Non-inventory
                </Select.Option>

              </Select>
            </Form.Item>
            <Form.Item label="Species" name="species" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Remarks" name="remarks">
              <TextArea />
            </Form.Item>

            <Form.Item label="Preservation method" name="preservation_method">
              <Select >
                {preservationMethods.map(v => <Select.Option key={`pm-${v}`} value={v}> {v} </Select.Option>)}
              </Select>
            </Form.Item>

            <Form.Item label="Country of origin" name="country_origin">
              <Select>
                <Select.Option value="in">
                  IN
                </Select.Option>
                <Select.Option value="sg">
                  SG
                </Select.Option>
                <Select.Option value="ph">
                  PH
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Harvest Method" name="harvest_method">
              <Select>
                {harvestMethods.map(v => <Select.Option key={`hm-${v}`} value={v}> {v} </Select.Option>)}
              </Select>
            </Form.Item>

            <Form.Item label="Available Quantity (KG)" name="quantity" rules={[{ required: true, message: '' }]}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Minimum Order Quantity" name="min_quantity" rules={[{ required: true, message: '' }]}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Unit Price" name="price" rules={[{ required: true, message: '' }]}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>


          </div>

          <div className="division">
            <Form.Item
              label="Unit of measurement"
              name="uom"
              rules={[{ required: true, message: '' }]}
            >
              <Input onClick={() => showModal("unit_of_measurement")} />
            </Form.Item>
            <Form.Item label="Product Type" name="species_type" rules={[{ required: true, message: '' }]}>
              <Input onClick={() => showModal("species_type")} />
            </Form.Item>
            <Form.Item
              label="Produce Environment"
              name="species_environment" rules={[{ required: true, message: '' }]}
            >
              <Input onClick={() => showModal("species_environment")} />
            </Form.Item>
            <Form.Item
              label="Product Sub Category"
              name="species_sub_category" rules={[{ required: true, message: '' }]}
            >
              <Input onClick={() => showModal("species_sub_category")} />
            </Form.Item>
            <Form.Item label="Produce Sizing" name="species_size" rules={[{ required: true, message: '' }]}>
              <Input onClick={() => showModal("species_sizing")} />
            </Form.Item>
            <Form.Item
              label="Common Item No."
              name="common_item_no"
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="Process Category"
              name="process_category"
              rules={[{ required: true, message: '' }]}
            >
              <Input onClick={() => showModal("process_category")} />
            </Form.Item>

            <Form.Item label="Offer Type" name="offer_type">
              <Select>
                <Select.Option value="gtc">
                  Good Till Cancelled ( GTC )
                </Select.Option>
                <Select.Option value="fok">
                  Fill or Kill ( FOK )
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Vendor ID" name="vendor_id" rules={[{ required: true, message: '' }]}>
              <VendorsDropDown />
            </Form.Item>

            <Form.Item label="Contact Name" name="contact_name" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Contact" name="contact_number" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

          </div>
        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit">
            {editMode ? "Update" : "Save"}
          </ButtonLoader>
          <CommonButton onClick={() => history.goBack()}>
            Cancel
          </CommonButton>
        </CommonDiv>
      </Form>
    </Container>
  );
}

NewDirectSelectionList.propTypes = {};

export default NewDirectSelectionList;
