import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 33.33%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function CommonSelectModal(props) {
    return <Modal
        visible={props.visible}
        centered
        onCancel={props.cancelHandler}
        onOk={props.okHandler}
        title={props.title}
        okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
        width={600}
        closable={false}
        bodyStyle={{ background: '#F2F2F2' }}
    >
        <ModalContainer>
            {props.children}
        </ModalContainer>
    </Modal>
}

CommonSelectModal.propTypes = {
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    okHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired
}

export default CommonSelectModal
