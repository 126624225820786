import { Button, Form, Input, notification } from 'antd';
import React from 'react';
import './style.scss';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_SETTINGS } from 'Util/queries';
import { UPDATE_SETTINGS } from 'Util/mutations';
import CommonLoader from 'Components/CommonLoader';
import _ from 'lodash'

function DirectSaleSettings() {

    const { data, loading } = useQuery(GET_SETTINGS, {
        variables: {
            where: { key: { _eq: "direct_sales" } }
        }
    });

    const [updateSettings] = useMutation(UPDATE_SETTINGS, {
        onCompleted(data) {
            notification['success']({
                message: 'Saved!',
                description:
                    'Settings has been saved',
            });
        },
        onError(e) {
            console.log('erre', e)
        }
    })

    if (loading) {
        return <CommonLoader />
    }

    const handleSubmit = (values) => {

        _.forOwn(values, function (value, key) {
            if (!_.isArray(value)) {
                values[key] = value.split(',').map(v => v.trim())
            }
        });

        updateSettings({
            variables: {
                key: "direct_sales",
                values
            }
        })

    }

    return (
        <div className="direct-sales-settings" >

            <div className="form-wrapper" >
                <Form
                    onFinish={handleSubmit}
                    className="form-settings"
                    wrapperCol={{ span: 15 }}
                    labelCol={{ span: 9 }}
                    initialValues={data.settings[0].values}
                >

                    {
                        Object.keys(data.settings[0].values).map(v => (
                            <Form.Item
                                key={v}
                                label={v.replace("_", " ")}
                                name={v}
                            >
                                <Input.TextArea rows={5} />
                            </Form.Item>
                        ))
                    }

                    <Form.Item wrapperCol={{ span: 15, offset: 9 }} >
                        <Button type="primary" htmlType="submit">
                            Save changes
                        </Button>
                    </Form.Item>

                </Form>
            </div>

        </div>
    )
}

export default DirectSaleSettings
