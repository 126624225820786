import { PlusOutlined } from '@ant-design/icons';
import { THEME, localizationList } from "Util/constants";
import {
  Form,
  Input,
  Switch,
  notification
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import { useLazyQuery, useMutation } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import { uploadImage } from 'Util/imageUploaderS3';
import { ADD_SPECIES } from "Util/mutations";
import { GET_SPECIES_PROCUREMENT } from "Util/queries";
import TextArea from "antd/lib/input/TextArea";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { VendorCardHeader } from "./components/SpeciesCard";

const Container = styled.div``;

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: calc(50% - 30px);
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

function NewSpeciesPage(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const routeLocation = useLocation();
  const history = useHistory();
  const editMode = routeLocation.pathname.includes("/species-database/new/edit");
  const [formLoading, setFormLoading] = useState(false);
  const [img, setImg] = useState();
  const [origImg, setOrigImg] = useState(null);
  const imageRef = useRef(null)

  const [getProduct, { loading, error, data, refetch, networkStatus }] =
    useLazyQuery(GET_SPECIES_PROCUREMENT, {
      fetchPolicy: "network-only",
      onError(error) {
        console.log('error', error)
      },
      onCompleted() {
        const species = data.species?.[0]

        setImg(species.image)
        setOrigImg(species.image)

        form.setFieldsValue({
          id: species.id,
          label: species.label,
          status: species.status,
          description: species.description,
          species_identifier: species.species_identifier,
          scientific_name: species.scientific_name,
          ...species.localizations,
        });

      },
    });

  const [add] = useMutation(ADD_SPECIES, {
    onCompleted() {

      setFormLoading(false);

      editMode && notification["success"]({
        message: "Saved!",
        description: "Species Updated!",
      });

      !editMode && notification["success"]({
        message: "Saved!",
        description: "Species Saved!",
      });

      //history.push(`/procurement/species-database`)
      history.goBack()

    }
  });

  useEffect(() => {
    if (editMode) {
      console.log('querying')
      getProduct({
        variables: { where: { id: { _eq: params.id } } },
      });
    } else {
      form.setFieldsValue({
        partner_type: params.type,
      });
    }
  }, []);

  const submitHandler = async (values) => {

    setFormLoading(true);

    /** format localizations */
    let newLocalizations = {}

    localizationList.forEach(key => {
      newLocalizations[key] = values[key]
      delete values[key]
    })

    values['localizations'] = newLocalizations

    let image = origImg

    /** file  */
    if (!_.isUndefined(imageRef.current.files[0])) {
      const file = imageRef.current.files[0];
      let existingImageId = null;

      if(editMode && origImg){
        const parts = origImg.split('/')
        const lastPart = parts[parts.length - 1]
        existingImageId = lastPart.replace(/\.(png|jpg)$/, "")
      }
      const imageId = existingImageId ?? uuidv4()

      const postData = {
        filename: `${imageId}.png`,
        directory: 'species'
      };
      
      await uploadImage(postData, file)
      image = `https://assets.catch-value.com/species/${imageId}.png`
    }

    add({
      variables: {
        update_columns: ["id", ...Object.keys(values), "image"],
        objects: [{ ...values, image }]
      }
    })

    /* KEEP 
    add({
      variables: {
        update_columns: ["id", ...Object.keys(values), "image"],
        objects: [
          {
            ...values,
            image: imgBase64,
            sku: `${values.species_type}-${values.species_environment}-${values.species_sub_category}-${values.process_category}-${values.species_size}`,
            species_cost_postings: {
              data: {
                ...species_cost_posting,
              },
              on_conflict: {
                constraint: "species_cost_posting_pkey",
                update_columns: ["id", ...Object.keys(species_cost_posting)]
              }
            },
            species_inventories: {
              data: {
                ...species_inventories,
              },
              on_conflict: {
                constraint: "species_inventory_pkey",
                update_columns: ["id", ...Object.keys(species_inventories)]
              }
            },
            species_item_trackings: {
              data: {
                ...species_item_trackings,
              },
              on_conflict: {
                constraint: "species_item_tracking_pkey",
                update_columns: ["id", ...Object.keys(species_item_trackings)]
              }
            },
            species_plannings: {
              data: {
                ...species_plannings,
              },
              on_conflict: {
                constraint: "species_planning_pkey",
                update_columns: ["id", ...Object.keys(species_plannings)]
              }
            },
            species_pricing_and_sales: {
              data: {
                ...species_pricing_and_sales,
              },
              on_conflict: {
                constraint: "species_pricing_and_sales_pkey",
                update_columns: ["id", ...Object.keys(species_pricing_and_sales)]
              }
            },
            species_replenishments: {
              data: {
                ...species_replenishments,
              },
              on_conflict: {
                constraint: "species_replenishment_pkey",
                update_columns: ["id", ...Object.keys(species_replenishments)]
              }
            },
            species_warehouses: {
              data: {
                ...species_warehouses,
              },
              on_conflict: {
                constraint: "species_warehouse_pkey",
                update_columns: ["id", ...Object.keys(species_warehouses)]
              }
            },
          },
        ],
      },
    });
    */
  };


  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const handleInputChange = async (event) => {

    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      setImg(URL.createObjectURL(file))
    }

  }

  return (
    <Container>   
      <VendorCardHeader editMode={editMode} label={data?.species[0].label}/>
      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title="General">
          <div className="division">

            <Form.Item style={{ display: 'none' }} label="id" name={"id"} >
              <Input />
            </Form.Item>

            <Form.Item label="Species Name" name="label">
              <Input />
            </Form.Item>

            <Form.Item label="Species Identifier" name="species_identifier">
              <Input />
            </Form.Item>

            <Form.Item
              label="Product Active?"
              name="status"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item label="Species Description" name="description">
              <TextArea rows={10} />
            </Form.Item>
          </div>

          <div className="division">


            <div style={{ display: "flex", flexDirection: "row", marginBottom: 24 }}>
              <div style={{ marginRight: 126 }}>Pictures</div>
              <div style={{ display: "flex" }}>
                {img && <img src={img} alt="" style={{ height: 70, width: 70, marginRight: 10 }} />}
                <label for="upload-photo" style={{ display: "flex", backgroundColor: "#D9E3F3", alignItems: "center", justifyContent: "center" }}>
                  <PlusOutlined style={{ fontSize: 70, color: "#90ABDD" }} size={100} color="red" />
                </label>
                <input type="file" ref={imageRef} onChange={handleInputChange} id="upload-photo" style={{ display: "none" }} />
              </div>
            </div>

            <Form.Item label="Scientific Name" name="scientific_name" >
              <Input />
            </Form.Item>

            <Form.Item label="English" name="english" >
              <Input />
            </Form.Item>

            <Form.Item label="Malayalam" name="malayalam" >
              <Input />
            </Form.Item>

            <Form.Item label="Tamil" name="tamil" >
              <Input />
            </Form.Item>

            <Form.Item label="Bengali" name="bengali" >
              <Input />
            </Form.Item>

            <Form.Item label="Marathi" name="marathi" >
              <Input />
            </Form.Item>

            <Form.Item label="Gujarati" name="gujarati" >
              <Input />
            </Form.Item>

            <Form.Item label="Telugu" name="telugu" >
              <Input />
            </Form.Item>

          </div>
        </Section>

        <CommonDiv direction="row" justify="flex-end">
          <ButtonLoader loading={formLoading} htmlType="submit" >
            {editMode ? "Update" : "Save"}
          </ButtonLoader>

          <CommonButton className='reverse' onClick={() => history.goBack()}>
            Cancel
          </CommonButton>

        </CommonDiv>
      </Form>
    </Container>
  );
}

NewSpeciesPage.propTypes = {};

export default NewSpeciesPage;
