import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import React, { useState } from 'react';
import { GET_USER_TYPE } from 'Util/queries';
const { Option } = Select;

function UserTypeDropdown({ onChange, ...rest }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_USER_TYPE, {
        onCompleted() {
            setList(data.user_type)
        }
    });

    return (
        <div>
            <Select
                {...rest}
                style={{ width: 300 }}
                placeholder="Select a user type"
                onChange={onChange}
            >
                {list.map(v => <Option value={v.id} key={`user-type-dd-opt${v.id}`}>{v.label}</Option>)}
            </Select>
        </div>
    )
}

export default UserTypeDropdown
