import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Table, Typography, notification } from 'antd';
import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants';
import { acceptedColumns, statusQuery } from './common'
import { useMutation, useSubscription } from '@apollo/client';
import { SUB_DIRECT_OFFERS, SUB_DIRECT_OFFER_ORDERS } from 'Util/subscriptions';
import { cleanTitle, getDate, getTimeFromDate } from 'Util/common';
import _ from 'lodash'
import './style.scss';

function AcceptedDirectSales(props) {

    const { data, loading, error } = useSubscription(SUB_DIRECT_OFFER_ORDERS);

    const dataSource = (key = 'accepted', data) => {

        if (_.isUndefined(data)) {
            return []
        }

        return data.direct_offer_orders.map((v, i) => (
            {
                ...v.direct_offer,
                key: `key-${i}-direct-offers-${key}-list`,
                location: v.direct_offer.user_info.user_location.label || "",
                vendor: leadingZeroes(v.direct_offer.user_id, appConstant.leadingZero),
                time: getTimeFromDate(v.created_at, true),
                date: getDate(v.created_at),
                po: !_.isNull(v.purchase_order) ? leadingZeroes(v.purchase_order.id, appConstant.leadingZero) : v.purchase_order,
                price: v.order_quantity * v.direct_offer.price,
                order_quantity: v.order_quantity
            }
        ))
    }


    const cancel = () => { console.log('cancel editing') };

    return (
        <div className="direct-offer-page" >
            <Table
                loading={loading}
                bordered
                dataSource={dataSource('accepted', data)}
                columns={acceptedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    )
}

AcceptedDirectSales.propTypes = {

}

export default AcceptedDirectSales