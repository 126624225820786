import React from 'react'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'

function index() {
  return (
    <div>
      <SubPageBreadCrumbs />
      Procurement</div>
  )
}

export default index