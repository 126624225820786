import { Input } from 'antd';
import React from 'react';
import FileBase64 from 'react-file-base64';
function ImageInput({ onDone, ...rest }) {

    const onImageProcessDone = d => {
        onDone(d)
    }

    return (
        <>
            <img style={{ maxWidth: 450 }} src={rest.value} />
            <FileBase64
                multiple={false}
                onDone={onImageProcessDone} />
            <Input hidden {...rest} />
        </>
    )
}

export default ImageInput