import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { FilterFilled, InfoCircleFilled, UnorderedListOutlined } from '@ant-design/icons';
import { IconShare } from 'Components/Icons';

const Container = styled.div`
    
    width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        cursor: pointer;
    }
    
    svg{
        cursor: pointer;
    }`;

function ExtraFilters({ onShare, onFilter, onList, onInfo }) {

    return (
        <Container>
            <IconShare color={THEME.colors.primary} size={15} onClick={onShare} />
            <FilterFilled onClick={onFilter} />
            <UnorderedListOutlined onClick={onList} />
            <InfoCircleFilled onClick={onInfo} />
        </Container>
    )
}

ExtraFilters.propTypes = {
    onShare: PropTypes.func,
    onFilter: PropTypes.func,
    onList: PropTypes.func,
    onInfo: PropTypes.func
}

export default ExtraFilters