import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Popover, Tabs, Typography } from "antd";
import styled, { css } from "styled-components";
import { THEME } from "Util/constants";
import TableFilter from "./TableFilter";
import _ from "lodash";
import { PaperClipOutlined, InfoCircleFilled } from "@ant-design/icons";
import { CRMVendorContext } from "..";
import { useHistory, Link } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { vendorInfoAtom, selectedVendorAtom, vendorSearchAtom } from "Store/crmAtom";
import { useSubscription } from '@apollo/client'
import { SUB_VENDORS } from 'Util/subscriptions'
import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants'

const Container = styled.div``

const columns = [
    {
        title: 'Vendor ID',
        dataIndex: 'vendor_id',
        key: 'vendor_id',
        fixed: 'left',
        width: 140,
        render: (_, record) => (
            <Link style={{ color: THEME.colors.primary, fontSize: 16 }} to={{pathname: `/crm/vendors/edit/${record.id}`, state: { prevPath: '/crm/notifications/all-users' }}} >
                {record.vendor_id}
            </Link>
        ),
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 250
    },
    {
        title: 'Aadhaar ID',
        dataIndex: 'aadhaar_id',
        key: 'aadhaar_id',
        width: 150
    },
    {
        title: 'Mobile#',
        dataIndex: 'mobile',
        key: 'mobile',
        width: 150
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 250
    },
    {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact',
        width: 200
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: 300
    },
    {
        title: 'Location Code',
        dataIndex: 'location_code',
        key: 'location_code',
        width: 140
    },
    {
        title: 'VPA',
        dataIndex: 'vpa',
        key: 'vpa',
        width: 250
    },
    {
        title: 'Vessel Reg',
        dataIndex: 'vessel',
        key: 'vessel',
        width: 200
    },
];

function DataTable() {
    const [vendor, setSelectedVendor] = useRecoilState(selectedVendorAtom);
    const [search, setSearch ] = useRecoilState(vendorSearchAtom)
    const [data, setData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const { loading } = useSubscription(SUB_VENDORS, {
        variables: {
            where: {
                user: {
                    status: {_eq: 'verified'},
                    user_type: {
                        label: { _eq: "Mobile" }
                    }
                },
                deleted_at: { _is_null: true }
            }
        },
        onSubscriptionData({ subscriptionData: { data } }) {   
            data && setData(
                data.vendor_details.map(vd => ({
                    id: vd.user.id,
                    user: vd.user,
                    vendor_id: leadingZeroes(vd.user.id, appConstant.leadingZero),
                    name: vd.user.name ?? 'N/A',
                    aadhaar_id: vd.user.aadhaar_id ?? 'N/A',
                    mobile: vd.user.mobile ?? 'N/A',
                    email: _.isEmpty(vd.user.email) ? 'N/A' : vd.user.email,
                    contact: vd.contact_person_name ?? 'N/A',
                    location: vd.user.user_location.label ?? 'N/A',
                    location_code: leadingZeroes(vd.user.user_location.id, appConstant.leadingZero) ?? 'N/A',
                    vpa: _.isEmpty(vd.virtual_payment_address) ? 'N/A' : vd.virtual_payment_address,
                    vessel: leadingZeroes(vd.user.vessel, appConstant.leadingZero),
                    onboard_code: vd.user.onboard_code,
                    channels: vd.user.channel_ids
                }))
            )
        }
    });

    const searchable = data.map((obj, i) => {
        let result = [];
        let concatenatedValues = "";
        columns.forEach(column => {
          if (obj[column.key] && obj[column.key] !== undefined) concatenatedValues += obj[column.dataIndex] + " ";
        });
        result.push(concatenatedValues.trim());
    
        return {
          ...obj,
          searchText:  result.toString()
        }
      })
    
    const filterData = (data) => {
        if(!search){
            return data
        }
        return _.filter(searchable, function (d) { 
            let query = search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    };

    const onSelectChange = (newSelectedRowKeys, data) => {
        setSelectedVendor(data)
        setSelectedRowKeys(newSelectedRowKeys);
      };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

    useEffect(() => {
        //return () => { setInfo(false)}
    }, [])
    
    return (
        <Container>
            <TableFilter data={filterData(data)} column={columns}/>
            <Table
                loading={loading}
                columns={columns}
                dataSource={filterData(data)}
                rowSelection={rowSelection}
                pagination={{ defaultPageSize: 50 }}
                scroll={{ y: 500, x: 1500 }}
                rowKey={rec => parseInt(rec.vendor_id, 10)}
            />
        </Container>
    )

}

DataTable.propTypes = {
};

export default DataTable;

