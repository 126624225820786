import React from 'react'
import { Divider } from 'antd'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Link } from 'react-router-dom';

const Container = styled.div`
    background-color: ${THEME.colors.primary};
    color: #fff;
    padding: 10px;
    width: 110px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 10px 0px 0px;
    cursor: pointer;

    ${props => props.blue && css`
        background-color: ${THEME.colors.blue};
    `}

    ${props => props.lightBlue && css`
        background-color: ${THEME.colors.lightBlue};
    `}

    ${props => props.selected && css`
        background-color: #cccccc !important;
    `}

    .card-title {
        display: block;
        line-height: 16px;
        white-space: initial;
        min-height: 30px;

        &.center { text-align: center; }
    }

    .card-content {
        display: flex;
        &.center { justify-content: center; }
        h1, h2, h3 { color: #fff; }
    }

    .button-card-divider {
        display: block;
        border-top: 1px solid #fff;
        line-height: 16px;
        font-weight: bold
    }   
`

function ButtonCard({ title, children, center = false, link = "#", ...rest }) {

    return (
        <Link to={link}>
            <Container className='button-card' {...rest} >
                <span className={`card-title ${center ? 'center' : ''}`} >{title}</span>
                <div className={`card-content ${center ? 'center' : ''}`}> {children} </div>
                <span className='button-card-divider' >{`>`}</span>
            </Container>
        </Link>
    )
}

export default ButtonCard