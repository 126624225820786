import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Select, Input, Popconfirm, notification } from 'antd';
import { SearchOutlined, PlusOutlined, DeleteFilled, EditOutlined } from '@ant-design/icons';
import ExtraFilters from './ExtraFilters';
import { Link, useHistory } from 'react-router-dom';
import { CollectionPointsContext } from '..';
import NewLocationModal from './NewLocationModal';
import { useMutation } from '@apollo/client';
import { UPDATE_COLLECTION_LOCATION_POINT } from 'Util/mutations';
import moment from 'moment'
import { collectionPointSearchAtom } from 'Store/collectionPointsAtom';
import { useRecoilState } from 'recoil';
import { debounce } from 'lodash';

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const MenuButtonContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

function LocationPointsTableFilter(props) {

    const history = useHistory()
    const { selectedRecord, setSelectedRecord, setFitler } = useContext(CollectionPointsContext)
    const [searchRecord, setSearchRecord] = useRecoilState(collectionPointSearchAtom) 

    const [update] = useMutation(UPDATE_COLLECTION_LOCATION_POINT, {
        onCompleted() {
            notification['success']({
                message: 'Deleted!',
                description: `Collection Location ${selectedRecord.location_name} has been deleted!`,
            });
            setSelectedRecord({})
        }
    })

    const deleteRecord = () => {
        update({
            variables: {
                where: { id: { _eq: selectedRecord.id } },
                _set: { deleted_at: moment() }
            }
        })
    }

    const updateQuery = (e) =>{
        setSearchRecord(e.target.value)
    }
    const debouncedOnChange = debounce(updateQuery, 200);


    return (
        <Container>

            <DropdownContainer>
                <label>Locations: </label>
                <Select defaultValue="active"
                    style={{ width: 100 }}
                    bordered={false}
                    onChange={v => { setFitler({ is_active: v === 'active' }) }}
                >
                    <Option value="active">Active</Option>
                    <Option value="inactive">Inactive</Option>
                </Select>
            </DropdownContainer>

            <SearchContainer>
                <Input prefix={<SearchOutlined />} style={{ width: 500 }} placeholder="Search" onChange={(e) => debouncedOnChange(e)}/>
            </SearchContainer>

            <NewLocationModal />

            <MenuButtonContainer>
                {
                    selectedRecord.id ? <Popconfirm
                        title="Are you sure to delete this location?"
                        onConfirm={deleteRecord}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Link to='#'><DeleteFilled /></Link>
                    </Popconfirm> : <Link to='#'><DeleteFilled /></Link>
                }
                <label>Delete</label>
            </MenuButtonContainer>

            <MenuButtonContainer>
                <Link to={{ pathname: selectedRecord.id && `collection-points/edit/${selectedRecord.id}`}}  > <EditOutlined /> </Link>
                <label>Edit</label>
            </MenuButtonContainer>

            <ExtraFilters data={props.data} columns={props.columns}/>

        </Container>
    )
}

LocationPointsTableFilter.propTypes = {}

export default LocationPointsTableFilter
