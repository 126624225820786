import { atom } from "recoil";

export const vendorDetailAtom = atom({
    key: "vendorDetailAtom",
    default: {}
})

export const selectedVendorAtom = atom({
	key: "selectedVendor",
	default: {},
});

export const selectedGroupAtom = atom({
	key: "selectedGroup",
	default: {},
});

export const vendorSearchAtom = atom({
	key: "vendorSearch",
	default: ""
});

export const notificationAtom = atom({
	key: "notification",
	default: ""
});