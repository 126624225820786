import { CheckOutlined, CloseOutlined, UndoOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useSubscription } from '@apollo/client';
import { Button, InputNumber, notification, Popover, Table, Switch, Row, Col, Popconfirm } from 'antd';
import { AppContext } from 'AppContext';
import useSettings from 'Hooks/settings'
import CurrencyText from 'Components/CurrencyText';
import Status from 'Components/Status';
import gmap from 'gmap';
import leadingZeroes from 'leading-zeroes';
import moment from 'moment';
import React, { useState, useContext } from 'react';
import { cleanTitle, getDate, getTimeFromDate } from 'Util/common';
import { UPDATE_SETTINGS } from 'Util/mutations';
import { GET_SETTINGS } from 'Util/queries';
import { appConstant, mapConstant } from 'Util/constants';
import { ADD_PICKUP_ORDER, AMMEND_QUOTA, UPDATE_BID_REJECT_STATUS, UPDATE_BID_STATUS, UPDATE_COUNTER_OFFER } from 'Util/mutations';
import { SUB_BIDS } from 'Util/subscriptions';
import _ from 'lodash'

/** TODO:
 * continue with label values
 * issue label has multiple words
 */

const columns = [
    "date",
    "time",
    "auction_id",
    "id",
    "fisherman_ID",
    "location",
    "species",
    "volume",
    "price",
    "reserve_price",
    "cost",
    // "total_cost(Unit)",
    // "gross",
    // "daily_quota",
    "rem_quota",
    "status",
    "actions"
].map((v, i) => {

    if (v === "species") {
        return {
            title: cleanTitle(v),
            sorter: {
                compare: (a, b) => a.species.localeCompare(b.species)
            },
            dataIndex: v,
            key: `species-${v}`
        }
    }

    if (v === "rem_quota") {
        return {
            title: "Rem Quota",
            dataIndex: v,
            key: `rem-quota-${v}`,
            render: (prop) => <RemQoutaAction {...prop} />
        }
    }

    if (v === "auction_id") {
        return {
            title: "Auction #",
            sorter: {
                compare: (a, b) => a.auction_id - b.auction_id
            },
            dataIndex: v,
            key: `auction-number-${v}`
        }
    }

    if (v === "id") {
        return {
            title: "Lot #",
            sorter: {
                compare: (a, b) => a.id - b.id
            },
            dataIndex: v,
            key: `lot-number-${v}`
        }
    }

    if (v === "fisherman_ID") {
        return {
            title: "Fisherman ID",
            sorter: {
                compare: (a, b) => a.fisherman_ID - b.fisherman_ID
            },
            dataIndex: v,
            key: `fisherman-number-${v}`
        }
    }


    if (v === 'price' || v === 'cost') {
        return {
            title: cleanTitle(v),
            sorter: {
                compare: (a, b) => (v === 'cost' ? a.cost - b.cost : a.price - b.price)
            },
            dataIndex: v,
            key: v,
            render: (val) => <CurrencyText value={val} />
        }
    }

    if (v === 'reserve_price') {
        return {
            title: cleanTitle(v),
            sorter: {
                compare: (a, b) => a.reserve_price - b.reserve_price
            },
            dataIndex: v,
            key: `reserve-price-key-${v}`,
            render: (val) => <CurrencyText value={val} />
        }
    }

    if (v === 'volume') {
        return {
            title: cleanTitle(v),
            sorter: {
                compare: (a, b) => a.volume - b.volume
            },
            dataIndex: v,
            key: `volume-key-${v}`,
            render: (val) => <span>{`${val}${appConstant.volumeUnit}`}</span>
        }
    }

    if (v === 'date') {
        return {
            title: cleanTitle(v),
            dataIndex: v,
            key: v,
            sorter: (a, b) => (moment(a.rawDate).unix() - moment(b.rawDate).unix()),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend'
        }
    }

    if (v === 'actions') {
        return {
            title: cleanTitle(v),
            dataIndex: v,
            key: v,
            render: (prop) => <BidActions {...prop} />
        }
    }

    if (v === 'status') {
        return {
            title: cleanTitle(v),
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status)
            },
            dataIndex: v,
            key: v,
            render: v => <Status value={v} />
        }
    }

    return {
        title: cleanTitle(v),
        dataIndex: v,
        key: v
    }
})


const RemQoutaAction = ({ remQuota, product_volume, product_id, auction_id, status }) => {

    /**States */
    const [popUp, setPopUp] = useState(false);
    const [quota, setQuota] = useState(product_volume);

    /**Mutations */
    const [ammendQuota] = useMutation(AMMEND_QUOTA, {
        onCompleted() {
            notification['success']({
                message: 'Updated!',
                description:
                    'New quota has been updated.',
            });
            setPopUp(false);
        }
    });

    const submitNewQuote = () => {

        const variables = {
            auction_id,
            "quota": quota - product_volume,
            product_id,
            "volume": quota - product_volume
        }

        ammendQuota({ variables })
    }

    const adjustQoutaContent = () => {
        return <div className="adjust-pop-wrapper">
            <InputNumber placeholder="Quota" value={quota} onChange={v => setQuota(v)} width={200} name="qouta" />
            <Button size="small" shape="circle" type="primary" icon={<CheckOutlined />} onClick={() => submitNewQuote()} />
        </div>
    }

    return <div className="rem-qouta-add-content" >
        <span>{remQuota}</span>

        {status !== 'accepted' && <Popover
            content={adjustQoutaContent}
            title="New Quota"
            trigger='click'
            visible={popUp}
            onVisibleChange={visble => setPopUp(visble)}
        >
            <Button size="small" shape="circle" type="primary" icon={<PlusOutlined />} />
        </Popover>}

    </div>
}


const BidActions = ({ id, status, user, product_vol, price, volume, bids, currQuota }) => {

    const [popUp, setPopUp] = useState(false);
    const [counterPrice, setCounterPrice] = useState(price);
    const [counterVolume, setCounterVolume] = useState(volume);

    const [addPickUpOrder] = useMutation(ADD_PICKUP_ORDER);
    const [updateRejectBidStatus] = useMutation(UPDATE_BID_REJECT_STATUS);
    const [updateConterOffer] = useMutation(UPDATE_COUNTER_OFFER, {
        onCompleted() {
            counterClose()
        }
    })
    const [updateBidStatus] = useMutation(UPDATE_BID_STATUS, {
        onCompleted(data) {

            const link = gmap.directions(mapConstant.pickupCoordinates, `${user.user_location.lat},${user.user_location.lng}`)
            const objects = {
                purchase_order_id: data.insert_purchase_orders.returning[0].id,
                bid_id: id,
                user_id: user.id,
                eta: 10,
                link
            }

            addPickUpOrder({ variables: { objects } })

            notification['success']({
                message: 'Posted!',
                description:
                    'Bid status details has been updated',
            });

        }

    })


    const confirmHandler = (status) => {

        const rem_quota = product_vol - (_.sumBy(bids, 'volume') + volume)

        const objectUpdate = {
            variables: {
                id,
                obj: {
                    status,
                    rem_quota
                },
                po_object: {
                    bid_id: id,
                    user_id: user.id
                }
            }
        }

        updateBidStatus(objectUpdate)
    }

    const rejectHandler = (status) => {
        updateRejectBidStatus({ variables: { id, status } })
    }

    const counterHandler = () => {

        const counter = {
            price: counterPrice,
            volume: counterVolume,
            prev_price: price,
            prev_volume: volume
        }

        updateConterOffer({ variables: { id, counter } })
    }

    const counterClose = () => {
        setPopUp(false);
        setCounterPrice(0)
        setCounterVolume(0)
    }



    const counterOfferContent = () => {
        return (
            <div className="counter-pop-wrapper">
                <label>Price:</label>
                <InputNumber placeholder="Price" defaultValue={price} onChange={v => setCounterPrice(v)} width={200} name="counterPrice" />
                <label>Volume:</label>
                <InputNumber placeholder="Volume" defaultValue={volume} onChange={v => setCounterVolume(v)} width={200} name="counterVolume" />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <a onClick={() => counterClose()}>Close</a>
                    <a onClick={() => counterHandler()}>Submit</a>
                </div>
            </div>
        )
    }

    return (
        <div className="action-buttons-wrapper">
            <Button size="small" disabled={(status !== 'pending') || !currQuota} shape="circle" type="primary" icon={<CheckOutlined />} onClick={() => confirmHandler('accepted')} />
            <Button size="small" disabled={(status !== 'pending')} shape="circle" type="primary" danger icon={<CloseOutlined onClick={() => rejectHandler('rejected')} />} />

            <Popover
                content={counterOfferContent}
                title="Counter Offer"
                trigger={(status !== 'accepted') ? 'click' : ''}
                visible={popUp}
                onVisibleChange={visble => setPopUp(visble)}
            >
                <Button size="small" disabled={(status === 'accepted')} shape="circle" type="primary" icon={<UndoOutlined />} />
            </Popover>


        </div>
    )

}

function AuctionLiveList(props) {

    var autoAcceptDefaultValue = '';

    const { refetchSettings } = useContext(AppContext)
    const [settings] = useSettings('auction');
    const [check, setCheck] = useState('')
    const [checked, setChecked] = useState(false)

    const { data, loading, error } = useSubscription(SUB_BIDS);

    let bidsObjects = []

    if (!loading && data) {
        bidsObjects = data.bids.map(bid => {
            const { id, rem_quota, auction_id, product, status, volume } = bid
            return { id, rem_quota, auction_id, product_id: product.id, status, volume }
        })
    }

    const [updateSettings, { data: sdata }] = useMutation(UPDATE_SETTINGS, {
        onCompleted(sdata) {
            refetchSettings();
            notification['success']({
                message: 'Saved!',
                description:
                    'Auction settings has been saved',
            });

        },
        onError(e) {
            console.log('erre', e)
        }
    })

    if (_.isUndefined(settings)) {
        return <></>
    }

    const defaultChecked = () => {

        return settings ? settings.automatic_bid_acceptance : false

    }

    if (!_.isUndefined(settings.automatic_bid_acceptance)) {
        var autoAcceptDefaultValue = settings.automatic_bid_acceptance
    }

    const dataSource = () => {

        if (data) {

            /** TODO: current auction only */
            return data.bids.map((v, i) => {

                const bids = _.filter(bidsObjects, { auction_id: v.auction_id, product_id: v.product.id, status: 'accepted' });
                const remQuotaMin = _.minBy(bids, 'rem_quota');
                const currQuota = _.isUndefined(remQuotaMin) ? v.product.volume : remQuotaMin.rem_quota

                return {
                    key: `key-${i}-bid-list`,
                    fisherman_ID: leadingZeroes(v.user.id, appConstant.leadingZero),
                    location: v.user.user_location.label,
                    species: v.product.species.label,
                    volume: v.volume,
                    cost: v.volume * v.price,
                    price: v.price,
                    reserve_price: v.product.price,
                    status: v.status,
                    time: getTimeFromDate(v.created_at, true),
                    date: getDate(v.created_at),
                    actions: {
                        id: v.id,
                        status: v.status,
                        user: v.user,
                        product_vol: v.product.volume,
                        volume: v.volume,
                        price: v.price,
                        bids,
                        currQuota
                    },
                    rawDate: v.created_at,
                    auction_id: v.auction_id,
                    id: leadingZeroes(v.id, appConstant.leadingZero),
                    rem_quota: {
                        remQuota: _.isNull(v.rem_quota) ? `${currQuota}/${v.product.volume}` : `${v.rem_quota}/${v.product.volume}`,
                        product_volume: v.product.volume,
                        product_id: v.product.id,
                        auction_id: v.auction_id,
                        status: v.status
                    }
                }

            })
        }

        return []

    }

    const onClick = (v) => {

        if (v) {
            setCheck(false)

        } else {
            setCheck(true)
        }

        setChecked(v)

    }

    const confirm = () => {

        const values = { ...settings, automatic_bid_acceptance: checked }

        updateSettings({
            variables: {
                key: "auction",
                values
            }
        })

        setCheck(checked)

    }

    return (
        <>
            <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Col span={24} style={{ padding: '5px', position: 'absolute', marginTop: '-57px' }}>
                    <div align="right">
                        <label style={{ marginRight: '5px' }} >Auto Accept</label>
                        <Popconfirm placement="left" title='Are you sure you want to do this?' onConfirm={confirm} okText="Yes" cancelText="No">
                            <Switch checked={autoAcceptDefaultValue ? true : check} onClick={(v) => onClick(v)} checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                        </Popconfirm>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <div className="action-live-table" >

                        <Table
                            loading={loading}
                            bordered
                            className="bid-table"
                            pagination={{ pageSize: 50 }}
                            columns={columns}
                            dataSource={dataSource()}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

AuctionLiveList.propTypes = {

}

export default AuctionLiveList

