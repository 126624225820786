import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ArrowLeftOutlined, EditFilled, DeleteFilled, PlusOutlined, CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { IconFlow, IconShare } from 'Components/Icons';
import { THEME, productOnboardStatus, approvalType } from 'Util/constants';
import { useHistory } from 'react-router-dom';
import { Modal, notification } from 'antd'
import str from 'string-sanitizer'
import CommonDiv from 'Components/CommonDiv'
import { isUndefined } from 'lodash'
import CommonConfirmModal from 'Components/Modal/ModalConfirm';
import { useRequestAproval } from '../../../../../Hooks/approval';
import { getApprovalWorkFlow } from '../../../../../Util/common';
import User from 'Hooks/user';
import {  UPDATE_PRODUCT } from 'Util/mutations';
import { useMutation, useQuery } from '@apollo/client';

const menu = [
    { label: "Process" },
    { label: "Item" },
    { label: "Prices & Discount" },
    { label: "Request Approval" },
    { label: "More Options" }
]

/** menu component */
const SecondMenu = ({ onMenuClick, currentStatus, currentRole }) => {

    let finalMenu = [];
    const menu2 = [
        { icon: <CheckOutlined style={{ color: THEME.colors.primary }} />, label: "Send Approval Request", role: 2, status: [productOnboardStatus.REVIEW] },
        { icon: <CloseOutlined style={{ color: 'red' }} />, label: "Cancel Approval Request", role: 2, status: [productOnboardStatus.REVIEW] },
        { icon: <IconFlow color={THEME.colors.primary} size={18} />, label: "Create Flow", role: 0 },
        { icon: <IconFlow color={THEME.colors.primary} size={18} />, label: "See my Flows", role: 0 }
    ]

    menu2.forEach(v => {
        if (isUndefined(v.status)) {

            finalMenu.push(
                <a key={`vendor-header-${str.addDash(v.label)}`} onClick={() => { onMenuClick(v.label) }} >
                    {v.icon && <span style={{ marginRight: 5 }}>{v.icon}</span>}
                    {v.label}
                </a>);
        } else {
            finalMenu.push(
                <a disabled={!v.status.includes(currentStatus)} key={`vendor-header-${str.addDash(v.label)}`} onClick={() => { v.status.includes(currentStatus) && onMenuClick(v.label) }} >
                    {v.icon && <span style={{ marginRight: 5 }}>{v.icon}</span>}
                    {v.label}
                </a>)
        }

    })

    return finalMenu
}

const Container = styled.div`
    .actions-wrapper{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icons-wrapper{
            justify-content: space-between;
            width: 180px;
            svg{
                color: ${THEME.colors.primary};
                font-size: 25px;
                cursor: pointer;
            }
        }
        
        .backIconWrapper{
            background-color: ${THEME.colors.primary};
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
        h1{
            margin: 0px 10px;
        }
        
    }

    .submenu-wrapper{

        padding: 10px 0px;
        border-bottom: solid #555 thin;
        border-top: solid #555 thin;
        margin: 20px 0px;

        &>a{
            color: #555;
            margin: 0px 10px;
            font-size: 16px;
            font-weight: 500;
            &[disabled]{
                color: #ccc;
                .anticon{
                    color: #ccc !important;
                }
            }
        }
    }
`

function Header({ editMode = false, label, onboardStatus, pid, onEvent }) {

    const history = useHistory()
    const { requestApproval } = useRequestAproval()
    const { user_id, hasRole } = User()

    const [updateProduct] = useMutation(UPDATE_PRODUCT)

    const backHandler = () => { 
        history.push(history.location.state.prevPath)
    }

    const requestApprovalHandler = (status) => {

        updateProduct({
            variables: {
                id: pid,
                object: { onboard_status: status }
            }
        }).then(res => {
            notification['success']({
                message: status === 'reject' ? 'Rejected!' : 'Requested!',
                description: `Product approval has been ${status === 'reject' ? 'rejected' : 'requested'}! `,
            });
        })
    }

    const subMenuClickHandler = (val) => {

        switch (val) {

            case "Request Approval":

                onboardStatus === 'pending' && CommonConfirmModal({
                    content: `Request Approval for Item - ${label}?`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                        requestApprovalHandler(productOnboardStatus.REVIEW)
                        onEvent()
                    },
                })

                break;

            case "Send Approval Request":
                CommonConfirmModal({
                    content: `Request Final Approval for Item - ${label}?`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                        requestApprovalHandler(productOnboardStatus.VERIFIED)
                        requestApproval({
                            role: 1,
                            requester_id: user_id,
                            type: approvalType.NEW_PRODUCT_APPROVAL,
                            workflow: getApprovalWorkFlow(approvalType.NEW_PRODUCT_APPROVAL),
                            ref_id: pid
                        })
                        onEvent()
                    },
                })

                break;

            case "Cancel Approval Request":

                CommonConfirmModal({
                    content: `Cancel Approval for ${label}s On-Boarding.`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                        requestApprovalHandler(productOnboardStatus.REJECTED)
                        onEvent()
                    },
                    onCancel: () => { }
                })

                break;

            default:
                alert(val)
                break;
        }

    }

    return (
        <Container>

            <div className='actions-wrapper' >

                <div>
                    <button onClick={backHandler} className='backIconWrapper' >
                        <ArrowLeftOutlined />
                    </button>

                    <h1>{editMode ? 'Edit' : ''} Item Card</h1>
                </div>

                <div className='icons-wrapper' >
                    <EditFilled />
                    <IconShare color={THEME.colors.primary} size={25} />
                    <DeleteFilled />
                    <PlusOutlined />
                </div>

            </div>

            <CommonDiv direction="row">
                {editMode && <h3 style={{ padding: '20px 10px 0px', }} >{label}</h3>}
            </CommonDiv>

            {editMode && <div className='submenu-wrapper'>
                {menu.map(v => (
                    <a key={`vendor-header-sub-${str.addDash(v.label)}`} 
                        onClick={() => subMenuClickHandler(v.label)}
                       disabled={v.label === "Request Approval" && onboardStatus !== 'pending'}
                    >{v.label}</a>
                ))}
            </div>}

            {editMode && <div className='submenu-wrapper'>
                <SecondMenu onMenuClick={event => subMenuClickHandler(event)} currentStatus={onboardStatus} />
            </div>}
        </Container>
    )
}

Header.propTypes = {}

export default Header
