import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 15, color: "white" }} spin />;


const colorMap = {
    primary: THEME.colors.primary,
    success: THEME.colors.green,
    warn: THEME.colors.orange,
    error: THEME.colors.red,
    none: "#fff"
}


const CommonButton = styled(Button)`
    background-color: ${props => colorMap[props.type] || THEME.colors.primary};
    color: ${props => props.type === "none" ? "#000" : "white"} ;
    font-size: 16px;
    font-weight: bold,
    height: auto;
    min-width: 120px;
    margin: 0px 5px;
    span{
        font-weight: bold;
    }
    &:hover, &:active, &:focus {
        background-color: ${THEME.colors.gray};
        color: white;
    }

    &.reverse {
        background-color: white;
        color: ${THEME.colors.primary};
        &:hover, &:active, &:focus {
            background-color: ${THEME.colors.gray};
            color: white
        }
    }
`

export function ButtonLoader({ children, loading = false, ...rest }) {
    return (
        <CommonButton {...rest} loading={loading}>
            <span>{children}</span>
        </CommonButton>
    )
}

export default CommonButton
