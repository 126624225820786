import { CameraFilled, DeleteFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Table } from 'antd';
import CommonButton from 'Components/CommonButton';
import CommonDiv from 'Components/CommonDiv';
import CommonConfirmModal from 'Components/Modal/ModalConfirm';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { THEME } from 'Util/constants';
import { GET_PURCHASE_ORDERS } from 'Util/queries';
import { JobCardContext } from '../../JobCard';
import SelectPurchaseOrder from './SelectPurchaseOrder';

const StyledTable = styled(Table)`
  .ant-table-placeholder {
    display: none;
  }
`;

function PurchaseOrderItems(props) {
    const { setFieldValue, jobId } = props
    const [select, showSelect] = useState(false)
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const {purchaseOrderList, setPurchaseOrderList} = useContext(JobCardContext)

    const { loading } = useQuery(GET_PURCHASE_ORDERS, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                job_id: { _eq: jobId ?? 0 },
            }
        },
        onCompleted(data) {    
            const newData = data.purchase_orders.map(po => {
                return {
                    key: `key-${po.id}-po-list`,
                    id: po.id,
                    image: po.bid?.product?.image || po.direct_offer_order?.direct_offer?.image,
                    description: po.bid?.product?.label || po.direct_offer_order?.direct_offer?.species_name,
                    qty: po.bid?.volume ?? po.direct_offer_order?.order_quantity,
                    status: po.status,
                };
            });
            setData(newData);
            setPurchaseOrderList(data.purchase_orders)
            setFieldValue('purchase_orders', newData.map(item => item.id));

        }
    });

    const handleDelete = (id) => {
        CommonConfirmModal({
            content: `Are you sure you would like to Purchase Order# ${id}?`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                const newData = data.filter(item => item.id !== id);
                setPurchaseOrderList(prev => prev.filter(item => item.id !== id));
                setData(newData);
                setFieldValue('purchase_orders', newData.map(item => item.id));
            },
        })
    }

    const handleAdd = (item) => {
        if (!data.some(d => d.id === item.id)) {
            const newData = [...data, {
                key: `key-${item.id}-po-list`,
                id: item.id,
                image: item.bid?.product?.image || item.direct_offer_order?.direct_offer?.image,
                description: item.bid?.product?.label || item.direct_offer_order?.direct_offer?.species_name,
                qty: item.bid?.volume ?? item.direct_offer_order?.order_quantity,
                status: item.status,
            }]
            setData(newData)
            setPurchaseOrderList([...purchaseOrderList, item])
            setFieldValue('purchase_orders', newData.map(item => item.id));
            showSelect(true)
        }
    }

    const columns = [
        {
            title: 'PO #',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <a style={{ color: THEME.colors.primary }}>{text}</a>,
            width: "10%"
        },
        {
            title: () => <CameraFilled />,
            dataIndex: 'image',
            key: 'image',
            render: (url) => <img src={url} style={{ width: 50 }} />,
            width: '10%'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (_, rec) => ( 
                <>
                    <div style={{fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{rec.description}</div>
                    <div>Fresh (whole, unprocessed)</div>
                </>
            ),
            width: "30%"
        },
        {
            title: 'QTY (KG)',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <span><DeleteFilled onClick={()=> handleDelete(id) }style={{color: THEME.colors.primary}}/></span>,
            width: '10%'
        }
    ];

    if (loading) return <></>

    return (
        <div>
            <CommonDiv direction='row' justify='space-between' style={{ marginBottom: 10 }}>
                <label>Item Details:</label>
                <CommonButton className='' onClick={() => {showSelect(true)}}>
                    + Add Item
                </CommonButton>
            </CommonDiv>
            <div style={{ maxHeight: 450, overflow: "auto", maxWidth: 1400}}>
                <Table pagination={false} columns={columns} dataSource={data} sticky /> 
            </div>
            <SelectPurchaseOrder showModal={showModal} setShowModal={setShowModal} handleAdd={handleAdd} excludedIds={data.map(item => item.id)} jobId={jobId}/>

            {(select || data.length === 0) && <div style={{ color: THEME.colors.primary, padding: 20, cursor: "pointer" }} onClick={() => setShowModal(true)}>Select PO#</div>}
        </div>
    )
}

export default PurchaseOrderItems