
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 33.33%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function NewSpecies(props) {
    const history = useHistory()
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState('species')

    const okHandler = () => {
        if(selected){
            setVisible(false);
            history.push(`/procurement/species-database/new/${selected}`)
        }
    }

    return (
        <Container>
            <PlusOutlined onClick={() => setVisible(true)} />
            <label>New</label>
            <Modal
                visible={visible}
                centered
                title="Select a template for a new species"
                width={600}
                closable={false}
                bodyStyle={{ background: '#F2F2F2' }}
                footer={[
                    <Button key="ok" type="primary" onClick={okHandler} style={{ backgroundColor: THEME.colors.primary, borderColor: THEME.colors.primary}}>OK</Button>,
                    <Button key="cancel" onClick={() => setVisible(false)}>Cancel</Button>
                ]}

            >
                <ModalContainer>

                    <div className='thead' >
                        <span> Code </span>
                        <span> Description </span>
                    </div>

                    <div onClick={() => setSelected('species')} className={`tbody ${selected === 'species' ? 'selected' : ''}`} >
                        <span> Species </span>
                        <span> Species Card</span>
                    </div>

                </ModalContainer>
            </Modal>
        </Container>
    )
}

NewSpecies.propTypes = {}

export default NewSpecies