import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useState } from 'react';
import { GET_SHIPPERS } from 'Util/queries';
const { Option } = Select;

function ShippersDropDown({ onChange, form, ...rest }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_SHIPPERS, {
        variables: {
            where: {
                deleted_at: { _is_null: true }
            }
        },
        onCompleted() {
            setList(data.shippers)
        }
    });


    const handleChange = (value) => {
        const selected = list.find(item => item.id === value);
        if (form) {
            form.setFieldsValue({
                shipper_name: selected.name,
            });
        }
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div>
            <Select
                {...rest}
                placeholder="Select a shipper"
                onChange={handleChange}
            >
                {list.map(v => <Option value={v.id} key={`shipper-dd-opt${v.id}`}>{v.name}</Option>)}
            </Select>
        </div>
    )
}

export default ShippersDropDown
