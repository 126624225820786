import { Button, Drawer } from 'antd';
import React from 'react';

function CommonDrawer({ open, form, children, title, onSubmit=()=>{}, onClose=()=>{} }) {

    const createDrawerClose = () => {
        form.resetFields()
        onClose()
    }

    const createDrawerSubmit = () => {
        onSubmit();
        form.submit()
    }

    return <Drawer
        visible={open}
        onClose={() => { createDrawerClose() }}
        title={title}
        width={750}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Button onClick={() => createDrawerClose()} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
                <Button onClick={() => createDrawerSubmit()} type="primary">
                    Submit
          </Button>
            </div>
        }
    >
        {children}
    </Drawer>
}

export default CommonDrawer
