
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Modal } from 'antd';
import { useSubscription } from '@apollo/client';
import { COLLECTION_LOCATIONS } from 'Util/subscriptions';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 50%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function ChooseLocation(props) {
    const [selected, setSelected] = useState('company')

    const { data } = useSubscription(COLLECTION_LOCATIONS, {
        variables: {
            where: {
                is_active: { _eq: true },
                deleted_at: { _is_null: true }
            }
        }
    })

    const { showModal, setShowModal, setFieldValue } = props

    const okHandler = () => {
        setShowModal(false);
        setFieldValue('collection_location_id', selected.id)
        setFieldValue('location_code', selected.location_code)
        setFieldValue('pickup_location', selected.location_name)
    }
 
    return (
        <Container>
        <Modal
            visible={showModal}
            centered
            onCancel={() => setShowModal(false)}
            onOk={okHandler}
            title={
            <div style={{ flexDirection: "row", display: "flex" }}>
                <span style={{ marginRight: 50 }}>Select Location</span>
            </div>
            }
            okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
            width={600}
            closable={false}
            bodyStyle={{ background: '#F2F2F2' }}
        >
            <ModalContainer>

            <div className='thead' >
                <span> Code </span>
                <span> Location </span>
            </div>

            {data?.collection_locations?.length > 0 &&
                data.collection_locations.map((item) => (
                <div key={`ChooseLocation-${item.id}`} onClick={() => setSelected(item)} className={`tbody ${selected.id === item.id ? 'selected' : ''}`} >
                    <span> {item.location_code} </span>
                    <span> {item.location_name} </span>
                </div>
                ))
            }

            </ModalContainer>
        </Modal>
        </Container>
    )
}

ChooseLocation.propTypes = {}

export default ChooseLocation
