import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Checkbox, Form, Input, notification, Switch } from 'antd';
import CommonButton, { ButtonLoader } from 'Components/CommonButton';
import CommonDiv from 'Components/CommonDiv';
import { CashFlowDropdown, PartnerTypeDropdown, PaymentCodeDropdown, ShipmentCodeDropdown } from 'Components/Dropdowns';
import { FormDivision, FormSection } from 'Components/Form';
import LocationDropdown from 'Components/LocationDropdown';
import leadingZeroes from 'leading-zeroes';
import { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { appConstant, onesignal, communication } from 'Util/constants';
import { ADD_VENDOR, UPDATE_USER_OBJECT, UPDATE_VENDOR } from 'Util/mutations';
import { GET_LOCATION, GET_VENDOR, GET_USER_CHANNELS } from 'Util/queries';
import { v4 as uuidv4 } from 'uuid';
import { VendorCardHeader, TagAdder, ActivityTimelineTable } from './components/VendorCard';
import { useRecoilState } from 'recoil';
import { vendorDetailAtom, selectedVendorAtom } from '../../../Store/crmAtom';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash'
import axios from 'axios';
import PhoneValidator from 'Components/PhoneValidator'
import { uploadImage } from 'Util/imageUploaderS3';
import SellerTypeDropdown from 'Components/SellerTypeDropdown';
import CollectionCentreDropdown from 'Components/CollectionCentreDropdown';

const Container = styled.div``
function NewVendorPage(props) {

    const [form] = Form.useForm();
    const params = useParams()
    const routeLocation = useLocation()
    const history = useHistory()
    const [editMode, setEditMode] = useState(routeLocation.pathname.includes('/crm/vendors/edit'))
    const [formLoading, setFormLoading] = useState(false)
    const [vendorId, setVendorId] = useState('')
    const [disabled, setDisabled] = useState(routeLocation.pathname.includes('/crm/vendors/edit'))
    const [img, setImg] = useState();
    const [origImg, setOrigImg] = useState(null);
    const imageRef = useRef(null)
    const onboard_code = uuidv4();
    const [location, setLocation] = useState([])
    const [tagList, setTagList] = useState([])
    const [disableEmail, setDisableEmail] = useState(true)

    const [vendor, setVendor] = useRecoilState(vendorDetailAtom) //TODO
    const [selectedVendor, setSelectedVendor] = useRecoilState(selectedVendorAtom);

    const [getLocation] = useLazyQuery(GET_LOCATION, {
        onCompleted(data) {
            const { id, lat, lng, address } = data.user_location[0]
            form.setFieldsValue({
                location_code: leadingZeroes(id, appConstant.leadingZero),
                coordinates: `${lat},${lng}`,
                location_address: address
            })
            setLocation(data.user_location[0])
        }
    })


    const [getVendor, { loading, error, data, refetch, networkStatus }] = useLazyQuery(GET_VENDOR, {
        fetchPolicy: "network-only",
        onCompleted() {
            const userDetails = data.vendor_details[0]?.user
            setVendor(userDetails)

            form.setFieldsValue({
                ...data.vendor_details[0],
                ...userDetails,
                blocked: userDetails.status === 'blocked',
                location_code: leadingZeroes(userDetails.user_location.id, appConstant.leadingZero),
                location_id: userDetails.user_location.id,
                coordinates: userDetails.user_location.lat + ',' + userDetails.user_location.lng,
                location_address: userDetails.user_location.address,
                channels: userDetails.channel_ids,
                'collection_location.address_1': userDetails.collection_location?.address_1 ?? undefined
            })
            setImg(data.vendor_details[0]?.image)
            setOrigImg(data.vendor_details[0]?.image)
            setDisableEmail(userDetails.email && userDetails.email === "")
        }
    })

    const {data: options} = useQuery(GET_USER_CHANNELS);


    const [add] = useMutation(ADD_VENDOR, {
        onCompleted(data) {
            const { returning } = data.insert_vendor_details
            console.log('return', returning);
            const vid = leadingZeroes(returning[0].user_id, appConstant.leadingZero)
            setVendorId(vid)
            notification['success']({
                message: 'Saved!',
                description: `Vendor ${vid} has beend saved!`,
            });
            setFormLoading(false);
            setEditMode(true)
            setSelectedVendor({
                id: returning[0].user_id,
                vendor_id: vid,
                name: returning[0].user.name,
            })
            history.push(`/crm/vendors/edit/${returning[0].user_id}`, { prevPath: '/crm/vendors'})
            setDisabled(true)
        }
    });
    const [update] = useMutation(UPDATE_VENDOR, {
        onCompleted(data) {
            setVendor(data.update_users.returning[0])
            setFormLoading(false);
            notification['success']({
                message: 'Updated!',
                description: 'Vendor Updated!',
            });
            setDisabled(true)
        }
    });

    const [updateUser] = useMutation(UPDATE_USER_OBJECT, {
        onCompleted() { }
    })

    useEffect(() => {
        if (editMode) {
            getVendor({ variables: { where: { user: { id: { _eq: params.id } } } } })
            setVendorId(leadingZeroes(params.id, appConstant.leadingZero))
        } else {
            form.setFieldsValue({ partner_type: params.type })
            setVendor({})
        }
        return () => {
            setVendor({})
        }
    }, [])

    const submitHandler = async (values) => {
        setFormLoading(true);

        /** file  */
        let image = origImg
        if (!_.isUndefined(imageRef.current.files[0])) {
            const file = imageRef.current.files[0];
            let existingImageId = null;

            if(editMode && origImg){
              const parts = origImg.split('/')
              const lastPart = parts[parts.length - 1]
              existingImageId = lastPart.replace(/\.(png|jpg)$/, "")
            }
            const imageId = existingImageId ?? uuidv4()
      
            const postData = {
              filename: `${imageId}.png`,
              directory: 'user-profile'
            };
            
            await uploadImage(postData, file)
            image = `https://assets.catch-value.com/user-profile/${imageId}.png`
          }

        const vendor_details_object = {
            cash_flow_terms: values.cash_flow_terms,
            coordinates: values.coordinates,
            creditor_number: values.creditor_number,
            gst_include: values.gst_include,
            gst_registration: values.gst_registration,
            lead_time_calculation: values.lead_time_calculation,
            location_address: values.location_address,
            location_code: values.location_code,
            partner_type: values.partner_type,
            payment_code: values.payment_code,
            payment_terms: values.payment_terms,
            phone: values.phone,
            prefered_bank: values.prefered_bank,
            shipment_method_code: values.shipment_method_code,
            virtual_payment_address: values.virtual_payment_address,
            wallet_accountt_number: values.wallet_accountt_number,
            image
        }

        let tags = values.tags ? '{' + values.tags.join(',') + '}' : '{}'
        let channels = values.channels ? '{' + values.channels.join(',') + '}' : '{}'

        const user_object = {
            aadhaar_id: values.aadhaar_id,
            email: values.email,
            location_id: values.location_id,
            mobile: values.mobile,
            name: values.name,
            status: values.blocked ? 'blocked' : !editMode ? 'pending' : 'verified',
            username: values.username,
            tag_ids: tags,
            channel_ids: channels,
            seller_type_id: parseInt(values.seller_type_id),
            collection_location_id: values.collection_location_id
        }


        if (editMode) {
            let prevCh = vendor.channel_ids
            let selCh = values.channels
            let playerIdJson = {...vendor.channel_playerids};

            const channelDiff = _.xor(prevCh, selCh);

            if (channelDiff.length > 0){
                let channelsToDelete = _.intersection(channelDiff, prevCh);
                if(channelsToDelete.length > 0){
                    channelsToDelete.forEach(id =>{
                        const playerId = vendor.channel_playerids[id];
                        if (playerId !== undefined){
                            deletePlayer(playerId)
                            delete playerIdJson[id];
                        }
                    })
                    user_object.channel_playerids = playerIdJson
                }
                let channelsToAdd = _.intersection(channelDiff, selCh)
                if(channelsToAdd.length > 0){
                    const addPlayerIds = await addPlayer(values, params.id);
                    user_object.channel_playerids = addPlayerIds
                }
            }

            update({
                variables: {
                    user_id: params.id,
                    vendor_details_object,
                    user_object
                }
            })
        }
    
        if (!editMode) {
            const vendorDetails = await add({
                variables: {
                    objects: [{
                        ...vendor_details_object,
                        user: {
                            data: {
                                ...user_object,
                                onboard_code,
                                vessel: "",
                                users_type_id: 2
                            }
                        }
                    }]
                }
            })

            const vid = vendorDetails.data.insert_vendor_details.returning[0].user_id

            if (values.channels){
                const addPlayerIds = await addPlayer(values, vid);

                if (Object.keys(addPlayerIds).length > 0) {
                    update({
                        variables: {
                            user_id: vid,
                            vendor_details_object,
                            user_object: {
                                channel_playerids: addPlayerIds
                            }
                        }
                    })
                }
            }
        }
    }
    
    const deletePlayer = (playerid) => {
        const data = { data: { playerid} }   
        axios.delete(`${process.env.REACT_APP_API}/onesignal/delete-player-id`, { data: data }).then(res => {
            const { success } = res.data
            console.log(res.data);
        })  
    }

    const addPlayer = async (values, vid) => {
        let channel_playerids = {}

        const { Sms, Email } = onesignal.device_type
        const { subscribed } = onesignal.notification_types
        const { MOBILE_PUSH, WEB_PUSH, SMS, EMAIL} = communication.channel

        const getPrefix = () => onesignal.ext_user_id_prefix.find(group => group.id == 1).prefix

        for (let i = 0; i < values.channels.length; i++) {
            const cid = values.channels[i];
            const tagsObj = tagList?.reduce((acc, val) => {
                const key = val.toLowerCase().replace(/\s/g, '_');
                acc[key] = 1;
                return acc;
              }, {});

            const info = {
                external_user_id: getPrefix() + vid.toString(),
                language: "en",
                tags: {
                    ...tagsObj
                },
                notification_types: subscribed,
                lat: location.lat,
                long: location.lng,
                // country: values.country
            }
            if (cid === EMAIL){ 
                info.device_type = Email
                info.identifier = values.email
            }
            if (cid === SMS){
                info.device_type = Sms
                info.identifier = values.mobile
            }

            if (cid === EMAIL || cid === SMS ){
                await axios.post(`${process.env.REACT_APP_API}/onesignal/add-device`, { info }).then(res => {
                    const { success, id } = res.data
                    console.log(res.data);
                    if (success) {
                        channel_playerids[cid] = id
                    }
                })  
            }
        };

        return channel_playerids
    }

    const failedSubmitHandler = (values) => {
        setFormLoading(false);
        notification['error']({
            message: 'Something went wrong!',
            description: 'Please input values on required fields.',
        });
    }

    const rejectVendor = async () => {

        await updateUser({
            variables: {
                id: data.vendor_details[0].user.id,
                obj: { status: "rejected" }
            }
        })

        notification['success']({
            message: 'Success!',
            description: `Vendor has beend rejected!`,
        });

        history.goBack()

    }

    const headerEvents = (eventName) => {

        console.log('nag refetch gid man?')

        // switch (eventName) {
        //     case 'preapprove-reject':
        //         break;
        //     default:
        //         break;
        // }

        /** fetch vendor */
        refetch().then(res => setVendor(res.data.vendor_details[0]?.user))

    }

    const cancelHandler = () => {
        history.push(`/crm/vendors`)
    }

    const handleInputChange = async (event) => {

        const file = event.target.files[0];

        if (file && file.type.startsWith('image/')) {
            setImg(URL.createObjectURL(file))
        }
    }

    const handleEmailChange = (value) => {
        if (value === "") {
            setDisableEmail(true)
            return true
        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const format = regex.test(value);
        if (format){
            setDisableEmail(false)
        } else {
            setDisableEmail(true)
        } 
        return format
    }

    const checkUser = async (user) => {
        const res = await axios.post(`${process.env.REACT_APP_AUTH_ENDPOINT}/checkuser`, { user }) 
        const { exist } = res.data
        return exist
    }
      
    const checkboxOptions = options?.user_channels.map(channel => ({
        label: channel.label,
        value: channel.id,
        disabled: channel.label === "Email" ?  disableEmail : false     
    }));

    const setFieldValue = (key, value) => {
        if (!Array.isArray(key)) form.setFieldsValue({ ...form.getFieldsValue(), [key]: value })
        else form.setFieldsValue({
          ...form.getFieldsValue(),
          [key[0]]: {
            ...form.getFieldsValue()?.[key[0]],
            [key[1]]: value
          }
        })
    }

    return (
        <Container>

            <VendorCardHeader onEvent={headerEvents} editMode={editMode} setDisabled={(set) => setDisabled(set)} />

            <Form
                form={form}
                name='vendorForm'
                onFinish={submitHandler}
                onFinishFailed={failedSubmitHandler}
            >

                <FormSection title="General">
                    <FormDivision>
                        <Form.Item label="Vendor ID #" >
                            <Input readOnly placeholder='Auto generated' value={vendorId} disabled={disabled} />
                        </Form.Item>

                        <Form.Item 
                            label="Name" 
                            name="name"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input disabled={disabled} />
                        </Form.Item>

                        <Form.Item 
                            label="Seller Type" 
                            name="seller_type_id"
                            rules={[{ required: true, message: '' }]}
                        >
                            <SellerTypeDropdown disabled={disabled} />
                        </Form.Item>
                        
                        <Form.Item 
                            name="blocked" 
                            label="Blocked" 
                            valuePropName="checked" 
                        >
                            <Checkbox />
                        </Form.Item>


                        <Form.Item 
                            label="Collection Centre (Code)" 
                            name="collection_location_id"
                            rules={[{ required: true, message: '' }]}
                        >
                            <CollectionCentreDropdown disabled={disabled} setFieldValue={setFieldValue} />
                        </Form.Item>


                        <Form.Item 
                            label="Collection Centre Address" 
                            name="collection_location.address_1"
                        >
                            <Input disabled={disabled} />
                        </Form.Item>
                    </FormDivision>

                    <FormDivision>
                        <Form.Item label="Mobile #" name="mobile"
                            rules={[{ required: true, message: '' },
                            {
                                validator: async (_, value) => {
                                    const exists = await checkUser(value)
                                    if (exists && value !== vendor.mobile) {
                                        return Promise.reject(new Error('Mobile # already exists.'))
                                    }
                                    return Promise.resolve();
                                },
                            },
                            {
                                pattern: /^\+?[1-9]\d{1,14}$/,
                                message: 'Invalid Format',
                            }
                        ]}>
                            <PhoneValidator disabled={disabled}/>
                        </Form.Item>
                        
                        <Form.Item 
                            label="Aadhaar ID/GSTIN ID" 
                            name="aadhaar_id"
                            rules={[{ required: true, message: '' },
                            {
                                validator: async (_, value) => {
                                    const exists = await checkUser(value)
                                    if (exists && value !== vendor.aadhaar_id) {
                                        return Promise.reject(new Error('Aadhaar ID already exists.'))
                                    }
                                    return Promise.resolve();
                                }
                            }        
                        ]}
                        >
                            <Input disabled={disabled} />
                        </Form.Item>

                        <Form.Item 
                            label="Email" 
                            name="email"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        const valid = handleEmailChange(value)
                                        if (valid || value === undefined || value == null) {
                                            return Promise.resolve();
                                        }else{
                                            return Promise.reject(new Error('Invalid Format'));
                                        }
                                    }
                                },
                                ]}
                        >
                            <Input disabled={disabled}/>
                        </Form.Item>

                        <Form.Item 
                            label="Username" 
                            name="username" 
                            rules={[{ required: true, message: '' },
                                {
                                    validator: async (_, value) => {
                                        const exists = await checkUser(value)
                                        if (exists && value !== vendor.username) {
                                            return Promise.reject(new Error('Username already exists.'))
                                        }
                                        return Promise.resolve();
                                    }
                                }               
                            ]}
                        >
                            <Input disabled={disabled} />
                        </Form.Item>
                        
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: 24 }}>
                            <div style={{ marginRight: 52 }}>Add Picture or Logo</div>
                            <div style={{ display: "flex" }}>
                                {img && <img src={img} alt="" style={{ height: 70, width: 70, marginRight: 10 }} />}
                                <label htmlFor="upload-photo" style={{ display: "flex", backgroundColor: "#D9E3F3", alignItems: "center", justifyContent: "center" }}>
                                    <PlusOutlined style={{ fontSize: 70, color: "#90ABDD" }} size={100} color="red" />
                                </label>
                                <input type="file" ref={imageRef} onChange={handleInputChange} id="upload-photo" style={{ display: "none" }} disabled={disabled} />
                            </div>
                        </div>

                    </FormDivision>
                </FormSection>

                <FormSection title="Invoicing">
                    <FormDivision>
                        <Form.Item label="GST Registration #" name="gst_registration" ><Input disabled={disabled} /></Form.Item>
                    </FormDivision>
                    <FormDivision>
                        <Form.Item label="Prices Including GST" name="gst_include" valuePropName="checked">
                            <Switch disabled={disabled} />
                        </Form.Item>
                    </FormDivision>
                </FormSection>

                <FormSection title="Payments">
                    <FormDivision>
                        <Form.Item label="VPA" name="virtual_payment_address"
                            rules={[{ required: true, message: '' }]}
                        ><Input disabled={disabled} /></Form.Item>
                        <Form.Item label="Wallet Account No." name="wallet_accountt_number" ><Input readOnly placeholder='Auto Generated' disabled={disabled} /></Form.Item>
                        <Form.Item label="Payment Terms Code" name="payment_terms"
                            rules={[{ required: true, message: '' }]}
                        >
                            <CashFlowDropdown placeholder="Payment Terms Code" disabled={disabled} />
                        </Form.Item>
                        <Form.Item label="Payment Method Code" name="payment_code"
                            rules={[{ required: true, message: '' }]}
                        >
                            <PaymentCodeDropdown placeholder="Payment Method Code" disabled={disabled} />
                        </Form.Item>
                    </FormDivision>
                    <FormDivision>
                        <Form.Item label="Preferred Bank Account" name="prefered_bank"><Input disabled={disabled} /></Form.Item>
                        <Form.Item label="Partner Type" name="partner_type"
                            rules={[{ required: true, message: '' }]}
                        >
                            <PartnerTypeDropdown disabled={!editMode || disabled} />
                        </Form.Item>
                        <Form.Item label="Cashflow Payment Terms" name="cash_flow_terms" >
                            <CashFlowDropdown placeholder="Select Cashflow Payment Terms" disabled={disabled} />
                        </Form.Item>
                        <Form.Item label="Creditor No." name="creditor_number" ><Input disabled={disabled} /></Form.Item>
                    </FormDivision>
                </FormSection>

                <FormSection title="Receiving">
                    <FormDivision>
                        <Form.Item label="Vendor Location" name="location_id"
                            rules={[{ required: true, message: '' }]}
                        >
                            <LocationDropdown onChange={(lid) => { getLocation({ variables: { where: { id: { _eq: lid } } } }) }} disabled={disabled} />
                        </Form.Item>
                        <Form.Item label="Shipment Method Code" name="shipment_method_code"
                            rules={[{ required: true, message: '' }]}
                        >
                            <ShipmentCodeDropdown placeholder="Shipment Method Code" disabled={disabled} />
                        </Form.Item>
                        <Form.Item label="Lead Time Calculation" name="lead_time_calculation" ><Input disabled={disabled} /></Form.Item>
                    </FormDivision>
                    <FormDivision>
                        <Form.Item label="Location Code" name="location_code" ><Input readOnly disabled={disabled} /></Form.Item>

                        <Form.Item label="Coordinates" name="coordinates" ><Input readOnly disabled={disabled} /></Form.Item>
                        <Form.Item label="Address" name="location_address" ><Input readOnly disabled={disabled} /></Form.Item>
                    </FormDivision>
                </FormSection>

                <FormSection title="Communication Channels">
                    <Form.Item name="channels">
                        <Checkbox.Group options={checkboxOptions} style={{borderColor: 'black', backgroundColor: 'white'}} disabled={disabled}/>
                     </Form.Item>
                </FormSection>

                <FormSection title="Tags">
                    <Form.Item name="tags">
                        <TagAdder form={form} name="tags" userTags={vendor.tag_ids} disabled={disabled} setTagList={setTagList}/>
                    </Form.Item>
                </FormSection>

                <FormSection title="Activity Timeline">
                    <ActivityTimelineTable/>
                </FormSection>

                <CommonDiv direction="row" justify="flex-end"  >
                    <ButtonLoader loading={formLoading} htmlType='submit' >
                        {editMode ? 'Update' : 'Save'}
                    </ButtonLoader>
                    <CommonButton className='reverse' onClick={cancelHandler} >Cancel</CommonButton>
                </CommonDiv>

            </Form>


        </Container>
    )
}

NewVendorPage.propTypes = {}

export default NewVendorPage
