
import styled from "styled-components";
import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'

const Container = styled.div`
margin: 15px 0px;
    .ant-divider-horizontal{
        margin: 0 0 20px 0;
        border-top: 3px solid #ccc;
    }
`;

function DashTitleDivider({ title }) {
    return (
        <Container className='dash-divider'>
            <h2>{title}</h2>
            <Divider />
        </Container>
    )
}

DashTitleDivider.propTypes = {
    title: PropTypes.string.isRequired
}

export default DashTitleDivider
