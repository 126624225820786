import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AdminRolesHeader, PermissionGroup, UsersDataTable, UsersTableFilter } from './components'

const Container = styled.div``
const TablePermissionContainer = styled.div`
    display: flex;
    position: relative;
`

export const UsersContext = createContext()

function Users(props) {

    const [selectedRecord, setSelectedRecord] = useState({})
    const [permissionView, setPermissionView] = useState(false)
    const { admin_user_groups, admin_user_roles } = selectedRecord

    return (
        <UsersContext.Provider value={{
            selectedRecord, setSelectedRecord,
            permissionView, setPermissionView
        }}>
            <Container>
                <AdminRolesHeader title='Users' />
                <UsersTableFilter />
                <TablePermissionContainer>
                    <UsersDataTable />
                    {permissionView && <PermissionGroup
                        sets={admin_user_roles.map(p => (p.user_role.display_name))}
                        // members={[
                        //     { username: "username", department: "department" }
                        // ]}
                        members={admin_user_groups.map(pg => (pg.user_group.code))}
                    />}
                </TablePermissionContainer>
            </Container>
        </UsersContext.Provider>
    )
}

Users.propTypes = {}

export default Users
