import React, { useState } from 'react'
import styled from 'styled-components'
import { SUB_SPECIES_PROCUREMENT } from "Util/subscriptions";
import { useSubscription } from "@apollo/client";
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { Level2Menu } from 'Components/Menus'
import { auctionMenu } from '../constants'
import { DataTable } from 'Pages/Procurement/ActiveSpecies/components';
const Container = styled.div``

function Species() {
    const [data, setData] = useState([])

    const { loading } = useSubscription(SUB_SPECIES_PROCUREMENT, {
        variables: { where: { status: { _eq: true } } },
        onSubscriptionData({ subscriptionData: { data } }) {
            data && data.species && setData(data.species)
        }
    });

    return (
        <Container>
            <SubPageBreadCrumbs />
            <Level2Menu menu={auctionMenu} />
            <DataTable
                data={data}
                loading={loading}
                module="direct-sales"
                pageSize={50}
                height={500}
            />

            {/* <TableFilters />
                {['open'].includes(filter.status) && <DataTable />}
                {['accepted'].includes(filter.status) && <AcceptedDataTable />}
                {['withdrawn', 'staged-widthdrawn'].includes(filter.status) && <WithdrawnDirectSales />} */}

        </Container>
    )
}

export default Species