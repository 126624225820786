import { Select } from 'antd';
import React from 'react';
import { saleType } from 'Util/constants';
const { Option } = Select;

function SaleTypeSelect({ onChange, ...rest }) {

    return (
        <div>
            <Select
                {...rest}
                placeholder="Select a type"
                onChange={onChange}
            >
                {saleType.map((val, idx) => <Option value={val} key={`saletype-opt${idx}`}>{val}</Option>)}
            </Select>
        </div>
    )
}

export default SaleTypeSelect
