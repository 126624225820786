import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Table, Typography, notification } from 'antd';
import leadingZeroes from 'leading-zeroes';
import { appConstant } from 'Util/constants';
import { useMutation, useSubscription } from '@apollo/client';
import { SUB_ADMIN_USERS } from 'Util/subscriptions';

function AdminUserList({ onEdit }) {

    const { data, loading } = useSubscription(SUB_ADMIN_USERS);

    const edit = (record) => {
        onEdit(record)
    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            editable: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true,
        },
        {
            title: 'Full Name',
            dataIndex: 'full_name',
            editable: true,
        },
        {
            title: 'User Role',
            dataIndex: 'user_role_name',
            editable: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            editable: true,
            render: (_, { status }) => (status ? 'Active' : 'Inactive')
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <Typography.Link onClick={() => edit(record)}>
                    Edit
                </Typography.Link>
            ),
        },
    ];


    const dataSource = () => {

        if (data) {

            return data.admin_user.map((v, i) => (
                {
                    key: `key-${i}-admin-user-list`,
                    id: v.id,
                    username: v.username,
                    email: v.email,
                    full_name: v.full_name,
                    status: v.status,
                    user_role_name: v.admin_user_role.name,
                    user_role: v.admin_user_role.id
                }
            ))
        }

        return []

    }

    return (
        <div className='admin-user-list'>
            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    )
}

AdminUserList.propTypes = {

}

export default AdminUserList