const { atom } = require("recoil");

export const listOrderAtom = atom({
	key: "listOrder",
	default: "grid",
});

export const infoIconAtom = atom({
	key: "infoIcon",
	default: {
        species: null,
        products: null,
		vendors: null
    }
});

export const selectedProduct = atom({
	key: "selectedProduct",
	default: {},
});

export const selectedSpecies = atom({
	key: "selectedSpecies",
	default: {},
});

export const speciesSearchAtom = atom({
	key: "speciesSearch",
	default: ""
});