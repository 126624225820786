import React from 'react'
import PropTypes from 'prop-types'
import ButtonCard from 'Components/ButtonCard'
import { useLocation, useParams } from 'react-router-dom'

const data = [
    { link: '/logistics/drivers', title: 'Master' },
    { link: '/logistics/drivers/verified', title: 'Verified Drivers' },
    { link: '/logistics/drivers/pending', title: 'Pending Approval' },
    { link: '/logistics/drivers/rejected', title: 'Rejected Drivers' },
    { link: '/logistics/drivers/blocked', title: 'Blocked Drivers' }
]

function MenuButton(props) {
    const location = useLocation();
    return (
        <div style={{ display: 'flex' }} >
            {data.map(v => (<ButtonCard
                key={v.link}
                link={v.link}
                title={v.title}
                lightBlue
                selected={location.pathname === v.link}
            />))}
        </div>
    )
}

MenuButton.propTypes = {}

export default MenuButton
