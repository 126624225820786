import { useMutation, useSubscription } from '@apollo/client';
import { Table } from 'antd';
import Duration from 'Components/Duration';
import Status from 'Components/Status';
import _ from 'lodash';
import React from 'react';
import { cleanTitle } from 'Util/common';
import { UPDATE_AUCTION_STATUS_BY_ID } from 'Util/mutations';
import { SUB_AUCTIONS } from 'Util/subscriptions';
import moment from 'moment'
function LiveAuctionList({ }) {

    const { data, loading, error } = useSubscription(SUB_AUCTIONS);
    const [updateAuctionStatus] = useMutation(UPDATE_AUCTION_STATUS_BY_ID)

    const updateStatus = (id) => {
        console.log(`Update auction ID: ${id}`)
        updateAuctionStatus({ variables: { id, status: 'inactive' } })
    }

    const columns = ["#", "product", "specifications", "volume", "price", "duration", "status"].map((v, i) => {

        if (v === 'specifications') {
            return {
                title: cleanTitle(v),
                dataIndex: v,
                key: v,
                render: (v) => {
                    const list = JSON.parse(JSON.parse(v));
                    if (!_.isNull(list)) {
                        return <ul>{list.map(v => <li key={`bid-spec-list${Math.random()}`} >{v}</li>)}</ul>
                    }
                    return '';
                },
                width: '15%'
            }
        }

        if (v === 'status') {
            return {
                title: cleanTitle(v),
                dataIndex: v,
                key: v,
                render: v => (<Status value={v} />),
                sorter: true,
                sorter: (a, b) => (a === 'active' ? 1 : -1),
                sortDirections: ['descend', 'ascend'],
                width: '5%'
            }
        }

        if (v === 'duration') {
            return {
                title: cleanTitle(v),
                dataIndex: v,
                key: v,
                render: v => (<span>{v.value} mins</span>),
                width: '6%'
            }
        }

        return {
            title: cleanTitle(v),
            dataIndex: v,
            key: v,
            width: (v === 'product') ? '10%' : '5%'
        }

    })

    const dataSource = () => {

        if (data) {

            return data.auctions.map((v, i) => (
                {
                    key: `key-${i}-auction-list`,
                    product: v.product.species.label,
                    specifications: JSON.stringify(v.bid_specs),
                    volume: v.volume,
                    price: v.price,
                    duration: { id: v.id, value: v.duration, createTime: v.created_at, status: v.status },
                    status: v.status
                }
            ))
        }

        return []

    }

    return (
        <div className="auction-table-wrapper">
            <Table
                loading={loading}
                bordered
                className="auction-table"
                pagination={{ pageSize: 100 }}
                columns={columns}
                dataSource={dataSource()}
            />
        </div>
    )
}

LiveAuctionList.propTypes = {

}

export default LiveAuctionList

