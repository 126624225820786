import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { Checkbox, Table } from 'antd';
import { THEME } from 'Util/constants';
import { useSubscription } from '@apollo/client';
import { USER_PROFILE_ROLES } from 'Util/subscriptions';
import { ProfileRolesContext } from '../ProfileRoles';

const Container = styled.div``

const columns = [
    {
        title: 'Profile ID',
        dataIndex: 'profile_id',
        key: 'profile_id',
        render: (text) => <a style={{ color: THEME.colors.primary }} >{text}</a>,
    },
    {
        title: 'Display Name',
        dataIndex: 'display_name',
        key: 'display_name',
    },
    {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
    },
    {
        title: 'Role ID',
        dataIndex: 'role_id',
        key: 'role_id',
    },
    {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (status) => <Checkbox checked={status} />
    },
    {
        title: 'Use as default profile',
        dataIndex: 'is_default_profile',
        key: 'is_default_profile',
        render: (status) => <Checkbox checked={status} />
    },
];

function ProfileRolesDataTable(props) {

    const { setSelectedRecord } = useContext(ProfileRolesContext)
    const [data, setData] = useState([])
    const [selected, setSelected] = useState([]);

    const { loading } = useSubscription(USER_PROFILE_ROLES, {
        onSubscriptionData({ subscriptionData: { data } }) {
            data.user_roles && setData(data.user_roles.map(prof => ({ ...prof, key: `user-profile-item-${prof.id}` })))
        }
    });

    const onSelect = (object) => {
        setSelectedRecord(object)
        setSelected(object)
    };

    return (
        <Container>
            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                rowSelection={{ type: "radio", onSelect }} />
        </Container>
    )
}

ProfileRolesDataTable.propTypes = {}

export default ProfileRolesDataTable
