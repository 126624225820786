import React, { useState, useContext } from 'react'
import { useSubscription } from '@apollo/client';
import CommonDiv from 'Components/CommonDiv'
import styled from 'styled-components'
import { THEME } from 'Util/constants'
import { USER_PROFILE_ROLES } from 'Util/subscriptions';
import { notification, Select } from 'antd';
import _ from 'lodash'
import { FaPlusCircle } from 'react-icons/fa';
import { UsersCardContext } from '../../UsersCard';

const Container = styled.div``

function PermissionSelect() {

    const { userData, setUserData } = useContext(UsersCardContext)

    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)
    const { loading } = useSubscription(USER_PROFILE_ROLES, {
        onSubscriptionData({ subscriptionData: { data } }) {
            data.user_roles && setData(data.user_roles.map(ur => ({
                id: ur.id,
                key: `user-roles-data-${ur.id}`,
                display_name: ur.display_name,
                description: ur.description,
                profile_id: ur.profile_id,
                permission_scope: "N/A",
                data: ur
            })))
        }
    });

    const selectHandler = (id) => {
        setSelected(_.find(data, { id }))
    }

    const addHandler = () => {
        if (selected) {
            const { admin_user_roles } = userData

            console.log(admin_user_roles, selected)

            if (!admin_user_roles.map(aug => (aug.user_role.id)).includes(selected.id)) {
                setUserData({
                    ...userData, admin_user_roles: [
                        ...admin_user_roles,
                        { user_role: selected }
                    ]
                })
            } else {
                notification.error({
                    message: 'User permission already been selected.'
                })

            }

        }
    }

    return (
        <Container>
            <CommonDiv direction="row" align="center" >
                <Select style={{ width: '100%', marginRight: 15 }} onSelect={selectHandler} >
                    {
                        data.map(ug =>
                            <Select.Option key={ug.key} value={ug.id} >
                                <CommonDiv direction="row" align="center" >
                                    <label style={{ minWidth: 300, color: `${THEME.colors.primary}` }} >{ug.display_name}</label>
                                    <label style={{ minWidth: 300 }} >{ug.profile_id}</label>
                                    <label>{ug.description}</label>
                                </CommonDiv>
                            </Select.Option>
                        )
                    }
                </Select>
                <FaPlusCircle onClick={addHandler} cursor="pointer" size={25} color={THEME.colors.primary} />
            </CommonDiv>

        </Container>
    )
}

export default PermissionSelect