import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

const { Option } = Select;

const Container = styled.div`
  label{
      font-weight: bold;
  }  
`;

function VendorDropDown(props) {

  const history = useHistory()
  const params = useParams()

  const handleChange = (value) => {
    console.log('goto', value)

    if (value === "") {
      history.push('/procurement/product-list')
      return
    }

    history.push(`/procurement/product-list/${value}`)

  }

  return (
    <Container>
      <label>Product: </label>
      <Select value={params.type ?? ""} defaultValue="" style={{ width: 100 }} bordered={false} onChange={handleChange}>
        <Option value="">All</Option>
        <Option value="active">Active</Option>
        <Option value="pending">Pending</Option>
        <Option value="quotas">Quotas</Option>
      </Select>
    </Container>
  )
}

VendorDropDown.propTypes = {}

export default VendorDropDown
