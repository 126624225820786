import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AdminRolesHeader, ProfileRolesDataTable, ProfileRolesTableFilter } from './components'
import { Button, Form, Input, Switch, notification } from 'antd'
import CommonDiv from 'Components/CommonDiv'
import { ButtonLoader } from 'Components/CommonButton'
import { FormDivision, FormSection } from 'Components/Form'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ADD_USER_ROLE, UPDATE_USER_ROLE } from 'Util/mutations'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { GET_USER_ROLE } from 'Util/queries'

const Container = styled.div`
    h1.profile-name{
        border-top: solid 1px #ccc;
        border-bottom: solid 1px #ccc;
        margin-top: 15px;
        /* padding-left: 40px; */
    }
`

function ProfileRolesCard(props) {

    const history = useHistory()
    const params = useParams()

    const [form] = Form.useForm()
    const [formLoading, setFormLoading] = useState(false)

    const editMode = !_.isUndefined(params.id)

    const [getUserRole] = useLazyQuery(GET_USER_ROLE, {
        onCompleted(data) {
            data.user_roles && form.setFieldsValue(data.user_roles[0])
        }
    })

    const [add] = useMutation(ADD_USER_ROLE, {
        onCompleted(data) {
            notification['success']({
                message: 'Saved!',
                description: `User Role has beend saved!`,
            });
            setFormLoading(false);
            setTimeout(() => { history.goBack() }, 2000)
        }
    })

    const [update] = useMutation(UPDATE_USER_ROLE, {
        onCompleted() {
            setFormLoading(false);
            notification['success']({
                message: 'Updated!',
                description: 'User Roles Updated!',
            });
        }
    });

    const failedSubmitHandler = (values) => {
        setFormLoading(false);
        notification['error']({
            message: 'Something went wrong!',
            description: 'Please input values on required fields.',
        });
    }

    const submitHandler = (values) => {
        setFormLoading(true);

        !editMode && add({ variables: { objects: { ...values } } })

        editMode && update({ variables: { where: { id: { _eq: params.id } }, _set: { ...values } } })

    }

    useEffect(() => {
        editMode && getUserRole({ variables: { where: { id: { _eq: params.id } } } })
    }, [])

    return (
        <Container>
            <AdminRolesHeader title='Profile Roles Card' />
            <h1 className='profile-name' >Accountant</h1>

            <Form
                form={form}
                name='useRolesForm'
                onFinish={submitHandler}
                onFinishFailed={failedSubmitHandler}
            >

                <FormSection title='General'>
                    <FormDivision>
                        <Form.Item label="Profile ID" name="profile_id"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="Source" name="source">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Display Name" name="display_name"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Description" name="description">
                            <Input.TextArea />
                        </Form.Item>
                    </FormDivision>

                    <FormDivision>
                        <Form.Item label="Role ID" name="role_id"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item initialValue={false} label="Enabled" name="enabled" valuePropName="checked">
                            <Switch />
                        </Form.Item>

                    </FormDivision>

                </FormSection>

                <FormSection title='Additional Settings'>

                    <FormDivision>

                        <Form.Item initialValue={false} label="Use as default profile" name="is_default_profile" valuePropName="checked">
                            <Switch />
                        </Form.Item>

                    </FormDivision>

                </FormSection>

                <CommonDiv direction="row" justify="flex-end" >
                    <ButtonLoader loading={formLoading} htmlType='submit' >
                        {editMode ? 'Update' : 'Save'}
                    </ButtonLoader>
                </CommonDiv>

            </Form>

        </Container>
    )
}

ProfileRolesCard.propTypes = {}

export default ProfileRolesCard
