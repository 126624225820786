import React from 'react'
import PropTypes from 'prop-types'
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';


const data = {
    labels: [
        'Registered',
        'Approved',
        'Bid',
        'Transact'
    ],
    datasets: [{
        label: 'Users',
        data: [90, 50, 100, 47],
        backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(255, 80, 200)',
        ],
        hoverOffset: 4
    }]
}

const BarChart = props => {
    return (
        <div style={{ width: 400 }}>
            <Bar data={data} />
        </div>
    )
}

BarChart.propTypes = {}

export default BarChart