import { useMutation, useQuery } from '@apollo/client'
import { Form, Input, notification } from 'antd'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import CommonButton from 'Components/CommonButton'
import { Level2Menu } from 'Components/Menus'
import _ from 'lodash'
import { useState } from 'react'
import styled from 'styled-components'
import { UPDATE_SETTINGS } from 'Util/mutations'
import { GET_SETTINGS } from 'Util/queries'
import { auctionMenu } from '../constants'

const Container = styled.div``
const ButtonContainer = styled.div`
    margin-top: 50px;
`
const FormContainer = styled.div`
    margin-top: 30px;
    .ant-form-item-label>label {
        text-transform: capitalize;
        font-size: 18px;
        font-weight: bold;
    }
    .form-input-items {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        
        .ant-form-item-control-input-content{
            width : 400px
        }
    }
`

function DirectSalesSettings() {

    const [filter, setFilter] = useState('live')

    /** GraphQL Hooks */
    const { data, loading } = useQuery(GET_SETTINGS, {
        variables: {
            where: { key: { _eq: "direct_sales" } }
        }
    });

    const [updateSettings] = useMutation(UPDATE_SETTINGS, {
        onCompleted(data) {
            notification['success']({
                message: 'Saved!',
                description:
                    'Settings has been saved',
            });
        },
        onError(e) {
            console.log('erre', e)
        }
    })

    const handleSubmit = (values) => {

        _.forOwn(values, function (value, key) {
            if (!_.isArray(value)) {
                const arr = value.split('\n')
                values[key] = _.without(arr.map(v => v.trim()), '')
            }
        });

        updateSettings({
            variables: {
                key: "direct_sales",
                values
            }
        })

    }

    if (loading) return <></>

    const initVal = () => {
        let { values } = data.settings[0]
        let final = {}

        _.forOwn(values, function (value, key) {
            let str = ''
            value.forEach((v, i) => { str = str + `${v}\n` })
            final = Object.assign(final, { [key]: str });
        });

        return final
    }

    return (

        <Container>
            <SubPageBreadCrumbs />
            <Level2Menu menu={auctionMenu} />

            <label style={{ textAlign: "center", color: 'gray', fontSize: 16, marginTop: 15, display: "block" }} >* Note: Separate each category per line.</label>

            <FormContainer>

                <Form
                    onFinish={handleSubmit}
                    className="form-settings"
                    wrapperCol={{ span: 15 }}
                    labelCol={{ span: 9 }}
                    initialValues={initVal()}
                    layout={"vertical"}

                >

                    <div className='form-input-items' >
                        {
                            Object.keys(data.settings[0].values).map(v => (
                                <Form.Item
                                    key={v}
                                    label={v.replace("_", " ")}
                                    name={v}
                                >
                                    <Input.TextArea rows={10} />
                                </Form.Item>
                            ))
                        }
                    </div>
                    <label style={{ textAlign: "center", color: 'gray', fontSize: 16, marginTop: 15, display: "block" }} >* Note: Separate each category per line.</label>

                    <ButtonContainer>
                        <CommonButton type="primary" htmlType="submit">
                            Save changes
                        </CommonButton>
                    </ButtonContainer>

                </Form>

            </FormContainer>


        </Container>

    )
}

export default DirectSalesSettings