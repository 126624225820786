import React, { useState} from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import styled, { css } from "styled-components";
import TableFilter from "./TableFilter";
import _ from "lodash";
import { useSubscription } from '@apollo/client'
import { SUB_USER_TAGS, SUB_USERS } from 'Util/subscriptions'
import KebabMenu from './KebabMenu'

const Container = styled.div``

const columns = [
    {
        title: 'Tag',
        dataIndex: 'label',
        key: 'label'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: 'Total Users',
        dataIndex: 'total_users',
        key: 'total_users',
        width: 120
    },
    {
        render: (row) => <KebabMenu data={row} />
    }
];

function DataTable() {
    const [search, setSearch ] = useState()
    const [data, setData] = useState([])
    const [users, setUsers] = useState([])

    const { loading } = useSubscription(SUB_USER_TAGS, {
        onSubscriptionData({ subscriptionData: { data } }) {     
            data && setData(data.user_tags)
        }
    });

    const {error } = useSubscription(SUB_USERS, { variables: { status: "verified" } ,     
        onSubscriptionData({ subscriptionData: { data } }) {     
                data && setUsers(data.users)
            }
        }
     );

    const countUsers = () => {
        let tagCounts = {};

        for(let i = 0; i < users.length; i++){
            for(let j = 0; j < users[i].tag_ids.length; j++){
                let tag = users[i].tag_ids[j];
                if(tagCounts[tag]){
                    tagCounts[tag]++;
                } else {
                    tagCounts[tag] = 1;
                }
            }
        }
        data.forEach((item) => {
            item.total_users = tagCounts[item.id] || 0;
          });
     }

    const searchable = data.map((obj, i) => {
        countUsers()
        let result = [];
        let concatenatedValues = "";
        columns.forEach(column => {
          if (obj[column.key] && obj[column.key] !== undefined) concatenatedValues += obj[column.dataIndex] + " ";
        });
        result.push(concatenatedValues.trim());
    
        return {
            key: i,
          ...obj,
          searchText:  result.toString()
        }
      })
    
    const filterData = (data) => {
        if(!search){
            return data
        }
        return _.filter(searchable, function (d) { 
            let query = search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    };
    
    return (
        <Container>
            <TableFilter data={filterData(data)} column={columns} setSearch={setSearch}/>
            <Table
                loading={loading}
                columns={columns}
                dataSource={filterData(data)}
                pagination={{ defaultPageSize: 50 }}
                scroll={{ y: 500, x: 1500 }}
                rowKey={rec => parseInt(rec.id, 10)}
            />
        </Container>
    )

}

DataTable.propTypes = {
};

export default DataTable;

