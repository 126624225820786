import React from 'react'
import { Spin } from 'antd';

import PropTypes from 'prop-types'

function CommonLoader(props) {
    return (
        <div className="common-loader">
            <Spin />
        </div>
    )
}

CommonLoader.propTypes = {}

export default CommonLoader
