import { useMutation, useSubscription } from '@apollo/client';
import { Button, Form, Input, notification, Table, Typography } from 'antd';
import CommonDrawer from 'Components/CommonDrawer';
import _ from 'lodash';
import React, { useState } from 'react';
import { ADD_LOCATION, UPDATE_LOCATION, UPDATE_USER } from 'Util/mutations';
import { SUB_LOCATIONS } from 'Util/subscriptions';
import './style.scss';

function CMSLocations() {
    const [form] = Form.useForm();
    const { data, loading, error } = useSubscription(SUB_LOCATIONS);
    const [editId, setEditId] = useState(null);
    const [drawer, setDrawer] = useState(false);
    const [add] = useMutation(ADD_LOCATION, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Location has been added',
            });
            setDrawer(false);
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const [update] = useMutation(UPDATE_LOCATION, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description: 'Location has been updated!',
            });
            setDrawer(false);
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const drawerClose = () => {
        setDrawer(false);
        setEditId(null);
    }

    const edit = (record) => {
        setEditId(record.id)
        form.setFieldsValue({
            ...record,
        });
        setDrawer(true);
    };

    const cancel = () => {
        console.log('cancel editing')
    };

    const onFormSubmit = () => {
        let object = form.getFieldsValue();

        _.isNull(editId) ? add({ variables: { object } }) : update({ variables: { id: editId, object } })

    }

    const columns = [
        {
            title: 'Location Name',
            dataIndex: 'label',
            editable: true,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            editable: true,
        },
        {
            title: 'Coordinates',
            dataIndex: 'coordinates',
            render: (_, rec) => (
                <a target="_blank" href={`https://www.google.com/maps/?q=${rec.lat},${rec.lng}`}>
                    <b>Lat:</b> {rec.lat} <br />
                    <b>Lng:</b> {rec.lng}
                </a>
            )
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            editable: true,
        },
        {
            title: 'Actions',
            width: '120px',
            dataIndex: 'action',
            render: (_, record) => (
                <Typography.Link onClick={() => edit(record)}>
                    Edit
                </Typography.Link>
            ),
        },
    ];

    const dataSource = () => {

        if (data) {
            return data.user_location.map((v, i) => (
                {
                    key: `key-${i}-loc-list`,
                    id: v.id,
                    label: v.label,
                    lat: v.lat,
                    lng: v.lng,
                    coordinates: { lat: v.lat, lng: v.lng },
                    address: v.address,
                    notes: v.notes

                }
            ))
        }

        return []

    }

    /** Validation Rules */
    const rule = [
        {
            required: true,
            message: "Field is required"
        },
    ]

    return (
        <div className="locations-page" >
            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { setDrawer(true); }} >New Location</Button>
            </div>

            <CommonDrawer
                open={drawer}
                form={form}
                title={editId ? "Edit location" : "Add new location"}
                onClose={drawerClose}
            >

                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                    component={false}
                >

                    <Form.Item
                        rules={rule}
                        label="Location Name" name="label">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Address" name="address">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Latitude" name="lat">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Longitude" name="lng">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        label="Notes" name="notes">
                        <Input.TextArea placeholder="" rows={4} style={{ width: 300 }} />
                    </Form.Item>

                </Form>

            </CommonDrawer>

            <Table
                loading={loading}
                bordered
                dataSource={dataSource()}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 100
                }}
            />
        </div>
    );
}

export default CMSLocations
