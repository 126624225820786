import moment from 'moment'
import { appConstant, approvalTypes } from './constants';
import slugify from 'slugify';
// import CryptoJS from 'crypto-js';


export const baseUrl = process.env.PUBLIC_URL || window.location.origin
export const getTimeFromDate = (date, ist = false) => {

    if (ist) {
        return moment(date).tz('Asia/Kolkata').format(appConstant.timeFormat2)
    }

    return moment(date).format(appConstant.timeFormat2)
}
export const getDate = (date) => (moment(date).format(appConstant.dateFormat))
export const cleanTitle = title => <span style={{ textTransform: 'capitalize' }} >{title.replace(/_/g, ' ')}</span>
// export const isDurationActive = (created, duration) => {}

/**
 * Make dropdown item object
 * @param {*} val string
 * @returns object { value: string, label: string }
 */
export const makeDropDownItem = val => ({ label: val, value: slugify(val, { lower: true }) })

export const auctionStatusText = (status) => {
    if (['auction', 'inprogress'].includes(status)) return 'In Progress'
    if (['prebid'].includes(status)) return 'Pre-Bid'
    if (['results'].includes(status)) return 'Pending Results'
    return ''
}

export const momentFormats = {
    po_date: 'DD MMM YYYY',
    po_time: 'hhmm',
    time: 'hh:mm:ss a',
    date: 'YYYY/MM/DD',
    dateTime: 'YYYY/MM/DD h:mm:ss a'
}

export const config = {
    timezone: "Asia/Kolkata"
}


export const getApprovalWorkFlow = (id) => {
    const app = approvalTypes.find((obj) => (obj.id === id));
    return app.label;
}

// export function createSignature(params) {
//     let signature = CryptoJS.HmacSHA1(params, '_uYUMQFdiKSjl8338uu8_fj3-II').toString();
//     return signature;
// }
