import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Checkbox } from 'antd';
import { GET_ADMIN_MODULE } from 'Util/queries';
import _ from 'lodash'


function ModuleCheckbox({ onChange, value, ...rest }) {

    const defaultV = value ? value.map(v => v.id) : []


    const { data } = useQuery(GET_ADMIN_MODULE);

    const dataSource = () => {
        if (!data) { return [] }
        return data.admin_modules.map(v => ({ label: v.name, value: v.id }))
    }

    const onChanges = (object) => {
        const vals = object.map(id => (_.find(data.admin_modules, { id })))
        onChange(vals)
    }

    return (
        <Checkbox.Group options={dataSource()} defaultValue={defaultV} onChange={onChanges} />
    )
}

ModuleCheckbox.propTypes = {}

export default ModuleCheckbox

