import React, { useContext } from 'react'
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, notification, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { UPSERT_DRIVERS } from 'Util/mutations'
import moment from 'moment'
import { LogisticsJobsContext } from '../..';
import { deleteDriver } from 'Util/trackpod'

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;


function Delete(props) {
    const { selectedRecord, setSelectedRecord } = useContext(LogisticsJobsContext)

    const [upsert] = useMutation(UPSERT_DRIVERS, {
        onCompleted(){
            notification["success"]({
                message: 'Deleted',
                description: "Driver has been deleted"
            });
        }
    })
  
    const confirmDelete = async () => {    
        const body = {
            "ID": selectedRecord.uuid
        }

        try {
            const res = await deleteDriver(body) 
            console.log('res', res);
            const { Detail, Status, Title } = res
            console.log({ Detail, Status, Title});
    
            if(Status !== 200){
              notification["error"]({
                message: Title,
                description: Detail,
              });
              return
            }
          } catch (error) {
            console.log('errorerrorerror', error);
            if (error.response) {
              console.error('Error status code:', error.response.status);
              if (error.response.status === 405) {
                  console.error('Error 405: Method Not Allowed');
              } else if (error.response.status === 500) {
                  console.error('Error 500: Internal Server Error');
              }
          } else {
              console.error('Error occurred:', error);
          }
            notification["error"]({
              message: "Something went wrong!",
              description: "Please try again later.",
            });
            return
          }
    
        const objects = {
            id: selectedRecord.id,
            name: selectedRecord.name,
            deleted_at: moment()
        };
      
        await upsert({ variables: { 
                update_columns: ["id", ...Object.keys(objects)],
                objects: [objects] 
            } 
        })
        setSelectedRecord({})
    }

    const confirm = () => {
        selectedRecord.id && Modal.confirm({
            centered: true,
            icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
            content: `Delete Driver ${selectedRecord.name}?`,
            autoFocusButton: 'ok',
            okButtonProps: {
                style: { backgroundColor: THEME.colors.primary }
            },
            onOk: confirmDelete
        });
    };

    if (!selectedRecord) return <DeleteFilled />

    return (
        <Container>
            <Tooltip title={selectedRecord.name}>
                <DeleteFilled onClick={confirm} />
                <label>Delete</label>
            </Tooltip>
        </Container>
    )
}

Delete.propTypes = {}

export default Delete