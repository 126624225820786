
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { SUB_PROCESS_CATEGORY, SUB_PRODUCT_SIZING, SUB_PRODUCT_SUB_CATEGORY, SUB_UNIT_OF_MEASUREMENT } from 'Util/subscriptions';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 33.33%;
            text-align: left;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function ChooseProcessCat(props) {
    const history = useHistory()
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState('company')

    const {data,loading} = useSubscription(SUB_PROCESS_CATEGORY)

    const {state, setState, setFieldValue} = props

    const okHandler = () => {
        setState({...state, showProcessCat: false});
        setFieldValue('process_category', selected.description, selected.code)
    }


    return (
        <Container>
            <Modal
                visible={state.showProcessCat}
                centered
                onCancel={() => setState({...state, showProcessCat: false})}
                onOk={okHandler}
                title={
                <div style={{flexDirection: "row", display: "flex"}}>
                    <span style={{marginRight: 50}}>Select - Process Cat</span>
                    <div><PlusOutlined color='#C6C8CD'/>New <EditOutlined style={{marginLeft: 20}}/>Edit <DeleteOutlined style={{marginLeft: 20}}/></div>
                </div>
                }
                okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
                width={600}
                closable={false}
                bodyStyle={{ background: '#F2F2F2' }}
            >
                <ModalContainer>

                    <div className='thead' >
                        <span> Code </span>
                        <span> Description </span>
                    </div>

                    {data?.process_category?.length > 0 &&
                        data.process_category.map((item)=>(
                            <div onClick={() => setSelected(item)} className={`tbody ${selected.id === item.id ? 'selected' : ''}`} key={`row-process-item-${item.id}`}>
                                <span> {item.code} </span>
                                <span> {item.description} </span>
                            </div>
                        ))
                    }

                </ModalContainer>
            </Modal>
        </Container>
    )
}

ChooseProcessCat.propTypes = {}

export default ChooseProcessCat