import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CommonDiv from 'Components/CommonDiv'
import { IconShare } from 'Components/Icons';
import { THEME } from 'Util/constants';
import { ArrowsAltOutlined, DeleteFilled } from '@ant-design/icons';
import { Popconfirm, Select, Table } from 'antd';
import GroupSelect from './GroupSelect';
import { UsersCardContext } from '../../UsersCard';
import _ from 'lodash'

const Container = styled.div`
.header-menu{

    border-bottom: solid 1px #ccc;
    border-top: solid 1px #ccc;
    padding: 10px 0px;
    margin-bottom: 10px;

    .title-menu{
        width: 300px;
        label{
            font-weight: bold;
        }
    }
    .filters{
        width: 60px;
    }
}`

const columns = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Departmament',
        dataIndex: 'department',
        key: 'department',
    }
];

function UserGroupInputTable({ data = [] }) {

    const { userData, setUserData } = useContext(UsersCardContext)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const tableData = data.map(gdata => ({
        key: gdata.id,
        code: gdata.user_group.code,
        name: gdata.user_group.name,
        department: "N/A"
    }))

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onDelete = () => {
        const { admin_user_groups } = userData
        setUserData({
            ...userData,
            admin_user_groups: _.filter(admin_user_groups, obj => (!selectedRowKeys.includes(obj.id)))
        })
    }

    return (
        <Container>
            <CommonDiv className='header-menu' direction="row" align="center" justify="space-between" >

                <CommonDiv justify="space-between" direction="row" className='title-menu' >
                    <label>User Group Memberships</label> | <label>Manage</label>
                </CommonDiv>

                <CommonDiv justify="space-between" direction="row" className='filters' >
                    <IconShare color={THEME.colors.primary} size={15} />
                    <ArrowsAltOutlined />
                    <Popconfirm
                        title="Remove all selected items?"
                        placement='left'
                        onConfirm={onDelete}
                    >
                        <DeleteFilled />
                    </Popconfirm>
                </CommonDiv>

            </CommonDiv>

            <Table
                size='small'
                columns={columns}
                dataSource={tableData}
                footer={() => <GroupSelect />}
                pagination={false}
                rowSelection={rowSelection}
            />

        </Container>
    )
}

UserGroupInputTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
}

export default UserGroupInputTable