import React from 'react'
import PropTypes from 'prop-types'
import DashTitleDivider from 'Components/DashTitleDivider'
import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'

const EcommPage = props => {
    return (
        <div>
            <SubPageBreadCrumbs />
            EcommPage</div>
    )
}

EcommPage.propTypes = {}

export default EcommPage