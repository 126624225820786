import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import { selectedProduct } from "Store/procurementAtom";
import { useRecoilState } from 'recoil';
import { useMutation } from '@apollo/client';
import { UPDATE_PRODUCT } from 'Util/mutations'
import moment from 'moment'

const Container = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;


function DeleteVendor(props) {
  
  const [product] = useRecoilState(selectedProduct);
  const [deleteProduct] = useMutation(UPDATE_PRODUCT);
  
  const confirmDelete = () => {    
    deleteProduct({
      variables: {
        id: product.id,
        object: {
          status: false,
          deleted_at: moment()
        }
      }
    })
  }
  
  const confirm = () => {

    Object.values(product).length > 0 && Modal.confirm({
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: THEME.colors.primary }} />,
      content: `Delete ${product.sku} - ${product.bid_specs}?`,
      autoFocusButton: 'ok',
      okButtonProps: {
        style: { backgroundColor: THEME.colors.primary }
      },
      onOk: confirmDelete
    });
  };

  return (
    <Container>
        <Tooltip title={Object.values(product).length > 0 ? `${product?.sku} - ${product?.bid_specs}` : undefined}>
            <DeleteFilled onClick={confirm} style={{ marginLeft: 20 }} />
            <label>Delete</label>
        </Tooltip>
    </Container>
  )
}

DeleteVendor.propTypes = {}

export default DeleteVendor
