
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Modal } from 'antd';
import { useSubscription } from '@apollo/client';
import { PURCHASE_ORDERS_LIST } from 'Util/subscriptions';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            // width: 25%;
            text-align: center;
        }
    }

    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

function SelectPurchaseOrder(props) {
    const { showModal, setShowModal, handleAdd, excludedIds, jobId } = props
    const [selected, setSelected] = useState()
    const [selectableItems, setSelectableItems] = useState([]);

    const { data, loading } = useSubscription(PURCHASE_ORDERS_LIST, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                _or: [
                    { job_id: { _is_null: true } },
                    { job_id: { _eq: jobId ?? 0 } }
                ]
    
            }
        }
    })

    useEffect(() => {
        if (data?.purchase_orders) {
            const items = data.purchase_orders.filter(item => !excludedIds.includes(item.id));
            setSelectableItems(items);
        }
    }, [data, excludedIds]);


    const okHandler = () => {
        if(selected){
            handleAdd(selected)
        }
        setShowModal(false);
    }

    if (loading) return <></>
 
    return (
        <Container>
        <Modal
            visible={showModal}
            centered
            onCancel={() => setShowModal(false)}
            onOk={okHandler}
            title={
            <div style={{ flexDirection: "row", display: "flex" }}>
                <span style={{ marginRight: 50 }}>Select Purchase Order</span>
            </div>
            }
            okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
            width={600}
            closable={false}
            bodyStyle={{ background: '#F2F2F2' }}
        >
            <ModalContainer>
            <div style={{ maxHeight: 300, overflow: "auto", maxWidth: 1400}}>
                <div className='thead' >
                    <span style={{ width: "10%"}}> PO No. </span>
                    <span style={{ width: "50%"}}> Item Description </span>
                    <span style={{ width: "20%"}}> Quantity (KG) </span>
                    <span style={{ width: "20%"}}> Status </span>
                </div>

                {selectableItems.length > 0 &&
                    selectableItems.map((item) => (
                    <div key={`ChooseLocation-${item.id}`} onClick={() => setSelected(item)} className={`tbody ${selected?.id === item.id ? 'selected' : ''}`} >
                        <span style={{ width: "10%"}}> {item.id} </span>
                        <span style={{ width: "50%", textAlign: 'left'}}> {item.bid?.product?.label || item.direct_offer_order?.direct_offer?.species_name} </span>
                        <span style={{ width: "20%"}}> {item.bid?.volume ?? item.direct_offer_order?.order_quantity} </span>
                        <span style={{ width: "20%", textTransform: 'capitalize'}}> {item.status} </span>
                    </div>
                    ))
                }
            </div>
            </ModalContainer>
        </Modal>
        </Container>
    )
}

SelectPurchaseOrder.propTypes = {}

export default SelectPurchaseOrder
