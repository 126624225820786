import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Select, Input, Checkbox } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import ManageDropDown from './ManageDropDown';
import ExtraFilters from './ExtraFilters';
import { useHistory } from 'react-router-dom';
import { UsersContext } from '../Users';
import _ from 'lodash';
import { UPDATE_ADMIN_USERS } from 'Util/mutations';

const { Option } = Select;

const Container = styled.div`
    border: solid 1px #ccc;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DropdownContainer = styled.div`
  label{
      font-weight: bold;
  }  
`;

const SearchContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
`;

const NewContainer = styled.div`
    cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

const CheckBoxContainer = styled.div`
    label {
        font-weight: bold;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${THEME.colors.primary};
        border-color: ${THEME.colors.primary};
    }
`;

function UsersTableFilter(props) {

    const history = useHistory();
    const { selectedRecord, permissionView, setPermissionView } = useContext(UsersContext)

    const editRecord = (id) => { history.push(`users/edit/${id}`) }
    const newRecord = (id) => { history.push(`users/new`) }
    const deleteRecord = () => { alert('delete') /* delete logic here, update deleted-at */ }
    const [defaultProfile, setDefaultProfile] = useState(selectedRecord.default_profile ? selectedRecord.default_profile : false)

    const [updateAdminUser] = useMutation(UPDATE_ADMIN_USERS);

    const onProfileChange = async (e) => {

        await updateAdminUser({
            variables: {
                where: {}, _set: { default_profile: false }
            }
        })

        await updateAdminUser({
            variables: {
                where: { id: { _eq: selectedRecord.id } },
                _set: { default_profile: e.target.checked }
            }
        })

        setDefaultProfile(e.target.checked)

    };

    useEffect(() => {
        setDefaultProfile(selectedRecord.default_profile)
    }, [selectedRecord.default_profile])


    return (
        <Container>

            <DropdownContainer>
                <label>Users: </label>
                <Select defaultValue="" style={{ width: 100 }} bordered={false} >
                    <Option value="">All</Option>
                </Select>
            </DropdownContainer>

            <SearchContainer>
                <Input prefix={<SearchOutlined />} placeholder="Search" />
            </SearchContainer>

            <NewContainer>
                <PlusOutlined onClick={newRecord} />
                <label>New</label>
            </NewContainer>

            <ManageDropDown
                selectedRecord={selectedRecord || {}}
                onDelete={deleteRecord}
                onEdit={editRecord}
            />

            <CheckBoxContainer>
                <Checkbox disabled={_.isEmpty(selectedRecord)} checked={defaultProfile} onChange={onProfileChange}>Use as default profile</Checkbox>
            </CheckBoxContainer>

            <ExtraFilters
                onInfo={() => setPermissionView(!permissionView)}
            />

        </Container>
    )
}

UsersTableFilter.propTypes = {}

export default UsersTableFilter
