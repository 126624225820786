import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, notification, Button, Input, InputNumber, Select } from 'antd'
import { useQuery } from '@apollo/client';
import { GET_ADMIN_USER_ROLES } from 'Util/queries';


function RolesSelect({ onChange, ...rest }) {

    const [list, setList] = useState([])

    const { data } = useQuery(GET_ADMIN_USER_ROLES, {
        onCompleted() {
            setList(data.admin_user_role)
        }
    });

    return (
        <Select onChange={onChange} {...rest} style={{ width: 300 }}>
            {list.map(v => <Select.Option value={v.id} key={`admin-usr-role${v.id}`}>{v.name}</Select.Option>)}
        </Select>
    )
}

RolesSelect.propTypes = {

}

export default RolesSelect

