import {
    ContainerOutlined,
    DashboardOutlined,
    HomeOutlined,
    UnorderedListOutlined,
    StarOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    SettingOutlined,
    BarChartOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined
} from '@ant-design/icons';
import { Menu, Tabs, Row, Col } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import { baseUrl } from 'Util/common';
// import BreadCrumbs from 'Components/BreadCrumbs';

const { TabPane } = Tabs;

function TemplateSecondaryMenu(props) {
    return (
        <>
            <Row>
                <Col span={12}>System Admin (EDIT)</Col>
                <Col span={12} style={{ textAlign: 'right' }}><span style={{ fontWeight: '700', color: '#09B3B3' }}>Pre-bid</span>  <span style={{ fontWeight: '700' }}>05:27:19</span></Col>
            </Row>


            <Tabs type="card" className="secondary-template-menu">
                <TabPane tab={
                    <span>
                        <HomeOutlined />
                        Home
                    </span>
                }
                    key="1">

                    {/* <BreadCrumbs /> */}
                    <br />

                    <Row>
                        <Col span={6} style={{ textAlign: 'center' }}>
                            <div>
                                <span style={{ fontSize: '17px', fontWeight: '700' }}>37</span>
                                <span style={{ fontSize: '11px' }}>MoM</span> <br />
                                <span style={{ fontSize: '10px', marginLeft: '21px', color: 'green' }}>17% <ArrowUpOutlined /></span><br />
                            </div>
                            <span>New Vendors</span>
                        </Col>
                        <Col span={6}><img src={`${baseUrl}/images/chart-1.png`} width="310" /></Col>
                        <Col span={6} style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: '17px', fontWeight: '700', textAlign: 'center' }}>New Vendors</span>
                            <img src={`${baseUrl}/images/chart-2.png`} width="310" />
                            <span style={{ fontSize: '17px', fontWeight: '700', textAlign: 'center' }}>Target Achievement</span>
                        </Col>
                        <Col span={6}><img src={`${baseUrl}/images/chart-3.png`} width="310" /></Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col span={6} style={{ textAlign: 'center' }}>
                            <div style={{ marginTop: '-124px' }}>
                                <span style={{ fontSize: '17px', fontWeight: '700' }}>1737</span>
                                <span style={{ fontSize: '11px' }}>MoM</span> <br />
                                <span style={{ fontSize: '10px', marginLeft: '21px', color: 'green' }}>2.2% <ArrowUpOutlined /></span><br />
                            </div>
                            <span>Total Vendors</span>
                        </Col>
                        <Col span={6}></Col>
                        <Col span={6}></Col>
                        <Col span={6}></Col>
                    </Row>

                    <br />
                    <br />
                    <Row>
                        <Col span={6} style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: '17px', fontWeight: '700' }}>32</span>
                            <span style={{ fontSize: '11px' }}>WoW</span> <br />
                            <span style={{ fontSize: '10px', marginLeft: '21px', color: 'red' }}>4.8% <ArrowDownOutlined /></span><br />
                            <span>Total Vendors</span>
                        </Col>

                        <Col span={8} style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '31px', fontWeight: '700', position: 'absolute', marginLeft: '112px', marginTop: '73px' }}>18.1%</span>
                            <img src={`${baseUrl}/images/chart-4.png`} width="310" />
                            <span style={{ fontSize: '17px', fontWeight: '700', position: 'absolute', marginTop: '180px', marginLeft: '-229px' }}>Inactive Vendors</span>
                        </Col>

                        <Col span={8}>
                            <Row>
                                <Col span={6} style={{ borderRight: '1px solid gray', height: '180px', textAlign: 'center' }}>
                                    1. Registered
                                    <br /><br />
                                    <span style={{ fontSize: '28px', fontWeight: '700', }}>178</span>
                                </Col>

                                <Col span={6} style={{ borderRight: '1px solid gray', height: '180px', textAlign: 'center' }}>
                                    2. Approved
                                    <img src={`${baseUrl}/images/color-1.png`} style={{ marginTop: '16px' }} width="90" height="100" /><br />
                                    <span style={{ fontSize: '17px', fontWeight: '700', }}>93.8%</span><br />
                                    <span style={{ fontSize: '17px', fontWeight: '700', }}>167</span>
                                </Col>

                                <Col span={6} style={{ borderRight: '1px solid gray', height: '180px', textAlign: 'center' }}>
                                    3. Bid

                                    <img src={`${baseUrl}/images/color-2.png`} style={{ marginTop: '25px' }} width="90" height="80" /><br />
                                    <div style={{ marginTop: '12px', position: 'absolute', marginLeft: '26px' }}>
                                        <span style={{ fontSize: '17px', fontWeight: '700' }}>71.3%</span><br />
                                        <span style={{ fontSize: '17px', fontWeight: '700', }}>127</span>
                                    </div>
                                </Col>

                                <Col span={6} style={{ borderRight: '1px solid gray', height: '180px', textAlign: 'center' }}>
                                    4. Transaction
                                    <img src={`${baseUrl}/images/color-3.png`} style={{ marginTop: '36px' }} width="90" height="60" /><br /><br />
                                    <span style={{ fontSize: '17px', fontWeight: '700', }}>47.8%</span><br />
                                    <span style={{ fontSize: '17px', fontWeight: '700', }}>85</span>
                                </Col>
                            </Row>


                        </Col>

                    </Row>

                    <br />
                    <br />
                    <Row>
                        <Col span={6} style={{ textAlign: 'center' }}>
                            <div style={{ marginTop: '-124px' }}> <span style={{ fontSize: '17px', fontWeight: '700', textAlign: 'center' }}>11</span> <br /></div>
                            <span>Pending Approval</span>
                        </Col>
                        <Col span={8}></Col>
                        <Col span={8}></Col>
                    </Row>

                </TabPane>

                <TabPane tab={
                    <span>
                        <UnorderedListOutlined />
                        Master
                    </span>
                }
                    key="2">

                    Master
                </TabPane>

                <TabPane tab={
                    <span>
                        <StarOutlined />
                        Active
                    </span>
                }
                    key="3">
                    Active
                </TabPane>

                <TabPane tab={
                    <span>
                        <CheckOutlined />
                        Pending
                    </span>
                }
                    key="4">
                    Pending
                </TabPane>

                <TabPane tab={
                    <span>
                        <CloseOutlined />
                        Rejected
                    </span>
                }
                    key="5">
                    Rejected
                </TabPane>

                <TabPane tab={
                    <span>
                        <DeleteOutlined />
                        Deleted
                    </span>
                }
                    key="6">
                    Deleted
                </TabPane>

                <TabPane tab={
                    <span>
                        <SettingOutlined />
                        Settings
                    </span>
                }
                    key="7">
                    Settings
                </TabPane>

                <TabPane tab={
                    <span>
                        <BarChartOutlined />
                        Economics
                    </span>
                }
                    key="8">
                    Economics
                </TabPane>

            </Tabs>

        </>
    )
}

TemplateSecondaryMenu.propTypes = {

}

export default TemplateSecondaryMenu

