import styled, { css } from "styled-components";
import CommonDiv from 'Components/CommonDiv'
import { THEME } from 'Util/constants';

export const AditionalInforContainer = styled(CommonDiv)`

    margin-bottom: 20px;

    label{
        display: block;
        min-width: 300px;
        color: ${THEME.colors.gray};
    }

    .dl-btn{
        background-color: ${THEME.colors.lightGray};
        border-color: ${THEME.colors.gray};
        color: #000;   
        height: 50px;
        margin: 0px 5px;
    }
    
`

export const LabelInfo = styled.label`
    color: ${THEME.colors.gray};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
`

export const OLWrapper = styled.ol`
    .ant-radio-wrapper{
        color: ${THEME.colors.gray};
    }
    &.ansOl{
        li{ 
            margin-bottom: 50px; 
            label.ans{
                color: ${THEME.colors.gray};
                font-style: italic;
            }
        }
    }
`