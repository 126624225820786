import React, { useState} from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import styled, { css } from "styled-components";
import TableFilter from "./TableFilter";
import _ from "lodash";
import { useSubscription } from '@apollo/client'
import { POLLS, SUB_ADMIN_USER_ROLES } from 'Util/subscriptions';
import KebabMenu from './KebabMenu'
import { THEME } from 'Util/constants';
import moment from 'moment-timezone';
import leadingZeroes from 'leading-zeroes'

const Container = styled.div``

function DataTable() {
    const [search, setSearch ] = useState()
    const [data, setData] = useState([])
    const [adminRoles, setAdminRoles] = useState()

    useSubscription(
        POLLS, {
        onSubscriptionData({ subscriptionData: { data } }) {
            if (data && !_.isEmpty(data)) {
                setData(data.polls)
            }
        }
    })

    useSubscription(
        SUB_ADMIN_USER_ROLES, {
        onSubscriptionData({ subscriptionData: { data } }) {
            if (data && !_.isEmpty(data)) {
                setAdminRoles(data.admin_user_role)
            }
        }
    })

    const getRoleName = (id) => {
        const roles = adminRoles?.find(obj => obj.id === id);
        return roles?.name
    }
    const loading = false

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 120,
            render: (_, row) => <span>{`QP${moment(row.created_at).year()}-${leadingZeroes(row.id, 3)}`}</span>
        },
        {
            title: 'Published Date',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 150,
            render: (date) => <span>{moment(date).format("DD-MMM-YY")}</span>
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            //width: 500
        },
        {
            title: 'Creator',
            dataIndex: 'created_by',
            key: 'created_by',
            width: 120,
            render: (creator) => <span style={{ color: THEME.colors.primary }}>{getRoleName(creator)}</span>
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            width: 100,
            render: (active) => <span style={{ color: active ? "green" : "red" }}>{active ? "Active" : "Closed"}</span>
        },
        {
            title: 'Expiration',
            dataIndex: 'expiry_date',
            key: 'expiry_date',
            width: 120,
            render: (date) => <span>{date ? moment(date).format("DD-MMM-YY") : "None"}</span>
        },
        {
            render: (row) => <KebabMenu data={row} />
        }
    ];

    const searchable = data.map((obj, i) => {
        let result = [];
        let concatenatedValues = "";
        columns.forEach(column => {
          if (obj[column.key] && obj[column.key] !== undefined) concatenatedValues += obj[column.dataIndex] + " ";
        });
        result.push(concatenatedValues.trim());
    
        return {
            key: i,
          ...obj,
          searchText:  result.toString()
        }
      })
    
    const filterData = (data) => {
        if(!search){
            return data
        }
        return _.filter(searchable, function (d) { 
            let query = search.toLowerCase();
            return _.includes(d.searchText.toLowerCase(), query)
        }); 
    };
    
    return (
        <Container>
            <TableFilter data={filterData(data)} column={columns} setSearch={setSearch}/>
            <Table
                loading={loading}
                columns={columns}
                dataSource={filterData(data)}
                pagination={{ defaultPageSize: 50 }}
                scroll={{ y: 500, x: 1500 }}
                rowKey={rec => parseInt(rec.id, 10)}
            />
        </Container>
    )

}

DataTable.propTypes = {
};

export default DataTable;

