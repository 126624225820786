import { useSubscription } from '@apollo/client';
import { Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { SUB_PRODUCT_DAILY_QUOTA } from 'Util/subscriptions';

function DailyQuota() {

    /* TODO: query the daily qouta with date parameter */
    const { data, loading, error } = useSubscription(SUB_PRODUCT_DAILY_QUOTA, { variables: { from: moment.utc().startOf('day').format() } });

    const columns = [
        {
            title: 'Product',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: `Daily volume`,
            dataIndex: 'quota',
            key: 'quota',
        },
        {
            title: 'Total Purchase volume ',
            dataIndex: 'bid_quota',
            key: 'bid_quota',
        },
        {
            title: 'Reserve Price',
            dataIndex: 'reserve_price',
            key: 'reserve_price',
        },
    ]

    const datasource = () => {
        if (data) {

            return data.products.map((v, i) => {
                const bid_quota = !_.isNull(v.bids_aggregate.aggregate.sum.volume) ? v.bids_aggregate.aggregate.sum.volume : 0;
                return {
                    key: `daily-qouta-key-${i}`,
                    product_name: v.label,
                    quota: v.quota,
                    bid_quota,
                    reserve_price: v.price
                }
            })

        }
    }

    return (
        <div>
            <Table loading={loading} columns={columns} dataSource={datasource()} />
        </div>
    )
}

export default DailyQuota
