import React from 'react'
import { SYSTEM_ADMIN } from '../constants'
import { LinkList } from './components'
import styled from "styled-components";
import sanitize from 'string-sanitizer'

const Container = styled.div`
`
const LinkContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

function SystemAdminView() {

    return (
        <Container>
            <h1>System Admin:</h1>
            <LinkContainer>
                {SYSTEM_ADMIN.links.map(v => <LinkList key={sanitize.addDash(v.title)} title={v.title} data={v.data} />)}
            </LinkContainer>
        </Container>
    )
}

export default SystemAdminView