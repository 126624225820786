import { ArrowLeftOutlined, PlusCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useSubscription, useQuery } from "@apollo/client";
import CommonButton, { ButtonLoader } from "Components/CommonButton";
import CommonDiv from "Components/CommonDiv";
import { THEME, communication, notification_groups, onesignal } from "Util/constants";
import { INSERT_ADMIN_NOTIFICATIONS, INSERT_ADMIN_NOTIFICATIONS_RECIPIENT } from 'Util/mutations';
import { GET_NOTIFICATION_TEMPLATES } from 'Util/queries';
import { POLLS } from 'Util/subscriptions';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  notification
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useRecoilState } from "recoil";
import { notificationAtom, selectedGroupAtom } from "Store/crmAtom";
import axios from 'axios';
import leadingZeroes from 'leading-zeroes';
import moment from 'moment-timezone';
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

const { Option } = Select;

const Container = styled.div`
    .actions-wrapper{
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icons-wrapper{
            justify-content: flex-end;
            width: 180px;
            gap: 20px;        
            svg{
                color: ${THEME.colors.primary};
                font-size: 25px;
                cursor: pointer;
            }
        }
        
        .backIconWrapper{
            background-color: ${THEME.colors.primary};
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
        h1{
            margin: 0px 10px;
        }
        
    }

    .submenu-wrapper{

        padding: 10px 0px;
        border-bottom: solid #555 thin;
        border-top: solid #555 thin;
        margin: 20px 0px;

        &>span{
            color: #555;
            margin: 0px 10px;
            font-size: 16px;
            font-weight: 500;
        }
    }
`

const SectionContainer = styled.div`
	.content {
		margin: 5px;
		display: flex;
		justify-content: space-between;
		& > div.division {
			width: 80%;
			.ant-form-item-label > label {
				width: 180px;
			}
			.ant-form-item-control {
				width: 10px;
			}
		}
	}
	.division-title {
		font-weight: bold;
	}

	.ant-switch-checked {
		background-color: ${THEME.colors.primary};
	}
`;

const Section = (props) => {
  return (
    <SectionContainer>
      <h3 style={{ borderBottom: "solid thin #000", padding: "5px" }}>
        {props.title}
      </h3>
      <div className="content">{props.children}</div>
    </SectionContainer>
  );
};

// TODO: transfer this to separate component
const CategoryDropdown = ({ category_id, onChange }) => {

  const [data, setData] = useState([])

  useQuery(GET_NOTIFICATION_TEMPLATES, {
    variables: { where: { category_id: { _eq: category_id } } },
    onCompleted(data) {
      data.admin_notifications_templates && setData(data.admin_notifications_templates)
    }
  })

  const changeHandler = (id) => {
    console.log(id)
    onChange(id ? data.find(v => v.id === id) : null)
  }

  return (
    <div>
      <Select placeholder="Select a category" dropdownMatchSelectWidth={true} onChange={changeHandler} allowClear
        defaultValue={0}
      >
        {data.map(v => <Option value={v.id} key={`category-${v.id}`}>{v.title}</Option>)}
        <Option value={0} key={`category-custom`}>Custom Notifications</Option>
      </Select>
    </div>
  )

}

const ModalContainer = styled.div`
    .thead, .tbody{
        width: 100%;
        padding: 5px;
        span{
            display: inline-block;
            width: 33.33%;
            text-align: left;
        }
    }
    .thead{
        span{
            font-weight: bold;
            color: #555;
        }
    }
    .tbody{
        cursor: pointer;
        &.selected{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        &:hover{
            background-color: ${THEME.colors.primary};
            span{
                color: #fff;
                &:first-child{
                    color: #fff;
                }
            }
        }
        span {
            &:first-child{
                color: ${THEME.colors.primary};
            }
            
        }
    }
`

const ChoosePoll = (props) => {
  const [selected, setSelected] = useState('company')
  const [templates, setTemplates] = useState([]);
  const { data } = useSubscription(POLLS)
  const { state, setState, setFieldValue } = props

  const okHandler = () => {
    setState(false);
    let date = `QP${moment(selected.created_at).year()}-${leadingZeroes(selected.id, 3)}`
    setFieldValue('poll_id', selected.id, date)
  }

  return (
    <Container>
      <Modal
        visible={state}
        centered
        onCancel={() => setState(false)}
        onOk={okHandler}
        title="Select Poll"
        okButtonProps={{ style: { backgroundColor: THEME.colors.primary } }}
        width={600}
        closable={false}
        bodyStyle={{ background: '#F2F2F2' }}
      >
        <ModalContainer>

          <div className='thead' >
            <span> Date </span>
            <span> Title </span>
          </div>

          {data?.polls?.length > 0 &&
            data.polls.map((item) => (
              <div onClick={() => setSelected(item)} className={`tbody ${selected.id === item.id ? 'selected' : ''}`} key={`row-item-${item.id}`}>
                <span> {`QP${moment(item.created_at).year()}-${leadingZeroes(item.id, 3)}`} </span>
                <span> {item.title} </span>
              </div>
            ))
          }

        </ModalContainer>
      </Modal>
    </Container>
  )
}

function PushNotificationPage(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const history = useHistory();
  const [formLoading, setFormLoading] = useState(false);

  const [notifvalues, setNotifValues] = useRecoilState(notificationAtom)
  const [selectedGroup, setSelectedGroup] = useRecoilState(selectedGroupAtom)

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(`Are you sure you want to send out this notification to these ${selectedGroup.length} recipient(s)?`);
  const [confirm, setConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [pollModal, setPollModal] = useState(false)
  const [pollAttachment, setPollAttachment] = useState()

  const submitHandler = (values) => {
    form.validateFields()
      .then(values => {
        setNotifValues(values)
        setOpen(true);
      })
  };

  const backHandler = () => { history.goBack() }

  const failedSubmitHandler = (values) => {
    setFormLoading(false);
    notification["error"]({
      message: "Something went wrong!",
      description: "Please input values on required fields.",
    });
  };

  const onConfirm = async () => {
    setConfirmLoading(true);

    const { MOBILE_PUSH, WEB_PUSH, SMS, EMAIL } = communication.channel

    const getPrefix = () => onesignal.ext_user_id_prefix.find(group => group.id == params.group_id).prefix

    const idPush = selectedGroup.filter(item => item.channels?.includes(WEB_PUSH)).map(item => getPrefix() + item.id.toString());
    const idEmail = selectedGroup.filter(item => item.channels?.includes(EMAIL)).map(item => getPrefix() + item.id.toString());
    const idSms = selectedGroup.filter(item => item.channels?.includes(SMS)).map(item => getPrefix() + item.id.toString());

    if (idPush.length > 0) {
      await sendPush(notifvalues.message, idPush)
    }
    if (idEmail.length > 0) {
      await sendEmail(notifvalues.message, notifvalues.title, idEmail)
    }
    if (idSms.length > 0) {
      await sendSms(notifvalues.message, idSms)
    }

    if (params.group_id == 4){
      sendAdminNotif(notifvalues.message, notifvalues.title, notifvalues.poll_id)
    }

    setTimeout(() => {
      setModalText('Your notification has been successfully sent!');
      setConfirm(true)
    }, 2000);
  }

  const openModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false)
    history.goBack()
  }

  const setFieldValue = (key, value, date) => {
    if (!Array.isArray(key)) form.setFieldsValue({ ...form.getFieldsValue(), [key]: value })
    else form.setFieldsValue({
      ...form.getFieldsValue(),
      [key[0]]: {
        ...form.getFieldsValue()?.[key[0]],
        [key[1]]: value
      }
    })
    setPollAttachment(date)
  }

  useEffect(() => {
    return () => {
      setNotifValues({})
      setSelectedGroup({})
    }  
  }, []);

  const [insertNotificationsRecipient] = useMutation(INSERT_ADMIN_NOTIFICATIONS_RECIPIENT, {
    onCompleted(data) { }
  })

  const [add] = useMutation(INSERT_ADMIN_NOTIFICATIONS, {
    onCompleted(response) {

      const admin_notifications_id = response.insert_admin_notifications.returning[0].id
      const users = selectedGroup.map(sel => {
        return {
          admin_notifications_id: admin_notifications_id,
          admin_user_id: sel.id
        };
      });
      
      insertNotificationsRecipient({
        variables: {
            objects: users
        }
      }) 
    }
  });

  const sendAdminNotif = async (body, title, poll_id) => {
    add({
      variables: {
        objects: {
          title,
          body,
          poll_id
        }
      },
    });
  }

  const sendEmail = async (message, subject, ids) => {
    const post = await axios.post(`${process.env.REACT_APP_API}/onesignal/send-ids-email`, { message, subject, ids }).then(res => {
      const { status, data } = res
      if (status === 200) {
        return data
      }
    })
    return post
  }

  const sendSms = async (message, ids) => {
    const post = await axios.post(`${process.env.REACT_APP_API}/onesignal/send-ids-sms`, { message, ids }).then(res => {
      const { status, data } = res
      if (status === 200) {
        return data
      }
    })
    return post
  }

  const sendPush = async (message, ids) => {
    const post = await axios.post(`${process.env.REACT_APP_API}/onesignal/send-ids-push`, { message, ids }).then(res => {
      const { status, data } = res
      if (status === 200) {
        return data
      }
    })
    return post
  }

  const getGroupName = (gid) => notification_groups.find(group => group.id == gid).group

  const showPollModal = () => {
    setPollModal(true)
  }

  const onCategoryChange = (item) => {
    form.setFieldsValue({ message: item ? item.message : "", title: item ? item.title : "" })
  }

  return (
    <Container>
      <ChoosePoll state={pollModal} setState={setPollModal} setFieldValue={setFieldValue} />
      <div className='actions-wrapper' >
        <div>
          <button onClick={backHandler} className='backIconWrapper' >
            <ArrowLeftOutlined />
          </button>
          <h1>Push Notification</h1>
        </div>
      </div>

      <div className='submenu-wrapper'>
          <span>You have selected {selectedGroup.length} recipient(s) for this notification</span>
      </div>
      
      <Form
        form={form}
        name="vendorForm"
        onFinish={submitHandler}
        onFinishFailed={failedSubmitHandler}
      >
        <Section title={getGroupName(params.group_id)}>
          <div className="division">

            {/* <Form.Item style={{ display: 'none' }} label="id" name={"id"} >
              <Input />
            </Form.Item> */}

            <Form.Item label="Category" name="category" style={{ marginTop: '20px', width: '600px' }}  >
              <CategoryDropdown category_id={params.group_id} onChange={onCategoryChange} />
            </Form.Item>

            <Form.Item label="Title" name="title" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Message" name="message" rules={[{ required: true, message: '' }]}>
              <TextArea rows={10} />
            </Form.Item>

            <Form.Item label="Attachment" name="Attachment">
              <PlusCircleFilled style={{ color: THEME.colors.primary }} />
            </Form.Item>

            <Form.Item label="Add Poll" name="poll_id">
              {pollAttachment &&
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                  <div style={{ background: "#eee", padding: "0px 5px" }} onClick={showPollModal}>{pollAttachment}</div>
                  <div onClick={() => { setPollAttachment(null) }}>x</div>
                </div>}
              {!pollAttachment && <PlusCircleFilled style={{ color: THEME.colors.primary }} onClick={showPollModal} />}
            </Form.Item>

          </div>
        </Section>

        <CommonDiv direction="row" justify="flex-end">
          {/* {!showModal && <ButtonLoader loading={formLoading} htmlType="submit" >Send</ButtonLoader>}
          {showModal && <Confirmation loading={formLoading} submitHandler={submitHandler}/>} */}
          <ButtonLoader loading={props.loading} htmlType="submit" onClick={open ? openModal : null}>Send</ButtonLoader>
          <Modal
            visible={open}
            centered={true}
            autoFocusButton='ok'
            width='600px'
            okText='Yes, Send'
            open={open}
            footer={
              !confirm ? <div>
                <Button loading={confirmLoading} style={{ backgroundColor: THEME.colors.primary, borderColor: THEME.colors.primary }} type="primary" onClick={onConfirm}>Yes, Send</Button>
                <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleCancel}>Cancel</Button>
              </div> :
                <div>
                  <Button onClick={onClose}>Close</Button>
                </div>
            }

          >
            <p><span style={{ fontSize: '30px', marginRight: '20px', color: THEME.colors.primary }}><QuestionCircleOutlined /></span> {modalText}</p>
          </Modal>

          <CommonButton className='reverse' onClick={() => history.goBack()}>
            Cancel
          </CommonButton>

        </CommonDiv>
      </Form>
    </Container>
  );
}

PushNotificationPage.propTypes = {};

export default PushNotificationPage;
