import { Form } from 'antd'
import React, { useState } from 'react'
import RoleForm from './components/RoleForm'
import AdminRoleList from './components/RoleList'

function RoleManagement(props) {

    const [form] = Form.useForm();
    const [drawer, setDrawer] = useState(false)
    const [editId, setEditId] = useState(null)

    const onEdit = (record) => {
        console.log(record)
        form.setFieldsValue({ ...record });
        setDrawer(true)
        setEditId(record.id)
    }

    const drawerToggle = (status) => {

        if (status) {
            form.resetFields()
            setEditId(null)
            setDrawer(true);
            return null
        }

        setDrawer(false)
    }



    return (
        <div className='admin-user-management'>
            <RoleForm
                form={form}
                drawer={drawer}
                drawerToggle={drawerToggle}
                editId={editId}
            />
            <AdminRoleList onEdit={onEdit} />
        </div>
    )
}

RoleManagement.propTypes = {

}

export default RoleManagement

