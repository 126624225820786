import { SubPageBreadCrumbs } from 'Components/BreadCrumbs'
import { createContext, useState } from 'react'
import { DataTable, Menu } from './components'
import ClockV2 from 'Components/ClockV2'
import { useSubscription } from '@apollo/client';
import { COLLECTION_LOCATIONS, SUB_JOBS } from 'Util/subscriptions';


export const LogisticsJobContext = createContext()

function LogisticsJobs() {

    /** Table filters */
    const [filters, setFilter] = useState({ jobtype: 'all' })
    const [selectedRecord, setSelectedRecord] = useState({})

    /** status condition */
    const type = filters.jobtype === "all" ? {} : filters.jobtype === "collection" ? { _eq: 1 } : { _eq: 0 }

    const { data, loading, error } = useSubscription(SUB_JOBS, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                type,
                deleted_at: { _is_null: true }
            }
        }
    })

    const formatData = (data) => {

        if (loading) { return [] }
        if (data?.jobs) {                   
            return data.jobs.map(d => {
                const totalVolume = d.purchase_orders.reduce((total, order) => {
                    const volume = order.bid?.volume || order.direct_offer_order?.order_quantity || 0;
                    return total + volume;                
                }, 0);
        
                return {
                    ...d,
                    depot: d.depot?.name,
                    shipper: d.shipper?.name,
                    total_weight: totalVolume 
                };
            });
        }
        return []


    }

    return (

        <LogisticsJobContext.Provider value={{ filters, setFilter, selectedRecord, setSelectedRecord }} >

            <div>
                <SubPageBreadCrumbs />
                <Menu/>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '20px'  }}>
                    {/* calendar compoenent here */}
                    
                    <div style={{ border: '1px solid #ccc', padding: '2px' }}>
                        {'<'} Tuesday March 6 2024 {'>'}
                    </div>

                    <ClockV2 />
                </div>
                <DataTable
                    data={loading ? [] : formatData(data)}
                    loading={loading}
                    pageSize={50}
                    height={500}
                />
            </div>

        </LogisticsJobContext.Provider>
    )
}

export default LogisticsJobs