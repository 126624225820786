import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import IconPurchaseInvoice from 'Components/Icons/IconPurchaseInvoice';
import IconPurchaseCreditMemo from 'Components/Icons/IconPurchaseCreditMemo';
import IconPurchaseOrder from 'Components/Icons/IconPurchaseOrder';
import IconPurchaseQuote from 'Components/Icons/IconPurchaseQuote';
import IconPurchaseReturnOrder from 'Components/Icons/IconPurchaseReturnOrder';

const menu = (
    <Menu>
        <Menu.Item>
            <a target="_blank" href="#" > <IconPurchaseQuote size={15} color={THEME.colors.primary} />
                <span style={{ marginLeft: 6 }}>Purchase Quote</span>
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" href="#" > <IconPurchaseInvoice size={15} color={THEME.colors.primary} />
                <span style={{ marginLeft: 6 }}>Purchase Invoice</span>
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" href="#" > <IconPurchaseOrder size={15} color={THEME.colors.primary} />
                <span style={{ marginLeft: 6 }}>Purchase Order</span>
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" href="#" > <IconPurchaseCreditMemo size={15} color={THEME.colors.primary} />
                <span style={{ marginLeft: 6 }}>Purchase Creadit Meno</span>
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" href="#" > <IconPurchaseReturnOrder size={15} color={THEME.colors.primary} />
                <span style={{ marginLeft: 6 }}>Purchase Return Order</span>
            </a>
        </Menu.Item>
    </Menu>
);

const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        margin-left: 5px;
    }
    label{
        font-weight: bold;
    }
    .ant-space-item{
        color: #000;
        &:first-child{
            font-weight: bold;
        }
    }
`;

function NewDocument(props) {
    return (
        <Container>
            <Dropdown overlay={menu} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        New Document
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Container>
    )
}

NewDocument.propTypes = {}

export default NewDocument