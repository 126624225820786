import React, { useContext } from 'react'
import ButtonCard from 'Components/ButtonCard'
import { useLocation } from 'react-router-dom'
import { LogisticsJobContext } from '..'


const data = [
    { link: '/logistics/jobs', title: 'All Jobs' },
    { link: '/logistics/jobs/collection', title: 'Collection' },
    { link: '/logistics/jobs/delivery', title: 'Delivery' },
]

function MenuButton(props) {
    const location = useLocation();
    const { filters, setFilter } = useContext( LogisticsJobContext )
    const handleOnClick = (jobtype) => {
        if(jobtype === 'all jobs'){
            jobtype = 'all'
        }
        setFilter({ ...filters, jobtype })
    }
    return (
        <div style={{ display: 'flex' }}>
            {data.map(v => (
                <div onClick={() => handleOnClick(v.title.toLocaleLowerCase())} key={`menu-key-${v.title}`}>
                    <ButtonCard
                        key={v.link}
                        link={v.link}
                        title={v.title}
                        lightBlue
                        selected={location.pathname === v.link}
                    />
                </div>

            ))}
        </div>
    )
}

MenuButton.propTypes = {}

export default MenuButton
