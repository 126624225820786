import { useMutation } from '@apollo/client';
import CommonDrawer from 'Components/CommonDrawer';
import { ADD_ADMIN_ROLE, UPDATE_ADMIN_ROLE } from 'Util/mutations';
import { Button, Form, Input, notification } from 'antd';
import _ from 'lodash';
import React from 'react';
import ModuleCheckbox from './ModuleCheckbox';

const rule = [
    {
        required: true,
        message: "Field is required"
    },
]

function AdminRoleForm({ form, drawer, drawerToggle, editId }) {

    const [addRole] = useMutation(ADD_ADMIN_ROLE, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Role has been added',
            });
            drawerToggle(false)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const [updateUser] = useMutation(UPDATE_ADMIN_ROLE, {
        onCompleted() {
            notification['success']({
                message: 'Saved!',
                description:
                    'Role has been updated!',
            });
            drawerToggle(false)
        },
        onError(e) {
            console.log('erre', e)
        }
    });

    const onFormSubmit = () => {
        const formData = form.getFieldsValue();

        console.log(formData.modules)

        // return null

        if (_.isNull(editId)) {
            // const modules = formData.modules.map(v => JSON.parse(v))
            addRole({ variables: { objects: formData } })
            return null
        }
        // console.log({ ...formData, modules })
        updateUser({ variables: { _eq: editId, _set: formData } })

    }


    return (
        <div className='admin-user-form'>

            <div className="action-button-wrapper" >
                <Button type="primary" onClick={() => { drawerToggle(true) }} >New Role</Button>
            </div>

            <CommonDrawer
                open={drawer}
                form={form}
                title={_.isNull(editId) ? "Add new Role" : "Edit Role"}
                onClose={() => drawerToggle(false)}
            >

                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    onFinish={onFormSubmit}
                    component={false}
                >
                    <Form.Item
                        rules={rule}
                        label="Name" name="name">
                        <Input placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Description" name="description">
                        <Input.TextArea placeholder="" style={{ width: 300 }} />
                    </Form.Item>

                    <Form.Item
                        rules={rule}
                        label="Modules" name="modules">
                        <ModuleCheckbox />
                    </Form.Item>

                </Form>

            </CommonDrawer>

        </div>
    )
}

AdminRoleForm.propTypes = {

}

export default AdminRoleForm

