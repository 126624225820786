import React from 'react'
import styled from "styled-components";
import { THEME } from 'Util/constants';
import CommonExportIcon from 'Components/CommonExportIcon'

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        cursor: pointer;
    }
    
    svg{
        cursor: pointer;
    }
`;

function ExtraFilters(props) {
    return (
        <Container>
            <CommonExportIcon data={props.data} columns={props.columns}/>
        </Container>
    )
}

ExtraFilters.propTypes = {}

export default ExtraFilters