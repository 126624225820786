import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import React from 'react';
import { makeDropDownItem } from 'Util/common';
import { preservationMethods } from 'Util/constants';
import { GET_SETTINGS } from 'Util/queries';
const { Option } = Select;

function PreservationMethodDropdown({ onChange, ...rest }) {

    const { loading, error, data, refetch, networkStatus } = useQuery(GET_SETTINGS, {
        variables: {
            where: { key: { _eq: "direct_sales" } }
        }
    })

    if (loading) { return <></> }

    const list = data.settings[0].values.preservation_method.map(makeDropDownItem)

    return (
        <div>
            <Select
                style={{ width: 300 }}
                placeholder="Select a method"
                onChange={onChange}
                {...rest}
            >
                {list.map((val, idx) => <Option value={val.label} key={`ppm-dd-opt${idx}`}>{val.value}</Option>)}
            </Select>
        </div>
    )
}

export default PreservationMethodDropdown
