
import React from 'react'
import styled from "styled-components";
import { THEME } from 'Util/constants';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
cursor: pointer;
    .anticon{
        color: ${THEME.colors.primary};
        margin-right: 5px;
    }
    label{
        font-weight: bold;
    }
    
`;

function New(props) {
    const history = useHistory()
    const onClickHandler = () => {
        history.push(`/logistics/depots/new/item`, { prevPath: '/logistics/depots'} )
    }

    return (
        <Container>
            <PlusOutlined onClick={onClickHandler} />
            <label>New</label>
        </Container>
    )
}

New.propTypes = {}

export default New