import React, { useState } from 'react';
import { Modal, Radio, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'
import { ADD_AUCTION_SCHEDULE_SETTINGS, UPDATE_AUCTION_SCHEDULE_SETTINGS } from 'Util/mutations'
import { useMutation } from '@apollo/client'

const RecurringModalOption = ({ visible, onCancel, onOk, scheduleId, scheduleDate, action }) => {
    const [selectedOption, setSelectedOption] = useState('single');
    const history = useHistory()

    const [addScheduleSettings] = useMutation(ADD_AUCTION_SCHEDULE_SETTINGS, {
        onCompleted(data) {
            notification['success']({
                message: 'Deleted!',
                description: 'Schedule deleted!',
            });
        }
    })

    const [updateScheduleSettings] = useMutation(UPDATE_AUCTION_SCHEDULE_SETTINGS, {
        onCompleted() {
            notification['success']({
                message: 'Deleted!',
                description: 'Schedule deleted!',
            });
        }
    });

    const addAuctionScheduleSettings = () => {
        addScheduleSettings({
            variables: {
                objects: {
                    date: scheduleDate,
                    override: "ignore",
                    reference_id: scheduleId,
                    end_date: scheduleDate
                }
            }
        })
    }

    const handleRadioChange = (e) => {
      setSelectedOption(e.target.value);
    };
  
    const handleModalOk = () => {
        if(action === 'edit'){
            history.push(`schedules/edit/${scheduleId}`, { editOption: selectedOption, scheduleDate })
        }
        else{
            if(selectedOption === "single"){
                addAuctionScheduleSettings()
            }
            if(selectedOption === "series"){
                updateScheduleSettings({
                    variables: {
                        where: { id: {_eq:  scheduleId}},
                        _set: {
                            end_date: scheduleDate
                        }   
                    }
                })
            }
        }

        onOk(selectedOption);
    };
  
    return (
        <Modal
        title={`${_.capitalize(action)} Recurring Item`}
        visible={visible}
        onCancel={onCancel}
        onOk={handleModalOk}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                    This is a recurring schedule. What do you want to {action}?
                </div>
                <Radio.Group onChange={handleRadioChange} value={selectedOption} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Radio style={{ display: 'block', marginBottom: 10 }} value="single">
                        Just this one
                    </Radio>
                    <Radio style={{ display: 'block' }} value="series">
                        The entire series
                    </Radio>
                </Radio.Group>
            </div>
        </Modal>
    );
  
};

export default RecurringModalOption;
