import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

const { Option } = Select;

const Container = styled.div`
  label{
      font-weight: bold;
  }  
`;

function StatusDropDown(props) {

    const history = useHistory()
    const params = useParams()

    const handleChange = (value) => {
        console.log('goto', value)

        if (!value) {
            history.push('/logistics/depots')
        }

        history.push(`/logistics/depots/${value}`)

    }

    return (
        <Container>
            <label>Depots: </label>
            <Select value={params.status ?? ""} defaultValue="" style={{ width: 180 }} bordered={false} onChange={handleChange}>
                <Option value="">All</Option>
                <Option value="collection-point">Collection Point</Option>
                <Option value="collection-hub">Collection Hub</Option>
                <Option value="processing-plant">Processing Plant</Option>
                <Option value="cold-room-warehouse">Cold Room Warehouse</Option>
            </Select>
        </Container>
    )
}

StatusDropDown.propTypes = {}

export default StatusDropDown