import React, { useState } from 'react'
import { Dropdown, Menu, notification, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { approvalStatus, approvalType, vendorApprovalStatus } from 'Util/constants';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_ADMIN_APPROVAL, UPDATE_ADMIN_USER, UPDATE_USER_OBJECT } from 'Util/mutations';
import { sendCreateAdminEmail } from 'Util/mailer';
import _ from 'lodash'
import { GET_ADMIN_USER, GET_USER } from 'Util/queries';
import User from 'Hooks/user';
import { sendOnBoardingSMS } from 'Util/smsHelper';
import axios from 'axios';


function VendorApproveActions({ ids }) {

    const [lbl, setLbl] = useState("Pending")
    const [vendor, setVendor] = useState(null)

    const [updateUser] = useMutation(UPDATE_USER_OBJECT)

    const { user_id } = User()

    useQuery(GET_USER, {
        variables: { where: { id: { _eq: ids.user_id } } },
        onCompleted(data) {
            data.users && setVendor(data.users[0])
        }
    })

    const [update] = useMutation(UPDATE_ADMIN_APPROVAL, {
        onCompleted(data) {
            notification.success({
                message: "Status updated!"
            })
        }
    })

    const onAction = async (status) => {

        await update({
            variables: {
                where: { id: { _eq: ids.request_id } },
                _set: { status: status === 'verified' ? 'approved' : status, approver_id: user_id }
            }
        })

        if (['rejected', 'blocked'].includes(status)) {
            sendOnBoardingSMS({ to: vendor.mobile, name: vendor.name, action: 'rejected' })
        }

        if (status === 'verified') { // note: this is also 'approve'

            /** send welcome sms */
            sendOnBoardingSMS({ to: vendor.mobile, name: vendor.name, action: 'approve' })

            /** update user status  */
            updateUser({ variables: { id: ids.user_id, obj: { status } } })

            /** send temp password with onboarding */
            if (!_.isNull(vendor.onboard_code)) {
                axios.post(`${process.env.REACT_APP_AUTH_ENDPOINT}/verify/vendor-generate-password`, { onboard_code: vendor.onboard_code })
            }

        }

        const res = _.find(vendorApprovalStatus, ['value', status])
        setLbl(res.label)
    }

    const menu = (
        <Menu >
            {vendorApprovalStatus.map(v => {
                return <Menu.Item key={v.value} onClick={() => onAction(v.value)} >{v.label}</Menu.Item>
            })}
        </Menu >
    );

    return (
        <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
                <Space> {lbl} <DownOutlined /> </Space>
            </a>
        </Dropdown>
    )
}

export default VendorApproveActions