import React, { useState} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { THEME } from 'Util/constants';
import { BellFilled } from '@ant-design/icons';
import CommonExportIcon from 'Components/CommonExportIcon'
import { useRecoilState } from "recoil";
import { selectedVendorAtom } from "Store/crmAtom";
import { SelectNotificationGroup } from 'Pages/CRM/components';

const Container = styled.div`
    
    width: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .anticon{
        color: ${THEME.colors.primary};
        cursor: pointer;
    }
    
    svg{
        cursor: pointer;
    }

    `;

function ExtraFilter(props) {
    const [vendor, setSelectedVendor] = useRecoilState(selectedVendorAtom)
    const [showModal, setShowModal] = useState(false)
    
    const onClick = () => {
        setShowModal(!showModal)
    }

    return (
        <Container>
            <SelectNotificationGroup showModal={showModal} setShowModal={setShowModal}/>
            <BellFilled onClick={onClick}/>
            <CommonExportIcon data={props.data} columns={props.column}/>
        </Container>
    )
}

ExtraFilter.propTypes = {}

export default ExtraFilter
