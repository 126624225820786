import React, { useState } from 'react'
import { Button, Popover } from 'antd';

function PopOverInfo({
    data = {},
    children
}) {

    const [visible, setVisible] = useState(false)
    const content = () => (
        <div className='direct-offer-popover' >

            <div className='desc-wrapper'>
                <img src={data.image} />
                <div className='desc'>
                    <span>{data.species_sku}</span>
                    <span className='title'>{data.species_name}</span>
                    <span>{`${data.harvest_method} from ${data.location}. ${data.preservation_method}`}</span>
                    <br />
                    <span>{data.description}</span>

                </div>
            </div>
            <div>
                <div className='row-wrapper'>
                    <label>Category:</label>
                    <span>{data.category}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Preservation type:</label>
                    <span>{data.preservation_method}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Country of origin:</label>
                    <span>{data.country_origin}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Harvest method:</label>
                    <span>{data.harvest_method}</span>
                </div>
                <div className={`row-wrapper`}>
                    <label>QTY:</label>
                    <span>{data.quantity}</span>
                </div>
                <div className={`row-wrapper`}>
                    <label>Minimum order QTY:</label>
                    <span>{data.min_quantity}</span>
                </div>
                <div className='row-wrapper'>
                    <label>Unit price:</label>
                    <span>{data.price}/{data.unit}</span>
                </div>
                <div className='row-wrapper'>
                    <label>OffeType price:</label>
                    <span>{data.offer_type}</span>
                </div>
            </div>

        </div>
    )

    return <Popover
        placement='leftTop'
        trigger={'click'}
        onVisibleChange={visible => setVisible(visible)}
        visible={visible}
        content={content}
    >{children}</Popover>
}

export default PopOverInfo