import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import React from 'react';
import { GET_LOCATION_POINTS } from 'Util/queries';
const { Option } = Select;

function CollectionCentreDropdown({ onChange, setFieldValue, ...rest }) {

    const { loading, data } = useQuery(GET_LOCATION_POINTS)

    if (loading) { return <></> }

    const list = data?.collection_locations || {};

    const handleChange = (value) => {
        const selectedCentre = list.find(v => v.id === value);
        if (setFieldValue) {
            setFieldValue('collection_location.address_1', selectedCentre.address_1)
        }
        setFieldValue('collection_location_id', value)
    };

    
    return (
        <div>
            <Select
                placeholder="Select a location"
                onChange={handleChange}
                {...rest}
            >
                {list.map(v => (
                    <Option key={v.id} value={v.id}>
                        {v.location_name}
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default CollectionCentreDropdown
