import React, {useState} from 'react';
import { EllipsisOutlined, AreaChartOutlined, FundViewOutlined , EyeOutlined, PauseOutlined } from "@ant-design/icons";
import { THEME } from "Util/constants";
import { Popover} from 'antd';
import styled from "styled-components";
import { ADD_INSERT_USER_TAGS } from 'Util/mutations';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

const Container = styled.div``

const StyledPopover = styled(Popover)`
    .ant-popover-inner-content {
        padding: 0px 0px;
    }
`

const Content = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    .row {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }
    .text {
        margin-left: 5px;
    }
`
  
function KebabMenu({ data }) {
    const [visible, setVisible] = useState(false)
    const [open, setOpen] = useState(false)
    const history = useHistory()

    const {id} = data

    const edit = () => {
        setOpen(!open)
        setVisible(true)
    }
  
    const onClick = () => {
        setOpen(!open)
    }

    const content = (
        <Content>
            <div onClick={edit} className='row'>
                <EyeOutlined style={{ color: THEME.colors.primary}}/>
                <div className='text'>View Users</div>
            </div>
            <div onClick={() => { history.push(`polls/details/${id}`) }} className='row'>
                <FundViewOutlined style={{ color: THEME.colors.primary}}/>
                <div className='text'>View Results</div>
            </div>
            <div onClick={edit} className='row'>
                <PauseOutlined style={{ color: THEME.colors.primary}}/>
                <div className='text'>Recent Activity</div>
            </div>
            <div onClick={edit} className='row'>
                <AreaChartOutlined style={{ color: THEME.colors.primary}}/>
                <div className='text'>Analytics</div>
            </div>
        </Content>
    );
      
    return (
      <Container>
        <StyledPopover placement="rightTop" content={content} trigger="click" visible={open} onClick={onClick} onVisibleChange={onClick}>
            <EllipsisOutlined type="ellipsis" style={{ transform: "rotate(90deg)", color: THEME.colors.primary}} />
        </StyledPopover>
      </Container>
    );
  };
  
  export default KebabMenu;
