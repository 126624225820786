import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { AcceptIcon, CardIcon, CounterIcon, EditIcon, RejectIcon, SaveIcon } from 'Components/Icons/actions';
import gmap from 'gmap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { auctionAtom } from 'Store/auctionAtom';
import styled from 'styled-components';
import { mapConstant } from 'Util/constants';
import { ADD_PICKUP_ORDER, UPDATE_BID_REJECT_STATUS, UPDATE_BID_STATUS } from 'Util/mutations';
import CounterOfferPopUp from './CounterOfferPopUp';
import { auctionQuotaSelectedRecordAtom } from 'Store/auctionAtom';
import { UPDATE_PRODUCT } from 'Util/mutations';

const Container = styled.div`
    width: 100px;
    svg{
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 5px;
        color: grey;
    }
`

function ActionsCol({ type = "arc", onAction, data }) {
    const [{ auctionStatus, currentAuction, currentBids }] = useRecoilState(auctionAtom);
    const [selectedData, setSelectedData] = useRecoilState(auctionQuotaSelectedRecordAtom)

    /** accept bid  */
    const [acceptBid] = useMutation(UPDATE_BID_STATUS, {
        onCompleted(data) {

            notification['success']({
                message: 'Posted!',
                description: 'Bid status details has been updated',
            });

        }
    })

    const [rejectBid] = useMutation(UPDATE_BID_REJECT_STATUS);

    /** pick up order  */
    const [addPickUpOrder] = useMutation(ADD_PICKUP_ORDER);

    const acceptBidHandler = async () => {

        const { bid } = data

        if (!["pending"].includes(bid.status)) return

        const bids = _.filter(currentBids, { auction_id: bid.auction_id, product_id: bid.product.id, status: 'accepted' });
        const rem_quota = bid.product.volume - (_.sumBy(bids, 'volume') + bid.volume)

        const variables = {
            id: bid.id,
            obj: { status: "accepted", rem_quota },
            po_object: { bid_id: bid.id, user_id: bid.user.id }
        }

        const res = await acceptBid({ variables })

        const objects = {
            purchase_order_id: res.data.insert_purchase_orders.returning[0].id,
            bid_id: bid.id,
            user_id: bid.user.id,
            link: gmap.directions(mapConstant.pickupCoordinates, `${bid.user.user_location.lat},${bid.user.user_location.lng}`),
            eta: 10,
        }

        addPickUpOrder({ variables: { objects } })
    }

    /** reject bid  */
    const rejectBidHandler = () => {
        const { bid: { id, status } } = data

        if (!["pending"].includes(status)) return

        rejectBid({ variables: { id, status: 'rejected' } })
    }

    /** cesr actions */
    const {product_data} = data

    const [update] = useMutation(UPDATE_PRODUCT, {
        // onCompleted() {
        //     notification['success']({
        //         message: 'Saved!',
        //         description:
        //             'Product has been updated!',
        //     });
        // },
        onError(e) {
            console.log('erre', e)
        }
    });

    const save = () =>{

        if (selectedData?.id !== product_data.id) return

        if(!_.isNull(selectedData)){
            const row = () => ({...selectedData})
            let rowDataCompare = row()
            delete rowDataCompare.searchText
            delete rowDataCompare.action

            const {action} = selectedData

            const auctionQuotaObj = action.auction_data.map((auction, id) => {
                    const dataPrice = _.find(product_data.auction_quota, ['aid', auction.id])
                    const productPrice = !_.isUndefined(dataPrice) ? dataPrice?.price : selectedData.reserved_price;
                    const quota = auction.status === 'closed' ? 0 : selectedData[`auction_${auction.id}`];
                    const price = auction.status === 'closed' ? selectedData.reserved_price : productPrice;
                    
                    return {aid: auction.id, price, quota }
            })

            const auctionQuotaChanges = JSON.stringify(auctionQuotaObj) !== JSON.stringify(action.product_data.auction_quota)
            const rowDataChanges = JSON.stringify(rowDataCompare) !== JSON.stringify(data.data)

            if(auctionQuotaChanges || rowDataChanges){
                const object = {
                    quota: selectedData.day_quota,
                    price: selectedData.reserved_price,
                    auction_quota: auctionQuotaObj
                }
                update({ variables: { id: selectedData.id, object} })
            }
            setSelectedData('')
        }
    }

    return (
        <Container>
            {['cesr'].includes(type) && <>
                {/* <CardIcon onClick={() => onAction('card')} /> */}
                <EditIcon onClick={() => onAction('edit')} />
                <SaveIcon color={selectedData?.id !== product_data.id ? "#cccccc" : null} onClick={save} />
                <RejectIcon onClick={() => onAction('reject')} />
            </>}

            {['arc'].includes(type) && <>
                <AcceptIcon color={!["pending"].includes(data.bid.status) ? "#cccccc" : null} onClick={acceptBidHandler} />
                <RejectIcon color={!["pending"].includes(data.bid.status) ? "#cccccc" : null} onClick={rejectBidHandler} />
                <CounterOfferPopUp data={data.bid} />
            </>}
        </Container>
    )
}

ActionsCol.propTypes = {
    type: PropTypes.string,
    onAction: PropTypes.func
}

export default ActionsCol
